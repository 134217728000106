import SettingsAccountComponent from './settings-account.component'

import AccountChangePasswordModule from './change-password'
import AccountDeleteModule from './delete-account'
import DeleteAccountResultModule from './delete-account-result'
import AccountAvatarModule from './account-avatar-upload'
import ChangeEmailDialogModule from './change-email'
import ChangeEmergencyContactModalModule from './change-emergency-contact'

const SettingsAccountModule = angular.module('cdbl.settings-account', [
  AccountChangePasswordModule,
  AccountDeleteModule,
  DeleteAccountResultModule,
  AccountAvatarModule,
  ChangeEmailDialogModule,
  ChangeEmergencyContactModalModule
])
  .component('cdblSettingsAccount', SettingsAccountComponent)
  .config(($stateProvider) => {
    'ngInject'

    $stateProvider
      .state('settings.account', {
        url: '/account',
        views: {
          'settingsView': {
            template: '<cdbl-settings-account></cdbl-settings-account>'
          }
        },
        resolve: {
          permissionResolver: (AuthService) => {
            'ngInject'
            return AuthService.permissionResolver('isAuthenticated')
          }
        },
        layout: 'new'
      })
  })
  .name

export default SettingsAccountModule
