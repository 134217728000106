const EVENT_PROJECT_REMINDERS_LOADED = 'project-reminders-loaded'
const ProjectRemindersService = class ProjectRemindersService {
  constructor ($http, Configuration, UserService, ProjectService, UIService, EventBusService, ModalService) {
    'ngInject'

    this.$http = $http
    this.Configuration = Configuration
    this.UserService = UserService
    this.ProjectService = ProjectService
    this.UIService = UIService
    this.ModalService = ModalService

    this.EventBusService = EventBusService
  }

  /**
   * shortcut to project object
   * @return {Object} Currently opened project
   */
  get project () {
    return this.ProjectService.project
  }

  canShowReminder (reminderName) {
    console.log('[ProjectRemindersService] > showReminder', !this.UIService.isMobile, this.UserService.canShowReminderModals, !this.getReminderState(reminderName))

    return !this.UIService.isMobile &&
      this.UserService.canShowReminderModals &&
      !this.getReminderState(reminderName) &&
      !this.ModalService.isOpened // only open if any other modal is not already open - this is to prevent clash between old and new modals as reminders are displayed via old modal
  }

  getReminderState (reminderName) {
    if (!this.project || !this.project._reminders) {
      return false
    }
    console.log('[ProjectRemindersService] > getReminderState', reminderName, this.project._reminders[reminderName])
    return this.project._reminders[reminderName]
  }

  setReminderState (reminderName, state = true) {
    if (!this.project || !this.project._reminders) {
      return false
    }

    const payload = {
      user_id: this.UserService.user.id // TODO: [wat] - why does BE need this - it could get data about user from request session
    }
    payload[reminderName] = state

    return this.$http
      .put(`${this.Configuration.apiUrl}/tasks/${this.project.id}/project_reminders`, payload)
      .then(response => {
        Object.assign(this.project._reminders, response.data.reminders) // update project reminders
      })
  }

  getReminders (projectId = this.project.id) {
    // Do not fetch reminders if no project is opened or if current user is not an expert
    if (typeof projectId !== 'undefined' && !this.UserService.isExpert()) {
      return
    }

    this.$http
      .get(`${this.Configuration.apiUrl}/tasks/${projectId}/project_reminders`)
      .then(response => {
        this.project._reminders = response.data.reminders // add data to project TODO: create method in ProjectService to append data to project object instead of direct change
        this.EventBusService
          .channel('legacy') // default root channel
          .publish(EVENT_PROJECT_REMINDERS_LOADED, {})
      })
  }

  // TODO: changing user setting - remove if not neccessary anymore - currently user is updating this via settings update
  // const toggleTooltips = function (toggleBoolean) {
  //     $http.put(`${Configuration.apiUrl}/users/me`, { modal_tooltips_enabled: toggleBoolean }).then(result => {
  //       if (result) {
  //         let responseData = result.data;
  //         if (responseData) {
  //           Auth.changeMe('modalTooltipsEnabled', responseData.modalTooltipsEnabled);
  //         }
  //       }
  //     });
  //   };
}
export default ProjectRemindersService
