import templateUrl from './switch.html'
import './switch.scss'

const SwitchComponent = {
  bindings: {
    onToggle: '&?'
  },
  require: {
    fieldCtrl: '?^cdblFieldContainer',
    ngModel: 'ngModel'
  },
  transclude: true,
  templateUrl,
  controller: class Switch {
    constructor ($element, $attrs, EventEmitter) {
      'ngInject'
      this._identify = 'SwitchComponent'

      this.$attrs = $attrs
      this.$element = $element
      this.EventEmitter = EventEmitter

      this.value = false
    }

    $onInit () {
      this.ngModel.$render = () => {
        this.value = this.ngModel.$viewValue
      }

      if (this.fieldCtrl) {
        this.fieldCtrl.setFieldElementCtrl({
          ngModel: this.ngModel,
          $element: this.$element,
          $attrs: this.$attrs
        })
      }
    }

    $onDestroy () {
      if (this.fieldCtrl) {
        this.fieldCtrl.setFieldElementCtrl(null)
      }
    }

    toggle () {
      this.value = !this.value
      this.ngModel.$setViewValue(this.value)
      this.ngModel.$setTouched()
      if (this.onToggle) {
        this.onToggle(this.EventEmitter({
          newValue: this.value
        }))
      }
    }
  }
}

export default SwitchComponent
