import templateUrl from './task-expert-feedback.html'
import './task-expert-feedback.scss'

const TaskExpertFeedbackComponent = {
  bindings: {
    taskId: '='
  },
  templateUrl,
  controller: class TaskExpertFeedback {
    constructor ($rootScope, TaskExpertFeedbackService, SegmentAnalytics, ModalService, $timeout) {
      'ngInject'
      this._identify = 'TaskExpertFeedbackComponent'
      this.TaskExpertFeedbackService = TaskExpertFeedbackService
      this.SegmentAnalytics = SegmentAnalytics
      this.ModalService = ModalService
      this.$timeout = $timeout

      this.canSubmit = false
      this.isSubmitted = false
      this.isLoaded = false
      this.taskFeedback = null

      this.tabs = [
        {
          title: '<span>Project</span> Feedback',
          id: 'project',
          active: true
        },
        {
          title: '<span>Client</span> Feedback',
          id: 'client',
          active: false
        },
        {
          title: '<span>Personal</span> Feedback',
          id: 'personal',
          active: false
        }
      ]

      // If user submits his estimate hide feedback box
      this.removeListener = $rootScope.$on('current-user::estimate-submitted', ($event, data) => {
        console.log('current-user::estimate-submittted', $event, data)
        if (data.task.id === this.taskId) {
          this.canSubmit = false
        }
      })
    }

    /**
     * On init lifecycle event
     * @return {[type]} [description]
     */
    $onInit () {
      this.isFormExpanded = false
      this.TaskExpertFeedbackService.getTaskExpertFeedback(this.taskId)
        .then(response => {
          console.log('TaskExpertFeedbackComponent > $onInit > getTaskExpertFeedback > response', response)
          this.isLoaded = true

                                      // TODO: use task.currentUserShouldGiveNoEstimateFeedback for now, when status is done on BE we can switch
          if (response.data.status === 'can_submit') {
            this.canSubmit = true
          } else {
            return
          }
          this.taskFeedback = {
            project: response.data.answers.filter(item => item.group === 'project'),
            client: response.data.answers.filter(item => item.group === 'client'),
            personal: response.data.answers.filter(item => item.group === 'expert')
          }
          console.log('TaskExpertFeedbackComponent > $onInit > getTaskExpertFeedback > this.taskFeedback', this.taskFeedback, this.isLoaded)
        })
        .catch(err => {
          console.log('TaskExpertFeedbackComponent > $onInit > getTaskExpertFeedback > error', err)
        })
    }

    $onDestroy () {
      this.removeListener()
    }

    toggleExpandForm () {
      this.isFormExpanded = !this.isFormExpanded
    }

    submitFeedback () {
      let payload = {
        feedback: []
      }

      for (let tab of this.tabs) {
        for (let item of this.taskFeedback[tab.id]) {
          if (item.value) {
            payload.feedback.push({
              feedback_answer_id: item.id,
              details: item.value
            })
          }
        }
      }

      console.log(payload)
      this.isProcessing = true
      this.error = false
      this.TaskExpertFeedbackService.putTaskExpertFeedback(this.taskId, payload)
        .then(response => {
          this.isProcessing = false
          this.isSubmitted = true
          console.log('TaskExpertFeedbackComponent > $onInit > putTaskExpertFeedback > response', response)
        })
        .catch(err => {
          this.isProcessing = false
          this.errorMessage = 'Error occured while saving your feedback. Please try again.'
          console.log('TaskExpertFeedbackComponent > $onInit > putTaskExpertFeedback > error', err)
        })
    }

    toggleActive (tag) {
      tag.value = !tag.value
    }

    selectTab (tab) {
      this.tabs.forEach(tab => {
        tab.active = false
      })
      tab.active = true
    }

    onTagClick (tag) {
      console.log('onTagClick', tag.id, tag.active)
    }

    countSelected (category) {
      if (this.taskFeedback && this.taskFeedback[category]) {
        return this.taskFeedback[category].filter(item => item.enabled === true || (item.value && item.value !== '')).length
      }
      return 0
    }

    disableSubmit () {
      let isDisabled = true
      for (let tab of this.tabs) {
        for (let item of this.taskFeedback[tab.id]) {
          if (item.value) {
            isDisabled = false
            return isDisabled
          }
        }
      }

      return isDisabled
    }
  }
}

export default TaskExpertFeedbackComponent
