import templateUrl from './project-browse-list.html'
import './project-browse-list.scss'

const ProjectBrowseListComponent = {
  bindings: {},
  templateUrl,
  controller: class ProjectBrowseList {
    constructor ($stateParams, PaginationService, ProjectBrowseService) {
      'ngInject'
      this._identify = 'ProjectBrowseListComponent'
      this.$stateParams = $stateParams
      this.PaginationService = PaginationService
      this.ProjectBrowseService = ProjectBrowseService

      this.isLoading = false
      this.projectList = null
    }

    $onInit () {
      this.ProjectBrowseService.init()
      this.showPodsBar = this.$stateParams.listId === 'expert-pods'

      this.isLoading = true
      this
        .ProjectBrowseService
        .getProjectList()
        .then(response => {
          this.projectList = response
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    get filters () {
      return this.ProjectBrowseService.filters
    }

    get pagination () {
      return this.ProjectBrowseService.pagination
    }

    get noResultsMessage () {
      let message = 'Sorry no projects found.'

      switch (this.$stateParams.listId) {
      case 'preferred':
        message = 'You do not have any preferred projects at the moment.'
        break
      case 'expert-pods':
        message = 'You do not have any pod projects at the moment.'
        break
      }

      return message
    }

    onPageChangeHandler () {
      this.ProjectBrowseService.updateQueryString()
    }
  }
}

export default ProjectBrowseListComponent
