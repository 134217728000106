import templateUrl from './project-favourite-button.html'
import './project-favourite-button.scss'

const ProjectFavouriteButtonComponent = {
  bindings: {
    project: '<',
    onChange: '&?'
  },
  templateUrl,
  controller: class ProjectFavouriteButtonComponent {
    constructor (ProjectService, EventEmitter) {
      'ngInject'
      this._identify = 'ProjectFavouriteButtonComponent'
      this.ProjectService = ProjectService
      this.EventEmitter = EventEmitter

      this.isProcessing = false
    }

    $onInit () {

    }

    get tooltipText () {
      this.addText = 'Add to favourites'
      this.removeText = 'Remove from favourites'

      return this.project.favoredByCurrentUser ? this.removeText : this.addText
    }

    toggleFavourite ($event) {
      $event.preventDefault()
      $event.stopPropagation()
      console.log('[ProjectFavouriteButtonComponent] > toggleFavourite')

      this.isProcessing = true

      if (this.project.favoredByCurrentUser) {
        this.ProjectService.unfavouriteProject(this.project.id)
          .then(response => {
            this.project.favoredByCurrentUser = false

            if (this.onChange) {
              this.onChange(this.EventEmitter({ projectId: this.project.id, isFavourite: this.project.favoredByCurrentUser }))
            }
          })
          .finally(() => {
            this.isProcessing = false
          })
      } else {
        this.ProjectService.favouriteProject(this.project.id)
          .then(response => {
            this.project.favoredByCurrentUser = true

            if (this.onChange) {
              this.onChange(this.EventEmitter({ projectId: this.project.id, isFavourite: this.project.favoredByCurrentUser }))
            }
          })
          .finally(() => {
            this.isProcessing = false
          })
      }
    }
  }
}

export default ProjectFavouriteButtonComponent
