(function () {
  'use strict';

  function customSubmit() {
    var link = function ($scope, element, attr) {
      element.on('submit', function (e) {
        e.preventDefault();

        // Fix autofill issues where Angular doesn't know about autofilled inputs
        element.find('.autofill-fix').trigger('input').trigger('change').trigger('keydown');

        var form = $scope[attr.name];
        var bypass = false;
        if (form && form.budget && form.budget.$modelValue !== 'custom' && form.exactBudget) {
          form.exactBudget.$setValidity('min', true);
          form.exactBudget.$setValidity('max', true);
        }
        angular.forEach(form, function (formElement, fieldName) {
          if (fieldName[0] === '$') { // angular properties
            return;
          }

          if (formElement.$error) {
            if (formElement.$error.server || formElement.$error.paymill) { // if we have server error, we have already passed all form checkers and we need to post every time, because we can have hidden fields
              bypass = true;
            }
          }

          formElement.$pristine = false;
          formElement.$dirty = true;
          formElement.$submitValue = formElement.$viewValue;
        });

        if (!form.$invalid || bypass === true) {
          $scope.$eval(attr.customSubmit); // Run function that's added as parameter
        }

        var firstInvalid = element[0].querySelector('.ng-invalid');// focus on first error
        if (firstInvalid) {
          element.addClass('submitted');// add class on form for validation
          firstInvalid.focus();
        } else {
          element.removeClass('submitted');// remove class on form for validation
        }

        $scope.$digest();
      });
    };

    return {
      restrict: 'A',
      link: link
    };
  }

  app.directive('customSubmit', customSubmit);
})();
