import templateUrl from './notification-item.html'
import './notification-item.scss'

const NotificationItemComponent = {
  bindings: {
    notification: '<',
    notificationOnaction: '&?'
  },
  templateUrl,
  controller: class NotificationItem {
    constructor (NotificationsService, EventEmitter) {
      'ngInject'
      this._identify = 'NotificationItemComponent'
      this.NotificationsService = NotificationsService
      this.EventEmitter = EventEmitter

      this.isProcessing = false
    }

    $onInit () {
      this.template = this.prepareTemplate()
    }

    get projectUiSref () {
      // TODO: SegmentAnalytics.trackNotificationClicked('page|dropdown', `tasks/[PROJECT_ID]/[workroom]`, notification);
      // TODO determine if redirect needs to be done to estimation room or workroom
      return `tasks.display({ id: ${this.notification.project.id} })`
    }

    get isRead () {
      return this.notification.isRead
    }

    get isNew () {
      return this.notification.isNew
    }

    get isImportant () {
      return this.notification.priority === 'important'
    }

    get isResolved () {
      return this.notification.isResolved
    }

    markAsRead ($event) {
      this.notification.isRead = true
      this.NotificationsService.markAsRead(this.notification.id)
        .then(response => {
          Object.assign(this.notification, response)

          if (this.notificationOnaction) {
            this.notificationOnaction(this.EventEmitter({
              action: 'read',
              notification: this.notification
            }))
          }
        })
    }

    toggleResolve ($event) {
      // Prevent click event from bubbling down to link layer
      if ($event) {
        $event.stopPropagation()
        $event.preventDefault()
      }

      // Prevent action if current one is in currently running
      if (this.isProcessing) {
        return
      }

      this.notification.isResolved = !this.notification.isResolved

      console.log('Toggle Resolve - executed without invoking link redirect!')

      this.isProcessing = true
      this.NotificationsService.toggleResolve(this.notification.id, this.notification.isResolved)
        .then(response => {
          console.log('[NotificationItemComponent] > toggleResolve > response', response)

          // Notify parent component about the action which should update list/dot count etc..
          if (this.notificationOnaction) {
            this.notificationOnaction(this.EventEmitter({
              action: 'resolve',
              notification: this.notification
            }))
          }
        })
        .catch(err => {
          console.log('[NotificationItemComponent] > toggleResolve > err', err)
        })
        .finally(() => {
          this.isProcessing = false
        })
    }

    prepareTemplate () {
      const defaultTemplate = {
        projectTitle: this.notification.project.title,
        date: this.notification.createdAt,
        // ¯\_(ツ)_/¯
        user: this.notification.name === 'subscription_expiring' || this.notification.name === 'subscription_ended' ? {
          avatar: 'https://storage.googleapis.com/avatars-staging.codeable.io/default-avatars/medium.png',
          name: null
        } : {
          avatar: this.notification.owner.avatars.mediumUrl,
          name: this.notification.owner.name
        },
        priority: this.notification.priority,
        tags: []
      }


      if (this.isImportant) {
        // Generate tags
        if (this.notification.project && this.notification.project.preferred) {
          defaultTemplate.tags.push('Preferred')
        }

        // Define action
        defaultTemplate.action = 'resolve'
      }

      // TODO think of better approach of doing this ie put templates into config and override them with special cases

      const templates = {
        // project
        create_task: {
          text: 'posted'
        },
        update_task: {
          text: 'posted'
        },
        cancel_task: {
          text: 'canceled'
        },
        not_hired_expert: {
          text: 'hired another expert for'
        },
        create_estimate: {
          text: 'posted an estimate in'
        },
        create_invitation: {
          text: 'hired you on'
        },
        update_invitation: { // probably not used anymore
          text: 'hired you on'
        },
        publicise_task: {
          text: 'posted'
        },
        create_expert_pods_project: {
          text: 'posted'
        },
        create_project_refund: {
          text: 'issued a refund for'
        },
        complete_task: {
          text: 'completed'
        },
        safecharge_authorized_payment: {
          text: 'paid for'
        },
        paypal_authorized_payment: {
          text: 'paid for'
        },
        stripe_authorized_payment: {
          text: 'paid for'
        },
        referoo_balance_authorized_payment: {
          text: 'paid for'
        },

        // comment
        create_comment: {
          text: 'commented in',
          comment: this.notification.comment ? this.notification.comment.text : null
        },
        update_comment: {
          text: 'updated a comment in',
          comment: this.notification.comment ? this.notification.comment.text : null
        },


        // additional task or retainer
        create_sub_task: {
          text: (this.notification.additionalTask && this.notification.additionalTask.retainer) ? 'created a retainer task in' : 'created an additional task in'
        },
        create_sub_task_refund: {
          text: 'issued a refund for an additional task in'
        },
        complete_sub_task: {
          text: 'completed an additional task in'
        },
        safecharge_authorized_sub_task_payment: {
          text: 'paid an additional task in '
        },
        stripe_authorized_sub_task_payment: {
          text: 'paid an additional task in '
        },
        paypal_authorized_sub_task_payment: {
          text: 'paid an additional task in '
        },
        referoo_balance_authorized_sub_task_payment: {
          text: 'paid an additional task via '
        },


        // retainer specific
        subscription_failed: {
          text: 'failed to pay for a retainer task in'
        },
        subscription_succeeded: {
          text: 'paid a retainer task in'
        },
        subscription_canceled: {
          text: 'canceled a retainer task in'
        },
        expert_paid: {
          text: 'released funds for a retainer task in'
        },
        subscription_renewed: {
          text: 'renewed a retainer task in'
        },
        subscription_expiring: {
          text: 'A retainer is expiring soon in'
        },
        subscription_ended: {
          text: 'A retainer has expired in'
        },


        // review
        create_review: {
          text: 'left a review for'
        },
        update_review: {
          text: 'left a review for'
        },


        // file
        create_attachment: {
          text: 'added a file in'
        },
        destroy_attachment: {
          text: 'deleted a file in '
        },


        // vault
        create_vault: {
          text: 'created a vault in'
        },
        update_vault: {
          text: 'updated the vault in'
        },
        destroy_vault: {
          text: 'deleted the vault in'
        },



        // scope doc
        create_scope_doc_submission: {
          text: 'has submitted a scope for approval on'
        },
        create_scope_doc_rejection: {
          text: 'has rejected your scope on'
        },
        create_scope_doc_approval: {
          text: 'has approved your scope on'
        },
        create_scope_doc_finish: {
          text: 'has finished all work items on'
        },
        create_scope_doc_item_finish: {
          text: 'has marked an item as done on'
        }
      }

      const template = Object.assign(defaultTemplate, templates[this.notification.name])

      return template
    }
  }
}

export default NotificationItemComponent
