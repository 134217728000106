import templateUrl from './project-browse-sidebar.html'
import './project-browse-sidebar.scss'

const ProjectBrowseSidebarComponent = {
  bindings: {},
  templateUrl,
  controller: class ProjectBrowseSidebar {
    constructor ($stateParams, ProjectBrowseService, UserService, ProjectBrowsePodsService) {
      'ngInject'
      this._identify = 'ProjectBrowseSidebarComponent'
      this.$stateParams = $stateParams
      this.ProjectBrowseService = ProjectBrowseService
      this.UserService = UserService
      this.ProjectBrowsePodsService = ProjectBrowsePodsService
    }

    $onInit () {

    }

    get showExpertPodsProjects () {
      return this.ProjectBrowsePodsService.showPodsPage
    }

    get filters () {
      return this.ProjectBrowseService.filters
    }

    get podList () {
      return this.ProjectBrowsePodsService.pods.expertPodsMemberships
    }

    get showFilters () {
      const allowedListIds = ['default', 'expert-pods']
      return allowedListIds.includes(this.$stateParams.listId)
    }

    get isExpertPodsSelected () {
      return this.$stateParams.listId === 'expert-pods'
    }

    onPodFilterChange () {
      this.ProjectBrowseService.updateQueryString()
    }

    onFiltersChange () {
      console.log('[ProjectBrowseSidebarComponent] > onFiltersChange')
      this.ProjectBrowseService.updateQueryString()
      this.closeSidebar()
    }

    onSearchFilter () {
      this.ProjectBrowseService.updateQueryString()
    }

    closeSidebar () {
      this.ProjectBrowseService.closeSidebar()
    }
  }
}

export default ProjectBrowseSidebarComponent
