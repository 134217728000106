(function () {
  'use strict';

  function onclickSelectAll() {
    var link = function ($scope, element) {
      element.on('click', function () {
        element.focus();
        element.select();
      });
    };

    return {
      restrict: 'A',
      link: link
    };
  }

  app.directive('onclickSelectAll', onclickSelectAll);
})();
