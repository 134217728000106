import templateUrl from './settings-notifications.html'
import './settings-notifications.scss'

import formTemplateClientUrl from './settings-notifications.client.html'
import formTemplateExpertUrl from './settings-notifications.expert.html'

const SettingsNotificationsComponent = {
  bindings: {},
  templateUrl,
  controller: class SettingsNotificationsComponent {
    constructor ($timeout, UserService, SettingsService, SoundService, Configuration, UIService) {
      'ngInject'
      this._identify = 'SettingsNotificationsComponent'
      this.$timeout = $timeout
      this.UserService = UserService
      this.UIService = UIService
      this.SettingsService = SettingsService
      this.SoundService = SoundService
      this.Configuration = Configuration
    }


    async $onInit () {
      this.formTemplateUrl = this.UserService.isClient() ? formTemplateClientUrl : formTemplateExpertUrl
      this.formData = {
        sounds: {                       // Clients only
          newProject: null,
          pendingProject: null,
          inprogressProject: null,
        },
        modalTooltipsEnabled: null,     // Experts only
        notificationSoundEnabled: null, // Clients only
        emails: {
          commentsPostedOnProject: null,
          projectNotEstimated: null,
          projectPublished: null
        }
      }

      // Preload sounds for preview for expert
      if (this.UserService.isExpert()) {
        this.soundList.forEach(sound => {
          this.SoundService.initSound(sound.id)
        })
      }

      // Fetch fresh data from BE
      this.isLoading = true
      await this.SettingsService.getNotificationSettings()
        .then(settings => {
          this.formData = { ...this.formData, ...settings }
        })

      this.isLoading = false
      console.log('isLoading', this.isLoading)
      this.$timeout()
    }

    get soundList () {
      return this.Configuration.features.settings.notificationSounds || []
    }


    soundPreview (notificationName) {
      let soundId = this.formData.sounds[notificationName] === 'default' ? 'click' : this.formData.sounds[notificationName]
      if (soundId !== 'none' || typeof soundId !== 'undefined') {
        this.SoundService.playSound(soundId)
      }
    }


    async saveSettings () {
      this.isProcessing = true

      // Experts save settings
      if (this.UserService.isExpert()) {
        // update notifications settings
        await this.SettingsService.updateNotificationSettings({
          sounds: this.formData.sounds,
          emails: this.formData.emails,
          modalTooltipsEnabled: this.formData.modalTooltipsEnabled
        })
      }

      // Clients save settings
      if (this.UserService.isClient()) {
         // update notifications settings
        await this.SettingsService.updateNotificationSettings({
          emails: this.formData.emails,
          notificationSoundEnabled: this.formData.notificationSoundEnabled
        })
      }

      this.isProcessing = false
      this.notificationForm.$setPristine()
      this.$timeout()
    }
  }
}

export default SettingsNotificationsComponent
