const SettingsService = class SettingsService {
  constructor ($http, Configuration, UserService, AuthService, $q, $timeout) {
    'ngInject'
    this._identify = 'SettingsService'
    this.$http = $http
    this.Configuration = Configuration
    this.UserService = UserService
    this.AuthService = AuthService
    this.$q = $q
    this.$timeout = $timeout
  }

  // -------------------------------------------------------
  // Account
  // -------------------------------------------------------

  async getAccount () {
    return this.$http
      .get(`${this.Configuration.apiUrl}/users/me`)
      .then(response => response.data)
  }

  async updateAccount (updatedUser) {
    const payload = { ...updatedUser, profile: {} }

    return this.$http
      .put(`${this.Configuration.apiUrl}/users/me`, payload)
      .then(response => {
        // Update user object in memory and in LS
        this.UserService.updateUser(response.data)
        return response.data
      })
  }

  async getAccountDetails () {
    const apiRoleSlug = this.UserService.apiRoleSlug
    return this.$http
      .get(`${this.Configuration.apiUrl}/${apiRoleSlug}/account_details`)
      .then(response => response.data)
  }

  async updateAccountDetails (formData) {
    const apiRoleSlug = this.UserService.apiRoleSlug
    const payload = { ...formData }

    return this.$http
      .put(`${this.Configuration.apiUrl}/${apiRoleSlug}/account_details`, payload)
      .then(response => {
        // Update auth token if returned in response
        const authToken = response.headers(this.Configuration.auth.tokenHeaderKey)
        if (authToken) {
          this.AuthService.onTokenChangeHandler(authToken)
        }

        // Update user object in memory and in LS
        this.UserService.updateUser(response.data)
        return response.data
      })
  }

  async getEmergencyDetails () {
    return this.$http
      .get(`${this.Configuration.apiUrl}/experts/emergency_contact`)
      .then(response => {
        return response.data
      })

    // const deferred = this.$q.defer()
    // this.$timeout(() => {
    //   // deferred.resolve({ fullName: '', email: '', relationship: undefined, phoneNumber: '' })
    //   deferred.resolve({ exists: false })
    // }, 500)
    // return deferred.promise
  }

  async updateEmergencyDetails (action, formData) {
    const payload = { ...formData }
    return this.$http({
      method: action.method,
      url: `${this.Configuration.apiUrl}/${action.url}`,
      data: payload
    })
      .then(response => {
        return response.data
      })

    // const deferred = this.$q.defer()
    // this.$timeout(() => {
    //   deferred.resolve({ ...formData, exists: true })
    // }, 500)
    // return deferred.promise
  }
  async getEmergencyDetailsRelationshipDataset (resource) {
    return this.$http({
      method: resource.method,
      url: `${this.Configuration.apiUrl}/${resource.url}`
    })
      .then(response => {
        return response.data
      })

    // const deferred = this.$q.defer()
    // this.$timeout(() => {
    //   deferred.resolve([
    //     'parent',
    //     'mother',
    //     'father',
    //     'guardian',
    //     'brother',
    //     'sister',
    //     'sibling',
    //     'son',
    //     'daughter',
    //     'child',
    //     'friend',
    //     'spouse',
    //     'partner',
    //     'coworker',
    //     'housemate',
    //     'family member',
    //     'other'
    //   ])
    // }, 500)
    // return deferred.promise
  }


  async uploadAvatar (formData, progressCallback) {
    const fd = new FormData()
    const config = {
      url: `${this.Configuration.apiUrl}/${this.UserService.apiRoleSlug}/account_details`,
      method: 'PUT',
      transformRequest: angular.identity,
      disableRequestDataInterceptor: true,
      headers: {
        'Content-Type': undefined
      },
      uploadEventHandlers: {
        progress: function (e) {
          if (e.lengthComputable && typeof progressCallback === 'function') {
            console.log('progress', e.loaded, e.total)
            const progressPercentage = Math.round(e.loaded / e.total * 100)
            progressCallback(progressPercentage)
          }
        }
      }
    }

    if (formData.avatar && formData.avatar[0]) {
      fd.append('avatar', formData.avatar[0])
    }
    return this.$http({ ...config, data: fd })
      .then(response => response.data)
  }

  changePassword (formData) {
    const apiRoleSlug = this.UserService.apiRoleSlug
    const payload = { ...formData }

    return this.$http
      .put(`${this.Configuration.apiUrl}/${apiRoleSlug}/account_details`, payload)
      .then(response => {
        // Update auth token if returned in response
        const authToken = response.headers(this.Configuration.auth.tokenHeaderKey)
        if (authToken) {
          this.AuthService.onTokenChangeHandler(authToken)
        }
        return response.data
      })
  }

  // -------------------------------------------------------
  // Notification settings
  // -------------------------------------------------------

  async getNotificationSettings () {
    return this.$http
      .get(`${this.Configuration.apiUrl}/users/me/notification_settings`)
      .then(response => {
        // Convert into FE friendly object, preprocess emails settings because raw BE structure is hard to work with
        if (response.data.settings) {
          response.data.emails = {}
          response.data.settings.forEach(setting => {
            if (setting.key === 'update_task') {
              response.data.emails.projectPublished = setting
            } else if (setting.key === 'daily_digest') {
              response.data.emails.projectNotEstimated = setting
            } else if (setting.key === 'create_comment') {
              response.data.emails.commentsPostedOnProject = setting
            }
          })
          delete response.data.settings
        }
        return response.data
      })
  }

  async updateNotificationSettings (notificationSettings) {
    // Convert back into BE compatible payload
    const emailsArr = []
    Object.entries(notificationSettings.emails).forEach(([key, value]) => {
      emailsArr.push(value)
    })
    const payload = {
      sounds: notificationSettings.sounds,
      settings: emailsArr
    }

    if (typeof notificationSettings.modalTooltipsEnabled !== 'undefined') {
      payload.modalTooltipsEnabled = notificationSettings.modalTooltipsEnabled
    }

    if (typeof notificationSettings.notificationSoundEnabled !== 'undefined') {
      payload.notificationSoundEnabled = notificationSettings.notificationSoundEnabled
    }

    return this.$http
      .put(`${this.Configuration.apiUrl}/users/me/notification_settings`, payload)
      .then(response => {
        // Notification settings are loaded inside user object for the read purpose
        // Update user object in memory and in LS
        this.UserService.updateUser(response.data)
        return response.data
      })
  }

  // -------------------------------------------------------
  // Comms Preferences - client only
  // -------------------------------------------------------

  async getCommsPreferences () {
    return this.$http
      .get(`${this.Configuration.apiUrl}/users/me/preferences`)
      .then(response => {
        // unwrap BE response
        return response.data.preferences.notifications.marketing
      })
  }

  async updateCommsPreferences (commsPreferences) {
    // Convert back into BE compatible payload
    const payload = {
      preferences: {
        notifications: {
          marketing: { ...commsPreferences }
        }
      }
    }

    return this.$http
      .put(`${this.Configuration.apiUrl}/users/me/preferences`, payload)
      .then(response => {
        return response.data
      })
  }

  // -------------------------------------------------------
  // Client Invoices
  // -------------------------------------------------------

  async getInvoicesSettings () {
    return this.$http
      .get(`${this.Configuration.apiUrl}/clients/profile/business_data`)
      .then(response => {
        // Convert to FE friendly structure
        const data = {
          name: response.data.businessName,
          address: response.data.businessAddress,
          city: response.data.businessCity,
          country: response.data.businessCountry,
          entity: response.data.businessEntity,
          businessRegion: response.data.businessRegion,
          vat: response.data.businessVat,
        }
        return data
      })
  }

  async updateInvoicesSettings (invoicesSettings) {
    // Convert back to BE friendly structure
    const payload = {
      businessName: invoicesSettings.name,
      businessAddress: invoicesSettings.address,
      businessCity: invoicesSettings.city,
      businessCountry: invoicesSettings.country,
      businessEntity: invoicesSettings.entity,
      businessRegion: invoicesSettings.businessRegion,
      businessVat: invoicesSettings.vat,
    }

    return this.$http
      .put(`${this.Configuration.apiUrl}/clients/profile/business_data`, payload)
      .then(response => {
        return response.data
      })
  }
}
export default SettingsService
