const TruncateFormatFilter = function ($filter, UtilService) {
  'ngInject'
  return function (text, maxChars, options = {}) {
    const defaultOptions = {
      dots: false,
      splitByWords: false
    }

    options = UtilService.merge(defaultOptions, options)

    const max = parseInt(maxChars, 10)

    if (!text) {
      return '' // do nothing and return empty string
    }

    if (!max || text.length <= max) {
      return text // do nothing and return original text
    }

    let truncatedText = text.substr(0, max)

    if (options.splitByWords) {
      const lastspace = truncatedText.lastIndexOf(' ')

      if (lastspace !== -1) {
        truncatedText = truncatedText.substr(0, Math.min(truncatedText.length, lastspace))
      }
    }

    if (options.dots) {
      truncatedText = truncatedText + ' ...'
    }

    return truncatedText
  }
}
export default TruncateFormatFilter
