import templateUrl from './stars.html'
import './stars.scss'

// This component can be used for display only (value binding) or be also interactive form element (ng-model binding is necessary)

const StarsComponent = {
  bindings: {
    value: '<?',
    readOnly: '<?',
    select: '&'
  },
  require: {
    model: '?^ngModel'
  },
  templateUrl,
  controller: class StarsComponent {
    constructor (EventEmitter) {
      'ngInject'
      this._identify = 'StarsComponent'
      this.EventEmitter = EventEmitter
      this.stars = [{}, {}, {}, {}, {}]
    }

    $onInit () {
      if (this.model) {
        this.model.$overrideModelOptions({
          allowInvalid: true,
          getterSetter: true
        })
        this.model.$render = () => {
          // Do stuff on model change (could be external change)
          // console.log('$render > modelValue', this.modelValue)
          this.markStars(this.roundUpValue(this.modelValue) - 1)
        }
      } else {
        this.markStars(this.roundUpValue(this.value) - 1)
      }
    }

    // Update binding change from parent if initial value biniding is present
    $onChanges (changes) {
      // console.log('$onChanges', changes)
      if (changes.value) {
        this.markStars(this.roundUpValue(changes.value.currentValue) - 1)
      }
    }


    roundUpValue (value) {
      return Math.round(value)
    }


    onSelectStar ($index) {
      this.modelValue = $index + 1
      this.markStars($index)

      // Emit value change to parent
      if (this.select) {
        this.select(this.EventEmitter(this.modelValue))
      }
    }

    markStars ($index) {
      this.stars.forEach((star, index) => {
        if ($index > -1 && $index >= index) {
          star.active = true
        } else {
          star.active = false
        }
      })
    }

    onMouseenter ($index) {
      this.markStars($index)
    }

    onMouseleave () {
      if (this.model) {
        this.markStars(this.roundUpValue(this.modelValue) - 1)
      } else {
        this.markStars(this.roundUpValue(this.value) - 1)
      }
    }

    get modelValue () {
      if (this.model) {
        return this.model.$viewValue
      }
    }

    set modelValue (newValue) {
      if (this.model) {
        this.model.$setViewValue(
          newValue,
          'click'
        )
        this.model.$setDirty()
        this.model.$setTouched()
        this.model.$processModelValue()
      }
    }
  }
}

export default StarsComponent
