import templateUrl from './collapsible.html'
import './collapsible.scss'

const CollapsibleComponent = {
  bindings: {
    isCollapsed: '<'
  },
  transclude: {
    collapsibleBadge: '?collapsibleBadge',
    collapsibleTitle: 'collapsibleTitle',
    collapsibleHelper: '?collapsibleHelper',
    collapsibleContent: 'collapsibleContent',
  },
  templateUrl,
  controller: class CollapsibleComponent {
    constructor () {
      'ngInject'
      this._identify = 'CollapsibleComponent'
    }
  }
}

export default CollapsibleComponent
