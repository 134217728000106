(function () {
  'use strict';

  function segmentAnalytics(SegmentAnalytics) {
    var link = function ($scope, element, attrs) {
      switch (attrs.segmentAnalytics) {
        case 'trackNavOpen':
          element.on('click', function () {
            SegmentAnalytics.trackNavOpen();
          });
          break;
        case 'trackReferralProgramViewed':
          if (attrs.segmentAnalyticsData) {
            element.on('click', function () {
              SegmentAnalytics.trackReferralProgramViewed(attrs.segmentAnalyticsData);
            });
          }
          break;
      }
    };

    return {
      restrict: 'A',
      link: link
    };
  }

  app.directive('segmentAnalytics', ['SegmentAnalytics', segmentAnalytics]);
})();
