import templateUrl from './radio-button-group.html'
import './radio-button-group.scss'

const RadioButtonGroupComponent = {
  bindings: {
    options: '<',
    config: '<',
    name: '@'
  },
  transclude: true,
  require: {
    ngModel: '^ngModel'
  },
  templateUrl,
  controller: class RadioButtonGroup {
    constructor () {
      'ngInject'
      this._identify = 'RadioButtonGroupComponent'

      this._config = null
      this._defaultConfig = {
        type: 'radio-button',
        horizontal: false
      }
    }

    $onInit () {
      this.name = this.name ? this.name : `group-${Math.floor(Math.random() * 10000)}`
      this.updateConfig()
    }

    $onChanges (changes) {
      if (changes.config) {
        this.updateConfig()
      }
    }

    updateConfig () {
      this._config = Object.assign({}, this._defaultConfig, this.config)
    }

    get modelValue () {
      return this.ngModel.$viewValue
    }

    set modelValue (newValue) {
      this.ngModel.$setViewValue(
        newValue,
        'click'
      )
    }
  }
}

export default RadioButtonGroupComponent
