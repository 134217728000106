import templateUrl from './project-subscribe-to-notifications.html'
import './project-subscribe-to-notifications.scss'

const ProjectSubscribeToNotificationsComponent = {
  bindings: {
    project: '<'
  },
  templateUrl,
  controller: class ProjectSubscribeToNotifications {
    constructor (ProjectService, UserService, SegmentAnalytics) {
      'ngInject'
      this._identify = 'ProjectSubscribeToNotificationsComponent'
      this.ProjectService = ProjectService
      this.UserService = UserService
      this.SegmentAnalytics = SegmentAnalytics
      this.isProcessing = false
    }

    toggleSubscriptionNotifications ($event) {
      this.isProcessing = true
      console.log('[ProjectSubscribeToNotificationsComponent] > toggleSubscriptionNotifications', $event, this.project)
      if ($event.newValue) {
        this.UserService.userProjectSubscribe(this.project)
          .then(() => {
            this.SegmentAnalytics.trackWorkroomNotificationsToggled(this.project, 'ON')
          })
          .finally(() => {
            this.isProcessing = false
          })
      } else {
        this.UserService.userProjectUnsubscribe(this.project)
          .then(() => {
            this.SegmentAnalytics.trackWorkroomNotificationsToggled(this.project, 'OFF')
          })
          .finally(() => {
            this.isProcessing = false
          })
      }
    }
  }
}

export default ProjectSubscribeToNotificationsComponent
