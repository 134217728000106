(function () {
  'use strict';

  function postEnter() {
    var link = function ($scope, element) {
      element.on('keydown', function (event) {
        if (event.keyCode === 10 || event.keyCode === 13) {
          if ($scope.postEnterMode) {
            if (!event.ctrlKey && !event.shiftKey) {
              event.preventDefault();

              element.parents('form').trigger('submit');
            }
          } else if (event.ctrlKey || event.shiftKey) {
            event.preventDefault();

            element.parents('form').trigger('submit');
          }
        }
      });
    };

    return {
      scope: {
        postEnterMode: '='
      },
      restrict: 'A',
      link: link
    };
  }

  app.directive('postEnter', postEnter);
})();
