import NotificationsComponent from './notifications.component'
import NotificationsService from './notifications.service'
import NotificationsConfig from './notifications.config'

import NotificationsListModule from './notifications-list'
import NotificationsWidgetModule from './notifications-widget'
import NotificationItemModule from './notification-item'
import NotificationDateSeparatorModule from './notification-date-separator'

const NotificationsModule = angular.module('cdbl.notifications', [
  NotificationsListModule,
  NotificationsWidgetModule,
  NotificationItemModule,
  NotificationDateSeparatorModule
])
  .run((Configuration, EventBusService) => {
    'ngInject'
    // Append notifications configuration to global configuration object
    Configuration.features.notifications = Object.assign({}, Configuration.features.notifications, NotificationsConfig)

    // Initiate notification channel - reserved for Notifications module only
    EventBusService.createChannel('notifications')
  })
  .service('NotificationsService', NotificationsService)
  .component('cdblNotifications', NotificationsComponent)
  .config(($stateProvider) => {
    'ngInject'

    $stateProvider
      .state('notifications', {
        url: '/notifications',
        template: '<cdbl-notifications></cdbl-notifications>',
        abstract: true,
      })

      .state({
        name: 'notifications.list',
        parent: 'notifications',
        url: '/:listId?page',
        params: {
          listId: { squash: true, value: 'default' },
          page: { squash: true, type: 'int', value: 1 }, // dynamic: true to not trigger view reload
        },
        views: {
          'notificationsView': {
            template: '<cdbl-notifications-list></cdbl-notifications-list>'
          }
        },
        resolve: {
          // permissionResolver: (AuthService) => {
          //   'ngInject'
          //   return AuthService.permissionResolver('isExpert')
          // }
        },
        layout: 'new'
      })
  })
  .name

export default NotificationsModule
