import templateUrl from './card.html'
import './card.scss'

const CardComponent = {
  bindings: {},
  templateUrl,
  transclude: {
    header: '?cardHeader',
    footer: '?cardFooter',
    title: '?cardTitle', // default slot for title
    content: '?cardContent', // default slot for description
    contentImage: '?cardContentImage', // default slot for description
    actionsLeft: '?cardActionsLeft',
    actionsRight: '?cardActionsRight'
  },
  controller: class CardComponent {
    constructor () {
      'ngInject'
      this._identify = 'CardComponent'
    }
  }
}

export default CardComponent
