// import IconComponent from './icon.component';
// import IconService from './icon.service';
import './form.scss'
import RadioButtonGroupModule from './radio-button-group'
import StarsModule from './stars'
import CircleSelectModule from './circle-select'
import FieldContainerModule from './field-container'
import CheckboxModule from './checkbox'
import RadioGroupModule from './radio-group'
import RadioModule from './radio'
import FileSelectorModule from './file-selector'
import SwitchModule from './switch'
import ValidationMessageModule from './validation-message'


const FormModule = angular.module('cdbl.form', [
  RadioButtonGroupModule,
  StarsModule,
  CircleSelectModule,
  FieldContainerModule,
  CheckboxModule,
  RadioGroupModule,
  RadioModule,
  FileSelectorModule,
  SwitchModule,
  ValidationMessageModule
])
// .service('IconService', IconService)
// .component('cdblIcon', IconComponent)
  .name

export default FormModule
