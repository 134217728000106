import templateUrl from './expert-profile-reviews.html'
import './expert-profile-reviews.scss'

const ExpertProfileReviewsComponent = {
  bindings: {},
  templateUrl,
  controller: class ExpertProfileReviewsComponent {
    constructor (ExpertProfileViewModalService, PaginationService) {
      'ngInject'
      this._identify = 'ExpertProfileReviewsComponent'
      this.ExpertProfileViewModalService = ExpertProfileViewModalService
      this.isLoading = false
      this.PaginationService = PaginationService
      this.pagination = this.PaginationService.init({
        itemsPerPage: 10,
        mode: 'server'
      })
    }

    onPageChangeHandler (page) {
      this.ExpertProfileViewModalService.fetchReviews(page).then(res => {
        this.reviews = res.data
      })
    }

    $onInit () {
      this.pagination.updateFromHeaders(this.ExpertProfileViewModalService.reviewsResponse)
      this.reviews = this.ExpertProfileViewModalService.reviewsResponse.data
    }

    responsivnessValue (review) {
      return review.responsivness ? review.responsivness.value : review.score.value
    }

    communicationValue (review) {
      return review.communication ? review.communication.value : review.score.value
    }

    showProfileItems () {
      return !this.loading &&
              this.reviews &&
              this.reviews.length === 0
    }
  }
}

export default ExpertProfileReviewsComponent
