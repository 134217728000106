import templateUrl from './content.html'
import './content.scss'

const ContentComponent = {
  bindings: {

  },
  transclude: true,
  templateUrl,
  controller: class ContentComponent {
    constructor () {
      'ngInject'
      this._identify = 'ContentComponent'
    }

    $onInit () {

    }
  }
}

export default ContentComponent
