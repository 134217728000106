import AdditionalTasksService from './additional-tasks.service'

import AdditionalTasksSidebarWidgetModule from './sidebar-widget'
import AdditionalTasksViewModalModule from './view-modal'
import AdditionalTasksCreateModalModule from './create-modal'

const AdditionalTasksModule = angular.module('cdbl.additional-tasks', [
  AdditionalTasksSidebarWidgetModule,
  AdditionalTasksViewModalModule,
  AdditionalTasksCreateModalModule
])
  .service('AdditionalTasksService', AdditionalTasksService)
  .name

export default AdditionalTasksModule
