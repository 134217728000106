import jsonInflector from 'json-inflector'

const CLIENT_DEFAULT_LIST = 'all'
const EXPERT_DEFAULT_LIST = 'in-progress'

const ProjectDashboardService = class ProjectDashboardService {
  constructor ($http, $location, $stateParams, $httpParamSerializer, Configuration, UserService, PaginationService) {
    'ngInject'
    this.$http = $http
    this.$location = $location
    this.$stateParams = $stateParams
    this.$httpParamSerializer = $httpParamSerializer
    this.Configuration = Configuration
    this.UserService = UserService
    this.PaginationService = PaginationService

    this._filters = null
    this._pagination = null
    this._projectStats = {} // default projectStats
  }

  init () {
    this._filters = {
      basic: {
        estimated: false,
        commented: false,
      }
    }

    this._pagination = this.PaginationService.init({
      itemsPerPage: 10,
      mode: 'server'
    })

    // Process basic filters from URL
    Object.entries(this._filters.basic).forEach(([key, value]) => {
      if (this.$stateParams[key]) {
        this._filters.basic[key] = this.$stateParams[key]
      }
    })

    // Process pagination from URL
    if (this.$stateParams['page']) {
      // console.log('init > page', this.$stateParams['page'])
      this._pagination.setCurrentPage(this.$stateParams['page'])
    }
  }

  get filters () {
    return this._filters
  }

  get pagination () {
    return this._pagination
  }

  updateQueryString ($event) {
    console.log('[ProjectDashboardService] > $event', $event)

    // update the query string with the new filters
    Object.entries(this._filters.basic).forEach(([key, value]) => {
      if (this._filters.basic[key]) {
        this.$location.search(key, value)
      } else {
        this.$location.search(key, null) // remove empty filter
      }
    })

    // update the query string with pagination
    if (this._pagination.currentPage !== this._pagination.firstPage) {
      this.$location.search('page', this._pagination.currentPage)
    } else {
      this.$location.search('page', null) // remove default page
    }
  }

  getProjectList () {
    let listId = this.$stateParams.listId

    // set default listId for client and expert
    if (typeof listId === 'undefined' || listId === 'default') {
      listId = this.UserService.isClient() ? CLIENT_DEFAULT_LIST : EXPERT_DEFAULT_LIST
    }

    // listId = jsonInflector.transform(listId, 'underscore')
    console.log('getProjectList', 'page', this._pagination.currentPage)
    const params = this._pagination ? {
      page: this._pagination.currentPage,
      per_page: this._pagination.itemsPerPage
    } : {}

    if (this.$stateParams && this.$stateParams.commented) {
      params.filter = 'commented'
    }

    if (this.$stateParams && this.$stateParams.estimated) {
      params.filter = 'estimated'
    }

    return this.$http.get(`${this.Configuration.apiUrl}/users/me/tasks/${listId}`, { params })
      .then(response => {
        if (this._pagination && typeof this._pagination.updateFromHeaders === 'function') {
          this._pagination.updateFromHeaders(response)

          // Autocorrect page param in url to match header page count without reloading view
          if (this.$stateParams.page > 1 && this.$stateParams.page > this._pagination.pageCount) {
            this.$location.search('page', Math.max(1, this._pagination.pageCount))
            this.$location.replace()
          }
        }
        return response.data
      })
  }

  // Get client or expert project stats
  getProjectStats () {
    return this.$http.get(`${this.Configuration.apiUrl}/${this.UserService.apiRoleSlug}/me/projects/stats`)
      .then(response => {
        const tmp = jsonInflector.transform(response.data, 'underscore')
        this._projectStats = jsonInflector.transform(tmp, 'dasherize')
        return this._projectStats
      })
  }

  // Get client or expert revenue stats
  getRevenueStats () {
    return this.$http.get(`${this.Configuration.apiUrl}/${this.UserService.apiRoleSlug}/me/revenues/stats`)
      .then(response => {
        return response.data
      })
  }


  // Expert toggle favourite project
  updateProjectStats (statName, $event) {
    console.log('[ProjectDashboardService] > updateProjectStats', statName, $event)
    if (typeof this._projectStats[statName] === 'undefined') {
      return
    }

    if (statName === 'favourites') {
      if ($event.isFavourite) {
        this._projectStats[statName] = this._projectStats[statName] + 1
      } else {
        this._projectStats[statName] = Math.max(0, this._projectStats[statName] - 1)
      }
    }
  }
}
export default ProjectDashboardService
