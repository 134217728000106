(function () {
  'use strict';

  function tabset($rootScope, $parse, Modal) {
    var link = function ($scope, element, attrs) {
      $scope.tabs = $parse(attrs.tabs)($scope);
      $scope.hideNavs = 'hideNavs' in attrs;
      var nextTab = null;

      function getCurrentIndex() {
        return _.findIndex($scope.tabs, function (obj) {
          return obj.active === true;
        });
      }

      function goToTab(index) {
        let activeTabIndex = $scope.tabs.findIndex(tab => {
          return tab.active === true;
        });

        $scope.tabs[activeTabIndex].active = false;
        $scope.tabs[index].active = true;
        if ($scope.tabs[activeTabIndex].destroyed) {
          $scope.tabs[activeTabIndex].destroyed();
        }
        if ($scope.tabs[index].callback) {
          $scope.tabs[index].callback();
        }

        $scope.sidebarTitle = $scope.tabs[index].name;
      }

      $scope.changeTab = function (index) {
        var currentIndex = getCurrentIndex();

        if ($scope.tabs[currentIndex].isValid && !$scope.tabs[currentIndex].isValid()) {
          nextTab = index;
          Modal.open('views/modals/changes_tab.html', $scope, {closeButton: false});
          return;
        }

        goToTab(index);
      };

      $scope.continueModal = function () {
        var currentIndex = getCurrentIndex();

        if ($scope.tabs[currentIndex].discard) {
          $scope.tabs[currentIndex].discard();
        }

        goToTab(nextTab);
        nextTab = null;

        Modal.close();
      };

      $scope.cancelModal = function () {
        nextTab = null;
        Modal.close();
      };

      $rootScope.$broadcast('tabsetLoaded');
    };

    return {
      restrict: 'E',
      replace: true,
      templateUrl: require('../../views/directives/tabset.html'),
      link: link
    };
  }

  app.directive('tabset', ['$rootScope', '$parse', 'Modal', tabset]);
})();
