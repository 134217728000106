import templateUrl from './modal.html'
import './modal.scss'

const ModalComponent = {
  templateUrl,
  controller: class Modal {
    constructor ($window, $element, ModalService, UtilService) {
      'ngInject'
      this._identify = 'ModalComponent'
      this.$window = $window
      this.$element = $element
      this.UtilService = UtilService
      this.ModalService = ModalService
    }

    $onInit () {

    }

    $onChanges (changes) {
      console.log('[ModalComponent] > $onChanges', changes)
    }

    $onDestroy () {

    }

    get showActions () {
      return this.modal.actions.length || this.modal.rejectButton || this.modal.resolveButton
    }

    get classList () {
      return `
        cdbl-modal--style-with-dividers
        cdbl-modal--style-with-sticky-header-and-footer
        cdbl-modal--size-${this.modal.size ? this.modal.size : 'default'}
        cdbl-modal--layout-${this.modal.layout ? this.modal.layout : 'default'}
        ${this.modal.class ? this.modal.class : ''}
      `
    }

    get modal () {
      const state = this.ModalService.getModal()
      if (state) {
        this.$window.document.addEventListener('touchmove', this.preventEventLeak)
      } else {
        this.$window.document.removeEventListener('touchmove', this.preventEventLeak)
      }
      return state
    }

    modalClose () {
      console.log('ModalComponent > modalClose')
      this.ModalService.close()
    }

    close () {
      this.modalClose()
    }

    resolve () {
      this.ModalService.resolve()
    }

    reject () {
      this.ModalService.reject()
    }

    modalNext () {
      console.log('ModalComponent > modalNext TODO')
    }

    modalPrevious () {
      console.log('ModalComponent > modalPrevious TODO')
    }

    modalCloseBackdrop ($event) {
      console.log('ModalComponent > modalCloseBackdrop', this.modal.closeOnBackdrop, $event)
      $event.preventDefault()
      $event.stopPropagation()

      // close only if click occured on backdrop and closeOnBackdrop is enabled
      if (this.modal.closeOnBackdrop && $event.target.classList.contains('cdbl-modal__backdrop')) {
        this.modalClose()
      }
    }

    preventEventLeak (event) {
      let isTouchMoveAllowed = true
      let target = event.target

      while (target !== null) {
        if (target.classList && target.classList.contains('.app-wrapper')) {
          isTouchMoveAllowed = false
          break
        }
        target = target.parentNode
      }

      if (!isTouchMoveAllowed) {
        event.preventDefault()
        event.stopPropagation()
      }
    }
  }
}

export default ModalComponent
