import templateUrl from './submission-progressbar.html'
import './submission-progressbar.scss'

const SubmissionProgressbarComponent = {
  bindings: {
    data: '<',
    step: '<'
  },
  require: {
    parent: '^cdblSubmission'
  },
  templateUrl,
  controller: class SubmissionProgressbar {
    constructor ($timeout, IntercomService, SegmentAnalytics, Modal, ModalService) {
      'ngInject'
      this._identify = 'SubmissionProgressbarComponent'
      this.IntercomService = IntercomService
      this.segmentAnalytics = SegmentAnalytics
      this.Modal = Modal
      this.ModalService = ModalService
      this.progressbar = {
        next: 'REVIEW PROJECT',
        active: 'PROJECT INFORMATION',
        step_one: {
          title: 'PROJECT TYPE',
          completed: true
        },
        step_two: {
          title: 'PROJECT INFORMATION',
          completed: false,
          check_list: []
        },
        step_three: {
          title: 'POST PROJECT',
          completed: false
        }
      }
    }

    $onInit () {
      // let vm = this
      // this.$scope.$watch(function () {
      //   const tmp = angular.copy(vm.data)
      //   delete tmp.attachments
      //   delete tmp.queuedattachments

      //   return tmp
      // }, () => {
      //   this.setCheckList(vm.data.project_type)
      //   this.setProgress()
      // }, true)

      // this.$scope.$watch(function () {
      //   return vm.step
      // }, () => {
      //   this.setStep()
      //   this.setCheckList(vm.data.project_type)
      //   this.setProgress()
      // }, true)
    }

    $onChanges (changesObj) {
      console.log('SubmissionProgressbarComponent> $onChanges', changesObj)
      this.setCheckList(changesObj.data.currentValue.project_type)
      if (changesObj.step.currentValue === 'two') {
        this.setStep()
      }
    }

    get isTitleCompleted () {
      return this.data.title !== ''
    }

    get progress () {
      return this.setProgress()
    }

    /**
     * Set Checklist
     * @param {string} project_type project type
     */
    setCheckList (project_type) {
      if (project_type === 'consultation') {
        this.progressbar.step_two.title = 'CONSULTATION INFORMATION'
        this.progressbar.step_three.title = 'POST'
        this.progressbar.step_two.check_list = [
          {
            id: 'title',
            title: 'Title',
            completed: () => {
              return this.data.title !== ''
            }
          },
          {
            id: 'description',
            title: 'Description',
            completed: () => {
              return this.data.description !== ''
            }
          }
        ]
      } else {
        this.progressbar.step_two.title = 'PROJECT INFORMATION'
        this.progressbar.step_three.title = 'POST PROJECT'
        this.progressbar.step_two.check_list = [
          {
            id: 'title',
            title: 'Project title',
            completed: () => {
              return this.data.title !== ''
            }
          },
          {
            id: 'preferredContractors',
            title: 'Preferred expert',
            completed: () => {
              return this.data.preferredContractors && this.data.preferredContractors.selected.length
            }
          },
          {
            id: 'urgency',
            title: 'Urgency',
            completed: () => {
              return this.data.urgency !== '' && this.data.urgency !== null
            }
          },
          {
            id: 'description',
            title: 'Project description',
            completed: () => {
              return this.data.description !== ''
            }
          },
          {
            id: 'budget',
            title: 'Budget',
            completed: () => {
              return typeof this.data.budget !== 'undefined' && this.data.budget !== null
            }
          }
        ]
      }
    }
    /**
     * Set step
     * Set progressbar active and next steps
     */
    setStep () {
      this.progressbar.active = this.progressbar.step_two.title
      this.progressbar.next = this.progressbar.step_three.title
    }

    /**
     * Set Progress
     */
    setProgress () {
      // Default progress value
      let defaultProgress = 20
      // Progress Step value
      let progressStepValue = this.data.project_type === 'consultation' ? 40 : 20
      const defaultProperties = ['title', 'urgency', 'description', 'budget', 'preferredContractors']
      const consultationProperties = ['title', 'description']
      if (this.data.project_type === 'consultation') {
        return this.calculateProgress(defaultProgress, progressStepValue, consultationProperties)
      } else {
        return this.calculateProgress(defaultProgress, progressStepValue, defaultProperties)
      }
    }

    /**
     * Calculate progress
     * @param  {[type]} defaultProgress   [description]
     * @param  {[type]} progressStepValue [description]
     * @param  {[type]} properties        [description]
     * @return {[type]}                   [description]
     */
    calculateProgress (defaultProgress, progressStepValue, properties) {
      // Loop through task
      properties.forEach(property => {
        const checklist = this.progressbar.step_two.check_list
        let index = checklist.findIndex(item => {
          return item.id === property
        })

        if (index === -1) {
          return
        }

        if (this.data[property] && property !== 'preferredContractors') {
          defaultProgress += progressStepValue
        }
      })
      return defaultProgress
    }

    askForHelp (medium) {
      this.IntercomService.showMessage('Hi! I would like to post a new project and I need help.')
      let step = 2
      if (this.step === 'three') {
        step = 3
      }
      this.segmentAnalytics.trackIntercomOpened('step' + step, medium)
    }

    openIntroVideoModal () {
      // this.Modal.open('views/modals/submission-instruction-video.html', {}, {closeButton: true});
      this.ModalService.open({
        closeOnBackdrop: true,
        template: `<cdbl-submission-instruction-modal></cdbl-submission-instruction-modal>`
      })
    }
  }
}

export default SubmissionProgressbarComponent
