const TransactionsService = class TransactionsService {
  constructor ($http, $location, $stateParams, $httpParamSerializer, $filter, Configuration, UserService, PaginationService, UIService) {
    'ngInject'
    this.$http = $http
    this.$location = $location
    this.$stateParams = $stateParams
    this.$httpParamSerializer = $httpParamSerializer
    this.$filter = $filter
    this.Configuration = Configuration
    this.UserService = UserService
    this.PaginationService = PaginationService
  }

  getTransactionTypes () {
    // client types
    if (this.UserService.isClient()) {
      return [
        { name: 'Task payments', value: ['client_deposit', 'offline_client_deposit'] },
        { name: 'Refunds', value: ['full_refund_keep_fee', 'full_refund', 'partial_refund'] }
      ]
    }
    // expert types
    return [
      { name: 'Task completions', value: ['task_completion'] },
      { name: 'Refunds', value: ['partial_refund'] },
      { name: 'Compensations by Codeable', value: ['ad_hoc_expert_credit'] },
      { name: 'Deductions by Codeable', value: ['ad_hoc_expert_debit'] },
      { name: 'Team transfers', value: ['ad_hoc_team_work'] },
      { name: 'Withdrawals', value: ['expert_withdrawal_request', 'contractor_withdrawal', 'cancel_expert_withdrawal_request'] },
    ]
  }

  getStats () {
    return this.$http.get(`${this.Configuration.apiUrl}/${this.UserService.apiRoleSlug}/me/revenues/stats`)
      .then(response => response.data)
  }

  prepareFilterPayload (pagination, filterData) {
    let params = {}

    if (pagination) {
      params = {
        ...params,
        page: pagination.currentPage,
        per_page: pagination.itemsPerPage
      }
    }

    const processDateTimezoneOffset = (dateString) => {
      const date = new Date(dateString)
      const offset = date.getTimezoneOffset() * 60000 //
      return new Date(date.getTime() - offset).toISOString()
    }
    const userParamName = `${this.UserService.isClient() ? 'expert' : 'client'}_id_eq`
    if (filterData) {
      const q = {
        created_at_gteq: filterData.dateFrom ? processDateTimezoneOffset(filterData.dateFrom) : null,
        created_at_lteq: filterData.dateTo ? processDateTimezoneOffset(filterData.dateTo).replace('T00:00:00.000Z', 'T23:59:59.999Z') : null,
        project_id_eq: filterData.project ? filterData.project.id : null,
        description_in: filterData.transactionType ? filterData.transactionType.value : null,
        s: filterData.sort ? filterData.sort : null
      }
      q[userParamName] = filterData.user ? filterData.user.id : null

      params = {
        ...params,
        q
      }
    }

    return params
  }

  getTransactions (pagination = null, filterData = null) {
    const params = this.prepareFilterPayload(pagination, filterData)

    console.log('[TransactionsService] > getTransactions > params', params)
    return this.$http.get(`${this.Configuration.apiUrl}/${this.UserService.apiRoleSlug}/transactions`, { params })
      .then(response => {
        if (pagination) {
          pagination.updateFromHeaders(response)
        }
        console.log('[TransactionsService] > getTransactions > response.data', response.data)
        return response.data
      })
  }


  downloadInvoice (transaction = null) {
    if (transaction === null) {
      return
    }

    return this.$http({
      url: `${this.Configuration.apiUrl}/${transaction.actions.readInvoice.url}`,
      method: transaction.actions.readInvoice.method
    })
      .then(response => response.data)
  }


  exportCSV (pagination = null, filterData = null) {
    const params = this.prepareFilterPayload(pagination, filterData)

    return this.$http.post(`${this.Configuration.apiUrl}/${this.UserService.apiRoleSlug}/transactions/export`, params)
      .then(response => {
        const fileName = `Transactions_${this.$filter('date')(new Date(), 'yyyyMMddHHmmss')}.csv`
        const fileType = response.headers('content-type') + ';charset=utf-8'
        const blob = new Blob([response.data], { type: fileType })

        const objectUrl = window.URL || window.webkitURL
        const link = angular.element('<a/>')

        console.log('[TransactionsService] > exportCSV > response', fileName, fileType)

        link.css({ display: 'none' })
        link.attr({
          href: objectUrl.createObjectURL(blob),
          target: '_blank',
          download: fileName
        })
        link[0].click()
        // clean up
        link.remove()
        objectUrl.revokeObjectURL(blob)
        return true
      })
  }
}
export default TransactionsService
