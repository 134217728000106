import templateUrl from './sidebar-widget.html'
import './sidebar-widget.scss'

const ProjectTeamSidebarWidgetComponent = {
  bindings: {
    project: '<'
  },
  templateUrl,
  controller: class ProjectTeamSidebarWidget {
    constructor (ProjectTeamService, UserService, ModalService) {
      'ngInject'
      this._identify = 'ProjectTeamSidebarWidgetComponent'
      this.ProjectTeamService = ProjectTeamService
      this.UserService = UserService
      this.ModalService = ModalService
    }

    $onInit () {
      this.team = null
      this.isLoading = false

      // Load team for expert and client
      this.loadTeam()
    }

    loadTeam () {
      // load team data
      this.isLoading = true
      this.ProjectTeamService.fetchTeam(this.project.id)
        .then(response => {
          this.team = response
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    get hasTeamMembers () {
      return this.team && this.team.teamMembers.length
    }

    updateMembers ($event) {
      this.team.teamMembers.push(...$event.members)
    }

    onRemoveMember ($event) {
      console.log('[ProjectTeamSidebarWidgetComponent] > onRemoveMember', $event)
      if ($event && $event.member) {
        const memberIndex = this.team.teamMembers.findIndex(member => member.id === $event.member.id)
        console.log('[ProjectTeamSidebarWidgetComponent] > onRemoveMember > memberIndex', memberIndex)
        if (memberIndex > -1) {
          this.team.teamMembers.splice(memberIndex, 1)
        }
      }
    }

    onPaymentCreated ($event) {
      console.log('[ProjectTeamSidebarWidgetComponent] > onPaymentCreated > 1')
      // update member who recevied payment
      if ($event.payment) {
        const member = this.team.teamMembers.find(m => m.id === $event.payment.teamMember.id)
        if (member) {
          Object.assign(member, $event.payment.teamMember)
          console.log('[ProjectTeamSidebarWidgetComponent] > onPaymentCreated > 2')
        }
      }
    }


    openManageModal () {
      const vm = this
      this.ModalService.open({
        closeOnBackdrop: true,
        closeIcon: true,
        layout: 'center',
        class: 'project-team-manage-modal',
        template: `<cdbl-project-team-manage-modal project="$ctrl.modal.project" team="$ctrl.modal.team" on-members-added="$ctrl.modal.updateMembers($event)"></cdbl-project-team-manage-modal>`,
        project: this.project,
        team: this.team,
        updateMembers: $event => {
          vm.updateMembers($event)
        }
      })
    }

    openPaymentsModal () {
      const vm = this
      this.ModalService.open({
        closeOnBackdrop: true,
        closeIcon: true,
        class: 'project-team-payments-modal',
        template: `<cdbl-project-team-payments-modal project="$ctrl.modal.project" team="$ctrl.modal.team" on-payment-created="$ctrl.modal.onPaymentCreated($event)"></cdbl-project-team-payments-modal>`,
        project: this.project,
        team: this.team,
        onPaymentCreated: $event => {
          vm.onPaymentCreated($event)
        }
      })
    }
  }
}

export default ProjectTeamSidebarWidgetComponent
