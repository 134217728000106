const toUserTimezoneFilter = function (moment, UserService) {
  'ngInject'
  return function (offset, format = 'short') {
    switch (format) {
    case 'short':
      format = 'ddd, hh:mma'
      break
    case 'utcOffsetInHours':
      // Handle offset undefined by returning empty string
      if (typeof offset === 'undefined' || offset === null) {
        return ''
      }

      let prefix = offset > 0 ? '-' : '+'
      return `UTC ${prefix}${Math.abs(Math.round(offset / 60))}`
    }

    if (typeof offset === 'undefined' || offset === null) {
      return 'Unknown'
    }

    // const d = new Date()
    // const utc = d.getTime() + (d.getTimezoneOffset() * 60000)
    // const nd = new Date(utc - (60000 * offset))
    // return moment(nd).format(format)

    return moment().utcOffset(offset * -1).format(format)
  }
}
export default toUserTimezoneFilter
