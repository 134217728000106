// import PaymentsComponent from './payments.component';
import PaymentsService from './payments.service'

import PaymentModalModule from './payment-modal'
import PaymentsPaypalButtonModule from './payments-paypal-button'
import PaymentsStripeButtonModule from './payments-stripe-button'

const PaymentsModule = angular.module('cdbl.payments', [
  uiRouter,
  PaymentModalModule,
  PaymentsPaypalButtonModule,
  PaymentsStripeButtonModule
])
// .config($stateProvider => {
//   'ngInject';
//   $stateProvider
//     .state('payments', {
//       url: '/payments',
//       template: '<cdbl-payments></cdbl-payments>'
//     });
// })
  .service('PaymentsService', PaymentsService)
// .component('cdblPayments', PaymentsComponent)
  .name

export default PaymentsModule
