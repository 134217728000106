(function () {
  'use strict';

  var dropdown = function ($document, $compile, Tether) {
    var link = function ($scope, element, attrs) {
      $scope.partial = attrs.partial;

      var dropdownMenu = angular.element('<div><div ng-include="partial"></div></div>');
      $compile(dropdownMenu)($scope);

      var tether = new Tether({
        element: dropdownMenu[0],
        target: element,
        attachment: attrs.attach ? attrs.attach : 'top right',
        targetAttachment: attrs.targetAttach ? attrs.targetAttach : 'bottom right',
        targetOffset: attrs.targetOffset ? attrs.targetOffset : '0 0'
      });

      $scope.$watch('active', function () {
        // console.log('active chg! + reposition');
        tether.position();
      });

      $scope.close = function () {
        if ($scope.active) {
          $scope.active = false;
        }
      };

      $scope.$on('$stateChangeStart', function () {
        // console.log('state change!');
        if (attrs.persistent) {
          $scope.active = false;
          dropdownMenu.find('.module-dropdown').addClass('ng-hide'); // if we logout we need to "manually" hide it
        } else {
          tether.destroy();
          dropdownMenu.remove();
        }
      });

      // tether.position();
    };

    return {
      restrict: 'A',
      scope: true,
      link: link
    };
  };

  app.directive('dropdown', ['$document', '$compile', 'Tether', dropdown]);
})();
