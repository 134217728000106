import templateUrl from './estimation-task-reminder-modal.html'
import './estimation-task-reminder-modal.scss'

const EstimationTaskReminderModalComponent = {
  bindings: {
    project: '<?',
    currentUser: '<?',
    estimate: '<?',
    estimationConfig: '<?',
    modalEstimate: '<?',
    onCreate: '&?'
  },
  require: {
    modalCtrl: '^^cdblModal'
  },
  templateUrl,
  controller: class EstimationTaskReminderModalComponent {
    constructor ($scope, $http, _, Configuration, UserService, EventEmitter) {
      'ngInject'
      this.$scope = $scope
      this.$http = $http
      this._ = _
      this.Configuration = Configuration
      this.UserService = UserService
      this.EventEmitter = EventEmitter

      this.currentUser = this.UserService.user
    }

    $onInit () {
      this.fetchEstimation(this.estimate.amount)
    }

    fetchEstimation (amount) {
      if ((amount && this.currentUser.modalTooltipsEnabled) || (amount && this.project.estimatesCount >= 4)) {
        this.$http.get(`${this.Configuration.apiUrl}/tasks/${this.project.id}/estimates/calculate?amount=${amount}`).then(result => {
          if (result.data) {
            this.modalEstimate.prices = result.data.prices
          }
        })
      } else {
        this.modalEstimate.prices = {}
      }
    }

    create () {
      this.onCreate(this.EventEmitter(this.estimate.amount))
    }
  }
}

export default EstimationTaskReminderModalComponent
