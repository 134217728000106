// Used for creating individual error handlers that can be then rendered by error component
// It could also be implemented by using event bus and broadcasting error from other api calls
//
const ErrorsService = class ErrorsService {
  constructor (ApiLogging) {
    'ngInject'
    this.ApiLogging = ApiLogging
  }

  createErrorHandler () {
    return new ErrorHandler()
  }
}


class ErrorHandler {
  constructor (config) {
    this._errorList = []
    this.aborted = false
  }

  get count () {
    return this._errorList.length
  }

  get list () {
    return this._errorList
  }

  stringToErrorObject (stringMessage) {
    return {
      message: stringMessage
    }
  }

  // replaces all errors with current one and sets aborted flag so where in use it can be used to abort further logic and reset form or redirect
  abort (errObject) {
    this.aborted = true
    this.add(errObject)
    console.log('ErrorHandler > aborted', errObject)
  }

  add (errObject) {
    this._errorList.length = 0

    if (typeof errObject === 'string') {
      errObject = [ this.stringToErrorObject(errObject) ]
    }

    if (Array.isArray(errObject)) {
      this._errorList.push(...errObject)
    } else if (errObject.data && errObject.data.errors) { // unwrap err object from request and unwrap it
      this._errorList.push(...errObject.data.errors)
    } else if (!errObject.data && errObject.xhrStatus) {
      // ignore third party errors (ie aborted file upload to gcs)
    } else if (errObject.message) {
      this._errorList.push(errObject)
    } else {
      console.error('errObject is invalid, no errors could be extracted', errObject)
    }
  }

  reset () {
    this.aborted = false
    this._errorList.length = 0
  }
}


export default ErrorsService
