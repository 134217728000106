import templateUrl from './scope-doc-edit-form.html'
import './scope-doc-edit-form.scss'

const ProjectScopeDocEditFormComponent = {
  bindings: {
    scopeDoc: '<',
    project: '<',
    onUpdate: '&',
    onEditFormInit: '&onInit'
  },
  templateUrl,
  controller: class ProjectScopeDocEditForm {
    constructor (ProjectScopeDocService, ProjectScopeDocItemFactory, EventEmitter) {
      'ngInject'
      this._identify = 'ProjectScopeDocEditFormComponent'
      this.ProjectScopeDocService = ProjectScopeDocService
      this.EventEmitter = EventEmitter
    }

    $onInit () {
      this.templates = {
        options: [
          this.ProjectScopeDocService.generateBlankTemplate()
        ],
        selected: null,
        newTemplateName: '',
        isUpdateExisting: false,
        showResetConfirmation: false
      }

      this.sortableConfig = {
        animation: 150,
        group: {
          name: 'nested',
          pull: function (evt1, evt2, el, evt4, evt5) {
            if (el.classList.contains('draggable-item--has-children')) {
              return false
            }
            return true
          }
        },
        chosenClass: 'draggable-item--selected',
        draggable: '.draggable-item',
        handle: '.draggable-item__handle',
        swapThreshold: 0.65,
        onAdd: this.onAddSortableHandler.bind(this),
        onStart: this.onStartSortableHandler.bind(this),
        onUpdate: this.onUpdateSortableHandler.bind(this)
      }

      if (this.onEditFormInit) {
        this.onEditFormInit(this.EventEmitter({
          getFormCtrl: function () {
            return this.editForm
          }.bind(this),
          setFormPristine: function () {
            this.setFormPristine()
          }.bind(this),
          isFormReady: function () {
            return !this.isLoading
          }.bind(this)
        }))
      }

      // load templates
      this.isLoading = true
      this.ProjectScopeDocService.getTemplatesList()
        .then(response => {
          this.templates.options = [ ...this.templates.options, ...response ]

          const selectedTemplate = this.templates.options.find(t => t.id === this.scopeDoc.templateId)
          if (selectedTemplate) {
            this.templates.selected = selectedTemplate
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    addNewItem (item, parent) {
      this.scopeDoc.addNewItem(item, parent)
      this.editForm.$setDirty()
    }
    // Remove item with confirmation
    promptRemoveItemConfirmation (item) {
      item.toggleRemoveItemConfirmation()
    }
    cancelRemoveItemConfirmation (item) {
      item.toggleRemoveItemConfirmation()
    }
    removeItem (item) {
      this.scopeDoc.removeItem(item)
      this.editForm.$setDirty()
    }
    //

    onAddSortableHandler (evt) {
      // console.log('onAddSortableHandler', evt)
      const item = evt.model
      const parent = this.scopeDoc.findParentOfItem(item)
      if (parent) {
        // item was added as child update the child item parent id
        item.assignParent(parent.id)
      } else {
        item.unassignParent()
      }

      this.editForm.$setDirty()
    }

    onStartSortableHandler (evt) {
      console.log('onStartSortableHandler', evt)
    }

    onUpdateSortableHandler (evt) {
      console.log('onUpdateSortableHandler', evt)
      this.editForm.$setDirty()
      this.triggerScopeDocUpdate()
    }

    triggerScopeDocUpdate () {
      if (this.onUpdate) {
        this.onUpdate(this.EventEmitter({ scopeDoc: this.scopeDoc }))
      }
    }

    setFormPristine () {
      console.log('[ProjectScopeDocEditFormComponent] > setFormPristine')
      this.wasSortableChanged = false
      this.editForm.$setPristine()
    }

    // Template reset with confirmation
    promptResetToDefaultTemplate () {
      this.templates.showResetConfirmation = true
    }
    cancelResetToDefaultTemplate () {
      this.templates.showResetConfirmation = false
    }
    resetToDefaultTemplate () {
      this.templates.showResetConfirmation = false
      this.applySelectedTemplate()
    }
    // ---


    resetToBlank () {
      console.log('resetToBlank')
      this.scopeDoc = this.ProjectScopeDocService.generateScopeDoc('blank')
      this.templates.selected = null
      this.templates.isUpdateExisting = false
      this.setFormPristine()
      this.triggerScopeDocUpdate()
    }

    applySelectedTemplate () {
      if (this.templates.selected !== null) {
        this.scopeDoc = this.ProjectScopeDocService.processScopeDocResetIds({
          ...this.templates.selected,
          templateId: this.templates.selected.id
        })
        this.triggerScopeDocUpdate()
        this.setFormPristine()
      } else {
        this.resetToBlank()
      }

      // update new name for template if selecting or deselecting template
      this.onChangeTemplateSaveMode()
    }

    onTemplateSelect ($event) {
      console.log('onTemplateSelect', this.templates.selected)
      if (this.templates.selected) {
        this.applySelectedTemplate()
      }
    }

    onChangeTemplateSaveMode () {
      if (this.templates.isUpdateExisting) {
        this.templates.newTemplateName = this.templates.selected ? this.templates.selected.name : ''
      } else {
        this.templates.newTemplateName = ''
      }
    }

    saveOrUpdateTemplate () {
      console.log('[saveOrUpdateTemplate]', this.templates.isUpdateExisting, this.templates.selected, this.templates.options)
      if (this.templates.isUpdateExisting && this.templates.selected && this.templates.options) {
        const template = this.ProjectScopeDocService.processScopeDocResetIds({
          ...this.scopeDoc,
        })
        template.id = this.templates.selected.id // preserve template id that user wants to update
        template.name = this.templates.selected.name // preserve template name that user wants to update

        this.ProjectScopeDocService.updateTemplate(template)
          .then(response => {
            const oldTemplateIndex = this.templates.options.findIndex(t => t.id === response.id)
            console.log('[saveOrUpdateTemplate] > updateTemplate', oldTemplateIndex, this.templateUpdateForm.$invalid, this.editForm.$invalid, this.editForm.$pristine)
            if (oldTemplateIndex > -1) {
              // this.templates.options.splice(oldTemplateIndex, 1, response)
              console.log('[saveOrUpdateTemplate] > updateTemplate > update old template')
              this.templates.options[oldTemplateIndex] = response
              this.templates.selected = response
              this.setFormPristine()
            }
          })
      } else {
        const template = this.ProjectScopeDocService.processScopeDocResetIds({
          ...this.scopeDoc
        })
        this.ProjectScopeDocService.createTemplate(this.templates.newTemplateName, template)
          .then(response => {
            this.templates.options.push(response)
            this.templates.selected = response // automatically select template
          })
      }
    }


    // global attachments
    onGlobalAttachmentsSelect ($event) {
      console.log('[onGlobalAttachmentsSelect]', $event)
      this.scopeDoc._edit.attachmentsQueueCtrl = $event

      if (this.scopeDoc._edit.attachmentsQueueCtrl.queueList.length) {
        this.editForm.$setDirty()
      }
    }

    onGlobalAttachmentsUploaded ($event) {
      console.log('[onGlobalAttachmentsUploaded]', $event)
      if ($event.attachment) {
        this.scopeDoc.attachments.push($event.attachment)
      }
    }
    onGlobalAttachmentDelete ($event) {
      console.log('[onGlobalAttachmentDelete]', $event)
      this.editForm.$setDirty() // mark form dirty so it can be saved and changes applied
    }


    // item attachments
    onItemAttachmentsSelect ($event, item) {
      console.log('[onItemAttachmentsSelect]', $event, item)
      item._edit.attachmentsQueueCtrl = $event

      if (item._edit.attachmentsQueueCtrl) {
        this.editForm.$setDirty()
      }
    }
    onItemAttachmentDelete ($event, item) {
      console.log('[onItemAttachmentDelete]', $event)
      this.editForm.$setDirty() // mark form dirty so it can be saved and changes applied
    }
  }
}

export default ProjectScopeDocEditFormComponent
