import templateUrl from './delete-account-result-dialog.html'
import './delete-account-result-dialog.scss'

const DeleteAccountResultDialogComponent = {
  bindings: {
    title: '<',
    success: '<',
    errors: '<',
  },
  templateUrl,
  require: {
    modalCtrl: '^^cdblModal'
  },
  controller: class DeleteAccountResultDialogComponent {
    constructor (Auth) {
      'ngInject'
      this._identify = 'DeleteAccountResultDialogComponent'
      this.Auth = Auth
    }

    $onInit () {
      this.title = this.modalCtrl.modal.title
      this.errors = this.modalCtrl.modal.errors
      this.success = this.modalCtrl.modal.success
    }

    logout () {
      this.closeDialog()
      this.Auth.signOut('auth.sign_in')
    }

    closeDialog () {
      this.modalCtrl.close()
    }
  }
}

export default DeleteAccountResultDialogComponent
