(function () {
  'use strict';

  function WorkroomTaskCtrl($scope, Auth, Modal, ModalService, Flash, Storage, PusherService, taskResource, PaymentsService, SegmentAnalytics, FeeService, UserService, ProjectService, ProjectRemindersService, EventBusService, $stateParams) {
    const HIRE_REMINDER = 'hireCheck'
    const COMPLETE_PROJECT_REMINDER = 'completionCheck'
    const EVENT_PROJECT_REMINDERS_LOADED = 'project-reminders-loaded'

    $scope.ProjectService = ProjectService

    // Shim - workaround so that task is always returned fresh from ProjectService and it is not lost by reference if updated in ProjectService - this is used so we dont need to migrate all existing variables in controllers/templates
    Object.defineProperty(taskResource, 'task', { get: () => ProjectService.project })
    Object.defineProperty($scope, 'task', { get: () => ProjectService.project })
    // ---

    ProjectService.setProjectPageTitle()

    $scope.workroomScope = $scope;

    $scope.data = taskResource; // So children scopes will have access

    $scope.subscription = taskResource.subscription;
    $scope.me = Auth.me();
    $scope.projectDetails = true;

    $scope.completedReview = false;

    $scope.expertFee = FeeService.getServiceFee('expert', $scope.task);
    $scope.clientFee = FeeService.getServiceFee('clientTask', $scope.task);

    $scope.UserService = UserService;
    $scope.currentUser = UserService.user;


    const remindersLoadedListener = event => {
      console.log('[WorkroomTaskCtrl] > remindersLoadedListener', event)

      // Hire check reminder
      if (UserService.isExpert() &&
        UserService.isUserHiredExpertOnProject($scope.task) &&
        ProjectService.isInState($scope.task, 'paid') &&
        ProjectRemindersService.canShowReminder(HIRE_REMINDER)) {
        ModalService.open({
          closeOnBackdrop: false,
          closeIcon: true,
          class: 'hired-reminder-modal cdbl-modal--style-with-dividers cdbl-modal--style-with-sticky-header-and-footer',
          template: `
            <cdbl-hired-reminder-modal
              project="$ctrl.modal.project"
              current-user="$ctrl.modal.currentUser">
            </cdbl-hired-reminder-modal>`,
          project: $scope.task,
          currentUser: $scope.currentUser,
        })
      }

      // Complete project check reminder
      if (UserService.isExpert() &&
        UserService.isUserHiredExpertOnProject($scope.task) &&
        ProjectService.isInState($scope.task, 'completed') &&
        ProjectRemindersService.canShowReminder(COMPLETE_PROJECT_REMINDER)) {
        ModalService.open({
          closeOnBackdrop: false,
          closeIcon: true,
          class: 'project-completed-reminder-modal cdbl-modal--style-with-dividers cdbl-modal--style-with-sticky-header-and-footer',
          template: `
            <cdbl-project-completed-reminder-modal
              project="$ctrl.modal.project"
              current-user="$ctrl.modal.currentUser">
            </cdbl-project-completed-reminder-modal>`,
          project: $scope.task,
          currentUser: $scope.currentUser,
        })
      }
    }
    EventBusService
      .channel('legacy') // TODO: create separate project channel when refactoring rest of the project page
      .subscribe(EVENT_PROJECT_REMINDERS_LOADED, remindersLoadedListener)


    if ($scope.task.state === 'refunded') {
      Flash.show('views/flashes/task_refunded.html', true, $scope);
    }

    $scope.$on('project-refund-successful', function () {
      $scope.task.state = 'refunded';
      Flash.show('views/flashes/task_refunded.html', true, $scope);
    });

    if ($stateParams.payment_provider == 'stripe') {
      ProjectService.handleStripePaymentConfirmation($scope.task)
    }

    // TODO: verify after retainer merge
    // If project is retainer tooltip is shown.
    $scope.retainerTooltipDismissed = Storage.get('TooltipDismissed');
    $scope.dismissTooltip = function () {
      Storage.set('TooltipDismissed', true);
      $scope.retainerTooltipDismissed = true;
    };

    let checkUserId = null;
    // TODO: migrate to PusherService when ready and refactor into own component
    if (UserService.isExpert()) {
      checkUserId = $scope.task.client.id;
    } else {
      checkUserId = $scope.task.contractor.id;
    }
    $scope.isUserOnline = PusherService.isUserOnline(checkUserId)

    $scope.openCompletedModal = function () {
      Modal.open('views/tasks/modal_complete_task.html', $scope, { closeButton: false });
    };

    $scope.openReviewModal = function () {
      ModalService.open({
        closeOnBackdrop: false,
        closeIcon: false,
        layout: 'center',
        class: 'project-review-modal',
        template: `<cdbl-project-review-modal project="$ctrl.modal.project"></cdbl-project-review-modal>`,
        project: $scope.task
      })
    };

    $scope.closeModal = function () {
      Modal.close();
    };

    // TODO: move to ProjectService
    $scope.$on('task-completed', function () {
      $scope.task.reviewNeeded = true;
      $scope.task.isEditable = function () {
        return false;
      };
    });

    $scope.$on('partner-mentioned', function (event, data) {
      // Remove partner in mentioned partners, if it matches project's referral - API SHOULD DO THIS... -
      $scope.task.mentionedPartners = data.mentionedPartners.filter(partner =>
        partner.trackingName !== $scope.task.partnerName
      )
    })

    $scope.$on('project-quality-review-updated', function (event, data) {
      ProjectService.getProject($scope.task.id)
        .then(response => {
          $scope.task.qualityReview = response.qualityReview;
        })
        .catch(err => {
          console.log(err)
        })
    })


    // TODO: refactor offline payment modal
    // if user offline payment happens when user is away, when he gets back task routing state will be workroom
    if ($scope.task.paymentConfirmed === false && UserService.isUserClientOnProject($scope.task)) {
      // --- if in display state and pusher event for payment is received check if was wired payment
      const modalScope = {
        task: $scope.task,
        offlinePaymentConfirm: function (task) {
          console.log('WorkroomTaskCtrl > offlinePaymentConfirm');
          PaymentsService.offlinePaymentConfirmation(task).then(function (response) {
            console.log('WorkroomTaskCtrl > offlinePaymentConfirm > response', response);
            angular.copy(response, $scope.task);
            SegmentAnalytics.trackTaskPayment(task, 'offline');
            SegmentAnalytics.trackPayment(task, null, 'offline');
            Modal.close();
          }, function (error) {
            modalScope.error = 'Error occured, please try again.';
            console.log('WorkroomTaskCtrl > offlinePaymentConfirm > error', error);
          });
        }
      };
      Modal.open('views/modals/deposit_funds_complete_manual.html', modalScope, {closeButton: false});
      // ---
    }

    $scope.$on('$destroy', () => {
      ModalService.close() // close any open modal
      EventBusService
        .channel('legacy') // default root channel TODO: create separate project channel
        .unsubscribe(EVENT_PROJECT_REMINDERS_LOADED, remindersLoadedListener)
    })
  }

  app.controller('WorkroomTaskCtrl', ['$scope', 'Auth', 'Modal', 'ModalService', 'Flash', 'Storage', 'PusherService', 'taskResource', 'PaymentsService', 'SegmentAnalytics', 'FeeService', 'UserService', 'ProjectService', 'ProjectRemindersService', 'EventBusService', '$stateParams', WorkroomTaskCtrl]);
})();
