const SentencecaseFilter = function () {
  'ngInject'
  return function (text) {
    if (typeof text !== 'string') {
      return text
    }

    return `${text.charAt(0).toUpperCase()}${text.substring(1, text.length)}`
  }
}
export default SentencecaseFilter
