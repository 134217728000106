import templateUrl from './server-error.html'
import './server-error.scss'

const ServerErrorComponent = {
  bindings: {
    instanceOptions: '<options',
    errors: '<'
  },
  transclude: true,
  templateUrl,
  controller: class ServerError {
    constructor () {
      'ngInject'
      this._identify = 'ServerErrorComponent'

      this.options = null
      this.defaultOptions = {

      }
    }

    $onInit () {
      this.options = Object.assign({}, this.defaultOptions, this.instanceOptions)
    }

    $onChanges (changes) {
      if (changes.errors) {

      }
    }

    get errorMessages () {
      let errorMessages = []
      if (this.errors && Array.isArray(this.errors)) {
        this.errors.forEach(e => {
          if (e.message) {
            errorMessages.push(e.message)
          }
        })
      } else if (this.errors) {
        errorMessages.push(this.errors.message || this.errors)
      }

      return errorMessages
    }
  }
}

export default ServerErrorComponent
