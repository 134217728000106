// This service is used for the internal app event distribution and to avoid usage of $scope and internal AngularJS mechanics for this purpose. As such it is independent of AngularJS

// TODO: first usage is meant to mimic the current implementation where all events are published to single channel called "root" ($rootScope)

// Example of use
// EventBusService.root.subscribe('estimate_updated', callbackFn)
// EventBusService.channel('project-10').subscribe('estimate_updated', callbackFn)
// EventBusService.channel('project-10').publish('estimate_updated', payload)

// import ConfigEnvironment from 'cdblEnvConfig'

const ROOT_CHANNEL_NAME = 'root'
const LEGACY_CHANNEL_NAME = 'legacy' // used for all legacy events that are currently global
const DEBUG_EVENT_BUS = false // !ConfigEnvironment.isBuild && !ConfigEnvironment.isProd // debug enabled only for local development
const DEBUG_LOG_MAX_ITEMS = 1000

const EventBusService = class EventBusService {
  constructor ($timeout) {
    'ngInject'

    if (DEBUG_EVENT_BUS) {
      console.log('[EventBusService] > constructor', this)
    }

    this.$timeout = $timeout
    this.ROOT_CHANNEL_NAME = ROOT_CHANNEL_NAME
    this.LEGACY_CHANNEL_NAME = LEGACY_CHANNEL_NAME

    this._channels = {}
    this.createChannel(ROOT_CHANNEL_NAME)
    this.createChannel(LEGACY_CHANNEL_NAME)
  }

  // Create new channel of return exisiting one
  createChannel (name) {
    if (DEBUG_EVENT_BUS) {
      console.log('[EventBusService] > createChannel', name)
    }
    if (!this._channels.hasOwnProperty(name)) {
      this._channels[name] = new Channel(name)
    }

    return this._channels[name]
  }

  isChannelInit (name) {
    return this._channels.hasOwnProperty(name)
  }

  // Find channel or return root channel by default
  channel (name = ROOT_CHANNEL_NAME, createOnTheFly = false) {
    if (DEBUG_EVENT_BUS) {
      console.log('[EventBusService] > channel', name)
    }

    if (!this._channels.hasOwnProperty(name) && !createOnTheFly) {
      return null
    }

    if (createOnTheFly) {
      return this.createChannel(name)
    }

    return this._channels[name]
  }

  // Destroy channel with all event topics and listeners
  destroyChannel (name) {
    if (DEBUG_EVENT_BUS) {
      console.log('[EventBusService] > destroyChannel', name)
    }
    // TODO go through
    if (this._channel.hasOwnProperty(name)) {
      delete this._topics[name]
    }
  }

  createSubscriptionCollection (name) {
    return new SubscriptionCollection(name, this)
  }
}



export default EventBusService



class Channel {
   /**
   * @param name - chanel name
   */
  constructor (name) {
    if (DEBUG_EVENT_BUS) {
      console.info('[EventBusService] > [Channel] > constructor', name, this)
    }

    if (typeof name === 'undefined' || name.length === 0) {
      throw new Error('Channel must have a name')
    }
    this._name = name

    // List of topics
    this._topics = {}

    // Log for debugging
    this._log = []
  }

  topic (topicName) {
    if (DEBUG_EVENT_BUS) {
      console.info('[EventBusService] > [Channel] > topic', this._name, topicName)
    }

    if (!this._topics.hasOwnProperty(topicName)) {
      return null
    }

    return this._topics[topicName]
  }

  // subscribe to channel's topic
  subscribe (topicName, listener) {
    if (DEBUG_EVENT_BUS) {
      console.info('[EventBusService] > [Channel] > subscribe', this._name, topicName)
    }

    if (!topicName || topicName.length === 0 || typeof listener !== 'function') {
      throw new Error('Channel subscribe needs a topic name and valid listener function')
    }

    if (!this._topics.hasOwnProperty(topicName)) {
      this._topics[topicName] = new Topic(topicName)
    }

    this._topics[topicName].addListener(listener)

    return {
      channelName: this._name,
      topicName: topicName,
      listener: listener
    }
  }

  // unsubscribe from channel's topic
  unsubscribe (topicName, listener) {
    if (DEBUG_EVENT_BUS) {
      console.info('[EventBusService] > [Channel] > unsubscribe', this._name, topicName)
    }

    if (this._topics.hasOwnProperty(topicName)) {
      if (typeof listener === 'function') {
        this._topics[topicName].removeListener(listener)
      } else {
        // remove all listeners
        this._topics[topicName].removeAllListeners()
      }
    }
  }

  // TODO: Subscribe only once, after first publish unsubscribe
  // once (topicName, listener) {
  //   if (DEBUG_EVENT_BUS) {
  //     console.info('[EventBusService] > [Channel] > once > TODO', this._name, topicName)
  //   }
  // }

  // publish to channel's topic
  publish (topicName, payload) {
    if (DEBUG_EVENT_BUS) {
      console.info('[EventBusService] > [Channel] > publish', this._name, topicName, payload)


      if (this._log.length > DEBUG_LOG_MAX_ITEMS) {
        this._log.length = 0
      }
      this._log.push({
        action: 'publish',
        topicName,
        payload
      })
    }

    if (!this._topics.hasOwnProperty(topicName)) {
      console.warn(`Channel "${this._name}" did not found a topic "${topicName}" to publish on. Aborting.`)
      return
    }

    this._topics[topicName].send(payload)
  }

  destroyTopic (topicName) {
    if (DEBUG_EVENT_BUS) {
      console.info('[EventBusService] > [Channel] > destroyTopic', this._name, topicName)
    }

    if (this._topics.hasOwnProperty(topicName)) {
      delete this._topics[topicName]
    }
  }

  destroyAll () {
    if (DEBUG_EVENT_BUS) {
      console.info('[EventBusService] > [Channel] > destroyAll', this._name)
    }

    Object.keys(this._topics).forEach(key => {
      delete this._topics[key]
    })
  }
}



class Topic {
   /**
   * @param name - Topic name
   */
  constructor (name) {
    if (DEBUG_EVENT_BUS) {
      console.info('[EventBusService] > [Topic] > constructor', name)
    }

    if (typeof name === 'undefined' || name.length === 0) {
      throw new Error('Topic must have a name')
    }
    this._name = name
    this._listeners = []
  }

  addListener (listener) {
    if (DEBUG_EVENT_BUS) {
      console.info('[EventBusService] > [Topic] > addListener', this._name)
    }

    return this._listeners.push(listener)
  }

  removeListener (listener) {
    if (DEBUG_EVENT_BUS) {
      console.info('[EventBusService] > [Topic] > removeListener', this._name, listener)
    }

    const index = this._listeners.findIndex(l => l === listener)
    if (index) {
      // delete this._listeners[index]
      this._listeners.splice(index, 1)

      if (DEBUG_EVENT_BUS) {
        console.info('[EventBusService] > [Topic] > removeListener > success', this._name)
      }
    }
  }

  removeAllListeners () {
    // this._listeners.forEach((element, index) => {
    //   delete this._listeners[index]
    // })
    this._listeners.splice(0, this._listeners.length)
  }

  send (payload = {}) {
    if (DEBUG_EVENT_BUS) {
      console.info('[EventBusService] > [Topic] > send', this._name, payload)
    }

    this._listeners.forEach(listener => {
      listener(payload)
    })
  }
}

class SubscriptionCollection {
   /**
   * @param name - chanel name
   */
  constructor (channelName, EventBusService) {
    if (DEBUG_EVENT_BUS) {
      console.info('[EventBusService] > [SubscriptionCollection] > constructor', channelName, this)
    }

    if (typeof channelName === 'undefined' || channelName.length === 0) {
      throw new Error('SubscriptionCollection must have a channelName')
    }
    this._channelName = channelName
    this.EventBusService = EventBusService

    // List of topics
    this._subscriptions = []
  }

  list () {
    return this._subscriptions
  }

  /*
  * @eventName: string or array of strings
  * @listener: function
  */
  subscribe (eventName, listener) {
    if (DEBUG_EVENT_BUS) {
      console.info('[EventBusService] > [SubscriptionCollection] > subscribe', eventName)
    }

    let eventNameList = []
    if (typeof eventName === 'string') {
      eventNameList.push(eventName)
    } else {
      eventNameList = [ ...eventName ]
    }

    eventNameList.forEach(eventName => {
      const subscription = this.EventBusService.channel(this._channelName).subscribe(eventName, listener)
      this._subscriptions.push(subscription)
    })
  }

  unsubscribeAll () {
    if (DEBUG_EVENT_BUS) {
      console.info('[EventBusService] > [SubscriptionCollection] > unsubscribeAll')
    }
    this._subscriptions.forEach(subscription => {
      if (DEBUG_EVENT_BUS) {
        console.info('[EventBusService] > [SubscriptionCollection] > unsubscribeAll > subscription topic', subscription.topicName, subscription.channelName, this._channelName)
      }
      this.EventBusService.channel(subscription.channelName).unsubscribe(subscription.topicName, subscription.listener)
    })
    // this._subscriptions = []
    this._subscriptions.splice(0, this._subscriptions.length)
  }
}
export { Channel, Topic, SubscriptionCollection, LEGACY_CHANNEL_NAME, ROOT_CHANNEL_NAME }
