import templateUrlClient from './project-client.html'
import templateUrlExpert from './project-expert.html'
import './project.scss'

const ProjectComponent = {
  bindings: {
    project: '<'
  },
  require: {
    dashboardListCtrl: '^^cdblProjectDashboardList'
  },
  template: `<a ui-sref="{{ $ctrl.projectSref }}"
                ng-include="$ctrl.templateUrl"
                class="project-template">
             </a>`, // Dynamic template switching
  controller: class Project {
    constructor ($stateParams, UserService, ProjectService, ProjectDashboardService) {
      'ngInject'
      this._identify = 'ProjectComponent'
      this.templateUrl = null
      this.$stateParams = $stateParams
      this.UserService = UserService
      this.ProjectService = ProjectService
      this.ProjectDashboardService = ProjectDashboardService


      this.projectSref = null
    }

    $onInit () {
      // console.log('[ProjectComponent] > init')
      this.templateUrl = this.UserService.isClient() ? templateUrlClient : templateUrlExpert

      this.projectDate = this.ProjectService.formatProjectDate(this.project)

      // Setup project link target
      switch (this.project.state) {
      case 'paid': // eslint-disable-line no-fallthrough
      case 'completed': // eslint-disable-line no-fallthrough
      case 'refunded':
        this.projectSref = `tasks.workroom({id: ${this.project.id}})`
        break
      default:
        this.projectSref = `tasks.display({id: ${this.project.id}})`
        break
      }

      // State label class
      this.projectStateClasses = []
      switch (this.project.state) {
      case 'paid': // eslint-disable-line no-fallthrough
      case 'completed':
        this.projectStateClasses.push('label-success')
        break
      case 'hired':
          // if (this.UserService.isExpert()) {
          //   this.projectStateClasses.push('label-danger')
          // }
          // break;
      case 'estimated': // eslint-disable-line no-fallthrough
      case 'published':
        this.projectStateClasses.push('label-danger')
        break
      }

      // if (this.project.lastEvent) {
      //   switch (this.project.lastEvent.type) {
      //     case 'create_invitation':
      //       if (this.project.lastEvent.object && this.project.lastEvent.object.state === 'accepted') {
      //         this.projectStateClasses.push('label-danger')
      //       }
      //       break;
      //     case 'update_invitation':
      //       this.projectStateClasses.push('label-danger')
      //       break;
      //   }
      // }
      // ---

      // TODO: remove when BE provides createdAt date - its needed for proper display of date to client/expert
      this.projectDate = this.ProjectService.formatProjectDate(this.project, { format: 'short' })


      // Client helper variables
      this.showPriceClient = this.project.prices.clientPriceAfterDiscounts > 0 &&
                             this.project.state !== 'refunded'
      this.priceClient = this.project.prices.clientPriceAfterDiscounts

      this.showRatingClient = this.project.lastEvent &&
                              this.project.lastEvent.clientRating !== -1 &&
                              this.project.lastEvent.reviewer &&
                              this.project.lastEvent.reviewer.id === this.UserService.user.id
      // ---

      // Expert helper variables
      this.showPriceExpert = this.project.prices.contractorEarnings > 0
      this.priceExpert = this.project.prices.contractorEarnings

      this.showExpertRating = this.project.expertRating > 0
      // ---
      //

      this.showHideProjectButton = this.$stateParams.listId === 'pending' &&
                                   this.project.eligibleForCancel

      this.showFavouriteProjectButton = true
                                        // this.$stateParams.listId === 'pending' ||
                                        // this.$stateParams.listId === 'favourites'
    }


    // CLIENT METHODS
    onCanceled ($event) {
      console.log('[ProjectComponent] > onCanceled callback', $event)
      this.dashboardListCtrl.removeProjectFromList($event.projectId)
    }



    // EXPERT METHODS
    onChangeFavourite ($event) {
      console.log('[ProjectComponent] > onChangeFavourite callback', $event)
      this.ProjectDashboardService.updateProjectStats('favourites', $event)

      // remove project from favourite list
      if (this.$stateParams.listId === 'favourites' && !$event.isFavourite) {
        this.dashboardListCtrl.removeProjectFromList($event.projectId)
      }
    }

    onChangeHide ($event) {
      console.log('[ProjectComponent] > onChangeHide callback', $event)
      this.dashboardListCtrl.removeProjectFromList($event.projectId)
    }
  }
}

export default ProjectComponent
