import TooltipDirective from './tooltip.directive'


const TooltipModule = angular.module('cdbl.tooltip', [

])
  .directive('cdblTooltip', TooltipDirective)
  .name

export default TooltipModule
