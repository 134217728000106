import templateUrl from './app.html'

console.log('[app.component.js]')

const AppComponent = {
  templateUrl,
  controller: class AppController {
    constructor (Configuration, UIService, UserService, AuthService, AuthTokenService, UtilService, $mdMedia, $timeout, $state, $location, $cookies, $stateParams, IntercomService, $window, $interval, $rootScope, $scope, SegmentAnalytics, Modal, $element, NavigationService, SystemReloadService, SleekService, SettingsExpertTeamService, ExperimentService) {
      'ngInject'
      this.name = 'appComponent'
      this.Configuration = Configuration
      this.UIService = UIService
      this.$mdMedia = $mdMedia
      this.UserService = UserService
      this.AuthService = AuthService
      this.AuthTokenService = AuthTokenService
      this.UtilService = UtilService
      this.$timeout = $timeout
      this.$state = $state
      this.$location = $location
      this.$cookies = $cookies
      this.$stateParams = $stateParams
      this.$window = $window
      this.$interval = $interval
      this.$rootScope = $rootScope
      this.SegmentAnalytics = SegmentAnalytics
      this.IntercomService = IntercomService
      this.$scope = $scope // TODO should be removed after refactoring of auth module
      this.Modal = Modal // TODO should be removed after all legacy modals are migrated to use refactored modal
      this.$element = $element
      this.SettingsExpertTeamService = SettingsExpertTeamService

      this.SystemReloadService = SystemReloadService
      this.NavigationService = NavigationService
      this.SleekService = SleekService
      this.ExperimentService = ExperimentService

      this.isUserLoaded = false
      this.isUserTimeout = false

      // TODO: remove these and just use UserService
      this.isAuthenticated = () => {
        return UserService.isAuthenticated()
      }
      this.isClient = () => {
        return UserService.isClient()
      }
      this.isExpert = () => {
        return UserService.isExpert()
      }
      // ---


      // TODO: main menu component should expose these, when refactoring main menu, move below code to the main menu component
      // Expose method for main menu
      this.showIntercom = () => {
        console.log('appComponent > showIntercom')
        IntercomService.showMessage()
      }
      // ---

      this.waitingForUserObject = true
    }

    async $onInit () {
      console.log('[AppComponent] > $onInit', this.$state.name)
      if (!this.Configuration.isProd && !this.Configuration.isBuild) {
        this.UtilService.addClass('body', 'devbar-visible')
      }

      // Update loader message
      this.UtilService
        .getElement('.app-loader-wrapper .loader-text')
        .innerHTML = 'Codeable is initializing...'

      // Subscribe to service that allows us to trigger app reload on enduser side
      // SystemReloadService will not capture logged out users and its a known edge case we are fine with (verified with Kiro)
      this.SystemReloadService.subscribeToSystemReload()

      // Initiate navigation service that subscribes to $stateChangeStart events and handles layout changes
      this.NavigationService.init()

      // User persona (segment cookie) setup from url
      if (this.$location.search().segment) {
        this.$cookies.remove('segment')
        this.$cookies.put('segment', this.$location.search().segment)
      }

      // Coupon (coupon_code cookie) setup from url
      if (this.$location.search().coupon_code) {
        this.$cookies.remove('coupon_code')
        this.$cookies.put('coupon_code', this.$location.search().coupon_code)
      }

      // Renew auth token on app init, if successfull continue with:
      // - start auth token refresh interval
      // - fetch fresh user data from BE
      // - store auth token and user object in LS
      // - intitialize 3rd party libs
      await this.AuthService.appInitAuthAttempt()
        .then(response => {
          console.log('[AppComponent] > appInitAuthAttempt > success', response)
        })
        .catch(err => {
          console.log('[AppComponent] > appInitAuthAttempt > err', err)
        })

      this.UserService.handleFallbackNotificationSettings()

      console.log('[AppComponent] > experiment service > init start')
      await this.ExperimentService.init()
      console.log('[AppComponent] > experiment service > init end')
      this.$timeout() // needs to be called to trigger digest after await so that AngularJS properly

      // Unblock UI loading screen
      this.UtilService.addClass('body', 'app-initialized')
      this.AuthService.setIsInitialized(true)
      this.waitingForUserObject = false
    }

    $doCheck () {
      if (!this.AuthService.isInitialized) {
        // Wait until app init auth attempt completes only then continue
        return
      }

      if (!this.AuthTokenService.get() && this.AuthService.isAuthenticatedInCurrentTab) {
        const currentUrl = this.$location.url()
        const logoutWithoutRedirect = this.Configuration.auth.logoutWithoutRedirectUrls.find(exceptionUrl => currentUrl.includes(exceptionUrl))

        if (logoutWithoutRedirect) {
          console.log('[AppComponent] > $doCheck > logout without redirect')
          this.AuthService.logout({ redirectToLoginPage: false })
        } else {
          console.log('[AppComponent] > $doCheck > logout')
          // params used for routing after next successful login
          this.AuthService.logout({ params: { redirect: currentUrl } })
        }
      } else if (!this.AuthService.isAuthenticatedInCurrentTab && this.AuthTokenService.get() && this.UserService.get()) {
        // set flag so this block does not trigger multiple times
        this.AuthService.setAuthenticatedInCurrentTab(true)
        if (this.$stateParams.redirect) {
          console.log('[AppComponent] > $doCheck > logged in in another tab > redirect to param', this.$stateParams.redirect)
          this.$location.url(this.$stateParams.redirect)
        } else {
          const currentUrl = this.$location.url()
          const loginWithoutRedirectUrls = this.Configuration.auth.loginWithoutRedirectUrls.find(exceptionUrl => currentUrl.includes(exceptionUrl))
          if (loginWithoutRedirectUrls) {
            console.log('[AppComponent] > $doCheck > logged in in another tab > do not redirect on login exception')
          } else {
            console.log('[AppComponent] > $doCheck > logged in in another tab > redirect to default')
            this.$state.go(this.Configuration.defaultRouterState.name)
          }
        }

        // Update BE for segemnt and coupon code if authenticated by existing auth token
        this.handleClientCookies()
      }
    }

    handleClientCookies () {
      if (this.UserService.isAuthenticated() && this.$cookies.get('segment')) {
        this.UserService
          .updatePersonaSurvey(this.$cookies.get('segment'))
          .finally(() => {
            this.$cookies.remove('segment')
          })
      }
      if (this.UserService.isAuthenticated() && this.$cookies.get('coupon_code')) {
        this.UserService
          .updateCouponCode(this.$cookies.get('coupon_code'))
          .finally(() => {
            this.$cookies.remove('coupon_code')
          })
      }
    }

    scrollTo (x, y) {
      this.$element.scrollTo(x, y)
    }

    $onDestroy () {
      this.SystemReloadService.unSubscribeToSystemReload()
    }

    // ---
  },
  controllerAs: 'AppCtrl'
}
export default AppComponent
