const PaginationService = class PaginationService {
  constructor (Configuration) {
    'ngInject'

    this.Configuration = Configuration
  }

  init (options = {
    mode: 'server', // server side uses first page index 1, while pagination of array uses first page index 0
    itemsPerPage: 50,
    itemsPerPageOptions: [
      25,
      50,
      100
    ],
    paginationArray: null,
    initialPage: null
  }) {
    let firstPage = options.mode === 'array' ? 0 : 1

    if (options.initialPage > 0) {
      firstPage = options.initialPage
    }

    return {
      currentPage: firstPage,
      firstPage: firstPage,

      mode: options.mode || 'server',
      paginationArray: options.paginationArray || null,

      itemsPerPage: options.itemsPerPage || 50,
      itemsPerPageOptions: options.itemsPerPageOptions || [ 25, 50, 100 ],

      totalCount: null,

      _pageCount: null,
      get pageCount () {
        if (this.mode === 'array' && Boolean(this.paginationArray)) {
          return Math.ceil(this.paginationArray.length / this.itemsPerPage)
        }
        return this._pageCount
      },

      reset () {
        this.currentPage = firstPage
      },
      setItemsPerPage (num) {
        const perPage = parseInt(num, 10)

        if (typeof perPage !== 'number' || isNaN(perPage)) {
          return
        }

        this.itemsPerPage = perPage
      },
      setCurrentPage (num) {
        const page = parseInt(num, 10)
        if (typeof page !== 'number' || isNaN(page)) {
          return
        }

        if (this.mode === 'array') {
          this.currentPage = Math.max(page, this.firstPage)
        } else if (this._pageCount) {
          this.currentPage = Math.max(Math.min(page, this._pageCount), this.firstPage)
        } else {
          this.currentPage = Math.max(page, this.firstPage)
        }
      },
      getCurrentPage () {
        return options.mode === 'array' ? this.currentPage + 1 : this.currentPage
      },
      getItemsFromTo () {
        const current = options.mode === 'array' ? this.currentPage : this.currentPage - 1

        const fromItem = current * this.itemsPerPage + 1
        const toItem = Math.min(current * this.itemsPerPage + this.itemsPerPage, this.totalCount)

        return `${fromItem} - ${toItem}`
      },
      getItemsOfTotal () {
        return `${this.getItemsFromTo()} of total ${this.totalCount}`
      },
      updateFromHeaders (response) {
        this._pageCount = Number(response.headers('x-page-count'))
        this.itemsPerPage = Number(response.headers('x-records-per-page'))
        this.totalCount = Number(response.headers('x-total-count'))

        // this.currentPage = Number(response.headers('x-current-page'))
        // console.log('updateFromHeaders', response.headers('x-current-page'), this._pageCount)
        if (response?.config?.params?.page === 'last') {
          this.setCurrentPage(this._pageCount)
        } else {
          this.setCurrentPage(response?.config?.params?.page)
        }
      },
      updatePaginationArray (newArr) {
        this.paginationArray = newArr
      }
    }
  }
}
export default PaginationService
