(function () {
  'use strict'

  function SignInCtrl (
    $http, $scope, $state, $stateParams, $location, $cookies, $window, Storage, UserResource, AuthService, Configuration, UserService, SettingsExpertTeamService, ModalService) {
    // check if user is already authenticated and redirect him
    if (AuthService.isAuthenticated()) {
      $state.go(Configuration.defaultRouterState.name)
    }

    if ($stateParams.token) {
      console.log('[SignInCtrl] > sign in with token', $stateParams.token)
      let t = $stateParams.token
      $location.search('token', null)
      AuthService.loginWithToken(t).then(function () {
        $location.url($stateParams.redirect)
      })
    }

    $scope.unlockAccount = {
      unlockToken: null,
      inProgress: false,
      message: null
    }

    if ($stateParams.unlock_token) {
      $scope.unlockAccount.token = $stateParams.unlock_token
      console.log('[SignInCtrl] > unlockAccount', $scope.unlockAccount.token)

      $state.transitionTo($state.current, {
        unlock_token: null
      }, {
        reload: false,
        location: true,
        inherit: false,
        notify: false
      })

      $scope.unlockAccount.inProgress = true
      $http
        .delete(`${Configuration.apiUrl}/users/lock`, {
          params: {
            'unlock_token': $scope.unlockAccount.token
          }
        })
        .then(response => {
          console.log('[SignInCtrl] > unlockAccount', response)
          $scope.unlockAccount.message = 'Your account has been unlocked. You can log in now.'
        })
        .catch(err => {
          if (err.data.reason === 'invalid_unlock_token') {
            $scope.unlockAccount.message = 'Your unlock link has expired, or your account has already been unlocked. You can try and log in now.'
          } else {
            $scope.unlockAccount.message = 'Error unlocking account'
          }
        })
        .finally(() => {
          $scope.unlockAccount.inProgress = false
        })
    }

    $scope.user = {
      email: '',
      password: ''
    }

    $scope.rememberMeChkModel = true

    $('input[type="email"]').focus()

    $scope.signIn = function (user) {
      $scope.unlockAccount.message = null // reset message for token unlock
      $scope.errorAccountLocked = false
      $scope.errorAccountAlreadyExists = false

      if ($scope.rememberMeChkModel) {
        Storage.setLocal()
      } else {
        Storage.setSession()
      }

      $scope.processing = true

      AuthService.login(user)
        .then(function (user) {
          if (!user.trackedSegmentByLink && user.role === 'client') {
            $cookies.put('segmentUser', 'yes')
          } else {
            $cookies.put('segmentUser', 'no')
          }

          // console.log('SignInCtrl > signIn > $cookies.segmentUser', $cookies.segmentUser, data.user.trackedSegmentByLink, data.user.role, data);

          $scope.inProgress = true

          AuthService.log()




          console.log('[SignInCtrl] > login ', user, '$stateParams.redirect >', $stateParams.redirect)
          if ($stateParams.redirect) {
            $location.url($stateParams.redirect)
          } else if (user.role === 'client') {
            if (user.tasksCount === 1) {
              console.log('client > tasksCount', user.tasksCount)
              var requests = ['tasksInProgress', 'tasksInquiring']
              UserResource.getUser(requests).then(function (tasks) {
                var taskId
                if (tasks.tasksInProgress.length === 1) {
                  taskId = tasks.tasksInProgress[0].id
                } else if (tasks.tasksInquiring.length === 1) {
                  taskId = tasks.tasksInquiring[0].id
                }

                console.log('client > taskId', taskId)
                $state.go('tasks.display', { id: taskId })
              }).catch(function () {
                $state.go(Configuration.defaultRouterState.name)
              })
            } else {
              $state.go(Configuration.defaultRouterState.name)
            }
          } else {
            $state.go(Configuration.defaultRouterState.name)
          }

          // TODO move to app service and call it here once login is completed
          UserService.getAutoDisplayModalsList()
            .then(response => {
              console.log('[SignInCtrl] > getAutoDisplayModalsList > response', response)
              response.forEach(autoDisplayModal => {
                if (autoDisplayModal.name === SettingsExpertTeamService.AUTO_DISPLAY_CONFIRMATION_MODAL_NAME) {
                  SettingsExpertTeamService.showUpdateConfirmationModal()
                }
              })
            })
            .catch(err => {
              console.log('[SignInCtrl] > getAutoDisplayModalsList > err', err)
            })
          // ----
        })
        .catch(err => {
          console.log('[SignInCtrl] > err', err)
          delete err.config // do not report users login data

          // TODO: because how the server errors are currently handeled this should be revisited when we change/refactor auth module
          if (err.data.reason === 'account_locked') {
            $scope.errorAccountLocked = true
          }

          if (Sentry) {
            Sentry.sendMessage('signInCtrl.js > AuthService.login (status: ' + err.status + '): ' + JSON.stringify(err), 'info')
          }
        })
        .finally(function () {
          $scope.processing = false
        })
    }

    $scope.signInWithGoogle = function () {
      var auth2 = gapi.auth2.getAuthInstance()
      auth2.grantOfflineAccess().then(signInCallback)
    }

    function signInCallback (resp) {
      AuthService.loginGoogle(resp.code)
        .then(function (user) {
          if (user.ttl) {
            ModalService.open({
              closeOnBackdrop: false,
              closeIcon: true,
              class: 'accept-tos-modal cdbl-modal--style-with-dividers cdbl-modal--style-with-sticky-header-and-footer',
              template: `
                <cdbl-accept-tos-modal
                token="$ctrl.modal.token">
                </cdbl-accept-tos-modal>`,
              token: user.token,
            })
          } else {
            if (!user.trackedSegmentByLink && user.role === 'client') {
              $cookies.put('segmentUser', 'yes')
            } else {
              $cookies.put('segmentUser', 'no')
            }

            $scope.inProgress = true

            AuthService.log()
            if ($stateParams.redirect) {
              $location.url($stateParams.redirect)
            } else if (user.role === 'client') {
              if (user.tasksCount === 1) {
                console.log('client > tasksCount', user.tasksCount)
                var requests = ['tasksInProgress', 'tasksInquiring']
                UserResource.getUser(requests).then(function (tasks) {
                  var taskId
                  if (tasks.tasksInProgress.length === 1) {
                    taskId = tasks.tasksInProgress[0].id
                  } else if (tasks.tasksInquiring.length === 1) {
                    taskId = tasks.tasksInquiring[0].id
                  }

                  console.log('client > taskId', taskId)
                  $state.go('tasks.display', { id: taskId })
                }).catch(function () {
                  $state.go(Configuration.defaultRouterState.name)
                })
              } else {
                $state.go(Configuration.defaultRouterState.name)
              }
            } else {
              $state.go(Configuration.defaultRouterState.name)
            }

          // TODO move to app service and call it here once login is completed
            UserService.getAutoDisplayModalsList()
              .then(response => {
                console.log('[SignInCtrl] > getAutoDisplayModalsList > response', response)
                response.forEach(autoDisplayModal => {
                  if (autoDisplayModal.name === SettingsExpertTeamService.AUTO_DISPLAY_CONFIRMATION_MODAL_NAME) {
                    SettingsExpertTeamService.showUpdateConfirmationModal()
                  }
                })
              })
              .catch(err => {
                console.log('[SignInCtrl] > getAutoDisplayModalsList > err', err)
              })
          // ----
          }
        })
        .catch(err => {
          console.log('[SignInCtrl] > err', err)
          delete err.config // do not report users login data

        // TODO: because how the server errors are currently handeled this should be revisited when we change/refactor auth module
          if (err.data.reason === 'account_locked') {
            $scope.errorAccountLocked = true
          }

          if (err.data.reason === 'user_already_exists') {
            $scope.errorAccountAlreadyExists = true
          }

          if (Sentry) {
            Sentry.sendMessage('signInCtrl.js > AuthService.login (status: ' + err.status + '): ' + JSON.stringify(err), 'info')
          }
        })
        .finally(function () {
          $scope.processing = false
        })
    }
  }

  app.controller('SignInCtrl', ['$http', '$scope', '$state', '$stateParams', '$location', '$cookies', '$window', 'Storage', 'UserResource', 'AuthService', 'Configuration', 'UserService', 'SettingsExpertTeamService', 'ModalService', SignInCtrl])
})()
// gAAAAABUWhXlCr5mWHodehu7d9bNDuwOywY5A4f5o4yPT6jyZ1EIzzNiKzV3Q3UTWPdUl8rpgXTgEzGPv3e5DFaTJkYYwjlRuA== publishTask.js?v=1415189924951&kind=theme&urgency=1&utm_campaign=campaign&utm_medium=medium&utm_sou…:92
