(function () {
  'use strict';

  function VaultCtrl($scope, Modal, Auth, Vault, VaultApi, TaskResource, $timeout, SegmentAnalytics, UserService) {
    $scope.vaultData = {
      password: '',
      text: '',
      shareWith: []
    };

    console.log('[vaulCtrl] > 1');

    $scope.currentUser = UserService.user;

    $scope.decryValuePIN = false;
    $scope.deleted = false;
    $scope.updated = false;

    $scope.deleteConfirm = false;

    $scope.setDeleteConfirm = function (state) {
      $scope.deleteConfirm = state;
    };

    $scope.contractors = [];

    $scope.vaultKey = Vault.loadVaultKeyFromLS($scope.modalScope.task.id);

    $scope.finished = false;
    $scope.errorNoContractors = false;

    $scope.loading = true;
    TaskResource.getTask($scope.modalScope.task.id, ['participants']).then(function (data) {
      if ($scope.modalScope.task.contractor) {
        $scope.contractors = data.participants.filter(user => user.id === $scope.modalScope.task.contractor.id);
      } else {
        $scope.contractors = data.participants.filter(user => user.role === 'contractor');
      }

      $scope.loading = false;

      if ($scope.modalScope.vault.encData) {
        $scope.modalScope.vault.sharedVaultAccesses.forEach(function (contractor) {
          var element = _.find($scope.contractors, {id: contractor.user.id});
          var position = $scope.contractors.indexOf(element);

          if (position !== -1) {
            $scope.contractors[position].added = true;
          }

          $scope.vaultData.shareWith.push({
            contractorId: contractor.user.id,
            publicKeyPem: contractor.user.rsaPublicKey
          });
        });

        $scope.modalScope.vault.sharedVaultAccesses = _.uniq($scope.modalScope.vault.sharedVaultAccesses);
        if ($scope.modalScope.decryValue) {
          SegmentAnalytics.trackVault('Content Viewed', $scope.modalScope.task);
          $scope.vaultData.text = $scope.modalScope.decryValue.data;
        }
      }
    });

    $scope.useNewVault = () => {
      return moment($scope.modalScope.task.postedOn).isAfter('2017-10-11', 'day');
      // return true;
    };

    $scope.vaultPin = [undefined, undefined, undefined, undefined];
    let lastKeydownWasDelete = false;
    $scope.onChangeVaultPin = index => {
      console.log('onChangeVaultPin', $scope.vaultPin);
      if (typeof $scope.vaultPin[index] !== 'undefined' && index < $scope.vaultPin.length - 1) {
        $(`#vault-pin-${index + 1}`).focus();
      }
      $scope.vaultData.password = $scope.vaultPin.join('');
    };

    $scope.onKeydownVaultPin = (evt, index) => {
      if (!((evt.which >= 48 && evt.which <= 57) ||
            (evt.which >= 96 && evt.which <= 105) ||
            evt.keyCode === 67 ||
            evt.keyCode === 8 ||
            evt.keyCode === 37 ||
            evt.keyCode === 39)) {
        evt.preventDefault();
        return false;
      }

      $timeout(() => {
        const isDelete = (evt.keyCode === 67 || evt.keyCode === 8);
        if ((isDelete && lastKeydownWasDelete && typeof $scope.vaultPin[index] === 'undefined' && index > 0) || evt.keyCode === 37) {
          $(`#vault-pin-${index - 1}`).focus();
        } else if ((typeof $scope.vaultPin[index] !== 'undefined' && index < $scope.vaultPin.length - 1) || evt.keyCode === 39) {
          $(`#vault-pin-${index + 1}`).focus();
        }

        lastKeydownWasDelete = lastKeydownWasDelete ? false : (evt.keyCode === 67 || evt.keyCode === 8);
      }, 0);
    };

    $scope.unlockWithPIN = (taskId, password) => {
      console.log('unlockWithPIN', taskId, password, Auth.me().email);
      SegmentAnalytics.trackVault('Unlocked', $scope.modalScope.task);
      Vault.saveVaultKeyToLS(taskId, Vault.encrSrv.deriveVaultKey(password, Auth.me().email));
      $scope.decryValuePIN = Vault.openAsClient(taskId, $scope.modalScope.vault);
    };

    $scope.retryPin = taskId => {
      console.log('taskId', taskId, '$scope', $scope);
      Vault.removeVaultKeyFromLS(taskId);
      $timeout(function () {
        Modal.open('views/modals/vault_set_password.html', $scope.modalScope, {closeButton: false});
      });
    };

    $scope.updateContractor = function (contractor) {
      if (contractor.added) {
        $scope.addContractor(contractor);
      } else {
        $scope.removeContractor(contractor);
      }
    };

    $scope.addContractor = function (contractor) {
      var element = _.find($scope.vaultData.shareWith, {contractorId: contractor.id});
      var position = $scope.vaultData.shareWith.indexOf(element);

      if (position === -1) {
        $scope.vaultData.shareWith.push({
          contractorId: contractor.id,
          publicKeyPem: contractor.rsaPublicKey
        });
      }

      contractor.added = true;
    };

    $scope.removeContractor = function (contractor) {
      var element = _.find($scope.vaultData.shareWith, {contractorId: contractor.id});
      var position = $scope.vaultData.shareWith.indexOf(element);

      $scope.vaultData.shareWith.splice(position, 1);

      contractor.added = false;
    };

    $scope.update = function (taskId, password, text, sharedWith) {
      if (sharedWith.length === 0) {
        $scope.errorNoContractors = true;

        return;
      }

      $scope.errorNoContractors = false;

      if ($scope.modalScope.vault.encData) {
        let vaultData = Vault.update(taskId, text, sharedWith);

        VaultApi.updateVault(taskId, JSON.stringify(vaultData.encData), vaultData.sharedWith).then(function (response) {
          if (!response) return

          const vault = response.data
          SegmentAnalytics.trackVault('Updated', $scope.modalScope.task, vaultData.sharedWith.length);

          $scope.finished = true;
          $scope.updated = true;

          if (!$scope.modalScope.decryValue) {
            $scope.modalScope.decryValue = {};
          }

          $scope.modalScope.decryValue.data = text;
          $scope.modalScope.vault = vault;
          $scope.modalScope.vault.encData = JSON.parse($scope.modalScope.vault.encData);
        });
      } else {
        let vaultData = Vault.add(taskId, password, Auth.me().email, text, sharedWith);

        VaultApi.createVault(taskId, JSON.stringify(vaultData.encData), vaultData.sharedWith).then(function (response) {
          if (!response) return
          const vault = response.data
          SegmentAnalytics.trackVault('Created', $scope.modalScope.task, vaultData.sharedWith.length);

          $scope.finished = true;

          if (!$scope.modalScope.decryValue) {
            $scope.modalScope.decryValue = {};
          }
          $scope.modalScope.decryValue.data = text;
          $scope.modalScope.vault = vault;
          $scope.modalScope.vault.encData = JSON.parse($scope.modalScope.vault.encData);
        });
      }
    };

    $scope.remove = function (taskId) {
      Vault.remove(taskId);
      VaultApi.deleteVault(taskId).then(function () {
        $scope.deleted = true;
        $scope.finished = true;

        $scope.modalScope.vault = {};

        SegmentAnalytics.trackVault('Deleted', $scope.modalScope.task);
        // Modal.close();
      });
    };

    $scope.setPassword = function (taskId, password) {
      Vault.saveVaultKeyToLS(taskId, Vault.encrSrv.deriveVaultKey(password, Auth.me().email));
      $scope.finished = true;
      $scope.modalScope.openVaultAsClient($scope.modalScope.task.id, $scope.modalScope.vault, false);// This has to be so decrypted value is refreshed!!!!!!!!!
    };

    $scope.setKey = function (key) {
      if (!Vault.checkVaultKey(key, $scope.modalScope.vault)) {
        $scope.modalScope.wrongKey = true;
        return;
      }

      Vault.savePKeyToLS(key);
      $scope.finished = true;
    };

    $scope.closeModal = function () {
      Modal.close();
    };
  }

  app.controller('VaultCtrl', ['$scope', 'Modal', 'Auth', 'Vault', 'VaultApi', 'TaskResource', '$timeout', 'SegmentAnalytics', 'UserService', VaultCtrl]);
})();
