const DateFromRelativeFilter = function (moment) {
  'ngInject'
  return function (dateOriginalRaw, dateCompareRaw = moment(), noSuffix = false) {
    if (dateOriginalRaw) {
      const dateOriginal = moment(dateOriginalRaw)
      const dateCompare = dateCompareRaw === 'now' ? moment() : moment(dateCompareRaw)

      return dateOriginal.from(dateCompare, noSuffix)
    }

    return 0
  }
}
export default DateFromRelativeFilter
