import WorkroomProjectInfoComponent from './workroom-project-info.component'
import WorkroomProjectInfoService from './workroom-project-info.service'

const WorkroomProjectInfoModule = angular.module('cdbl.workroom-project-info', [

])
  .service('WorkroomProjectInfoService', WorkroomProjectInfoService)
  .component('cdblWorkroomProjectInfo', WorkroomProjectInfoComponent)
  .name

export default WorkroomProjectInfoModule
