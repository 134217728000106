(function () {
  'use strict';

  function HiredExpertTaskCtrl($scope, $http, Configuration, Auth, $location, $state, $stateParams) {
    $scope.project = {};
    $scope.tasks = [];

    // TODO put into service
    $http({
      method: 'GET',
      url: Configuration.apiUrl + '/tasks/' + $stateParams.id + '/hired-expert'
    }).then(success => {
      const client = success.data.client;
      const expert = success.data.contractor;

      $scope.project = {
        title: success.data.title,
        canceled: success.data.state === 'canceled',
        otherExpertHired: success.data.state === 'hired' && expert,
        client: {
          fullName: client.fullName,
          avatar: client.avatar.largeUrl,
          averageReviewRating: Math.round(success.data.clientAverageReviewRating),
          completedTasksCount: client.completedTasksCount,
          tasksCount: client.tasksCount,
          refundedTasksCount: client.refundedTasksCount
        }
      };

      if (success.data.state !== 'canceled' && expert) { // check if expert object exists
        $scope.expertHired = true;
        $scope.project.expert = {
          fullName: expert.fullName,
          avatar: expert.avatar.largeUrl,
          averageReviewRating: Math.round(expert.avgRating),
          completedTasksCount: expert.completedTasksCount
        };
      }
    });
  }

  app.controller('HiredExpertTaskCtrl', ['$scope', '$http', 'Configuration', 'Auth', '$location', '$state', '$stateParams', HiredExpertTaskCtrl]);
})();
