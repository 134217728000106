import ProjectReviewActionsComponent from './project-review-actions.component'
import ProjectQualityReviewService from './project-quality-review.service'

const ProjectReviewActionsModule = angular.module('cdbl.project-review-actions', [

])
  .service('ProjectQualityReviewService', ProjectQualityReviewService)
  .component('cdblProjectReviewActions', ProjectReviewActionsComponent)
  .name

export default ProjectReviewActionsModule
