import templateUrl from './submission-step-one-banner.html'
import './submission-step-one-banner.scss'

const SubmissionStepOneBannerComponent = {
  bindings: {
    submissionobanner: '<'
  },
  require: {
    grandParent: '^cdblSubmission'
  },
  templateUrl,
  controller: class SubmissionStepOneBanner {
    constructor () {
      'ngInject'
      this._identify = 'SubmissionStepOneBannerComponent'
    }
    select () {
      this.grandParent.goToStepTwo(this.submissionobanner.project_type, this.submissionobanner.project_subject)
    }
  }
}

export default SubmissionStepOneBannerComponent
