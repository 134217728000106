
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import './tooltip.scss'

const TooltipDirective = function ($window) {
  'ngInject'
  return {
    restrict: 'A',
    link: (scope, element, attrs) => {
      const instance = tippy(element[0], {
        // content: `<div class="cdbl__tooltip ${classes}" role="tooltip"><div class="cdbl__tooltip-arrow"></div><div class="cdbl__tooltip-inner">${attrs.cdblTooltip}</div></div>`,
        content: `${attrs.cdblTooltip}`,
        allowHTML: true,
        placement: 'top',
        ignoreAttributes: true,
        delay: 0,
        duration: [275, 250],
        hideOnClick: true,
        // popperOptions: {
        //   eventsEnabled: true,
        //   removeOnDestroy: true,
        // }
      })

      scope.$on('$destroy', () => {
        // instance.dispose()
        instance.destroy()
      })
    }
  }
}

export default TooltipDirective

// Tippy element structure
/*
<div class="tippy-popper">
  <div class="tippy-tooltip" data-placement="top">
    <div class="tippy-content">
      My content
    </div>
  </div>
</div>
*/
