(function () {
  'use strict';

  function LostPasswordCtrl($scope, $location, $http, Configuration) {
    $scope.step = 1;

    if ($location.search().reset_password_token) {
      $scope.token = $location.search().reset_password_token;
      $scope.step = 2;
    }

    $scope.resetPassword = function (email) {
      $scope.processing = true
      $http
        .post(`${Configuration.apiUrl}/users/reset_password`, { email: email })
        .then(function () {
          $scope.displayCheckEmail = true
          $scope.email = ''
        })
        .catch(function (err) {
          console.log(err)
        })
        .finally(function () {
          $scope.processing = false
        })
    };

    $scope.data = {
      password: '',
      repeatPassword: ''
    };
    $scope.confirmPasswordReset = function (token, password) {
      $scope.processing = true;
      $http
        .put(`${Configuration.apiUrl}/users/reset_password`, { token: token, password: password })
        .then(function () {
          $scope.success = true
          $scope.data.password = ''
          $scope.data.repeatPassword = ''
        })
        .catch(function (err) {
          $scope.error = err
        })
        .finally(function () {
          $scope.processing = false
        })
    };
  }

  app.controller('LostPasswordCtrl', ['$scope', '$location', '$http', 'Configuration', LostPasswordCtrl]);
})();
