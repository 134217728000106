const bindCompileDirective = function ($compile) {
  'ngInject'
  return {
    restrict: 'A',
    link: (scope, element, attrs) => {
      // console.log('cdblBindCompile > ', scope, element, attrs);
      scope.$watch(function () {
        return scope.$eval(attrs.cdblBindCompile)
      }, function (value) {
        // console.log('cdblBindCompile > $watch', value);
        // In case value is a TrustedValueHolderType, sometimes it
        // needs to be explicitly called into a string in order to
        // get the HTML string.
        element.html(value && value.toString())
        // If scope is provided use it, otherwise use parent scope
        var compileScope = scope
        if (attrs.cdblBindCompileScope) {
          compileScope = scope.$eval(attrs.cdblBindCompileScope)
        }
        $compile(element.contents())(compileScope)
      })
    }
  }
}

export default bindCompileDirective
