import templateUrl from './change-emergency-contact.html'
import './change-emergency-contact.scss'


const ChangeEmergencyContactModalComponent = {
  bindings: {
    data: '<',
    onSave: '&?'
  },
  require: {
    modalCtrl: '^^cdblModal'
  },
  templateUrl,
  controller: class ChangeEmergencyContactModalComponent {
    constructor ($timeout, EventEmitter, ModalService, SettingsService) {
      'ngInject'
      this._identify = 'ChangeEmergencyContactModalComponent'
      this.$timeout = $timeout
      this.EventEmitter = EventEmitter
      this.SettingsService = SettingsService
      this.ModalService = ModalService

      this.phonePattern = /^\+[0-9]{5,15}$/
    }

    $onInit () {
      this.isCompleted = false
      this.formData = {
        email: this.data.email || '',
        fullName: this.data.fullName || '',
        relationship: this.data.relationship || null,
        phoneNumber: this.data.phoneNumber || ''
      }
      this.isLoading = true
      this.SettingsService.getEmergencyDetailsRelationshipDataset(this.data.resources.relationships)
        .then(response => {
          console.log('🚀 ~ file: change-emergency-contact.component.js:38 ~ ChangeEmergencyContactModalComponent ~ $onInit ~ response:', response)
          this.relationshipList = response
        })
        .finally(() => {
          this.isLoading = false
          this.$timeout()
        })
    }

    update () {
      this.error = null
      const payload = {
        ...this.formData
      }
      this.isProcessing = true
      this.SettingsService.updateEmergencyDetails(this.data.actions.update, payload)
        .then(emergencyContact => {
          this.isCompleted = true
        })
        .finally(() => {
          this.isProcessing = false
          this.$timeout()
        })
    }

    closeDialog () {
      this.modalCtrl.resolve(this.formData)
      // this.modalCtrl.resolve()
    }
  }
}

export default ChangeEmergencyContactModalComponent
