import ClientProfileViewModalComponent from './client-profile-view-modal.component'

import ClientProfileService from './client-profile.service'

const ClientProfileViewModalModule = angular.module('cdbl.client-profile-view-modal', [

])
  .component('cdblClientProfileViewModal', ClientProfileViewModalComponent)
  .service('ClientProfileService', ClientProfileService)
  .name

export default ClientProfileViewModalModule
