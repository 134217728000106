import AdditionalTasksSidebarWidgetComponent from './sidebar-widget.component'
import AdditionalTasksSidebarListItemComponent from './list-item'

const AdditionalTasksSidebarWidgetModule = angular.module('cdbl.sidebar-widget', [

])
  .component('cdblAdditionalTasksSidebarWidget', AdditionalTasksSidebarWidgetComponent)
  .component('cdblAdditionalTasksSidebarListItem', AdditionalTasksSidebarListItemComponent)
  .name

export default AdditionalTasksSidebarWidgetModule
