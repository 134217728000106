import templateUrl from './attachments-modal.html'
import './attachments-modal.scss'

const AttachmentsModalComponent = {
  bindings: {
    project: '<'
  },
  templateUrl,
  require: {
    modalCtrl: '^^cdblModal'
  },
  controller: class AttachmentsModal {
    constructor (AttachmentsService, UserService, ErrorsService) {
      'ngInject'
      this._identify = 'AttachmentsModalComponent'

      this.AttachmentsService = AttachmentsService
      this.UserService = UserService
      this.ErrorsService = ErrorsService



      const vm = this
      this.attachmentsFilter = (item, index, array) => {
        console.log('[AttachmentsModalComponent] > attachmentsFilter', item)
        let allowed = true

        if (vm.listFilters.searchString.enabled) {
          allowed = allowed && item.name.toLowerCase().contains(vm.listFilters.searchString.value.toLowerCase())
        }

        if (vm.listFilters.owner.enabled) {
          switch (vm.listFilters.owner.value) {
          case 'me':
            allowed = allowed && item.user.id === vm.UserService.user.id
            break
          case 'others':
            allowed = allowed && item.user.id !== vm.UserService.user.id
            break
          }
        }

        return allowed
      }


      this.expirationConfig = {
        expiresAt: null,
        timeoutPromise: null,
        callback: () => {
          vm.getProjectAttachments()
        }
      }

      this.downloadAllErrorHandler = this.ErrorsService.createErrorHandler()
    }

    $onInit () {
      this.attachmentList = []
      this.isLoading = false
      this.listSort = '-createdAt'
      this.listFilters = {
        'searchString': {
          name: 'searchString',
          enabled: true,
          value: '',
        },
        'owner': {
          name: 'owner',
          enabled: true,
          value: 'all',
          options: [
            {
              name: 'All',
              value: 'all'
            },
            {
              name: 'Uploaded by me',
              value: 'me'
            },
            {
              name: 'Uploaded by others',
              value: 'others'
            }
          ]
        }
      }

      this.getProjectAttachments()

      // TODO: pusher events for create / delete
      // TODO: update also attahcment widget in sidebar (adding/removing)
    }

    $onDestroy () {
      this.AttachmentsService.cancelTimeoutUpdateAttachmentsData(this.expirationConfig)
    }

    getProjectAttachments () {
      this.isLoading = true
      this.AttachmentsService
        .getAttachmentList(this.project.id)
        .then(response => {
          this.attachmentList = response

          // trigger expiration timestamp for sign urls refetch
          if (this.attachmentList[0]) {
            this.expirationConfig.expiresAt = this.attachmentList[0].expiresAt
          } else {
            this.expirationConfig.expiresAt = null
          }
        })
        .catch(err => { console.error(err) })
        .finally(() => {
          this.isLoading = false
          this.AttachmentsService.timeoutUpdateAttachmentsData(this.expirationConfig)
        })
    }

    onDeleteAttachment ($event) {
      const index = this.attachmentList.findIndex(a => a.id === $event.attachment.id)
      if (index > -1) {
        this.attachmentList.splice(index, 1)
      }
    }

    onUploadFinish ($event) {
      // All attachments uploaded
      console.log('[AttachmentsModalComponent] > onUploadFinish', $event)
    }

    onFileUploaded ($event) {
      // Update widget with new attachment
      console.log('[AttachmentsModalComponent] > onFileUploaded', $event)
      if ($event.attachment) {
        this.attachmentList.push($event.attachment)
      }
    }

    resetFilter () {
      this.listFilters.searchString.value = ''
      this.listFilters.owner.value = 'all'
    }

    downloadAllAttachments ($event) {
      console.log('[AttachmentsModalComponent] > downloadAllAttachments')
      this.inProgressDownloadAll = true
      this.downloadAllErrorHandler.reset()
      this.AttachmentsService.downloadAllAttachments(this.project.id)
        .catch(err => {
          this.downloadAllErrorHandler.add(err)
        })
        .finally(() => {
          this.inProgressDownloadAll = false
        })
    }
  }
}

export default AttachmentsModalComponent
