const onLastRenderDirective = function () {
  'ngInject'
  return {
    restrict: 'A',
    link: (scope, element, attrs) => {
      if (scope.$last) {
        console.log('onLastRenderDirective > eval', attrs.onLastRender)
        scope.$evalAsync(attrs.onLastRender)
      }
    }
  }
}

export default onLastRenderDirective
