import templateUrl from './team-member-edit-modal.html'
import './team-member-edit-modal.scss'

const SettingsExpertTeamMemberEditModalComponent = {
  bindings: {
    team: '<',
    member: '<?',
    resources: '<',
    onSave: '&?'
  },
  templateUrl,
  require: {
    modalCtrl: '^^cdblModal'
  },
  controller: class SettingsExpertTeamMemberEditModalComponent {
    constructor (SettingsExpertTeamService, EventEmitter) {
      'ngInject'
      this._identify = 'SettingsExpertTeamMemberEditModalComponent'
      this.SettingsExpertTeamService = SettingsExpertTeamService
      this.EventEmitter = EventEmitter
    }

    $onInit () {
      this.isProcessing = false
      this.serverError = null

      this.formData = this.member ? {
        name: this.member.name,
        roles: this.member.roles,
        email: this.member.email,
        employee: this.member.employee
      } : {}

      this.fileSelectorConfig = {
        preview: true, // only for images
        previewWidth: 200,
        previewHeight: 150,
        showRemoveIcon: true,
        accept: 'image/png, image/jpeg',
        maxSize: 2 * 1024 * 1e+3, // max 2mb,
      }
    }

    get canShowPreviouslyUploadedImage () {
      if (this.formData.avatar && this.formData.avatar.length > 0) {
        return false
      } else if (this.member && this.member.avatar) {
        return true
      }

      return false
    }

    removeExistingImage () {
      this.member.avatar = null
      this.formData.avatar = null
    }

    saveMember () {
      this.serverError = null
      this.isProcessing = true
      this.SettingsExpertTeamService.saveMember(this.team, this.member, this.formData)
        .then(response => {
          console.log('[SettingsExpertTeamMemberEditModalComponent] > saveMember > response', response)
          if (this.onSave) {
            this.onSave(this.EventEmitter({ member: response }))
          }
          this.modalCtrl.close()
        })
        .catch(err => {
          this.serverError = err
          console.log('err', err)
        })
        .finally(() => {
          this.isProcessing = false
        })
    }


    cancelEdit () {
      this.modalCtrl.close()
    }
  }
}

export default SettingsExpertTeamMemberEditModalComponent
