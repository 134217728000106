const NotificationsConfig = {
  categories: {
    // client notification widget/page
    'all': {
      name: 'all',
      filter: null, // null param makes it disable filter
      title: 'Notifications',
      widget: {
        title: '',
        icon: 'icon-notifications-comments',
        normal: true,
        important: false
      },
      // uisref: `notifications.list({listId: 'default'})`,
      userSoundParam: 'pendingProject' // mapping of User object notification settings for category
    },
    // expert notification widgets/pages
    'new-projects': {
      name: 'new-projects',
      filter: 'new-projects',
      title: 'New Project Notifications',
      widget: {
        title: 'New Projects',
        icon: 'icon-notifications-new-projects',
        normal: true,
        important: true
      },
      // uisref: `notifications.list({listId: 'new-projects'})`,
      userSoundParam: 'newProject' // mapping of User object notification settings for category
    },
    'in-progress': {
      name: 'in-progress',
      filter: 'in-progress',
      title: 'In Progress Project Notifications',
      widget: {
        title: 'In Progress Projects',
        icon: 'icon-notifications-projects-in-progress',
        normal: true,
        important: false
      },
      // uisref: `notifications.list({listId: 'in-progress'})`,
      userSoundParam: 'inprogressProject' // mapping of User object notification settings for category
    },
    'pending': {
      name: 'pending',
      filter: 'other',
      title: 'Pending Project Notifications',
      widget: {
        title: 'Pending Projects',
        icon: 'icon-notifications-pending-projects',
        normal: true,
        important: false
      },
      // uisref: `notifications.list({listId: 'pending'})`,
      userSoundParam: 'pendingProject' // mapping of User object notification settings for category
    }
  },
  allowed: [
    // project
    'create_task',
    'update_task',
    'create_estimate',
    'create_invitation',
    'update_invitation',
    'publicise_task',
    'create_expert_pods_project',
    'create_project_refund',
    'complete_task',
    'safecharge_authorized_payment',
    'paypal_authorized_payment',
    'stripe_authorized_payment',
    'referoo_balance_authorized_payment',
    'cancel_task',
    'not_hired_expert',

    // comment
    'create_comment',
    'update_comment',

    // additional task or retainer
    'create_sub_task',
    'create_sub_task_refund',
    'complete_sub_task',
    'safecharge_authorized_sub_task_payment',
    'stripe_authorized_sub_task_payment',
    'paypal_authorized_sub_task_payment',
    'referoo_balance_authorized_sub_task_payment',

    // retainer specific
    'subscription_failed',
    'subscription_succeeded',
    'subscription_canceled',
    'expert_paid',
    'subscription_renewed',
    'subscription_expiring',
    'subscription_ended',

    // review
    'create_review',
    'update_review',

    // file
    'create_attachment',
    'destroy_attachment',

    // vault
    'create_vault',
    'update_vault',
    'destroy_vault',


    // scope doc
    'create_scope_doc_submission',
    'create_scope_doc_rejection',
    'create_scope_doc_approval',
    'create_scope_doc_finish',
    'create_scope_doc_item_finish',
  ]
}

export default NotificationsConfig
