import marked from 'marked'
const markedFilter = function () {
  'ngInject'
  const processLinks = (html) => {
    const reg = /^((ftp|http|https):\/\/)/i
    const delimiter = '<a href="'
    const protocol = 'http://'

    return html.split(delimiter).reduce(function (prev, curr, idx, arr) {
      if (idx > 0) {
        if (!reg.test(curr)) {
          prev.push(protocol) // add missing protocol
        }
      }
      prev.push(curr)
      if (idx < arr.length - 1) {
        prev.push(delimiter)
      }
      return prev
    }, []).join('')
  }


  return (html) => {
    if (html === null) {
      return ''
    }

    const markedText = marked(html, {
      sanitize: true,
      breaks: true
    })

    return processLinks(markedText)
  }
}
export default markedFilter
