const MachinenameToTextFilter = function () {
  'ngInject'
  return function (text) {
    if (typeof text !== 'string') {
      return text
    }

    return text.replace(/[-|_|]/, ' ')
  }
}
export default MachinenameToTextFilter
