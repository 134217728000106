const budgetDisplayFilter = function (Configuration, currencyFormatFilter) {
  'ngInject'
  return function (budgetValue) {
    let budgetLabel = budgetValue
    if (budgetValue === null) {
      budgetLabel = 'not sure or flexible'
    } else if (budgetValue > 0) {
      budgetLabel = `${currencyFormatFilter(budgetValue)}`
    } else {
      const budgetRange = Configuration.features.submission.budgetRanges.find(item => item.id === budgetValue)
      if (budgetRange) {
        budgetLabel = budgetRange.label
      } else {
        budgetLabel = 'N/A'
      }
    }

    return budgetLabel
  }
}
export default budgetDisplayFilter
