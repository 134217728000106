// import templateUrlBasic from './expert-profile-edit-form.basic.template.html'
import templateUrlDetails from './expert-profile-edit-form.details.template.html'
import templateUrlProjectPreferences from './expert-profile-edit-form.project-preferences.template.html'
import templateUrlLocation from './expert-profile-edit-form.location.template.html'
import templateUrlPortfolio from './expert-profile-edit-form.portfolio.template.html'
import templateUrlReviews from './expert-profile-edit-form.reviews.template.html'


const sectionsConfig = [
  // {
  //   id: 'basic',
  //   title: 'Basic Information',
  //   helper: '(required)',
  //   templateUrl: templateUrlBasic,
  //   isCollapsed: false,
  //   progressFields: ['firstName', 'lastName']
  // },
  {
    id: 'details',
    title: 'More Details',
    helper: '(required)',
    templateUrl: templateUrlDetails,
    isCollapsed: false,
    progressFields: ['shortBio', 'longBio', 'coreSpeciality', 'expertRole', 'skills', 'languages']
  },
  {
    id: 'project-preferences',
    title: 'Project Preferrences',
    helper: '(required)',
    templateUrl: templateUrlProjectPreferences,
    isCollapsed: false,
    progressFields: ['preferredProjectTypes']
  },
  {
    id: 'location',
    title: 'Location',
    helper: '(required)',
    templateUrl: templateUrlLocation,
    isCollapsed: false,
    progressFields: ['location', 'timezone']
  },
  {
    id: 'portfolio',
    title: 'Portfolio',
    helper: null,
    templateUrl: templateUrlPortfolio,
    isCollapsed: false,
    progressFields: ['portfolio']
  },
  {
    id: 'reviews',
    title: 'Reviews',
    helper: null,
    templateUrl: templateUrlReviews,
    isCollapsed: false,
    progressFields: []
  }
]

const helperVisibleOnPublic = 'Visible on your public profile'
const profileFieldsConfig = {
  // firstName: {
  //   label: 'First Name',
  //   description: null,
  //   helper: helperVisibleOnPublic,
  //   placeholder: 'Enter First Name',
  //   validation: {
  //     minlength: 2,
  //     maxlength: 20
  //   },
  //   requiredForPublish: true
  // },
  // lastName: {
  //   label: 'Last Name',
  //   description: null,
  //   helper: helperVisibleOnPublic,
  //   placeholder: 'Enter Last Name',
  //   validation: {
  //     minlength: 2,
  //     maxlength: 30
  //   },
  //   requiredForPublish: true
  // },
  shortBio: {
    label: 'Short Intro',
    description: null,
    helper: helperVisibleOnPublic,
    placeholder: 'Add description',
    validation: {
      minlength: 50,
      maxlength: 300
    },
    requiredForPublish: true
  },
  longBio: {
    label: 'Long Description',
    description: null,
    helper: helperVisibleOnPublic,
    placeholder: 'Add description',
    validation: {
      minlength: 50,
      maxlength: 2000
    },
    requiredForPublish: true
  },
  coreSpeciality: {
    label: 'Core Specialty',
    description: null,
    helper: helperVisibleOnPublic,
    placeholder: 'Select Specialty',
    validation: {
      max: 1
    },
    requiredForPublish: true,
    options: []
  },
  expertRole: {
    label: 'Role',
    description: null,
    helper: helperVisibleOnPublic,
    placeholder: 'Select Role',
    validation: {
      max: 1
    },
    requiredForPublish: true,
    options: []
  },
  skills: {
    label: 'Skills',
    description: 'Tag up to 20 skills',
    helper: helperVisibleOnPublic,
    placeholder: 'Add Skill',
    validation: {
      min: 3,
      max: 20
    },
    requiredForPublish: true,
    options: []
  },
  languages: {
    label: 'Languages',
    description: 'Tag up to 5 languages',
    helper: helperVisibleOnPublic,
    placeholder: 'Add Language',
    validation: {
      // min: 1
      max: 5
    },
    requiredForPublish: true,
    options: []
  },
  preferredProjectTypes: {
    label: null,
    description: 'Tag up to 3 project types',
    helper: helperVisibleOnPublic,
    placeholder: 'Select Project Type',
    validation: {
      // min: 1,
      max: 3
    },
    requiredForPublish: true,
    options: []
  },
  location: {
    label: 'City',
    description: null,
    helper: helperVisibleOnPublic,
    placeholder: 'Select City',
    validation: {},
    requiredForPublish: true,
    options: []
  },
  timezone: {
    label: 'Timezone',
    description: null,
    helper: helperVisibleOnPublic,
    placeholder: 'Select Timezone',
    validation: {},
    requiredForPublish: true,
    options: []
  },
  portfolio: {
    label: 'Portfolio',
    description: null,
    helper: helperVisibleOnPublic
  }
}

export default {
  sectionsConfig,
  profileFieldsConfig
}
