import templateUrl from './presets-parent-list.html'
import './presets-parent-list.scss'

const ProjectPresetsParentListComponent = {
  bindings: {},
  templateUrl,
  controller: class ProjectPresetsParentListComponent {
    constructor (ProjectPresetsService, $filter) {
      'ngInject'
      this._identify = 'ProjectPresetsParentListComponent'
      this.ProjectPresetsService = ProjectPresetsService
      this.$filter = $filter
    }

    $onInit () {
      this.searchText = ''
      this.isLoading = false
      this.presetsList = []
      this.error = null

      this.isLoading = true
      this.ProjectPresetsService.loadParentPresetsList()
        .then(presetsList => {
          this.presetsList = this.groupAndSortPresets(presetsList)
          // this.presetsList = this.$filter('orderBy', this.presetsList)
        })
        .finally(() => {
          this.isLoading = false
        })
        .catch(err => {
          this.error = err
        })

      this.searchFn = function (preset) {
        if (this.searchText === '') {
          return true
        }
        const targetString = [preset?.definer, preset?.definerType, preset?.form].join(' ').trim().toLowerCase()
        return targetString.includes(this.searchText.toLowerCase())
      }.bind(this)
    }

    groupAndSortPresets (presetsList) {
      return presetsList.sort((a, b) => {
        const aText = [a.definerType, a.definer, a.form].join(' ').trim().toLowerCase()
        const bText = [b.definerType, b.definer, b.form].join(' ').trim().toLowerCase()

        console.log(aText, bText)
        return aText.localeCompare(bText)
      })
    }

    getPresetParams (preset) {
      const params = {
        form: preset.form
      }
      params[preset.definerType] = preset.definer
      return params
    }
  }
}

export default ProjectPresetsParentListComponent
