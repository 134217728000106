(function () {
  'use strict';

  function timestampToDate(_, $filter) {
    return function (collection, property, newProperty, format) {
      var result = [];

      _.forEach(collection, function (item) {
        item[newProperty] = $filter('date')((item[property] * 1000), format);
        result.push(item);
      });

      return result;
    };
  }

  app.filter('timestampToDate', ['_', '$filter', timestampToDate]);
})();
