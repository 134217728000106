(function () {
  'use strict'

  var compareTo = function () {
    var link = function (scope, element, attrs, ctrl) {
      var different = 'different' in attrs
      var compare = function () {
        var compareVal = element.val()
        var comparedField = scope.targetModel

        if (different) {
          if (typeof comparedField === 'string') {
            return compareVal !== comparedField
          }
          return comparedField && compareVal !== comparedField.$viewValue
        }

        if (comparedField !== null) {
          if (typeof comparedField === 'string') {
            return compareVal === comparedField
          }
          return comparedField && compareVal === comparedField.$viewValue
        }

        return false
      }

      scope.$watch(compare, function (newValue) {
        ctrl.$setValidity('compare', newValue)
      })
    }

    return {
      scope: {
        targetModel: '=compareTo'
      },
      require: 'ngModel',
      link: link
    }
  }

  app.directive('compareTo', compareTo)
})()
