(function () {
  'use strict';

  function userLink(User) {
    var link = function ($scope, element, attrs) {
      $scope.image = attrs.image === 'true' || '';

      $scope.showProfile = function (task, user) {
        User.showProfile(task, user.id, user.role);
      };

      $scope.canShowProfile = () => {
        return $scope.user.role !== 'superuser'
      }

      $scope.avatarImage = () => {
        const avatar = $scope.user.avatar ? $scope.user.avatar : $scope.user.avatars
        if (avatar) {
          return avatar.mediumUrl
        }
      }
    };

    return {
      restrict: 'E',
      scope: {
        user: '=',
        task: '=',
        showHire: '='
      },
      replace: true,
      templateUrl: require('../../views/directives/user_link.html'),
      link: link
    };
  }

  app.directive('userLink', ['User', userLink]);
})();
