import ProjectScopeDocComponent from './project-scope-doc.component'

import ProjectScopeDocService from './project-scope-doc.service'
import ProjectScopeDocFactory from './project-scope-doc.factory'
import ProjectScopeDocItemFactory from './project-scope-doc-item.factory'

import ProjectScopeDocSidebarWidgetModule from './scope-doc-sidebar-widget'
import ProjectScopeDocModalModule from './scope-doc-modal'

import ProjectScopeDocViewModule from './scope-doc-view'
import ProjectScopeDocEditFormModule from './scope-doc-edit-form'

import ProjectScopeDocActivityModule from './scope-doc-activity'


const ProjectScopeDocModule = angular.module('cdbl.project-scope-doc', [
  ProjectScopeDocSidebarWidgetModule,
  ProjectScopeDocModalModule,
  ProjectScopeDocViewModule,
  ProjectScopeDocEditFormModule,
  ProjectScopeDocActivityModule
])
  .component('cdblProjectScopeDoc', ProjectScopeDocComponent)
  .service('ProjectScopeDocService', ProjectScopeDocService)
  .factory('ProjectScopeDocFactory', ProjectScopeDocFactory)
  .factory('ProjectScopeDocItemFactory', ProjectScopeDocItemFactory)

  // TODO: remove, used for testing only
  // .config(($stateProvider) => {
  //   'ngInject'

  //   $stateProvider
  //     .state('scope-doc', {
  //       url: '/scope-doc/:id',
  //       template: '<cdbl-project-scope-doc></cdbl-cdbl-project-scope-doc>',
  //       resolve: {
  //         redirectIfNotDevelopment: ($state, UIService) => {
  //           'ngInject'
  //           if (!UIService.isDevelopment) {
  //             $state.go('dashboard.list', { listId: 'in-progress' })
  //           }
  //         }
  //       },
  //       layout: 'new'
  //     })
  // })

  .name

export default ProjectScopeDocModule
