const CodeableSubmissionConfig = {
  displayName: 'Codeable',
  types: [
    {
      type: 'Install & configure',
      title: 'Install & configure',
      project_type: 'install',
      project_subject: '',
      position: 'main_options',
      icon: 'icon-install',
      options: [
        {
          value: 'theme',
          name: 'Theme',
          description: 'Theme'
        },
        {
          value: 'plugin',
          name: 'Plugin',
          description: 'Plugin'
        }
      ]
    },
    {
      type: 'Customize',
      title: 'Customize',
      project_type: 'customize',
      project_subject: '',
      icon: 'icon-customize',
      position: 'main_options',
      options: [
        {
          value: 'theme',
          name: 'Theme',
          description: 'Theme'
        },
        {
          value: 'plugin',
          name: 'Plugin',
          description: 'Plugin'
        },
        {
          value: 'ecommerce_site',
          name: 'eCommerce site',
          description: 'eCommerce site'
        },
        {
          value: 'site',
          name: 'Site',
          description: 'Site'
        }
      ]
    },
    {
      type: 'Fix',
      title: 'Fix',
      project_type: 'fix',
      project_subject: '',
      icon: 'icon-fix',
      position: 'main_options',
      options: [
        {
          value: 'theme',
          name: 'Theme',
          description: 'Theme'
        },
        {
          value: 'plugin',
          name: 'Plugin',
          description: 'Plugin'
        },
        {
          value: 'ecommerce_site',
          name: 'eCommerce site',
          description: 'eCommerce site'
        },
        {
          value: 'site',
          name: 'Site',
          description: 'Site'
        }
      ]
    },
    {
      type: 'Develop & Design',
      title: 'Develop & Design',
      project_type: 'design_and_develop',
      project_subject: '',
      icon: 'icon-design-develop',
      position: 'main_options',
      options: [
        {
          value: 'theme',
          name: 'Theme',
          description: 'Theme'
        },
        {
          value: 'plugin',
          name: 'Plugin',
          description: 'Plugin'
        },
        {
          value: 'ecommerce_site',
          name: 'eCommerce site',
          description: 'eCommerce site'
        },
        {
          value: 'site',
          name: 'Site',
          description: 'Site'
        }
      ]
    },
    {
      type: 'Migrate',
      title: 'Migrate',
      project_type: 'migrate',
      project_subject: '',
      icon: 'icon-migrate',
      position: 'main_options',
      options: [
        {
          value: 'ecommerce_site',
          name: 'eCommerce site',
          description: 'eCommerce site'
        },
        {
          value: 'site',
          name: 'Site',
          description: 'Site'
        }
      ]
    },
    {
      type: 'Consultation',
      title: `Consult an Expert`,
      description: `For only <strong>{{ ::($ctrl.consultationPrice.value | currencyFormat) }}</strong>, speak to one of our experts about your project for 1 hour. Get advice on what should be done, or how complex different approaches are, or how to define a project before getting started.
        <a class="more" href="https://help.codeable.io/posting-your-project/what-is-a-consultation-task" target="_blank" cdbl-track="Submission Flow Link Clicked" cdbl-track-payload="{label:'Learn more'}">Learn more.</a>`,
      project_type: 'consultation',
      project_subject: 'consultation',
      kind: 'consultation',
      icon: 'icon-consultation',
      position: 'main_options',
      hideOptions: true,
      options: [
        {
          value: 'consultation',
          name: 'Consultation',
          description: false,
        }
      ]
    }
  ]
}

export default CodeableSubmissionConfig
