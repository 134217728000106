import templateUrl from './radio.html'
import './radio.scss'

const RadioComponent = {
  bindings: {},
  require: {
    radioGroupCtrl: '^cdblRadioGroup'
  },
  transclude: true,
  templateUrl,
  controller: class RadioComponent {
    constructor ($element, $attrs, $mdConstant) {
      'ngInject'
      this._identify = 'RadioComponent'
      this.$element = $element
      this.$attrs = $attrs
      this.$mdConstant = $mdConstant
    }

    $onInit () {
      if (!this.radioGroupCtrl) {
        throw new Error('CdblRadio: No CdblRadioGroupController could be found.')
      }

      this.$attrs.$set('tabindex', this.$attrs.tabindex || '0')
      this.$attrs.$set('type', 'radio')
      this.$attrs.$set('role', this.$attrs.type)
      this.$attrs.$set('id', this.$attrs.id)

      this.$element.children().on('focus', function () {
        this.$element.focus()
      })

      this.$element.on('click', this.onClickHandler.bind(this))
        .on('keypress', this.onKeypressHandler.bind(this))
    }


    get modelValue () {
      return this.radioGroupCtrl.ngModel.$viewValue
    }

    set modelValue (newValue) {
      this.radioGroupCtrl.ngModel.$setViewValue(
        newValue
      )
      this.radioGroupCtrl.ngModel.$setTouched()
      this.radioGroupCtrl.ngModel.$render()
    }

    get isChecked () {
      return this.modelValue === this.$attrs.value
    }

    onClickHandler (evt) {
      if (this.$attrs.disabled || evt.target.tagName === 'A' || this.radioGroupCtrl.$attrs.disabled) {
        evt.stopImmediatePropagation()
        return
      }
      this.modelValue = this.$attrs.value
    }

    onKeypressHandler (evt) {
      const keyCode = evt.which || evt.keyCode

      evt.preventDefault()

      switch (keyCode) {
      case this.$mdConstant.KEY_CODE.SPACE:
        this.onClickHandler(evt)
        break
      case this.$mdConstant.KEY_CODE.ENTER:
        this.onClickHandler(evt)
        break
      }
    }
  }
}

export default RadioComponent
