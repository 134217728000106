import SettingsInvoicesComponent from './settings-invoices.component'

const SettingsInvoicesModule = angular.module('cdbl.settings-invoices', [

])
  .component('cdblSettingsInvoices', SettingsInvoicesComponent)
  .config(($stateProvider) => {
    'ngInject'

    $stateProvider
      .state('settings.invoices', {
        url: '/invoices',
        views: {
          'settingsView': {
            template: '<cdbl-settings-invoices></cdbl-settings-invoices>'
          }
        },
        resolve: {
          permissionResolver: (AuthService) => {
            'ngInject'
            return AuthService.permissionResolver('isClient')
          }
        },
        layout: 'new'
      })
  })
  .name

export default SettingsInvoicesModule
