import templateUrl from './project-expert-pods.html'
import './project-expert-pods.scss'

const ProjectExpertPodsComponent = {
  bindings: {
    project: '<?'
  },
  templateUrl,
  controller: class ProjectExpertPods {
    constructor (UserService) {
      'ngInject'
      this._identify = 'ProjectExpertPodsComponent'
      this.UserService = UserService
    }

    $onInit () {

    }

    get showExpertPods () {
      return this.UserService.isExpert() && this.project && this.project.expertPodsProjects && this.project.expertPodsProjects.length
    }
  }
}

export default ProjectExpertPodsComponent
