const TaskExpertFeedbackService = class TaskExpertFeedbackService {
  constructor ($q, $http, $timeout, Configuration) {
    'ngInject'
    this.$q = $q
    this.$http = $http
    this.$timeout = $timeout
    this.Configuration = Configuration
  }

  getTaskExpertFeedback (taskId) {
    return this.$http
      .get(`${this.Configuration.apiUrl}/projects/${taskId}/feedback`)
  }

  putTaskExpertFeedback (taskId, payload) {
    return this.$http
      .put(`${this.Configuration.apiUrl}/projects/${taskId}/feedback`, payload)
  }
}
export default TaskExpertFeedbackService

