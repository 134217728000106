(function () {
  'use strict';

  function stars() {
    var link = function ($scope) {
      $scope.stars = [{}, {}, {}, {}, {}]; // 5 stars

      if (!$scope.score && !$scope.readOnly) {
        $scope.score = 0;
      } else {
        _markStars(Math.round($scope.score));
      }

      $scope.$watch('score', function (score) {
        if (score) {
          _markStars(Math.round(score));
        }
      });

      $scope.hoverStars = function (index) {
        _markStars(index);
      };

      $scope.leaveStars = function () {
        if ($scope.updateReview) {
          _markStars(Math.round($scope.score));
        } else {
          _markStars(0);
        }
      };

      $scope.selectStar = function (index) {
        $scope.score = index;

        if ($scope.updateReview) {
          $scope.$parent.score = index;
        }

        if ($scope.createReview) {
          $scope.createReview({
            score: index
          });
        }
      };

      function _markStars(index) {
        for (var i = 0; i < 5; i++) {
          $scope.stars[i].marked = (index > i);
        }
      }
    };

    return {
      restrict: 'E',
      replace: false,
      scope: {
        score: '=',
        readOnly: '=',
        createReview: '&',
        updateReview: '='
      },
      templateUrl: require('../../views/directives/stars.html'),
      link: link
    };
  }

  app.directive('stars', stars);
})();
