/*
  NOTES

- This service is intended for all project related api that cant belong anywhere else - only refactored code permitted here
- Events that are user notifications: create_estimate
- All project states:
  - abandoned E
  - canceled E
  - completed W
  - created E
  - estimated E
  - hired E
  - paid W
  - published E
  - refunded E
  - withheld E (should not be returned by BE)
- Project states that are "workrooms": paid, completed
 */

// TODO: create Project class with methods and migreate appropriate ones from service to that class

const EVENTS_TO_UPDATE_STATE = [
  'estimate-updated',  // expert estimates project
  'invitation-created', // client hires expert
]

const ProjectService = class ProjectService {
  constructor ($window, $http, $filter, Configuration, SegmentAnalytics, UserService, Modal, EventBusService, $stateParams, ModalService) {
    'ngInject'

    this.$window = $window
    this.$http = $http
    this.$filter = $filter
    this.Configuration = Configuration
    this.SegmentAnalytics = SegmentAnalytics
    this.UserService = UserService
    this.Modal = Modal // TODO: replace legacy modals with new modals
    this.EventBusService = EventBusService
    this.$stateParams = $stateParams
    this.ModalService = ModalService

    this._project = null // TODO: set project object on route access
  }

  // Event methods
  // --------------------------------------------------------------

  addListenersUpdateProjectOnEvent () {
    // Universal callback to handle different events and state changes to project or other entities
    const updateStateListener = (event) => {
      console.log('[ProjectService] > updateProjectState', event.pusherEvent, event)

      if (event) {
        // Expert estimates project
        if (event.pusherEvent === 'estimate-updated') {
          this._project.state = 'estimated'
          this._project.prices = event.data.prices
          // this._project = {
          //   ...this.project,
          //   state: 'estimated',
          //   prices: event.data.prices
          // }
        }

        // Client hires expert
        if (event.pusherEvent === 'invitation-created') {
          this.project.state = event.data.taskState
          this.project.contractor = event.data.expert

          // this.getProject(this.project.id)
          //   .then(project => {
          //     console.log('[ProjectService] > updateProjectState > getProject', project)
          //     this._project = project
          //   })
        }
      }
    }

    EVENTS_TO_UPDATE_STATE.forEach(event => {
      this.EventBusService.channel('legacy').subscribe(event, updateStateListener)
    })
  }
  removeListenersUpdateProjectOnEvent () {
    EVENTS_TO_UPDATE_STATE.forEach(event => {
      this.EventBusService.channel('legacy').unsubscribe(event)
    })
  }


  // Utility methods
  // --------------------------------------------------------------

  // PROJECT
  setProject (project) {
    console.log('[ProjectService] > setProject', project)
    this._project = project
    this.addListenersUpdateProjectOnEvent()
  }

  unsetProject () {
    console.log('[ProjectService] > unsetProject')
    this.removeListenersUpdateProjectOnEvent()
    this._project = null
  }

  // TODO: migrate this method to additional tasks service instead (at some point additional tasks should be returned via service and not via project + BE separation of endpoints)
  addAdditionalTaskToProject (task, prepend = true) {
    if (!this.project) {
      return
    }

    if (!Array.isArray(this.project.subtasks)) {
      this.project.subtasks = []
    }

    console.log('[ProjectService] > addAdditionalTaskToProject', task, this.project.subtasks)

    // Add only if it does not exist yet - condition should be removed after fully removing legacy pusher event
    if (!this.project.subtasks.find(t => t.id === task.id)) {
      if (prepend) {
        this.project.subtasks.unshift(task)
      } else {
        this.project.subtasks.push(task)
      }
    }
  }

  get isProjectSet () {
    return Boolean(this._project)
  }

  get project () {
    return this._project
  }

  isInState (project, stateToCheck = '') {
    const statesToCheck = Array.isArray(stateToCheck) ? stateToCheck : stateToCheck.split('|')
    return statesToCheck.includes(project.state)
  }

  isNotInState (project, stateToCheck = '') {
    return !this.isInState(project, stateToCheck)
  }

  hasPreferredExpert (project) {
    return project && (
      (project.preferredContractors && project.preferredContractors.length > 0) ||
      (project.currentUserIsPreferredContractor) // handle dashboard project structure
    )
  }

  canPublishProjectForAll (project) {
    return this.hasPreferredExpert(project) && !project.isPublishedForAll
  }

  hasExpertPods (project) {
    return project && project.expertPodsProjects && project.expertPodsProjects.length
  }

  isPosted (project) {
    return (this.isConsultation(project) || !project.staysPreferred) &&
          !this.hasPreferredExpert(project)
  }

  isPublishedForEveryone (project) {
    return (this.isConsultation(project) || !project.staysPreferred) &&
      this.hasPreferredExpert(project) &&
      this.$filter('timeDifference')(project.publishedAt, 'hours') >= 0
  }

  isStaysPreferred (project) {
    return (
      (
        this.isConsultation(project) ||
        !project.staysPreferred
      ) &&
      this.hasPreferredExpert(project) &&
      this.$filter('timeDifference')(project.publishedAt, 'hours') < 0
    ) ||
    (
      !this.isConsultation(project) &&
      project.staysPreferred
    )
  }

  // Is project in workroom (private | paid) mode or in estimation mode (public, hired is exception and this should be revisited)
  isWorkroom (project) {
    // TODO: use project object from service when impmented, now temporarly use project from passed argument
    return this.isNotInState(project, [
      'created',
      'published',
      'canceled',
      'estimated',
      'hired',
      'refunded',
      'abandoned',
      'withheld'
    ])
  }

  isConsultation (project) {
    return project.kind === 'consultation'
  }

  isV3 (project) {
    return project?.submissionFormVersion >= '3'
  }

  isEditBudgetAllowed (project) {
    return (
      this.isInState(project, [
        'created',
        'published'
      ]) &&
      !this.isConsultation(project) &&
      project.submissionFormVersion < '2.0' &&
      this.UserService.isClient()
    )
  }

  isEditDescriptionAllowed (project) {
    return (
      this.isInState(project, [
        'created',
        'published'
      ]) &&
      this.UserService.isClient()
    )
  }

  isEligibleForScopeDoc (project) {
    return project && project.eligibleForScopeDoc
  }

  formatProjectDate (project, options = {}) {
    const dateFormat = options.format === 'short' ? 'DD MMM YYYY' : 'DD MMM YYYY h:mm:ss A'
    const isPreferred = this.hasPreferredExpert(project)
    const staysPreferred = project.staysPreferred
    // const date = project.createdAt || project.publishedAt
    let label = 'Posted'
    let value = this.$filter('dateFormat')(project.createdAt, dateFormat)

    if (this.UserService.isExpert() && isPreferred) {
      label = 'Stays Preferred'
    }

    if (this.UserService.isExpert()) {
      value = (isPreferred && staysPreferred) ? 'Indefinitely' : this.$filter('dateFormat')(project.publishedAt, dateFormat)
    }

    return {
      label,
      value
    }
  }

  // ---------
  // LEAGACY functions

  // TODO: use service task object instead of argument
  // TODO: refactor modals
  openPublishTaskAllModal (project) {
    this.Modal.open('views/modals/publish_task_all.html', { task: project })
  }

  // TODO: use service task object instead of argument
  // TODO: refactor modals
  // TODO: rename to openCancelProjectModal
  openCancelTaskModal (project) {
    console.log('[ProjectService] > openCancelTaskModal', project)
  }
  // ---------

  /**************************************************************
  /* API
  /**************************************************************/

  // Project
  getProject (projectId) {
    return this.$http
      .get(`${this.Configuration.apiUrl}/tasks/${projectId}`)
      .then(response => {
        // Remove partner in mentioned parter, if it matches project's referral - API SHOULD DO THIS... -
        response.data.mentionedPartners = response.data.mentionedPartners.filter(partner =>
          partner.trackingName !== response.data.partnerName
        )
        return response.data
      })
  }
  // Alias for backward compatibility
  getTask (projectId) {
    return this.getProject(projectId)
  }


  // CLIENT API
  hireExpert (project, expert) {
    const payload = {
      userId: expert.id
    }

    return this.$http
      .post(`${this.Configuration.apiUrl}/tasks/${project.id}/invitations`, payload)
      .then(response => {
        console.log('[ProjectService] > hireExpert > response', response)
        this.SegmentAnalytics.trackTaskConfirmed(project, expert)  // TODO move where its called

        // update project for client right
        this.project.state = 'hired'
        this.project.contractor = expert
        return response.data
      })
      .catch(function (err) {
        console.log(err)
      })
  }

  updateProjectDescription (project, newDescription) {
    const payload = {
      description: newDescription
    }

    return this.$http
      .put(`${this.Configuration.apiUrl}/tasks/${project.id}/description`, payload)
      .then(response => {
        console.log('[ProjectService] > updateProjectDescription > response', response)
        this.SegmentAnalytics.trackProjectDescriptionEdited(project) // TODO move where its called
        return response.data
      })
      .catch(function (err) {
        console.log(err)
      })
  }

  convertProjectToConsultation (projectId) {
    console.log('[ProjectService] > convertProjectToConsultation', projectId)

    return this.$http
      .put(`${this.Configuration.apiUrl}/tasks/${projectId}/type_conversion`, { type: 'consultation' })
      .then(response => {
        return response.data
      })
  }

  cancelProject (projectId) {
    console.log('[ProjectService] > cancelProject', projectId)

    return this.$http
      .put(`${this.Configuration.apiUrl}/tasks/${projectId}/cancel`)
      .then(response => {
        return response.data
      })
  }




  // EXPERT API
  favouriteProject (projectId) {
    console.log('[ProjectService] > favouriteProject', projectId)

    const payload = {
      taskId: projectId
    }

    return this.$http
      .put(`${this.Configuration.apiUrl}/users/me/tasks/favourites`, payload)
      .then(response => {
        return response.data
      })
  }

  unfavouriteProject (projectId) {
    console.log('[ProjectService] > unfavouriteProject', projectId)
    return this.$http
      .delete(`${this.Configuration.apiUrl}/users/me/tasks/favourites/${projectId}`)
      .then(response => {
        return response.data
      })
  }


  // Reminders
  // TODO: move into separate service once legacy code for this is fully refactored
  getReminderState (reminderName) {
    if (!this.project || !this.project._reminders) {
      return false
    }
    console.log('[ProjectService] > showReminder', reminderName, this.project._reminders[reminderName])
    return this.project._reminders[reminderName]
  }

  setReminder (reminderName, state) {
    if (!this.project || !this.project._reminders) {
      return false
    }

    // TODO make endpoint call
    const payload = {
      user_id: this.UserService.user.id // TODO: [wat] - why does BE need this - it could get data about user from request session
    }
    payload[reminderName] = state

    return this.$http
      .put(`${this.Configuration.apiUrl}/tasks/${this.project.id}/project_reminders`, payload)
      .then(response => {
        Object.assign(this.project._reminders, response.data.reminders) // update
      })

    // Status 200
    // {
    //   "id" : integer
    //   "user_id" : integer
    //   "project_id" : integer
    //   "reminders" : {
    //     "estimation_check" : boolean
    //     , "hire_check" : boolean
    //     , "completion_check" : boolean
    //   }
    // }

    // Status 403
    // {
    //     "error": "forbidden",
    //     "reason": "not_authorized",
    //     "message": "Not authorized."
    // }
    // ```
  }
  getReminders () {
    if (!this.project) {
      return
    }

    this.$http
      .get(`${this.Configuration.apiUrl}/tasks/${this.project.id}/project_reminders`)
      .then(response => {
        this.project._reminders = response.data.reminders
      })
    // #### To enable the FE to retrieve the reminders for a project

    // ```
    // GET /tasks/:project_id/project_reminders

    // Status 200
    // {
    //   "id" : integer
    //   "user_id" : integer
    //   "project_id" : integer
    //   "reminders" : {
    //     "estimation_check" : boolean
    //     , "hire_check" : boolean
    //     , "completion_check" : boolean
    //   }
    // }

    // Status 403
    // {
    //     "error": "forbidden",
    //     "reason": "not_authorized",
    //     "message": "Not authorized."
    // }
    // ```
  }
  canShowAdditionalTasks (project) {
    return this.isInState(project, [
      'paid',
      'completed',
      'refunded',
    ])
  }
  handleStripePaymentConfirmation (project) {
    // Stripe check after redirect
    const paidStripeSubTask = project.subtasks.find(subTask => subTask.id.toString() === this.$stateParams.sub_task_id)

    if (this.$stateParams.payment_completed) {
      const task = project
      if (paidStripeSubTask) {
        this.SegmentAnalytics.trackSubTaskPayment(task, paidStripeSubTask, 'stripe')
      } else {
        this.SegmentAnalytics.trackTaskPayment(task, 'stripe')
      }
      this.SegmentAnalytics.trackPayment(task, paidStripeSubTask || null, 'stripe')

      this.ModalService.open({
        closeOnBackdrop: false,
        class: 'payment-modal',
        layout: 'center',
        // Check if it's a project or subtask
        ...(this.$stateParams.task_id) && { task: project, initialStep: 'payment-successful', template: `<cdbl-payment-modal task="$ctrl.modal.task" initial-step="payment-successful"></cdbl-payment-modal>` },
        ...(paidStripeSubTask) && {
          subTask: paidStripeSubTask,
          initialStep: paidStripeSubTask.retainer ? 'payment-successful-task-retainer' : 'payment-successful-task',
          template: `<cdbl-payment-modal sub-task="$ctrl.modal.subTask" initial-step="${paidStripeSubTask.retainer ? 'payment-successful-task-retainer' : 'payment-successful-task'}"></cdbl-payment-modal>`
        }
      })
    } else if (this.$stateParams.payment_cancelled) {
      this.ModalService.open({
        closeOnBackdrop: false,
        class: 'payment-modal',
        layout: 'center',
      // Check if it's a project or subtask
        ...(this.$stateParams.task_id) && { task: project, initialStep: 'payment-canceled', template: `<cdbl-payment-modal task="$ctrl.modal.task" initial-step="payment-canceled"></cdbl-payment-modal>` },
        ...(paidStripeSubTask) && { subTask: paidStripeSubTask, initialStep: 'payment-canceled', template: `<cdbl-payment-modal sub-task="$ctrl.modal.subTask" initial-step="payment-canceled"></cdbl-payment-modal>` }
      })
    }
  }

  setProjectPageTitle () {
    if (this.project?.title) {
      const maxLength = 20
      let projectTitle = this.project.title
      if (projectTitle.length > maxLength) {
        projectTitle = projectTitle.substring(0, maxLength) + '...'
      }

      this.$window.document.title = `${projectTitle} | Codeable`
    }
  }

  showPartnerModal (partners, referring) {
    if (partners) {
      this.ModalService.open({
        closeOnBackdrop: true,
        closeIcon: true,
        class: 'partner-details-modal cdbl-modal--style-with-sticky-header-and-footer',
        template: `<cdbl-partner-details-modal partners="$ctrl.modal.partners" referring="$ctrl.modal.referring"></cdbl-partner-details-modal>`,
        partners: partners,
        referring: referring
      })
    }
  }
}
export default ProjectService
