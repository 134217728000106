/**
 * Tail-hook ngCloak to delay the uncloaking while Layout transformers
 * finish processing. Eliminates flicker with Material.Layouts
 */

const ngCloakDirective = function () {
  return function ($timeout) {
    'ngInject'
    return {
      restrict: 'A',
      priority: -10,   // run after normal ng-cloak
      compile: function (element) {
        // Re-add the cloak
        element.addClass('ng-cloak')

        return function (scope, element) {
          // Wait while layout injectors configure, then uncloak
          // NOTE: $rAF does not delay enough... and this is a 1x-only event,
          //       $timeout is acceptable.
          $timeout(function () {
            element.removeClass('ng-cloak')
          }, 10, false)
        }
      }
    }
  }
}

export default ngCloakDirective
