(function () {
  'use strict';

  function createOverflowRightSide($window) {
    return {
      restrict: 'A',
      link: function ($scope, elem) {
        var moduleMarginBottom = parseInt(elem.css('margin-bottom'), 10) || 0;

        setTimeout(resizeAttachments, 0);

        $($window).bind('resize', function () {
          resizeAttachments();
        });

        $scope.$on('flashDirChange', function () {
          setTimeout(resizeAttachments);
        });

        function resizeAttachments() {
          var windowHeight = $($window).height();
          var rightSideOffsetTop = elem.offset().top;
          var rightSideHeight = windowHeight - rightSideOffsetTop - moduleMarginBottom;

          elem.css({height: rightSideHeight + 'px'});
        }
      }
    };
  }

  app.directive('createOverflowRightSide', ['$window', createOverflowRightSide]);
})();
