import templateUrl from './notifications.html'
import './notifications.scss'

const NotificationsComponent = {
  bindings: {},
  templateUrl,
  controller: class Notifications {
    constructor ($mdMedia, UIService, UserService) {
      'ngInject'
      this._identify = 'NotificationsComponent'
      this.UIService = UIService
      this.$mdMedia = $mdMedia
      this.UserService = UserService
    }

    $onInit () {

    }

    get showSidebar () {
      return this.UserService.isExpert()
    }

    closeSidebar () {
      this.sidebar = this.UIService.getComponent('sidebar-main-left')
      if (this.sidebar) {
        this.sidebar.close()
      }
    }
  }
}

export default NotificationsComponent
