import templateUrl from './comment.html'
import './comment.scss'

const CommentComponent = {
  bindings: {
    comment: '<'
  },
  templateUrl,
  controller: class Comment {
    constructor () {
      'ngInject'
      this._identify = 'CommentComponent'
    }
  }
}

export default CommentComponent
