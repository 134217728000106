const ProjectBrowsePodsService = class ProjectBrowsePodsService {
  constructor ($q, $timeout, $http, Configuration, UserService) {
    'ngInject'
    this.$q = $q
    this.$timeout = $timeout
    this.$http = $http
    this.Configuration = Configuration
    this.UserService = UserService

    this.pods = null
  }
  get showPodsPage () {
    return this.pods && this.pods.expertPodsMemberships && this.pods.availableExpertPods && (this.pods.expertPodsMemberships.length || this.pods.availableExpertPods.length)
  }
  getPodsList () {
    return this.$http.get(`${this.Configuration.apiUrl}/experts/expert_pods/memberships`)
      .then(response => {
        this.pods = response.data
        return response.data
      })
  }
}
export default ProjectBrowsePodsService
