(function () {
  'use strict';

  function ApiLogging($q, $http, $location, Configuration) {
    const error = function (context, name, error, resource = null) {
      let payload = {
        message: 'ERROR: ' + context + ', ' + name,
        data: {
          context: context,
          name: name,
          error: error,
          resource: resource
        }
      };
      console.log('ApiLogging > error', payload);

      let deferred = $q.defer();
      $http
        .post(Configuration.apiUrl + '/log/errors', payload)
        .then(function (response) {
          deferred.resolve(response.data);
        })
        .catch(function (err) {
          console.log('ApiLogging > error > failed');
          deferred.reject(err);
        });
      return deferred.promise;
    };

    const info = function (context, name, data, resource = null) {
      let payload = {
        message: 'INFO: ' + context + ', ' + name,
        data: {
          context: context,
          name: name,
          data: data,
          resource: resource
        }
      };
      console.log('ApiLogging > info', payload);

      let deferred = $q.defer();
      $http
        .post(Configuration.apiUrl + '/log/infos', payload)
        .then(function (response) {
          console.log('success');
          deferred.resolve(response.data);
        })
        .catch(function (err) {
          console.log('ApiLogging > info > failed');
          deferred.reject(err);
        });
      return deferred.promise;
    };

    return {
      error: error,
      info: info
    };
  }

  app.service('ApiLogging', ['$q', '$http', '$location', 'Configuration', ApiLogging]);
})();
