import templateUrlClient from './project-stats-client.html'
import templateUrlExpert from './project-stats-expert.html'
import './project-stats.scss'

const ProjectStatsComponent = {
  bindings: {},
  template: `<div ng-include="$ctrl.templateUrl"></div>`,
  controller: class ProjectStats {
    constructor (UserService, ProjectDashboardService) {
      'ngInject'
      this._identify = 'ProjectStatsComponent'
      this.UserService = UserService
      this.ProjectDashboardService = ProjectDashboardService

      this.revenueStats = null
      this.walletStats = null
    }

    $onInit () {
      this.templateUrl = this.UserService.isClient() ? templateUrlClient : templateUrlExpert
      this.ProjectDashboardService.getRevenueStats()
        .then(response => {
          this.revenueStats = response
          this.walletStats = this.UserService.userWallet()
          console.log('ProjectStatsComponent > onInit > walletStats', this.walletStats)
        })
    }
  }
}

export default ProjectStatsComponent
