(function () {
  'use strict';

  function ServerErrorInterceptor($q, $injector, $rootScope, Modal, Configuration) {
    var previousUrl; // so we only change state once, when we have multiple 403 on a page

    return {
      responseError: function (response) {
        if (response.status === 0 || response.status === 500) {
          Modal.open('views/modals/server_error.html', $rootScope, { closeButton: false })
        } else if (response.status === 404) {
          let $state = $injector.get('$state')
          let $location = $injector.get('$location')

          var AuthService = $injector.get('AuthService') // inject AuthService without circular dependecny
          const is404exception = Configuration.router.preventRedirectOn404Urls.find(exceptionUrl => response.config.url.includes(exceptionUrl))
          if (!AuthService.isAuthenticated()) {
            console.log('ServerErrorInterceptor > 404 > not logged in');
          } else if ($location.url() !== previousUrl && !is404exception) {
            $state.go(Configuration.defaultRouterState.name, Configuration.defaultRouterState.options)
          }

          previousUrl = $location.url();
        } else if (response.status === 403) {
          let $state = $injector.get('$state');
          let $location = $injector.get('$location');

          // redirect if task is forbidden for user
          // console.log(response);
          // console.log($location.url());
          // console.log($state);
          // console.log(previousUrl);
          if ($location.url() !== previousUrl && $location.url().indexOf('tasks') !== -1) {
            var paths = $location.url().split('/');
            // console.log(paths);
            if (paths.length > 2 && !isNaN(paths[2])) {
              // console.log(isNaN(paths[2]));
              if (paths[1] === 'tasks' && !isNaN(paths[2])) {
                // console.log('redirect');
                $state.go('tasks.hiredExpert', {id: paths[2]});
              } else {
                $state.go(Configuration.defaultRouterState.name, Configuration.defaultRouterState.options);
              }
            }
          }

          previousUrl = $location.url();
        }

        return $q.reject(response);
      }
    };
  }

  app.factory('ServerErrorInterceptor', ['$q', '$injector', '$rootScope', 'Modal', 'Configuration', ServerErrorInterceptor]);

  app.config(['$httpProvider', function ($httpProvider) {
    $httpProvider.interceptors.push('ServerErrorInterceptor');
  }]);
})();
