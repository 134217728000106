import templateUrl from './loader.html'
import './loader.scss'

const LoaderComponent = {
  bindings: {
    customOptions: '<options'
  },
  transclude: true,
  templateUrl,
  controller: class LoaderComponent {
    constructor ($attrs) {
      'ngInject'
      this._identify = 'LoaderComponent'
      this.$attrs = $attrs

      this.defaultOptions = {
        showDefaultText: false,
        inline: false,
        spinnerOnly: false
      }
    }

    $onInit () {
      this.options = Object.assign(this.defaultOptions, this.customOptions)
    }
  }
}

export default LoaderComponent
