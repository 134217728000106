import templateUrl from './scope-doc-activity.html'
import './scope-doc-activity.scss'

const ProjectScopeDocActivityComponent = {
  bindings: {
    activity: '<',
    project: '<'
  },
  templateUrl,
  controller: class ProjectScopeDocActivity {
    constructor () {
      'ngInject'
      this._identify = 'ProjectScopeDocActivityComponent'
    }

    get canShowActivity () {
      // TODO: move to configuration and reuse in other places
      return [
        'create_scope_doc_submission',
        'create_scope_doc_rejection',
        'create_scope_doc_approval',
        'create_scope_doc_finish',
        'create_scope_doc_item_finish'
      ].includes(this.activity.verb)
    }

    get expertName () {
      return this.project.contractor.fullName
    }
    get clientName () {
      return this.project.client.fullName
    }
  }
}

export default ProjectScopeDocActivityComponent
