/* eslint-disable */
const AttachmentsService = class AttachmentsService {
  constructor ($q, $http, $timeout, $window, Configuration, SegmentAnalytics, ApiLogging, FileUploadWrapperFactory) {
    'ngInject'

    this.$q = $q
    this.$http = $http
    this.$timeout = $timeout
    this.$window = $window
    this.Configuration = Configuration
    this.FileUploadWrapperFactory = FileUploadWrapperFactory

    this.config = Configuration // TODO: delete
    this.SegmentAnalytics = SegmentAnalytics
    this.ApiLogging = ApiLogging
  }

  /**
   * Handle timeout for updating attachment signed url via callbacks for multiple regions (sidebar, modal, activities)
   * @param  {Object} config { expiresAt, cancelPromise, callback }
   */
  timeoutUpdateAttachmentsData (config, origin) {
    const currentTime = new Date().getTime()

    const TRIGGER_BEFORE_EXPIRATION = 60 * 60 * 1000 // 1h in milliseconds
    const DEFAULT_TIMEOUT = 24 * 60 * 60 * 1000 + currentTime// 24h in milliseconds from current time

    let expiresAtTime = DEFAULT_TIMEOUT // as a guard set it to default timeout
    if (config.expiresAt) {
      const expiresAtTmp = new Date(config.expiresAt).getTime()

      // Make sure expiration date in expiration config is set in the future before using it
      if (expiresAtTmp > currentTime) {
        expiresAtTime = expiresAtTmp
      }
    }

    // const expiresAtTime = config.expiresAt ? new Date(config.expiresAt).getTime() : DEFAULT_TIMEOUT
    const timeoutDuration = Math.max(0, expiresAtTime - currentTime - TRIGGER_BEFORE_EXPIRATION)
    // const timeoutDuration = 50000 // TODO: remove - only for dev
    console.log('[AttachmentsService] > timeoutUpdateAttachmentsData', `${timeoutDuration/1000}s (${timeoutDuration/1000/60/60}h)`, origin)
    if (config.timeoutPromise) {
      this.$timeout.cancel(config.timeoutPromise)
    }

    config.timeoutPromise = this.$timeout(() => {
      if (config.callback) {
        config.callback()
      }

      console.log('[AttachmentsService] > timeoutUpdateAttachmentsData > trigger')
    }, timeoutDuration)
  }
  cancelTimeoutUpdateAttachmentsData (config) {
    if (config && config.timeoutPromise) {
      console.log('[AttachmentsService] > cancelTimeoutUpdateAttachmentsData > config', config)
      this.$timeout.cancel(config.timeoutPromise)
    }
  }

  getAttachmentList (projectId, params = null) {
    if (params && Array.isArray(params.ids)) {
      params.ids = params.ids.join(',')
    }

    const config = {
      params
    }
    console.log('[AttachmentsService] > getAttachmentList > config', config)
    return this.$http
      .get(`${this.Configuration.apiUrl}/tasks/${projectId}/attachments`, config)
      .then(response => {
        return response.data
      })
  }

  deleteAttachment (projectId, attachmentId) {
    return this.$http
      .delete(`${this.Configuration.apiUrl}/tasks/${projectId}/attachments/${attachmentId}`)
      .then(response => {
        return response.data
      })
  }

  getAttachmentDownloadSignedUrl (projectId, attachmentId) {
    return this.$http
      .get(`${this.Configuration.apiUrl}/tasks/${projectId}/attachments/${attachmentId}`)
      .then(response => {
        return response.data
      })
  }
  downloadAttachment (projectId, attachmentId) {
    return this.getAttachmentDownloadSignedUrl(projectId, attachmentId)
      .then(response => {
        if (response.url) {
          this.$window.open(response.url, '_blank')
          this.$window.focus() // to keep focus in current tab
        }
        return response
      })
  }

  async getAttachmentUploadSignedUrls (projectId, queueList = [], errorHandler = angular.noop) {
    const payload = queueList.map(qfile => {
      return {
        filename: qfile.fileObject.name,
        contentType: qfile.fileObject.type
      }
    })
    console.log('[AttachmentsService] > getAttachmentUploadSignedUrls', payload)

    return this.$http
      .post(`${this.Configuration.apiUrl}/tasks/${projectId}/attachments/signed_urls`, payload)
      .then(response => {
        // endpoint returns urls, enrich this data with fileObjects for easier usage
        response.data.forEach(signedUrl => {
          const qFile = queueList.find(qf => qf.fileObject.name === signedUrl.filename)
          if (qFile) {
            qFile.setSignedUrl(signedUrl)
          }
        })
        return response.data
      })
  }

  async postAttachmentToProject (projectId, fileObject) {
    const payload = {
      name: fileObject.name,
      url: fileObject.name,
      size: fileObject.size // TODO: should be removed on BE first then on FE
    }
    return this.$http
      .post(`${this.Configuration.apiUrl}/tasks/${projectId}/attachments`, payload)
      .then(response => response.data)
      // .catch(err => { console.error(err) })
  }

  async uploadFileToGCS (wrappedFile) {
    console.log('[AttachmentsService] > uploadFileToGCS > fileObject', wrappedFile)
    return this.$http
      .put(wrappedFile.signedUrlUpload, wrappedFile.fileObject, {
        headers: {
          'Accept': '*/*',
          'Content-Type': wrappedFile.fileObject.type
        },
        disableAuthInterceptor: true, // override the defaults used for app
        disableDataInterceptor: true, // override the defaults used for app
        withCredentials: false,       // override the defaults used for app
        uploadEventHandlers: {        // add support for progress update via callback fn
          progress: function (event) {
            const percentage = Math.floor((event.loaded / event.total) * 100)
            if (wrappedFile.onUploadFileProgressHandler) {
              wrappedFile.onUploadFileProgressHandler(wrappedFile.fileObject, percentage)
            }
          }
        },
        timeout: wrappedFile.httpRequestCanceller.promise // use promise.resolve to abort the exisitng request - need to find a good way to put this in here and expose it to consumer part of the app
      })
      .then(response => response)
      // .catch(err => { console.error(err) })
  }

  extractFilenameOnly (filenameRaw, offset = 0) {
    if (typeof filenameRaw === 'string') {
      let tmp = filenameRaw.split(/(.[^.]+)$/i)
      if (tmp[0].length - offset < 2) {
        offset = Math.min(tmp[0].length - 2, 0)
      }
      let re = new RegExp('(.{' + offset + '}.[^.]+)$', 'i') //  /(.[^.]+)$/i; //
      let list = filenameRaw.split(re)
      return list[0]
    }

    return filenameRaw
  }

  extractFileExtensionOnly (filenameRaw, offset = 0) {
    if (typeof filenameRaw === 'string') {
      let tmp = filenameRaw.split(/(.[^.]+)$/i)
      if (tmp[0].length - offset < 2) {
        offset = Math.min(tmp[0].length - 2, 0)
      }
      let re = new RegExp('(.{' + offset + '}.[^.]+)$', 'i') //  /(.[^.]+)$/i; //
      let list = filenameRaw.split(re)
      return list[1]
    }

    return filenameRaw
  }


  // Universal API for any entity
  entityGetAttachmentList (urlSlug, params = null) {
    if (params && Array.isArray(params.ids)) {
      params.ids = params.ids.join(',')
    }

    const config = {
      params
    }
    return this.$http
      .get(`${this.Configuration.apiUrl}${urlSlug}`, config)
      .then(response => {
        return response.data
      })
  }

  entityDeleteAttachment (urlSlug, attachmentId) {
    return this.$http
      .delete(`${this.Configuration.apiUrl}${urlSlug}/${attachmentId}`)
      .then(response => {
        return response.data
      })
  }

  entityGetAttachmentDownloadSignedUrl (urlSlug, attachmentId) {
    return this.$http
      .get(`${this.Configuration.apiUrl}${urlSlug}/${attachmentId}`)
      .then(response => {
        return response.data
      })
  }
  entityDownloadAttachment (urlSlug, attachmentId) {
    return this.getAttachmentDownloadSignedUrl(urlSlug, attachmentId)
      .then(response => {
        if (response.url) {
          this.$window.open(response.url, '_blank')
          this.$window.focus() // to keep focus in current tab
        }
        return response
      })
  }

  entityGetSignedUrls(urlSlug, queueList = [], errorHandler = angular.noop) {
    console.log('[AttachmentsService] > entityGetSignedUrls', urlSlug, queueList)

    if (typeof urlSlug !== 'string') {
      throw new Error('Attachments entity signed url - url slug missing.')
      return
    }

    const payload = queueList.map(qfile => {
      return {
        filename: qfile.fileObject.name,
        contentType: qfile.fileObject.type
      }
    })
    console.log('[AttachmentsService] > entityGetSignedUrls', payload)

    return this.$http
      .post(`${this.Configuration.apiUrl}${urlSlug}`, payload)
      .then(response => {
        // endpoint returns urls, enrich this data with fileObjects for easier usage
        response.data.forEach(signedUrl => {
          const qFile = queueList.find(qf => qf.fileObject.name === signedUrl.filename)
          if (qFile) {
            qFile.setSignedUrl(signedUrl)
          }
        })
        return response.data
      })
  }

  entityAttachFile (urlSlug, fileObject) {
    const payload = {
      name: fileObject.name,
      url: fileObject.name,
      size: fileObject.size // TODO: should be removed on BE first then on FE
    }
    return this.$http
      .post(`${this.Configuration.apiUrl}${urlSlug}`, payload)
      .then(response => response.data)
      // .catch(err => { console.error(err) })
  }

  downloadAllAttachments (projectId) {
    // https://stackoverflow.com/questions/66986527/downloading-a-file-in-response-to-post-request
    return this.$http
      .get(`${this.Configuration.apiUrl}/tasks/${projectId}/attachments/zip`, {
        responseType: 'blob',
        headers: {
          'Accept': 'application/zip, application/vnd.codeable.v1',
          'Content-Type': 'application/zip'
        },
        disableRequestDataInterceptor: true,
        disableResponseDataInterceptor: true,
        // disableDataInterceptor: true,
      })
      .then(response => {
        const fileName = response.headers('content-disposition').split('=')[1].split(';')[0].replace(/\"/gi,'')
        const fileType = response.headers('content-type') + ';charset=utf-8'
        const blob = new Blob([response.data], { type: fileType })
        const objectUrl = window.URL || window.webkitURL
        const link = angular.element('<a/>')

        console.log('[AttachmentsService] > downloadAllAttachments > response', fileName, fileType)

        link.css({ display: 'none' })
        link.attr({
            href : objectUrl.createObjectURL(blob),
            target: '_blank',
            download : fileName
        })
        link[0].click()
        // clean up
        link.remove()
        objectUrl.revokeObjectURL(blob)
        return true
      })
      .catch(err => {
        if (err.status === 422) {
          throw new Error('You can archive up to 2 GB')
        } else {
          throw err
        }
      })
  }
}
export default AttachmentsService
