(function () {
  'use strict';

  function createOverflowSubTasks($window, _) {
    return {
      restrict: 'A',
      link: function ($scope, elem) {
        var mainMarginBottom = parseInt(angular.element('.main').css('margin-bottom'), 10) || 0;
        var modulePaddingBottom = parseInt(elem.parent().css('padding-bottom'), 10) || 0;
        var initialize = _.once(resizeScope);

        $scope.$on('subTasksTabOpened', function () {
          initialize();
        });

        $scope.$on('flashDirChange', function () {
          setTimeout(resizeScope);
        });

        $($window).bind('resize', function () {
          resizeScope();
        });

        function resizeScope() {
          var windowHeight = $($window).height();
          var scopeOffsetTop = elem.offset().top;
          var scopeHeight = windowHeight - scopeOffsetTop - mainMarginBottom - modulePaddingBottom;

          elem.css({height: scopeHeight + 'px'});
        }
      }
    };
  }

  app.directive('createOverflowSubTasks', ['$window', '_', createOverflowSubTasks]);
})();
