import templateUrl from './parent-field-control.html'
import './parent-field-control.scss'
import { fieldsLockedAllowEdit } from '../project-presets.config'

const ProjectPresetsParentFieldControlComponent = {
  bindings: {
    field: '<'
  },
  templateUrl,
  require: {
    'definerFormCtrl': '^cdblProjectPresetsDefinerForm',
    'parentEditCtrl': '?^cdblProjectPresetsParentEdit'
  },
  controller: class ProjectPresetsParentFieldControl {
    constructor ($element) {
      'ngInject'
      this._identify = 'ProjectPresetsParentFieldControlComponent'
      this.$element = $element
      this.text = {
        visible: 'Visible - definer can view/edit, client can view field.',
        hidden: 'Hidden - both definer and client do not see the field.',
        locked: 'Locked - client can not edit the field',
        unlocked: 'Unlocked - client can edit field'
      }
    }
    $onInit () {
      const fieldEntry = Object.entries(this.definerFormCtrl?.preset?.fields).find(entry => entry[1] === this.field)
      this.fieldName = fieldEntry ? fieldEntry[0] : ''
    }
    get isControlHidden () {
      return !this.definerFormCtrl.isParentEditMode && !this.canToggleHidden && !this.canToggleLocked
    }

    $doCheck () {
      this.$element.toggleClass('hidden', this.isControlHidden)
    }
    get canToggleHidden () {
      return this.definerFormCtrl.isParentEditMode
    }
    toggleHidden () {
      this.field.hidden = !this.field.hidden
    }


    get canToggleLocked () {
      if (!fieldsLockedAllowEdit.includes(this.fieldName)) {
        return false
      }

      if (this.definerFormCtrl.isParentEditMode) {
        return true
      }

      return !this.field.hidden && this?.definerFormCtrl?.preset?.fields?.definerCanEditLocked?.value
    }
    toggleLocked () {
      try {
        this.field.locked = !this.field?.locked
      } catch (err) {
        console.error('[ProjectPresetsDefinerFieldLockComponent] > toggleLock, error', err)
      }
    }
  }
}

export default ProjectPresetsParentFieldControlComponent
