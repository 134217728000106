const CodeableVariantSubmissionConfig = {
  displayName: 'Codeable',
  types: [
    {
      type: 'Installing or Configuring',
      title: 'Installing or Configuring',
      project_type: 'install',
      project_subject: '',
      icon: 'icon-project-idea',
      pricing: {
        multiplier: 1,
        initial: 210
      },
      options: [
        {
          value: 'theme',
          name: 'Theme'
        },
        {
          value: 'plugin',
          name: 'Plugin'
        },
        {
          value: 'other',
          name: 'Other'
        }
      ]
    },
    {
      type: 'Customizing',
      title: 'Customizing',
      project_type: 'customize',
      project_subject: '',
      icon: 'icon-project-idea',
      pricing: {
        multiplier: 2,
        initial: 420
      },
      options: [
        {
          value: 'theme',
          name: 'Theme'
        },
        {
          value: 'plugin',
          name: 'Plugin'
        },
        {
          value: 'site',
          name: 'Site'
        },
        {
          value: 'ecommerce_site',
          name: 'eCommerce site'
        },
        {
          value: 'other',
          name: 'Other'
        }
      ]
    },
    {
      type: 'Fixing',
      title: 'Fixing',
      project_type: 'fix',
      project_subject: '',
      icon: 'icon-project-idea',
      pricing: {
        multiplier: 1,
        initial: 210
      },
      options: [
        {
          value: 'theme',
          name: 'Theme'
        },
        {
          value: 'plugin',
          name: 'Plugin'
        },
        {
          value: 'site',
          name: 'Site'
        },
        {
          value: 'ecommerce_site',
          name: 'eCommerce site'
        },
        {
          value: 'other',
          name: 'Other'
        }
      ]
    },
    {
      type: 'Developing or Designing',
      title: 'Developing or Designing',
      project_type: 'design_and_develop',
      project_subject: '',
      icon: 'icon-project-idea',
      pricing: {
        multiplier: 1.2,
        initial: 980
      },
      options: [
        {
          value: 'theme',
          name: 'Theme'
        },
        {
          value: 'plugin',
          name: 'Plugin',
          pricing: {
            multiplier: 1,
            initial: 960
          }
        },
        {
          value: 'site',
          name: 'Site',
          pricing: {
            multiplier: 1.18,
            initial: 2500
          }
        },
        {
          value: 'ecommerce_site',
          name: 'eCommerce site',
          pricing: {
            multiplier: 1.38,
            initial: 3200
          }
        },
        {
          value: 'other',
          name: 'Other'
        }
      ]
    },
    {
      type: 'Migrating',
      title: 'Migrating',
      project_type: 'migrate',
      project_subject: '',
      icon: 'icon-project-idea',
      pricing: {
        multiplier: 1,
        initial: 210
      },
      options: [
        {
          value: 'site',
          name: 'Site'
        },
        {
          value: 'ecommerce_site',
          name: 'eCommerce site'
        },
        {
          value: 'other',
          name: 'Other'
        }
      ]
    },
    {
      type: 'Other',
      title: 'Other',
      project_type: 'other',
      project_subject: 'other',
      icon: 'icon-project-idea',
      pricing: null,
      options: [
        {
          value: 'other',
          name: 'Other'
        }
      ]
    },
    {
      type: 'Consultation',
      title: 'Consult an Expert',
      project_type: 'consultation',
      project_subject: 'consultation',
      icon: 'icon-expert-consult',
      pricing: null,
      options: [
        {
          value: 'consultation',
          name: 'Consultation'
        }
      ]
    }
  ]
}

export default CodeableVariantSubmissionConfig
