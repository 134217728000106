import templateUrl from './banner.html'
import './banner.scss'

const BannerComponent = {
  bindings: {},
  templateUrl,
  controller: class Banner {
    constructor () {
      'ngInject'
      this._identify = 'BannerComponent'
    }
  }
}

export default BannerComponent
