import SettingsVaultComponent from './settings-vault.component'

const SettingsVaultModule = angular.module('cdbl.settings-vault', [

])
  .component('cdblSettingsVault', SettingsVaultComponent)
  .config(($stateProvider) => {
    'ngInject'

    $stateProvider
      .state('settings.vault', {
        url: '/vault',
        views: {
          'settingsView': {
            template: '<cdbl-settings-vault></cdbl-settings-vault>'
          }
        },
        resolve: {
          permissionResolver: (AuthService) => {
            'ngInject'
            return AuthService.permissionResolver('isExpert')
          }
        },
        layout: 'new'
      })
  })
  .name

export default SettingsVaultModule
