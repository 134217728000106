import templateUrl from './submission-step-one-variant.html'
import './submission-step-one-variant.scss'

import { getFieldTooltipTemplate, getFieldComplexityLabel } from './submission-variant-helpers.js'

const SubmissionStepOneVariantComponent = {
  bindings: {
    config: '<',
    data: '<',
    substep: '<',
    preferredExpertsFromUrl: '<' // hack solution for notifiyng this controller that v1 loaded pe
  },
  templateUrl,
  require: {
    SubmissionCtrl: '^cdblSubmission'
  },
  controller: class SubmissionStepOneVariant {
    constructor ($scope, $window, $anchorScroll, $stateParams, $filter, $http, SegmentAnalytics, IntercomService, SubmissionService, UserService, Configuration, ExperimentService, DevService, StorageService, SubmissionDraftService, ModalService, $timeout, debounce) {
      'ngInject'
      this._identify = 'SubmissionStepOneVariantComponent'
      this.$window = $window
      this.$anchorScroll = $anchorScroll
      this.IntercomService = IntercomService
      this.SegmentAnalyticsService = SegmentAnalytics
      this.$stateParams = $stateParams
      this.$filter = $filter
      this.$http = $http
      this.$scope = $scope
      this.Configuration = Configuration
      this.SubmissionService = SubmissionService
      this.UserService = UserService
      this.ExperimentService = ExperimentService
      this.DevService = DevService
      this.StorageService = StorageService
      this.SubmissionDraftService = SubmissionDraftService
      this.ModalService = ModalService
      this.$timeout = $timeout
      this.debounce = debounce

      const vm = this
      this.urlPattern = new RegExp('^(http[s]?:\\/\\/){0,1}(www\\.){0,1}[a-zA-Z0-9\\.\\-]+\\.[a-zA-Z]{2,15}[\\.]{0,1}(\\/.*)?(\\?.*)?$')
      this.consultationPrice = this.Configuration.features.pricing.consultationPrice
      this.stepListConfig = {
        project: ['type', 'details', 'budget', 'auth'],
        consultation: ['consultation', 'auth']
      }

      this.configRatesRange = Object.assign({}, Configuration.features.pricing.ratesRange)

      this.formData = {
        // version
        submissionFormVersion: '2.0',

        // step type
        type: null,
        subject: null,
        // step budget
        complexity: null,
        urgency: null,
        // step details
        title: null,
        description: null,
        websiteUrl: null,

        // TODO: fetch experts in this controller after expermient is finished
        // preferredExperts: {    // TODO: redo the autocomplete component to update with callback and not via object/array
        //   selected: [],
        //   options: []
        // },
        // preferredExpertInvite: false, // set to false by default
        // preferredExpertsPublishForAll: false, // set to false by default

        preferredExperts: [],
        preferredExpertsInvite: false,
        preferredExpertsPublishForAll: false,

        attachments: [],       // TODO: redo the attachement component to update with callback and not via object/array
        attachmentsQueue: [],   // TODO: redo the attachement component to update with callback and not via object/array
      }



      // step budget fields
      this.complexityField = {
        config: {
          type: 'radio-button-outline',
          horizontal: true,
        },
        get label () {
          return getFieldComplexityLabel(vm.formData.type, vm.formData.subject)
        },
        get isVisible () {
          return !['fix', 'other'].includes(vm.formData.type.project_type)
        },
        options: [
          {
            title: 'Low',
            value: 'low'
          },
          {
            title: 'Medium',
            value: 'medium'
          },
          {
            title: 'High',
            value: 'high'
          }
        ]
      }

      this.urgencyField = {
        config: {
          type: 'radio-button-outline',
          horizontal: true
        },
        options: [
          {
            title: 'Flexible',
            value: 'flexible'
          },
          {
            title: 'Moderate',
            value: 'moderate'
          },
          {
            title: 'Urgent',
            value: 'urgent'
          }
        ]
      }
      // ---


      this.authData = {
        email: null,
        password: null,
        firstName: null,
        lastName: null,
        formType: 'register',
        toc: null,
        contactConsent: false
      }

      this.showPrivacy = false

      this.isPredictingBudgetLoading = false
      this.predictedBudget = null


      this.experimentIsVariant = false
      this.experimentIsProcessing = false
      this.experimentIsEmailError = null

      this.DevService.registerAction({
        name: 'refresh experiment',
        callback: this.experimentExecute.bind(this)
      })

      this.draft = null

      this.experimentPredictedBudget = false

      this.typingTimer = null
    }

    experimentExecute () {
      if (!this.UserService.isAuthenticated()) {
        this.goToSubstep('user')
      }
    }

    experimentCheckEmail () {
      this.experimentIsEmailOk = false

      const payload = {
        firstName: this.authData.firstName,
        lastName: this.authData.lastName,
        email: this.authData.email,
        consent: this.authData.contactConsent,
        submissionDraft: {
          ...this.prepareDraftData().submissionDraft
        }
      }
      this.experimentIsProcessing = true
      this.experimentIsEmailError = null
      console.log('[draft] > experimentCheckEmail > payload', payload)
      this.$http.post(`${this.Configuration.apiUrl}/clients/contacts`, payload)
        .then(response => {
          console.log('[draft] > experimentCheckEmail > response', response)
          if (response?.data?.id) {
            this.SubmissionDraftService.saveDraftIdToLS(response.data.id)
            this.draft = response.data
          }
          this.goToSubstep('intro')
        })
        .catch(err => {
          console.log('[draft] > experimentCheckEmail > err', err)
          this.experimentIsEmailError = err.data
        })
        .finally(() => {
          this.experimentIsProcessing = false
        })
    }

    // Handler for change detection of the form, should be ignorred on first step
    async updateDraft () {
      if (['user'].includes(this.substep)) {
        console.log('[draft] > updateDraft > skip for user step')
        return
      }

      if (!this.draft || !this.draft.id) {
        console.log('[draft] > updateDraft > skip, no draft id')
        return
      }

      console.log('[draft] > updateDraft',)
      this.draft = this.prepareDraftData()
      return this.SubmissionDraftService.updateDraft(this.draft)
        .then(updatedDraft => {
          this.draft = updatedDraft
        })
    }

    restoreFormFromDraft (draft) {
      this.draft = draft
      if (this.draft?.submissionDraft) {
        // restore project form data
        const { attachmentsQueueCtrl, attachments, attachmentsQueue, ...restFormData } = this.draft.submissionDraft.formData
        this.formData = {
          ...this.formData,
          ...restFormData
        }

        // custom handling for type/subject due to values are object refereneces
        if (this.formData.type?.projectType) {
          this.processTypeChange(this.formData.type.projectType)
        }

        if (this.formData.subject?.value) {
          this.formData.subject = this.formData.type.options.find(s => s.value === this.formData.subject.value)
        }


        // restore auth data
        this.authData = {
          ...this.authData,
          ...this.draft.submissionDraft.authData
        }

        // exception handling for user first step
        const substep = this.draft.submissionDraft.substep === 'user' ? 'intro' : this.draft.submissionDraft.substep
        this.goToSubstep(substep)
      }
    }

    async restoreDraft () {
      let draftId = this.SubmissionDraftService.restoreDraftId()
      if (!draftId || typeof draftId !== 'string') {
        console.log('[draft] > restoreDraft > no draftId or invalid > skip restore')
        return
      }

      console.log('[draft] > restoreDraft > draftId', draftId)

      const configDraftRestoreModal = this.ModalService.generateConfig('confirmation', {
        title: `You have an unfinished project`,
        body: `Would you like to continue your existing progress or start a new posting?`,
        rejectButton: {
          label: 'Start new'
        },
        resolveButton: {
          label: 'Continue Progress'
        },
      })
      const configDraftErrorModal = this.ModalService.generateConfig('success', {
        title: `Error Loading Draft`,
        body: `There was an error loading your project draft.`,
        closeIcon: false,
        resolveButton: {
          label: 'Ok'
        },
      })

      this.draftIsLoading = true
      await this.SubmissionDraftService.loadDraft(draftId)
        .then(draft => {
          // update draft id in local storage
          this.SubmissionDraftService.saveDraftIdToLS(draft.id)

          console.log('[draft] > restoreDraft > draft', draft)
          this.ModalService.open(configDraftRestoreModal)
            .then(response => {
              this.restoreFormFromDraft(draft)
              this.SegmentAnalyticsService.trackEvent('submission_draft_restore', { value: 'continue_progress' })
            })
            .catch(reject => {
              const draftReseted = {
                id: draft.id,
                submissionDraft: {
                  ...draft.submissionDraft,
                  formData: this.prepareDraftData().submissionDraft.formData,
                  substep: 'intro'
                }
              }
              this.restoreFormFromDraft(draftReseted) // restore draft by keeping the draft id and auth data (user first step data) however blank form data
              this.SegmentAnalyticsService.trackEvent('submission_draft_restore', { value: 'start_new' })
            })
            .finally(() => {
              // resume render of submission flow
              this.draftIsLoading = false
            })
        })
        .catch(err => {
          console.log('[draft] > restoreDraft > err', err)
          if (err.data?.reason === 'draft_published') {
            // if draft was already used for posting a project it is not valid anymore - custom copy for this error
            configDraftErrorModal.title = 'Draft Already Published'
            configDraftErrorModal.body = 'You\'ve already published this project draft.'
          }
          this.ModalService.open(configDraftErrorModal)
            .then((resolve) => {
              this.SubmissionDraftService.removeDraftId()
            })
            .finally(() => {
              // resume render of submission flow
              this.draftIsLoading = false
            })
        })
    }

    prepareDraftData () {
      const { password, ...restAuthData } = this.authData
      const { attachments, attachmentsQueue, attachmentsQueueCtrl, ...restFormData } = this.formData
      return {
        id: this.draft?.id,
        actions: this.draft?.actions,
        submissionDraft: {
          authData: restAuthData,
          formData: {
            ...restFormData
          },
          substep: this.substep === 'user' ? 'intro' : this.substep
        }
      }
    }

    draftChangeDetectionInit () {
      console.log('[draft] > draftChangeDetectionInit')
      const detectDraftChange = () => {
        return this.prepareDraftData()
      }

      this.$scope.$watch(detectDraftChange, this.debounce((newValue, oldValue) => {
        console.log('[draft] > draftChangeDetectionInit > change detected', newValue)
        this.updateDraft()
      }, 500), true)
    }

    async $onInit () {
      console.log('[SubmissionStepOneVariantComponent] > $onInit')

      this.experimentExecute()
      this.typeList = this.config.types.filter(t => t.project_type !== 'consultation')

      // Process Query Params
      this.SubmissionService.processQueryParams(this.formData, this.$stateParams, {
        complexityField: this.complexityField,
        urgencyField: this.urgencyField,
        typeField: this.typeList,
        consultationType: this.config.types.filter(t => t.project_type === 'consultation')
      })

      // TODO: move preferred experts restre from url paream to SubmissionService.processQueryParams from old v1 controller, temporrary solutrion - just remap
      this.restorePreferredExpertsFromV1()

      // TODO: verify what to do in this case if experiment is a variant and data is available (ie from URL)
      // if (!this.isVariant) {
      //   // Go to next substep if type and subject are selected
      //   if (this.isProject && !this.formData.subject) {
      //     this.goToSubstep('type')
      //   } else if (this.isProject && this.formData.subject) {
      //     this.goToSubstep('details')
      //   } else if (this.isConsultation) {
      //     this.goToSubstep('consultation')
      //   }
      // }

      // TODO: process url params and map to legacy params + assign correct substep to
      // type&subject&scope&complexity&urgency&title&description&ref

      // TODO: comment test data
      // this.populateTestData()
      // ----

      await this.restoreDraft()
      this.draftChangeDetectionInit()

      this.processTypeChange()
    }

    $onChanges (changes) {
      if (changes?.preferredExpertsFromUrl?.currentValue) {
        this.restorePreferredExpertsFromV1()
        console.log('[SubmissionStepOneVariantComponent] > $onChanges > preferredExpertsFromUrl', this.formData.preferredExperts)
      }
    }

    restorePreferredExpertsFromV1 () {
      console.log('[SubmissionStepOneVariantComponent] > restorePreferredExpertsFromV1')
      if (this.data?.invitePreferredExpert) {
        this.formData.preferredExpertsInvite = this.data.invitePreferredExpert

        if (this.data?.preferredContractors?.selected.length > 0) {
          this.formData.preferredExperts = [...this.data?.preferredContractors?.selected]
        }

        if (this.data?.publishForAll) {
          this.formData.preferredExpertsPublishForAll = this.data?.publishForAll
        }
      }
    }

    populateTestData () {
      // TESTING URL
      // http://app.cdbl.local:9000/tasks/new?type=customize&subject=plugin&complexity=low&urgency=flexible&title=title%20title%20title%20title%20title%20title&desc=descriptiondescription%20description%20description%20description%20description%20description%20description%20description%20description%20description%20description%20description&prefix=kaboom&preferredContractor=1819

      this.authData.firstName = 'Client '
      this.authData.lastName = 'Client'
      this.authData.email = 'jan+client@codeable.io'
      this.authData.contactConsent = true

      this.formData.type = this.typeList.find(t => t.project_type === 'install')
      this.formData.subject = this.formData.type.options.find(s => s.value === 'theme')
      this.formData.title = 'Create a title for your project'
      this.formData.description = `Create a title for your project
Create a title for your project
Create a title for your project
Create a title for your project
      `
      this.formData.urgency = 'flexible'
      this.formData.complexity = 'medium'
    }

    processTypeChange (typeValue) {
      // Assign project type
      if (typeValue) {
        const type = this.config.types.find(t => t.project_type === typeValue)
        if (type) {
          this.formData.type = type
        }
      }

      // Process all rules regarding specific project types that can happen on init (via url params) or on type change
      if (this.formData.type && this.formData.type.project_type === 'consultation') {
        this.formData.subject = this.formData.type.options[0]
        this.formData.complexity = null
        this.formData.urgency = 'moderate' // maps to value 2
        this.predictedBudget = null
      }

      if (this.formData.type && this.formData.type.project_type === 'fix') {
        this.formData.complexity = this.complexityField.options.find(o => o.value === 'medium').value // preselect default value for type fix
      }

      if (this.formData.type && this.formData.type.project_type === 'other') {
        this.formData.subject = this.formData.type.options.find(o => o.value === 'other')
        this.formData.complexity = this.complexityField.options.find(o => o.value === 'medium').value
        this.predictedBudget = null
      }
    }

    get stepBudgetTitle () {
      return (this.formData.type && this.formData.type.project_type === 'other') ? 'Please answer the following' : 'Please answer the following to get an idea of our pricing'
    }

    get stepList () {
      // list of steps for stepper
      if (this.isConsultation) {
        return this.stepListConfig.consultation
      }
      return this.stepListConfig.project
    }

    get isConsultation () {
      return this.formData.type && this.formData.type.project_type === 'consultation'
    }

    get isProject () {
      return this.formData.type && this.formData.type.project_type !== 'consultation'
    }

    get isProcessing () {
      return this.SubmissionCtrl.processingStatus !== null && this.SubmissionCtrl.processingStatus !== 'error'
    }

    get publishButtonText () {
      let text = ''
      const label = `${this.isConsultation ? 'consultation' : 'project'}`
      switch (this.SubmissionCtrl.processingStatus) {
      case 'authenticate':
        text = 'Authenticating...'
        break

      case 'create':
      case 'publish':
        text = `Creating ${label}...`
        break

      case 'upload':
        if (this.formData.attachmentsQueueCtrl && this.formData.attachmentsQueueCtrl.uploadProgress) {
          text = `Uploading files ${this.formData.attachmentsQueueCtrl.uploadProgress()}%`
        } else {
          text = `Uploading files...`
        }
        break

      case 'complete':
        text = `Redirecting, please wait...`
        break

      case 'error':
      default:
        text = `Publish ${this.isConsultation ? '' : 'my'} ${label}`
      }

      return text
    }

    get serverErrors () {
      if (this.SubmissionCtrl.processingStatus === 'error') {
        return this.SubmissionCtrl.serverErrors
      }

      return null
    }

    get showPredictedBudget () {
      if (!this.experimentPredictedBudget) {
        return false
      }

      if (!this.formData.type ||
          !this.formData.type.pricing ||
          !this.formData.subject ||
          !this.formData.urgency ||
          !this.formData.complexity ||
          ['other'].includes(this.formData.type.project_type)) {
        return false
      }

      return true
    }


    goToNextSubstep () {
      let stepList = this.stepListConfig.project
      if (this.isConsultation) {
        stepList = this.stepListConfig.consultation
      }

      const currentStepIndex = stepList.findIndex(item => item === this.substep)
      console.log('[SubmissionStepOneVariantComponent] > goToNextSubstep', this.substep, '>', stepList[currentStepIndex + 1])
      if (currentStepIndex < stepList.length) {
        this.goToSubstep(stepList[currentStepIndex + 1])
      } else {
        this.goToSubstep('intro')
      }
    }


    goBackToSubstep () {
      let stepList = this.stepListConfig.project
      if (this.isConsultation) {
        stepList = this.stepListConfig.consultation
      }

      const currentStepIndex = stepList.findIndex(item => item === this.substep)
      if (currentStepIndex > 0) {
        this.goToSubstep(stepList[currentStepIndex - 1])
      } else {
        this.goToSubstep('intro')
      }
    }

    // onTypeStepCompleted () {

    // }

    // onProjectDetailsStepCompleted () {

    // }

    // onBudgetStepCompleted () {

    // }

    // onConsulationDetailsStepCompleted () {

    // }

    // onAuthStepCompleted () {

    // }

    stepperGoToSubstep (substep) {
      let trackingStepLocation = this.mapSubstepForTracking(this.substep)
      this.SegmentAnalyticsService.trackEvent('Stepper Button Clicked', { location: `submission ${trackingStepLocation} step` })

      this.goToSubstep(substep)
    }

    mapSubstepForTracking (substep) {
      let result = substep
      switch (substep) {
      case 'details':
        result = 'project details'
        break
      case 'consultation':
        result = 'consultation details'
        break
      case 'budget':
        result = 'budget urgency'
        break
      }

      return result
    }

    onStepViewed (substep) {
      console.log('[SubmissionStepOneVariantComponent] > onStepViewed', substep)
      const mappedSubstep = this.mapSubstepForTracking(substep)

      this.SegmentAnalyticsService.trackEvent(`${mappedSubstep} step viewed`)
    }

    goToSubstep (substep) {
      console.log('[SubmissionStepOneVariantComponent] > goToSubstep', substep)
      this.processTypeChange()

      if (substep === 'type') {
        // On substep transition logic here

        // Reset form data for specific case when consultation was selected first then user navigated to project form
        if (this.isConsultation) {
          this.resetFormData()
        }
      } else if (substep === 'details') {
        // On substep transition logic here

        if (this.substep === 'type') {
          this.SegmentAnalyticsService.trackProjectTypeSelected({
            project_type: this.formData.type.project_type,
            project_subject: this.formData.subject.value,
            partner_name: this.SubmissionCtrl.parent,
            experiment: this.SubmissionCtrl.data.experiment
          })
        }
      } else if (substep === 'budget') {
        // On substep transition logic here

        if (this.substep === 'details') {
          this.SegmentAnalyticsService.trackProjectDetailsCompleted({
            project_type: this.formData.type.project_type,
            project_subject: this.formData.subject.value,
            partner_name: this.SubmissionCtrl.parent,
            experiment: this.SubmissionCtrl.data.experiment
          })
        }
      } else if (substep === 'consultation') {
        // On substep transition logic here

        // Reset form data for specific case when project form was selected first then user navigated to consultation form
        if (this.isProject) {
          this.resetFormData()
        }
        const originalSubstep = this.substep
        this.processTypeChange('consultation') // set consultation and its default values

        if (originalSubstep === 'intro') {
          this.SegmentAnalyticsService.trackProjectTypeSelected({
            project_type: this.formData.type.project_type,
            project_subject: this.formData.subject.value,
            partner_name: this.SubmissionCtrl.parent,
            experiment: this.SubmissionCtrl.data.experiment
          })
        }
      } else if (substep === 'auth') {
        // On substep transition logic here

        if (this.substep === 'budget') {
          this.SegmentAnalyticsService.trackBudgetUrgencyCompleted({
            project_type: this.formData.type.project_type,
            project_subject: this.formData.subject.value,
            partner_name: this.SubmissionCtrl.parent,
            experiment: this.SubmissionCtrl.data.experiment
          })
        }

        if (this.substep === 'consultation') {
          this.SegmentAnalyticsService.trackConsulationDetailsCompleted({
            project_type: this.formData.type.project_type,
            project_subject: this.formData.subject.value,
            partner_name: this.SubmissionCtrl.parent,
            experiment: this.SubmissionCtrl.data.experiment
          })
        }
      }

      this.SubmissionCtrl.goToSubstep(substep)
    }

    resetFormData () {
      console.log('[SubmissionStepOneVariantComponent] > resetFormData')
      this.formData.type = null
      this.formData.subject = null
      this.formData.complexity = null
      this.formData.urgency = null
      this.formData.title = null
      this.formData.description = null
      this.formData.preferredExperts = []
      this.formData.preferredExpertsInvite = false
      this.formData.preferredExpertsPublishForAll = false
      this.formData.attachements = []
      this.formData.attachmentsQueue = []
      this.formData.attachmentsQueueCtrl = null
    }

    onAttachmentsSelect ($event) {
      console.log('[SubmissionStepOneVariantComponent] > onAttachmentsSelect', $event)
      this.formData.attachmentsQueueCtrl = $event
    }

    getUrgencyMappedValue (urgencyFromValue) {
      switch (urgencyFromValue) {
      case 'urgent':
        return 0
      case 'moderate':
        return 2
      case 'flexible':
        return 3
      default:
        return 2 // catch all values and map them to this value for BE
      }
    }

    publishProject () {
      // Copy new props to old submission data object
      this.data.submissionFormVersion = this.formData.submissionFormVersion

      // add draftId to payload
      if (this.draft?.id) {
        this.data.draftId = this.draft.id
      }

      this.data.title = this.formData.title
      this.data.description = this.formData.description
      this.data.websiteUrl = this.formData.websiteUrl
      this.data.complexity = this.formData.complexity

      // remap preferred experts back to data prop of v1 submissionflow
      // TODO: remove on reqrite, logic should be in service or v2 controller
      this.data.invitePreferredExpert = this.formData.preferredExpertsInvite
      this.data.publishForAll = this.formData.preferredExpertsPublishForAll
      if (this.formData.preferredExperts?.length > 0) {
        this.data.preferredContractors.selected = [...this.formData.preferredExperts]
      }


      switch (this.formData.urgency) {
      case 'urgent':
        this.data.urgency = 0
        break
      case 'moderate':
        this.data.urgency = 2
        break
      case 'flexible':
        this.data.urgency = 3
        break
      }
      this.data.urgency = this.getUrgencyMappedValue(this.formData.urgency)

      this.data.project_type = this.formData.type.project_type
      this.data.project_subject = this.formData.subject.value
      this.data.attachments = this.formData.attachments
      this.data.queuedattachments = this.formData.attachmentsQueue
      this.data.attachmentsQueueCtrl = this.formData.attachmentsQueueCtrl

      this.data.budget = !this.experimentPredictedBudget ? null : -5 // not sure
      this.data.budgetRange = !this.experimentPredictedBudget ? null : this.predictedBudget

      // auth login/register
      if (this.authData.formType === 'login') {
        this.SubmissionCtrl.authForm.type = 'sign in'
        this.SubmissionCtrl.user.email = this.authData.email
        this.SubmissionCtrl.user.password = this.authData.password
      } else {
        this.SubmissionCtrl.authForm.type = 'register'
        this.SubmissionCtrl.user.email = this.authData.email
        this.SubmissionCtrl.user.password = this.authData.password
        this.SubmissionCtrl.user.firstName = this.authData.firstName
        this.SubmissionCtrl.user.lastName = this.authData.lastName
      }

      console.log('[SubmissionStepOneVariantComponent] > publishProject', this.SubmissionCtrl.data, this.SubmissionCtrl.authForm, this.SubmissionCtrl.user)
      this.SubmissionCtrl.publishProject()
    }

    onChangeType () {
      // Reset the subject if type is changed
      this.formData.subject = null
      console.log('[SubmissionStepOneVariantComponent] > onChangeType', arguments)
      // Auto asign 'other' to subject if type is 'other'
      this.processTypeChange()

      this.updatePredictedBudget()
    }

    onChangeComplexity () {
      this.updatePredictedBudget()
    }

    onChangeSubject () {
      this.updatePredictedBudget()
    }

    onChangeUrgency () {
      this.updatePredictedBudget()
    }

    updatePredictedBudget () {
      // Check if all fields have values
      if (this.showPredictedBudget) {
        this.isPredictingBudgetLoading = true
        this.SubmissionService.getPredictedBudget({
          project_type: this.formData.type.project_type,
          project_subject: this.formData.subject.value,
          complexity: this.formData.complexity,
          urgency: this.getUrgencyMappedValue(this.formData.urgency)
        })
          .then(response => {
            this.predictedBudget = response
          })
          .finally(() => {
            this.isPredictingBudgetLoading = false
          })
      }
    }

    togglePrivacy () {
      this.showPrivacy = !this.showPrivacy
    }

    askForHelp (message) {
      this.IntercomService.showMessage(message)
    }

    getFieldTooltipTemplate (fieldName) {
      if (typeof fieldName !== 'string' || !this.formData.type || !this.formData.subject) {
        return null
      }
      const template = getFieldTooltipTemplate(fieldName, this.formData.type.project_type, this.formData.subject.value)
      return template
    }

    getValidURL (url) {
      this.$http.post(`${this.Configuration.apiUrl}/url_protocol_check`, { url })
        .then(response => {
          const url = response.data.url
          if (url) {
            this.formData.websiteUrl = url
          }
        })
        .catch(err => {
          console.log('Error trying to get url', err)
        })
    }

    onInputChange () {
      // Clear the previous typing timer
      this.$timeout.cancel(this.typingTimer)

      // Set a new typing timer
      this.typingTimer = this.$timeout(() => {
        if (!this.formSubstepConsultation.websiteurl.$error || Object.keys(this.formSubstepConsultation?.websiteurl.$error).length === 0) {
          this.getValidURL(this.formData.websiteUrl)
        }
      }, 500) // Delay in milliseconds
    }
  }
}

export default SubmissionStepOneVariantComponent
