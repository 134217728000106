const ProjectClientTypeFilter = function () {
  'ngInject'

  // Argument should be user object of client that is present in project and that it has clientType property,
  // some endpoints return project with clientType directly on a project
  return function (project, format = 'short') {
    if (!(project.clientType || (project.client && project.client.clientType))) {
      return ''
    }

    const clientType = project.clientType ? project.clientType : project.client.clientType
    // console.log('clientType', clientType, project)

    let type = ''
    switch (clientType) {
    case 'Repeat Client':
    case 'VIP Client':
      type = 'Loyal'
      break
    case 'New Client':
      type = 'New'
      break
    }

    if (format === 'long') {
      type = `${type} Client`
    }

    return type
  }
}
export default ProjectClientTypeFilter
