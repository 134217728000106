import ErrorsService from './errors.service'

import ErrorRendererComponent from './error-renderer'

const ErrorRendererModule = angular.module('cdbl.errors', [

])
  .service('ErrorsService', ErrorsService)
  .component('cdblErrorRenderer', ErrorRendererComponent)
  .name

export default ErrorRendererModule
