import templateUrl from './hired-reminder-modal.html'
import './hired-reminder-modal.scss'

const HIRE_REMINDER = 'hireCheck'
const HiredReminderModalComponent = {
  bindings: {
    project: '<?',
    currentUser: '<?',
  },
  require: {
    modalCtrl: '^^cdblModal'
  },
  templateUrl,
  controller: class HiredReminderModalComponent {
    constructor (ModalService, ProjectRemindersService) {
      'ngInject'
      this.ModalService = ModalService
      this.ProjectRemindersService = ProjectRemindersService
      this.proccesing = false
    }

    closeHiredModal () {
      this.proccesing = true
      this.ProjectRemindersService.setReminderState(HIRE_REMINDER)
      this.ModalService.close()
      this.proccesing = false
    };
  }
}

export default HiredReminderModalComponent
