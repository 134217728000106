import './icon.scss'
const svgSpriteFile = require('svgIconSprite') // require('assets/images/icons-sprite.svg')
const IconComponent = {
  bindings: {
    icon: '<?'
  },
  template: ($element, $attrs) => {
    'ngInject'
    // console.log('IconComponent >', $element, $attrs, svgSpriteFile)
    const url = `${svgSpriteFile}#`
    if ($attrs.role === 'img') {
      return '<img ng-src="{{\'' + url + '\' + $ctrl.icon + \'-usage\'}}">'
    } else if ($attrs.cdblsrc) {
      console.log('IconComponent > $attrs.cdblsrc', $attrs.cdblsrc)
      return `<img ng-src="{{'${$attrs.cdblsrc}'}}" >`
    }
    return '<svg ng-if="$ctrl.icon"><use xlink:href="" ng-attr-xlink:href="{{\'#\' + $ctrl.icon}}" class="{{$ctrl.class}}"></use></svg>'
  },
  controller: class IconComponent {
    constructor () {
      'ngInject'
      this._identify = 'IconComponent'
      // console.log('IconComponent > controller: ', this.icon)
    }
  }
}

export default IconComponent
