import ErrorsModule from './errors.module.js'
import AuthorizationPermissionError from './types/authorization-permission.class.js'
import MissingArgumentsError from './types/missing-arguments.class.js'
import FileSizeError from './types/file-size.class.js'

export default ErrorsModule
export {
  AuthorizationPermissionError,
  MissingArgumentsError,
  FileSizeError
}
