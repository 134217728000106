const complexityFieldTemplates = {
  install: {
    theme: `<p class="info-popover-title">Low Complexity</p>
               <p>A standard theme install and configuration (e.g. for a standard business site).</p>

               <p class="info-popover-title">Medium Complexity</p>
               <p>A theme configuration with some custom elements, widgets, etc.</p>

               <p class="info-popover-title">High Complexity</p>
               <p>A theme configuration with lots of custom code, elements, widgets, etc.</p>`,
    plugin: `<p class="info-popover-title">Low Complexity</p>
               <p>A standard plugin configuration (e.g. for a standard website feature like a contact form).</p>

               <p class="info-popover-title">Medium Complexity</p>
               <p>A plugin configuration with some custom elements, functions, etc.</p>

               <p class="info-popover-title">High Complexity</p>
               <p>A plugin configuration that requires lots of custom code to get your desired result.</p>`,
    other: `<p class="info-popover-title">Low Complexity</p>
               <p>A standard configuration (e.g. for a standard website feature like a contact form).</p>

               <p class="info-popover-title">Medium Complexity</p>
               <p>A configuration with some custom elements, functions, etc.</p>

               <p class="info-popover-title">High Complexity</p>
               <p>A configuration that requires lots of custom code to get your desired result.</p>`
  },
  customize: {
    theme: `<p class="info-popover-title">Low Complexity</p>
               <p>Minimal customization of 1 or 2 theme elements (e.g. a single post type, page view, widget, etc).</p>

               <p class="info-popover-title">Medium Complexity</p>
               <p>Some customization of several theme elements.</p>

               <p class="info-popover-title">High Complexity</p>
               <p>Lots of code and many theme elements require customization to get your desired result.</p>`,
    plugin: `<p class="info-popover-title">Low Complexity</p>
               <p>Minimal customization of 1 or 2 plugin elements (e.g. a string or widget).</p>

               <p class="info-popover-title">Medium Complexity</p>
               <p>Some customization of several plugin elements.</p>

               <p class="info-popover-title">High Complexity</p>
               <p>Lots of code and many plugin elements require customization to get your desired result.</p>`,
    site: `<p class="info-popover-title">Low Complexity</p>
               <p>Minimal customization of 1 or 2 site parts (e.g. a single post type, page view, widget, etc).</p>

               <p class="info-popover-title">Medium Complexity</p>
               <p>Some customization of several site parts.</p>

               <p class="info-popover-title">High Complexity</p>
               <p>Lots of code and many site parts require customization to get your desired result.</p>`,
    ecommerce_site: `<p class="info-popover-title">Low Complexity</p>
               <p>Minimal customization of 1 or 2 site parts (e.g. a single post type, page view, widget, etc).</p>

               <p class="info-popover-title">Medium Complexity</p>
               <p>Some customization of several site parts.</p>

               <p class="info-popover-title">High Complexity</p>
               <p>Lots of code and many site parts require customization to get your desired result.</p>`,
    other: `<p class="info-popover-title">Low Complexity</p>
               <p>Minimal customization of 1 or 2 site parts (e.g. a single post type, page view, widget, etc).</p>

               <p class="info-popover-title">Medium Complexity</p>
               <p>Some customization of several site parts.</p>

               <p class="info-popover-title">High Complexity</p>
               <p>Lots of code and many site parts require customization to get your desired result.</p>`
  },
  fix: {
    theme: ``,
    plugin: ``,
    site: ``,
    ecommerce_site: ``,
    other: ``
  },
  design_and_develop: {
    theme: `<p class="info-popover-title">Low Complexity</p>
               <p>A theme with standard pages (e.g. home, about, contact us) and features (e.g. contact forms, comments, etc.).</p>

               <p class="info-popover-title">Medium Complexity</p>
               <p>A theme with some, but few custom pages and/or features.</p>

               <p class="info-popover-title">High Complexity</p>
               <p>A theme with lots of custom functionality and page views.</p>`,
    plugin: `<p class="info-popover-title">Low Complexity</p>
               <p>A plugin that performs a standard feature (e.g. a contact form).</p>

               <p class="info-popover-title">Medium Complexity</p>
               <p>A plugin with some, but few custom features.</p>

               <p class="info-popover-title">High Complexity</p>
               <p>A plugin with lots of custom functionality.</p>`,
    site: `<p class="info-popover-title">Low Complexity</p>
               <p>A website with standard features like contact forms, comments, etc.</p>

               <p class="info-popover-title">Medium Complexity</p>
               <p>A website with some, but few custom functionalities.</p>

               <p class="info-popover-title">High Complexity</p>
               <p>A website with lots of custom functionality, user accounts, API integrations, etc.</p>`,
    ecommerce_site: `<p class="info-popover-title">Low Complexity</p>
               <p>A website with standard e-commerce functionality and a few products.</p>

               <p class="info-popover-title">Medium Complexity</p>
               <p>A website with some custom e-commerce functionality, custom product views, etc.</p>

               <p class="info-popover-title">High Complexity</p>
               <p>A website with highly customized e-commerce functionality, many custom product views, etc.</p>`,
    other: `<p class="info-popover-title">Low Complexity</p>
               <p>Minimal development/design of 1 or 2 site parts (e.g. a single post type, page view, widget, etc).</p>

               <p class="info-popover-title">Medium Complexity</p>
               <p>Some development/design of several site parts.</p>

               <p class="info-popover-title">High Complexity</p>
               <p>Lots of development/design of many site parts.</p>`
  },
  migrate: {
    site: `<p class="info-popover-title">Low Complexity</p>
               <p>Migrating a standard website (e.g. 4-5 pages and a blog).</p>

               <p class="info-popover-title">Medium Complexity</p>
               <p>Migrating a website that has been customized and has a moderate amount of posts and pages.</p>

               <p class="info-popover-title">High Complexity</p>
               <p>Migrating a highly customized website, lots of posts, pages and files.</p>`,
    ecommerce_site: `<p class="info-popover-title">Low Complexity</p>
               <p>Migrating a standard website (e.g. 4-5 pages and a some products).</p>

               <p class="info-popover-title">Medium Complexity</p>
               <p>Migrating a website that has been customized and has a moderate amount of posts, pages and products.</p>

               <p class="info-popover-title">High Complexity</p>
               <p>Migrating a highly customized website, lots of posts, pages, products and files.</p>`,
    other: `<p class="info-popover-title">Low Complexity</p>
               <p>Migrating a standard website (e.g. 4-5 pages and a blog).</p>

               <p class="info-popover-title">Medium Complexity</p>
               <p>Migrating a website that has been customized and has a moderate amount of posts and pages.</p>

               <p class="info-popover-title">High Complexity</p>
               <p>Migrating a highly customized website, lots of posts, pages and files.</p>`
  }
}





const urgencyFieldTemplates = {
  install: {
    theme: `<p class="info-popover-title">Flexible</p>
               <p>As long as needed to produce a great end result</p>

               <p class="info-popover-title">Moderate</p>
               <p>Around 2 weeks</p>

               <p class="info-popover-title">Urgent</p>
               <p>Within 1 week</p>`,
    plugin: `<p class="info-popover-title">Flexible</p>
               <p>As long as needed to produce a great end result</p>

               <p class="info-popover-title">Moderate</p>
               <p>Around 2 weeks</p>

               <p class="info-popover-title">Urgent</p>
               <p>Within 1 week</p>`,
    other: `<p class="info-popover-title">Flexible</p>
               <p>As long as needed to produce a great end result</p>

               <p class="info-popover-title">Moderate</p>
               <p>Around 2 weeks</p>

               <p class="info-popover-title">Urgent</p>
               <p>Within 1 week</p>`
  },
  customize: {
    theme: `<p class="info-popover-title">Flexible</p>
               <p>As long as needed to produce a great end result</p>

               <p class="info-popover-title">Moderate</p>
               <p>Around 2 weeks</p>

               <p class="info-popover-title">Urgent</p>
               <p>Within 1 week</p>`,
    plugin: `<p class="info-popover-title">Flexible</p>
               <p>As long as needed to produce a great end result</p>

               <p class="info-popover-title">Moderate</p>
               <p>Around 2 weeks</p>

               <p class="info-popover-title">Urgent</p>
               <p>Within 1 week</p>`,
    site: `<p class="info-popover-title">Flexible</p>
               <p>As long as needed to produce a great end result</p>

               <p class="info-popover-title">Moderate</p>
               <p>Around 2 weeks</p>

               <p class="info-popover-title">Urgent</p>
               <p>Within 1 week</p>`,
    ecommerce_site: `<p class="info-popover-title">Flexible</p>
               <p>As long as needed to produce a great end result</p>

               <p class="info-popover-title">Moderate</p>
               <p>Around 2 weeks</p>

               <p class="info-popover-title">Urgent</p>
               <p>Within 1 week</p>`,
    other: `<p class="info-popover-title">Flexible</p>
               <p>As long as needed to produce a great end result</p>

               <p class="info-popover-title">Moderate</p>
               <p>Around 2 weeks</p>

               <p class="info-popover-title">Urgent</p>
               <p>Within 1 week</p>`
  },
  fix: {
    theme: `<p class="info-popover-title">Flexible</p>
               <p>As long as needed to produce a great end result</p>

               <p class="info-popover-title">Moderate</p>
               <p>Around 2 weeks</p>

               <p class="info-popover-title">Urgent</p>
               <p>Within 1 week</p>`,
    plugin: `<p class="info-popover-title">Flexible</p>
               <p>As long as needed to produce a great end result</p>

               <p class="info-popover-title">Moderate</p>
               <p>Around 2 weeks</p>

               <p class="info-popover-title">Urgent</p>
               <p>Within 1 week</p>`,
    site: `<p class="info-popover-title">Flexible</p>
               <p>As long as needed to produce a great end result</p>

               <p class="info-popover-title">Moderate</p>
               <p>Around 2 weeks</p>

               <p class="info-popover-title">Urgent</p>
               <p>Within 1 week</p>`,
    ecommerce_site: `<p class="info-popover-title">Flexible</p>
               <p>As long as needed to produce a great end result</p>

               <p class="info-popover-title">Moderate</p>
               <p>Around 2 weeks</p>

               <p class="info-popover-title">Urgent</p>
               <p>Within 1 week</p>`,
    other: `<p class="info-popover-title">Flexible</p>
               <p>As long as needed to produce a great end result</p>

               <p class="info-popover-title">Moderate</p>
               <p>Around 2 weeks</p>

               <p class="info-popover-title">Urgent</p>
               <p>Within 1 week</p>`
  },
  design_and_develop: {
    theme: `<p class="info-popover-title">Flexible</p>
               <p>As long as needed to produce a great end result</p>

               <p class="info-popover-title">Moderate</p>
               <p>Around 1 month</p>

               <p class="info-popover-title">Urgent</p>
               <p>Within 1 to 2 weeks</p>`,
    plugin: `<p class="info-popover-title">Flexible</p>
               <p>As long as needed to produce a great end result</p>

               <p class="info-popover-title">Moderate</p>
               <p>Around 1 month</p>

               <p class="info-popover-title">Urgent</p>
               <p>Within 1 to 2 weeks</p>`,
    site: `<p class="info-popover-title">Flexible</p>
               <p>As long as needed to produce a great end result</p>

               <p class="info-popover-title">Moderate</p>
               <p>Around 1 month</p>

               <p class="info-popover-title">Urgent</p>
               <p>Within 1 to 2 weeks</p>`,
    ecommerce_site: `<p class="info-popover-title">Flexible</p>
               <p>As long as needed to produce a great end result</p>

               <p class="info-popover-title">Moderate</p>
               <p>Around 1 month</p>

               <p class="info-popover-title">Urgent</p>
               <p>Within 1 to 2 weeks</p>`,
    other: `<p class="info-popover-title">Flexible</p>
               <p>As long as needed to produce a great end result</p>

               <p class="info-popover-title">Moderate</p>
               <p>Around 1 month</p>

               <p class="info-popover-title">Urgent</p>
               <p>Within 1 to 2 weeks</p>`
  },
  migrate: {
    site: `<p class="info-popover-title">Flexible</p>
               <p>As long as needed to produce a great end result</p>

               <p class="info-popover-title">Moderate</p>
               <p>Around 2 weeks</p>

               <p class="info-popover-title">Urgent</p>
               <p>Within 1 week</p>`,
    ecommerce_site: `<p class="info-popover-title">Flexible</p>
               <p>As long as needed to produce a great end result</p>

               <p class="info-popover-title">Moderate</p>
               <p>Around 2 weeks</p>

               <p class="info-popover-title">Urgent</p>
               <p>Within 1 week</p>`,
    other: `<p class="info-popover-title">Flexible</p>
               <p>As long as needed to produce a great end result</p>

               <p class="info-popover-title">Moderate</p>
               <p>Around 2 weeks</p>

               <p class="info-popover-title">Urgent</p>
               <p>Within 1 week</p>`
  },
  other: {
    other: `<p class="info-popover-title">Flexible</p>
               <p>As long as needed to produce a great end result</p>

               <p class="info-popover-title">Moderate</p>
               <p>Around 2 weeks</p>

               <p class="info-popover-title">Urgent</p>
               <p>Within 1 week</p>`
  }
}

const getFieldTooltipTemplate = function (fieldName, type, subject) {
  const fields = {
    complexity: complexityFieldTemplates,
    urgency: urgencyFieldTemplates
  }

  const fieldTemplates = fields[fieldName]

  if (fieldTemplates && fieldTemplates[type] && fieldTemplates[type][subject]) {
    return fieldTemplates[type][subject]
  }

  return null
}

const getFieldComplexityLabel = function (type, subject) {
  const defaultLabel = 'What do you think is the complexity of your project?'
  let labelText

  if (!type || !type.project_type || !subject || !subject.value) {
    return defaultLabel
  }

  switch (type.project_type) {
  case 'install':
  case 'design_and_develop':
    if (['theme', 'plugin', 'site', 'ecommerce_site'].includes(subject.value)) {
      const name = subject.name.charAt(0).toLowerCase() + subject.name.slice(1) // convert first letter to lower case
      let token = subject.value === 'site' ? 'website' : name
      token = subject.value === 'ecommerce_site' ? 'eCommerce' : token

      labelText = `What do you think is the complexity of your ${token} project?`
    } else {
      labelText = defaultLabel
    }
    break
  case 'customize':
    labelText = 'What do you think is the complexity of your customization project?'
    break
  case 'migrate':
    labelText = 'What do you think is the complexity of your migration project?'
    break
  default:
    labelText = defaultLabel
  }

  return labelText
}

const getEstimatedPriceRange = function (type, subject, complexity, urgency) {
  let min,
    max,
    initialPrice,
    initialMultiplier,
    complexityValue,
    urgencyValue,
    currentFormulaMultiplierMin,
    currentFormulaMultiplierMax

  function multiplier (complexity, urgency, range = 'min') {
    const fixedMultiplier = 1.25
    let defaultMultiplier = range === 'max' ? 3 / fixedMultiplier : 1 // as we are not using scope field as on website use 1 for min and 3 for max
    let currentFormulaMultiplier = defaultMultiplier
    let complexityMultiplier = 0
    let urgencyMultiplier = 0

    switch (complexity) {
    case 'medium':
      complexityMultiplier = 2 / fixedMultiplier
      break
    case 'high':
      complexityMultiplier = 3 / fixedMultiplier
      break
    default: // low, null, undefined
      complexityMultiplier = 1
    }

    switch (urgency) {
    case 'moderate':
      urgencyMultiplier = 2 / fixedMultiplier
      break
    case 'urgent':
      urgencyMultiplier = 3 / fixedMultiplier
      break
    default: // low, null, undefined
      urgencyMultiplier = 1
    }

    currentFormulaMultiplier += complexityMultiplier + urgencyMultiplier

    return currentFormulaMultiplier
  }

  function smoothValue (value) {
    return Math.ceil(Math.round(value) / 10) * 10
  }

  // ----------

  complexityValue = complexity
  urgencyValue = urgency

  initialPrice = type.pricing.initial
  initialMultiplier = type.pricing.multiplier

  // check if subject overrides type pricing
  if (subject.pricing) {
    initialPrice = subject.pricing.initial
    initialMultiplier = subject.pricing.multiplier
  }

  currentFormulaMultiplierMin = multiplier(complexityValue, urgencyValue, 'min')
  currentFormulaMultiplierMax = multiplier(complexityValue, urgencyValue, 'max')

  if (currentFormulaMultiplierMin > 3) {
    min = smoothValue(initialPrice * currentFormulaMultiplierMin / 2 * initialMultiplier)
  } else {
    min = initialPrice
  }

  max = smoothValue(initialPrice * currentFormulaMultiplierMax / 2 * initialMultiplier)

  return {
    min,
    max
  }
}

export {
  getFieldTooltipTemplate,
  getFieldComplexityLabel,
  getEstimatedPriceRange
}
