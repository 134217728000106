import TaskExpertFeedbackComponent from './task-expert-feedback.component'
import TaskExpertFeedbackService from './task-expert-feedback.service'

const TaskExpertFeedbackModule = angular.module('cdbl.task-expert-feedback', [

])
  .service('TaskExpertFeedbackService', TaskExpertFeedbackService)
  .component('cdblTaskExpertFeedback', TaskExpertFeedbackComponent)
  .name

export default TaskExpertFeedbackModule
