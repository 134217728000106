import templateUrl from './layout.html'
import './layout.scss'

const LayoutComponent = {
  bindings: {

  },
  transclude: true,
  templateUrl,
  controller: class LayoutComponent {
    constructor ($attrs) {
      'ngInject'
      this._identify = 'LayoutComponent'
    }

    $onInit () {

    }
  }
}

export default LayoutComponent
