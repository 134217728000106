const ExpertProfileViewModalService = class ExpertProfileViewModalService {
  constructor ($http, Configuration, ModalService, $q) {
    'ngInject'
    this._identify = 'ExpertProfileViewModalService'
    this.$http = $http
    this.Configuration = Configuration
    this.ModalService = ModalService
    this.$q = $q
    this.response = null
  }

  showPublicProfile (userId) {
    this.fetchProfile(userId)
      .then(response => {
        this.ModalService.open({
          closeOnBackdrop: true,
          closeIcon: true,
          class: 'cdbl-modal--style-with-sticky-header-and-footer expert-profile-view-modal cdbl-modal--style-without-dividers',
          template: `<cdbl-expert-profile-view-modal></cdbl-expert-profile-view-modal>`,
          mode: 'public',
        })
      })
      .catch(e => {
        this.ModalService.open({
          closeOnBackdrop: true,
          closeIcon: true,
          class: '',
          layout: 'center',
          body: 'This expert\'s profile is unavailable. Please, check back later.',
          actions: [
            {
              label: 'CLOSE',
              class: 'button-outline',
              callback: () => {
                this.ModalService.close()
              }
            }
          ]
        })
      })
  }

  fetchProfile (userId) {
    const me = `${this.Configuration.apiUrl}/experts/profile${this.mode}`
    const urlEndpoint = userId ? `${this.Configuration.apiUrl}/users/${userId}` : me

    if (this.response) {
      return this.$q.resolve(this.response)
    }

    return this.$http.get(urlEndpoint, { })
      .then(response => {
        this.resources = {
          portfolio: response.data.resources.portfolio.url,
          reviews: response.data.resources.reviews.url,
          stats: response.data.resources.stats.url,
        }
        this.response = response
        return response
      })
  }

  fetchStats () {
    return this.$http.get(`${this.Configuration.apiUrl}${this.resources.stats}`, { })
      .then(response => {
        return response.data
      })
  }

  setMode (mode) {
    this.mode = mode === 'draft' ? '/draft?mode=preview' : ''
  }

  setPortfolioItems (portfolioResponse) {
    this.portfolioResponse = portfolioResponse
  }

  fetchPortfolio () {
    return this.$http.get(
      `${this.Configuration.apiUrl}${this.resources.portfolio}`, { }
    )
      .then(response => {
        return response
      })
  }

  setReviews (reviewsResponse) {
    this.reviewsResponse = reviewsResponse
  }

  fetchReviews (page, pagination) {
    const PER_PAGE = 10
    const queryJoiner = this.resources.reviews.includes('?') ? '&' : '?'
    const query = page ? `page=${page}&per_page=${PER_PAGE}` : `page=1&per_page=${PER_PAGE}`
    return this.$http.get(
      `${this.Configuration.apiUrl}${this.resources.reviews}${queryJoiner}${query}`, { })
      .then(response => {
        return response
      })
  }
}
export default ExpertProfileViewModalService
