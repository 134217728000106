(function () {
  'use strict';

  function flash($rootScope, $timeout) {
    var link = function ($scope) {
      console.log('FlashDir (legacy) > link init');

      // headerSettings were originally in legacy application controller (now removed)
      $scope.headerSettings = {
        displayNotice: false
      };

      $rootScope.$on('showFlash', function (event, data) {
        console.log('FlashDir (legacy) > showFlash');

        $scope.headerSettings.displayNotice = true;

        $scope.flashScope = data.flashScope;
        $scope.partial = data.partial;
        $scope.persistent = data.persistent;

        if (!$scope.persistent) {
          $timeout(() => {
            $scope.closeFlash();
          }, 10000);
        }

        $timeout(() => {
          $rootScope.$broadcast('flashDirChange');
        });
      });

      $rootScope.$on('hideFlash', function () {
        $scope.closeFlash();
      });

      $rootScope.$on('$stateChangeStart', function () {
        $scope.closeFlash();
      });

      $rootScope.$on('hideBanner', function () {
        $scope.headerSettings.displayNotice = false;
      });

      $rootScope.$on('showBanner', function () {
        $scope.headerSettings.displayNotice = true;
      });

      $scope.closeFlash = function () {
        if ($scope.headerSettings.displayNotice) {
          console.log('FlashDir (legacy) > closeFlash');
          $scope.headerSettings.displayNotice = false;

          $timeout(() => {
            $rootScope.$broadcast('flashDirChange');
          });
        }
      };
    };

    return {
      restrict: 'E',
      replace: false,
      templateUrl: require('../../views/flashes/flash.html'),
      link: link
    };
  }

  app.directive('flash', ['$rootScope', '$timeout', flash]);
})();
