import templateUrl from './expert-profile-visibility-modal.html'
import './expert-profile-visibility-modal.scss'

const ExpertProfileVisibilityModalComponent = {
  bindings: {
    showOnWbsite: '<?',
    showOnWbsiteHandler: '<?',
    toggleVisibilityAction: '<?',
  },
  require: {
    modalCtrl: '^^cdblModal'
  },
  templateUrl,
  controller: class ExpertProfileVisibilityModalComponent {
    constructor (ExpertProfileService) {
      'ngInject'
      this._identify = 'ExpertProfileVisibilityModalComponent'
      this.ExpertProfileService = ExpertProfileService
    }

    $onInit () {
      this.formData = {
        showOnWebsite: this.modalCtrl.modal.showOnWebsite()
      }
    }

    onChangeShowOnWebsiteHandler ($event) {
      this.modalCtrl.modal.showOnWebsiteHandler()
    }

    save () {
      this.ExpertProfileService
        .saveProfileVisibility(this.formData.showOnWebsite, this.toggleVisibilityAction)
        .then(res => {
          this.closeDialog()
        })
        .catch(err => {
          console.error(err)
        })
    }

    closeDialog () {
      this.modalCtrl.close()
    }
  }
}

export default ExpertProfileVisibilityModalComponent
