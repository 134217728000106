import moment from 'moment'
import templateUrl from './expert-profile-skills-and-expertise.html'
import './expert-profile-skills-and-expertise.scss'

const ExpertProfileSkillsAndExpertiseComponent = {
  bindings: {},
  templateUrl,
  controller: class ExpertProfileSkillsAndExpertiseComponent {
    constructor (UserService, ExpertProfileViewModalService) {
      'ngInject'
      this._identify = 'ExpertProfileSkillsAndExpertiseComponent'
      this.UserService = UserService
      this.ExpertProfileViewModalService = ExpertProfileViewModalService

      this.tabs = [
        {
          title: 'ABOUT',
          id: 'about',
          active: true,
          visibility: true
        },
        {
          title: 'REVIEWS',
          id: 'reviews',
          active: false,
          visibility: false
        },
        {
          title: 'PORTFOLIO',
          id: 'portfolio',
          active: false,
          visibility: false
        },
      ]
      this.selectedTab = this.tabs[0]
      this.isLoading = false
    }

    selectTab (tab) {
      this.tabs.forEach(tab => {
        tab.active = false
      })
      tab.active = true
      this.selectedTab = tab
    }

    locationFormatter (expertLocation) {
      if (expertLocation && expertLocation.displayName) {
        return expertLocation.displayName
      }

      return ''
    }

    setTabVisibility (id, visibility) {
      this.tabs = this.tabs.map(tab => {
        if (tab.id === id) {
          return { ...tab, visibility }
        }
        return tab
      })
    }

    $onInit () {
      this.isLoading = true
      this.ExpertProfileViewModalService
        .fetchProfile()
        .then(res => {
          const data = res.data
          this.shortBio = data.shortBio
          this.longBio = data.longBio
          this.location = data.location
          this.timezone = data.timezone
          this.expertRole = data.expertRole
          this.languages = data.languages
          this.skills = data.skills
          this.preferredProjectTypes = data.preferredProjectTypes
          this.signUpDatePart1 = moment(data.memberSince).format('MMM YYYY')
          this.signUpDatePart2 = moment(data.memberSince).fromNow(true)
          this.fullName = data.fullName || `${this.UserService.user.firstName} ${this.UserService.user.lastName}`
          this.avatarLargeUrl = (data.avatars && data.avatars.largeUrl) || this.UserService.user.avatar.largeUrl
          return this.ExpertProfileViewModalService.fetchStats()
        })
        .then(res => {
          this.stats = res
          return this.ExpertProfileViewModalService.fetchReviews()
        })
        .then(res => {
          if (res.data.length > 0) {
            this.ExpertProfileViewModalService.setReviews(res)
            this.setTabVisibility('reviews', true)
          }
          return this.ExpertProfileViewModalService.fetchPortfolio()
        })
        .then(res => {
          if (res.data.items.length > 0) {
            this.ExpertProfileViewModalService.setPortfolioItems(res)
            this.setTabVisibility('portfolio', true)
          }
        })
        .catch(e => {
          console.error(e)
        })
        .finally(() => {
          this.isLoading = false
          this.ExpertProfileViewModalService.response = null
        })
    }
  }
}

export default ExpertProfileSkillsAndExpertiseComponent
