import templateUrl from './referred-partner-notice.html'
import './referred-partner-notice.scss'

const ReferredPartnerNoticeComponent = {
  bindings: {
    task: '<'
  },
  templateUrl,
  controller: class ReferredPartnerNotice {
    constructor (Storage, UserService, ProjectService) {
      'ngInject'
      this._identify = 'ReferredPartnerNoticeComponent'
      this.Storage = Storage
      this.UserService = UserService
      this.ProjectService = ProjectService
    }

    $onInit () {
      this.referredPartnerNotice = this.Storage.get('referredPartnerNotice') || {}
      this.alreadyAgreed = this.referredPartnerNotice[this.task.id] || false
    }

    agreeToRules () {
      this.referredPartnerNotice[this.task.id] = true
      this.alreadyAgreed = true

      this.Storage.set('referredPartnerNotice', this.referredPartnerNotice)
    }
  }
}

export default ReferredPartnerNoticeComponent
