(function () {
  'use strict';

  function GoBackCtrl($scope, $state, NavigationService) {
    console.log('GoBackCtrl > init',  NavigationService.previousState);
    $scope.$state = $state;

    $scope.goBackLabel = function () {
      let label = 'PREVIOUS PAGE';
      if (!NavigationService.previousState ||
          NavigationService.previousState.name === '' ||
          NavigationService.previousState.name === 'tasks.create' ||
          NavigationService.previousState.name === 'dashboard.list') { // no history
        label = 'DASHBOARD';
      } else if (NavigationService.previousState.name === 'tasks.all') {
        label = 'BACK TO NEW PROJECTS';
      }

      return label;
    };

    $scope.goBack = function () { // window.history.back() is not sufficient, because we can come back from paypal
      if (!NavigationService.previousState || NavigationService.previousState.name === '') { // no history
        $state.go('dashboard.list');
      } else if (['tasks.display', 'dashboard.list'].includes(NavigationService.previousState.name)) {
        $state.go('dashboard.list', { listId: 'pending' });
      } else {
        $state.go(NavigationService.previousState.name);
      }
    };

  }

  app.controller('GoBackCtrl', ['$scope', '$state', 'NavigationService', GoBackCtrl]);
})();
