(function () {
  'use strict';

  function comment(UserService, marked, newlinesFilter, markupFilter, CommentService) {
    var link = function ($scope, $element) {
      $scope.currentUser = UserService.user;
      $scope.UserService = UserService;

      var _editCommentAllowedExpression = function (userId) {
        var userCondition = UserService.user.id === userId;
        var timeCondition = (new Date().getTime() / 1000) - $scope.comment.publishedAt < 300; // 5 minutes edit time

        return userCondition && timeCondition;
      };

      // This creates the fullname which is needed to render properly
      if (!$scope.comment.user.fullName) {
        $scope.comment.user.fullName = $scope.comment.user.firstName + ' ' + $scope.comment.user.lastName;
      }

      // console.log('[commentDir] > comment', $scope.comment);

      $scope.editComment = false;
      $scope.editCommentAllowed = false;
      $scope.showEditComment = false;

      var initialComment = JSON.parse(JSON.stringify($scope.comment));

      $scope.$watch('comment', function (newValue) {
        if (newValue) {
          if (newValue && newValue.user && _editCommentAllowedExpression(newValue.user.id)) {
            $scope.editCommentAllowed = true;
          }
        }
      });

      $scope.toggleShowEditComment = function (value) {
        if ($scope.editCommentAllowed) {
          $scope.showEditComment = value;
        }
      };

      $scope.toggleEditComment = function () {
        if ($scope.editCommentAllowed) {
          $scope.editComment = !$scope.editComment;
        }
      };

      $scope.cancelEditComment = function () {
        $scope.editComment = false;
        $scope.showEditComment = false;
        $scope.comment.edited = initialComment.edited;
        $scope.comment.isContractorsOnly = initialComment.isContractorsOnly;

        $scope.comment.markedHTML = initialComment.markedHTML;
      };

      $scope.postEditComment = function (comment) {
        if (!_editCommentAllowedExpression(comment.user.id)) {
          $scope.editCommentAllowed = false;
          $scope.editTimeLimit = true;

          return;
        }

        $scope.editComment = false;
        $scope.editCommentAllowed = false;

        CommentService.updateComment($scope.comment)
          .then(response => {
            Object.assign($scope.comment, response);
            $scope.comment.edited = true;
            $scope.editCommentAllowed = true;

            $scope.comment.markedHTML = CommentService.processComment($scope.comment).markedHTML;

            initialComment = JSON.parse(JSON.stringify($scope.comment));
          });
      };
    };

    return {
      restrict: 'E',
      replace: true,
      scope: {
        comment: '=',
        activity: '<',
        task: '=',
        hihlightText: '@',
        refactoredMode: '<'
      },
      templateUrl: require('../../views/comments/comment.html'),
      link: link
    };
  }

  app.directive('comment', ['UserService', 'marked', 'newlinesFilter', 'markupFilter', 'CommentService', comment]);
})();
