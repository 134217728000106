const NEW_APP_TOKEN = 'app-auth'
const AuthTokenService = class AuthTokenService {
  constructor (Configuration, StorageService) {
    'ngInject'
    this.Configuration = Configuration
    this.StorageService = StorageService
  }


  save (token) {
    this.StorageService.nativeSet(NEW_APP_TOKEN, token)
    // this.StorageService.set(this.Configuration.auth.tokenKey, token)
    // console.log('[AuthTokenService] > save > NEW AUTH TOKEN')
  }

  get () {
    const newAppToken = this.StorageService.nativeGet(NEW_APP_TOKEN)
    if (newAppToken) {
      return newAppToken
    }
    return this.StorageService.get(this.Configuration.auth.tokenKey)
  }

  destroy () {
    // remove both old and new tokens
    this.StorageService.nativeRemove(NEW_APP_TOKEN)
    this.StorageService.destroy(this.Configuration.auth.tokenKey)
    // console.log('[AuthTokenService] > destroy')
  }
}
export default AuthTokenService
