const AdditionalTasksService = class AdditionalTasksService {
  constructor ($http, Configuration) {
    'ngInject'
    this.$http = $http
    this.Configuration = Configuration
  }

  getBillingPreview (projectId, amount) {
    console.log('[AdditionalTasksService] > getBillingPreview', projectId, amount)
    return this.$http.get(`${this.Configuration.apiUrl}/tasks/${projectId}/estimates/calculate?amount=${amount}&type=sub-task`)
      .then(response => {
        return response.data
      })
  }

  createTask (projectId, payload) {
    console.log('[AdditionalTasksService] > createTask', projectId, payload)
    return this.$http.post(`${this.Configuration.apiUrl}/tasks/${projectId}/sub_tasks`, payload)
      .then(response => {
        return response.data
      })
  }

  createRetainerTask (projectId, payload) {
    console.log('[AdditionalTasksService] > createRetainerTask', projectId, payload)
    return this.$http.post(`${this.Configuration.apiUrl}/tasks/${projectId}/retainers`, payload)
      .then(response => {
        return response.data
      })
  }

  cancelRetainerTask (retainerId) {
    console.log('[AdditionalTasksService] > cancelRetainerTask', retainerId)
    // console.warn('[AdditionalTasksService] > retainers are disabled', retainerId)
    return this.$http.delete(`${this.Configuration.apiUrl}/retainers/${retainerId}/payments`)
      .then(response => {
        return response.data
      })
  }

  cancelTask (projectId, taskId) {
    console.log('[AdditionalTasksService] > cancelTask', taskId)
    // console.warn('[AdditionalTasksService] > retainers are disabled', retainerId)
    return this.$http.delete(`${this.Configuration.apiUrl}/tasks/${projectId}/sub_tasks/${taskId}`)
      .then(response => {
        return response.data
      })
  }

  markCompleted (projectId, taskId) {
    // Note: BE returns empty response
    return this.$http.put(`${this.Configuration.apiUrl}/tasks/${projectId}/sub_tasks/${taskId}/complete`)
      .then(response => {
        return response.data
      })
  }

  renewRetainer (retainer) {
    const requestConfig = { ...retainer.paymentHistory.actions.renew }
    requestConfig.url = `${this.Configuration.apiUrl}${requestConfig.url}`
    return this.$http(requestConfig)
      .then(response => response.data)
  }
}
export default AdditionalTasksService
