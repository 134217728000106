import templateUrl from './account-avatar-upload.html'
import './account-avatar-upload.scss'

const AccountAvatarUploadComponent = {
  bindings: {},
  templateUrl,
  controller: class AccountAvatarUploadComponent {
    constructor ($element, $attrs, $timeout, UserService, SettingsService) {
      'ngInject'
      this._identify = 'AccountAvatarUploadComponent'
      this.$element = $element
      this.$attrs = $attrs
      this.$timeout = $timeout
      this.UserService = UserService
      this.SettingsService = SettingsService
    }

    $onInit () {
      this.accountAvatarImage = this.$element[0].querySelector('#account-avatar-upload')
      this.onSelectFileHandler = this.onSelectFile.bind(this)
      this.progess = 0
      this.formData = {}
    }

    updateProgress (progress) {
      console.log('[AccountAvatarUploadComponent] > updateProgress > progress', progress, this.isUploading)
      this.progress = progress
    }

    get isUploading () {
      return this.isProcessing && this.progress < 100
    }

    onSelectFile ($event) {
      console.log('[AccountAvatarUploadComponent] > onSelectFile', this.formData)
      $event.preventDefault()

      this.formData.avatar = $event.currentTarget.files
      this.isProcessing = true
      this.error = false
      this.SettingsService.uploadAvatar(this.formData, this.updateProgress.bind(this))
        .then(response => {
          console.log('[AccountAvatarUploadComponent] > avatar uploaded', response)
          this.UserService.updateUser({
            avatar: response.avatar
          })
        })
        .catch(err => {
          console.log('[AccountAvatarUploadComponent] > avatar upload error', err)
          // TODO: this is temporary place for mapping error messages.
          // - create better error mapping method - see error-message component,
          // - place it there and here just provide mappings object, the actual mapping should happen in error-message component
          // - currently error-message component is set up to provide custom messages and map based on the status code, find a good solution for that supporting different error structures with fallbacks
          if (err.errors && err.errors[0] && err.errors[0] && err.errors[0].message === 'dimensions too large') {
            this.error = 'Uploaded picture is too large. Please choose a smaller file.'
          } else if (err.errors && err.errors[0] && err.errors[0] && err.errors[0].message === 'failed to be processed') {
            this.error = 'Something went wrong with processing of the image. Choose different image and try again.'
          } else if (err.errors && err.errors[0] && err.errors[0] && err.errors[0].message === 'is not of an allowed file type') {
            this.error = 'Image type not supported. Allowed types: jpg, jpeg, gif, png.'
          } else {
            this.error = err
          }
        })
        .finally(() => {
          this.isProcessing = false
          this.$timeout()
        })
    }

    selectFile () {
      this.accountAvatarImage.click()
    }
  }
}

export default AccountAvatarUploadComponent

