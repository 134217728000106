import templateUrl from './header-help-menu-popover.html'
import './header-help-menu-popover.scss'

const HeaderHelpMenuPopoverComponent = {
  bindings: {},
  templateUrl,
  controller: class HeaderHelpMenuPopoverComponent {
    constructor (UserService, IntercomService, SleekService) {
      'ngInject'
      this.UserService = UserService
      this.IntercomService = IntercomService
      this.SleekService = SleekService
    }

    showIntercom () {
      console.log('appComponent > showIntercom')
      this.IntercomService.showMessage()
    }
  }
}

export default HeaderHelpMenuPopoverComponent
