const projectVersionFilter = function ($filter, ProjectService) {
  'ngInject'
  return function (project, version) {
    if (typeof project === 'object') {
      return project.submissionFormVersion >= version
    }
    return project >= version
  }
}
export default projectVersionFilter
