(function () {
  'use strict';

  function modelChanges(Modal, $state, _) {
    var link = function ($scope) {
      var initialModel = JSON.parse(JSON.stringify($scope.model));
      var nextState = null;

      $scope.$on('$stateChangeStart', function (event, toState) {
        if (!$scope.model) {
          return;
        }

        if (!_.isEqual(JSON.parse(JSON.stringify($scope.model)), JSON.parse(JSON.stringify(initialModel)))) {
          event.preventDefault();
          nextState = toState;
          Modal.open('views/modals/changes_page.html', $scope, {closeButton: false});
          return;
        }
      });

      $scope.$on('modelChangesInitialModelChange', function (event, model) {
        initialModel = JSON.parse(JSON.stringify(model));
      });

      $scope.continueModal = function () {
        $scope.model = $scope.modelCompare;
        Modal.close();
        $state.go(nextState);
      };

      $scope.cancelModal = function () {
        Modal.close();
      };
    };

    return {
      restrict: 'A',
      scope: {
        model: '='
      },
      link: link
    };
  }

  app.directive('modelChanges', ['Modal', '$state', '_', modelChanges]);
})();
