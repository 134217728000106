import templateUrl from './submission-firstproject.html'
import './submission-firstproject.scss'

const SubmissionFirstprojectComponent = {
  bindings: {

  },
  templateUrl,
  controller: class SubmissionFirstproject {
    constructor ($stateParams, $state, UserService, IntercomService, SegmentAnalytics, FeeService) {
      'ngInject'
      this._identify = 'SubmissionFirstprojectComponent'
      this.$stateParams = $stateParams
      this.$state = $state
      this.IntercomService = IntercomService
      this.SegmentAnalytics = SegmentAnalytics

      this.activeStep = 0
      this.maxStep = 1

      this.workroomId = $stateParams.id

      this.clientFee = FeeService.getServiceFee('clientTask')

      const firstProjectData = UserService.getFirstProjectFlag()
      this.projectType = firstProjectData && firstProjectData.projectType === 'consultation' ? 'consultation' : 'project'
      console.log('firstProjectData', firstProjectData)

      // remove flag
      if (this.workroomId) {
        UserService.clearFirstProjectFlag()
      }
    }

    askForHelp () {
      this.IntercomService.showMessage('Hi! I would like to post a new project and I need help.')
      this.SegmentAnalytics.trackIntercomOpened('first project info page')
    }

    nextStep () {
      this.activeStep = Math.min(this.activeStep + 1, this.maxStep)
    }
    goToStep (step) {
      this.activeStep = Math.max(0, Math.min(step, this.maxStep))
    }
    isStep (step) {
      return this.activeStep === step
    }
    goToWorkroom () {
      if (this.workroomId) {
        this.$state.go('tasks.display', this.$stateParams)
      } else {
        this.$state.go('dashboard.list', { listId: 'pending' })
      }
    }
  }
}

export default SubmissionFirstprojectComponent
