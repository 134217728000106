class AuthorizationPermissionError extends Error {
  constructor (message) {
    const messageText = message ? `Unauthorized: ${message}` : 'Unauthorized'
    super(messageText)

    this.name = 'AuthorizationPermissionError'
    this.message = 'Unauthorized'
    this.description = 'User authentication required.'
  }
}

export default AuthorizationPermissionError
