const PaymentsStripeService = class PaymentsStripeService {
  constructor ($http, Configuration) {
    'ngInject'
    this.$http = $http
    this.Configuration = Configuration
  }

  getStripeUrl (payload) {
    return this.$http
      .post(`${this.Configuration.apiUrl}/payments/stripe/checkout_sessions`, payload)
      .then(response => {
        return response.data
      })
  }
}
export default PaymentsStripeService
