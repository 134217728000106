(function () {
  'use strict';

  function CancelSubscriptionCtrl($scope, Auth, Modal, ModalService) {
    $scope.task = $scope.modalScope.task;
    $scope.currentUser = $scope.modalScope.currentUser;

    $scope.cancelSubscription = function () {
      $scope.task.all('subscription').customDELETE().then(function () {
        $scope.modalScope.task.state = 'completed';

        if (Auth.me().completedTasksCount) {
          Auth.changeMe('completedTasksCount', Auth.me().completedTasksCount + 1);
        }

        Modal.close();

        ModalService.open({
          closeOnBackdrop: false,
          closeIcon: false,
          layout: 'center',
          class: 'project-review-modal',
          template: `<cdbl-project-review-modal project="$ctrl.modal.project"></cdbl-project-review-modal>`,
          project: $scope.task
        });
      });
    };
  }

  app.controller('CancelSubscriptionCtrl', ['$scope', 'Auth', 'Modal', 'ModalService', CancelSubscriptionCtrl]);
})();
