(function () {
  'use strict';

  function passwordWithReveal() {
    var link = function ($scope) {
      $scope.passwordText = 'Reveal password';
      $scope.fieldType = 'password';
      $scope.revealed = false;

      $scope.toggleFieldType = function () {
        $scope.fieldType = ($scope.fieldType === 'password') ? 'text' : 'password';
        $scope.passwordText = ($scope.fieldType === 'password') ? 'Reveal password' : 'Hide password';
        $scope.revealed = !$scope.revealed;
      };
    };

    return {
      restrict: 'A',
      link: link,
      scope: true
    };
  }

  app.directive('passwordWithReveal', passwordWithReveal);
})();
