import templateUrl from './status-badge.html'
import './status-badge.scss'

const StatusBadgeComponent = {
  bindings: {
    icon: '@?'
  },
  transclude: true,
  templateUrl,
  controller: class StatusBadgeComponent {
    constructor ($element) {
      'ngInject'
      this._identify = 'StatusBadgeComponent'
      this.$element = $element
    }

    $onInit () {
      this.$element.addClass('cdbl-status-badge')
    }
  }
}

export default StatusBadgeComponent
