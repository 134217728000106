(function () {
  'use strict';

  function taskKindToString() {
    return function (taskKind) {
      var taskKindString = '';

      switch (taskKind) {
        default:
          taskKindString = taskKind.capitaliseFirstLetter();
      }

      return taskKindString;
    };
  }

  app.filter('taskKindToString', taskKindToString);
})();
