import WoocommerceSubmissionConfig from './submission.config.woocommerce'
import SitegroundrcSubmissionConfig from './submission.config.sitegroundrc'
import Barn2ukSubmissionConfig from './submission.config.barn2uk'
import CodeableSubmissionConfig from './submission.config.codeable'
import CodeableVariantSubmissionConfig from './submission.config.codeable.variant'

const SubmissionConfig = {
  woocommerce: WoocommerceSubmissionConfig,
  sitegroundrc: SitegroundrcSubmissionConfig,
  barn2uk: Barn2ukSubmissionConfig,
  codeableVariant: CodeableVariantSubmissionConfig,
  codeable: CodeableSubmissionConfig
}

export default SubmissionConfig
