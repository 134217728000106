import AuthService from './auth.service'
import AuthTokenService from './auth-token.service'

const AuthModule = angular.module('cdbl.auth', [

])
  .service('AuthService', AuthService)
  .service('AuthTokenService', AuthTokenService)
  .name

export default AuthModule
