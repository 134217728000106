import templateUrl from './settings-expert-team.html'
import './settings-expert-team.scss'

const SettingsExpertTeamComponent = {
  bindings: {},
  templateUrl,
  controller: class SettingsExpertTeamComponent {
    constructor ($timeout, ModalService, SettingsExpertTeamService) {
      'ngInject'
      this._identify = 'SettingsExpertTeamComponent'
      this.$timeout = $timeout
      this.ModalService = ModalService
      this.SettingsExpertTeamService = SettingsExpertTeamService
    }


    async $onInit () {
      this.formData = {}
      this.error = null

      this.team = null
      this.resources = null

      this.isLoading = true
      await this.SettingsExpertTeamService.getExpertTeamSettings()
        .then(response => {
          this.team = response
        })
        .catch(err => {
          this.error = err
        })

      await this.SettingsExpertTeamService.getAllResources(this.team.resources)
        .then(response => {
          this.resources = response
          console.log(response)
          // load resources
        })
        .catch(err => {
          this.error = err
        })
      this.isLoading = false
      this.$timeout()
    }

    get showFirstTimeSetupStep () {
      return this.team && this.team.details === null
    }

    get showTeamConfirmation () {
      return this.team && this.team.actions.updateConfirmation
    }

    confirmTeamInformation () {
      // delete this.team.actions.updateConfirmation
      this.isProcessingConfirmation = true
      this.SettingsExpertTeamService.confirmTeamInformation(this.team)
        .then(response => {
          this.team = response
        })
        .finally(() => {
          this.isProcessingConfirmation = false
        })
    }

    openTeamDetailsEditModal () {
      this.ModalService.open({
        closeOnBackdrop: false,
        closeIcon: true,
        class: 'settings-expert-team-details-edit-modal cdbl-modal--style-with-dividers cdbl-modal--style-with-sticky-header-and-footer',
        template: `
          <cdbl-settings-expert-team-details-edit-modal
            team="$ctrl.modal.team"
            on-save="$ctrl.modal.onDetailsSaveHandler($event)">
          </cdbl-settings-expert-team-details-edit-modal>`,
        team: this.team,
        onDetailsSaveHandler: this.onDetailsSaveHandler.bind(this)
      })
    }

    onDetailsSaveHandler ($event) {
      console.log($event)
      this.team.details = { ...$event.details }
    }


    openTeamMemberEditModal (member = null) {
      this.ModalService.open({
        closeOnBackdrop: false,
        closeIcon: true,
        class: 'settings-expert-team-member-edit-modal cdbl-modal--style-with-dividers cdbl-modal--style-with-sticky-header-and-footer',
        template: `
          <cdbl-settings-expert-team-member-edit-modal
            team="$ctrl.modal.team"
            member="$ctrl.modal.member"
            resources="$ctrl.modal.resources"
            on-save="$ctrl.modal.onMemberSaveHandler($event)">
          </cdbl-settings-expert-team-member-edit-modal>`,
        team: this.team,
        resources: this.resources,
        member: member,
        onMemberSaveHandler: this.onMemberSaveHandler.bind(this)
      })
    }

    onMemberSaveHandler ($event) {
      console.log('onMemberSaveHandler', $event)
      const member = { ...$event.member }
      const existingMemberIndex = this.team.members.findIndex(m => m.id === member.id)
      if (existingMemberIndex !== -1) {
        this.team.members[existingMemberIndex] = member
      } else {
        this.team.members.push(member)
      }
    }

    async onMemberActiveToggleHandler ($event) {
      console.log('onMemberActiveToggleHandler', $event)
      await this.SettingsExpertTeamService.toggleActiveMember($event.member, $event.active)
        .then(response => {
          console.log('onMemberActiveToggleHandler > ', response)
          const existingMemberIndex = this.team.members.findIndex(m => m.id === response.id)
          if (existingMemberIndex !== -1) {
            this.team.members.splice(existingMemberIndex, 1, response)
          }
        })
        .catch(err => {
          console.log(err)
        })
      this.$timeout()
    }


    onMemberDeleteHandler (member) {
      const config = this.ModalService.generateConfig('danger', {
        title: 'Delete member?',
        body: `Are you sure you want to delete member <strong>${member.name}</strong>?`,
        rejectButton: {
          label: 'No, Cancel'
        },
        resolveButton: {
          label: 'Yes, Delete'
        },
      })

      this.ModalService.open(config)
        .then(response => {
          this.SettingsExpertTeamService.deleteMember(member)
            .then(response => {
              const removeIndex = this.team.members.findIndex(m => m.id === member.id)
              if (removeIndex > -1) {
                this.team.members.splice(removeIndex, 1)
              }
            })
            .catch(err => {
              console.log(err)
            })
            .finally(() => {
              this.ModalService.close()
              this.$timeout()
            })
        })
    }
  }
}

export default SettingsExpertTeamComponent
