import templateUrl from './error-renderer.html'
import './error-renderer.scss'

const ErrorRendererComponent = {
  bindings: {
    errorHandler: '<',
    template: '&',
    showResetButton: '<'
  },
  templateUrl,
  controller: class ErrorRenderer {
    constructor () {
      'ngInject'
      this._identify = 'ErrorRendererComponent'
    }

    $onInit () {
      this.template = this.template || 'list'
      this.showResetButton = this.showResetButton || true
    }

    reset () {
      this.errorHandler.reset()
    }

    get errorList () {
      if (this.errorHandler) {
        return this.errorHandler.list
      }

      return []
    }
  }
}

export default ErrorRendererComponent
