import templateUrl from './validation-message.html'
import './validation-message.scss'

const ValidationMessageComponent = {
  bindings: {
    message: '@?'
  },
  templateUrl,
  require: {
    fieldContainerCtrl: '^cdblFieldContainer'
  },
  controller: class ValidationMessageComponent {
    constructor ($attrs) {
      'ngInject'
      this._identify = 'ValidationMessageComponent'
      this.$attrs = $attrs
    }

    $onInit () {
      console.log(this.fieldContainerCtrl)
      if (typeof this.message !== 'undefined') {
        return
      }

      switch (this.$attrs.ngMessage) {
      case 'minlength':
        this.message = `The text you've entered is too short.`
        break
      case 'maxlength':
        this.message = `The text you've entered is too long.`
        break
      case 'min':
        if (this.fieldContainerCtrl &&
          this.fieldContainerCtrl._fieldElementCtrl &&
          this.fieldContainerCtrl._fieldElementCtrl.ngModel &&
          typeof this.fieldContainerCtrl._fieldElementCtrl.ngModel.$$attr.min !== 'undefined') {
          this.message = `The minimum number of selected items is ${this.fieldContainerCtrl._fieldElementCtrl.ngModel.$$attr.min}.`
        } else {
          this.message = `The minimum number of selected items is required.`
        }
        break
      case 'max':
        if (this.fieldContainerCtrl &&
          this.fieldContainerCtrl._fieldElementCtrl &&
          this.fieldContainerCtrl._fieldElementCtrl.ngModel &&
          typeof this.fieldContainerCtrl._fieldElementCtrl.ngModel.$$attr.max !== 'undefined') {
          this.message = `The maximum number of selected items is ${this.fieldContainerCtrl._fieldElementCtrl.ngModel.$$attr.max}.`
        } else {
          this.message = `The maximum number of selected items exceded.`
        }
        break
      case 'required':
        this.message = `This field is required.`
        break
      case 'email':
        this.message = `Please enter a valid email address.`
        break
      case 'url':
        this.message = `Please enter a valid URL (e.g. http://example.com)`
        break
      case 'checkRequired':
        this.message = `This field is required.`
        break
      default:
        this.message = `Field validation error.`
      }
    }
  }
}

export default ValidationMessageComponent
