import LayoutComponent from './layout.component'

import ToolbarModule from './toolbar'
import ContentModule from './content'
import SidebarModule from './sidebar'
import SectionModule from './section'

import NoticebarModule from './noticebar'

const LayoutModule = angular.module('cdbl.layout', [
  ToolbarModule,
  ContentModule,
  SidebarModule,
  NoticebarModule,
  SectionModule
])
  .component('cdblLayout', LayoutComponent)
  .name

export default LayoutModule
