import templateUrl from './queue-item.html'
import './queue-item.scss'

const AttachmentsUploadQueueItemComponent = {
  bindings: {
    data: '<',  // attachment name is taken as there is a legacy directive with same name - TODO: after done with new attachments remove old one and rename this to attachment
    mode: '<',
    onRemove: '&',
    showUploadProgress: '<',
    onAbortUpload: '&'
  },
  templateUrl,
  controller: class AttachmentsUploadQueueItem {
    constructor (EventEmitter) {
      'ngInject'
      this._identify = 'AttachmentsUploadQueueItemComponent'
      this.EventEmitter = EventEmitter
    }

    $onInit () {
      console.log('[AttachmentsUploadQueueItemComponent]', this.data)
      this.showUploadProgress = this.showUploadProgress || false
    }


    removeFromQueue () {
      if (this.onRemove) {
        this.onRemove(this.EventEmitter(this.data))
      }
    }

    cancelUpload () {
      if (this.onAbortUpload) {
        this.onAbortUpload()
      }

      if (this.data) {
        this.data.cancelUpload()
      }

      this.removeFromQueue()
    }
  }
}

export default AttachmentsUploadQueueItemComponent
