import AttachmentsSidebarWidgetComponent from './attachments-sidebar-widget.component'


const AttachmentsSidebarWidgetModule = angular.module('cdbl.attachments.attachments-sidebar-widget', [

])
  .component('cdblAttachmentsSidebarWidget', AttachmentsSidebarWidgetComponent)
  .name

export default AttachmentsSidebarWidgetModule
