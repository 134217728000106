import PaginationComponent from './pagination.component'
import PaginationService from './pagination.service'

const PaginationModule = angular.module('cdbl.pagination', [

])
  .component('cdblPagination', PaginationComponent)
  .service('PaginationService', PaginationService)
  .name

export default PaginationModule
