(function () {
  'use strict';

  function pagination(_) {
    var link = function ($scope) {
      $scope.goToPage = function (page, onlyPaginationReset) {
        if (!$scope.pagination) {
          return;
        }

        onlyPaginationReset = onlyPaginationReset || false;

        if (!onlyPaginationReset) {
          $scope.processing = true;

          $scope.pagination.goToPage(page).then(function (data) {
            $scope.resource = data;
            $scope.processing = false;
          });
        }

        var minPage = page - 3 > 0 ? page - 3 : 1;
        var maxPage = page + 3 < $scope.pagination.pageCount() ? page + 4 : $scope.pagination.pageCount() + 1;

        if (page >= 1 && page <= 3 && page && $scope.pagination.pageCount() > 3) {
          maxPage += 3 - page;
        }

        if ($scope.pagination.pageCount() - 3 <= page && page <= $scope.pagination.pageCount() && page > 3) {
          minPage += $scope.pagination.pageCount() - page - 3;
        }

        if (maxPage > $scope.pagination.pageCount()) {
          maxPage = $scope.pagination.pageCount() + 1;
        }

        if (minPage < 0) {
          minPage = 1;
        }

        $scope.pages = _.range(minPage, maxPage);
      };

      if ($scope.scrollToTop) {
        $scope.$on('pagination-refreshed', function () {
          if ($scope.scrollToTop === 'window') {
            $(window).scrollTop(0);
          } else {
            $($scope.scrollToTop).scrollTop(0);
          }
        });
      }

      $scope.goToNextPage = function () {
        if (!$scope.pagination || !$scope.pagination.nextPageExists()) {
          return;
        }

        $scope.goToPage($scope.pagination.currentPage() + 1);
      };

      $scope.goToPreviousPage = function () {
        if (!$scope.pagination || !$scope.pagination.previousPageExists()) {
          return;
        }

        $scope.goToPage($scope.pagination.currentPage() - 1);
      };

      $scope.$watch('pagination.pageCount()', function () { // when we change filter we change page count
        $scope.goToPage(1, true);
      });

      $scope.$watch('pagination.currentPage()', function () { // initialize current page
        if (!$scope.pagination) {
          return;
        }

        $scope.goToPage($scope.pagination.currentPage(), true);
      });

      $scope.$on('pagination-reset', function () {
        $scope.goToPage(1, false);
      });
    };

    return {
      restrict: 'E',
      replace: true,
      scope: {
        pagination: '=',
        resource: '=',
        scrollToTop: '@'
      },
      templateUrl: require('../../views/directives/pagination.html'),
      link: link
    };
  }

  app.directive('pagination', ['_', pagination]);
})();
