import templateUrl from './filter-widget.html'
import './filter-widget.scss'

const FilterWidgetComponent = {
  bindings: {
    onApply: '&?',
    onClear: '&?',
    hideFilter: '<?',
    hideSort: '<?',
    sortOptions: '<?',
    filterData: '<?'
  },
  templateUrl,
  transclude: true,
  controller: class FilterWidgetComponent {
    constructor (EventEmitter) {
      'ngInject'
      this._identify = 'FilterWidgetComponent'
      this.EventEmitter = EventEmitter
    }

    $onInit () {
      this.sortList = typeof this.sortOptions !== 'undefined' ? this.sortOptions : [
        { id: 'asc', name: 'Ascending', field: 'created_at' },
        { id: 'desc', name: 'Descending', field: 'created_at', default: true }
      ]
      this.filter = {
        open: false,
        active: false,
        sort: this.findDefaultSort()
      }
    }

    get isFilterEmpty () {
      console.log(Object.keys(this.filterData).filter(key => key !== 'sort' && this.filterData[key] !== null), this.filterData)
      if (this.filterData) {
        return Object.keys(this.filterData).filter(key => key !== 'sort' && this.filterData[key] !== null).length === 0
      }
      return true
    }

    get isFilterApplied () {
      return this.filter.active
    }


    toggle () {
      this.filter.open = !this.filter.open
    }
    open () {
      this.filter.open = true
    }
    close () {
      this.filter.open = false
    }

    get isFilterOpen () {
      return this.filter.open
    }

    onApplyHandler () {
      if (typeof this.onApply === 'function') {
        this.filter.active = !this.isFilterEmpty
        this.onApply(this.EventEmitter({ filter: this.filter }))
      }
    }

    onClearHandler () {
      this.filter.sort = this.findDefaultSort()
      if (typeof this.onClear === 'function') {
        this.filter.active = false
        this.onClear(this.EventEmitter({ filter: this.filter }))
      }
    }

    findDefaultSort () {
      if (!Array.isArray(this.sortList)) {
        return null
      }

      const defaultSort = this.sortList.find(sortOption => sortOption.default)
      if (defaultSort) {
        return defaultSort.id
      }

      return null
    }
  }
}

export default FilterWidgetComponent
