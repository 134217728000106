
const ngFormDirective = () => {
  // function hasError(formField) {
  //   if (angular.isDefined(formField)) {
  //     return formField.$touched && formField.$invalid;
  //   }
  //   return false;
  // }
  return {
    require: 'form',
    link: function (scope, elem, attr, form) {
      form.hasError = formField => {
        // console.log('hasError', formField.$name, hasError(formField));
        return hasError(formField)
      }

      function hasError (formField) {
        let field = formField
        if (typeof formField === 'string') {
          field = form[formField]
        }
        if (angular.isDefined(field)) {
          return field.$invalid && (field.$dirty || field.$touched)
        }
        return false
      }
    }
  }
}

export default ngFormDirective
