(function () {
  'use strict';

  // Price format; prepends $, formats number and limits to 2 decimals
  function priceFormat($filter) {
    return function (input, useCurrency) {
      if (isNaN(input) || input === null) {
        input = 0;
      }
      var postfix = useCurrency ? ' USD' : '';
      var price = $filter('number')(input, 2);
      return '$' + price.replace(/[.]00$/, '') + postfix;
    };
  }

  app.filter('priceFormat', ['$filter', priceFormat]);
})();
