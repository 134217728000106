// import { AuthorizationPermissionError } from '@/app/common/errors'

const runSequence = ($rootScope, $window, $location, $urlRouter, $state, $locale, UIService, UserService, Configuration, SegmentAnalytics, IntercomService, Restangular, NavigationService, Flash) => {
  'ngInject'

  // Scroll to top on view load
  $rootScope.$on('$viewContentLoaded', function () {
    $window.scrollTo(0, 0) // otherwise page is not scrolled to the top (instead to ui-view)
  })

  // set up locale settings for customized number and currency filters
  $locale.NUMBER_FORMATS.GROUP_SEP = ','
  $locale.NUMBER_FORMATS.DECIMAL_SEP = '.'


  // TODO: deprecate and use $http instead
  // Setup Restanglar - remove when migrated fully to $http
  Restangular.setBaseUrl(Configuration.apiUrl)
  // Restangular by default doesn't return headers, you can set setFullResponse to true, but out solution is much better,
  // because it returns object without additional nesting
  Restangular.addResponseInterceptor(function (data, operation, what, url, response) {
    if (data && response.headers) {
      data.headers = response.headers
    }
    return data
  })

  // Decorator for Restangular's "model"
  Restangular.extendModel('tasks', function (task) {
    task.isEditable = function () {
      return task.state !== 'completed'
    }
    return task
  })

  $window.gapi.load('auth2', function () {
    // eslint-disable-next-line no-undef
    gapi.auth2.init({
      client_id: Configuration.googleClientId,
      plugin_name: 'chat'
    })
  })
}
export default runSequence
