import templateUrl from './expert-profile-about.html'
import './expert-profile-about.scss'

const ExpertProfileAboutComponent = {
  bindings: {
    about: '<',
  },
  templateUrl,
  controller: class ExpertProfileAboutComponent {
    constructor () {
      'ngInject'
      this._identify = 'ExpertProfileAboutComponent'
    }
  }
}

export default ExpertProfileAboutComponent
