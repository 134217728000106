import templateUrl from './project-scope-doc.html'
import './project-scope-doc.scss'

const ProjectScopeDocComponent = {
  bindings: {},
  templateUrl,
  controller: class ProjectScopeDoc {
    constructor ($stateParams, ModalService, ProjectService, ProjectScopeDocService) {
      'ngInject'
      this._identify = 'ProjectScopeDocComponent'
      this.$stateParams = $stateParams
      this.ModalService = ModalService
      this.ProjectService = ProjectService
      this.ProjectScopeDocService = ProjectScopeDocService
    }

    $onInit () {
      this.project = null
      if (this.$stateParams.id) {
        this.ProjectService.getProject(this.$stateParams.id)
          .then(response => {
            this.project = response
          })
          .catch(err => {
            console.log(err)
          })
      }
    }
  }
}

export default ProjectScopeDocComponent
