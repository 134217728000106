(function () {
  'use strict';

  function ResourceManipulation($q) {
    var resolveError = function (resource) {
      var deferred = $q.defer();

      resource.then(function (data) {
        deferred.resolve(data);
      }).catch(function () { // we need to resolve 403, otherwise $q.all never resolves
        deferred.resolve({});
      });

      return deferred.promise;
    };

    return {
      resolveError: resolveError
    };
  }

  app.service('ResourceManipulation', ['$q', ResourceManipulation]);
})();
