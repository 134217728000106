const MapNullToZero = function () {
  return function (value) {
    if (value === null) {
      return 0
    }
    return value
  }
}

export default MapNullToZero
