// This component is primarly used for BE side file uploads and serves as file selector with image preview as an option passing file list to BE endpoint. How preview mode should work and look like with other file types we need more requirements.
// If possible merge/migrate with the attachments-upload component, separating concerns of selecting files, showing queues, exposing methods, and doing FE side file upload into different parts components or service methods. This should be done after knowing how to support multi entity attachment uploads.


import templateUrl from './file-selector.html'
import './file-selector.scss'
import { FileSizeError } from '@/app/common/errors'

const FileSelectorComponent = {
  bindings: {
    config: '<?',
    onSelect: '&?',
    onSelectedFileRemoved: '&?'
  },
  require: {
    fieldCtrl: '?^cdblFieldContainer',
    ngModel: 'ngModel'
  },
  transclude: {
    fileSelectorHelper: '?fileSelectorHelper'
  },
  templateUrl,
  controller: class FileSelectorComponent {
    constructor ($timeout, $element, $attrs, ErrorsService, EventEmitter) {
      'ngInject'
      this._identify = 'FileSelectorComponent'
      this.$timeout = $timeout
      this.$element = $element
      this.$attrs = $attrs
      this.ErrorsService = ErrorsService
      this.EventEmitter = EventEmitter
    }

    $onInit () {
      this.config = {
        ui: 'dragdrop',
        multiple: false,
        preview: false,
        previewWidth: 200,
        previewHeight: 150,
        showFileName: false,
        showRemoveIcon: false,
        accept: '*',
        maxSize: null, // in bytes
        ...this.config
      }
      this.ngModel.$render = () => {
        this.queueList = Array.isArray(this.ngModel.$viewValue) ? this.ngModel.$viewValue : []
      }

      // register it in parent field container component
      if (this.fieldCtrl) {
        this.fieldCtrl.setFieldElementCtrl({
          ngModel: this.ngModel,
          $element: this.$element,
          $attrs: this.$attrs
        })
      }

      this.errorHandler = this.ErrorsService.createErrorHandler()
      this.elFileSelector = this.$element[0].querySelector('#cdbl-file-selector')

      const vm = this
      this.onFileSelectorChange = function ($event) {
        $event.preventDefault()
        const files = $event.currentTarget.files
        vm.$timeout(() => {
          vm.onFileSelectorChangeHandler(Array.from(files))
        })
      }

      this.previewImage = function (fileWrapper) {
        const reader = new FileReader()
        reader.addEventListener('load', function () {
          vm.$timeout(() => {
            fileWrapper.src = reader.result
          })
        }, false)
        if (fileWrapper.file) {
          reader.readAsDataURL(fileWrapper.file)
        }
      }

      this.$timeout(() => {
        this.checkValidity() // wait until next tick
      })
    }

    $doCheck () {
      this.$timeout(() => {
        this.checkValidity() // wait until next tick
      })
    }

    $onDestroy () {
      if (this.fieldCtrl) {
        this.fieldCtrl.setFieldElementCtrl(null)
      }
    }

    previewImageStyle (wrapper) {
      return {
        'background-image': `url(${wrapper.src})`,
        width: `${this.config.previewWidth}px`,
        height: `${this.config.previewHeight}px`
      }
    }

    updateNgModel (newValue) {
      this.ngModel.$setViewValue(newValue)
      this.ngModel.$setDirty()
      this.ngModel.$setTouched()
      this.checkValidity()
    }

    openFileSelectDialog () {
      console.log('[FileSelectorComponent] > openFileSelectDialog', this.ngModel)
      this.errorHandler.reset()
      this.elFileSelector.click() // invoke upload dialog
    }

    onDropHandler ($event) {
      $event.preventDefault()
      const dataTransfer = $event.dataTransfer || $event.originalEvent.dataTransfer
      if (dataTransfer.files) {
        this.onFileSelectorChangeHandler(Array.from(dataTransfer.files))
      }
    }
    onDragOverHandler (evt) {
      evt.preventDefault()
    }

    onFileSelectorChangeHandler (files) {
      console.log('[FileSelectorComponent] > onFileSelectorChangeHandler > files', files)
      if (!files || files.length === 0) {
        this.ngModel.$setTouched()
        return
      }

      // Reset file input to allow removal and selection of same file
      this.elFileSelector.value = null

      // Replace with new selection, if there will be requirements to add to exisiting queue list this can be changed to preserve previous selection
      this.queueList = files.filter(file => {
        if (this.config.maxSize < file.size) {
          this.errorHandler.add(new FileSizeError(file.name))
          return false
        }

        return true
      }).map(file => {
        return {
          file: file,
          src: null
        }
      })

      // trigger file preview
      if (this.config.preview) {
        this.queueList.forEach(fileWrapper => this.previewImage(fileWrapper))
      }

      if (typeof this.onSelect === 'function') {
        this.onSelect(this.EventEmitter({ queueList: this.queueList }))
      }

      this.updateNgModel(this.queueList.map(fileWrapper => {
        return fileWrapper.file
      }))
    }

    removeItemFromQueueList (fileWrapper) {
      const itemIndex = this.queueList.findIndex(fw => fw === fileWrapper)
      if (itemIndex > -1) {
        this.queueList.splice(itemIndex, 1)
      }

      this.updateNgModel(this.queueList.map(fileWrapper => {
        return fileWrapper.file
      }))

      if (typeof this.onSelectedFileRemoved === 'function') {
        this.onSelectedFileRemoved(this.EventEmitter({ queueList: this.queueList }))
      }
    }

    checkValidity () {
      if (typeof this.queueList === 'undefined') {
        return
      }

      if ('required' in this.$attrs && this.$attrs.required) {
        if (this.queueList.length === 0) {
          this.ngModel.$setValidity('required', false)
        } else {
          this.ngModel.$setValidity('required', true)
        }
      }

      if ('max' in this.$attrs) {
        if (this.queueList.length > parseInt(this.$attrs.max)) {
          this.ngModel.$setValidity('max', false)
        } else {
          this.ngModel.$setValidity('max', true)
        }
      }

      if ('min' in this.$attrs) {
        if (this.queueList.length < parseInt(this.$attrs.min)) {
          this.ngModel.$setValidity('min', false)
        } else {
          this.ngModel.$setValidity('min', true)
        }
      }
    }
  }
}

export default FileSelectorComponent
