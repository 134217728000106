// import jsonInflector from 'json-inflector'

const ProjectReviewService = class ProjectReviewService {
  constructor ($http, Configuration, UserService) {
    'ngInject'
    this.$http = $http
    this.Configuration = Configuration
    this.UserService = UserService
  }


  // Get client or expert project stats
  postNetPromotorScore (payload) {
    if (!('taskId' in payload && 'userId' in payload && 'score' in payload)) {
      console.error('[ProjectReviewService] > postNetPromotorScore > payload is wrong')
    }

    return this.$http
      .post(`${this.Configuration.apiUrl}/net_promoter_scores`, payload)
      .then(response => {
        return response.data
      })
  }

  // Get client or expert revenue stats
  postReview (projectId, reviewData) {
    const payload = {
      score: reviewData.score,
      comment: reviewData.comment,
    }

    if (reviewData.communication) {
      payload.communication = reviewData.communication
    }

    if (reviewData.responsiveness) {
      payload.responsiveness = reviewData.responsiveness
    }

    if (reviewData.socialMediaConsent) {
      payload.socialMediaConsent = reviewData.socialMediaConsent
    }

    return this.$http
      .post(`${this.Configuration.apiUrl}/tasks/${projectId}/reviews`, payload)
      .then(response => {
        return response.data
      })
  }
}
export default ProjectReviewService
