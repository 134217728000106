const DEFAULT_SOUND = 'click'

const SoundService = class SoundService {
  constructor ($http, Configuration, UserService) {
    'ngInject'
    this.$http = $http
    this.Configuration = Configuration
    this.UserService = UserService

    this.sounds = {}
  }

  getSounds () {
    return this.sounds
  }

  playSound (soundId = DEFAULT_SOUND) {
    if (this.sounds[soundId] && soundId !== 'none') {
      console.log('[SoundService] > playSound', soundId)
      let promise = this.sounds[soundId].play()
      if (promise !== undefined) {
        promise.then(() => {
          // Autoplay started!
          console.log('[SoundService] > playSound > autoplay allowed', soundId)
        }).catch(err => {
          // Autoplay was prevented.
          // Show a "Play" button so that user can start playback.
          console.log('[SoundService] > playSound > autoplay blocked', err)
        })
      }
    }
  }

  loadSound (soundId) {
    const fileName = soundId
    console.log('loadSound > ', soundId)
    const audioTag = document.createElement('audio')
    audioTag.style.display = 'none'
    audioTag.id = soundId
    audioTag.src = `/assets/sounds/${fileName}.mp3`
    document.body.appendChild(audioTag)
    audioTag.load()
    this.sounds[soundId] = audioTag
  }

  initSound (soundId = DEFAULT_SOUND) {
    if (soundId === 'default') {
      soundId = DEFAULT_SOUND
    }

    if (!this.sounds[soundId] && soundId !== 'none') {
      this.loadSound(soundId)
    } else {
      console.log('[SoundService] > initSound > ', soundId, 'already initialized')
    }
  }

  initAllUserSounds () {
    if (this.UserService.isExpert() && this.UserService.user.notificationSettings && this.UserService.user.notificationSettings.sounds) {
      const soundsConfig = this.UserService.user.notificationSettings.sounds
      console.log('[SoundService] > initAllUserSounds > user specific', soundsConfig)
      Object.entries(soundsConfig)
        .forEach(([ soundKey, soundName ]) => {
          this.initSound(soundName)
        })
    } else {
      console.log('[SoundService] > initAllUserSounds > default', DEFAULT_SOUND)
      this.initSound(DEFAULT_SOUND)
    }
  }
}
export default SoundService
