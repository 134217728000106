/* eslint-disable */
(function () {
  'use strict';

  function states($stateProvider) {
    $stateProvider.state('tasks', {
      abstract: true,
      url: '/tasks',
      views: {
        '@': {
          template: '<ui-view> </ui-view>'
        }
      }
    })
    .state('tasks.display', {
      url: '/:id?intro&feedback&showContractor&edit&pay&page&payment_completed&payment_provider&sub_task_id&task_id&payment_cancelled',
      reloadOnSearch: false,
      views: {
        '@': {
          templateUrl: require('../../views/tasks/display.html'),
          controller: 'DisplayTaskCtrl'
        }
      },
      resolve: {
        checkIsInitialized: ['AuthService', function (AuthService) {
          console.log('[states] > tasks.display > checkIsInitialized')
          return AuthService.checkIsInitialized()
        }],
        taskResource: ['TaskResource', '$stateParams', '$q', '$state', 'Auth', 'UserService', function (TaskResource, $stateParams, $q, $state, Auth, UserService) {
          const resource = TaskResource.getTask($stateParams.id, ['task', 'estimators', 'vault']);
          resource
          .then(data => {
            console.log('[states] > tasks.display > response', data, $stateParams)
            // TODO: whole thing needs to be reworked as it should not load all resources before showing page, permissions needs to be checked differently
            // Check for expert-hired
            // because of how TaskResource works - if we get 403 resoponse task will be empty except in cases where contractor is preferred by the client then we need to handle normal response
            if (
                (
                  UserService.isExpert() &&
                  data.task &&
                  Object.keys(data.task).length > 0 &&
                  data.task.contractor &&
                  data.task.contractor.id !== UserService.user.id
                ) ||
                (
                  UserService.isExpert() &&
                  (
                    !data.task ||
                    Object.keys(data.task).length === 0
                  )
                )
              ) {
              // reject whole TaskResource promise and redirect to hired expert page
              return $q.reject().catch(() => {
                $state.go('tasks.hiredExpert', { id: $stateParams.id });
                return $q.reject();
              });
            }
          })
          .catch(error => {
            console.log('[states] > tasks.display > error', error, $stateParams)
            $state.go('tasks.hiredExpert', { id: $stateParams.id });
          });

          return resource;
        }]
      },
      onEnter: ['$stateParams', '$state', 'Auth', 'Sockets', 'UserService', 'ProjectService', 'ProjectRemindersService', 'taskResource', function ($stateParams, $state, Auth, Sockets, UserService, ProjectService, ProjectRemindersService, taskResource) {
        console.log('[states] > tasks.display > onEnter')

        // TODO: move to main project compoenent when ready
        // Remove partner in mentioned partners, if it matches project's referral - API SHOULD DO THIS... -
        taskResource.task.mentionedPartners = taskResource.task.mentionedPartners.filter(partner =>
          partner.trackingName !== taskResource.task.partnerName
        )

        ProjectService.setProject(taskResource.task);
        if (UserService.isExpert()) {
          ProjectRemindersService.getReminders();
        }
        // --

        if (UserService.isAuthenticated()) {  // subscribe only if user object exists
          var channelName = UserService.isExpert() ? 'private-contractor-task-' : 'private-task-owner-task-';
          console.log('[states] > tasks.display > onEnter > channelName', channelName)
          Sockets.subscribe(channelName + $stateParams.id, $stateParams.id);
        }

        if (UserService.showFirstProjectPage()) {
          $state.go('tasks.firstproject', $stateParams);
        }


      }],
      onExit: ['$stateParams', 'Auth', 'Sockets', 'ProjectService', 'UserService', function ($stateParams, Auth, Sockets, ProjectService, UserService) {
        if (UserService.isAuthenticated()) { // We need to check if user is signed in, he can logout on this page
          var channelName = UserService.isExpert() ? 'private-contractor-task-' : 'private-task-owner-task-';
          Sockets.unsubscribe(channelName + $stateParams.id, $stateParams.id);
        }

        // TODO: move to main project compoenent when ready
        ProjectService.unsetProject();
        // --
      }]
    })
    .state('tasks.workroom', {
      url: '/:id/workroom?tab&complete&page&payment_completed&payment_provider&sub_task_id&task_id&payment_cancelled',
      views: {
        '@': {
          templateUrl: require('../../views/tasks/workroom.html'),
          controller: 'WorkroomTaskCtrl'
        }
      },
      resolve: {
        checkIsInitialized: ['AuthService', function (AuthService) {
          console.log('[states] > tasks.display > checkIsInitialized')
          return AuthService.checkIsInitialized()
        }],
        taskResource: ['Auth', 'TaskResource', '$state', '$stateParams', '$q', 'UserService', 'ProjectService', function (Auth, TaskResource, $state, $stateParams, $q, UserService, ProjectService) {
          const requests = ['task', 'estimators', 'vault'];
          // For experts also load reviews
          if (UserService.isExpert()) {
            requests.push('reviews');
          }

          var resource = TaskResource.getTask($stateParams.id, requests);

          // console.log('resolve taskResource');
          resource.then(data => {
            // Check for expert-hired
            // because of how TaskResource works - if we get 403 resoponse task will be empty except in cases where contractor is preferred by the client then we need to handle normal response
            if (
              UserService.isExpert() &&
              data.task &&
              Object.keys(data.task).length > 0 &&
              data.task.contractor &&
              data.task.contractor.id !== UserService.user.id
            ) {
              return $q.reject().catch(() => {
                $state.go('tasks.hiredExpert', { id: $stateParams.id });
                return $q.reject();
              });
            }
            if (!ProjectService.isWorkroom(data.task)) {
              $state.go('tasks.display', { id: $stateParams.id });
            }

            if (data.task.contractor && data.task.contractor.id !== UserService.user.id && data.task.client.id !== UserService.user.id) {
              $state.go('tasks.display', {id: $stateParams.id});
            }
          }, err => {
            console.log('ERROR', err);
          });

          return resource;
        }]
      },
      onEnter: ['$stateParams', 'Auth', 'Sockets', 'UserService', 'ProjectService', 'ProjectRemindersService', 'taskResource', function ($stateParams, Auth, Sockets, UserService, ProjectService, ProjectRemindersService, taskResource) {
        // TODO: move to main project compoenent when ready
        ProjectService.setProject(taskResource.task);

        if (UserService.isExpert()) {
          ProjectRemindersService.getReminders();
        }

        // --

        // expert should have its own channnel
        Sockets.subscribe('private-task-owner-task-' + $stateParams.id, $stateParams.id);
        /*
        if (Auth.me() && Auth.me().role === 'contractor') {
          Sockets.subscribe('private-contractor-task-' + $stateParams.id, $stateParams.id);
        } else if (Auth.me() && Auth.me().role === 'client') {
          Sockets.subscribe('private-task-owner-task-' + $stateParams.id, $stateParams.id);
        } */
      }],
      onExit: ['$stateParams', 'Auth', 'Sockets', 'ProjectService', function ($stateParams, Auth, Sockets, ProjectService) {
        // expert should have its own channnel
        Sockets.unsubscribe('private-task-owner-task-' + $stateParams.id, $stateParams.id);
        /*
        if (Auth.me() && Auth.me().role === 'contractor') { // We need to check if user is signed in, he can logout on this page
          Sockets.unsubscribe('private-contractor-task-' + $stateParams.id, $stateParams.id);
        } else if (Auth.me() && Auth.me().role === 'client') {
          Sockets.unsubscribe('private-task-owner-task-' + $stateParams.id, $stateParams.id);
        } */

        // TODO: move to main project compoenent when ready
        ProjectService.unsetProject();
        // --
      }]
    })
    .state('tasks.hiredExpert', {
      url: '/:id/hired-expert',
      views: {
        '@': {
          templateUrl: require('../../views/tasks/hired_expert.html'),
          controller: 'HiredExpertTaskCtrl'
        }
      },
      resolve: {
        checkIsInitialized: ['AuthService', function (AuthService) {
          console.log('[states] > tasks.display > checkIsInitialized')
          return AuthService.checkIsInitialized()
        }],
        RedirectClients: ['$state', '$stateParams', '$q', 'Auth', 'UserService', ($state, $stateParams, $q, Auth, UserService) => {
          if (!UserService.isExpert()) { // redirect for client
            return $q.reject().catch(() => {
              $state.go('dashboard.list');
              return $q.reject();
            });
          }
        }]
      },
      onEnter: ['ProjectService', function (ProjectService) {
        // TODO: provide taskResource for this page
        // TODO: move to main project compoenent when ready
        // ProjectService.setProject(taskResource.task);
        // --
      }],
      onExit: ['ProjectService', function (ProjectService) {
        // TODO: move to main project compoenent when ready
        // ProjectService.unsetProject();
        // --
      }]
    });
  }

  app.config(['$stateProvider', states]);
})();
