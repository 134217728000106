import ExpertProfileService from './expert-profile.service'

import ExpertProfileEditModule from './expert-profile-edit'
import ExpertProfileEditPortfolioItemModule from './expert-profile-edit-portfolio-item'
import ExpertProfilePortfolioEditModalModule from './expert-profile-portfolio-edit-modal'
import ExpertProfileEditReviewItemModule from './expert-profile-edit-review-item'
import ExpertProfileReviewsEditModalModule from './expert-profile-reviews-edit-modal'

import ExpertProfileViewModalModule from './expert-profile-view-modal'
import ExpertProfileVisibilityModal from './expert-profile-visibility-modal'

const ExpertProfileModule = angular.module('cdbl.expert-profile', [
  ExpertProfileEditModule,
  ExpertProfileEditPortfolioItemModule,
  ExpertProfilePortfolioEditModalModule,
  ExpertProfileEditReviewItemModule,
  ExpertProfileReviewsEditModalModule,

  ExpertProfileViewModalModule,
  ExpertProfileVisibilityModal
])
  .service('ExpertProfileService', ExpertProfileService)
  .name

export default ExpertProfileModule
