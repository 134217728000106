(function () {
  'use strict';

  function typeahead(_) {
    let link = function ($scope, elem, attrs) {
      let substringMatcher = function (options) {
        return function (q, cb) {
          var matches;
          var foundQ = false;

          // an array that will be populated with substring matches
          matches = [];

          // iterate through the pool of strings and for any string that
          // contains the substring `q`, add it to the `matches` array
          options.forEach(function (suggestion) {
            let compareTo;
            let alreadySelected = $scope.alreadySelected;

            if ($scope.displayKey === '') {
              compareTo = suggestion;
            } else {
              compareTo = suggestion[$scope.displayKey];
            }

            if (compareTo.toLowerCase().indexOf(q.toLowerCase()) !== -1) {
              // the typeahead jQuery plugin expects suggestions to a
              // JavaScript object, refer to typeahead docs for more info

              if ($scope.alreadySelected && alreadySelected.indexOf(compareTo) === -1) {
                matches.push(suggestion);
              } else if (!$scope.alreadySelected) {
                matches.push(suggestion);
              }
            }

            if (q.toLowerCase() === compareTo.toLowerCase()) {
              foundQ = true;
            }
          });

          if (!foundQ && !$scope.onlyPredefined) {
            var addQ;

            if ($scope.displayKey === '') {
              addQ = q.toLowerCase();
            } else {
              addQ = {};
              addQ[$scope.displayKey] = q.toLowerCase();
            }

            matches.push(addQ);
          }

          if ($scope.displayKey === '') {
            matches = matches.map(function (match) {
              return {
                value: match
              };
            });
          }

          cb(matches);
        };
      };

      if ('localSource' in attrs) {
        init($scope.suggestionSource);
      } else {
        $scope.suggestionSource.then(function (data) {
          init(data);
        });
      }

      function init(data) {
        elem.typeahead({
          hint: false,
          highlight: true,
          minLength: 0
        }, {
          displayKey: $scope.displayKey === '' ? 'value' : $scope.displayKey,
          source: substringMatcher(data)
        });
      }

      var selectedSuggestion;

      function addToList(selected) {
        if ($scope.returnKey) {
          var callArray = {};
          callArray[$scope.returnKey] = selected;

          $scope.add(callArray);
        } else {
          $scope.add(selected);
        }

        elem.typeahead('val', '');

        selectedSuggestion = null;
      }

      function openDropdown() {
        if ($scope.showOnClick) {
          var event = $.Event('keydown');
          event.keyCode = event.which = 40;

          elem.trigger(event);
        }
      }

      elem.on('typeahead:autocompleted', function (event, selected) {
        selectedSuggestion = selected;
      });

      elem.on('typeahead:selected', function (event, selected) {
        addToList(selected);

        setTimeout(function () {
          openDropdown();
        }, 50);
      });

      elem.keypress(function (event) {
        if (event.which === 13) {
          event.preventDefault();

          if (selectedSuggestion) {
            addToList(selectedSuggestion);
          }
        }
      });

      elem.on('click', function () {
        openDropdown();
      });
    };

    return {
      restrict: 'A',
      replace: false,
      scope: {
        add: '&',
        returnKey: '@',
        suggestionSource: '=',
        onlyPredefined: '=',
        alreadySelected: '=',
        displayKey: '@',
        showOnClick: '='
      },
      link: link
    };
  }

  app.directive('typeahead', ['_', typeahead]);
})();
