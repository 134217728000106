(function () {
  'use strict';

  function truncate() {
    return function (text, maxLength) {
      return text.truncate(maxLength, true);
    };
  }

  app.filter('truncate', truncate);
})();
