const DateDifferenceFilter = function (moment) {
  'ngInject'
  return function (date, dateToCompare = moment(), format = 'days') {
    if (date) {
      const start = moment(date)
      const now = dateToCompare === 'now' ? moment() : moment(dateToCompare)

      return now.diff(start, format)
    }

    return 0
  }
}
export default DateDifferenceFilter
