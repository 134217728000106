import templateUrl from './field-container.html'
import './field-container.scss'

const FieldContainerComponent = {
  bindings: {},
  templateUrl,
  require: {
    formCtrl: '^?form'
  },
  transclude: {
    label: '?fieldLabel',
    description: '?fieldDescription',
    helper: '?fieldHelper',
    validation: '?fieldValidation'
  },
  controller: class FieldContainerComponent {
    constructor ($element, $timeout) {
      'ngInject'
      this._identify = 'FieldContainerComponent'
      this.$element = $element
      this.$timeout = $timeout
    }

    $onInit () {
      this._fieldElementCtrl = null
      this.classList = {}
    }

    $postLink () {
      // Wait until end of digest to find all children
      this.$timeout(() => {
        const elContainer = this.$element[0].querySelector('.field__form-element')
        const elFormElement = elContainer.querySelector('[ng-model]')
        if (elFormElement && elFormElement.nodeName) {
          // console.log('[FieldContainerComponent] > formElement', elFormElement.nodeName)
          elContainer.classList.add(`field__${elFormElement.nodeName.toLowerCase()}`)
        }
      })
    }

    get isDisabled () {
      if (this._fieldElementCtrl && this._fieldElementCtrl.ngModel) {
        return this._fieldElementCtrl.$attrs.disabled
      }
      return false
    }

    get hasError () {
      if (this._fieldElementCtrl && this._fieldElementCtrl.ngModel) {
        const ngModel = this._fieldElementCtrl.ngModel
        return ngModel.$invalid && (ngModel.$touched || ngModel.$dirty)
      }

      return false
    }

    setFieldElementCtrl (fieldElementCtrl) {
      // console.log('[FieldContainerComponent] > setFieldElementCtrl', fieldElementCtrl)
      this._fieldElementCtrl = fieldElementCtrl
    }
  }
}

export default FieldContainerComponent
