(function () {
  'use strict';

  function taskLink() {
    return {
      restrict: 'E',
      scope: {
        task: '=',
        disabled: '=',
        subTask: '='
      },
      templateUrl: require('../../views/directives/task_link.html')
    };
  }

  app.directive('taskLink', taskLink);
})();
