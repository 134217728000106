(function () {
  'use strict';

  /*
   * THIS NEEDS REWRITE THE MOST
   *  - doesn't handle 403 responses
   *  - uses Restangular
   * Get ride of this when state resolver gets refactored.
   */
  function TaskResource($http, $q, Restangular, ResourceManipulation, _, Configuration) {
    var local = {}; // Local namespace

    var getTask = function (id, options) {
      var task = Restangular.one('tasks', id);
      var returnObject = {};
      options.forEach(function (option) {
        var resource = local['get' + option.capitaliseFirstLetter()].call(this, task); // call method with same name as option parameter
        returnObject[option] = ResourceManipulation.resolveError(resource);
      });

      return $q.all(returnObject);
    };

    local.getTask = function (task) {
      return task.get();
    };

    var items = [
      {item: 'comments', name: 'getComments', list: true},
      {item: 'attachments', name: 'getAttachments', list: true},
      {item: 'estimators', name: 'getEstimators', list: true},
      {item: 'participants', name: 'getParticipants', list: true},
      {item: 'vault', name: 'getVault', list: false},
      {item: 'reviews', name: 'getReviews', list: true}
      // {item: 'subscription', name: 'getSubscription', list: false}
    ];

    items.forEach(function (obj) {
      local[obj.name] = function (task) {
        if (obj.params) {
          return task.all(obj.item, obj.params).get('');
        } else if (obj.list) {
          return task.all(obj.item).getList();
        }
        return task.all(obj.item).get('');
      };
    });

    // Retainer  -----
    const createRetainer = function (data, task) {
      console.log('createRetainer', data);

      let deferred = $q.defer();
      let payload = {
        retainer: {
          title: data.title,
          description: data.description,
          price: data.price,
          task_id: task.id
        }
      };

      $http
        .post(`${Configuration.apiUrl}/tasks/${task.id}/retainers`, payload)
        .then(function (response) {
          deferred.resolve(response.data);
        })
        .catch(function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    };

    const cancelRetainer = function (retainerSubTask) {
      // console.log('cancelRetainer', retainerSubTask);

      let deferred = $q.defer();

      $http
        .delete(`${Configuration.apiUrl}/retainers/${retainerSubTask.id}/payments`)
        .then(function (response) {
          // console.log('cancelRetainer -> response', response);
          deferred.resolve(response.data);
        })
        .catch(function (err) {
          deferred.reject(err);
        });
      return deferred.promise;
    };

    // @TODO: remove when all is working; moved this to PaymentSrv
    // const updateRetainerPayInfo = function (retainerSubTask) {
    //   console.log('updateRetainerPayInfo', retainerSubTask);

    //   let deferred = $q.defer();

    //   $http
    //     .put(`${Configuration.apiUrl}/retainers/${retainerSubTask.id}/payments`)
    //     .then(function (response) {
    //       deferred.resolve(response);
    //     })
    //     .catch(function (response) {
    //       deferred.reject(response);
    //     });
    //   return deferred.promise;
    // };
    // // --

    return {
      getTask: getTask,
      createRetainer: createRetainer,
      cancelRetainer: cancelRetainer
      // updateRetainerPayInfo: updateRetainerPayInfo
    };
  }

  app.service('TaskResource', ['$http', '$q', 'Restangular', 'ResourceManipulation', '_', 'Configuration', TaskResource]);
})();
