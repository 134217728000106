import templateUrl from './circle-select.html'
import './circle-select.scss'

// This component can be used for display only (value binding) or be also interactive form element (ng-model binding is necessary)

const CircleSelectComponent = {
  bindings: {
    value: '<?'
  },
  require: {
    model: '?^ngModel'
  },
  templateUrl,
  controller: class CircleSelectComponent {
    constructor (EventEmitter) {
      'ngInject'
      this._identify = 'CircleSelectComponent'
      this.EventEmitter = EventEmitter

      this.isEditable = false
      this.circles = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}]
      this.labels = {
        min: 'Not at all likely',
        max: 'Extremely likely'
      }
    }

    $onInit () {
      if (this.model) {
        this.isEditable = true
        this.model.$overrideModelOptions({
          allowInvalid: true,
          getterSetter: true
        })
        this.model.$render = () => {
          // Do stuff on model change (could be external change)
          // console.log('$render > modelValue', this.modelValue)
          this.markCircle(this.modelValue - 1)
        }
      } else {
        this.markCircle(this.value - 1)
      }
    }

    // Update binding change from parent if initial value biniding is present
    $onChanges (changes) {
      // console.log('$onChanges', changes)
      if (changes.value) {
        this.markCircle(changes.value.currentValue - 1)
      }
    }

    onSelectCircle ($index) {
      if (!this.isEditable) {
        return // Handle non editable scenario
      }

      this.modelValue = $index + 1
      this.markCircle($index)

      // Emit value change to parent
      if (this.select) {
        this.select(this.EventEmitter(this.modelValue))
      }
    }

    markCircle ($index) {
      this.circles.forEach((circle, index) => {
        if ($index === index) {
          circle.active = true
        } else {
          circle.active = false
        }
      })
    }

    get modelValue () {
      if (this.model) {
        return this.model.$viewValue
      }
    }

    set modelValue (newValue) {
      if (this.model) {
        this.model.$setViewValue(
          newValue,
          'click'
        )
        this.model.$setDirty()
        this.model.$setTouched()
        this.model.$processModelValue()
      }
    }
  }
}

export default CircleSelectComponent
