(function () {
  'use strict';

  function TaskBudgetCtrl ($scope, Configuration, $filter, CommentService, SegmentAnalytics, $http) {
    console.log('[TaskBudgetCtrl]', $scope)

    $scope.budgetRanges = Configuration.features.submission.budgetRanges;
    $scope.budgetCustom = Configuration.features.submission.budgetCustom;
    $scope.init = function () {
      $scope.initBudget = $scope.$ctrl.project.budget;
      $scope.exactBudget = $scope.$ctrl.project.budget > 0 ? $scope.$ctrl.project.budget : null;
    };
    $scope.taskbudgetctrlBudget = $scope.$ctrl.project.budget;

    const originalBudget = $scope.$ctrl.project.budget;
    $scope.$watch('taskbudgetctrlBudget', function (budget) {
      if (budget > 0) {
        $scope.exactBudget = '';
        $('#exact-budget').focus();
      } else if (budget < 0) {
        $scope.exactBudget = null;
        // console.log($scope.form);
      }
    });

    $scope.setNewBudget = function (newBudget) {
      $scope.taskbudgetctrlBudget = newBudget;
      console.log('setNewBudget', newBudget, $scope.taskbudgetctrlBudget);
    };

    $scope.postEditBudget = function (budget, exactBudget) {
      $scope.processing = true;
      $scope.spinnerText = 'Please wait...';
      console.log('postEditBudget > $scope.$ctrl.project.budget 1', $scope.$ctrl.project.budget, budget);
      if (exactBudget) {
        var cleaned = exactBudget.toString().replace(/[^0-9.]/g, '');
        budget = parseInt(cleaned, 10);
      }
      console.log('postEditBudget > $scope.$ctrl.project.budget 2', $scope.$ctrl.project.budget, budget);

      $http
        .put(`${Configuration.apiUrl}/tasks/${$scope.$ctrl.project.id}/budget`, { budget: budget })
        .then(function (response) {
          if (!response) return
          var task = response.data
          console.log('postEditBudget > $scope.task.budget 3', $scope.$ctrl.project.budget, budget)

          $scope.editBudget = false
          $scope.initBudget = task.budget
          $scope.exactBudget = task.budget > 0 ? task.budget : null
          $scope.hide()

          $scope.$ctrl.project.budget = budget

          SegmentAnalytics.trackTaskBudgetChanged(originalBudget, budget)

          // POST COMMENT to notify users about change
          CommentService.postComment(task.id, `I've just updated the budget to ${$filter('budgetDisplay')(budget)}`).then(resolve => {
            console.log('postEditBudget > postComment > success', resolve)
          }, error => {
            console.log('postEditBudget > postComment > error', error)
          })
        })
        .catch(function (err) {
          console.log(err)
        })
        .finally(function () {
          $scope.processing = false
          $scope.spinnerText = ''
        })
    };

    $scope.cancel = function () {
      $scope.$ctrl.project.budget = $scope.initBudget;
      $scope.exactBudget = $scope.$ctrl.project.budget > 0 ? $scope.$ctrl.project.budget : null;
      $scope.hide();
    };
  }

  app.controller('TaskBudgetCtrl', ['$scope', 'Configuration', '$filter', 'CommentService', 'SegmentAnalytics', '$http', TaskBudgetCtrl]);
})();
