(function () {
  'use strict';

  function tooltip($compile) {
    var link = function ($scope, element, attrs) {
      if (!$scope.text) {
        return;
      }

      var content = angular.element('<div style="' + attrs.textStyle + '" ng-bind-html="text | trustAsHtml"></div>');
      $compile(content)($scope);

      var opts = {
        title: content[0],
        html: true,
        trigger: 'hover',
        sanitize: false
      };

      if (!('dataPlacement' in attrs)) {
        opts.placement = 'bottom';
      }

      // console.log('tooltip', element, opts, content[0]);
      element.tooltip(opts);
    };

    return {
      restrict: 'A',
      scope: {
        text: '@'
      },
      link: link
    };
  }

  app.directive('tooltip', ['$compile', tooltip]);
})();
