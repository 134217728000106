import NotificationsWidgetComponent from './notifications-widget.component'

import NotificationsWidgetPopoverModule from './notifications-popover'

const NotificationsWidgetModule = angular.module('cdbl.notifications.notifications-widget-popover', [
  NotificationsWidgetPopoverModule
])
  .component('cdblNotificationsWidget', NotificationsWidgetComponent)
  .name

export default NotificationsWidgetModule
