const OnFileChangeDirective = function () {
  'ngInject'
  return {
    restrict: 'A',
    scope: {
      onFileChange: '&'
    },
    controllerAs: '$ctrl',
    bindToController: true,
    controller: class OnFileChangeDirectiveController {
      constructor ($element, $timeout, EventEmitter) {
        'ngInject'
        this.$element = $element
        this.$timeout = $timeout
        this.EventEmitter = EventEmitter
      }

      $onInit () {
        const vm = this
        this.$element.bind('change', () => {
          vm.$timeout(() => {
            const files = this.$element[0].files
            if (files) {
              vm.onFileChange(this.EventEmitter({ files }))
            }
          })
        })
      }
    },
    // link: (scope, element, attrs, ctrl) => {

    // }
  }
}

export default OnFileChangeDirective
