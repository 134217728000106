import templateUrl from './client-profile-view-modal.html'
import './client-profile-view-modal.scss'

import profileSkeletonTemplate from './profile.skeleton.html'
import reviewsSkeletonTemplate from './reviews.skeleton.html'

const ClientProfileViewModalComponent = {
  bindings: {
    project: '<?'
  },
  templateUrl,
  require: {
    modalCtrl: '^^cdblModal'
  },
  controller: class ClientProfileViewModalComponent {
    constructor ($timeout, PusherService, PaginationService, ClientProfileService, UtilService) {
      'ngInject'
      this._identify = 'ClientProfileViewModalComponent'
      this.$timeout = $timeout
      this.PusherService = PusherService
      this.PaginationService = PaginationService
      this.ClientProfileService = ClientProfileService
      this.UtilService = UtilService
    }

    async $onInit () {
      this.isLoading = true
      this.isLoadingReviews = true
      this.skeletonTemplates = {
        profile: profileSkeletonTemplate,
        reviews: reviewsSkeletonTemplate
      }
      this.reviewsPagination = this.PaginationService.init({
        itemsPerPage: 10,
        mode: 'server'
      })

      await this.loadProfile()
      await this.loadReviews()
    }


    get isClientOnline () {
      if (this.profile) {
        return this.PusherService.isUserOnline(this.profile.clientId)
      }
      return false
    }

    get clientRating () {
      if (this.profile) {
        return this.UtilService.round(this.profile.stats.averageRating.value, 1)
      }
      return null
    }

    async loadProfile () {
      this.isLoading = true
      await this.ClientProfileService.getProfile(this.project)
        .then(response => {
          console.log('ClientProfileService > response', response)
          this.profile = response
        })
        .catch(err => {
          console.log(err)
        })
      this.isLoading = false
      console.log('ClientProfileService > getProfile > isLoading', this.isLoading)
    }

    async loadReviews () {
      this.isLoadingReviews = true
      await this.ClientProfileService.getProfileReviews(this.profile, this.reviewsPagination)
        .then(response => {
          console.log('ClientProfileService > getProfileReviews > response', response)
          this.reviews = response
        })
        .catch(err => {
          console.log(err)
        })
      this.isLoadingReviews = false
      console.log('ClientProfileService > getProfileReviews > isLoadingReviews', this.isLoadingReviews, this.reviews)
      this.$timeout() // trigger change detection, this is ugly hack however, look into decorators to make async/await work better in angularjs
    }

    onReviewsPageChangeHandler (page) {
      this.loadReviews() // pagination is handeled in service
    }
  }
}

export default ClientProfileViewModalComponent
