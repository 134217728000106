import templateUrl from './earnings-certificate-modal.html'
import './earnings-certificate-modal.scss'

const EarningsCertificateModalComponent = {
  bindings: {
  },
  templateUrl,
  require: {
    modalCtrl: '^^cdblModal'
  },
  controller: class EarningsCertificateModal {
    constructor (EarningCertificateService, $http, Configuration, PaginationService, EventBusService) {
      'ngInject'
      this.EarningCertificateService = EarningCertificateService
      this.$http = $http
      this.Configuration = Configuration
      this.range = null
      this.canGenerate = false
      this.hasPending = false
      this.PaginationService = PaginationService
      this.startDate = null
      this.endDate = null
      this.error = null
      this.EventBusService = EventBusService

      EventBusService.channel('legacy').subscribe('experts-earnings-certificate-issued', () => {
        this.getCertificates()
      })
    }

    $onInit () {
      this.initPagination()
      this.getCertificates()
    }

    callbackOnChange () {
      this.error = null
    }

    generateCertificate () {
      if (this.range !== 'custom') {
        this.startDate = null
        this.endDate = null
      }

      this.error = null

      const payload = {
        'period': this.range,
        ...(this.startDate && {
          start_date: this.processDateTimezoneOffset(this.startDate, false),
          end_date: this.processDateTimezoneOffset(this.endDate, true)
        }),
      }
      this.EarningCertificateService.generateEarningCertificate(payload).then(response => {
        this.getCertificates()
      }).catch(err => {
        if (err.data.errors[0].message === 'must be before `current date`') {
          this.error = 'End date has to be before current date.'
        }

        if (err.data.errors[0].message === 'must be before `end_date`') {
          this.error = 'Start date has to be before end date.'
        }
      })
    }

    initPagination () {
      this.pagination = this.PaginationService.init({
        itemsPerPage: 5,
        mode: 'server'
      })
    }

    getCertificates () {
      const params = {
        page: this.pagination.currentPage,
        per_page: this.pagination.itemsPerPage
      }

      this.$http
        .get(`${this.Configuration.apiUrl}/experts/earnings_certificates?`, { params })
        .then(response => {
          this.pagination.updateFromHeaders(response)
          this.certificates = response.data.certificates
          this.canGenerate = response.data.actions?.method === 'POST'
          this.hasPending = this.certificates.find(cert => !cert.certificatePdfUrl)
        })
    }

    processDateTimezoneOffset (dateString, endDate) {
      const date = new Date(dateString)
      if (endDate) {
        date.setMonth(date.getMonth() + 1, 0)
      }
      const offset = date.getTimezoneOffset() * 60000 //
      return new Date(date.getTime() - offset).toISOString()
    }
  },

  $onDestroy () {
    this.EventBusService.channel('legacy').unsubscribe('experts-earnings-certificate-issued')
  }
}

export default EarningsCertificateModalComponent
