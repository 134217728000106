import templateUrl from './project-navigation.html'
import './project-navigation.scss'

// TODO: add sref objects to parent link items to make template more clean
const PROJECT_LISTS_CLIENT = [
  {
    id: 'default',
    title: 'All',
    showCount: false
  },
  {
    id: 'in-progress',
    title: 'In progress',
    showCount: true
  },
  {
    id: 'pending',
    title: 'Pending',
    showCount: true
  },
  {
    id: 'archived',
    title: 'Archived',
    showCount: true
  },
  {
    id: 'favourites',
    title: 'Favourites',
    showCount: true,
    icon: 'icon-fav-selected'
  }
]

const PROJECT_LISTS_EXPERT = [
  {
    id: 'default',
    title: 'In progress',
    showCount: false
  },
  {
    id: 'pending',
    title: 'Pending',
    showCount: false,
    menu: [
      {
        id: 'pending',
        title: 'All',
        sref: {
          listId: 'pending'
        }
      },
      {
        id: 'pending',
        title: 'Commented',
        sref: {
          listId: 'pending',
          commented: true
        }
      },
      {
        id: 'pending',
        title: 'Estimated',
        sref: {
          listId: 'pending',
          estimated: true
        }
      }
    ]
  },
  {
    id: 'archived',
    title: 'Archived',
    showCount: false
  },
  {
    id: 'favourites',
    title: 'Favourites',
    showCount: true,
    icon: 'icon-fav-selected'
  }
]

const ProjectNavigationComponent = {
  bindings: {},
  templateUrl,
  controller: class ProjectNavigation {
    constructor ($stateParams, UserService, ProjectDashboardService) {
      'ngInject'
      this._identify = 'ProjectNavigationComponent'
      this.$stateParams = $stateParams
      this.UserService = UserService
      this.ProjectDashboardService = ProjectDashboardService
    }

    $onInit () {
      if (this.UserService.isClient()) {
        this.categoryList = PROJECT_LISTS_CLIENT
      } else {
        this.categoryList = PROJECT_LISTS_EXPERT
      }

      console.log('[ProjectNavigationComponent] > init')

      this.ProjectDashboardService.getProjectStats()
        .then(response => {
          this.projectStats = response
        })
    }

    isActiveSref (sref = {}) {
      // Compare input sref object with actual state params and return if all params matches == stricter than default ui-sref matching so child links are matched more precise
      // Supports only primitive parameter values
      let active = true

      Object.entries(this.$stateParams).forEach(entry => {
        const paramName = entry[0]
        const paramValue = entry[1]

        if (typeof sref[paramName] === 'undefined' && typeof paramValue === 'undefined') {
          // both are undefined - sref still matches url
        } else if (typeof sref[paramName] === 'undefined' || sref[paramName].toString() !== paramValue) {
          active = false
        }
      })

      return active
    }
  }
}

export default ProjectNavigationComponent
