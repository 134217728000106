import templateUrl from './no-results.html'
import './no-results.scss'

const NoResultsComponent = {
  bindings: {

  },
  transclude: true,
  templateUrl,
  // controller: class NoResultsComponent {
  //   constructor () {
  //     'ngInject';
  //     this._identify = 'NoResultsComponent'
  //   }

  //   $onInit () {

  //   }

  // }
}

export default NoResultsComponent
