const SleekService = class SleekService {
  constructor ($window, UserService) {
    'ngInject'
    this.$window = $window
    this.UserService = UserService
  }

  openFeedback () {
    const user = this.UserService.user

    if (user) {
      this.$window.$sleek.setUser({
        mail: user.email,
        id: user.id,
        name: user.fullName,
        img: user.avatar.mediumUrl,
        weight: 10
      })
      this.$window.$sleek.open()
    }
  }

  destroy () {
    try {
      this.$window.$sleek && this.$window.$sleek.resetUser()
    } catch (e) {
      console.error('The sleek error', e)
    }
  }
}
export default SleekService
