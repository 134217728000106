(function () {
  'use strict';

  function autoGrow() {
    var link = function ($scope, element) {
      var minHeight;
      var $shadow = null;

      function createShadow() {
        minHeight = element.outerHeight();

        if (minHeight === 0) {
          return;
        }

        var paddingLeft = element.css('paddingLeft');
        var paddingRight = element.css('paddingRight');

        $shadow = angular.element('<div></div>').css({
          position: 'absolute',
          top: -10000,
          left: -10000,
          width: element[0].offsetWidth - parseInt(paddingLeft ? paddingLeft : 0, 10) - parseInt(paddingRight ? paddingRight : 0, 10),
          fontSize: element.css('fontSize'),
          fontFamily: element.css('fontFamily'),
          lineHeight: element.css('lineHeight'),
          resize: 'none'
        });

        angular.element(document.body).append($shadow);
      }

      var update = function () {
        if ($shadow === null) {
          createShadow();
        }

        if ($shadow === null) {
          return;
        }

        var times = function (string, number) {
          let r = '';
          for (var i = 0; i < number; i++) {
            r += string;
          }

          return r;
        };

        var val = element.val().replace(/</g, '&lt;')
          .replace(/>/g, '&gt;')
          .replace(/&/g, '&amp;')
          .replace(/\n$/, '<br/>&nbsp;')
          .replace(/\n/g, '<br/>')
          .replace(/\s{2,}/g, function (space) {
            return times('&nbsp;', space.length - 1) + ' ';
          });
        $shadow.html(val);

        var height = Math.max($shadow.outerHeight() + 30, minHeight);

        if (height < 200) {
          element.css('height', height + 'px');

          var elementHeight = angular.element('#commentsForm').outerHeight();
          var marginTop = parseInt(angular.element('#commentsForm').css('margin-top'), 10);

          $scope.$broadcast('commentsFormDimensions', { // we need to replace overflow height of comments box
            height: elementHeight,
            marginTop: marginTop
          });
        }
      };

      element.bind('keyup keydown keypress change focus', update);
    };

    return {
      restrict: 'A',
      link: link
    };
  }

  app.directive('autoGrow', autoGrow);
})();
