(function () {
  'use strict';

  function renderError() {
    var link = function ($scope, element, attrs) {
      var form = attrs.key.split('.')[0];
      var field = attrs.key.split('.')[1];

      $scope.break = attrs.break === 'true';

      if (attrs.error === 'server') {
        $scope.$parent.$watch(attrs.key + '.$error.message', function (value) {
          $scope.message = value;
        }, false);
      } else {
        $scope.message = attrs.message;
      }

      $scope.$parent.$watchCollection(attrs.key, function (value) {
        if (!$scope.$parent[form][field]) { // when we load resource
          return;
        }

        $scope.display = false;

        if (value.$error[attrs.error] && value.$dirty && value.$invalid) {
          if (value.$submitValue === value.$viewValue) { // don't display this error again when user starts typing
            $scope.display = true;
          } else if (attrs.error === 'server' || attrs.error === 'paymill' || 'resetPost' in attrs) { // when input value changes, set error from server as valid, so we can get to another POST
            value.$setValidity(attrs.error, true);
          }
        }
      });
    };

    return {
      restrict: 'E',
      replace: true,
      scope: {},
      templateUrl: require('../../views/directives/render_error.html'),
      link: link
    };
  }

  app.directive('renderError', renderError);
})();
