import AttachmentsService from './../attachments/attachments.service'
import CouponService from './../../common/services/coupon.service'
import SubmissionComponent from './submission.component'
import SubmissionService from './submission.service'

import SubmissionStepOneModule from './submission-step-one'
import SubmissionStepOneVariantModule from './submission-step-one-variant'
import SubmissionStepTwoModule from './submission-step-two'
import SubmissionFirstprojectModule from './submission-firstproject'
import SubmissionAuthModule from './submission-auth'

const SubmissionModule = angular.module('cdbl.submission', [
  SubmissionStepOneModule,
  SubmissionStepOneVariantModule,
  SubmissionStepTwoModule,
  SubmissionFirstprojectModule,
  SubmissionAuthModule
])
  .service('SubmissionService', SubmissionService)
  .service('AttachmentsService', AttachmentsService)
  .service('CouponService', CouponService)
  .component('cdblSubmission', SubmissionComponent)
  .config($stateProvider => {
    'ngInject'

    // See docs https://github.com/codeablehq/docs/blob/master/codeable-frontend.md#submission-form-url-supported-parameters
    const queryParams = [
      'draft',

      'ref',
      'coupon_code',
      'utm_campaign',
      'utm_medium',
      'utm_source',

      'type',
      'subject',
      'usePreset',

      'complexity',
      'urgency',

      'title',
      'prefix',
      'affix',
      'desc',
      'preferredExpert',
      'team',
      'exactBudget',

      'project_type',         // support for older versions, gets remapped to type
      'project_subject',      // support for older versions, gets remapped to subject
      'preferredContractor',  // support for older versions, gets remapped to preferredExpert
    ]
    const queryParamsString = queryParams.join('&')

    // console.log('[SubmissionModule] > queryParams', queryParams, queryParamsString)

    $stateProvider
      .state('tasks.create', {
        url: `/new?${queryParamsString}`,
        reloadOnSearch: false,
        template: '<cdbl-submission></cdbl-submission>',
        onEnter: (UserService, $state) => {
          'ngInject'
          if (UserService.isAuthenticated() && UserService.isExpert()) {
            console.log('SubmissionModule > onEnter > expert >> redirect')
            $state.go('dashboard.list')
          }
        },
      })
  })
  .name

export default SubmissionModule
