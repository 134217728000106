(function () {
  'use strict';

  function objectToString() {
    return function (object, delimiter) {
      delimiter = delimiter || ',';
      var outputString = '';

      for (var element in object) {
        if (element) {
          outputString += object[element] + delimiter + ' ';
        }
      }

      return outputString.substring(0, outputString.length - 2);
    };
  }

  app.filter('objectToString', objectToString);
})();
