const unsignedOnlyDirective = function () {
  const keyDownHandler = $event => {
    // console.log('unsignedOnly > keyDownHandler', $event);
    // prevent unsigned
    if ($event.key === '+' || $event.keyCode === 187 || $event.key === '-' || $event.keyCode === 189) {
      $event.preventDefault()
      $event.stopPropagation()
    }
  }

  return {
    require: 'ngModel',
    restrict: 'A',
    link: function (scope, element) {
      console.log(element)

      element.on('keydown', keyDownHandler)
      scope.$on('destroy', () => {
        element.off('keydown', keyDownHandler)
      })
    }
  }
}

export default unsignedOnlyDirective
