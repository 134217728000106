import './password-reveal.directive.scss'

const passwordRevealDirective = function ($compile) {
  'ngInject'
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      if (attrs.type === 'password') {
        const template = `
          <cdbl-icon icon="icon" role="img" class="password-reveal-icon" ng-click="toggleReveal()"></cdbl-icon>
        `
        // TODO: when tech debt + make it work with field containers
        // const template = `
        //   <span class="icon icon-interactable" ng-class="{'icon-eye-closed-default': !isVisible, 'icon-eye-open-default': isVisible, }" ng-click="toggleReveal()"></span>
        // `
        const iconEl = $compile(template)(scope)
        element.parent().append(iconEl)

        scope.icon = 'show'
        scope.isVisible = false
        scope.toggleReveal = () => {
          scope.isVisible = !scope.isVisible
          if (element.attr('type') === 'password') {
            element.attr('type', 'text')
            scope.icon = 'hide'
          } else {
            element.attr('type', 'password')
            scope.icon = 'show'
          }
        }
      }
    }
  }
}

export default passwordRevealDirective
