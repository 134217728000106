import templateUrl from './team-details-edit-modal.html'
import './team-details-edit-modal.scss'

const SettingsExpertTeamDetailsEditModalComponent = {
  bindings: {
    team: '<',
    onSave: '&'
  },
  templateUrl,
  require: {
    modalCtrl: '^^cdblModal'
  },
  controller: class SettingsExpertTeamDetailsEditModalComponent {
    constructor (SettingsExpertTeamService, EventEmitter) {
      'ngInject'
      this._identify = 'SettingsExpertTeamDetailsEditModalComponent'
      this.SettingsExpertTeamService = SettingsExpertTeamService
      this.EventEmitter = EventEmitter
    }

    $onInit () {
      this.isProcessing = false
      this.serverError = null

      this.formData = this.team && this.team.details ? {
        legalEntityName: this.team.details.legalEntityName,
        teamDisplayName: this.team.details.teamDisplayName,
        location: this.team.details.location,
        teamSize: this.team.details.teamSize,
        yearFounded: this.team.details.yearFounded,
      } : {}

      // Generate values for dropdown
      const startYear = 1970
      const currentYear = new Date().getUTCFullYear()
      this.yearFoundedList = Array.from(Array(currentYear - startYear + 1), (element, index) => currentYear - index)
      this.fileSelectorConfig = {
        preview: true, // only for images
        previewWidth: 200,
        previewHeight: 150,
        showRemoveIcon: true,
        accept: 'image/png, image/jpeg',
        maxSize: 2 * 1024 * 1e+3, // max 2mb,
      }
    }

    get canShowPreviouslyUploadedImage () {
      if (this.formData.logo && this.formData.logo.length > 0) {
        return false
      } else if (this.team && this.team.details && this.team.details.logo) {
        return true
      }

      return false
    }

    removeExistingImage () {
      this.team.details.logo = null
      this.formData.logo = null
    }

    saveTeamDetails () {
      this.serverError = null
      this.isProcessing = true
      this.SettingsExpertTeamService.updateExpertTeamDetails(this.team, this.formData)
        .then(response => {
          console.log('response', response)
          if (this.onSave) {
            this.onSave(this.EventEmitter({ details: response }))
            this.modalCtrl.close()
          }
        })
        .catch(err => {
          this.serverError = err
          console.log('err', err)
        })
        .finally(() => {
          this.isProcessing = false
        })
    }
    cancelEdit () {
      this.modalCtrl.close()
    }
  }
}

export default SettingsExpertTeamDetailsEditModalComponent
