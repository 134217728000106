const ProjectQualityReviewService = class ProjectQualityReviewService {
  constructor ($http, $location, $stateParams, $httpParamSerializer, $filter, Configuration, UserService, PaginationService, UIService) {
    'ngInject'
    this.$http = $http
    this.$location = $location
    this.$stateParams = $stateParams
    this.$httpParamSerializer = $httpParamSerializer
    this.$filter = $filter
    this.Configuration = Configuration
    this.UserService = UserService
    this.PaginationService = PaginationService
  }

  claimForReview (method, url, payload) {
    return this.$http({
      url: `${this.Configuration.apiUrl}${url}`,
      method: method,
      data: payload,
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => response.data)
  }
}
export default ProjectQualityReviewService
