import moment from 'moment'
import templateUrl from './expert-profile-portfolio.html'
import './expert-profile-portfolio.scss'

const ExpertProfilePortfolioComponent = {
  bindings: {},
  templateUrl,
  controller: class ExpertProfilePortfolioComponent {
    constructor (ExpertProfileViewModalService, PaginationService, UIService) {
      'ngInject'
      this._identify = 'ExpertProfilePortfolioComponent'
      this.ExpertProfileViewModalService = ExpertProfileViewModalService
      this.isLoading = false
      this.UIService = UIService
      this.isMobile = this.UIService.isMobile
      this.PaginationService = PaginationService

      this.pagination = this.PaginationService.init({
        itemsPerPage: this.UIService.isMobile ? 1 : 4,
        mode: 'array',
        paginationArray: this.portfolio,
      })
    }

    showProfileItems () {
      return !this.loading &&
              this.portfolio &&
              this.portfolio.length === 0
    }

    formatDate (date) {
      return moment(date).format('MMM DD, YYYY')
    }

    onPageChangeHandler (page) {
      const data = this.portfolioOrigin.slice(
        page * this.pagination.itemsPerPage,
        page * this.pagination.itemsPerPage + this.pagination.itemsPerPage
      )
      this.pagination.updatePaginationArray(this.portfolioOrigin)
      this.portfolio = data
    }

    $onInit () {
      this.portfolioOrigin = this.ExpertProfileViewModalService.portfolioResponse.data.items
      this.initPortfolio(this.ExpertProfileViewModalService.portfolioResponse.data)
    }

    initPortfolio () {
      this.pagination.updatePaginationArray(this.portfolioOrigin)
      this.portfolio = this.portfolioOrigin.slice(0, this.pagination.itemsPerPage)
    }

    $doCheck () {
      if (this.UIService.isMobile !== this.isMobile) {
        this.isMobile = this.UIService.isMobile
        this.pagination = this.PaginationService.init({
          itemsPerPage: this.UIService.isMobile ? 1 : 4,
          mode: 'array',
          paginationArray: this.portfolioOrigin,
        })
        this.initPortfolio(this.portfolioOrigin)
      }
    }
  }
}

export default ExpertProfilePortfolioComponent
