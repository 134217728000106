(function () {
  'use strict';

  function taskState() {
    return function (taskState, lastEvent) {
      var taskStateNewValue = '';

      if (!lastEvent) { // if last_event is null means that expert did not get hired
        if (taskState === 'paid' || taskState === 'completed' || taskState === 'hired') {
          taskStateNewValue = 'not hired';
        } else {
          taskStateNewValue = taskState;
        }
      } else if (taskState === 'paid') {
        taskStateNewValue = 'in progress';
      } else {
        taskStateNewValue = taskState;
      }

      /* old filter was not taking into account not hired projects in favourites
      switch (taskState) {
        case 'paid':
          taskStateNewValue = 'in progress';

          break;
        default:
          taskStateNewValue = taskState;

          break;
      } */

      return taskStateNewValue;
    };
  }

  app.filter('taskState', taskState);
})();
