import './button.scss'

function ButtonDirective ($mdInteraction) {
  'ngInject'

  function isAnchor (attr) {
    return angular.isDefined(attr.href) || angular.isDefined(attr.ngHref) || angular.isDefined(attr.ngLink) || angular.isDefined(attr.uiSref)
  }

  return {
    restrict: 'EA',
    replace: true, // exceptional use of replace for Button directive
    transclude: true,
    template: function (element, attr) {
      if (isAnchor(attr)) {
        return '<a class="cdbl-button" ng-transclude></a>'
      } else {
        // If buttons don't have type="button", they will submit forms automatically.
        var btnType = (typeof attr.type === 'undefined') ? 'button' : attr.type
        return '<button class="cdbl-button" type="' + btnType + '" ng-transclude></button>'
      }
    },
    link: (scope, element, attr) => {
      'ngInject'
      // For anchor elements, we have to set tabindex manually when the
      // element is disabled
      if (isAnchor(attr) && angular.isDefined(attr.ngDisabled)) {
        scope.$watch(attr.ngDisabled, function (isDisabled) {
          element.attr('tabindex', isDisabled ? -1 : 0)
        })
      }

      // disabling click event when disabled is true
      element.on('click', function (e) {
        if (attr.disabled === true) {
          e.preventDefault()
          e.stopImmediatePropagation()
        }
      })

      if (!element.hasClass('cdbl-no-focus')) {
        element.on('focus', function () {
          // Only show the focus effect when being focused through keyboard interaction or programmatically
          if (!$mdInteraction.isUserInvoked() || $mdInteraction.getLastInteractionType() === 'keyboard') {
            element.addClass('cdbl-focused')
          }
        })

        element.on('blur', function () {
          element.removeClass('cdbl-focused')
        })
      }
    }
  }
}

export default ButtonDirective
