import { AuthorizationPermissionError } from '@/app/common/errors'

const AuthService = class AuthService {
  constructor ($q, $http, $state, $window, $rootScope, $interval, $timeout, Configuration, AuthTokenService, UserService, PusherService, SegmentAnalytics, IntercomService, StorageService, SystemReloadService, SleekService, ExperimentService) {
    'ngInject'
    this.$q = $q
    this.$http = $http
    this.$state = $state
    this.$window = $window
    this.$rootScope = $rootScope
    this.$interval = $interval
    this.$timeout = $timeout

    this.Configuration = Configuration
    this.UserService = UserService
    this.AuthTokenService = AuthTokenService
    this.PusherService = PusherService
    this.StorageService = StorageService

    this.SegmentAnalytics = SegmentAnalytics
    this.SystemReloadService = SystemReloadService
    this.IntercomService = IntercomService
    this.SleekService = SleekService
    this.ExperimentService = ExperimentService

    this._tokenRenewalInterval = null
    this._authenticatedInCurrentTab = false

    this._isInitialized = false
  }

  log () {
    console.log('[AuthService] log > authenticated:', this.isAuthenticated(), 'token:', Boolean(this.AuthTokenService.get()), ', user:', Boolean(this.UserService.user))
  }

  isAuthenticated () {
    return Boolean(this.AuthTokenService.get() && this.UserService.get())
  }

  startTokenRenewalInterval () {
    // Destrpy previous token update interval before creating new one
    if (this._tokenRenewalInterval) {
      this.cancelTokenRenewalInterval()
    }

    console.log('[AuthService] > startTokenRenewalInterval >', this.Configuration.auth.tokenRenewalInterval, 'seconds')
    this._tokenRenewalInterval = this.$interval(this.onTokenRenewalInterval.bind(this), this.Configuration.auth.tokenRenewalInterval * 1000)
  }

  onTokenRenewalInterval () {
    console.log('[AuthService] > onTokenRenewalInterval')
    this.renewAuthToken()
      .then(authToken => {
        this.onTokenChangeHandler(authToken)
      })
  }

  onTokenChangeHandler (authToken) {
    console.log('[AuthService] > onTokenChangeHandler > NEW TOKEN', authToken)
    this.AuthTokenService.save(authToken)
    // Refresh 3rd party libs with new token
    this.PusherService.updateAuthToken(authToken)
  }

  cancelTokenRenewalInterval () {
    console.log('[AuthService] > cancelTokenRenewalInterval')
    if (this._tokenRenewalInterval) {
      this.$interval.cancel(this._tokenRenewalInterval)
    }
  }

  renewAuthToken () {
    const payload = {
      authToken: this.AuthTokenService.get() // TODO: remove this fallback when BE starts excepting token provided in request header
    }
    console.log('[AuthService] > renewAuthToken', payload)
    return this.$http
      .post(`${this.Configuration.apiUrl}/users/auth_tokens`, payload)
      .then(response => {
        // 1. Save token
        const authToken = response.headers(this.Configuration.auth.tokenHeaderKey)
        this.AuthTokenService.save(authToken)

        // // 2. Refresh 3rd party libs
        // this.PusherService.updateAuthToken(authToken)
        // return response.data

        return authToken
      })
  }

  setAuthenticatedInCurrentTab (state) {
    this._authenticatedInCurrentTab = state
  }

  get isAuthenticatedInCurrentTab () {
    return this._authenticatedInCurrentTab
  }


  onAuthAttemptSuccess (response, authType = 'login') {
    // 1. Save token
    const authToken = response.headers(this.Configuration.auth.tokenHeaderKey)
    this.AuthTokenService.save(authToken)

    // 2. Save user object
    const user = response.data.user ? response.data.user : response.data // TODO: remove fallback once BE changes location of user object
    this.UserService.save(user)

    console.log(`[AuthService] > onAuthAttemptSuccess > ${authType} > response`, user)

    // 3. start token renewal interval
    this.startTokenRenewalInterval()

    // 4. init 3rd party libs
    this.PusherService.init(user.id, user.role, authToken)
    this.SystemReloadService.subscribeToSystemReload()
    this.IntercomService.init()
    this.SegmentAnalytics.identify(user, authType)
    this.ExperimentService.identify(user)

    // 5. set app as initialized and ready to display pages
    this.setIsInitialized(true)
    this.setAuthenticatedInCurrentTab(true)
  }

  register (userCredentials) {
    const payload = {
      ...userCredentials,
      timezoneOffset: new Date().getTimezoneOffset()
    }

    if (!payload.fullName) {
      payload.fullName = `${payload.firstName} ${payload.lastName}`
    }

    console.log('[AuthService] > register', payload)
    return this.$http
      .post(`${this.Configuration.apiUrl}/users`, payload)
      .then(response => {
        this.onAuthAttemptSuccess(response, 'register')
        // TODO: Potentially it would make more sense to track trackAccountCreated here instead of in the register controller
        // this.SegmentAnalytics.trackAccountCreated(user)
        const user = response.data.user ? response.data.user : response.data // TODO: remove fallback once BE changes location of user object
        return user
      })
  }

  login (userCredentials) {
    const payload = {
      ...userCredentials,
      timezoneOffset: new Date().getTimezoneOffset()
    }

    console.log('[AuthService] > login', payload)
    return this.$http
      .post(`${this.Configuration.apiUrl}/users/login`, payload)
      .then(response => {
        this.onAuthAttemptSuccess(response, 'login')
        const user = response.data.user ? response.data.user : response.data // TODO: remove fallback once BE changes location of user object
        return user
      })
  }

  loginGoogle (code) {
    const payload = {
      code: code,
      scope: 'profile https://www.googleapis.com/auth/userinfo.profile',
      timezoneOffset: new Date().getTimezoneOffset()
    }

    console.log('[AuthService] > login', payload)
    return this.$http
      .post(`${this.Configuration.apiUrl}/auth/google_oauth2/callback`, payload)
      .then(response => {
        this.onAuthAttemptSuccess(response, 'login')
        const user = response.data.user ? response.data.user : response.data // TODO: remove fallback once BE changes location of user object
        return user
      })
  }

  acceptTOS (code) {
    const payload = {
      sessionToken: code,
      tosAccepted: true
    }

    console.log('[AuthService] > login', payload)
    return this.$http
      .put(`${this.Configuration.apiUrl}/sso/accept_tos`, payload)
      .then(response => {
        this.onAuthAttemptSuccess(response, 'login')
        const user = response.data.user ? response.data.user : response.data // TODO: remove fallback once BE changes location of user object
        return user
      })
  }

  // TODO: remove this after retiring the affiliate form
  loginWithToken (token) {
    const payload = {
      authToken: token // TODO: remove this fallback when BE starts excepting token provided in request header
    }
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    console.log('[AuthService] > loginWithToken', payload, config)
    return this.$http
      .post(`${this.Configuration.apiUrl}/users/token_login`, payload, config)
      .then(response => {
        this.onAuthAttemptSuccess(response, 'token-login')
        const user = response.data.user ? response.data.user : response.data // TODO: remove fallback once BE changes location of user object
        return user
      })
  }


  // Should only be called once on app init phase
  // Renew auth token on app init, if successfull continue with:
  // - start auth token refresh interval
  // - fetch fresh user data from BE
  // - store auth token and user object in LS
  // - intitialize 3rd party libs
  async appInitAuthAttempt () {
    const deferred = this.$q.defer()
    this.setIsInitialized(false)

    // 1. renew auth token
    console.log('[AuthService] > onAppInitAuthAttemptAsync > 1. renew auth token')
    await this.renewAuthToken()
      .then(authToken => {
        this.AuthTokenService.save(authToken)
      })
      .catch(err => {
        deferred.reject(err)
        return deferred.promise
      })

    // 2. fetch user object
    console.log('[AuthService] > onAppInitAuthAttemptAsync > 2. fetch user object')
    await this.UserService.fetchUser()
      .then(user => {
        this.UserService.save(user)
      })
      .catch(err => {
        deferred.reject(err)
        return deferred.promise
      })

    // 3. start token renewal interval
    console.log('[AuthService] > onAppInitAuthAttemptAsync > 3. start token renewal interval')
    this.startTokenRenewalInterval()


    // 4. init 3rd party libs
    console.log('[AuthService] > onAppInitAuthAttemptAsync > 4. init 3rd party libs')
    const user = this.UserService.get()
    const authToken = this.AuthTokenService.get()
    this.PusherService.init(user.id, user.role, authToken)
    this.SystemReloadService.subscribeToSystemReload()
    this.IntercomService.init()
    this.SegmentAnalytics.identify(user, 'appInit')
    this.ExperimentService.identify(user)

    // 5. set app as initialized and ready to display pages
    // this.setIsInitialized(true) // this should be triggred in app.component on init
    this.setAuthenticatedInCurrentTab(true)

    deferred.resolve()
    return deferred.promise
  }

  setIsInitialized (value = false) {
    this._isInitialized = value
  }

  get isInitialized () {
    return this._isInitialized
  }

  checkIsInitialized () {
    const deferred = this.$q.defer()

    const interval = this.$interval(() => {
      console.log('[AuthService] > checkIsInitialized > interval')
      if (this.isInitialized) {
        console.log('[AuthService] > checkIsInitialized', this.isInitialized, 'interval canceled')
        this.$interval.cancel(interval)
        deferred.resolve()
      }
    }, 100)

    return deferred.promise
  }

  async logout (customConfig) {
    const defaultConfig = {
      state: this.Configuration.auth.loginRoute,
      params: {},
      options: {},
      redirectToLoginPage: true
    }
    const config = { ...defaultConfig, ...customConfig }
    console.log('[AuthService] > logout', config)
    // Used in: app.component.js, auth.interceptorj.js, user_menu.html

    // 1.1 cancel token renewal interval
    this.cancelTokenRenewalInterval()

    // 1.2 delete token from BE
    if (this.AuthTokenService.get()) {
      await this.$http
        .delete(`${this.Configuration.apiUrl}/users/login`)
        .finally(() => {
          console.log('AUTH TOKEN DELETED FROM BE')
          this.AuthTokenService.destroy()
        })
    }

    // 2. delete current token
    this.AuthTokenService.destroy()

    // 3. delete current user
    this.UserService.destroy()

    // 4. disconnect from 3rd party libs
    this.PusherService.unsubscribeAll()
    this.SystemReloadService.unSubscribeToSystemReload()
    this.IntercomService.shutdown()
    this.SleekService.destroy()

    // 5. send app wide event for some of the features
    // this.$rootScope.$broadcast('authSrv::logged-out')

    this.setAuthenticatedInCurrentTab(false)

    // By default logout redirects to login page, for some use cases logout should clear all data above hover not redirect to login page
    if (config.redirectToLoginPage) {
      // 6. redirect to login page
      this.$state.go(config.state, config.params, config.options)
    }
  }


  // TODO: remove this method
  logoutWithoutRedirect () {
    console.error('INVESTIGATE THIS!!')
    // this.oldAuthService.signOutWithoutRedirect()
  }

  // TODO: remove this and use UserService instead
  getUser () {
    return this.UserService.user
  }

  isClient () {
    return this.UserService.isClient()
  }

  isExpert () {
    return this.UserService.isExpert()
  }
  // ---

  // TODO: remove this and use AuthTokenService instead
  getAuthToken () {
    return this.AuthTokenService.get()
  }
  // ---

  // TODO: rename to guards to match naming of new angular
  // Permission checks to be used in routes. Currently most legacy routes are unguarded
  permissionCheck (permission) {
    switch (permission) {
    case 'isExpert':
      if (this.isAuthenticated() && this.isExpert()) {
        return true
      }
      return new AuthorizationPermissionError()

    case 'isClient':
      if (this.isAuthenticated() && this.isClient()) {
        return true
      }
      return new AuthorizationPermissionError()

    case 'isAuthenticated':
      if (this.isAuthenticated()) {
        return true
      }
      return new AuthorizationPermissionError()

    case 'isNotAuthenticated':
      if (!this.isAuthenticated()) {
        return true
      }
      return new AuthorizationPermissionError()
    }

    // Allways denied by default
    return new AuthorizationPermissionError()
  }

  permissionResolver (permission) {
    return this.$q((resolve, reject) => {
      const result = this.permissionCheck(permission)

      if (result instanceof AuthorizationPermissionError) {
        reject(result)
      } else {
        resolve(result)
      }
    })
  }
}
export default AuthService
