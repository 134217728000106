const LS_DRAFT_KEY = 'submission-draft'
const SubmissionDraftService = class SubmissionDraftService {
  constructor ($http, Configuration, StorageService, $location, $stateParams) {
    'ngInject'
    this.$http = $http
    this.Configuration = Configuration
    this.StorageService = StorageService
    this.$location = $location
    this.$stateParams = $stateParams
  }

  loadDraft (draftId) {
    console.log('[SubmissionDraftService] > loadDraft', draftId)
    return this.$http.get(`${this.Configuration.apiUrl}/draft_submissions/${draftId}`)
      .then(response => response.data)
  }

  updateDraft (draft) {
    console.log('[SubmissionDraftService] > updateDraft', draft)
    const payload = {
      submissionDraft: draft.submissionDraft
    }

    // TODO: unomment this once BE starts working
    // if (draft?.actions?.update) {
    //   return this.$http.put(`${this.Configuration.apiUrl}${draft.actions.update.url}`, payload)
    //   .then(response => response.data)
    // }

    // // return back promise and same object back
    // return new Promise((resolve) => {
    //   resolve(draft)
    // })


    // TODO: remove this once BE :top: works
    return this.$http.put(`${this.Configuration.apiUrl}/draft_submissions/${draft.id}`, payload)
      .then(response => response.data)
  }


  saveDraftIdToLS (id) {
    console.log('[SubmissionDraftService] > saveDraftIdToLS', id)
    this.StorageService.set(LS_DRAFT_KEY, id)
  }

  restoreDraftIdFromLS () {
    console.log('[SubmissionDraftService] > restoreDraftIdFromLS')
    return this.StorageService.get(LS_DRAFT_KEY)
  }

  removeDraftIdFromLS () {
    console.log('[SubmissionDraftService] > removeDraftIdFromLS')
    this.StorageService.remove(LS_DRAFT_KEY)
  }

  restoreDraftIdFromUrl () {
    console.log('[SubmissionDraftService] > restoreDraftIdFromUrl')
    return this.$stateParams.draft
  }


  removeDraftIdFromUrl () {
    console.log('[SubmissionDraftService] > removeDraftIdFromUrl')
    this.$location.search('draft', null) // remove draft from url
  }

  restoreDraftId () {
    return this.restoreDraftIdFromUrl() || this.restoreDraftIdFromLS()
  }

  removeDraftId () {
    this.removeDraftIdFromUrl()
    this.removeDraftIdFromLS()
  }

  get hasDraftId () {
    return Boolean(this.restoreDraftId())
  }
}

export default SubmissionDraftService
