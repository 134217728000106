import SubmissionStepTwoComponent from './submission-step-two.component'
import SubmissionProgressbarModule from './../submission-progressbar/submission-progressbar.module'
import SubmissionLoginRegister from './../submission-login-register/submission-login-register.module'
import SubmissionStepTwoService from './submission-step-two.service'
import cdblAttachments from './../../attachments/attachments.module'

const SubmissionStepTwoModule = angular.module('cdbl.submission-step-two', [
  SubmissionProgressbarModule,
  SubmissionLoginRegister,
  cdblAttachments
])
  .service('SubmissionStepTwoService', SubmissionStepTwoService)
  .component('cdblSubmissionStepTwo', SubmissionStepTwoComponent)
  .name

export default SubmissionStepTwoModule
