
// TODO: migrate and refactor into ProjectService
const FeeService = class FeeService {
  constructor (UserService) {
    'ngInject'
    this.UserService = UserService
  }

  calcServiceFee (task = null, subtask = null) {
    const serviceFee = {
      client: null,
      expert: null,
      clientTask: null,
      clientSubtask: null
    }

    // Client service fee
    if (this.UserService.isClient()) {
      // client
      serviceFee.client = this.UserService.me.serviceFeePercentage
    } else if (task) {
      // expert
      serviceFee.client = task.client.serviceFeePercentage // TODO: in case of task having discount expert cant get client service fee
    }

    // Expert service fee (always has task or subtask context)
    if (task) {
      // for client and expert
      serviceFee.expert = task.prices.contractorFeePercentage
    }

    // Task service fee
    serviceFee.clientTask = task ? task.serviceFeePercentage : serviceFee.client // use task service fee or fallback to user service fee

    // Subtask service fee
    serviceFee.clientSubtask = subtask ? subtask.serviceFeePercentage : serviceFee.client // use subtask service fee or fallback to user service fee

    return serviceFee
  }

  getServiceFee (context, ...args) {
    const fee = this.calcServiceFee(...args)[context]

    // console.log('getServiceFee > ', context, fee);
    if (typeof fee !== 'undefined' && fee !== null) {
      return `${fee}%`
    }

    return ''
  }
}
export default FeeService
