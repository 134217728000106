const stickyDirective = function ($compile, $window, $document, UIService, EventEmitter) {
  'ngInject'
  return {
    restrict: 'A',
    scope: {
      cdblStickyCallback: '&'
    },
    link: (scope, element, attrs) => {
      console.log('stickyDirective', element)
      const top = angular.isDefined(attrs.cdblStickyTop) ? parseInt(attrs.cdblStickyTop) : 0
      element.css('top', `${top}px`)
      const callback = scope.cdblStickyCallback || angular.noop

      if (UIService.isStickySupported) {
        element.css('position', UIService.isStickySupported)
      } else {
        const scrollBreakpoint = angular.isDefined(attrs.cdblSticky) ? parseInt(attrs.cdblSticky) : 0
        const width = angular.isDefined(attrs.cdblStickyWidth) ? attrs.cdblStickyWidth : false
        let isFixed = false

        const getWidth = function () {
          console.log('getWidth', element.parent().width(), width)
          return `${Math.min(element.parent().width(), 1330) * width / 100}px`
          // return `calc(${element.parent().width()}px * ${width} / 100 - 8px)`;
        }

        let widthCalc = getWidth()

        const scrollHandler = () => {
          const shouldBeFixed = angular.element($document).scrollTop() > scrollBreakpoint
          if (shouldBeFixed && !isFixed) {
            element.css('position', 'fixed')
            element.css('width', widthCalc)
            isFixed = true
            callback(EventEmitter({ isFixed: isFixed }))
          } else if (!shouldBeFixed && isFixed) {
            // console.log('stickyDirective > static');
            element.css('position', 'static')
            element.css('width', 'auto')
            isFixed = false
            callback(EventEmitter({ isFixed: isFixed }))
          }
        }

        const resizeHandler = () => {
          widthCalc = getWidth()
          console.log(widthCalc)
          if (isFixed) {
            element.css('width', widthCalc)
          }
        }

        $window.addEventListener('scroll', scrollHandler)
        $window.addEventListener('resize', resizeHandler)
        scope.$on('destroy', () => {
          $window.removeEventListener('scroll', scrollHandler)
          $window.removeEventListener('resize', resizeHandler)
        })
      }
    }
  }
}

export default stickyDirective
