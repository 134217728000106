import templateUrl from './project-user-card.html'
import './project-user-card.scss'

const ProjectUserCardComponent = {
  bindings: {
    // user: '<',
    customTitle: '@title',
    project: '<'
  },
  templateUrl,
  controller: class ProjectUserCard {
    constructor (UserService, PusherService, ModalService, $filter) {
      'ngInject'
      this._identify = 'ProjectUserCardComponent'
      this.UserService = UserService
      this.PusherService = PusherService
      this.ModalService = ModalService
      this.$filter = $filter
    }

    get user () {
      return this.UserService.isClient() ? this.project.contractor : this.project.client
    }

    get title () {
      if (this.customTitle) {
        return this.customTitle
      }

      return `Your ${this.UserService.isClient() ? 'developer' : 'client'}`
    }

    get localTime () {
      const timezoneOffset = this.UserService.isClient() ? this.project.contractor.timezoneOffset : this.user.timezoneOffset
      const time = this.$filter('timezoneToCurrentTime')(timezoneOffset)

      return this.$filter('amDateFormat')(time, 'ddd, h:mm a')
    }

    openReviewModal () {
      this.ModalService.open({
        closeOnBackdrop: false,
        closeIcon: false,
        layout: 'center',
        class: 'project-review-modal',
        template: `<cdbl-project-review-modal project="$ctrl.modal.project"></cdbl-project-review-modal>`,
        project: this.project
      })
    }
  }
}

export default ProjectUserCardComponent
