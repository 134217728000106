// import ProjectBrowsePodsComponent from './project-browse-pods.component'

import ProjectBrowsePodsManagementModalComponent from './project-browse-pods-management-modal'
import ProjectBrowsePodsBarComponent from './project-browse-pods-bar'
import ProjectBrowsePodsService from './project-browse-pods.service'

const ProjectBrowsePodsModule = angular.module('cdbl.project-browse-pods', [

])
  // .component('cdblProjectBrowsePods', ProjectBrowsePodsComponent)
  .component('cdblProjectBrowsePodsManagementModal', ProjectBrowsePodsManagementModalComponent)
  .component('cdblProjectBrowsePodsBar', ProjectBrowsePodsBarComponent)
  .service('ProjectBrowsePodsService', ProjectBrowsePodsService)

  .name

export default ProjectBrowsePodsModule
