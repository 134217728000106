(function () {
  'use strict';

  function IntroTaskCtrl($scope, $location, $cookies, Modal, Storage, Restangular, Auth) {
    $scope.closed = false;
    // $scope.feedback = null;
    $scope.otherActive = false;

    $scope.feedback = {
      selected: null
    };

    $scope.completedSurvey = Boolean(Auth.me().trackedSegmentByLink);

    $scope.feedbackAnswers = [
      {
        id: 'wp_freelancer',
        label: 'WordPress developer freelancer'
      },
      {
        id: 'wp_agency',
        label: 'WordPress development/marketing agency'
      },
      {
        id: 'business_owner',
        label: 'Business owner/employee with a WordPress website'
      }
    ];
    $scope.feedbackAnswers = $scope.feedbackAnswers.sort(() => (Math.random() - 0.5)); // randomize answers

    $scope.saveFeedback = function (removeIntroBox, feedback, answer) {
      console.log(removeIntroBox, feedback, answer, $scope.feedback);
      if (feedback === 'other' && !answer) {
        return;
      }

      $scope.error = null;
      if (!feedback) {
        $scope.error = 'Please make a selection!';
        return;
      }

      if (removeIntroBox === true) {
        // ! I've replaced the global Storage object with localStorage here
        localStorage.setItem($scope.modalScope.localStorageVariable, true);
      }

      let data = {
        segment: (feedback === 'other' ? answer : feedback)
      };

      Restangular.all('users').one('me').one('segment').customPUT(data).then(function () {
        $cookies.segmentUser = 'no';

        $scope.closed = true;
        Auth.changeMe('trackedSegmentByLink', data.segment);

        $location.search('intro', null).replace();
        Modal.close();
      });
    };

    $scope.close = function (removeIntroBox) {
      if (removeIntroBox === true) {
        // ! I've replaced the global Storage object with localStorage here
        localStorage.setItem($scope.modalScope.localStorageVariable, true);
      }

      $location.search('intro', null).replace();
      Modal.close();
    };
  }

  app.controller('IntroTaskCtrl', ['$scope', '$location', '$cookies', 'Modal', 'Storage', 'Restangular', 'Auth', IntroTaskCtrl]);
})();
