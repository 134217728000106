import templateUrl from './presets-create.html'
import './presets-create.scss'

const ProjectPresetsCreateComponent = {
  bindings: {},
  templateUrl,
  controller: class ProjectPresetsCreateComponent {
    constructor ($stateParams, ProjectPresetsService, ModalService, EventEmitter, $state, UtilService, $timeout) {
      'ngInject'
      this._identify = 'ProjectPresetsCreateComponent'
      this.$stateParams = $stateParams
      this.ProjectPresetsService = ProjectPresetsService
      this.ModalService = ModalService
      this.EventEmitter = EventEmitter
      this.$state = $state
      this.UtilService = UtilService
      this.$timeout = $timeout
    }

    $onInit () {
      this.errorMessages = {
        'not_found': 'Form not found.',
        'PreferredExpertNotFoundError': 'The Codeable expert that was preselected for this form is no longer available. Please, contact Codeable support.',
      }
      this.loadParentPreset()
        .then(preset => {
          this.ProjectPresetsService.trackEventBasePresetFormLoaded(preset)
        })
    }

    loadParentPreset () {
      this.error = null
      this.isLoading = true
      return this.ProjectPresetsService.loadParentPreset(this.$stateParams)
        .then(data => {
          this.preset = data
          // override shortcode with url param
          if (this.$stateParams.shortcode) {
            this.preset.shortcode = this.$stateParams.shortcode
            this.preset.fields.shortcode.value = this.preset.shortcode
          }
          return this.preset
        })
        .catch(err => {
          this.error = err
          throw err
        })
        .finally(() => {
          this.isLoading = false
          this.$timeout()
        })
    }

    generateEnduserLink () {
      this.presetLink = null
      return this.ProjectPresetsService.createPresetLink(this.preset)
        .then(presetLink => {
          this.ProjectPresetsService.trackEventPresetLinkCreated(this.preset)
          this.ModalService.open(this.ModalService.generateConfig('success', {
            title: 'COPY LINK (to share)',
            body: `
              <cdbl-field-container>
                <input id="srcContentClipboard" type="text" name="presetLink" ng-value="$ctrl.modal.presetLink" readonly="readonly">
              </cdbl-field-container>
            
              <cdbl-button class="button-link" ng-click="$ctrl.modal.copy2Clipboard()">Copy Link</cdbl-button>
            `,
            resolveButton: {
              label: 'Close'
            },
            presetLink,
            copy2Clipboard: this.UtilService.copy2Clipboard
          }))
            .then(resolve => {
              console.log('modal resolve', resolve)
            })
            .catch(err => {
              console.log('modal reject', err)
            })

          this.resetForm()
        })
        .catch(err => {
          this.ModalService.open(this.ModalService.generateConfig('success', {
            title: 'Error',
            body: `<cdbl-error-message error="$ctrl.modal.error" no-styles="true"></cdbl-error-message>`,
            resolveButton: {
              label: 'Close'
            },
            error: err
          }))
          throw err
        })
    }

    resetForm () {
      this.loadParentPreset()
    }
  }
}

export default ProjectPresetsCreateComponent
