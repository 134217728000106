import { v4 as uuidv4 } from 'uuid'
import { GrowthBook } from '@growthbook/growthbook'

const ANONYMOUS_HASH_ATTRIBUTE = 'cdbl_anonymous_id'
const USER_HASH_ATTRIBUTE = 'cdbl_user_id'

const ExperimentService = class ExperimentService {
  constructor ($http, $cookies, $window, $timeout, Configuration, SegmentAnalytics, StorageService, UserService) {
    'ngInject'
    this.$http = $http
    this.$cookies = $cookies
    this.$window = $window
    this.$timeout = $timeout
    this.Configuration = Configuration
    this.StorageService = StorageService
    this.SegmentAnalytics = SegmentAnalytics
    this.UserService = UserService

    this.experiments = {}
    this.growthbook = null

    this.cookieName = this.Configuration.CDBL_ENV === 'production' ? ANONYMOUS_HASH_ATTRIBUTE : `${ANONYMOUS_HASH_ATTRIBUTE}--${this.Configuration.CDBL_ENV}`
  }

  identify (user) {
    if (this.growthbook) {
      const attributes = {}
      attributes[USER_HASH_ATTRIBUTE] = user ? user.id : null
      attributes[ANONYMOUS_HASH_ATTRIBUTE] = this.anonymousId
      this.growthbook.setAttributes(attributes)
      console.log('[ExperimentService] > identify', user, attributes)
    }
  }

  async init () {
    this.generateAnonymousId()
    // Create a GrowthBook context
    this.growthbook = new GrowthBook({
      apiHost: 'https://cdn.growthbook.io',
      clientKey: this.Configuration.growthbook.apiKey,
      enableDevMode: true,
      subscribeToChanges: true,
      trackingCallback: (experiment, result) => {
        this.SegmentAnalytics.trackEvent('Experiment Viewed', {
          experimentId: experiment.key,
          variationId: result.variationId,
        })

        console.log('[ExperimentService] > trackingCallback', experiment.key, result.variationId)
      },
      onFeatureUsage: (featureKey, result) => {
        console.log('[ExperimentService] > onFeatureUsage', featureKey, result)
      },
    })

    // Identify user if already available on init
    const user = this.UserService.user
    this.identify(user)

    return this.growthbook.loadFeatures()

    // Load feature definitions (from API, database, etc.)
    // return fetch(`https://cdn.growthbook.io/api/features/${this.Configuration.growthbook.apiKey}`)
    //   .then((res) => res.json())
    //   .then((parsed) => {
    //     this.growthbook.setFeatures(parsed.features)
    //     console.log('[ExperimentService] > growthbook > loaded features', parsed.features)
    //   })
  }

  getFeatureList () {
    return this.growthbook ? this.growthbook.getFeatures() : {}
  }

  isOn (name) {
    const result = this.growthbook.isOn(name)
    console.log('[ExperimentService] > isOn', name, result)
    return result
  }

  isOff (name) {
    const result = this.growthbook.isOff(name)
    console.log('[ExperimentService] > isOff', name, result)
    return result
  }
  get anonymousId () {
    const uuidCookie = this.$cookies.get(this.cookieName)
    const uuidStorage = this.StorageService.nativeGet(ANONYMOUS_HASH_ATTRIBUTE)
    return uuidCookie || uuidStorage
  }
  generateAnonymousId () {
    const uuidCookie = this.$cookies.get(this.cookieName)
    const uuidStorage = this.StorageService.nativeGet(ANONYMOUS_HASH_ATTRIBUTE)
    const uuidStoragePrefixed = this.StorageService.get(ANONYMOUS_HASH_ATTRIBUTE)

    let uuid = uuidCookie || uuidStorage || uuidStoragePrefixed
    console.log('🚀 ~ ExperimentService ~ generateAnonymousId ~ uuidStorage:', uuidStorage)
    console.log('🚀 ~ ExperimentService ~ generateAnonymousId ~ uuidCookie:', uuidCookie)
    console.log('🚀 ~ ExperimentService ~ generateAnonymousId ~ uuid:', uuid)

    // Set expiration to 1 years from now, some browsers cap this to less (7 days Brave, 400 days chrome, etc..)
    const expireDate = new Date()
    expireDate.setFullYear(expireDate.getFullYear() + 1)
    // Set domain and other options
    // For production: .codeable.io
    // For other environments: .ENVIRONMENT.codeable.io and for local development .localhost or .app.cdbl.local
    const cookieOptions = {
      domain: this.Configuration.isBuild ? '.codeable.io' : this.$window.location.hostname,
      path: '/',
      expires: expireDate
    }
    console.log('🚀 ~ ExperimentService ~ generateAnonymousId ~ cookieOptions:', cookieOptions)

    if (uuid) {
      if (!uuidCookie) {
        console.log('[ExperimentService] > generateAnonymousId > CDBL anonymous id already exists but cookie is missing > create new cookie', uuid)
        this.$cookies.put(this.cookieName, uuid, cookieOptions)
      } else {
        // always inherit from cookie if exists
        this.StorageService.nativeSet(ANONYMOUS_HASH_ATTRIBUTE, uuid)
      }

      // remove old prefixed token
      if (uuidStoragePrefixed) {
        this.StorageService.remove(ANONYMOUS_HASH_ATTRIBUTE)
      }
      console.log('[ExperimentService] > generateAnonymousId > CDBL anonymous id already exists', uuidCookie)
    } else {
      uuid = uuidv4()
      this.$cookies.put(this.cookieName, uuid, cookieOptions)
      this.StorageService.nativeSet(ANONYMOUS_HASH_ATTRIBUTE, uuid)
      console.log('[ExperimentService] > generateAnonymousId > CDBL anonymous id generated', uuid)
    }
  }
}
export default ExperimentService
