// TODO: use updateCouponCode method of UserService and deprecate this file

const CouponService = class CouponService {
  constructor ($http, Configuration) {
    'ngInject'
    this.$http = $http
    this.config = Configuration
  }
  addCoupon (coupon_code) {
    let payload = {
      coupon_code: coupon_code
    }

    return this.$http
      .post(`${this.config.apiUrl}/users/me/coupons`, payload)
  }
}
export default CouponService
