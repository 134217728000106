export function mergeObjects (obj1, obj2) {
  for (let p in obj2) {
    try {
      if (obj2[p].constructor === Object) {
        obj1[p] = mergeObjects(obj1[p], obj2[p])
      } else {
        obj1[p] = obj2[p]
      }
    } catch (e) {
      obj1[p] = obj2[p]
    }
  }
  return obj1
}

export default mergeObjects
