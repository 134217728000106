import templateUrl from './attachments-sidebar-widget.html'
import './attachments-sidebar-widget.scss'

const AttachmentsSidebarWidgetComponent = {
  bindings: {
    project: '<'
  },
  templateUrl,
  controller: class AttachmentsSidebarWidget {
    constructor (AttachmentsService, ModalService, EventBusService, UserService, ErrorsService) {
      'ngInject'
      this._identify = 'AttachmentsSidebarWidgetComponent'
      this.AttachmentsService = AttachmentsService
      this.ModalService = ModalService
      this.EventBusService = EventBusService
      this.UserService = UserService
      this.ErrorsService = ErrorsService

      this.attachmentList = []
      this.isLoading = false

      const vm = this
      this.expirationConfig = {
        expiresAt: null,
        timeoutPromise: null,
        callback: () => {
          vm.getProjectAttachments()
        }
      }

      this.downloadAllErrorHandler = this.ErrorsService.createErrorHandler()
    }

    $onInit () {
      this.params = {
        page: 1,
        per_page: 6
      }
      this.getProjectAttachments()

      this.subscriptionCollection = this.EventBusService.createSubscriptionCollection('legacy')

      // attachment-created
      this.subscriptionCollection.subscribe('attachment-created', (event) => {
        this.addNewAttachment(event.data)
      })

      // attachment-destroyed
      this.subscriptionCollection.subscribe('attachment-destroyed', (event) => {
        const attachmentIndex = this.attachmentList.findIndex(a => a.id === event.data.id)
        if (attachmentIndex > -1) {
          this.attachmentList.splice(attachmentIndex, 1)
        }
      })
    }

    $onDestroy () {
      this.subscriptionCollection.unsubscribeAll()
      this.AttachmentsService.cancelTimeoutUpdateAttachmentsData(this.expirationConfig)
    }

    getProjectAttachments () {
      console.log('[AttachmentsSidebarWidgetComponent] > getProjectAttachments')
      this.isLoading = true
      this.AttachmentsService
        .getAttachmentList(this.project.id, this.params)
        .then(response => {
          this.attachmentList = response

          // trigger expiration timestamp for sign urls refetch
          if (this.attachmentList[0]) {
            this.expirationConfig.expiresAt = this.attachmentList[0].expiresAt
          } else {
            this.expirationConfig.expiresAt = null
          }
        })
        .catch(err => { console.error(err) })
        .finally(() => {
          this.isLoading = false
          this.AttachmentsService.timeoutUpdateAttachmentsData(this.expirationConfig)
        })
    }

    onDeleteAttachment ($event) {
      const index = this.attachmentList.findIndex(a => a.id === $event.attachment.id)
      if (index > -1) {
        this.attachmentList.splice(index, 1)
      }
    }


    onUploadFinish ($event) {
      // All attachments uploaded
      console.log('[AttachmentsSidebarWidgetComponent] > onUploadFinish', $event)
    }

    onFileUploaded ($event) {
      // Update widget with new attachment
      console.log('[AttachmentsSidebarWidgetComponent] > onFileUploaded', $event)
      if ($event.attachment) {
        this.addNewAttachment($event.attachment)
      }
    }

    addNewAttachment (newAttachment) {
      console.log('[AttachmentsSidebarWidgetComponent] > addNewAttachment', newAttachment.id, newAttachment)
      const exists = this.attachmentList.find(a => a.id === newAttachment.id)
      if (!exists) {
        // Add new attachment if it does not exist yet (i.e. other user uploaded it)
        this.attachmentList.push(newAttachment)
      }
    }

    openAllAttachmentsModal ($event) {
      $event.preventDefault()
      $event.stopPropagation()

      this.ModalService.open({
        closeOnBackdrop: true,
        closeIcon: true,
        class: 'project-attachments-modal',
        template: `<cdbl-attachments-modal project="$ctrl.modal.project"></cdbl-attachments-modal>`,
        project: this.project
      })
    }

    downloadAllAttachments ($event) {
      console.log('[AttachmentsSidebarWidgetComponent] > downloadAllAttachments')
      this.downloadAllErrorHandler.reset()
      this.inProgressDownloadAll = true
      this.AttachmentsService.downloadAllAttachments(this.project.id)
        .catch(err => {
          this.downloadAllErrorHandler.add(err)
        })
        .finally(() => {
          this.inProgressDownloadAll = false
        })
    }
  }
}

export default AttachmentsSidebarWidgetComponent
