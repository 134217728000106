const ProjectTeamService = class ProjectTeamService {
  constructor ($http, Configuration, UserService) {
    'ngInject'

    this.$http = $http
    this.Configuration = Configuration
    this.UserService = UserService
  }

  fetchTeam (projectId) {
    const apiRoleSlug = this.UserService.apiRoleSlug
    return this.$http
      .get(`${this.Configuration.apiUrl}/${apiRoleSlug}/projects/${projectId}/team`)
      .then(response => response.data)
  }

  addTeamMembers (projectId, expertIds) {
    const apiRoleSlug = this.UserService.apiRoleSlug
    const payload = {
      expertIds
    }
    return this.$http
      .post(`${this.Configuration.apiUrl}/${apiRoleSlug}/projects/${projectId}/team-members`, payload)
      .then(response => response.data)
  }

  removeTeamMember (projectId, expertIds) {
    const apiRoleSlug = this.UserService.apiRoleSlug
    const config = {
      data: {
        expert_ids: expertIds
      },
      headers: { 'Content-Type': 'application/json' }
    }
    return this.$http
      .delete(`${this.Configuration.apiUrl}/${apiRoleSlug}/projects/${projectId}/team-members`, config)
      .then(response => response.data)
  }

  fetchExperts () {
    return this.$http
      .get(`${this.Configuration.apiUrl}/users/contractors`)
      .then(response => {
        if (response.data) {
          return response.data.map(expert => {
            return {
              id: expert.id,
              name: expert.fullName,
              available: expert.available
            }
          })
        }
        return []
      })
  }

  // *********************
  // PAYMENTS
  // *********************

  fetchTeamPayments (projectId) {
    return this.$http
      .get(`${this.Configuration.apiUrl}/experts/projects/${projectId}/team-payments`)
      .then(response => response.data)
  }

  createTeamPayment (projectId, paymentData) {
    console.log('[ProjectTeamService] > createTeamPayment', projectId, paymentData)
    const payload = {
      amount: paymentData.amount,
      note: paymentData.note,
      recipientId: paymentData.recipientId
    }

    if (paymentData.resource.type === 'SubTask') {
      payload.subTaskId = paymentData.resource.id
    }

    return this.$http
      .post(`${this.Configuration.apiUrl}/experts/projects/${projectId}/team-payments`, payload)
      .then(response => response.data)
  }
}
export default ProjectTeamService
