import SettingsComponent from './settings.component'
import SettingsService from './settings.service'

import SettingsAccountModule from './settings-account'
import SettingsAvailabilityModule from './settings-availability'
import SettingsNotificationsModule from './settings-notifications'
import SettingsVaultModule from './settings-vault'
import SettingsInvoicesModule from './settings-invoices'
import SettingsCommsPreferencesModule from './settings-comms-preferences'
import SettingsExpertTeamModule from './settings-expert-team'

const SettingsModule = angular.module('cdbl.settings', [
  SettingsAccountModule,
  SettingsAvailabilityModule,
  SettingsNotificationsModule,
  SettingsVaultModule,
  SettingsInvoicesModule,
  SettingsCommsPreferencesModule,
  SettingsExpertTeamModule
])
  .component('cdblSettings', SettingsComponent)
  .service('SettingsService', SettingsService)
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject'

    $stateProvider
      .state('settings', {
        url: '/settings',
        abstract: true,
        template: '<cdbl-settings></cdbl-settings>',
        resolve: {
          permissionResolver: (AuthService) => {
            'ngInject'
            return AuthService.permissionResolver('isAuthenticated')
          }
        },
        layout: 'new'
      })

    $urlRouterProvider.when('/settings', '/settings/account')
  })
  .name

export default SettingsModule
