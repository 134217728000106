import AutocompleteComponent from './autocomplete.component'

import AutocompleteTagModule from './autocomplete-tag'

const AutocompleteModule = angular.module('cdbl.autocomplete', [
  AutocompleteTagModule
])
  .component('cdblAutocomplete', AutocompleteComponent)
  .name

export default AutocompleteModule
