import templateUrl from './team-details.html'
import './team-details.scss'

const SettingsExpertTeamDetailsComponent = {
  bindings: {
    team: '<',
    openEditModal: '&'
  },
  templateUrl,
  controller: class SettingsExpertTeamDetailsComponent {
    constructor () {
      'ngInject'
      this._identify = 'SettingsExpertTeamDetailsComponent'
    }

    get logoStyle () {
      return {
        backgroundImage: `url(${this.team.details.logo.mediumUrl})`
      }
    }

    get canEdit () {
      return this.team && this.team.actions && this.team.actions.updateDetails
    }
  }
}

export default SettingsExpertTeamDetailsComponent
