class MissingArgumentsError extends Error {
  constructor (message) {
    const messageText = message ? `Missing arguments: ${message}` : 'Missing arguments'
    super(messageText)

    this.name = 'MissingArgumentsError'
    this.message = 'Missing arguments.'
    this.description = 'Ensure that data is present in function calls.'
  }
}

export default MissingArgumentsError
