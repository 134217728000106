const ProjectStateFormatFilter = function ($filter, UserService) {
  'ngInject'
  return function (projectState) {
    if (typeof projectState !== 'string') {
      return ''
    }

    let stateText = projectState

    switch (projectState) {
    case 'paid':
      stateText = 'in progress'
      break
    }

    return stateText
  }
}
export default ProjectStateFormatFilter
