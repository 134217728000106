(function () {
  'use strict';

  function split() {
    return function (input, splitChar, splitIndex) {
      return input.split(splitChar)[splitIndex];
    };
  }

  app.filter('split', split);
})();
