const SystemReloadService = class SystemReloadService {
  constructor (EventBusService, ModalService, $window, Sockets) {
    'ngInject'
    this._identify = 'SystemReloadService'
    this.EventBusService = EventBusService
    this.ModalService = ModalService
    this.Sockets = Sockets
    this.$window = $window
  }

  subscribeToSystemReload () {
    this.Sockets.subscribe('root')
    this.EventBusService
      .channel('root')
      .subscribe('system-reload', ($event) => {
        this.ModalService.open({
          closeOnBackdrop: false,
          closeIcon: true,
          layout: 'center',
          template: `<cdbl-system-reload-modal></cdbl-system-reload-modal>`
        })
      })
  }

  unSubscribeToSystemReload () {
    this.Sockets.unsubscribe('root')
  }
}
export default SystemReloadService
