import templateUrl from './system-reload-modal.html'
import './system-reload-modal.scss'

const SystemReloadModalComponent = {
  bindings: {
  },
  templateUrl,
  require: {
    modalCtrl: '^^cdblModal'
  },
  controller: class ReloadModal {
    constructor ($window) {
      'ngInject'
      this._identify = 'SystemReloadModalComponent'
      this.$window = $window
    }

    reload () {
      this.$window.location.reload()
    }
  }
}

export default SystemReloadModalComponent
