const persistentScrollDirective = function ($window) {
  'ngInject'
  return {
    restrict: 'A',
    link: (scope, element, attrs) => {
      const scrollUp = 'scroll-up'
      const scrollDown = 'scroll-down'
      let lastScroll = 0
      const triggerHandler = (event) => {
        const currentScroll = event.target.scrollTop
        if (currentScroll <= 0) {
          element.removeClass(scrollUp)
          return
        }

        if (currentScroll > lastScroll && !element.hasClass(scrollDown)) {
          // down
          element.removeClass(scrollUp)
          element.addClass(scrollDown)
        } else if (
          currentScroll < lastScroll &&
          element.hasClass(scrollDown)
        ) {
          // up
          element.removeClass(scrollDown)
          element.addClass(scrollUp)
        }
        lastScroll = currentScroll
      }
      $window.addEventListener('scroll', triggerHandler, true)
      scope.$on('destroy', () => {
        $window.removeEventListener('scroll', triggerHandler)
      })
    }
  }
}

export default persistentScrollDirective
