import AttachmentModule from './attachment'

import AttachmentsUploadModule from './attachments-upload'
import AttachmentsSidebarWidgetModule from './attachments-sidebar-widget'
import AttachmentsModalModule from './attachments-modal'

import AttachmentsService from './attachments.service'
import FileUploadWrapperFactory from './file-upload-wrapper.factory'


import OnFileChangeDirective from './on-file-change.directive'


const AttachmentsModule = angular.module('cdbl.attachments', [
  AttachmentModule,
  AttachmentsUploadModule,
  AttachmentsSidebarWidgetModule,
  AttachmentsModalModule
])
  .factory('FileUploadWrapperFactory', FileUploadWrapperFactory)
  .service('AttachmentsService', AttachmentsService)
  .directive('onFileChange', OnFileChangeDirective)
  .name

export default AttachmentsModule
