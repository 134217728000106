import templateUrl from './autocomplete.html'
import './autocomplete.scss'

const AutocompleteComponent = {
  bindings: {
    hideTags: '<',
    placeholder: '@',
    state: '<',
    propLabel: '@',
    propValue: '@',
    multiple: '<?',
    excludeSelected: '<?',
    onSelectHandler: '&?',
    optionsListApi: '<?',
    searchDebounce: '<?',
    optionsList: '<' // new prop to separate selected data in ng-model from list of available options, it plays role of changeing autocomplete template and default position of tags
  },
  require: {
    fieldCtrl: '?^cdblFieldContainer',
    model: 'ngModel'
  },
  transclude: {
    'description': '?cdblAutocompleteOptionDescription' // could be a div, why not?
  },
  templateUrl,
  controller: class Autocomplete {
    constructor ($scope, $element, $attrs, $timeout, $filter, $http, Configuration, EventEmitter) {
      'ngInject'
      this._identify = 'AutocompleteComponent'

      this.search = {
        name: ''
      }
      this.$timeout = $timeout
      this.$element = $element
      this.$attrs = $attrs
      this.$filter = $filter
      this.$http = $http
      this.Configuration = Configuration
      this.EventEmitter = EventEmitter
      this.data = null
      this.showTags = true
    }

    $onInit () {
      // TODO currently multiple option has reverse logic - to enable single select binding has to be set to false for backward compatibility - in future go through app and make it opposite adding multiple bining this logic
      this.multiple = typeof this.multiple !== 'undefined' ? this.multiple : true
      this.excludeSelected = typeof this.excludeSelected !== 'undefined' ? this.excludeSelected : true
      this.searchDebounce = typeof this.searchDebounce !== 'undefined' ? this.searchDebounce : 1000
      this.model.$render = () => {
        this.data = this.model.$viewValue
      }

      // if new prop is used apply default tags on top styles
      if (this.optionsList || this.optionsListApi) {
        this.$element.addClass('tags--top')
      }

      if (this.optionsListApi) {
        this.optionsList = this.optionsList || []
      }

      this.$element.attr('tabindex', '0')
      this.$element.on('focus', () => {
        console.log(this.$element.find('input'))
      })

      // this.$timeout(() => {
      //   this.checkValidity()
      // })

      if (typeof (this.state) !== 'object') {
        this.state = {
          focus: false
        }
      }

      // register it in parent field container component
      if (this.fieldCtrl) {
        this.fieldCtrl.setFieldElementCtrl({
          ngModel: this.model,
          $element: this.$element,
          $attrs: this.$attrs
        })
      }
    }

    $onDestroy () {
      if (this.fieldCtrl) {
        this.fieldCtrl.setFieldElementCtrl(null)
      }
    }

    get isNewMode () {
      return this.optionsList || this.optionsListApi
    }

    get _filteredOptions () {
      let tmpResult = this._optionsList
      if (this.excludeSelected) {
        tmpResult = this.$filter('excludeFromArray')(tmpResult, this._selectedList, this.propValue)
      }

      if (this.optionsListApi) {
        return tmpResult
      }

      return this.$filter('filter')(tmpResult, this.searchQuery)
    }

    get _selectedList () {
      if (this.optionsList || this.optionsListApi) {
        return this.data
      }

      return this.data.selected // old way, kept for backward compatibility, remove when not used anymore
    }
    set _selectedList (newValue) {
      if (this.optionsList || this.optionsListApi) {
        this.data = newValue
      } else {
        this.data.selected = newValue // old way, kept for backward compatibility, remove when not used anymore
      }
    }

    get _optionsList () {
      if (this.optionsList || this.optionsListApi) {
        return this.optionsList
      }

      return this.data.options // old way, kept for backward compatibility, remove when not used anymore
    }

    getOptionLabel (option) {
      if (this.optionsListApi && typeof this.optionsListApi.labelFormatter === 'function') {
        return this.optionsListApi.labelFormatter(option)
      } else if (this.propLabel && typeof option === 'object') {
        return option[this.propLabel]
      } else if (this.propValue && this.propLabel && typeof option !== 'object') {
        const optionFromList = this._optionsList.find(o => o[this.propValue] === option)
        if (optionFromList) {
          return optionFromList[this.propLabel]
        }
      }

      return option
    }

    getOptionValue (option) {
      if (this.propValue) {
        return option[this.propValue]
      }
      return option
    }

    get noResultsMessage () {
      if (this.optionsListApi && this.search.name === '') {
        return 'Type something...'
      } else if (this.optionsListApi) {
        return 'No results found, please refine your search'
      }

      return 'No results found'
    }

    get searchQuery () {
      if ((this.optionsList || this.optionsListApi) && this.propLabel) {
        const q = {}
        q[this.propLabel] = this.search.name
        return q
      } else if ((this.optionsList || this.optionsListApi) && !this.propLabel) {
        return this.search.name
      }

      return this.search // old way, kept for backward compatibility, remove when not used anymore
    }

    loadOptionsAsync () {
      const url = this.optionsListApi.urlSlug ? `${this.Configuration.apiUrl}${this.optionsListApi.urlSlug}` : this.optionsListApi.url
      const config = this.optionsListApi.paramName ? {
        params: {
          [this.optionsListApi.paramName]: this.search.name
        }
      } : {}

      return this.$http.get(url, config)
        .then(response => {
          if (typeof this.optionsListApi.listFormatter === 'function') {
            return this.optionsListApi.listFormatter(response.data)
          }
          return response.data
        })
    }

    onChangeSearchQuery () {
      this.openDropdown()
      if (this.optionsListApi) {
        this.isLoadingOptions = true
        this.loadOptionsAsync()
          .then(response => {
            this.optionsList = response
          })
          .finally(() => {
            this.isLoadingOptions = false
          })
      }
    }

    onKeydown ($event) {
      if ($event.key === 'ArrowDown' || $event.keyCode === 40) {
        $event.preventDefault()
        $event.stopPropagation()
        console.log('focus', this.$element.find('.dropdown-menu .dropdown-menu__option').first())
        this.$element.find('.dropdown-menu .dropdown-menu__option a').first().focus()
      }
    }

    onFocus () {
      this.$timeout(() => {
        this.openDropdown()
      }, 100)
      this.state.focus = true
    }

    onFocusOut () {
      this.$timeout(() => {
        this.state.focus = false
      }, 1000)
      this.model.$setTouched()
    }

    onSelect (option, $event) {
      if (this.multiple) {
        if (!Array.isArray(this._selectedList)) {
          this._selectedList = []
        }
        if (('limit' in this.$attrs) && this._selectedList.length === parseInt(this.$attrs.limit)) {
          return
        }
        this._selectedList.push(this.getOptionValue(option))
      } else {
        this._selectedList = this.getOptionValue(option)
      }
      this.search.name = '' // reset name

      this.model.$setViewValue(this.data)
      this.model.$setDirty()
      this.model.$setTouched()
      this.checkValidity()

      if (typeof this.onSelectHandler === 'function') {
        this.onSelectHandler(this.EventEmitter({ option }))
      }
    }

    onOptionRemove ($event) {
      console.log('onOptionRemove > $event', $event)
      if (this.multiple && Array.isArray(this._selectedList)) {
        let comparator
        if (typeof $event.option === 'object') {
          comparator = o => o.id !== $event.option.id
        } else {
          comparator = o => o !== $event.option
        }

        this._selectedList = this._selectedList.filter(comparator)
      } else if (!this.multiple) {
        this._selectedList = null
      }

      this.model.$setViewValue(this.data)
      this.model.$setDirty()
      this.model.$setTouched()
      this.checkValidity()
    }

    checkValidity () {
      if (typeof this.data === 'undefined') {
        return
      }

      if ('required' in this.$attrs) {
        if (this._selectedList.length === 0) {
          this.model.$setValidity('required', false)
        } else {
          this.model.$setValidity('required', true)
        }
      }

      if ('max' in this.$attrs) {
        if (this._selectedList.length > parseInt(this.$attrs.max)) {
          this.model.$setValidity('max', false)
        } else {
          this.model.$setValidity('max', true)
        }
      }

      if ('min' in this.$attrs) {
        if (this._selectedList.length > 0 && this._selectedList.length < parseInt(this.$attrs.min)) {
          this.model.$setValidity('min', false)
        } else {
          this.model.$setValidity('min', true)
        }
      }
    }

    openDropdown () {
      this.$element.find('.cdbl__dropdown, .dropdown').addClass('open')
    }
    closeDropdown () {
      this.$element.find('.cdbl__dropdown, .dropdown').removeClass('open')

      if (this.optionsListApi) {
        this.optionsList = []
      }
    }
  }
}

export default AutocompleteComponent
