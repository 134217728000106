'use strict';
import { AnalyticsBrowser } from '@segment/analytics-next'

function SegmentAnalytics($window, $location, $state, $cookies, Configuration, StorageService) {
  const analytics = AnalyticsBrowser.load({ writeKey: Configuration.segmentAnalytics })
  $window.analytics = analytics

  let currentUser = false;

  const CODEALBLE_SUBMISSION_FORM_ID_DEFAULT = 'codeable_default';

  function setGeneralData(p) {
    p.user_type = (currentUser && currentUser.role) ? currentUser.role : null;
    p.account_created = (currentUser && currentUser.createdAt) ? currentUser.createdAt : null;
  }

  function setExperimentVersion(payload, experimentData) {
    payload.experiment = experimentData ? experimentData : null;
  }

  // PAGEVIEW

  const pageview = function (fromState) {
    let path = $location.path();
    let querystring = '';
    let referrer = '';

    if (path.indexOf('?') !== -1) {
      querystring = path.substring(path.indexOf('?'), path.length);
    }

    if (fromState.name) {
      referrer = $location.protocol() + '://' + $location.host() + '/#' + fromState.url;
    }

    let payload = {
      path: path,
      referrer: referrer,
      search: querystring,
      url: $location.absUrl(),
      user_hash: (currentUser && currentUser.createdAt) ? currentUser.intercomUserHash : null
    };

    // if on submission flow add additional category attribute
    if (path.includes('tasks/new')) {
      payload.category = 'app submission flow';
      console.log('add category to segmentAnalytics', payload);
    }

    if ($location.search().gclid) {
      payload.gclid = $location.search().gclid;
    }
    if ($location.search().ref) {
      payload.affiliate_ref = $location.search().ref;
    }
    if ($location.search().coupon_code) {
      payload.coupon_code = $location.search().coupon_code;
    }

    setGeneralData(payload);
    console.info('SegmentAnalytics > pageview', payload);
    // let intercom = Storage.get('showIntercom');
    $window.analytics.page(payload);
  };

  // IDENTIFY

  const identify = function (user, logMessage) {
    if (angular.isUndefined(user) || angular.isUndefined(user.id) || user === null) {
      return;
    }
    currentUser = user;

    logMessage = '> ' + (logMessage || '');
    let purchase_triggered = user.role === 'client' && user.stats.avgTaskSize !== 'NaN' ? true : null;

    let payload = {
      name: user.fullName,
      email: user.email,
      user_hash: (currentUser && currentUser.createdAt) ? currentUser.intercomUserHash : null,
      tracked_segment_by_link: user.trackedSegmentByLink,
      purchase_triggered: purchase_triggered
    };

    setGeneralData(payload);
    console.info('SegmentAnalytics > identify ', logMessage, user.id, payload);
    // let intercom = Storage.get('showIntercom');
    $window.analytics.identify(user.id, payload);
  };

  // -----------------------------------
  // GENERAL EVENTS

  const trackEvent = function (eventName, payload = {}) {
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackEvent', eventName, payload);
    $window.analytics.track(eventName, payload);
  };

  const trackNavOpen = function () {
    let payload = {
      nav_name: 'Dashboard nav'
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackNavOpen > payload', payload);
    $window.analytics.track('Nav Opened', payload);
  };

  const trackNavOpenHelp = function () {
    let payload = {
      nav_name: 'Dashboard nav help'
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackNavOpenHelp > payload', payload);
    $window.analytics.track('Nav HelpOpened', payload);
  };

  const trackClientProgressDropdownOpened = function () {
    let payload = {
      client_score: Math.min(currentUser.completedTasksCount, 10)
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackClientProgressDropdownOpened > payload', payload);
    $window.analytics.track('Client Progress Dropdown Opened', payload);
  };

  const trackVimeoVideo = function (videoName, eventName, videoEvt) {
    let payload = {
      progress: videoEvt.seconds,
      duration: videoEvt.duration,
      procent: videoEvt.percent * 100,
      video: videoName
    };
    let eventNameProcessed;
    switch (eventName) {
      case 'play': eventNameProcessed = 'Played';
        break;
      case 'pause': eventNameProcessed = 'Paused';
        break;
      case 'ended': eventNameProcessed = 'Ended';
        break;
      case 'seeked': eventNameProcessed = 'Seeked';
        break;
    }
    setGeneralData(videoEvt);
    console.info('SegmentAnalytics > trackVimeoVideo > payload', eventNameProcessed, payload);
    $window.analytics.track(`Video ${eventNameProcessed}`, payload);
  };

  // -----------------------------------
  // EXPERIMENT VIEWED
  const trackExperimentViewed = function (experimentData) {
    let payload = {};
    setGeneralData(payload);
    setExperimentVersion(payload, experimentData);

    console.info('SegmentAnalytics > trackExperimentViewed > payload', payload);
    $window.analytics.track('Experiment Viewed', payload);
  };

  // -----------------------------------
  // TASK SUBMISSION
  const trackSubmissionFormLoaded = function (eventData) {
    let payload = {
      form_id: eventData.partnerName ? eventData.partnerName : CODEALBLE_SUBMISSION_FORM_ID_DEFAULT,
      trigger_method: eventData.trigger ? eventData.trigger : 'on_load'
    };
    setGeneralData(payload);
    setExperimentVersion(payload, eventData.experiment);

    console.info('SegmentAnalytics > trackSubmissionFormLoaded > payload', payload);
    $window.analytics.track('Submission Form Loaded', payload);
  };

  const trackProjectTypeSelected = function (eventData) {
    let payload = {
      project_subject: eventData.project_subject,
      project_type: eventData.project_type,
      type: '',
      form_id: eventData.partner_name ? eventData.partner_name : CODEALBLE_SUBMISSION_FORM_ID_DEFAULT
    };
    setGeneralData(payload);
    setExperimentVersion(payload, eventData.experiment);
    console.info('SegmentAnalytics > trackProjectTypeSelected > payload', payload);
    $window.analytics.track('Project Type Selected', payload);
  };

  const trackProjectDetailsCompleted = function (eventData) {
    let payload = {
      project_subject: eventData.project_subject,
      project_type: eventData.project_type,
      type: '',
      form_id: eventData.partner_name ? eventData.partner_name : CODEALBLE_SUBMISSION_FORM_ID_DEFAULT
    };
    setGeneralData(payload);
    setExperimentVersion(payload, eventData.experiment);
    console.info('SegmentAnalytics > trackProjectDetailsCompleted > payload', payload);
    $window.analytics.track('Project Details Completed', payload);
  };

  const trackConsulationDetailsCompleted = function (eventData) {
    let payload = {
      project_subject: eventData.project_subject,
      project_type: eventData.project_type,
      type: '',
      form_id: eventData.partner_name ? eventData.partner_name : CODEALBLE_SUBMISSION_FORM_ID_DEFAULT
    };
    setGeneralData(payload);
    setExperimentVersion(payload, eventData.experiment);
    console.info('SegmentAnalytics > trackConsulationDetailsCompleted > payload', payload);
    $window.analytics.track('Consultation Details Completed', payload);
  };

  const trackBudgetUrgencyCompleted = function (eventData) {
    let payload = {
      project_subject: eventData.project_subject,
      project_type: eventData.project_type,
      type: '',
      form_id: eventData.partner_name ? eventData.partner_name : CODEALBLE_SUBMISSION_FORM_ID_DEFAULT
    };
    setGeneralData(payload);
    setExperimentVersion(payload, eventData.experiment);
    console.info('SegmentAnalytics > trackBudgetUrgencyCompleted > payload', payload);
    $window.analytics.track('Budget and Urgency Completed', payload);
  };

  const trackTaskInfoSubmitted = function (task) {
    let selectedContractors = [];
    if (task.preferredContractors && task.preferredContractors.selected) {
      if (task.preferredContractors.selected.length === 1) {
        selectedContractors = task.preferredContractors.selected[0].id;
      } else {
        angular.forEach(task.preferredContractors.selected, contractor => {
          selectedContractors.push(contractor.id);
        });
      }
    }

    let payload = {
      type: task.kind,
      project_title: task.title,
      budget: task.budget,
      urgency: task.urgency,
      preferred_contractor: selectedContractors,
      project_subject: task.project_subject,
      project_type: task.project_type
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackTaskInfoSubmitted > payload', payload);
    $window.analytics.track('Project Info Submitted', payload);
  };

  // Submission File
  const trackSubmissionFileAdded = function (taskId) {
    let payload = {
      project_id: taskId
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackSubmissionFileAdded > payload', payload);
    $window.analytics.track(`Submission File Added`, payload);
  };

  const trackSubmissionFileAddedFailed = function (taskId) {
    let payload = {
      project_id: taskId
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackSubmissionFileAddedFailed > payload', payload);
    $window.analytics.track(`Submission File Added Failed`, payload);
  };

  const trackTaskPosted = function (task, eventData) {
    let preferredContractors = null;
    if (angular.isArray(task.preferredContractors)) {
      preferredContractors = [];
      angular.forEach(task.preferredContractors, function (pc) {
        preferredContractors.push(pc.id);
      });
    } else if (angular.isObject(task.preferredContractors)) {
      preferredContractors = task.preferredContractors.id;
    }

    let payload = {
      project_id: task.id,
      project_title: task.title,
      budget: task.budget,
      type: task.kind,
      created_at: task.createdAt, // published_at would be better
      published: task.updatedAt, // using updated_at because API sets publishe_at date to 100years in the future if preferred contractors are selected
      urgency: task.urgency,
      preferred_contractor: preferredContractors,
      form_location: 'SPA',
      affiliate_partner_id: null,
      affiliate_page: null,
      project_subject: task.projectSubject,
      project_type: task.projectType,
      form_id: eventData.partner_name ? eventData.partner_name : CODEALBLE_SUBMISSION_FORM_ID_DEFAULT,
      presetDefiner: eventData.definer ? eventData.definer : null,
      presetForm: eventData.form ? eventData.form : null,
    };
    setGeneralData(payload);
    setExperimentVersion(payload, eventData.experiment);

    console.info('SegmentAnalytics > trackTaskPosted > payload', payload);
    $window.analytics.track('Project Posted', payload);
  };

  const trackTaskPostingFailed = function (task, requestMethod) {
    if (typeof task !== 'object') {
      task = {}; // fallback so that properties for payload will be undefined and no js errors are raised
    }

    let preferredContractors = null;
    if (angular.isArray(task.preferredContractors)) {
      preferredContractors = [];
      angular.forEach(task.preferredContractors, function (pc) {
        preferredContractors.push(pc.id);
      });
    } else if (angular.isObject(task.preferredContractors)) {
      preferredContractors = task.preferredContractors.id;
    }
    let payload = {
      project_id: task.id,
      project_title: task.title,
      budget: task.budget,
      type: task.kind,
      created_at: task.createdAt, // published_at would be better
      published: task.updatedAt, // using updated_at because API sets publishe_at date to 100years in the future if preferred contractors are selected
      urgency: task.urgency,
      preferred_contractor: preferredContractors,
      call_response_error: requestMethod,
      form_location: 'SPA',
      affiliate_partner_id: null,
      affiliate_page: null,
      project_subject: task.project_subject,
      project_type: task.project_type
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackTaskPostingFailed > payload', payload);
    $window.analytics.track('Project Posting Failed', payload);
  };

  const trackIntercomOpened = function (step, medium = 'link') {
    let payload = {
      location: step,
      medium: medium
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackIntercomOpened > payload', payload);
    $window.analytics.track('Intercom Opened', payload);
  };

  // -----------------------------------
  // TASK WORKROOM
  const trackTaskBudgetChanged = function (originalBudget, newBudget) {
    let payload = {
      original_budget: originalBudget,
      new_budget: newBudget
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackTaskBudgetChanged > payload', payload);
    $window.analytics.track('Project Budget Changed', payload);
  };

  const trackTaskCanceled = function (task, reason) {
    let payload = {
      project_id: task.id,
      type: task.kind,
      created_at: task.createdAt ? task.createdAt : task.publishedAt,
      urgency: task.urgency,
      reason: reason,
      project_state: task.state
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackTaskCanceled > payload', payload);
    $window.analytics.track('Project Cancelled', payload);
  };
  const trackTaskConverted = function (task, previousType) {
    let page = null;
    const stateName = $state.current.name;
    console.info('trackTaskConverted > stateName', stateName);
    // TODO: remove old dashboard conditions
    if (stateName === 'tasks.all' || startsWith(stateName, 'dashboard')) {
      page = 'Dashboard';
    } else if (stateName === 'tasks.display') {
      page = 'Display';
    }
    let payload = {
      project_id: task.id,
      type: previousType,
      created_at: task.createdAt,
      page: page
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackTaskConverted > payload', payload);
    $window.analytics.track('Project Converted', payload);
  };
  const trackTaskReported = function (task) {
    let payload = {
      project_id: task.id,
      type: task.kind,
      created_at: task.createdAt
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackTaskReported > payload', payload);
    $window.analytics.track('Project Reported', payload);
  };

  const trackTaskHidden = function (task, stateName) {
    let page = null;
    if (stateName === 'tasks.all' || startsWith(stateName, 'dashboard')) {
      page = 'Dashboard';
    } else if (stateName === 'tasks.display') {
      page = 'Display';
    }
    let payload = {
      project_id: task.id,
      type: task.kind,
      created_at: task.createdAt,
      page: page
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackTaskHidden > payload', payload);
    $window.analytics.track('Project Hidden', payload);
  };

  const trackTaskUnhidden = function (task, stateName) {
    let page = null;
    if (stateName === 'tasks.all' || startsWith(stateName, 'dashboard')) {
      page = 'Dashboard';
    } else if (stateName === 'tasks.display') {
      page = 'Display';
    }
    let payload = {
      project_id: task.id,
      type: task.kind,
      created_at: task.createdAt,
      page: page
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackTaskUnhidden > payload', payload);
    $window.analytics.track('Project Unhidden', payload);
  };

  const trackTaskFavorited = function (task, stateName) {
    let page = null;
    if (stateName === 'tasks.all' || startsWith(stateName, 'dashboard')) {
      page = 'Dashboard';
    } else if (stateName === 'tasks.display') {
      page = 'Display';
    }
    let payload = {
      project_id: task.id,
      type: task.kind,
      created_at: task.createdAt,
      page: page
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackTaskFavorited > payload', payload);
    $window.analytics.track('Project Favorited', payload);
  };

  const trackTaskUnfavorited = function (task, stateName) {
    let page = null;
    if (stateName === 'tasks.all' || startsWith(stateName, 'dashboard')) {
      page = 'Dashboard';
    } else if (stateName === 'tasks.display') {
      page = 'Display';
    }
    let payload = {
      project_id: task.id,
      type: task.kind,
      created_at: task.createdAt,
      page: page
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackTaskUnfavorited > payload', payload);
    $window.analytics.track('Project Unfavorited', payload);
  };

  const trackTasksFiltered = function (filters, searchString) {
    // TODO duplicate with function in tasksCtrl.js
    const filterObjectToParams = filterObj => {
      let paramsObj = {};
      const paramNames = ['category', 'price', 'urgency', 'special', 'skills'];

      paramNames.forEach(paramName => {
        // console.log(filterObj[paramName]);
        if (filterObj && filterObj[paramName] && !_.isEmpty(filterObj[paramName])) {
          const paramFilterValues = Object.entries(filterObj[paramName])
                                        .filter(([key, val]) => key !== 'all' && val !== false)
                                        .map(([key]) => key)
                                        .join(',');
          if (paramFilterValues) {
            paramsObj[paramName] = paramFilterValues;
          }
        }
      });

      return paramsObj;
    };

    let filterString = '';
    angular.forEach(filterObjectToParams(filters), function (value, key) {
      if (typeof value === 'undefined' || value.length === 0) {
        return;
      }
      if (filterString !== '') {
        filterString += '&';
      }
      filterString += key + '=' + value;
    });

    let payload = {
      filter_string: filterString,
      search_term: searchString
    };
    console.log('trackTasksFiltered', filters, filterObjectToParams(filters), payload);
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackTasksFiltered > payload', payload);
    $window.analytics.track('Projects Filtered', payload);
  };

  const trackTaskEstimated = function (task, contractorId, amount) {
    let payload = {
      project_id: task.id,
      contractor_id: contractorId,
      estimate_value: amount
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackTaskEstimated > payload', payload);
    $window.analytics.track('Project Estimated', payload);
  };

  const trackTaskConfirmed = function (task, contractor) {
    let payload = {
      project_id: task.id,
      contractor_id: contractor.id,
      project_value: task.prices.paymentAmountDollars
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackTaskConfirmed > payload', payload);
    $window.analytics.track('Project Confirmed', payload);
  };

  const trackTaskCompleted = function (task) {
    let subtaskSum = 0;
    if (task.subtasks) {
      angular.forEach(task.subtasks, function (subTask) {
        // only make sum of additional tasks
        if (!subTask.retainer) {
          subtaskSum += subTask.prices.paymentAmountDollars;
        }
      });
    }

    let payload = {
      type: task.kind,
      project_id: task.id,
      completed_at: Date.now(), // TODO: not property on task?
      project_value: task.prices.paymentAmountDollars,
      subtask_value: subtaskSum,
      total_value: task.prices.paymentAmountDollars + subtaskSum
    };

    setGeneralData(payload);
    console.info('SegmentAnalytics > trackTaskCompleted > payload', payload);
    $window.analytics.track('Project Completed', payload);
  };

  const trackReviewCTAViewed = function (npsScore) {
    let payload = {
      nps_score: npsScore
    };

    setGeneralData(payload);
    console.info('SegmentAnalytics > trackReviewCTAViewed > payload', payload);
    $window.analytics.track('Review CTA Viewed', payload);
  };

  const trackReviewCTAClicked = function (data) {
    let payload = {
      nps_score: data.npsScore,
      social_type: data.socialType
    };

    setGeneralData(payload);
    console.info('SegmentAnalytics > trackReviewCTAClicked > payload', payload);
    $window.analytics.track('Review CTA Clicked', payload);
  };

  const trackNpsSubmitted = function (data) {
    let payload = {
      nps_score: data.npsScore,
      user_id: data.userId
    };

    setGeneralData(payload);
    console.info('SegmentAnalytics > trackNpsSubmitted > payload', payload);
    $window.analytics.track('NPS Submitted', payload);
  };

  const trackSubtaskPosted = function (subTask, contractor) {
    let payload = {
      subtask_id: subTask.id,
      subtask_value: subTask.prices.paymentAmountDollars,
      project_id: subTask.parentTask.id,
      contractor_id: contractor.id
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackSubtaskPosted > payload', payload);
    $window.analytics.track('Subtask Posted', payload);
  };

  const trackRetainerPosted = function (retainer, contractor, parentTask, buttonClicked) {
    // console.info('SegmentAnalytics > trackRetainerPosted > ', retainer, contractor, parentTask, buttonClicked);
    let payload = {
      contractor_id: contractor.id,
      project_id: retainer.parentTask.id,
      retainer_id: retainer.id,
      retainer_created: retainer.createdAt,
      client_id: parentTask.client.id,
      retainer_title: retainer.title,
      value: retainer.prices.paymentAmountDollars,
      retainer_button_clicked: buttonClicked
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackRetainerPosted > payload', payload);
    $window.analytics.track('Retainer Posted', payload);
  };

  const trackRetainerPayment = function (task, retainer, provider) {
    // console.info('SegmentAnalytics > trackRetainerPayment > ', task, retainer, provider);

    let payload = {
      contractor_id: task.contractor.id,
      payment_provider: provider,
      project_id: task.id,
      retainer_id: retainer.id,
      payment_submitted: retainer.paymentHistory.createdAt,
      client_id: task.client.id,
      retainer_title: retainer.title,
      value: retainer.prices.paymentAmountDollars,
      revenue: retainer.prices.paymentAmountDollars,
      currency: 'USD'
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackRetainerPayment > payload', payload);
    $window.analytics.track('Retainer Payment Submitted', payload);
  };

  const trackRetainerCancelled = function (retainer, task, user) {
    // console.info('SegmentAnalytics > trackRetainerCancelled > ', retainer, user);
    const getRetainerFirstPaid = function (paymentHistoryLog) {
      const firstClientPayment = _.find(paymentHistoryLog);
      return firstClientPayment ? firstClientPayment.date : null;
    };

    let payload = {
      contractor_id: task.contractor.id,
      project_id: retainer.parentTask.id,
      retainer_id: retainer.id,
      retainer_created: retainer.createdAt,
      payment_submitted: retainer.paymentHistory.createdAt,
      retainer_first_paid: getRetainerFirstPaid(retainer.paymentHistory.events), // retainer.paymentHistory.events
      retainer_cancelled: retainer.updatedAt,
      client_id: task.client.id,
      retainer_title: retainer.title,
      user_type: user.role,
      value: retainer.prices.paymentAmountDollars,
      retainer_payment_state: (getRetainerFirstPaid(retainer.paymentHistory.events) ? 'Paid' : 'Not paid')
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackRetainerCancelled > payload', payload);
    $window.analytics.track('Retainer Cancelled', payload);
  };

  const trackTaskSearch = (search, results_total) => {
    let payload = {
      search_string: search,
      number_of_results: results_total
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackTaskSearch > payload', payload);
    $window.analytics.track('Workroom Searched ', payload);
  };

  // -----------------------------------
  // PAYMENT
  const trackPayment = function (task, subTask, provider) {
    let payload = {
      value: task.prices.paymentAmountDollars, // needs to be in dollars, not cents
      revenue: task.prices.paymentAmountDollars, // needs to be in dollars, not cents
      currency: 'USD',
      project_id: task.id,
      project_title: task.title,
      contractor_id: task.contractor.id,
      payment_provider: provider,
      npc: task.npc || false,
      source: task.partnerName || '',
      numberPaidProjects: task.client.numberPaidProjects,
      item_type: 'project'
    };

    if (subTask && typeof subTask.id !== 'undefined') {
      payload.item_type = 'add_task';
      payload.value = subTask.prices.paymentAmountDollars; // needs to be in dollars, not cents
      payload.revenue = subTask.prices.paymentAmountDollars; // needs to be in dollars, not cents
      payload.subtask_id = subTask.id;
      payload.subtask_title = subTask.title;
    }

    setGeneralData(payload);
    console.info('SegmentAnalytics > trackPayment > payload', payload);
    $window.analytics.track('Payment', payload);
  };

  // -----------------------------------
  // TASK PAYMENT

  const trackTaskPayment = function (task, provider) {
    let payload = {
      value: task.prices.paymentAmountDollars, // needs to be in dollars, not cents
      revenue: task.prices.paymentAmountDollars, // needs to be in dollars, not cents
      currency: 'USD',
      project_id: task.id,
      project_title: task.title,
      contractor_id: task.contractor.id,
      payment_provider: provider,
      npc: task.npc || false,
      source: task.partnerName || '',
      numberPaidProjects: task.client.numberPaidProjects
    };

    setGeneralData(payload);
    console.info('SegmentAnalytics > trackTaskPayment > payload', payload);
    $window.analytics.track('Project Paid', payload);
  };

  const trackSubTaskPayment = function (task, subTask, provider) {
    let payload = {
      value: subTask.prices.paymentAmountDollars, // needs to be in dollars, not cents
      revenue: subTask.prices.paymentAmountDollars, // needs to be in dollars, not cents
      currency: 'USD',
      project_id: task.id,
      contractor_id: task.contractor.id,
      subtask_id: subTask.id,
      subtask_title: subTask.title,
      payment_provider: provider
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackSubTaskPayment > payload', payload);
    $window.analytics.track('Subtask Paid', payload);
  };

  const trackPaymillError = function (contex, error, resourceData) {
    let payload = {
      contex: contex,
      error: error,
      resource: resourceData
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackPaymillError > payload', payload);
    $window.analytics.track('Paymill Error', payload);
  };

  // -----------------------------------
  // REFERRAL

  const trackReferralProgramViewed = function (origin) {
    let rp = null;
    if (origin === 'nextModal') {
      rp = 'What’s Next modal';
    }

    let payload = {
      referral_page: rp
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackReferralProgramViewed > payload', payload);
    $window.analytics.track('Referral Program Viewed', payload);
  };

  // -----------------------------------
  // USER

  const trackAccountCreated = function (user) {
    let page = null;
    let stateName = $state.current.name;

    if (stateName === 'auth.register') {
      page = 'SPA register';
    } else if (stateName === 'tasks.create') {
      page = 'SPA new task';
    } else if (stateName === 'presets.apply') {
      page = 'SPA presets apply';
    }

    let payload = {
      completed_tasks_count: page,
      created_at: user.createdAt,
      affiliate_partner_id: null,
      affiliate_page: null
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackAccountCreated > payload', payload);
    $window.analytics.track('Account Created', payload);
  };

  // -----------------------------------
  // Help center

  const helpCenterClicked = function (origin) {
    let payload = {
      location: origin
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > helpCenterClicked > payload', payload);
    $window.analytics.track('Help Center Clicked', payload);
  };

  // -----------------------------------
  // Swag Promotion

  const swagPromotionViewed = function (user) {
    let payload = {
      completed_tasks_count: user.tasksCount
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > swagPromotionViewed > payload', payload);
    $window.analytics.track('Swag Promotion Viewed', payload);
  };

  const swagPromotionAddAddressBtnClicked = function (user) {
    let payload = {
      completed_tasks_count: user.tasksCount
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > swagPromotionAddAddressBtnClicked > payload', payload);
    $window.analytics.track('Swag Promotion Click', payload);
  };

  const swagPromotionHide = function (user) {
    let payload = {
      completed_tasks_count: user.tasksCount
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > swagPromotionHide > payload', payload);
    $window.analytics.track('Swag Promotion Hide', payload);
  };

  const settingsButtonClicked = function (user) {
    let payload = {
      completed_tasks_count:  user.tasksCount
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > settingsButtonClicked > payload', payload);
    $window.analytics.track('Swag Promotion Complete', payload);
  };

  // NOTIFICATIONS
  const trackNotificationDropdownOpen = function (type) {
    let payload = {
      notification_type: mapNotificationType(type)
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackNotificationDropdownOpen > payload', payload);
    $window.analytics.track('Notification Dropdown Opened', payload);
  };
  const trackNotificationmarkAllAsRead = function (type) {
    let payload = {
      notification_type: mapNotificationType(type)
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackNotificationmarkAllAsRead > payload', payload);
    $window.analytics.track('Notifications Marked As Read', payload);
  };

  const trackNotificationClicked = function (location, url, notification) {
    let payload = {
      notification_type: mapNotificationType(notification.type),
      notification_url: url,
      location: location, // dropdown|page|toaster
      attribution_type: notification.verb,
      attribution_channel: 'inapp'
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackNotificationClicked > payload', payload);
    $window.analytics.track('Notification Clicked', payload);
  };

  // -----------------------------------
  // ESTIMATION / WORKROOM

  // Subtask Overview Modal Opened
  const trackSubtaskOverviewModalOpened = function (task = null) {
    let payload = {
      project_id: task ? task.id : null
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackSubtaskOverviewModalOpened > payload', payload);
    $window.analytics.track('Subtask Overview Modal Opened', payload);
  };

  // Subtask Detail Opened
  const trackSubtaskDetailOpened = function (task = null, subtask = null) {
    let payload = {
      project_id: subtask.parentTask.id,
      subtask_id: subtask.id
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackSubtaskOverviewModalOpened > payload', payload);
    $window.analytics.track('Subtask Detail Opened', payload);
  };

  // Subtask Create New Opened
  const trackSubtaskCreateNewOpened = function (task = null, type) {
    let payload = {
      project_id: task.id,
      type: type
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackSubtaskCreateNewOpened > payload', payload);
    $window.analytics.track('Subtask Create New Opened', payload);
  };

  // Subtask Cancelled
  const trackSubtaskCancelled = function (subtask) {
    let payload = {
      project_id: subtask.parentTask.id,
      subtask_id: subtask.id
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackSubtaskCancelled > payload', payload);
    $window.analytics.track('Subtask Cancelled', payload);
  };

  // Payment Modal Opened
  const trackPaymentModalOpened = function (task, subtask = null) {
    let payload = {
      project_id: task ? task.id : null
    };

    if (subtask) {
      payload.project_id = subtask.parentTask.id;
      payload.subtask_id = subtask.id;
    }

    setGeneralData(payload);
    console.info('SegmentAnalytics > trackPaymentModalOpened > payload', payload);
    $window.analytics.track('Payment Modal Opened', payload);
  };

  // Expert Reviewed
  const trackExpertReviewed = function (task, reviewScore) {
    let payload = {
      project_id: task.id,
      review_score: reviewScore,
      expert_id: task.contractor.id
    };

    if (angular.isObject(reviewScore)) {
      payload.review_score = reviewScore.score;
      payload.review_score_responsiveness = reviewScore.responsiveness;
      payload.review_score_communication = reviewScore.communication;
    }
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackExpertReviewed > payload', payload);
    $window.analytics.track('Expert Reviewed', payload);
  };

  // Client Reviewed
  const trackClientReviewed = function (task, reviewScore) {
    let payload = {
      project_id: task.id,
      review_score: reviewScore,
      expert_id: task.contractor.id
    };

    if (angular.isObject(reviewScore)) {
      payload.review_score = reviewScore.score;
      // payload.review_score_responsiveness = reviewScore.responsiveness;
      // payload.review_score_communication = reviewScore.communication;
    }
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackClientReviewed > payload', payload);
    $window.analytics.track('Client Reviewed', payload);
  };

  // Workroom File
  const trackWorkroomFile = function (actionName, task) {
    if (!['Added', 'Removed', 'Modal Opened', 'Downloaded'].includes(actionName)) {
      console.log('SegmentAnalytics > trackWorkroomFile > FAILED > wrong action', actionName);
      return;
    }

    let payload = {
      project_id: task.id
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackWorkroomFile > payload', actionName, payload);
    $window.analytics.track(`Workroom File ${actionName}`, payload);
  };

  // Project description edited
  const trackProjectDescriptionEdited = function (project) {
    let payload = {
      project_id: project.id
    };
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackProjectDescriptionEdited > payload', payload);
    $window.analytics.track('Project Description Edited', payload);
  };

  // Vault
  const trackVault = function (actionName, task, expertsCount = null) {
    if (!['Created', 'Updated', 'Deleted', 'Unlocked', 'Content Viewed'].includes(actionName)) {
      console.log('SegmentAnalytics > trackVault > FAILED > wrong action', actionName);
      return;
    }

    let payload = {
      project_id: task.id
    };

    if (expertsCount) {
      payload.experts_count = expertsCount;
    }
    setGeneralData(payload);
    console.info('SegmentAnalytics > trackVault > payload', actionName, payload);
    $window.analytics.track(`Vault ${actionName}`, payload);
  };

  const trackMarkdownBarClicked = function (actionName, task) {
    if (!['bold', 'unordered-list', 'ordered-list', 'link', 'help'].includes(actionName)) {
      console.log('SegmentAnalytics > trackMarkdownBarClicked > FAILED > wrong action', actionName);
      return;
    }

    let payload = {
      project_id: task.id,
      label: actionName
    };

    setGeneralData(payload);
    console.info('SegmentAnalytics > trackMarkdownBarClicked > payload', actionName, payload);
    $window.analytics.track('Markdown Bar Clicked', payload);
  };

  const trackPriceCalculatorOpened = function (task) {
    let payload = {
      project_id: task.id
    };

    setGeneralData(payload);
    console.info('SegmentAnalytics > trackPriceCalculatorOpened > payload', payload);
    $window.analytics.track('Price Calculator Opened', payload);
  };

  const trackPriceCalculatorCalculated = function (task, estimateValue) {
    let payload = {
      project_id: task.id,
      estimate_value: estimateValue
    };

    setGeneralData(payload);
    console.info('SegmentAnalytics > trackPriceCalculatorCalculated > payload', payload);
    $window.analytics.track('Price Calculator Calculated', payload);
  };

  const trackWorkroomNotificationsToggled = function (task, state) {
    let payload = {
      project_id: task.id,
      state: state
    };

    setGeneralData(payload);
    console.info('SegmentAnalytics > trackWorkroomNotificationsToggled > payload', payload);
    $window.analytics.track('Workroom Notifications Toggled', payload);
  };

  const trackExpertProfileOpened = function (task, expertId) {
    let payload = {
      project_id: task ? task.id : null,
      expert_id: task && task.contractor ? task.contractor.id : expertId
    };

    setGeneralData(payload);
    console.info('SegmentAnalytics > trackExpertProfileOpened > payload', payload);
    $window.analytics.track('Expert Profile Opened', payload);
  };

  const trackClientProfileOpened = function (task, clientId) {
    let payload = {
      project_id: task ? task.id : null,
      client_id: task && task.client ? task.client.id : clientId
    };

    setGeneralData(payload);
    console.info('SegmentAnalytics > trackClientProfileOpened > payload', payload);
    $window.analytics.track('Client Profile Opened', payload);
  };

  // -----------------------------------
  // HELPERS

  // starts with
  function startsWith(string, partial) {
    return string.indexOf(partial) !== -1;
  }

  // notification type mapper
  function mapNotificationType(type) {
    let t = type;
    switch (type) {
      case 'other':
        t = 'pending';
        break;
      case 'important':
        t = 'in_progress';
        break;
      case 'new_projects':
        t = 'new_projects';
    }
    return t;
  }


  const getAnonymousId = () => {
    if (analytics && analytics.user && analytics.user.anonymousId) {
      return analytics.user().anonymousId()
    } else {
      console.log('[SegmentAnalytics] > segment not ready!!!')
    }

    // Fallback extraction from local storage
    let id = StorageService.nativeGet('ajs_anonymous_id')

    // Fallback extraction from cookie
    if (!id) {
      id = $cookies.get('ajs_anonymous_id') ? JSON.parse($cookies.get('ajs_anonymous_id')) : null
    }
    return id
  }



  return {
    getAnonymousId: getAnonymousId,

    pageview: pageview,

    identify: identify,

    // GENERAL EVENTS
    trackEvent: trackEvent,
    trackVimeoVideo: trackVimeoVideo,

    // NAVIGATION
    trackNavOpen: trackNavOpen,
    trackClientProgressDropdownOpened: trackClientProgressDropdownOpened,

    // NOTIFICATIONS
    trackNotificationDropdownOpen: trackNotificationDropdownOpen,
    trackNotificationmarkAllAsRead: trackNotificationmarkAllAsRead,
    trackNotificationClicked: trackNotificationClicked,

    // EXPERIMENT VIEWED
    trackExperimentViewed: trackExperimentViewed,

    // TASK SUBMISSION
    trackSubmissionFormLoaded: trackSubmissionFormLoaded,
    trackProjectTypeSelected: trackProjectTypeSelected,
    trackTaskInfoSubmitted: trackTaskInfoSubmitted,
    trackTaskPosted: trackTaskPosted,
    trackTaskPostingFailed: trackTaskPostingFailed,
    trackIntercomOpened: trackIntercomOpened,
    trackSubmissionFileAdded: trackSubmissionFileAdded,
    trackSubmissionFileAddedFailed: trackSubmissionFileAddedFailed,

    trackConsulationDetailsCompleted: trackConsulationDetailsCompleted,
    trackProjectDetailsCompleted: trackProjectDetailsCompleted,
    trackBudgetUrgencyCompleted: trackBudgetUrgencyCompleted,

    // TASK WORKROOM
    trackTaskBudgetChanged: trackTaskBudgetChanged,
    trackTaskCanceled: trackTaskCanceled,
    trackTaskConverted: trackTaskConverted,
    trackTaskReported: trackTaskReported,
    trackTaskHidden: trackTaskHidden,
    trackTaskUnhidden: trackTaskUnhidden,
    trackTaskFavorited: trackTaskFavorited,
    trackTaskUnfavorited: trackTaskUnfavorited,
    trackTasksFiltered: trackTasksFiltered,
    trackTaskEstimated: trackTaskEstimated,
    trackTaskConfirmed: trackTaskConfirmed,
    trackTaskCompleted: trackTaskCompleted,
    trackReviewCTAViewed: trackReviewCTAViewed,
    trackNpsSubmitted: trackNpsSubmitted,
    trackReviewCTAClicked: trackReviewCTAClicked,
    trackSubtaskPosted: trackSubtaskPosted,
    trackRetainerPosted: trackRetainerPosted,
    trackRetainerPayment: trackRetainerPayment,
    trackRetainerCancelled: trackRetainerCancelled,
    trackTaskSearch: trackTaskSearch,

    // TASK PAYMENT
    trackPayment: trackPayment,
    trackTaskPayment: trackTaskPayment,
    trackSubTaskPayment: trackSubTaskPayment,
    trackPaymillError: trackPaymillError,

    // REFERRAL
    trackReferralProgramViewed: trackReferralProgramViewed,

    // USER
    trackAccountCreated: trackAccountCreated,

    // Help center
    helpCenterClicked: helpCenterClicked,

    // Swag promotion
    settingsButtonClicked: settingsButtonClicked,
    swagPromotionViewed: swagPromotionViewed,
    swagPromotionAddAddressBtnClicked: swagPromotionAddAddressBtnClicked,
    swagPromotionHide: swagPromotionHide,

    // ESTIMATION / WORKROOM
    trackSubtaskOverviewModalOpened: trackSubtaskOverviewModalOpened,
    trackSubtaskDetailOpened: trackSubtaskDetailOpened,
    trackSubtaskCreateNewOpened: trackSubtaskCreateNewOpened,
    trackSubtaskCancelled: trackSubtaskCancelled,
    trackPaymentModalOpened: trackPaymentModalOpened,
    trackExpertReviewed: trackExpertReviewed,
    trackClientReviewed: trackClientReviewed,
    trackWorkroomFile: trackWorkroomFile,
    trackProjectDescriptionEdited: trackProjectDescriptionEdited,
    trackVault: trackVault,
    trackMarkdownBarClicked: trackMarkdownBarClicked,
    trackPriceCalculatorOpened: trackPriceCalculatorOpened,
    trackPriceCalculatorCalculated: trackPriceCalculatorCalculated,
    trackWorkroomNotificationsToggled: trackWorkroomNotificationsToggled,
    trackExpertProfileOpened: trackExpertProfileOpened,
    trackClientProfileOpened: trackClientProfileOpened,
    trackNavOpenHelp: trackNavOpenHelp
  };
}

app.service('SegmentAnalytics', ['$window', '$location', '$state', '$cookies', 'Configuration', 'StorageService', SegmentAnalytics]);

