// TODO: create User class with methods and migreate appropriate ones from service to that class

const UserService = class UserService {
  constructor ($http, $cookies, $window, Storage, Configuration, AuthTokenService, UtilService, User, StorageService) {
    'ngInject'
    this.$http = $http
    this.$cookies = $cookies
    this.$window = $window
    this.Storage = Storage
    this.Configuration = Configuration
    this.AuthTokenService = AuthTokenService
    this.UtilService = UtilService
    this.StorageService = StorageService
    this.User = User // TODO: refactor old User service and port it to UserService
  }

  isAuthenticated () {
    return Boolean(this.AuthTokenService.get() && this.get())
  }

  log () {
    console.log('[UserService] > token:', Boolean(this.AuthTokenService.get()), ', user:', Boolean(this.user))
  }

  // ---
  // TODO: verify if we can deprecate this and current user data lives only in memory
  // Save user object in LS
  save (user) {
    this.StorageService.set(this.Configuration.auth.userKey, user)
    console.log('[UserService] > save > NEW USER', user)
  }

  // Get user object from LS
  get () {
    return this.StorageService.get(this.Configuration.auth.userKey)
  }

  // Delete user object from LS
  destroy () {
    this.StorageService.destroy(this.Configuration.auth.userKey)
    console.log('[UserService] > destroy')
  }

  updateUser (partialUser) {
    this.UtilService.merge(this.user, partialUser)
    this.save(this.user)
    console.warn('Deprecated')
  }

  // TODO: Old alias for save function, depricate and remove from the app
  setUser (user) {
    console.warn('Deprecated')
    this.save(user)
  }

  // TODO: refactor and reduce number of methods used for the retreval of user object when refactoring the legacy auth. Create user class with support functions
  getUser () {
    console.warn('Deprecated')
    return this.user
  }
  // ---

  get user () {
    return this.get()
  }

  get me () {
    console.warn('Deprecated')
    return this.user
  }

  get userRole () {
    // currently we have multipe
    if (this.isAuthenticated) {
      if (this.isClient()) {
        return 'client'
      } else if (this.isExpert()) {
        return 'expert'
      }
    }

    return null
  }

  // TODO: move to Configuration and set it in application init/user data refresh or some other service
  get apiRoleSlug () {
    if (!this.isAuthenticated()) {
      return false
    }

    if (this.user.role === 'client') {
      return 'clients'
    }
    return 'experts'
  }

  get templateByUserRole () {
    if (!this.isAuthenticated()) {
      return false
    }

    if (this.user.role === 'client') {
      return 'client'
    }
    return 'expert'
  }

  get defaultRouterState () {
    const state = this.isClient ? this.Configuration.defaultStateClient : this.Configuration.defaultStateExpert

    return state
  }

  get canShowReminderModals () {
    return this.user && this.user.modalTooltipsEnabled
  }

  get isOnProbation () {
    return this.user && this.user.probation
  }


  getUserTimezone () {
    const user = this.getUser()
    if (this.user) {
      return Math.round(user.timezoneOffset / 60)
    }
    return 0
  }

  isClient () {
    return this.user ? this.user.role === 'client' : false
  }

  isVipClient () {
    return this.isClient() && this.user.completedTasksCount >= 10
  }

  isExpert () {
    return this.user ? (this.user.role === 'contractor' || this.user.role === 'expert') : false
  }

  isUserHiredExpertOnProject (project) {
    return this.isExpert() && project && this.user.id === Number(project.contractor.id)
  }

  isUserClientOnProject (project) {
    return this.isClient() && project && this.user.id === Number(project.client.id)
  }

  isUserPreferredExpertOnProject (project) {
    return this.isExpert() && project && project.currentUserIsPreferredContractor
  }



  isEstimator (taskEstimators) {
    return this.isExpert() && typeof taskEstimators.find(estimator => estimator.id === this.getUser().id) !== 'undefined'
  }

  hasCompletedPersonaSurvey () {
    return this.me && Boolean(this.me.trackedSegmentByLink)
  }

  updatePersonaSurvey (data) {
    const payload = {
      segment: data
    }
    return this.$http
      .put(`${this.Configuration.apiUrl}/users/me/segment`, payload)
  }

  updateCouponCode (couponCode) {
    let payload = {
      coupon_code: couponCode
    }
    return this.$http
      .post(`${this.Configuration.apiUrl}/users/me/coupons`, payload)
  }

  async fetchUser () {
    return this.$http
      .get(`${this.Configuration.apiUrl}/users/me`)
      .then(response => response.data)
  }

  getAutoDisplayModalsList () {
    return this.$http
      .get(`${this.Configuration.apiUrl}/${this.apiRoleSlug}/auto_display_modals`) // TODO: replace with feature endpoint when BE supports it
      .then(response => response.data)
  }

  // TODO: deprecate this
  updateFeatureList () {
    return this.$http
      .get(`${this.Configuration.apiUrl}/users/me`) // TODO: replace with feature endpoint when BE supports it
      .then(response => {
        console.log('[UserService] > updateFeatureList > response', response.data)
        this.updateUser(response.data)
      })
  }

  // TODO: deprecate this
  isFeatureActive (featureName) {
    return this.user && this.user.featureRules && this.user.featureRules.includes(featureName)
  }

  /**
   * Check if user with one project was shown first project info page otherwise flag it and return false to show info page
   * @return {Boolean}
   */
  showFirstProjectPage () {
    const flag = this.Storage.get('firstProjectFlag')
    if (flag !== true && this.isClient() && this.user.tasksCount === 1) {
      return true
    }
    return false
  }

  getFirstProjectFlag () {
    return this.Storage.get('firstProjectFlag')
  }

  // TODO: already migrated to SegmentAnalyitics service, remove from user service
  get SegmentAnalyticsAnonymousId () {
    let id = this.StorageService.nativeGet('ajs_anonymous_id')

    // fallback to cookie
    if (!id) {
      id = this.$cookies.get('ajs_anonymous_id') ? JSON.parse(this.$cookies.get('ajs_anonymous_id')) : null
    }
    return id
  }
  // ----

  setFirstProjectFlag (taskData) {
    this.Storage.set('firstProjectFlag', taskData)
  }

  clearFirstProjectFlag () {
    this.Storage.set('firstProjectFlag', true)
  }
   /**
   * Subscribe current user for project notifications
   * @param  {object} task
   * @return {object} Promise
   */
  userProjectSubscribe (task) {
    const payload = {
      task_id: task.id
    }
    return this.$http.put(`${this.Configuration.apiUrl}/users/me/tasks/project-subscriptions`, payload).then(() => {
      task.subscribedByCurrentUser = true
    })
  }
  /**
   * Unsubscribe current user for project notifications
   * @param  {object} task
   * @return {object} Promise
   */
  userProjectUnsubscribe (task) {
    const payload = {
      id: task.id
    }
    return this.$http.delete(`${this.Configuration.apiUrl}/users/me/tasks/project-subscriptions/${task.id}`, payload).then(() => {
      task.subscribedByCurrentUser = false
    })
  }

  /**
   * Show public profile of client or expert
   * @param  {object} task
   */
  showProfile () {
    this.User.showProfile(null, this.user.id, this.user.role) // TODO: refactor showProfile and port it to UserService
  }

  /**
   * Return the wallet for the current user
   *
   * @return {object} wallet
   */
  userWallet () {
    const user = this.user
    let wallet = false

    console.log('userWallet > walletEnabled', user)
    if (user.walletEnabled) {
      wallet = {
        enabled: user.walletEnabled,
        currentBalance: user.walletBalance
      }
      console.log('userWallet > walletEnabled > true', wallet)
    };

    return wallet
  }


  handleFallbackNotificationSettings () {
    // Inject default notification settings if not exisitng on current user
    if (this.isAuthenticated() && ((typeof this.user.notificationSettings === 'undefined') || (typeof this.user.notificationSettings.sounds === 'undefined'))) {
      console.log('user object is missing notification settings >  inject default settings')
      this.updateUser({
        notificationSettings: this.Configuration.features.user.notificationSettings
      })
    }
  }
}
export default UserService
