import templateUrl from './settings.html'
import './settings.scss'

const SettingsComponent = {
  bindings: {},
  templateUrl,
  controller: class Settings {
    constructor ($timeout, UIService, UserService, SettingsService, SettingsExpertTeamService) {
      'ngInject'
      this._identify = 'SettingsComponent'
      this.$timeout = $timeout
      this.UIService = UIService
      this.UserService = UserService
      this.SettingsService = SettingsService
      this.SettingsExpertTeamService = SettingsExpertTeamService
    }


    async $onInit () {
      this.isSidebarReady = false
      this.settingsMenu = [
        {
          sref: 'settings.account',
          title: 'Account',
          visible: true,
        },
        {
          sref: 'settings.availability',
          title: 'Availability',
          visible: this.UserService.isExpert() // Expert specific
        },
        {
          sref: 'settings.notifications',
          title: 'Notifications',
          visible: true
        },
        {
          sref: 'settings.vault',
          title: 'Vault',
          visible: this.UserService.isExpert() // Expert specific
        },
        {
          sref: 'settings.invoices',
          title: 'Invoices',
          visible: this.UserService.isClient() // Client specific
        },
        {
          sref: 'settings.comms-preferences',
          title: 'Communication & Privacy',
          visible: this.UserService.isClient() // Client specific
        },
        {
          sref: 'settings.expert-team',
          title: 'Team & Team Members',
          visible: false // Expert specific, visible only if enabled via admin
        },
      ]


      // If expert check if expert can see the Team & Team Members section
      if (this.UserService.isExpert()) {
        await this.SettingsExpertTeamService.getExpertTeamSettings()
          .then(response => {
            console.log('response', response)
            const menuItem = this.settingsMenu.find(menuItem => menuItem.sref === 'settings.expert-team')
            menuItem.visible = true
          })
          .catch(err => {
            console.log('err', err)
          })
        this.isSidebarReady = true
        this.$timeout()
      } else {
        this.isSidebarReady = true
      }
    }

    closeSidebar () {
      this.sidebar = this.UIService.getComponent('sidebar-main-left')
      if (this.sidebar) {
        this.sidebar.close()
      }
    }
  }
}

export default SettingsComponent
