const cdblTrackDirective = function (SegmentAnalytics, $parse) {
  'ngInject'
  return {
    restrict: 'A',
    scope: {
      cdblTrack: '@?',
      cdblTrackAction: '@?',
      cdblTrackPayload: '<?'
    },
    // scope: false,
    link: (scope, element, attrs) => {
      // console.log('[cdblTrackDirective] > attrs', attrs)
      let eventName = scope.cdblTrack

      if (typeof scope.cdblTrackPayload === 'undefined') {
        scope.cdblTrackPayload = {}
      }

      if (eventName === '' || typeof eventName === 'undefined') {
        eventName = 'Unnamed event'
      }

      function track () {
        console.log('cdblTrackDirective', eventName, scope.cdblTrackAction, scope.cdblTrackPayload)
        SegmentAnalytics.trackEvent(eventName, scope.cdblTrackPayload)
      }


      if (typeof scope.cdblTrackAction === 'undefined') {
        scope.cdblTrackAction = 'click'
      } else if (scope.cdblTrackAction === 'hover') {
        scope.cdblTrackAction = 'mouseenter'
      } else if (scope.cdblTrackAction === 'show') {
        track()
        return // do not bind to element event
      }


      element.on(scope.cdblTrackAction, track)
      scope.$on('$destroy', function () {
        element.off(scope.cdblTrackAction, track)
      })

      // ALTERNATIVE WITH NONISOLATED SCOPE - untested
      // const options = {
      //   event: 'Unnamed event',
      //   action: 'click',
      //   payload: {}
      // }
      // if (typeof attrs.cdblTrack !== 'undefined' && attrs.cdblTrack !== '') {
      //   options.event = attrs.cdblTrack
      // }
      // if (typeof attrs.cdblTrackAction !== 'undefined' && attrs.cdblTrackAction !== '') {
      //   options.action = attrs.cdblTrackAction === 'hover' ? 'mouseenter' : attrs.cdblTrackAction
      // }
      // if (typeof attrs.cdblTrackPayload !== 'undefined' && attrs.cdblTrackPayload !== '') {
      //   options.payload = $parse(attrs.cdblTrackPayload)
      // }
      // function track() {
      //   console.log('cdblTrackDirective', options.event, options.action, options.payload)
      //   SegmentAnalytics.trackEvent(options.event, options.payload)
      // }
      // element.on(options.action, track)
      // scope.$on('$destroy', function () {
      //   element.off(options.action, track)
      // })
    }
  }
}

export default cdblTrackDirective
