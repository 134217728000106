import templateUrl from './submission-step-one.html'
import './submission-step-one.scss'
// import submissionConfig from './../submission.config';

const SubmissionStepOneComponent = {
  bindings: {
    config: '<',
    data: '<',
    callbacks: '<'
  },
  require: {
    parent: '^cdblSubmission'
  },
  templateUrl,
  controller: class SubmissionStepOne {
    constructor (IntercomService, SegmentAnalytics) {
      'ngInject'
      this.IntercomService = IntercomService
      this.segmentAnalytics = SegmentAnalytics
      this.name = 'submission-step-one'
      this.title = 'Submit your project in less than 10 minutes'
      this.banner_options = []
      this.main_options = []
    }

    filterTypesByPosition (types, position) {
      return types.filter(type => {
        return type.position === position
      })
    }

    $onInit () {
      this.init()
    }

    $onChanges (changesObj) {
      console.log('SubmissionStepOneComponent> $onChanges', changesObj)
      this.init()
    }

    init () {
      this.main_options = this.filterTypesByPosition(this.config.types, 'main_options')
      this.banner_options = this.filterTypesByPosition(this.config.types, 'banner_options')

      const defaultContent = {
        title: `Submit your project in less than 10 minutes`,
        introduction: `<h2>Get quickly matched with high-quality specialists who are available to help.</h2>
            To begin, choose a {{ $ctrl.parent.submissionConfig.displayName }} category below or if you can't find a fit here toggle to our <span class="t-bold">regular</span> Codeable project submission form.`
      }

      const partnerContent = this.parent.submissionConfig.content ? this.parent.submissionConfig.content.stepone : {}
      this.content = Object.assign(defaultContent, partnerContent)
    }

    askForHelp () {
      this.IntercomService.showMessage('Hi! I would like to post a new project and I need help.')
      this.segmentAnalytics.trackIntercomOpened('step1')
    }

    getSubject (selectedProjectSubject) {
      let obj = this.config.types.find(configItem => {
        return configItem.project_subject === selectedProjectSubject
      })
      return obj ? obj.name : null
    }

    callbackInit () {

    }
  }
}

export default SubmissionStepOneComponent
