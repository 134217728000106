(function () {
  'use strict';

  function GoogleExperimentsCtrl($scope) {
    $scope.variation = 1;

    // googleExperiments.getVariation().then(function (variation) {
    //   $scope.variation = variation;
    // });
  }

  app.controller('GoogleExperimentsCtrl', ['$scope', GoogleExperimentsCtrl]);
})();
