import ExpertProfileSkillsAndExpertiseModule from './expert-profile-skills-and-expertise'
import ExpertProfileReviewsModule from './expert-profile-reviews'
import ExpertProfilePortfolioModule from './expert-profile-portfolio'
import ExpertProfileAboutModule from './expert-profile-about'
import ExpertProfileViewModalComponent from './expert-profile-view-modal.component'
import ExpertProfileViewModalService from './expert-profile-view-modal.service'

const ExpertProfileViewModalModule = angular.module('cdbl.expert-profile-view-modal', [
  ExpertProfileSkillsAndExpertiseModule,
  ExpertProfileReviewsModule,
  ExpertProfilePortfolioModule,
  ExpertProfileAboutModule,
])
  .service('ExpertProfileViewModalService', ExpertProfileViewModalService)
  .component('cdblExpertProfileViewModal', ExpertProfileViewModalComponent)
  .name

export default ExpertProfileViewModalModule
