import templateUrl from './tag.html'
import './tag.scss'

const TagComponent = {
  bindings: {
    onRemove: '&'
  },
  templateUrl,
  transclude: true,
  controller: class TagComponent {
    constructor ($attrs, $element) {
      'ngInject'
      this._identify = 'TagComponent'
      this.$attrs = $attrs
      this.$element = $element
    }

    $onInit () {
      if (!this.hasAction) {
        this.$element.addClass('tag--view-only')
      }
    }

    get hasAction () {
      return ('onRemove' in this.$attrs)
    }
  }
}

export default TagComponent
