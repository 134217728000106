const Barn2UKSubmissionPresets = {
  'barn2uk_woo_product_table_plugin': {
    'installation': {
      description: `NOTICE: The fixed budget for this project type is based on the requirement as pre-defined exactly below. If you edit these requirements in the description, or your project has additional requirements, this could affect the estimate you receive. You have the opportunity to discuss this with Codeable expert before you decide to hire.

### Before you start

Before you start, you should [purchase the WooCommerce Product Table plugin](https://barn2.co.uk/wordpress-plugins/woocommerce-product-table/). Your Codeable developer will need the following:

-   The plugin files and your license key. You can download these from the order confirmation page. You will also receive them by email.
-   Your WordPress login URL, username and password.
-   Details of where you would like the product table to be added to your site, and how you would like it to work. Please include as much information as possible, including a list of table columns, the wording for the column headers, whether or not you require an add to cart column/quantity picker, how you would like variations to work in the table, required filters, etc.

### Included in the fixed price quote

-   Install the plugin and activate your license key.
-   Add up to 5 product tables, each displaying different products. This assumes that you have already added your products to your store, along with all the data that you wish to display in a table.
-   The tables can be added using either of these methods:
-   To a normal page on your site, or;
-   Directly to the archive-product.php template file in your theme using the [sample template](https://barn2.co.uk/kb/wpt-shop-category-search-template/) provided by Barn2. With this method, the product table layout will replace the default grid layout on your shop page, all your category and sub-category pages, etc. The price includes creating a child theme, if this is needed to ensure that you can still update the theme in future.
-   Use the [features and options](https://barn2.co.uk/kb/product-table-options/) available in WooCommerce Product Table to configure the tables according to your requirements.

### Not included in the fixed price quote

-   Adding products or additional custom fields or data.
-   Customizing the plugin beyond its intended use.
-   Any additional styling or fixing theme-specific issues.
-   Customizing the archive-product.php template.

All of the above can be quoted for separately, either as part of the agreed work or as a separate job.`
    },

    'integration': {
      description: `NOTICE: The fixed budget for this project type is based on the requirement as pre-defined exactly below. If you edit these requirements in the description, or your project has additional requirements, this could affect the estimate you receive. You have the opportunity to discuss this with Codeable expert before you decide to hire.

The easiest way to use WooCommerce Product Table is to add the [product_table] shortcode to a normal page in WordPress. If you prefer, you can add the product table layout directly to the template file in your theme so that it is automatically used on all your store pages, such as the shop page and all your category pages. This will overwrite the default store layouts on your site.

This job is a fixed price quote to do this by installing the [sample template](https://barn2.co.uk/kb/wpt-shop-category-search-template/) provided by Barn2.

### Before you start

Before you start, you should [purchase the WooCommerce Product Table plugin](https://barn2.co.uk/wordpress-plugins/woocommerce-product-table/), install it on your site, and choose your product table options on the plugin settings page. Your Codeable developer will need the following:

-   Your WordPress login URL, username and password.
-   Your FTP logins.

### Included in the fixed price quote

-   Use the [sample template](https://barn2.co.uk/kb/wpt-shop-category-search-template/) provided by Barn2 to override the default store layouts and automatically add product tables to your shop page, all your category and sub-category pages, and any other pages that use the archive-product.php template in your theme.
-   Create a child theme, if this is needed to ensure that you can still update the theme in future without overwriting the custom template.

### Not included in the fixed price quote

-   Installing and setting up WooCommerce Product Table.
-   Customizing the plugin beyond its intended use.
-   Customizing the archive-product.php template.

All of the above can be quoted for separately, either as part of the agreed work or as a separate job.`
    }
  },

  'barn2uk_posts_table_pro_plugin': {
    'basic_installation': {
      description: `NOTICE: The fixed budget for this project type is based on the requirement as pre-defined exactly below. If you edit these requirements in the description, or your project has additional requirements, this could affect the estimate you receive. You have the opportunity to discuss this with Codeable expert before you decide to hire.

### Summary

This basic installation service includes setting up the Posts Table Pro plugin to display information already stored on your WordPress site in a table layout. Please see the 'Advanced' version of this job if you would like your Codeable developer to set up the initial infrastructure for your tables, such as creating a custom post type and extra fields to store the data.

### Before you start

Before you start, you should [purchase the Posts Table Pro plugin](https://barn2.co.uk/wordpress-plugins/posts-table-pro/). Your Codeable developer will need the following:

-   The plugin files and your license key. You can download these from the order confirmation page. You will also receive them by email.
-   Your WordPress login URL, username and password.
-   Details of where you would like the table to be added to your site, and how you would like it to work. Please provide as much information as possible about your requirements, including a list of table columns, the wording for the column headers, whether or not you require any links in the table, required filters, etc.

### Included in the fixed price quote

-   Install the plugin and activate your license key.
-   Add up to 5 tables, each displaying different posts/pages/custom posts. This assumes that your WordPress site already contains the information that you wish to display.
-   The tables can be added using either of these methods:
-   To a normal page on your site, or;
-   Directly to the category archive template file in your theme using the [sample template](https://barn2.co.uk/kb/post-tables-archive-pages/) provided by Barn2. With this method, the posts table layout will replace the default grid layout on your main blog page, blog categories, and any custom post type category pages that use this template. The price includes creating a child theme, if this is needed to ensure that you can still update the theme in future.
-   Use the [features and options](https://barn2.co.uk/kb/posts-table-options/) available in Posts Table Pro to configure the tables according to your requirements.

### Not included in the fixed price quote

-   Data entry, such as adding posts or custom posts.
-   Creating custom post types, custom fields and taxonomies.
-   Customizing the plugin beyond its intended use.
-   Any additional styling or fixing theme-specific issues.
-   Customizing the category archive template.

All of the above can be quoted for separately, either as part of the agreed work or as a separate job. Please see the separate fixed price quote for installing Posts Table Pro and creating your custom post types.`
    },

    'advanced_installation': {
      description: `NOTICE: The fixed budget for this project type is based on the requirement as pre-defined exactly below. If you edit these requirements in the description, or your project has additional requirements, this could affect the estimate you receive. You have the opportunity to discuss this with Codeable expert before you decide to hire.

This advanced installation service includes adding an extra content type to your WordPress site (e.g. a 'Documents' post type) and displaying the information in a table (e.g. as a document library).

### Before you start

Before you start, you should [purchase the Posts Table Pro plugin](https://barn2.co.uk/wordpress-plugins/posts-table-pro/). Your Codeable developer will need the following:

-   The plugin files and your license key. You can download these from the order confirmation page. You will also receive them by email.
-   Your WordPress login URL, username and password.
-   Details of where you would like the table to be added to your site, and how you would like it to work. Please provide as much information as possible about your requirements, including a list of table columns, the wording for the column headers, whether or not you require any links in the table, required filters, etc.
-   If you would like to implement the plugin according to one of the tutorials in the Barn2 blog (e.g. [How to Create a WordPress Document Library](https://barn2.co.uk/wordpress-document-library-plugin/)), please also provide a link to this tutorial.

### Included in the fixed price quote

-   Install the plugin and activate your license key.
-   Create a custom post type using a free plugin such as [Pods](https://en-gb.wordpress.org/plugins/pods/) or [WordPress Download Manager](https://en-gb.wordpress.org/plugins/download-manager/). This will be used to store the information that you will display using Posts Table Pro, such as documents, audio, video, or members.
-   Create any custom fields or taxonomies that are required to store the extra data that will be displayed in the table. This will be done using free plugins such as [Pods](https://en-gb.wordpress.org/plugins/pods/) or [Advanced Custom Fields](https://en-gb.wordpress.org/plugins/advanced-custom-fields/).
-   Add some sample data to the custom post type.
-   Add up to 5 tables, each displaying the sample data created above.
-   The tables can be added using either of these methods:
-   To a normal page on your site, or;
-   Directly to the category archive template file in your theme using the [sample template](https://barn2.co.uk/kb/post-tables-archive-pages/) provided by Barn2. With this method, the posts table layout will replace the default grid layout on your main blog page, blog categories, and any custom post type category pages that use this template. The price includes creating a child theme, if this is needed to ensure that you can still update the theme in future.
-   Use the [features and options](https://barn2.co.uk/kb/posts-table-options/) available in Posts Table Pro to configure the tables according to your requirements.
-   If required, the job can also include the following additional tasks:
-   Using [these instructions](https://barn2.co.uk/kb/ptp-links-downloads-buttons/) to add buttons, links or clickable icons to the table.
-   Using the free [Page Links To](https://wordpress.org/plugins/page-links-to/) plugin to link the items in the table to another URL, such as a downloadable document.

### Not included in the fixed price quote

-   Data entry, such as adding posts or custom posts.
-   Customizing the plugin beyond its intended use.
-   Any additional styling or fixing theme-specific issues.
-   Customizing the category archive template.

All of the above can be quoted for separately, either as part of the agreed work or as a separate job.`
    }
  },

  'barn2uk_woo_private_store_plugin': {
    'installation': {
      description: `NOTICE: The fixed budget for this project type is based on the requirement as pre-defined exactly below. If you edit these requirements in the description, or your project has additional requirements, this could affect the estimate you receive. You have the opportunity to discuss this with Codeable expert before you decide to hire.

### Before you start

Before you start, you should [purchase the WooCommerce Private Store plugin](https://barn2.co.uk/wordpress-plugins/woocommerce-private-store/). Your Codeable developer will need the following:

-   The plugin files and your license key. You can download these from the order confirmation page. You will also receive them by email.
-   Your WordPress login URL, username and password.
-   Details of whether you would like people to be able to unlock the store by entering a password, and/or by logging into their user account.
-   Details of where you would like the login page to appear on your site, and any text to appear on it.

### Included in the fixed price quote

-   Install the plugin and activate your license key.
-   Use WooCommerce Private Store to hide all your store content from the public areas of your site.
-   Create a login page, either by configuring the password entry page that comes with the plugin, or using a free plugin such as [Theme My Login](https://wordpress.org/plugins/theme-my-login/) to create a front end user login page.

### Not included in the fixed price quote

-   Customizing the plugin beyond its intended use.
-   Any additional styling or fixing theme-specific issues.

All of the above can be quoted for separately, either as part of the agreed work or as a separate job.`
    }
  },

  'barn2uk_woo_protected_categories_plugin': {
    'installation': {
      description: `NOTICE: The fixed budget for this project type is based on the requirement as pre-defined exactly below. If you edit these requirements in the description, or your project has additional requirements, this could affect the estimate you receive. You have the opportunity to discuss this with Codeable expert before you decide to hire.

### Before you start

Before you start, you should [purchase the WooCommerce Protected Categories plugin](https://barn2.co.uk/wordpress-plugins/woocommerce-protected-categories/). Your Codeable developer will need the following:

-   The plugin files and your license key. You can download these from the order confirmation page. You will also receive them by email.
-   Your WordPress login URL, username and password.
-   Details of which categories you would like to protect, and how you wish to protect them. Categories can be password protected with 1 or more passwords, or restricted so that only specific user roles or individual users can access them. You can mix and match these options as needed.
-   Whether or not you would like the protected categories and their products to be visible in the public areas of your site.
-   Whether you would like a central login page.

### Included in the fixed price quote

-   Install the plugin and activate your license key.
-   Use WooCommerce Protected Categories to protect up to 10 categories. This can include protecting your existing categories, or creating new ones.
-   Create a login page, either by using the central login page for your password protected categories that comes with the plugin; or by linking to the WooCommerce 'Account' page which functions as a front end user login form.

### Not included in the fixed price quote

-   Customizing the plugin beyond its intended use.
-   Any additional styling or fixing theme-specific issues.

All of the above can be quoted for separately, either as part of the agreed work or as a separate job.`
    }
  },


  'barn2uk_password_protected_categories_plugin': {
    'installation': {
      description: `NOTICE: The fixed budget for this project type is based on the requirement as pre-defined exactly below. If you edit these requirements in the description, or your project has additional requirements, this could affect the estimate you receive. You have the opportunity to discuss this with Codeable expert before you decide to hire.

### Before you start

Before you start, you should [purchase the Password Protected Categories plugin](https://barn2.co.uk/wordpress-plugins/password-protected-categories/). Your Codeable developer will need the following:

-   The plugin files and your license key. You can download these from the order confirmation page. You will also receive them by email.
-   Your WordPress login URL, username and password.
-   Details of which categories you would like to protect, and how you wish to protect them. Categories can either be password protected with 1 or more passwords, or made private so that only logged in users with a specific role can access them.
-   Whether or not you would like the protected categories and their contents to be visible in the public areas of your site.
-   Whether you would like a central login page.

### Included in the fixed price quote

-   Install the plugin and activate your license key.
-   Use Password Protected Categories to protect up to 10 categories. This can include protecting your existing categories, or creating new ones.
-   If you are using the private categories option, then the job can include using the free [User Role Editor](https://en-gb.wordpress.org/plugins/user-role-editor/) plugin to modify your user roles to control which roles can access the private categories and their posts.
-   Create a login page, either by using the central login page for your password protected categories that comes with the plugin; or by using a free plugin such as [Theme My Login](https://wordpress.org/plugins/theme-my-login/) to create a front end user login page.

### Not included in the fixed price quote

-   Customizing the plugin beyond its intended use.
-   Any additional styling or fixing theme-specific issues.

All of the above can be quoted for separately, either as part of the agreed work or as a separate job.`
    }
  }
}

export default Barn2UKSubmissionPresets
