import templateUrl from './payments-modal.html'
import './payments-modal.scss'

const ProjectTeamPaymentsModalComponent = {
  bindings: {
    project: '<',
    team: '<',
    onPaymentCreated: '&'
  },
  require: {
    modalCtrl: '^^cdblModal'
  },
  templateUrl,
  controller: class ProjectTeamPaymentsModal {
    constructor (ProjectTeamService, EventEmitter, UserService) {
      'ngInject'
      this._identify = 'ProjectTeamPaymentsModalComponent'
      this.ProjectTeamService = ProjectTeamService
      this.EventEmitter = EventEmitter
      this.UserService = UserService
    }

    $onInit () {
      this.steps = ['list', 'details', 'payment-form']
      this.step = 'list'
      this.resourcesList = []
      this.selectedResource = null

      this.resetPaymentForm()

      this.isLoading = true
      this.ProjectTeamService.fetchTeamPayments(this.project.id)
        .then(response => {
          this.resourcesList = response
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    get memberList () {
      return this.team ? this.team.teamMembers : []
    }

    resourceAmountLeft (resource) {
      if (resource && resource.teamLeadEarnings && resource.payments) {
        const amountTransfered = resource.payments.reduce((sum, currentItem) => sum + currentItem.amount, 0)
        return Number((resource.teamLeadEarnings - amountTransfered).toFixed(2))
      }
      return 0
    }

    goToStep (step) {
      console.log('[ProjectTeamPaymentsModalComponent] > goToStep', step)
      const availableSteps = ['list', 'details', 'payment-form']
      if (availableSteps.includes(step)) {
        this.step = step
      }

      if (step === 'list') {
        this.selectedResource = null
      }
    }

    goBack () {
      if (this.step === 'payment-form') {
        this.resetPaymentForm()
        this.goToStep('details')
        return
      }

      this.resetSelectedResource()
      this.goToStep('list')
    }

    resetPaymentForm () {
      this.formData = {
        note: null,
        amount: null,
        recipient: null
      }
    }

    resetSelectedResource () {
      this.selectedResource = null
    }

    openResourceDetails (selectedResource, $event) {
      if ($event) {
        $event.stopPropagation()
        $event.preventDefault()
      }
      this.selectedResource = selectedResource
      this.goToStep('details')
    }

    openResourcePaymentForm (selectedResource, $event) {
      if ($event) {
        $event.stopPropagation()
        $event.preventDefault()
      }

      if (selectedResource) {
        this.selectedResource = selectedResource
      }
      this.resetPaymentForm()
      this.goToStep('payment-form')
    }

    cancelPayment () {
      this.goToStep('details')
    }

    submitPayment () {
      this.isLoading = true
      this.ProjectTeamService
        .createTeamPayment(this.project.id, {
          amount: this.formData.amount,
          note: this.formData.note,
          recipientId: this.formData.recipient.expert.id,
          resource: this.selectedResource
        })
        .then(response => {
          console.log('[ProjectTeamPaymentsModalComponent] > submitPayment > createTeamPayment > response', response)
          // update resource with payment data
          const resource = this.resourcesList.find(r => r.id === response.resourceId)
          if (resource && Array.isArray(resource.payments)) {
            resource.payments.push(response)
          }
          console.log('[ProjectTeamPaymentsModalComponent] > submitPayment > 1')

          // callback to execute to update parent component
          if (this.onPaymentCreated) {
            this.onPaymentCreated(this.EventEmitter({ payment: response }))
          }

          console.log('[ProjectTeamPaymentsModalComponent] > submitPayment > 2')
          this.resetPaymentForm()
          console.log('[ProjectTeamPaymentsModalComponent] > submitPayment > 3')
          this.goToStep('details')
          console.log('[ProjectTeamPaymentsModalComponent] > submitPayment > 4')
        })
        .finally(() => {
          this.isLoading = false
        })
    }


    get detailsStepTitle () {
      return `${this.selectedResource.type === 'Task' ? 'Main project' : 'Additional task'} details`
    }

    showNewPaymentButton (resource) {
      return (this.resourceAmountLeft(resource) > 0)
    }
  }
}

export default ProjectTeamPaymentsModalComponent
