import SubmissionStepOneComponent from './submission-step-one.component'
import SubmissionStepOneOptionModule from './submission-step-one-option/submission-step-one-option.module'
import SubmissionStepOneBannerModule from './submission-step-one-banner/submission-step-one-banner.module'

const SubmissionStepOneModule = angular.module('cdbl.submission-step-one', [
  SubmissionStepOneOptionModule,
  SubmissionStepOneBannerModule
])
  .component('cdblSubmissionStepOne', SubmissionStepOneComponent)
  .name

export default SubmissionStepOneModule
