(function () {
  'use strict';

  function states($stateProvider, $urlRouterProvider) {
    $stateProvider.state('auth', {
      abstract: true,
      url: '/auth',
      template: '<ui-view> </ui-view>',

      // redirect to dashboard if the user is already logged in (goes for any auth state)
      controller: ['UserService', '$state', 'Configuration', function (UserService, $state, Configuration) {
        if (UserService.isAuthenticated()) {
          $state.go(Configuration.defaultRouterState.name, Configuration.defaultRouterState.options);
        }
      }]
    })
    .state('auth.sign_in', {
      url: '^/sign_in?redirect&token&unlock_token',
      templateUrl: require('../../views/auth/auth_sign_in.html'),
      controller: 'SignInCtrl'
    })
    .state('auth.register', {
      url: '^/register',
      templateUrl: require('../../views/auth/auth_register.html'),
      controller: 'RegisterCtrl'
    })
    .state('auth.reset_password', {
      url: '^/reset_password?reset_password_token',
      templateUrl: require('../../views/auth/auth_lost_password.html'),
      controller: 'LostPasswordCtrl'
    });

    $urlRouterProvider.otherwise('/sign_in');
  }

  app.config(['$stateProvider', '$urlRouterProvider', states]);
})();
