(function () {
  'use strict';

  function vault(Modal, Vault, TaskResource, Auth, UserService) {
    var link = function ($scope) {
      $scope.showPassword = false;
      $scope.decryValue = '';

      $scope.currentUser = UserService.user;

      $scope.vault = $scope.data.vault;

      if ($scope.vault.encData) {
        $scope.vault.encData = JSON.parse($scope.vault.encData);
      }

      $scope.accessVault = function () {
        $scope.decryValue = Vault.openAsClient($scope.task.id, $scope.vault);
        console.log('accessVault', $scope.task.id, $scope.decryValue);

        if ($scope.decryValue) {
          Modal.open('views/modals/vault_update.html', $scope, {closeButton: false});
        } else {
          Modal.open('views/modals/vault_set_password.html', $scope, {closeButton: false});
        }
      };

      $scope.updateVault = function (newVault) {
        if (newVault || Vault.loadVaultKeyFromLS($scope.task.id)) {
          try {
            $scope.decryValue = Vault.openAsClient($scope.task.id, $scope.vault, false);
          } catch (err) {
            Modal.open('views/modals/vault_set_password.html', $scope, {closeButton: false});
            return;
          }

          Modal.open('views/modals/vault_update.html', $scope, {closeButton: false});
        } else {
          Modal.open('views/modals/vault_set_password.html', $scope, {closeButton: false});
        }
      };

      $scope.removeVault = function () {
        Modal.open('views/modals/vault_remove.html', $scope, {closeButton: false});
      };

      $scope.setPassword = function () {
        Modal.open('views/modals/vault_set_password.html', $scope, {closeButton: false});
      };

      $scope.openVaultAsClient = function (taskId, vaultData, openModal) {
        console.log('[vaultDir] > openVaultAsClient', taskId, vaultData, openModal);
        $scope.decryValue = Vault.openAsClient(taskId, vaultData);

        if (!$scope.decryValue && openModal) {
          Modal.open('views/modals/vault_set_password.html', $scope, {closeButton: false});
        } else {
          $scope.showPassword = true;
        }
      };

      $scope.openVaultAsContractor = function (taskId, vaultData, openModal) {
        console.log('[vaultDir] > openVaultAsContractor', taskId, vaultData, openModal);
        $scope.wrongKey = false;

        try {
          $scope.decryValue = Vault.openAsContractor(taskId, vaultData);
          console.log('[vaultDir] > openVaultAsContractor > 2');
        } catch (err) {
          console.log('[vaultDir] > openVaultAsContractor > 3');
          if (openModal) {
            Modal.open('views/modals/vault_set_key.html', $scope, {closeButton: false});
            return;
          }
        }

        if ($scope.decryValue) {
          $scope.showPassword = true;
          console.log('[vaultDir] > openVaultAsContractor > 4');
        } else if (openModal) {
          console.log('[vaultDir] > openVaultAsContractor > 5');
          Modal.open('views/modals/vault_set_key.html', $scope, {closeButton: false});
          return;
        }
      };

      $scope.hideVault = function () {
        $scope.showPassword = false;
      };

      $scope.updatePassword = function () {
        Modal.open('views/modals/vault_set_password.html', $scope, {closeButton: false});
      };

      $scope.$on('vault-created', function () {
        updateVault();
      });

      $scope.$on('vault-updated', function () {
        updateVault();
      });

      $scope.$on('vault-removed', function () {
        destroyVault();
      });

      $scope.$on('vault-destroyed', function () {
        destroyVault();
      });

      function destroyVault() {
        $scope.vault = null;
      }

      function updateVault() {
        TaskResource.getTask($scope.task.id, ['vault']).then(function (data) {
          $scope.vault = data.vault;
          $scope.data.vault = data.vault;

          if ($scope.vault.encData) {
            $scope.vault.encData = JSON.parse($scope.vault.encData);

            if (Auth.me().role === 'contractor' && $scope.showPassword) {
              $scope.decryValue = Vault.openAsContractor($scope.task.id, $scope.vault);
            }
          }
        });
      }
    };

    return {
      restrict: 'E',
      replace: true,
      templateUrl: require('../../views/vault/vault.html'),
      link: link
    };
  }

  app.directive('vault', ['Modal', 'Vault', 'TaskResource', 'Auth', 'UserService', vault]);
})();
