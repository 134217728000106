import moment from 'moment'

import templateUrl from './notification-date-separator.html'
import './notification-date-separator.scss'

const DATE_FORMAT_COMPARATOR = 'YYYY-MM-DD' // used for mapping unique days

const NotificationDateSeparatorComponent = {
  bindings: {
    notification: '<',
    dateSeparators: '<'
  },
  templateUrl,
  controller: class NotificationDateSeparator {
    constructor () {
      'ngInject'
      this._identify = 'NotificationDateSeparatorComponent'
    }

    $onInit () {
      this.dateToday = moment().format(DATE_FORMAT_COMPARATOR)
      this.yesterday = moment(this.dateToday).subtract(1, 'days')
    }

    showDateSeparator () {
      if (this.notification.createdAt) {
        const date = moment(this.notification.createdAt).format(DATE_FORMAT_COMPARATOR)

        if (moment(this.dateToday).isSame(date)) {
          return false
        }

        if (!this.dateSeparators[date]) {
          this.dateSeparators[date] = this.notification.id
        }

        return this.dateSeparators[date] === this.notification.id
      }
      return false
    }

    get dateSeparator () {
      let date = moment(this.notification.createdAt).format(DATE_FORMAT_COMPARATOR)

      if (moment(date).isSame(this.yesterday)) {
        return 'Yesterday'
      }

      return moment(date).format('D MMM')
    }
  }
}

export default NotificationDateSeparatorComponent
