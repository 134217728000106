import templateUrl from './settings-vault.html'
import './settings-vault.scss'

const SettingsVaultComponent = {
  bindings: {},
  templateUrl,
  controller: class SettingsVaultComponent {
    constructor (SettingsService, Vault, $timeout) {
      'ngInject'
      this._identify = 'SettingsVaultComponent'
      this.SettingsService = SettingsService
      this.Vault = Vault
      this.$timeout = $timeout
    }

    $onInit () {
      this.showExample = false
      this.privateKeyInvalid = false
      this.formData = {
        privateKey: this.Vault.getPKeyFromLS()
      }

      // Attach custom validator after next tick
      this.$timeout(() => {
        this.vaultForm.privateKey.$validators.validPrivateKey = (modelValue, viewValue) => {
          const value = modelValue || viewValue
          return typeof value === 'undefined' || value === '' || this.Vault.checkPKey(value)
        }
      })
    }

    updateVaultPrivateKey () {
      console.log('updateVaultPrivateKey', this.formData)
      this.isProcessing = true
      const privateKey = this.formData.privateKey
      if (privateKey) {
        if (this.Vault.checkPKey(privateKey)) {
          console.log('updateVaultPrivateKey > valid')
          this.Vault.savePKeyToLS(privateKey)
          // Flash.show('views/flashes/settings_updated.html', false, $scope);
          this.toggleShowExample(false)
        } else {
          console.log('updateVaultPrivateKey > not valid')
        }
      } else {
        console.log('updateVaultPrivateKey > empty')
        this.Vault.removePKeyFromLS()
        this.toggleShowExample(false)
        // Flash.show('views/flashes/settings_updated.html', false, $scope);
      }

      this.isProcessing = false
      this.vaultForm.$setPristine()
      console.log('updateVaultPrivateKey > reset form')
    }

    onChangeHandler ($event) {
      console.log('onChangeHandler', $event)
    }

    toggleShowExample (value = !this.showExample) {
      this.showExample = value
    }
  }
}

export default SettingsVaultComponent
