import templateUrl from './noticebar.html'
import './noticebar.scss'

const NoticebarComponent = {
  templateUrl,
  controller: class NoticebarComponent {
    constructor (NoticebarService) {
      'ngInject'
      this._identify = 'NoticebarComponent'
      this.NoticebarService = NoticebarService
    }

    get notice () {
      return this.NoticebarService.notice
    }

    noticeClose () {
      console.log('NoticebarComponent > noticeClose')
      this.NoticebarService.close()
    }

    noticeNext () {
      console.log('NoticebarComponent > noticeNext TODO')
    }

    noticePrevious () {
      console.log('NoticebarComponent > noticePrevious TODO')
    }
  }
}

export default NoticebarComponent
