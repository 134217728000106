import templateUrl from './privacy-notice.html'
import './privacy-notice.scss'

const PrivacyNoticeComponent = {
  bindings: {
    showPrivacy: '<',
  },
  templateUrl,
  controller: class PrivacyNotice {
    constructor ($http, Configuration) {
      'ngInject'
      this._identify = 'PrivacyNoticeComponent'
      this.$http = $http
      this.Configuration = Configuration

      this.html = false
    }

    $onInit () {
      this.showPrivacy = this.showPrivacy || false
      // Need to set credentials headers to false as CORS settings for gcloud bucket dont accept that setting and fails..
      this.$http.get(this.Configuration.privacy.noticeUrl, { withCredentials: false }).then(response => {
        this.html = response.data
      })
    }

    togglePrivacy () {
      this.showPrivacy = !this.showPrivacy
    }
  }
}

export default PrivacyNoticeComponent
