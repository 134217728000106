(function () {
  'use strict';
  function Vault(forge, Storage) {
    var encrSrv = new EncryptionService(forge);

    function openAsClient(taskId, vaultData) {
      var decryText = open(taskId, vaultData);

      return decryText;
    }

    function openAsContractor(taskId, vaultData) {
      if (!getPKeyFromLS()) {
        return null;
      }

      decryptVaultKey(taskId, getPKeyFromLS(), forge.util.decode64(vaultData.encVaultKey));

      return open(taskId, vaultData);
    }

    function open(taskId, vaultData) {
      var vaultKey = loadVaultKeyFromLS(taskId);

      if (vaultKey) {
        var iv = vaultData.encData.iv;
        var encrText = forge.util.createBuffer(vaultData.encData.encrText);

        return encrSrv.aesDecrypt(encrText, iv, vaultKey);
      }

      return null;
    }

    function add(taskId, password, email, text, sharedWith) {
      var vaultKey = encrSrv.deriveVaultKey(password, email);
      saveVaultKeyToLS(taskId, vaultKey);
      var encrData = encrSrv.aesEncrypt(text, vaultKey);

      shareSafelyVaultKeyWithContractors(sharedWith, vaultKey);

      return {encData: encrData, sharedWith: sharedWith};
    }

    function update(taskId, text, sharedWith) {
      var vaultKey = loadVaultKeyFromLS(taskId);
      var encrText = encrSrv.aesEncrypt(text, vaultKey);

      shareSafelyVaultKeyWithContractors(sharedWith, vaultKey);

      return {encData: encrText, sharedWith: sharedWith};
    }

    function remove(taskId) {
      return removeVaultKeyFromLS(taskId);
    }

    function checkVaultKey(privateKeyPem, vaultData) {
      // TODO: rewrite
      try {
        encrSrv.rsaDecrypt(privateKeyPem, forge.util.decode64(vaultData.encVaultKey));
        return true;
      } catch (err) {
        return false;
      }
    }

    function decryptVaultKey(taskId, privateKeyPem, encVaultKey) {
      var vaultKey = encrSrv.rsaDecrypt(privateKeyPem, encVaultKey);
      saveVaultKeyToLS(taskId, vaultKey);

      return vaultKey;
    }

    function shareSafelyVaultKeyWithContractors(sharedWith, vaultKey) {
      sharedWith.forEach(function (contractor) {
        contractor.encVaultKey = forge.util.encode64(encrSrv.rsaEncrypt(contractor.publicKeyPem, vaultKey));
      });
      return sharedWith;
    }

    function checkPKey(vaultKey) {
      return encrSrv.rsaCheck(vaultKey);
    }

    function savePKeyToLS(vaultKey) {
      if (checkPKey(vaultKey)) {
        return Storage.set('codeable_pKey', vaultKey);
      }
      return false;
    }

    function removePKeyFromLS() {
      return Storage.remove('codeable_pKey');
    }

    function getPKeyFromLS() {
      return Storage.get('codeable_pKey');
    }

    function saveVaultKeyToLS(taskId, vaultKey) {
      return Storage.set('task_' + taskId.toString() + '_vaultKey', vaultKey);
    }

    function loadVaultKeyFromLS(taskId) {
      return Storage.get('task_' + taskId.toString() + '_vaultKey');
    }

    function removeVaultKeyFromLS(taskId) {
      return Storage.remove('task_' + taskId.toString() + '_vaultKey');
    }

    return {
      openAsClient: openAsClient,
      openAsContractor: openAsContractor,
      add: add,
      update: update,
      remove: remove,
      encrSrv: encrSrv,
      loadVaultKeyFromLS: loadVaultKeyFromLS,
      checkPKey: checkPKey,
      savePKeyToLS: savePKeyToLS,
      removePKeyFromLS: removePKeyFromLS,
      saveVaultKeyToLS: saveVaultKeyToLS,
      getPKeyFromLS: getPKeyFromLS,
      decryptVaultKey: decryptVaultKey,
      checkVaultKey: checkVaultKey,
      removeVaultKeyFromLS: removeVaultKeyFromLS
    };
  }

  function EncryptionService(forge) {
    this.forge = forge;
    var keySize = 16;
    var cipherType = 'AES-CBC';
    var iterations = 10000;

    // PBKDF2 key derivation

    function deriveVaultKey(password, email) {
      var salt = saltFromEmail(email);
      var key = forge.pkcs5.pbkdf2(password, salt, iterations, keySize);

      return key;
    }

    function saltFromEmail(email) {
      var md = forge.md.sha512.create();
      md.update(email);
      return md.digest().toHex();
    }

    // AES

    function aesEncrypt(text, key) {
      var iv = forge.random.getBytesSync(keySize);

      var cipher = forge.cipher.createCipher(cipherType, key);
      cipher.start({iv: iv});
      cipher.update(forge.util.createBuffer(encodeURIComponent(text))); // Encode special characters before you encrypt COD-501
      cipher.finish();

      return {encrText: cipher.output, iv: iv};
    }

    function aesDecrypt(encrText, iv, key) {
      var decipher = forge.cipher.createDecipher(cipherType, key);
      decipher.start({iv: iv});
      decipher.update(encrText);
      decipher.finish();

      // in some cases of vault texts that were not encoded with encodeURIComponent we get an error URI malformed
      // in that case we catch the error and leave decipher.output.data as it is
      try {
        decipher.output.data = decodeURIComponent(decipher.output.data); // Decode special characters after you decrypt COD-501
      } catch (err) {
        // console.log(err);
      }

      return decipher.output;
    }

    // RSA

    function rsaCheck(privateKeyPem) {
      try {
        forge.pki.privateKeyFromPem(privateKeyPem);
        return true;
      } catch (err) {
        return false;
      }
    }

    function rsaEncrypt(publicKeyPem, text) {
      var publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
      return publicKey.encrypt(text);
    }

    function rsaDecrypt(privateKeyPem, encrText) {
      var privateKey = forge.pki.privateKeyFromPem(privateKeyPem);
      return privateKey.decrypt(encrText);
    }

    return {
      deriveVaultKey: deriveVaultKey,
      aesEncrypt: aesEncrypt,
      aesDecrypt: aesDecrypt,
      rsaCheck: rsaCheck,
      rsaEncrypt: rsaEncrypt,
      rsaDecrypt: rsaDecrypt
    };
  }

  app.service('Vault', ['forge', 'Storage', Vault]);
})();
