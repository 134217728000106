import templateUrl from './submission-progressbar-step.html'
import './submission-progressbar-step.scss'

const SubmissionProgressbarStepComponent = {
  bindings: {
    data: '<',
    next: '<',
    active: '<'
  },
  templateUrl,
  controller: class SubmissionProgressbarStep {
    constructor () {
      'ngInject'
      this._identify = 'SubmissionProgressbarStepComponent'
    }
  }
}

export default SubmissionProgressbarStepComponent
