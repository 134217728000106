import { merge } from 'lodash'

const _poolingIntervalList = {}
const UtilService = class UtilService {
  constructor ($window, $q, $interval) {
    'ngInject'
    this.$window = $window
    this.$q = $q
    this.$interval = $interval
    this.merge = merge
  }

  isEmpty (value) {
    if (value === '' || (Array.isArray(value) && value.length === 0) || value === null || typeof value === 'undefined') {
      return true
    }

    return false
  }

  getElement (selector) {
    if (selector === 'window') {
      return this.$window
    }
    return this.$window.document.querySelector(selector)
  }
  addClass (el, className) {
    if (typeof el === 'string') {
      el = this.getElement(el)
    }
    el.classList.add(className)
  }
  removeClass (el, className) {
    if (typeof el === 'string') {
      el = this.getElement(el)
    }
    el.classList.remove(className)
  }

  addBodyClass (className) {
    this.$window.document.body.classList.add(className)
  }

  removeBodyClass (className) {
    this.$window.document.body.classList.remove(className)
  }

  rafThrottle (callback) {
    let requestId

    const later = (context, args) => () => {
      requestId = null
      callback.apply(context, args)
    }

    const throttled = function (...args) {
      if ((requestId === null) || (requestId === undefined)) {
        requestId = this.$window.requestAnimationFrame(later(this, args))
      }
    }

    throttled.cancel = () =>
      this.$window.cancelAnimationFrame(requestId)

    return throttled
  }


  startPollingPromise (callback, interval) {
    interval = parseInt(interval, 10) || 100    // default to 100 milliseconds

    const poolingId = `${(new Date()).getTime()}-${Math.round(Math.random() * 1000000)}`
    console.log('[UtilService] > startPollingPromise', poolingId, interval)

    _poolingIntervalList[poolingId] = {
      poolingId: poolingId,
      callbackResolved: true, // set flag to true to allow first iteration
      intervalPromise: this.$interval(() => {
        if (this.getPoolingCallbackStatus(poolingId)) {  // check flag before start new call
          console.log('[UtilService] > intervalPromise > execute callback', this.getPoolingCallbackStatus(poolingId))
          this.setPoolingCallbackStatus(poolingId, false) // reset flag for next iteration
          callback(poolingId)
        } else {
          console.log('[UtilService] > intervalPromise > callback already in progress', this.getPoolingCallbackStatus(poolingId))
        }

        // if (_poolingTimeout[poolingId]) {
        //   this.stopPooling(poolingId); // Stop $interval if it reaches to threshold
        // }
      }, interval)
    }

    return _poolingIntervalList[poolingId]
  }

  stopPoolingPromise (poolingId) {
    console.log('[UtilService] > stopPoolingPromise >', poolingId)
    if (_poolingIntervalList[poolingId] && _poolingIntervalList[poolingId].intervalPromise) {
      console.log('[UtilService] > stopPoolingPromise > cancel intervalPromise')
      this.$interval.cancel(_poolingIntervalList[poolingId].intervalPromise)
      delete _poolingIntervalList[poolingId]
    }
  }

  getPoolingCallbackStatus (poolingId) {
    if (typeof _poolingIntervalList[poolingId] !== 'undefined') {
      return _poolingIntervalList[poolingId].callbackResolved
    }

    return false
  }

  setPoolingCallbackStatus (poolingId, value) {
    if (typeof _poolingIntervalList[poolingId] !== 'undefined') {
      _poolingIntervalList[poolingId].callbackResolved = value
    }
  }

  round (value, precision = 2) {
    const multiplier = Math.pow(10, precision || 0)
    return Math.round(value * multiplier) / multiplier
  }

  copy2Clipboard (selector = '#srcContentClipboard') {
    const copyText = document.querySelector(selector)
    copyText.select()
    document.execCommand('copy')
  }
}
export default UtilService
