(function () {
  'use strict';

  function provide($provide, Configuration) {
    function decorator($delegate, toastr) {
      toastr.options.positionClass = 'toast-top-right';

      // var debugFn = $delegate.debug;

      $delegate.debug = function () {
        var args = [].slice.call(arguments);
        var now = moment().format('MMMM Do YYYY, h:mm:ss');

        args[0] = '{0} - {1}'.supplant([now, args[0]]);

        // Only show debug in development environment
        if (!Configuration.isProd) {
          // toastr.info(args[0]);
          // debugFn.apply(null, args);
        }
      };
      $delegate.debug.logs = [];  // this keeps angular-mocks happy
      return $delegate;
    }

    $provide.decorator('$log', ['$delegate', 'toastr', decorator]);
  }

  app.config(['$provide', 'Configuration', provide]);
})();
