import templateUrl from './project-browse-pods-bar.html'
import './project-browse-pods-bar.scss'

const ProjectBrowsePodsBarComponent = {
  bindings: {},
  templateUrl,
  controller: class ProjectBrowsePodsBar {
    constructor (ModalService, ProjectBrowsePodsService) {
      'ngInject'
      this._identify = 'ProjectBrowsePodsBarComponent'
      this.ModalService = ModalService
      this.ProjectBrowsePodsService = ProjectBrowsePodsService

      this.isLoading = false
    }

    // $onInit () {
    //   this.pods = null
    //   this.isLoading = true
    //   this.ProjectBrowsePodsService.getPodsList()
    //     .then(pods => {
    //       this.pods = pods
    //     })
    //     .finally(() => { this.isLoading = false })
    // }

    get pods () {
      return this.ProjectBrowsePodsService.pods
    }

    get numYourPods () {
      return this.pods.expertPodsMemberships.length
    }

    get numAvailablePods () {
      return this.pods.availableExpertPods.length
    }

    openPodsManagementModal () {
      this.ModalService.open({
        closeOnBackdrop: false,
        closeIcon: true,
        class: 'project-browse-pods-management-modal cdbl-modal--size-large cdbl-modal--style-with-dividers cdbl-modal--style-with-sticky-header-and-footer',
        template: `<cdbl-project-browse-pods-management-modal data="$ctrl.modal.data"></cdbl-project-browse-pods-management-modal>`,
        data: {
          pods: this.pods
        }
      })
    }
  }
}

export default ProjectBrowsePodsBarComponent
