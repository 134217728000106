import ProjectBrowseComponent from './project-browse.component'
import ProjectBrowseService from './project-browse.service'

import ProjectBrowseListModule from './project-browse-list'
import ProjectBrowseProjectModule from './project-browse-project'
import ProjectBrowseSidebarModule from './project-browse-sidebar'


import ProjectBrowsePodsModule from './project-browse-pods'

const ProjectBrowseModule = angular.module('cdbl.project-browse', [
  ProjectBrowseListModule,
  ProjectBrowseProjectModule,
  ProjectBrowseSidebarModule,
  ProjectBrowsePodsModule
])
  .service('ProjectBrowseService', ProjectBrowseService)
  .component('cdblProjectBrowse', ProjectBrowseComponent)
  .config(($stateProvider) => {
    'ngInject'

    $stateProvider
      .state('project-browse', {
        url: '/new-projects',
        template: '<cdbl-project-browse></cdbl-project-browse>',
        abstract: true,
        resolve: {
          pods: (ProjectBrowsePodsService) => {
            'ngInject'
            console.log('🚀 ~ file: project-browse.module.js:29 ~ resolve')
            return ProjectBrowsePodsService.getPodsList()
          }
        },
      })

      .state({
        name: 'project-browse.list',
        parent: 'project-browse',
        url: '/:listId?page&q&previousClients,noEstimates,noComments,skills,pods',
        params: {
          listId: { squash: true, value: 'default' },
          page: { squash: true, type: 'int', value: 1 }, // dynamic: true to not trigger view reload
          previousClients: { squash: true, type: 'bool', value: false },
          noEstimates: { squash: true, type: 'bool', value: false },
          noComments: { squash: true, type: 'bool', value: false },
          skills: { squash: true, type: 'bool', value: false },
          pods: { squash: true, type: 'int' },
          // q: { squash: true, value: '' }
        },
        views: {
          'projectList': {
            template: '<cdbl-project-browse-list></cdbl-project-browse-list>'
          }
        },
        resolve: {
          permissionResolver: (AuthService) => {
            'ngInject'
            return AuthService.permissionResolver('isExpert')
          },
          // pods: (ProjectBrowsePodsService) => {
          //   'ngInject'
          //   return ProjectBrowsePodsService.getPodsList()
          // }
        },
        layout: 'new'
      })
  })
  // This makes sure app folows right routing after init
  .run(($rootScope, $state, UserService) => {
    'ngInject'
    const LEGACY_BROWSE_ROUTER_STATE = 'tasks.all'

    // Redirect to new dashboard
    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
      if (toState.name.startsWith(LEGACY_BROWSE_ROUTER_STATE)) {
        event.preventDefault() // cancel original route transition

        console.log('[ProjectBrowseModule] > redirect to new browse', toState.name, toParams)
        $state.go('project-browse.list', { listId: 'default' })
      }
    })
    // -----
  })
  .name

export default ProjectBrowseModule


// TODO: add authorized attriubute to state and attach permission resolver to $stateCHangeStart if state has attribute authorized to simplify for other pages as well

