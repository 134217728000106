(function () {
  'use strict';

  function PublishTaskAllCtrl($scope, Modal, $http, Configuration) {
    $scope.finished = false;
    $scope.processing = false;

    $scope.publish = function (taskId, text) {
      $scope.processing = true;

      $http
        .put(`${Configuration.apiUrl}/users/me/tasks/preferred/${taskId}/publish`, { text: text })
        .then(function (response) {
          $scope.finished = true
          $scope.modalScope.task.preferredContractors = []
          $scope.modalScope.task.staysPreferred = false
          $scope.modalScope.task.publishedAt = response.data.publishAt
        })
        .catch(function (err) {
          console.log(err)
        })
        .finally(function () {
          $scope.processing = false
        })
    };

    $scope.closeModal = function () {
      Modal.close();
    };
  }

  app.controller('PublishTaskAllCtrl', ['$scope', 'Modal', '$http', 'Configuration', PublishTaskAllCtrl]);
})();
