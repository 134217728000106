(function () {
  'use strict';

  function sidebar($state) {
    var getSidebarTitle = function (state, $scope, defTitle) {
      defTitle = defTitle ? defTitle : 'Filters';

      var title = state.current.label;
      title = title ? title : defTitle;

      return title;
    };

    var link = function ($scope, element, attrs) {
      $scope.template = attrs.template;
      $scope.collapsible = (attrs.collapse === '');
      $scope.collapseMenu = true;

      // Set title to appropriate state
      $scope.sidebarTitle = getSidebarTitle($state, $scope, attrs.title);

      $scope.changeTitle = function (title) {
        $scope.sidebarTitle = title;
      };

      $scope.toggleCollapse = function ($event) {
        // Do not toggle collapse on clear filter button click
        if ($event && $event.target.classList.contains('clear-filters')) {
          return;
        }

        $scope.collapseMenu = !$scope.collapseMenu;

        $scope.updateCollapsedMenu();
      };

      // For sliding animation of responsive bar
      $scope.updateCollapsedMenu = function () {
        var maxHeight = $('.sidebar-left.sidebar .sidebar-responsive .sidebar--menu')[0].scrollHeight;

        // if ($scope.collapseMenu) {
        //   maxHeight = 0;
        // }

        $('.sidebar-left.sidebar .sidebar-responsive').css('max-height', maxHeight + 'px');
      };

      // $scope.$watch('collapseMenu', $scope.updateCollapsedMenu);
    };

    return {
      restrict: 'E',
      scope: true,
      replace: true,
      templateUrl: require('../../views/directives/sidebar.html'),
      link: link
    };
  }

  app.directive('sidebar', ['$state', sidebar]);
})();
