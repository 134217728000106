import templateUrl from './sidebar-widget.html'
import './sidebar-widget.scss'

const AdditionalTasksSidebarWidgetComponent = {
  bindings: {},
  templateUrl,
  controller: class AdditionalTasksSidebarWidget {
    constructor ($location, ProjectService, ModalService, UserService, EventBusService, PaymentsService, SegmentAnalytics, Flash, Modal) {
      'ngInject'
      this._identify = 'AdditionalTasksSidebarWidgetComponent'
      this.$location = $location
      this.ProjectService = ProjectService
      this.ModalService = ModalService
      this.UserService = UserService
      this.EventBusService = EventBusService
      this.PaymentsService = PaymentsService
      this.SegmentAnalytics = SegmentAnalytics
      this.Flash = Flash // TODO migrate to refactored flash component
      this.Modal = Modal // TODO migrate to refactored modal component

      this.subscriptionCollection = this.EventBusService.createSubscriptionCollection('legacy')
    }

    $onInit () {
      // normal or retainer task - created
      this.subscriptionCollection.subscribe('sub-task-created', (event) => {
        this.ProjectService.addAdditionalTaskToProject(event.data)

        this.Flash.hide()
        this.showFlashNextDeposit()
      })

      // normal task - paid
      this.subscriptionCollection.subscribe('sub-task-payment-successful', (event) => {
        const originalTask = this.project.subtasks.find(t => t.id === event.data.id)
        if (originalTask) {
          Object.assign(originalTask, event.data)

          // offline payment confirmation
          this.checkForOfflinePayment()
          // if (!originalTask.paymentConfirmed && this.UserService.isClient()) {
          //   this.Modal.open('views/modals/deposit_funds_complete_manual_subtask.html',
          //     {
          //       offlinePaymentConfirm: this.offlinePaymentConfirm,
          //       task: this.project,
          //       subtask: originalTask
          //     },
          //     {
          //       closeButton: false
          //     }
          //   )
          // }
          this.Flash.hide()
          this.showFlashNextDeposit()
        }
      })

      // task - completed
      this.subscriptionCollection.subscribe('sub-task-completed', (event) => {
        const originalTask = this.project.subtasks.find(t => t.id === event.data.id)
        if (originalTask) {
          Object.assign(originalTask, event.data)
        }
      })

      // task - canceled
      this.subscriptionCollection.subscribe('sub-task-canceled', (event) => {
        const originalTask = this.project.subtasks.find(t => t.id === event.data.id)
        if (originalTask) {
          Object.assign(originalTask, event.data)
          this.Flash.hide()
        }
      })

      // task - refund
      this.subscriptionCollection.subscribe('sub-task-refund-successful', (event) => {
        const originalTask = this.project.subtasks.find(t => t.id === event.data.id)
        if (originalTask) {
          Object.assign(originalTask, event.data)
          this.Flash.hide()
        }
      })

      // retainer task - subscription created - SC payment form submitted
      this.subscriptionCollection.subscribe('retainer-subscription-created', (event) => {
        const originalTask = this.project.subtasks.find(t => t.id === event.data.id)
        if (originalTask) {
          Object.assign(originalTask, event.data)

          this.Flash.hide()
        }
      })

      // retainer task - subscription paid
      this.subscriptionCollection.subscribe('retainer-subscription-succeeded', (event) => {
        const originalTask = this.project.subtasks.find(t => t.id === event.data.id)
        if (originalTask) {
          Object.assign(originalTask, event.data)

          this.Flash.hide()
          this.showFlashNextDeposit()
        }
      })

      // retainer task - subscription canceled
      this.subscriptionCollection.subscribe('retainer-subscription-canceled', (event) => {
        const originalTask = this.project.subtasks.find(t => t.id === event.data.id)
        if (originalTask) {
          Object.assign(originalTask, event.data)
        }
      })

      // retainer task - subscription ended
      this.subscriptionCollection.subscribe('retainer-subscription-ended', (event) => {
        const originalTask = this.project.subtasks.find(t => t.id === event.data.id)
        if (originalTask) {
          Object.assign(originalTask, event.data)
        }
      })

      // retainer task - subscription failed
      this.subscriptionCollection.subscribe('retainer-subscription-failed', (event) => {
        const originalTask = this.project.subtasks.find(t => t.id === event.data.id)
        if (originalTask) {
          Object.assign(originalTask, event.data)
        }
      })

      // TODO: abandoned - reverted event handling (waiting on requirements)

      // -----------------------------------
      // Init code
      this.showFlashNextDeposit()
      this.checkForOfflinePayment()

      // Support legacy url param and open payment modal for specific task on page load
      if (this.$location.search().paySubTask) {
        const specificUnpaidTask = this.project.subtasks.find(t => t.id === Number(this.$location.search().paySubTask))
        if (specificUnpaidTask) {
          this.ModalService.open({
            closeOnBackdrop: false,
            class: 'payment-modal',
            layout: 'center',
            template: `<cdbl-payment-modal task="$ctrl.modal.project" sub-task="$ctrl.modal.task"></cdbl-payment-modal>`,
            project: this.project,
            task: specificUnpaidTask
          })
        }
      }
    }

    $onDestroy () {
      this.subscriptionCollection.unsubscribeAll()
    }

    get project () {
      return this.ProjectService.project
    }

    showFlashNextDeposit () {
      if (this.project && this.project.subtasks && this.UserService.isClient()) {
        const nextUnpaidTask = this.project.subtasks.find(t => t.state === 'published')
        if (nextUnpaidTask) {
          this.Flash.hide()
          // TODO: use new flash component on new layout
          this.Flash.show('views/flashes/deposit_money_sub_task.html', true, {
            project: this.project,
            task: nextUnpaidTask,
            openPaymentModal: () => {
              this.ModalService.open({
                closeOnBackdrop: false,
                class: 'payment-modal',
                layout: 'center',
                template: `<cdbl-payment-modal task="$ctrl.modal.project" sub-task="$ctrl.modal.task"></cdbl-payment-modal>`,
                project: this.project,
                task: nextUnpaidTask
              })
            },
            openViewModalWithUnpaidFilter: () => {
              this.ModalService.open({
                closeOnBackdrop: true,
                closeIcon: true,
                class: 'additional-tasks-view-modal',
                template: `<cdbl-additional-tasks-view-modal filters="$ctrl.modal.filters"></cdbl-additional-tasks-view-modal>`,
                filters: {
                  'unpaid': true
                }
              })
            }
          })
        }
      }
    }

    checkForOfflinePayment () {
      if (this.project && this.project.subtasks && this.UserService.isClient()) {
        const offlinePaidTask = this.project.subtasks.find(t => t.paymentConfirmed === false)
        const vm = this

        if (offlinePaidTask) {
          this.Modal.open('views/modals/deposit_funds_complete_manual_subtask.html',
            {
              offlinePaymentConfirm: function (modalScope) {
                vm.offlinePaymentConfirm(modalScope)
              },
              task: this.project,
              subtask: offlinePaidTask
            },
            {
              closeButton: false
            }
          )
        }
      }
    }

    offlinePaymentConfirm (modalScope) {
      console.log('SubTasksCtrl > offlinePaymentConfirm', modalScope)
      this.PaymentsService.offlinePaymentConfirmation(modalScope.task, modalScope.subtask)
        .then(response => {
          console.log('SubTasksCtrl > offlinePaymentConfirm > response', response)
          Object.assign(modalScope.subtask, response)
          this.SegmentAnalytics.trackSubTaskPayment(modalScope.task, modalScope.subtask, 'offline')
          this.SegmentAnalytics.trackPayment(modalScope.task, modalScope.subtask, 'offline')
          this.Modal.close()

          // Check for any other offline paid subtask
          this.checkForOfflinePayment()
        }, error => {
          modalScope.error = 'Error occured, please try again.'
          console.log('WorkroomTaskCtrl > offlinePaymentConfirm > error', error)
        })
    }


    openViewModal (additionalTaskId = null, $event) {
      if ($event) {
        $event.preventDefault()
        $event.stopPropagation()
      }

      this.ModalService.open({
        closeOnBackdrop: true,
        closeIcon: true,
        class: 'additional-tasks-view-modal',
        template: `<cdbl-additional-tasks-view-modal additional-task-id="$ctrl.modal.additionalTaskId"></cdbl-additional-tasks-view-modal>`,
        additionalTaskId: additionalTaskId
      })
    }

    openCreateModal ($event) {
      if ($event) {
        $event.preventDefault()
        $event.stopPropagation()
      }

      this.ModalService.open({
        closeOnBackdrop: true,
        closeIcon: true,
        class: 'additional-tasks-create-modal',
        template: `<cdbl-additional-tasks-create-modal></cdbl-additional-tasks-create-modal>`,
      })
    }
  }
}

export default AdditionalTasksSidebarWidgetComponent
