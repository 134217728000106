function authInterceptor (Configuration, $q, $location, $injector, AuthTokenService, ModalService) {
  'ngInject'

  return {
    request: function (config) {
      // TODO: enable below logic only if url is codeable API
      if (!config.disableAuthInterceptor) {
        // We need to manually fetch Auth to avoid circular dependency
        const authToken = AuthTokenService.get()

        // We don't want to authorize template fetching
        if (config.url.indexOf('.html') === -1 && authToken) {
          config.headers.Authorization = `Bearer ${authToken}`
          config.headers.Accept = 'application/json, application/vnd.codeable.v1'
        }
      }

      return config || $q.when(config)
    },

    requestError: function (rejection) {
      return $q.reject(rejection)
    },


    response: function (response) {
      // TODO: move token extraction from heders to services calling those endpoints which return new token
      // const authToken = response.headers(Configuration.auth.tokenHeaderKey)
      // const canUpdate = Configuration.auth.tokenEndpointUrls.find(tokenUrl => {
      //   return response.config.url.includes(tokenUrl)
      // })

      // if (canUpdate && authToken) {
      //   AuthTokenService.save(authToken)
      // }

      return response
    },


    // Handle 401
    responseError: function (rejection) {
      // to Avoid circular dependecey
      const AuthService = $injector.get('AuthService')

      // TODO: enable below logic only if url is codeable API and deprecate / replace if/else
      if (!rejection.config.disableAuthInterceptor) {
        if (rejection.status === 401) {
          console.log('[AuthInterceptor] > 401')

          const isLogoutException = Configuration.auth.logoutWithoutRedirectUrls.find(exceptionUrl => {
            return $location.url().includes(exceptionUrl)
          })

          if (isLogoutException) {
            AuthService.logout({ redirectToLoginPage: false })
          } else {
            // params used for routing after next successful login
            AuthService.logout({ params: { redirect: $location.url() } })
          }
        } else if (rejection.status === 403) {
          const isUnauthorizedException = Configuration.auth.preventLogoutWhenUnauthorizedUrls.find(exceptionUrl => {
            return rejection.config.url.includes(exceptionUrl)
          })
          if (isUnauthorizedException) {
            return $q.reject(rejection)
          }
          ModalService.clear()
          ModalService.close()
          AuthService.logout({ redirectToLoginPage: true })
        }
      }

      return $q.reject(rejection)
    }
  }
}

export default authInterceptor
