import CommentComponent from './comment.component'
import CommentService from './comment.service'

const CommentModule = angular.module('cdbl.comment', [

])
  .component('cdblComment', CommentComponent)
  .service('CommentService', CommentService)
  .name

export default CommentModule
