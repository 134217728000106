(function () {
  'use strict';

  function Storage($rootScope, $localStorage, $sessionStorage) {
    $rootScope.$storage = $localStorage;

    var setLocal = function () {
      $rootScope.$storage = $localStorage;
    };

    var setSession = function () {
      $rootScope.$storage = $sessionStorage;
    };

    var set = function (key, val) {
      $rootScope.$storage[key] = val;
    };

    var get = function (key) {
      return $rootScope.$storage[key];
    };

    var remove = function (key) {
      delete $rootScope.$storage[key];
    };

    var removeAll = function () {
      $rootScope.$storage.$reset();
    };

    return {
      setLocal: setLocal,
      setSession: setSession,
      set: set,
      get: get,
      remove: remove,
      removeAll: removeAll
    };
  }

  app.service('Storage', ['$rootScope', '$localStorage', '$sessionStorage', Storage]);
})();
