(function () {
  'use strict';

  function hideTask(SegmentAnalytics, $state, UserService, $http, Configuration) {
    var link = function ($scope, element, attrs) {
      element.on('click', function (event) {
        event.stopPropagation(); // keeps it from firing the click on the document
      });

      $scope.UserService = UserService;
      $scope.cssClass = attrs.cssClass ? attrs.cssClass : '';

      $scope.currentUserIsContractor = function () {
        if (!$scope.UserService || !$scope.task || !$scope.task.contractor) {
          return false;
        }
        return $scope.UserService.user.id === $scope.task.contractor.id;
      };

      $scope.hideTask = function (task) {
        task.hidden = true;

        if ($scope.task.hiddenByCurrentUser) {
          $scope.task.hiddenByCurrentUser = false;

          $http
            .delete(`${Configuration.apiUrl}/users/me/hidden_tasks/${task.id}`)
            .then(function () {
              SegmentAnalytics.trackTaskUnhidden(task, $state.current.name)
            })
            .catch(function (err) {
              console.log(err)
            })
        } else {
          $scope.task.hiddenByCurrentUser = true;

          $http
            .post(`${Configuration.apiUrl}/users/me/hidden_tasks`, { taskId: task.id })
            .then(function () {
              SegmentAnalytics.trackTaskHidden(task, $state.current.name)
            })
            .catch(function (err) {
              console.log(err)
            })
        }
      };

      $scope.hideText = 'This will hide the project from all projects list (search) and will mute any in-app notifications coming from it. You can un-hide it on all projects page if you select hidden projects filter.';
      $scope.showText = 'You will receive notifications from this project again and see this project on all projects list (search)';
    };

    return {
      restrict: 'E',
      templateUrl: require('../../views/directives/hide_task.html'),
      link: link,
      scope: {
        task: '=task'
      }
    };
  }

  app.directive('hideTask', ['SegmentAnalytics', '$state', 'UserService', '$http', 'Configuration', hideTask]);
})();
