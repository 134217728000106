import SystemReloadService from './system-reload.service'
import ReloadModalModule from './system-reload-modal'

const SystemReloadModule = angular.module('cdbl.system-reload', [
  ReloadModalModule
])
  .service('SystemReloadService', SystemReloadService)
  .name

export default SystemReloadModule
