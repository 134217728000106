'use strict'

// -------------------------
// APP -- DEPENDENCIES
// -------------------------

import 'core-js/stable'  // babel polyfill replacement
import 'regenerator-runtime/runtime' // babel polyfill replacement

// app vendor js
import 'jquery'
import 'bootstrap'
import 'typeahead.js'
// import 'angular-load'
import 'pace'
import 'pace-js'
import 'velocity-animate' // remove when legacy popup is not used anymore
import 'mark.js/dist/jquery.mark.js' // remove when refactorign activities - used for activites search

import Lodash from 'lodash'
import Moment from 'moment' // also imported as global variable in webpack
import Toastr from 'toastr' // also imported as global variable in webpack
import Spin from 'spin'
import Pusher from 'pusher-js'
import Tether from 'tether'
import Marked from 'marked' // also imported as global variable in webpack
import Speakingurl from 'speakingurl' // used for settings > profile > expert url slug
import Forge from '@legacy/vendor/forge.min.js'  // hardcoded vendor file

import * as Sentry from '@sentry/browser'
import { Angular as AngularIntegration } from '@sentry/integrations'
import { Integrations } from '@sentry/tracing'

import angular from 'angular'
import uiRouter from 'angular-ui-router' // also imported as global variable in webpack
import ngSanitize from 'angular-sanitize'
import ngAnimate from 'angular-animate'
import 'angular-svg-base-fix'
import 'angular-vs-repeat'
import 'angular-messages'
import 'angular-moment'
import 'angular-linkify'
import 'angular-cookies'
import 'ngstorage'
import 'angular-filter'
import 'ng-tags-input'
import 'restangular'
import '@legacy/vendor/angular-ui-scroll.js' // hardcoded vendor file

// app vendor styles
import 'normalize.css'
import '@legacy/styles/ng-tags-input.min.css'
import '@legacy/styles/ng-tags-input.bootstrap.min.css'

// Sortable - scope doc
import Sortable from 'sortablejs'
// import 'angular-legacy-sortablejs-maintained' // has an import bug
import '@/assets/js/angular-legacy-sortable.js' // temporary fix until maintainers update the above package

// other dependencies and assets
import '@/assets/sounds/another-message.mp3'
import '@/assets/sounds/arpeggio.mp3'
import '@/assets/sounds/bubble.mp3'
import '@/assets/sounds/cha-ching.mp3'
import '@/assets/sounds/click.mp3'
import '@/assets/sounds/coins.mp3'
import '@/assets/sounds/filling-inbox.mp3'
import '@/assets/sounds/friends.mp3'
import '@/assets/sounds/heart-attack.mp3'
import '@/assets/sounds/jingle-bells.mp3'



// -------------------------
// APP -- BEGIN
// -------------------------

import requireAll from '@/assets/js/helpers.js' // folder importer for legacy folders

// config and runtime sequences
import ConfigEnvironment from 'cdblEnvConfig'
import ConfigGeneral from '@/app/app.config'
import AppComponent from '@/app/app.component'
import AppRun from '@/app/app.run'
import IconsRun from '@/app/app.run.icons'
import ReferooRun from '@/app/app.run.referoo'

// Interceptors
import authInterceptor from '@/app/common/auth/auth.interceptor'

// app modules
import CommonModule from '@/app/common/common'
import ComponentsModule from '@/app/components/components'

// app global styles
import '@/app/app.scss'
import { v4 as uuidv4 } from 'uuid'

// setup config
// APP_ENV is generated by webpack based on current setup and it overrides some attributes
// set in ConfigEnvironment that is also defined by webpack
const Configuration = angular.merge({ appInstanceUUID: uuidv4() }, ConfigGeneral, ConfigEnvironment, APP_ENV)


// Override apiUrl with provided local api url
if (APP_ENV.CDBL_LOCAL_API) {
  Configuration.apiUrl = APP_ENV.CDBL_LOCAL_API
}

console.log('----- Codeable ----- ')
console.log('Configuration: ', Configuration)

// Make sure to call Sentry.init after importing AngularJS.
// You can also pass {angular: AngularInstance} to the Integrations.Angular() constructor.
// Read more: https://docs.sentry.io/platforms/javascript/guides/angular/angular1/

Sentry.init({
  debug: false,
  dsn: Configuration.NODE_ENV === 'production' ? Configuration.sentry.dns : null,
  release: Configuration.REVISION,
  environment: Configuration.CDBL_ENV,
  integrations: [
    new AngularIntegration(),
    new Integrations.BrowserTracing()
  ],
  tracesSampleRate: 0.05,
  ignoreErrors: [
    '/AuthorizationPermissionError/',
    '/Error: ResizeObserver loop limit exceeded/',
    '/Error: ResizeObserver loop completed with undelivered notifications./',
    'ResizeObserver loop limit exceeded',
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    'ResizeObserver loop completed with undelivered notifications',
    'Error: [$rootScope:infdig] 10 $digest() iterations reached',
    'RangeError'
  ],
  allowUrls: [/https?:\/\/((app|develop|staging)\.)?codeable\.io/i],
  // denyUrls: [
  //   // Chrome extensions
  //   /extensions\//i,
  //   /^chrome:\/\//i,
  // ]
})

if (Configuration.NODE_ENV === 'development') {
  Sentry.setTag('local', 'true')
}

window.Sortable = Sortable

// Initialize app
window.app = angular
  .module('Codeable', [
    // Dependency modules
    uiRouter,
    ngSanitize,
    ngAnimate,
    'svgBaseFix', // fix svgs
    'vs-repeat',
    'linkify',
    'restangular',
    'angularMoment',
    'ngCookies',
    'ngStorage',
    'ui.scroll',
    'angular.filter',
    'ngTagsInput',
    'ngMessages',
    'ngSentry',
    'ng-sortable',

    // App modules
    CommonModule,
    ComponentsModule
  ])

  // Dependencies inject - map 3rd party libraries as angular dependencies
  .value('_', Lodash)
  .value('toastr', Toastr)
  .value('Pusher', Pusher)
  .value('Spinner', Spin)  // TODO: try to remove this dependecy
  .value('Tether', Tether) // TODO: try to migrate to popper.js
  .value('moment', Moment)
  .value('marked', Marked)
  .value('getSlug', Speakingurl)
  .value('forge', Forge) // TODO: try to update to newest version
  .value('Sentry', Sentry)

  .constant('Configuration', Configuration)

  .factory('debounce', ['$timeout', '$q', function ($timeout, $q) {
    // The service is actually this function, which we call with the func
    // that should be debounced and how long to wait in between calls
    return function debounce (func, wait, immediate) {
      var timeout
      // Create a deferred object that will be resolved when we need to
      // actually call the func
      var deferred = $q.defer()
      return function () {
        var context = this
        var args = arguments
        var later = function () {
          timeout = null
          if (!immediate) {
            deferred.resolve(func.apply(context, args))
            deferred = $q.defer()
          }
        }
        var callNow = immediate && !timeout
        if (timeout) {
          $timeout.cancel(timeout)
        }
        timeout = $timeout(later, wait)
        if (callNow) {
          deferred.resolve(func.apply(context, args))
          deferred = $q.defer()
        }
        return deferred.promise
      }
    }
  }])

  // TODO: move under .config
  .run(AppRun)
  .run(IconsRun)
  .run(ReferooRun)

  .config((
    $stateProvider,
    $compileProvider,
    $locationProvider,
    $uiViewScrollProvider,
    $httpProvider,
    tagsInputConfigProvider,
    $qProvider,
    $urlMatcherFactoryProvider
  ) => {
    'ngInject'

    $httpProvider.interceptors.push(authInterceptor)

    // @see: https://github.com/angular-ui/ui-router/wiki/Frequently-Asked-Questions
    // #how-to-configure-your-server-to-work-with-html5mode
    $locationProvider.html5Mode(true).hashPrefix('!')

    if (Configuration.isBuild) {
      $compileProvider.debugInfoEnabled(false)
    } else {
      $compileProvider.debugInfoEnabled(true)
    }

    // Disable comment directives nad css class directives
    // Normal element and attribute directives will still work
    $compileProvider.commentDirectivesEnabled(false)
    $compileProvider.cssClassDirectivesEnabled(false)

    $uiViewScrollProvider.useAnchorScroll()

    $httpProvider.defaults.withCredentials = true
    $httpProvider.useApplyAsync(true) // Angular takes care of deferring the resolution of your XHR calls to the next tick

    tagsInputConfigProvider.setActiveInterpolation('tagsInput', {
      placeholder: true
    })

    // prevent automatical error generation for rejected unhandled
    // promises - this will be addressed with refactoring
    $qProvider.errorOnUnhandledRejections(false)

    // Ignore trailing slashes in url for route matching
    $urlMatcherFactoryProvider.strictMode(false)

    // TODO: Remove when not needed anymore. Setup base abstrat state for parts of legacy application.
    $stateProvider.state('users', {
      abstract: true,
      url: '/users',
      views: {
        '@': {
          template: '<ui-view> </ui-view>'
        }
      }
    })
  })

  .component('app', AppComponent)



// Load favicon
require('@/assets/images/favicon.ico')

// Load images folder
requireAll(require.context('@legacy/images', true, /\.(svg|png)$/))

// Load views folder (templates)
requireAll(require.context('@legacy/views', true, /\.html$/))

// Load scripts folder
requireAll(require.context('@legacy/scripts/', true, /\.js$/))
