(function () {
  'use strict';

  function Flash($rootScope, $timeout) {
    var show = function (partial, persistent, flashScope) {
      $timeout(() => {
        console.log('Flash (legacy) > show', partial);
        $rootScope.$broadcast('showFlash', {
          partial: partial,
          persistent: persistent,
          flashScope: flashScope
        });
      });
    };

    var hide = function () {
      $timeout(() => {
        console.log('Flash (legacy) > hide');
        $rootScope.$broadcast('hideFlash');
      });
    };

    return {
      show: show,
      hide: hide
    };
  }

  app.service('Flash', ['$rootScope', '$timeout', Flash]);
})();
