import templateUrl from './settings-account.html'
import './settings-account.scss'

const SettingsAccountComponent = {
  bindings: {},
  templateUrl,
  controller: class SettingsAccountComponent {
    constructor ($timeout, UserService, SettingsService, ModalService) {
      'ngInject'
      this._identify = 'SettingsAccountComponent'
      this.$timeout = $timeout
      this.UserService = UserService
      this.SettingsService = SettingsService
      this.ModalService = ModalService

      this.phonePattern = /^\+[0-9]{5,15}$/
    }

    async $onInit () {
      const payload = {
        firstName: this.UserService.user.firstName,
        lastName: this.UserService.user.lastName,
        email: this.UserService.user.email,
        imageList: []
      }
      if (this.UserService.isClient()) {
        payload.location = this.UserService.user.location
      }
      this.formData = payload
      this.isProcessing = false
      this.isSaved = false


      this.formData = {
        firstName: null,
        lastName: null,
        email: null,
        imageList: []
      }

      this.emergencyContact = null

      await this.getUserProfile()

      if (this.UserService.isExpert()) {
        this.showEmergencyContactSection = true
        await this.getEmergencyDetails()
      }
    }

    async getUserProfile () {
      // Fetch fresh data from BE
      this.isLoading = true
      await this.SettingsService.getAccountDetails()
        .then(user => {
          this.formData.firstName = user.firstName
          this.formData.lastName = user.lastName
          this.formData.email = user.email

          if (this.UserService.isClient()) {
            this.formData.location = user.location // Clients only
          }
          if (this.UserService.isExpert()) {
            this.formData.phoneNumber = user.phoneNumber // Experts only
          }
        })
      this.isLoading = false
      this.$timeout()
    }
    async getEmergencyDetails () {
      // Fetch fresh data from BE
      this.isLoading = true
      await this.SettingsService.getEmergencyDetails()
        .then(emergencyContact => {
          this.emergencyContact = emergencyContact
        })
      this.isLoading = false
      this.$timeout()
    }

    get canShowPreviouslyUploadedImage () {
      return true
    }

    get showSavedStatus () {
      return this.isSaved && this.accountForm.$pristine
    }

    async saveAccount () {
      const payload = {
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
      }
      if (this.UserService.isClient()) {
        payload.location = this.formData.location
      }
      if (this.UserService.isExpert()) {
        payload.phoneNumber = this.formData.phoneNumber
      }
      this.isProcessing = true
      await this.SettingsService.updateAccountDetails(payload)
        .then(user => {
          this.isSaved = true
          this.isProcessing = false
          this.accountForm.$setPristine()
        })
        .finally(() => {
          this.isProcessing = false
        })
      this.$timeout()
    }

    openChangePasswordModal (portfolio = null) {
      this.ModalService.open({
        closeOnBackdrop: false,
        closeIcon: true,
        class: 'cdbl-account-modal cdbl-account-modal cdbl-change-password cdbl-modal--style-with-dividers cdbl-modal--style-with-sticky-header-and-footer',
        template: `
          <cdbl-account-change-password>
          </cdbl-account-change-password>`,
      })
    }

    openChangeEmailModal (portfolio = null) {
      this.ModalService.open({
        closeOnBackdrop: false,
        closeIcon: true,
        class: 'cdbl-account-modal cdbl-account-modal cdbl-change-email cdbl-modal--style-with-dividers cdbl-modal--style-with-sticky-header-and-footer',
        template: `
          <cdbl-account-change-email email="$ctrl.modal.email">
          </cdbl-account-change-email>`,
        email: this.formData.email
      }).then(response => {
        this.getUserProfile()
      })
    }

    openDeleteModal (portfolio = null) {
      this.ModalService.open({
        closeOnBackdrop: false,
        closeIcon: true,
        class: 'cdbl-account-modal cdbl-account-delete cdbl-modal--style-with-dividers cdbl-modal--style-with-sticky-header-and-footer',
        template: `
          <cdbl-account-delete>
          </cdbl-account-delete>`,
      })
    }


    openEmergencyContactsModal () {
      this.ModalService.open({
        closeOnBackdrop: false,
        closeIcon: true,
        class: 'cdbl-account-change-emergency-contact-modal cdbl-modal--style-with-dividers cdbl-modal--style-with-sticky-header-and-footer',
        template: `
          <cdbl-account-change-emergency-contact data="$ctrl.modal.data">
          </cdbl-account-change-emergency-contact>`,
        data: this.emergencyContact
      }).then(response => {
        this.getEmergencyDetails()
      })
    }
  }
}

export default SettingsAccountComponent
