(function () {
  'use strict';

  function Sockets($rootScope, $timeout, Restangular, Configuration, Pusher, DataInterceptor, ApiLogging, EventBusService, Sentry) {
    var pusher;
    var currentUser;

    const vm = this;
    const NON_LEGACY_CHANNELS = ['root']
    vm.pusher = null;

    this._channels = {};

    var retrySubscriptions = {};

    // console.log('ROOT SCOPE');
    // console.log($rootScope);

    // console.log('LISTENERS:');
    // console.log($rootScope.$$listeners);

    var subscribe = function (channelName, taskId) {
      // IF SUBSCRIPTION ALREADY EXISTS FIRST UNSUBSCRIBE
      if (this && vm._channels[channelName]) {
        this.unsubscribe(channelName);
      }

      // console.log('SUBSCRIBE TO CHANNEL: ' + channelName);
      if (typeof vm.pusher === 'undefined' || vm.pusher === null) {
        // do not subscribe to any channels if pusher was not initialized - this could happen if user was redirected
        // from tasks/:id path to sign-in
        return;
      }

      var channel = vm.pusher.subscribe(channelName);

      channel.bind('pusher:subscription_error', function (status) {
        ApiLogging.error('pusher:subscription', channelName, {status: status, taskId: taskId});
        console.info('Sockets > pusher > subscribe > error', status);
        if (!retrySubscriptions[channelName]) {
          channel.unbind_all();
          retrySubscriptions[channelName] = true;
          subscribe(channelName, taskId);
        }
      });

      channel.bind_global(function (eventName, payload) {
        if (eventName.substr(0, 6) === 'pusher') {
          return;
        } // Prevent internal pusher events from being broadcasted

        payload = DataInterceptor.convertCase(payload, 'toCamelCase');

        /******************************************************/
        /******************************************************/
        /* REFACTORING
        * Temporary refactoring code is hooked up to exising legacy Sockets service.
        * Pusher events are being forwarded to internal app event network.
        */
        /******************************************************/
        /******************************************************/

        // Publish notifications separately on separate internal channel
        // They will be consumed by refactored notifications page and widgets
        if (channelName.endsWith('notifications')) {
          EventBusService.channel('notifications').publish(eventName, {
            pusherChannel: channelName,
            pusherEvent: eventName,
            data: payload
          });
        } else if (channelName === 'root') {
          EventBusService.channel('root').publish(eventName, {
            pusherChannel: channelName,
            pusherEvent: eventName,
            data: payload
          });

        } else {
          // Expose all incoming pusher events on single global channel for legacy
          // With each refactoring events can separated into different buckets
          EventBusService.channel('legacy').publish(eventName, {
            pusherChannel: channelName,
            pusherEvent: eventName,
            projectId: Number(taskId), // TODO: check if this really can only be taskId
            data: payload
          });
        }
        // Stop execution here - all none legacy channels are not compatible with legacy code below.
        // App processing and subscription to events should be done via EventBusService. See above.
        if (NON_LEGACY_CHANNELS.includes(channelName)) {
          $timeout();
          return;
        }
        /******************************************************/

        console.info('Sockets > event: ', eventName, channelName, payload);

        // --- For client only, if other user creates comment broadcast "notifications" event for client
        // this is needed because of missing pusher event

        // if (eventName === 'comment-created' &&
        //     channelName.indexOf('private-task-owner-task-') === 0 &&
        //     payload.user.id !== currentUser.id) {
        //   $timeout(() => {
        //     $rootScope.$broadcast('client::notification_create_comment', payload); // Fire the event for $scope listeners
        //   });
        // }
        // ---

        if (channelName.substr(channelName.length - 13, channelName.length) === 'notifications') {
          $timeout(() => {
            $rootScope.$broadcast('notification_' + eventName, payload); // Fire the event for $scope listeners
          });
        } else if (payload.eventOwner.id !== currentUser.id) {
          if (['comment-created', 'comment-updated', 'attachment-created', 'estimators-updated', 'estimate-updated',
            'invitation-created', 'invitation-updated'].indexOf(eventName) !== -1) {
            Restangular.restangularizeElement(null, payload, 'tasks/' + taskId + '/' + eventName.split('-')[0] + 's');
          }

          // ---- broadcast all events that are not on blacklist - they are handled separatly below - this should be refactored and combined
          const blacklistEvents = ['retainer-subscription-created', 'retainer-subscription-succeeded'];
          if (!blacklistEvents.includes(eventName)) {
            $timeout(() => {
              $rootScope.$broadcast(eventName, payload); // Fire the event for $scope listeners
            });
          }
        }

        // ---- client payment specific
        // - internal event delagation is separated by original design/implementation
        // - some events are not internaly broadcasted - this block of code fixes this issue for payment of subtasks and tasks
        if (payload.eventOwner && payload.eventOwner.id === currentUser.id && currentUser.role === 'client' && ['payment-successful', 'sub-task-payment-successful'].indexOf(eventName) !== -1) {
          $timeout(() => {
            $rootScope.$broadcast(eventName, payload); // Fire the event for $scope listeners
          });
        }
        // ----

        // ---- retainer specific for both client and expert
        /*
        if (['retainer-subscription-created', 'retainer-subscription-succeeded'].indexOf(eventName) !== -1) {
          $timeout(() => {
            $rootScope.$broadcast(eventName, payload); // Fire the event for $scope listeners
          });
        } */
        // ----

        // ---- Retainer subscription was created
        if (eventName === 'retainer-subscription-created') {
          $timeout(() => {
            $rootScope.$broadcast('retainer-subscription-created', payload);
          });
        }

        // ---- Retainer payment was processed by payment gateway
        if (eventName === 'retainer-subscription-succeeded' && channelName.includes('private-task-owner-task-')) {
          $timeout(() => {
            $rootScope.$broadcast('retainer-subscription-succeeded', payload);
          });
        }

        if (eventName === 'partner-mentioned') {
          $timeout(() => {
            $rootScope.$broadcast('partner-mentioned', payload);
          });
        }

        if (eventName.includes('project_quality_review')) {
          $timeout(() => {
            $rootScope.$broadcast('project-quality-review-updated', payload)
          });
        }
      });

      vm._channels[channelName] = channel; // Add the channel to local list for unsubscriptions
    };

    // This is called only once from Auth service
    // and is used to subscribed to presence channel
    // (for Rails to know where to dispatch events)
    var init = function (user, authToken) {
      console.log('[SocketsSrv] > init')
      currentUser = user

      vm.pusher = new Pusher(Configuration.pusher.appKey, {
        authEndpoint: Configuration.pusher.authEndpoint,
        authTransport: 'ajax',
        auth: { headers: { Authorization: `Bearer ${authToken}` } }
      })

      // Channels that are always open
      subscribe('presence-online');

      subscribe('private-user-' + currentUser.id + '-notifications');

      if (currentUser.role === 'contractor') {
        subscribe('private-contractors');
        subscribe('private-contractor-' + currentUser.id);
      }
    };

    var updateToken = function (token) {
      if (vm.pusher) {
        console.log('[SocketsSrv] > updateToken > token', token);
        vm.pusher.config.auth.headers.Authorization = 'Bearer ' + token;
      } else {
        Sentry.captureMessage('[SocketsSrv] > updateToken > pusher not intialized');
      }
    };

    var unsubscribe = function (channelName) {
      // console.log('UNSUBSCRIBE FROM: ' + channelName);
      if (vm._channels[channelName]) {
        vm._channels[channelName] = vm.pusher.unsubscribe(channelName); // undefined
      }
    };

    var unsubscribeAll = function () {
      for (var channel in vm._channels) {
        if (channel) {
          unsubscribe(channel);
        }
      }
    };

    var getChannel = function (channel) {
      return vm._channels[channel];
    };

    var isInitialized = function () {
      return Boolean(vm.pusher);
    }

    return {
      init: init,
      updateToken: updateToken,
      unsubscribe: unsubscribe,
      unsubscribeAll: unsubscribeAll,
      subscribe: subscribe,
      getChannel: getChannel,
      isInitialized: isInitialized,
    };
  }

  app.service('Sockets', ['$rootScope', '$timeout', 'Restangular', 'Configuration', 'Pusher', 'DataInterceptor', 'ApiLogging', 'EventBusService', 'Sentry', Sockets]);
})();
