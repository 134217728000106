import PaymentsStripeButtonComponent from './payments-stripe-button.component'
import PaymentsStripeService from './payments-stripe-button.service'

const PaymentsStripeButtonModule = angular.module('cdbl.payments-stripe-button', [

])
  .component('cdblPaymentsStripeButton', PaymentsStripeButtonComponent)
  .service('PaymentsStripeService', PaymentsStripeService)
  .name

export default PaymentsStripeButtonModule
