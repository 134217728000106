import templateUrl from './dropdown.html'
import './dropdown.scss'

import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light-border.css'


const DropdownComponent = {
  bindings: {
    optionsList: '<',
    onCreate: '&',
    valueProp: '@',
    labelProp: '@',
  },
  require: {
    model: '^ngModel'
  },
  templateUrl,
  transclude: {
    // dropdownMenu: 'dropdownMenu',
    // dropdownMenuItem: 'dropdownMenuItem'
  },
  controller: class DropdownComponentController {
    constructor ($attrs, $element, $timeout, EventEmitter) {
      'ngInject'
      this._identify = 'DropdownComponent'
      this.search = {
        name: ''
      }
      this.$attrs = $attrs
      this.$element = $element
      this.$timeout = $timeout
      this.EventEmitter = EventEmitter
      console.log('model', this.model)
    }

    $onInit () {
      this.data = this.model.$viewValue
      this.model.$render = () => {
        this.data = this.model.$viewValue
      }

      this.hideMenuOnClick = true
      this.uiAutocomplete = false

      this.searchString = ''
    }

    hideMenuOnClickHandler () {
      if (!this.hideMenuOnClick) {
        this.hideMenu()
      }
    }

    hideMenu () {
      if (this.tippyInstance) {
        this.tippyInstance.hide()
      }
    }
    showMenu () {
      if (this.tippyInstance) {
        this.tippyInstance.show()
      }
    }

    onOptionRemove (option) {
      if (Array.isArray(this.data) && typeof option === 'object') {
        const optionIndex = this.data.findIndex(o => o.id === option.id)
        if (optionIndex > -1) {
          this.data.splice(optionIndex, 1)
        }
      }
    }

    onOptionSelect (option) {
      // if (this.valueProp) {
      //   alert(option[this.valueProp])
      // } else {
      //   alert(option)
      // }

      if (Array.isArray(this.data)) {
        if (('limit' in this.$attrs) && this.data.selected.length === parseInt(this.$attrs.limit)) {
          return
        }
        this.data.push(option)
      } else {
        this.data = option
      }

      this.searchString = '' // reset name
      this.model.$setViewValue(this.data)
      this.model.$setDirty()
      this.model.$setTouched()
      this.checkValidity()

      this.hideMenu()
    }

    checkValidity () {
      if (typeof this.data === 'undefined') {
        return
      }

      if ('required' in this.$attrs) {
        if (this.data.length === 0) {
          // this.hasError = true;
          this.model.$setValidity('required', false)
        } else {
          // this.hasError = false;
          this.model.$setValidity('required', true)
        }
      }

      if ('max' in this.$attrs) {
        console.log('checkValidity > max', parseInt(this.$attrs.max), this.data.length > parseInt(this.$attrs.max))
        if (this.data.length > parseInt(this.$attrs.max)) {
          this.model.$setValidity('max', false)
        } else {
          this.model.$setValidity('max', true)
        }
      }
    }

    getOptionLabel (option) {
      if (this.labelProp) {
        return option[this.labelProp]
      }
      return option
    }

    $postLink () {
      // initialize tippy after initial digest so DOM is present
      this.$timeout(() => {
        console.log('[DropdownComponent] > $postLink > $timeout', this)
        // TODO:
        const parentElement = this.$element[0]
        const referenceElement = parentElement.querySelector('#dropdownReference')
        // const triggerElement = parentElement.querySelector('#dropdownTrigger')
        const template = parentElement.querySelector('#dropdownMenuTemplate')
        const templateWrapper = document.createElement('div')
        templateWrapper.appendChild(template)
        console.log('[DropdownComponent] > $postLink > template', template)
        this.tippyInstance = tippy(referenceElement, {
          content: templateWrapper,
          allowHTML: true,
          interactive: true,
          interactiveBorder: 30,
          interactiveDebounce: 75,
          trigger: 'manual',
          // triggerTarget: triggerElement,
          placement: 'bottom-start',
          ignoreAttributes: true,
          delay: 0,
          duration: [275, 250],
          // hideOnClick: true,
          maxWidth: 350,
          // offset: [20, 5],
          theme: 'light-border',
          // popperOptions: {
          //   placement: 'bottom-start'
          // },
          // popperOptions: {
          //   eventsEnabled: true,
          //   removeOnDestroy: true,
          // }
        })

        if (typeof this.onCreate !== 'function') {
          this.onCreate(this.EventEmitter({ $instance: this.tippyInstance }))
        }
      }, 0)
    }

    $onDestroy () {
      if (this.tippyInstance) {
        this.tippyInstance.destroy()
      }
    }
  }
}

export default DropdownComponent

        // <!-- <cdbl-dropdown
        //   ng-model="$ctrl.dropdownData"
        //   label-prop="name"
        //   value-prop="id"
        //   options-list="$ctrl.dropdownOptionsList">
        //   <cdbl-button ng-click="$parent.$ctrl.showMenu()">Dropdown trigger</cdbl-button>
        // </cdbl-dropdown> -->

