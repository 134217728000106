import SettingsCommsPreferencesComponent from './settings-comms-preferences.component'

const SettingsCommsPreferencesModule = angular.module('cdbl.settings-comms-preferences', [

])
  .component('cdblSettingsCommsPreferences', SettingsCommsPreferencesComponent)
  .config(($stateProvider) => {
    'ngInject'

    $stateProvider
      .state('settings.comms-preferences', {
        url: '/comms-preferences',
        views: {
          'settingsView': {
            template: '<cdbl-settings-comms-preferences></cdbl-settings-comms-preferences>'
          }
        },
        resolve: {
          permissionResolver: (AuthService) => {
            'ngInject'
            return AuthService.permissionResolver('isClient')
          }
        },
        layout: 'new'
      })
  })
  .name

export default SettingsCommsPreferencesModule
