const linksNewTab = function () {
  'ngInject'
  return function (html) {
    var el = document.createElement('span')
    el.innerHTML = html
    $(el).find('a').attr('target', '_blank')
    const processedHtml = el.innerHTML
    return processedHtml
  }
}
export default linksNewTab
