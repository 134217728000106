const CommentService = class CommentService {
  constructor ($http, $filter, Configuration) {
    'ngInject'
    this.$http = $http
    this.$filter = $filter
    this.Configuration = Configuration

    this.tmlEl = document.createElement('div')
  }

  postComment (taskId, text, isContractorsOnly = false) {
    let payload = {
      text: text,
      isContractorsOnly: isContractorsOnly
    }
    console.log('CommentService > postComment', taskId, payload, `${this.Configuration.apiUrl}/tasks/${taskId}/comments`)
    return this.$http
      .post(`${this.Configuration.apiUrl}/tasks/${taskId}/comments`, payload)
      .then(response => response.data)
  }

  updateComment (comment) {
    if (!comment || !comment.taskId || !comment.id) {
      console.warn('[CommentService] > updateComment > abort > comment missing crucial data', comment)
      return null
    }

    console.log('CommentService > updateComment', comment.taskId, comment.id, comment, `${this.Configuration.apiUrl}/tasks/${comment.taskId}/comments/${comment.id}`)
    return this.$http
      .put(`${this.Configuration.apiUrl}/tasks/${comment.taskId}/comments/${comment.id}`, comment)
      .then(response => response.data)
  }

  processCommentLinks (html) {
    // Add target _blank to links
    this.tmlEl.innerHTML = html
    const linkList = this.tmlEl.querySelectorAll('a')
    linkList.forEach(link => {
      link.setAttribute('target', '_blank')
    })

    // Additional processing goes here...
    return this.tmlEl.innerHTML
  }

  processComment (comment) {
    // This creates the fullname which is needed to render properly
    if (!comment.user.fullName) {
      comment.user.fullName = comment.user.firstName + ' ' + comment.user.lastName
    }

    const markedHTML = this.$filter('cdblMarked')(comment.text)
    comment.markedHTML = this.processCommentLinks(markedHTML)

    return comment
  }
}
export default CommentService
