// This should be put into trash ASAP...

(function () {
  'use strict';

  String.prototype.supplant = function (o) {
    return this.replace(
      /\{([^{}]*)\}/g,
      function (a, b) {
        var r = o[b];
        return typeof r === 'string' || typeof r === 'number' ? r : a;
      }
    );
  };

  String.prototype.escape = function () {
    var string = encodeURIComponent(String(this)); // encodes & and % that might cause JS error

    return decodeURIComponent(string).replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;');
  };

  String.prototype.capitaliseFirstLetter = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  };

  String.prototype.toSnakeCase = function () {
    var string = String(this);

    return string.replace(/([A-Z])/g, function ($1) {
      return '_' + $1.toLowerCase();
    });
  };

  String.prototype.toCamelCase = function () {
    var string = String(this);

    return string.replace(/(_[a-z])/g, function ($1) {
      return $1.toUpperCase().replace('_', '');
    });
  };

  String.prototype.truncate = function (n, useWordBoundary) {
    var toLong = this.length > n;
    var s_ = toLong ? this.substr(0, n - 1) : this;
    s_ = useWordBoundary && toLong ? s_.substr(0, s_.lastIndexOf(' ')) : s_;
    return toLong ? s_ + '...' : s_;
  };

  String.prototype.startsWith = function (str) {
    return this.indexOf(str) === 0;
  };

  Date.prototype.addDays = function (days) {
    var dat = new Date(this.valueOf());
    dat.setDate(dat.getDate() + days);
    return dat;
  };
})();
