(function () {
  'use strict';

  let domain = function () {
    let link = function ($scope, elem, attrs, ngModelController) {
      let domainText = attrs.domain;

      // prebuild social domain checkers
      // TODO: can be improved and made more robust with error messages
      var filters = {
        // linkedin - only domain must match
        linkedin: /(((ftp|http|https):\/\/)((www|\w\w)\.)?linkedin\.com)/i,
        // linkedin-strict - url must have valid domain and suffix to be valid
        'linkedin-strict': /(((ftp|http|https):\/\/)((www|\w\w)\.)?linkedin\.com\/)(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?/i
      };

      function _checkPrebuild(value) {
        if (value) {
          return filters[domainText].test(value);
        } else if ('required' in attrs) {
          return false;
        }
        return true;
      }

      function _checkDomain(value) {
        let valid = false;
        if (value) {
          if (value.length > domainText.length) {
            valid = value.substring(0, domainText.length) === domainText;
          }
        } else {
          valid = true;
        }
        return valid;
      }

      // based on domainText select prebuild filter check (if filter rules found) or use default domain check function
      var _checkCallback;
      if (angular.isDefined(filters[domainText])) {
        // console.log('linkedin domian filter > prebuild');
        _checkCallback = _checkPrebuild;
      } else {
        // console.log('linkedin domian filter > default');
        _checkCallback = _checkDomain;
      }

      $scope.$watch(attrs.ngModel, function (value, oldValue) {
        // var isValid = _checkCallback(value);
        if (value !== oldValue) {
          ngModelController.$setValidity('domain', _checkCallback(value));
        }
      });
    };

    return {
      require: 'ngModel',
      restrict: 'A',
      link: link
    };
  };

  app.directive('domain', domain);
})();
