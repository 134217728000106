import SettingsExpertTeamComponent from './settings-expert-team.component'
import SettingsExpertTeamService from './settings-expert-team.service'

import SettingsExpertTeamDetailsModule from './team-details'
import SettingsExpertTeamDetailsEditModalModule from './team-details-edit-modal'
import SettingsExpertTeamMemberModule from './team-member'
import SettingsExpertTeamMemberEditModalModule from './team-member-edit-modal'

const SettingsExpertTeamModule = angular.module('cdbl.settings-expert-team', [
  SettingsExpertTeamDetailsModule,
  SettingsExpertTeamDetailsEditModalModule,
  SettingsExpertTeamMemberModule,
  SettingsExpertTeamMemberEditModalModule
])
  .component('cdblSettingsExpertTeam', SettingsExpertTeamComponent)
  .service('SettingsExpertTeamService', SettingsExpertTeamService)
  .config(($stateProvider) => {
    'ngInject'

    $stateProvider
      .state('settings.expert-team', {
        url: '/expert-team',
        views: {
          'settingsView': {
            template: '<cdbl-settings-expert-team></cdbl-settings-expert-team>'
          }
        },
        resolve: {
          permissionResolver: (AuthService) => {
            'ngInject'
            return AuthService.permissionResolver('isExpert')
          }
        },
        layout: 'new'
      })
  })
  .name

export default SettingsExpertTeamModule
