(function () {
  'use strict';

  var __bind = function (fn, me) {
    return function () {
      return fn.apply(me, arguments);
    };
  };

  var Modal = (function () {
    function Modal ($timeout, ModalService) {
      this.defaultSettings = {
        // Set default to true because of problems
        // closeButton: false,
        closeButton: true,
        cssClass: ''
      };

      this.close = __bind(this.close, this);
      this.open = __bind(this.open, this);
      this.isOpen = __bind(this.isOpen, this);
      this.$timeout = $timeout;
      this.ModalService = ModalService; // new modal service
    }

    Modal.prototype.isOpen = function () {
      return Boolean(this.source);
    };

    Modal.prototype.open = function (source, scope, settings) {
      // Use timeout so that modal open method executes at the end of event loop so that all data will be available - to solve problem with legacy app improper scope bindings.
      // This solved issue with data not being available in legacy modals however it might clash with refactored modal as this will be executed at the end of the event loop.

      console.log('[modalSrv] > open > 1', this.ModalService.isOpened);
      this.$timeout(() => {
        if (this.ModalService.isOpened) {
          console.log('[modalSrv] > open > 2 > abort open');
          return // stop here and prevent legacy modal to be opened if any refactored modals are already opened
        }

        this.settings = angular.extend({}, this.defaultSettings, settings);
        this.source = source;
        this.scope = scope;

        console.log('[modalSrv] > open > 3', this.ModalService.isOpened);
      }, 250);
    };

    Modal.prototype.close = function () {
      this.scope = null;
      this.source = '';
    };

    return Modal;
  })();

  app.service('Modal', ['$timeout', 'ModalService', Modal]);
})();
