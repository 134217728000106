import templateUrl from './pagination.html'
import './pagination.scss'

const PaginationComponent = {
  bindings: {
    pagination: '<',
    onPageChange: '&'
  },
  templateUrl,
  controller: class Pagination {
    constructor () {
      'ngInject'
      this._identify = 'PaginationComponent'
    }

    $onInit () {
      if (typeof this.pagination === 'undefined') {
        throw new Error('Invalid pagination object')
      }
    }

    $onChanges (changes) {

    }

    get isPaginationReady () {
      if (this.pagination.mode === 'array') {
        return Boolean(this.pagination.paginationArray) // make sure pagination array exists if mode is array
      }

      return true
    }

    get showPrevious () {
      return this.pagination.currentPage > (this.pagination.firstPage === 0 ? 0 : 1)
    }

    get showNext () {
      return this.pagination.currentPage < (this.pagination.firstPage === 0 ? this.pagination.pageCount - 1 : this.pagination.pageCount)
    }

    get paginationItems () {
      const currentPage = this.pagination.currentPage + (this.pagination.firstPage === 0 ? 1 : 0)
      const lastPage = this.pagination.pageCount - 1 // this.pagination.firstPage === 0 ? (this.pageCount - 1) : this.pageCount

      const delta = 2

      let range = []
      for (let i = Math.max(2, currentPage - delta); i <= Math.min(lastPage, currentPage + delta); i++) {
        range.push(i)
      }

      if (currentPage - delta > 2) {
        range.unshift('...')
      }
      if (currentPage + delta < lastPage) {
        range.push('...')
      }

      range.unshift(1)
      range.push(this.pagination.pageCount)

      return range
    }

    isItemActive (item) {
      return this.pagination.currentPage === (this.pagination.firstPage === 0 ? (item - 1) : item)
    }

    resetCurrentPage () {
      this.pagination.currentPage = this.pagination.firstPage
    }

    notifyParent () {
      if (this.onPageChange) {
        this.onPageChange({
          page: this.pagination.currentPage
        })
      }
    }

    goToPage (index) {
      this.pagination.currentPage = this.pagination.firstPage === 0 ? (index - 1) : index
      console.log('[Pagination] goToPage', this.pagination.currentPage)
      this.notifyParent()
    }

    goToNextPage () {
      this.pagination.currentPage += 1
      console.log('[Pagination] goToNextPage', this.pagination.currentPage)
      this.notifyParent()
    }

    goToPrevPage () {
      this.pagination.currentPage -= 1
      console.log('[Pagination] goToPrevPage', this.pagination.currentPage)
      this.notifyParent()
    }

    goToFirst () {
      this.pagination.currentPage = this.pagination.firstPage
      console.log('[Pagination] goToFirst', this.pagination.currentPage)
      this.notifyParent()
    }

    goToLast () {
      this.pagination.currentPage = this.pagination.firstPage === 0 ? (this.pageCount - 1) : this.pagination.pageCount
      console.log('[Pagination] goToLast', this.pagination.currentPage)
      this.notifyParent()
    }
  }
}

export default PaginationComponent
