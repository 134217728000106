const helperVisibleOnPublic = 'Visible on your public profile'

const portfolioFieldsConfig = {
  title: {
    label: 'Title',
    description: null,
    helper: helperVisibleOnPublic,
    placeholder: 'Enter Title',
    validation: {
      min: 3,
      max: 65,
      required: true
    }
  },
  description: {
    label: 'Description',
    description: null,
    helper: helperVisibleOnPublic,
    placeholder: 'Add description',
    validation: {
      min: 70,
      max: 400,
      required: true
    }
  },
  siteUrl: {
    label: 'Link',
    description: 'Website, showcase site, etc.',
    helper: helperVisibleOnPublic,
    placeholder: 'e.g. http://example.com',
    validation: {
      max: 256
    }
  },
  image: {
    label: null,
    description: null,
    helper: '800px wide x 600px height minimum size or larger, up to 2MB, only one image is accepted',
    placeholder: '',
    validation: {
      required: true
    }
  }
}

export default {
  portfolioFieldsConfig
}
