(function () {
  'use strict';

  function checkbox() {
    var link = function ($scope, element, attrs) {
      $scope.right = 'rightIcon' in attrs;
    };

    return {
      restrict: 'E',
      scope: {
        model: '=',
        disable: '=',
        label: '@',
        sublabel: '@'
      },
      replace: true,
      templateUrl: require('../../views/directives/checkbox.html'),
      link: link
    };
  }

  app.directive('checkbox', [checkbox]);
})();
