// Services
import UIService from './ui.service'

// Modules
import LayoutModule from './layout'
import FormModule from './form'
import IconModule from './icon'
import ButtonModule from './button'
import DropdownModule from './dropdown'
import AutocompleteModule from './autocomplete'
import ModalModule from './modal'
import TooltipModule from './tooltip'
import PaginationModule from './pagination'
import LoaderModule from './loader'
import ErrorMessageModule from './error-message'
import StepperModule from './stepper'
import BannerModule from './banner'
import CollapsibleModule from './collapsible'
import TagModule from './tag'
import StatusBadgeModule from './status-badge'
import SkeletonModule from './skeleton'
import CardModule from './card'
import FilterWidgetModule from './filter-widget'
import NoResultsModule from './no-results'



// New directives for bootstrap
import cdblPopoverDirective from './popover.directive'

// Helper services
import mdMetaProvider from './mdMeta.provider'
import mdConstantService from './mdConstant.service'
import mdMediaService from './mdMedia.service'
import mdInteractionService from './mdInteraction.service'

const uiModule = angular.module('app.ui', [
  LayoutModule,
  FormModule,
  IconModule,
  ButtonModule,
  DropdownModule,
  AutocompleteModule,
  ModalModule,
  TooltipModule,
  PaginationModule,
  LoaderModule,
  ErrorMessageModule,
  StepperModule,
  BannerModule,
  CollapsibleModule,
  TagModule,
  StatusBadgeModule,
  SkeletonModule,
  CardModule,
  FilterWidgetModule,
  NoResultsModule
])

  .service('UIService', UIService)
// Bootstrap 3 new directives
  .directive('cdblPopover', cdblPopoverDirective)
// Grid helper services
  .service('$mdConstant', mdConstantService)
  .service('$mdMedia', mdMediaService)
  .service('$mdInteraction', mdInteractionService)
  .provider('$$mdMeta', mdMetaProvider)

  .name

export default uiModule

