(function () {
  'use strict';

  function ValidationInterceptor($rootScope, $q, DataInterceptor) {
    return {
      responseError: function (response) {
        if (response.status === 422 || response.status === 409) {
          response.data = DataInterceptor.convertCase(response.data, 'toCamelCase');
          $rootScope.$broadcast('server-errors', response);
        }

        return $q.reject(response);
      }
    };
  }

  app.factory('ValidationInterceptor', ['$rootScope', '$q', 'DataInterceptor', ValidationInterceptor]);

  app.config(['$httpProvider', function ($httpProvider) {
    $httpProvider.interceptors.push('ValidationInterceptor');
  }]);
})();
