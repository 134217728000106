import templateUrl from './presets-list.html'
import './presets-list.scss'

const ProjectPresetsListComponent = {
  bindings: {},
  templateUrl,
  controller: class ProjectPresetsListComponent {
    constructor ($stateParams, ProjectPresetsService, $state, SegmentAnalytics) {
      'ngInject'
      this._identify = 'ProjectPresetsListComponent'
      this.$stateParams = $stateParams
      this.$state = $state
      this.ProjectPresetsService = ProjectPresetsService
      this.SegmentAnalytics = SegmentAnalytics
    }

    $onInit () {
      console.log('this.$stateParams', this.$stateParams)
      this.ProjectPresetsService.trackEventParentPresetsListLoaded(this.$stateParams)

      this.isLoading = false
      this.presetsList = []

      this.isLoading = true
      this.ProjectPresetsService.loadParentPresetsList(this.$stateParams)
        .then(presetsList => {
          this.presetsList = presetsList
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    getPresetParams (preset) {
      const params = {
        form: preset.form
      }
      params[preset.definerType] = preset.definer
      return params
    }
  }
}

export default ProjectPresetsListComponent
