(function () {
  'use strict';

  function toSlug(getSlug) {
    return function (text) {
      if (text) {
        return getSlug(text);
      }
    };
  }

  app.filter('toSlug', ['getSlug', toSlug]);
})();
