import templateUrl from './partner-details-modal.html'
import './partner-details-modal.scss'

const PartnerDetailsModalComponent = {
  bindings: {
    partners: '<?',
    referring: '<?'
  },
  templateUrl,
  require: {
    modalCtrl: '^^cdblModal'
  },
  controller: class PartnerDetailsModalComponent {
    constructor () {
      'ngInject'
    }
  }
}

export default PartnerDetailsModalComponent
