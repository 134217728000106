import templateUrl from './delete.html'
import './delete.scss'


const AccountDeletedModalComponent = {
  bindings: {
    portfolio: '<?',
    onSave: '&?'
  },
  require: {
    modalCtrl: '^^cdblModal'
  },
  templateUrl,
  controller: class AccountDeletedModalComponent {
    constructor ($timeout, EventEmitter, DeleteAccountService, ModalService, Configuration) {
      'ngInject'
      this._identify = 'AccountDeletedModalComponent'
      this.$timeout = $timeout
      this.EventEmitter = EventEmitter
      this.DeleteAccountService = DeleteAccountService
      this.ModalService = ModalService
      this.Configuration = Configuration
    }

    $onInit () {
      this.formData = {
        email: '',
        current_password: '',
      }
    }

    onChangeField (fieldName, validationRule) {
      this.deleteForm[fieldName].$setValidity(validationRule, true)
    }

    deleteAccount () {
      this.isProcessing = true
      this.DeleteAccountService.deleteAccount(this.formData)
        .then(response => {
          this.ModalService.clear()
          this.ModalService.close()
          this.ModalService.open({
            closeOnBackdrop: false,
            closeIcon: true,
            layout: 'center',
            class: 'cdbl-account-modal cdbl-delete-account-result-dialog cdbl-modal--style-with-dividers cdbl-modal--style-with-sticky-header-and-footer',
            template: `
              <cdbl-delete-account-result-dialog>
              </cdbl-delete-account-result-dialog>`,
            success: 'Your account was successfully suspended and your personal data will be removed within 30 days.',
            title: 'DELETE YOUR PERSONAL DATA'
          })
        })
        .catch(errors => {
          if (errors.error === 'correctPassword') {
            this.deleteForm.password.$setValidity('correctPassword', false)
          } else if (errors.error === 'correctEmail') {
            this.deleteForm.email.$setValidity('correctEmail', false)
          } else {
            this.ModalService.open({
              closeOnBackdrop: false,
              closeIcon: true,
              layout: 'center',
              class: 'cdbl-account-modal cdbl-delete-account-result-dialog cdbl-modal--style-with-dividers cdbl-modal--style-with-sticky-header-and-footer',
              template: `
                <cdbl-delete-account-result-dialog>
                </cdbl-delete-account-result-dialog>`,
              errors: errors.data.errors,
              title: 'DELETE YOUR PERSONAL DATA'
            })
          }
        })
        .finally(() => {
          this.isProcessing = false
        })
    }

    closeDialog () {
      this.modalCtrl.close()
    }
  }
}

export default AccountDeletedModalComponent
