const FieldSelectDirective = function () {
  return {
    restrict: 'E',
    require: {
      fieldCtrl: '?^cdblFieldContainer',
      ngModel: '?ngModel'
    },
    bindToController: true,
    link: ($scope, $element, $attrs, $controllers) => {
      if (typeof $attrs.ignoreFieldCtrl === 'undefined' && $controllers.fieldCtrl) {
        $controllers.fieldCtrl.setFieldElementCtrl({
          ngModel: $controllers.ngModel,
          $element: $element,
          $attrs: $attrs
        })

        $scope.$on('$destroy', function () {
          $controllers.fieldCtrl.setFieldElementCtrl(null)
        })
      }
    }
  }
}

export default FieldSelectDirective
