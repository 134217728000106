const presetFields = {
    // Primary fields
  introMessage: {
    value: '',
    hidden: false,
    locked: true,
  },
  title: {
    value: '',
    hidden: false, // TODO: can not be hidden if empty
    locked: true
  },
  description: {
    value: '',
    hidden: false, // TODO: can not be hidden if empty
    locked: true,
  },
  descriptionNotesEnable: {
    value: false,
    hidden: false,
    locked: true,
  },
  descriptionNotes: {
    value: '',
    hidden: false,
    locked: false
  },
  websiteUrl: {
    value: '',
    hidden: false,
    locked: true,
  },

  // Secondary fields, by default hidden from definers unless configured by CS
  projectType: {
    value: 'other', // safeguarding with default value we will always support
    hidden: true,
    locked: true,
  },
  projectSubject: {
    value: 'other', // safeguarding with default value we will always support
    hidden: true,
    locked: true,
  },
  preferredExperts: {
    value: [],
    hidden: true,
    locked: true,
  },
  preferredExpertsPublishForAll: {
    value: false, // publish for all in 24h
    hidden: true,
    locked: true,
  },
  complexity: {  // BE expects string/enum
    value: null, // return null as field is optional and we do not have default value
    hidden: true,
    locked: true,
  },
  urgency: {     // BE expects integer
    value: null, // return null as field is optional and we do not have default value
    hidden: true,
    locked: true,
  },
  shortcode: {
    value: '',
    hidden: true,
    locked: true,
  },
  expertPodId: { // BE expects integer
    value: null, // return null as field is optional and we do not have default value
    hidden: true,
    locked: true,
  },
  couponCode: {
    value: '',
    hidden: true,
    locked: true,
  },
  internalTrackingId: {
    value: '',
    hidden: true,
    locked: true
  },

  // System fields - CS only / will become editable only via admin or hardcoded
  definerType: {
    value: null,
    // TODO: one way would be to append options: [{ id: 'partner', name: 'Partner' }, { id: 'expert', name: 'Expert' }, { id: 'staff', name: 'Staff' }],
    hidden: true,
    locked: true,
  },
  definer: {
    value: '', // TODO: TDB, conditionally visible based on definerType
    hidden: true,
    locked: true,
  },
  form: {
    value: '',
    hidden: true,
    locked: true,
  },
  multiUseLink: {
    value: false,
    hidden: true,
    locked: true,
  },
  expireDurationDays: {
    value: 7,
    hidden: true,
    locked: true,
  },
  titlePrefix: {
    value: '',
    hidden: true,
    locked: true,
  },
  definerCanEditLocked: {
    value: false,
    hidden: true,
    locked: true
  }
}

const presetConfig = {
  version: 'v1.2'
}

const presetResources = {
  taskProjectTypes: {
    method: 'GET',
    url: '/enum_values?model_attribute=project_type&model_name=task'
  },
  taskProjectSubjects: {
    method: 'GET',
    url: '/enum_values?model_attribute=project_subject&model_name=task'
  },
  taskProjectUrgency: {
    method: 'GET',
    url: '/enum_values?model_attribute=urgency&model_name=task'
  },
  taskProjectComplexity: {
    method: 'GET',
    url: '/enum_values?model_attribute=complexity&model_name=task'
  },
  taskExpertsPreferrables: {
    method: 'GET',
    url: '/experts/preferrables'
  },
  expertPodIds: {
    method: 'GET',
    url: '/enum_values?model_attribute=id&model_name=expert_pod'
  }
}

const fieldsLockedAllowEdit = ['websiteUrl']
const urlPattern = new RegExp('^(http[s]?:\\/\\/){0,1}(www\\.){0,1}[a-zA-Z0-9\\.\\-]+\\.[a-zA-Z]{2,15}[\\.]{0,1}(\\/.*)?(\\?.*)?$')

export { presetFields, presetConfig, presetResources, urlPattern, fieldsLockedAllowEdit }
