(function () {
  'use strict';

  function User(SegmentAnalytics, ExpertProfileViewModalService, ClientProfileService) {
    var modalScope = {};

    const showProfile = function (task, userId, userRole) {
      if (userRole && userRole === 'client') {
        ClientProfileService.showProfileModal(task);
        SegmentAnalytics.trackClientProfileOpened(task, userId)
      } else {
        ExpertProfileViewModalService.showPublicProfile(userId)
        SegmentAnalytics.trackExpertProfileOpened(task, userId)
      }
    };

    return {
      showProfile: showProfile,
    };
  }

  app.service('User', ['SegmentAnalytics', 'ExpertProfileViewModalService', 'ClientProfileService', User])
})();
