const DevService = class DevService {
  constructor () {
    'ngInject'
    this.identify = 'DevService'
    this.actions = []
  }

  registerAction (actionConfig) {

  }
}
export default DevService
