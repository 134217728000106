import templateUrl from './project-review-actions.html'
import './project-review-actions.scss'

const ProjectReviewActionsComponent = {
  bindings: {
    project: '<',
  },
  templateUrl,
  controller: class ProjectReviewActionsComponent {
    constructor (ProjectQualityReviewService) {
      'ngInject'
      this._identify = 'ProjectReviewActionsComponent'
      this.ProjectQualityReviewService = ProjectQualityReviewService
    }

    get canClaim () {
      return this.project?.qualityReview?.actions.find(action => action.name === 'claim')
    }

    get actions () {
      return this.project?.qualityReview?.actions
    }

    get reviewer () {
      return this.project?.qualityReview?.reviewer || null
    }

    actionChanged () {
      this.unsavedChanges = true
    }

    executeAction () {
      if (!this.action) {
        this.action = this.canClaim
      }

      this.ProjectQualityReviewService.claimForReview(this.action.verb, this.action.url, this.action.payload)
        .then(response => {
          this.unsavedChanges = false
          this.project = response
          this.action = null
          console.log(response)
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}

export default ProjectReviewActionsComponent
