class FileSizeError extends Error {
  constructor (fileName) {
    const messageText = fileName ? `File is to big: ${fileName}` : 'File is to big'
    super(messageText)

    this.name = 'FileSizeError'
    this.description = 'Try file with smaller size.'
  }
}

export default FileSizeError
