// Note: this feature was changed from original implementation to use BE provided endpoints for all resources and actions, it could be improved by holding state of profile and portfolio items in the service, simplifing some of the code below.

const ExpertProfileService = class ExpertProfileService {
  constructor ($http, $q, $timeout, Configuration, UserService) {
    'ngInject'

    this.$http = $http
    this.$q = $q
    this.$timeout = $timeout
    this.Configuration = Configuration
    this.UserService = UserService

    this.profileAPI = {

    }
    this.portfolioAPI = {}
  }

  // API METHODS
  // ------------------------------------------

  get profileUrl () {
    return `${this.Configuration.apiUrl}/experts/profile`
  }

  // Profile
  getProfile () {
    console.log('getProfile')
    const requestConfig = { ...this.UserService.user.resources.draftProfile }

    requestConfig.url = `${this.Configuration.apiUrl}${requestConfig.url}`
    return this.$http(requestConfig)
      .then(response => {
        if (response.data && Object.keys(response.data).length === 0) {
          return null // handle empty object case (TODO: look into data intercepter)
        }
        return response.data
      })
  }

  saveProfile (formData) {
    const payload = {
      shortBio: formData.shortBio,
      longBio: formData.longBio,
      location: formData.location ? formData.location.id : null,
      timezone: formData.timezone ? formData.timezone.id : null,
      expertRole: formData.expertRole ? formData.expertRole.id : null,
      coreSpeciality: formData.coreSpeciality ? formData.coreSpeciality.id : null,
      skills: formData.skills.map(s => s.id),
      languages: formData.languages.map(l => l.id),
      preferredProjectTypes: formData.preferredProjectTypes.map(p => p.id),
      showOnWebsite: formData.showOnWebsite,
      portfolioItems: formData.portfolio.items.map(p => {
        return { id: p.id, inProfile: p.inProfile }
      })
    }

    const requestConfig = { ...formData.actions.saveDraft }
    requestConfig.url = `${this.Configuration.apiUrl}${requestConfig.url}`
    return this.$http({ ...requestConfig, data: payload })
      .then(response => response.data)
  }

  publishDraft (formData) {
    const payload = {}
    const requestConfig = { ...formData.actions.publishDraft }
    requestConfig.url = `${this.Configuration.apiUrl}${requestConfig.url}`
    return this.$http({ ...requestConfig, data: payload })
      .then(response => response.data)
  }

  // Datasets
  getAllDatasets (profileResources) {
    // locations will be handled by autocomplete, do not load self again, remove both from list of preloaded resources
    const { locations, self, ...resources } = profileResources
    const promises = []
    const resourcesMap = Object.entries(resources)
    resourcesMap.forEach(([resourceName, resourceConfig]) => {
      resourceConfig.url = `${this.Configuration.apiUrl}${resourceConfig.url}`
      const p = this.$http(resourceConfig)
        .then(response => [resourceName, response.data])
      promises.push(p)
    })

    return this.$q.all(promises).then(response => Object.fromEntries(response))
  }

  // Reviews
  getAvailableReviews (favoriteReviews, pagination) {
    const params = pagination ? {
      page: pagination.currentPage,
      per_page: pagination.itemsPerPage
    } : {}

    const resourceConfig = { ...favoriteReviews.resources.reviews, params }
    resourceConfig.url = `${this.Configuration.apiUrl}${resourceConfig.url}`
    return this.$http(resourceConfig)
      .then(response => {
        if (pagination && typeof pagination.updateFromHeaders === 'function') {
          pagination.updateFromHeaders(response)
        }
        return response.data
      })
  }

  updateFavoriteReviews (selectedReviews, favoriteReviews) {
    const requestConfig = { ...favoriteReviews.actions.update }
    requestConfig.url = `${this.Configuration.apiUrl}${requestConfig.url}`
    const payload = {
      items: selectedReviews.map(r => { return { id: r.id } })
    }
    return this.$http({ ...requestConfig, data: payload })
      .then(response => response.data)
  }


  // Portfolio
  refechPortfolio (portfolio) {
    const resourceConfig = { ...portfolio.resources.self }
    resourceConfig.url = `${this.Configuration.apiUrl}${resourceConfig.url}`
    return this.$http(resourceConfig)
      .then(response => {
        return response.data
      })
  }

  preparePortfolioItemRequest (data, resourceActionConfig) {
    const fd = new FormData()
    const config = {
      url: `${this.Configuration.apiUrl}${resourceActionConfig.url}`,
      method: resourceActionConfig.method,
      transformRequest: angular.identity,
      disableRequestDataInterceptor: true,
      headers: {
        'Content-Type': undefined
      }
    }

    fd.append('title', data.title)
    fd.append('description', data.description)
    fd.append('site_url', data.siteUrl)

    if (data.imageList && data.imageList[0]) {
      fd.append('featured_image', data.imageList[0])
    }

    return { ...config, data: fd }
  }

  createPortfolioItem (data, portfolio) {
    const requestConfig = this.preparePortfolioItemRequest(data, portfolio.actions.addItem)
    return this.$http(requestConfig)
      .then(response => response.data)
  }

  updatePortfolioItem (data, portfolioItem) {
    const requestConfig = this.preparePortfolioItemRequest(data, portfolioItem.actions.update)
    return this.$http(requestConfig)
      .then(response => response.data)
  }

  savePortfolioItem (data, portfolioItem = null, portfolio) {
    if (portfolioItem) {
      return this.updatePortfolioItem(data, portfolioItem)
    }

    return this.createPortfolioItem(data, portfolio)
  }

  deletePortfolioItem (portfolioItem) {
    const requestConfig = { ...portfolioItem.actions.delete }
    requestConfig.url = `${this.Configuration.apiUrl}${requestConfig.url}`
    return this.$http(requestConfig)
      .then(response => response.data)
  }

  saveProfileVisibility (showOnWebsite, toggleVisibilityAction) {
    const payload = {
      showOnWebsite: showOnWebsite
    }
    const requestConfig = { ...toggleVisibilityAction }
    requestConfig.url = `${this.Configuration.apiUrl}${requestConfig.url}`
    return this.$http({ ...requestConfig, data: payload })
      .then(response => response.data)
  }
}
export default ExpertProfileService

