(function () {
  'use strict';
  function ActivitiesCtrl($scope, $timeout, DataManipulation, marked, markupFilter, _, SegmentAnalytics, UserService, ActivityStreamService) {
    console.log('ActivitiesCtrl > $scope', $scope);

    $scope.UserService = UserService;
    $scope.activities = $scope.data.activities;

    // TODO: move this to estimation/room / woorkroom
    // TODO: refactor code not to use $watch
    $scope.$watch('task.subtasks', function (subTasks) {
      if (!subTasks) {
        return;
      }

      $scope.task.subTasksPrice = {
        sumBasePrice: 0,
        sumWithClientFee: 0,
        sumBasePricePaid: 0,
        sumWithClientFeePaid: 0
      };
      $scope.task.subTasksPriceSumWithClientFee = 0;

      subTasks.forEach(function (subTask) {
        if (subTask.state === 'canceled') {
          return;
        }

        if (subTask.state !== 'published') {
          $scope.task.subTasksPrice.sumBasePricePaid += subTask.prices.basePrice;
          $scope.task.subTasksPrice.sumWithClientFeePaid += subTask.prices.priceWithClientFee;
        }

        $scope.task.subTasksPrice.sumBasePrice += subTask.prices.basePrice;
        $scope.task.subTasksPrice.sumWithClientFee += subTask.prices.priceWithClientFee;
      });
    }, true);
    // ---

    $scope.$on('comment-created', function (event, comment) {
      if (comment.taskId !== $scope.task.id) {
        return;
      }

      // comment.verb = 'create_comment';

      ActivityStreamService.addActivity(comment);
    });

    $scope.$on('comment-updated', function (event, comment) {
      // comment.verb = 'create_comment';
      // comment.textMarked = markupFilter(comment.text); // New lines filter must be before marked

      ActivityStreamService.addActivity(comment);
    });

    /**
     * Moved to ActivityStream component and using EventBusService
     */
    // $scope.$on('attachment-created', function (event, attachment) {
    //   console.log('[ActivitiesCtrl] > attachment-created', event)
    //   attachment.verb = 'create_attachment';
    //   ActivityStreamService.addActivity(attachment);
    // });
    // $scope.$on('attachment-removed', function (event, attachment) {
    //   const activity = {
    //     createdAt: attachment.createdAt,
    //     verb: 'destroy_attachment',
    //     attachment: attachment.name
    //   };
    //   ActivityStreamService.addActivity(activity);
    // });

    $scope.$on('task-completed', function (event, task) {
      // task.verb = 'complete_task';

      ActivityStreamService.addActivity(task);
    });

    $scope.$on('invitation-created', function (event, data) {
      // TODO: refactor with invitations
      // set contractor id so that remove button gets hidden
      $scope.task.contractor = {
        id: data.userId
      };
    });

    // Special case - see activity stream service processActivity method
    $scope.$on('estimate-updated', function (event, data) {
      // const activity = {
      //   verb: 'create_estimate',
      //   estimator: data.eventOwner,
      //   createdAt: new Date()
      // };
      console.log('[ActivitiesCtrl] estimate-updated', JSON.stringify(data))
      ActivityStreamService.addActivity(data);

      $scope.task.currentUserShouldGiveNoEstimateFeedback = false;
    });

    function _pushSubTask(subTask) {
      // we need to create new same object because of angular hash in array
      const activity = JSON.parse(JSON.stringify(subTask));

      ActivityStreamService.addActivity(activity);
    }

    $scope.$on('sub-task-created', function (event, subTask) {
      // subTask.verb = 'create_sub_task';
      _pushSubTask(subTask);
    });

    $scope.$on('sub-task-payment-successful', function (event, subTask) {
      if (subTask.parentTask && $scope.$parent.task.id !== subTask.parentTask.id) {
        return;
      }

      // if retainer skip this event and wait for "retainer.subscription-succeeded" event
      if (subTask.retainer) {
        return;
      }

      // subTask.verb = 'sub_task_payment_successful';
      _pushSubTask(subTask);
    });

    $scope.$on('retainer-subscription-succeeded', function (event, subTask) {
      // console.log('activitiesCtrl retainer-subscription-succeeded');
      if (subTask.parentTask && $scope.$parent.task.id !== subTask.parentTask.id) {
        return;
      }

      // subTask.verb = 'retainer_subscription_succeeded';
      _pushSubTask(subTask);
    });

    $scope.$on('sub-task-completed', function (event, subTask) {
      // subTask.verb = 'complete_sub_task';
      _pushSubTask(subTask);
    });

    $scope.$on('sub-task-canceled', function (event, subTask) {
      console.log('$on sub-task-created ActivitiesCtrl', subTask);
      // subTask.verb = 'cancel_sub_task';
      _pushSubTask(subTask);
    });
  }

  app.controller('ActivitiesCtrl', ['$scope', '$timeout', 'DataManipulation', 'marked', 'markupFilter', '_', 'SegmentAnalytics', 'UserService', 'ActivityStreamService', ActivitiesCtrl]);
})();
