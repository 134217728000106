import templateUrl from './workroom.html'
import './workroom.scss'

const WorkroomComponent = {
  bindings: {},
  templateUrl,
  controller: class Workroom {
    constructor () {
      'ngInject'
      this._identify = 'WorkroomComponent'
    }
  }
}

export default WorkroomComponent
