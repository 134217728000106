(function () {
  'use strict';

  function timezoneToCurrentTime() {
    return function (offset) {
      var d = new Date();
      var utc = d.getTime() + (d.getTimezoneOffset() * 60000);
      var nd = new Date(utc - (60000 * offset));

      return nd;
    };
  }

  app.filter('timezoneToCurrentTime', timezoneToCurrentTime);
})();
