import templateUrl from './transactions.html'
import './transactions.scss'

const TransactionsComponent = {
  bindings: {},
  templateUrl,
  controller: class TransactionsComponent {
    constructor (UIService, UserService, TransactionsService, PaginationService, ModalService) {
      'ngInject'
      this._identify = 'TransactionsComponent'
      this.UIService = UIService
      this.UserService = UserService
      this.TransactionsService = TransactionsService
      this.PaginationService = PaginationService
      this.ModalService = ModalService
    }

    $onInit () {
      this.transactionsList = []
      this.stats = null
      this.isLoading = false
      this.EXPORT_MAX_TRANSACTIONS = 1000

      this.sortOptions = [
        {
          id: 'created_at asc',
          name: 'Ascending'
        },
        {
          id: 'created_at desc',
          name: 'Descending',
          default: true
        }
      ]

      this.filterData = {
        sort: 'created_at desc'
      }

      this.userOptionsListApi = {
        method: 'get',
        urlSlug: `/${this.UserService.apiRoleSlug}/search?type=${this.UserService.isClient() ? 'experts' : 'clients'}`,
        paramName: 'q'
      }
      this.projectOptionsListApi = {
        method: 'get',
        urlSlug: `/${this.UserService.apiRoleSlug}/search?type=projects`,
        paramName: 'q'
      }
      this.transactionTypes = this.TransactionsService.getTransactionTypes()

      this.pagination = this.PaginationService.init({
        itemsPerPage: 20,
        mode: 'server'
      })

      this.loadTransactionsList()

      // load and display stats in sidebar only for experts
      if (this.showRightSidebar) {
        this.isLoadingStats = true
        this.TransactionsService.getStats()
          .then(response => {
            this.stats = response
          })
          .finally(() => {
            this.isLoadingStats = false
          })
      }
    }

    openSidebar () {
      const sidebar = this.UIService.getComponent('sidebar-transactions-right')
      sidebar.open()
    }

    onFilterApplyHandler ($event) {
      console.log('[TransactionsComponent] > onFilterApplyHandler', $event)
      this.filterData.sort = $event.filter.sort
      this.loadTransactionsList()
    }
    onFilterClearHandler ($event) {
      console.log('[TransactionsComponent] > onFilterClearHandler', $event)
      this.filterData = {}
      this.filterData.sort = $event.filter.sort
      this.loadTransactionsList()
    }

    onPageChangeHandler (page) {
      this.loadTransactionsList()
    }

    // TODO: in near future replace confirmation modal below with new standardized confirmation modal (which is in staging at the moment and not yet mereged into production)
    // TODO: use also promisable modal feature and after wire its completed reload the stats to refresh them
    openWithdrawalModal () {
      if (this.UserService.user.withdrawalsEnabled) {
        // Modal.open('views/modals/withdrawal.html', $scope, { closeButton: true })
        this.ModalService.open({
          closeOnBackdrop: false,
          closeIcon: true,
          template: `<cdbl-withdrawal-modal></cdbl-withdrawal-modal>`
        })
      } else {
        this.ModalService.open({
          closeOnBackdrop: true,
          closeIcon: false,
          body: 'Your withdrawals have temporarily been disabled. Contact support for more details.',
          actions: [
            {
              label: 'OK',
              class: 'button-uppercase',
              callback: () => {
                this.ModalService.close()
              }
            }
          ]
        })
      }
    }

    openEarningsCertificateModal () {
      this.ModalService.open({
        closeOnBackdrop: false,
        size: 'large',
        closeIcon: true,
        template: `<cdbl-earnings-certificate-modal></cdbl-earnings-certificate-modal>`
      })
    }

    loadTransactionsList () {
      this.isLoading = true
      this.TransactionsService.getTransactions(this.pagination, this.filterData)
        .then(response => {
          console.log('[TransactionsComponent] > loadTransactionsList', response)
          this.transactionsList = response
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    get canExportCSV () {
      return this.pagination.totalCount > 0 && this.pagination.totalCount <= this.EXPORT_MAX_TRANSACTIONS
    }

    get showExportCSVRestrictionInfo () {
      return this.pagination.totalCount >= this.EXPORT_MAX_TRANSACTIONS
    }

    get showSidebarToggle () {
      return this.showRightSidebar && !this.UIService.$mdMedia('gt-sm')
    }

    get showRightSidebar () {
      return this.UserService.isExpert()
    }


    exportCSV () {
      this.TransactionsService.exportCSV(this.pagination, this.filterData)
        .then(response => {
          console.log('[TransactionsComponent] > exportCSV > response', response)
        })
        .catch(err => {
          console.log('[TransactionsComponent] > exportCSV > err', err)
        })
    }
  }
}

export default TransactionsComponent
