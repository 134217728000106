(function () {
  'use strict'

  function DisplayTaskCtrl ($scope, $timeout, $location, $state, $stateParams, Auth, User, Storage, taskResource, Modal, Flash, Sockets, PaymentsService, SegmentAnalytics, CommentService, UserService, IntercomService, ModalService, ProjectService, EventBusService) {
    $scope.UserService = UserService
    $scope.ProjectService = ProjectService



    // Shim - workaround so that task is always returned fresh from ProjectService and it is not lost by reference if updated in ProjectService - this is used so we dont need to migrate all existing variables in controllers/templates
    Object.defineProperty(taskResource, 'task', { get: () => ProjectService.project })
    Object.defineProperty($scope, 'task', { get: () => ProjectService.project })
    // ---

    ProjectService.setProjectPageTitle()

    $scope.data = taskResource // So children scopes will have access

    $scope.me = Auth.me()
    $scope.taskEstimators = taskResource.estimators

    console.log('DisplayTaskCtrl > init, $scope.task', $scope.task)

    $scope.openCancelProjectModal = () => {
      ModalService.open({
        closeOnBackdrop: false,
        closeIcon: false,
        class: 'project-cancel-modal',
        layout: 'center',
        template: `<cdbl-project-cancel-modal></cdbl-project-cancel-modal>`,
        project: $scope.task,
        onCanceled: () => {
          $scope.task.state = 'canceled' // TODO: handle project changes from service/store
          Flash.show('views/flashes/task_canceled.html', true, $scope) // TOOD: refactor and use new Flash component
        }
      })
    }

    $scope.showIntercom = function () {
      IntercomService.showMessage()
    }

    if ($scope.task && $scope.task.prices && ($scope.task.prices.clientDiscounts !== 0 || $scope.task.prices.promotionDiscounts !== 0) && !UserService.isExpert()) {
      Flash.show('views/flashes/coupon_code_estimation_room.html', true, $scope)
    }

    if ($scope.task.state === 'abandoned') {
      Flash.show('views/flashes/task_abandoned.html', true, $scope)
    }

    // TODO: refactor this and move to proper event handling inside ProjectService, avoid using $watch
    $scope.$watch('task.state', function (newState, oldState) {
      console.log('DisplayTaskCtrl > task > state changed', oldState, '>>>', newState)

      if (newState === 'hired' && UserService.isUserClientOnProject($scope.task)) {
        if (angular.isDefined($scope.task) && angular.isDefined($scope.task.id)) { // extra check for task to exist before opening deposit modal
          console.log('DisplayTaskCtrl > deposit funds modal open > success')
          if ($scope.task.state !== 'paid' && !$scope.task.paymentConfirmed) {
            $scope.openDepositModal()
          }
        } else {
          console.log('DisplayTaskCtrl > deposit funds modal open > aborted - task missing')
        }

        if ($scope.task.paymentConfirmed === false) {
          Flash.show('views/flashes/deposit_money.html', true, $scope)
        }
      } else if (newState === 'hired' && UserService.isExpert()) {
        if (UserService.isUserHiredExpertOnProject($scope.task) &&
            typeof Sockets.getChannel(`private-task-owner-task-${$stateParams.id}`) === 'undefined'
        ) {
          console.log('DisplayTaskCtrl > subscribe HIRED expert to private channel')
          Sockets.unsubscribe('private-contractor-task-' + $stateParams.id, $stateParams.id)
          Sockets.subscribe('private-task-owner-task-' + $stateParams.id, $stateParams.id)
        } else {
          console.log('DisplayTaskCtrl > redirect NON HIRED away from project')
          $state.go('tasks.hiredExpert', { id: $stateParams.id })
        }
      }
    })

    $scope.$on('consultation-conversion-enabled', function () {
      $scope.task.convertibleToConsultation = true
    })

    $scope.$on('project-converted', function (event, data) {
      console.log('DisplayTaskCtrl > project-converted', event, data)
      if ($scope.task.id === data.id) {
        Object.assign($scope.task, {
          kind: data.kind,
          projectType: data.projectType,
          projectSubject: data.projectSubject,
          convertibleToConsultation: data.convertibleToConsultation
        })
      }
    })

    $scope.$on('payment-successful', function (event, task) {
      console.log('DisplayTaskCtrl > payment-successful', task)
      if (task.id === $scope.task.id) {
        if ($scope.task.paymentConfirmed === false && UserService.isUserClientOnProject($scope.task)) {
          // --- if in display state and pusher event for payment is received check if was wired payment
          const modalScope = {
            task: $scope.task,
            offlinePaymentConfirm: function (task) {
              console.log('DisplayTaskCtrl > offlinePaymentConfirm')
              PaymentsService.offlinePaymentConfirmation(task).then(function (response) {
                console.log('DisplayTaskCtrl > offlinePaymentConfirm > response', response)
                angular.copy(task, $scope.task)
                SegmentAnalytics.trackTaskPayment(task, 'offline')
                SegmentAnalytics.trackPayment(task, null, 'offline')
                Modal.close()
              }, function (error) {
                modalScope.error = 'Error occured, please try again.'
                console.log('DisplayTaskCtrl > offlinePaymentConfirm > error', error)
              })
            }
          }
          Modal.open('views/modals/deposit_funds_complete_manual.html', modalScope, { closeButton: false })
          // ---
        } else {
          // if normal payment redirect to workroom
          $state.go('tasks.workroom', { id: task.id })
        }
      }
    })

    $scope.openConvertTaskType = function () {
      var modalScope = {
        task: $scope.task
      }
      Modal.open('views/modals/convert_task_type.html', modalScope, { closeButton: false })
    }

    $scope.isAllowedTypeChange = function () {
      const allowed = ($scope.task.kind === 'consultation' && $scope.task.estimatesCount === 0)

      return allowed
    }

    // Function for task estimation/display template and flash notification
    $scope.openDepositModal = () => {
      // TODO remove this async check for internal feature flags - deprecate internal feature flags.
      UserService.updateFeatureList()
        .finally(() => {
          ModalService.open({
            closeOnBackdrop: false,
            class: 'payment-modal',
            layout: 'center',
            template: `<cdbl-payment-modal task="$ctrl.modal.task"></cdbl-payment-modal>`,
            task: $scope.task
          })
        })
    }

    $scope.openPublishTaskAllModal = function () {
      Modal.open('views/modals/publish_task_all.html', $scope)
    }

    $scope.openWorkroom = function (taskId) {
      Modal.close()
      $state.go('tasks.workroom', { id: taskId })
      Flash.show('views/flashes/private_workroom.html', false, $scope)
    }

    if (ProjectService.isWorkroom($scope.task)) { // TODO: check if additional checking needs to happen here or if this is rejected by BE via endpoint call otherwise add in check if isUserClientOnProject() || isUserExpertOnProject()
      $state.go('tasks.workroom', {
        id: $scope.task.id,
        payment_completed: $stateParams.payment_completed,
        payment_cancelled: $stateParams.payment_cancelled,
        payment_provider: $stateParams.payment_provider,
        sub_task_id: $stateParams.sub_task_id,
        task_id: $stateParams.task_id
      })
    } else if (ProjectService.isInState($scope.task, 'canceled')) {
      Flash.show('views/flashes/task_canceled.html', true, $scope)
    } else if (ProjectService.isInState($scope.task, 'refunded')) {
      Flash.show('views/flashes/task_refunded.html', true, $scope)
    }

    if (UserService.isUserPreferredExpertOnProject($scope.task)) {
      Flash.show('views/flashes/preferred_contractor.html', false, {})
    }

    if ($stateParams.showContractor) {
      User.showProfile($scope.task, parseInt($stateParams.showContractor, 10))
    }

    // Show intro modal if after update
    // TODO: refactor integration bellow with persona / intro modal
    if ($stateParams.intro === 'true') {
      $scope.localStorageVariable = ''

      if (ProjectService.isConsultation($scope.task)) {
        $scope.localStorageVariable = 'removeIntroBoxConsultation'
      } else {
        $scope.localStorageVariable = 'removeIntroBoxOneStep'
      }

      if (!UserService.hasCompletedPersonaSurvey() && UserService.isClient()) {
        ModalService.open({
          closeOnBackdrop: false,
          closeIcon: false,
          class: 'modal__persona-survey',
          template: `<cdbl-persona-survey-modal></cdbl-persona-survey-modal>`,
        })
      }
    }

    $scope.$on('$destroy', () => {
      ModalService.close() // close any open modal
    })
  }

  app.controller('DisplayTaskCtrl', ['$scope', '$timeout', '$location', '$state', '$stateParams', 'Auth', 'User', 'Storage', 'taskResource', 'Modal', 'Flash', 'Sockets', 'PaymentsService', 'SegmentAnalytics', 'CommentService', 'UserService', 'IntercomService', 'ModalService', 'ProjectService', 'EventBusService', DisplayTaskCtrl])
})()
