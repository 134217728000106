import templateUrl from './view-modal.html'
import listTemplateUrl from './view-modal--list.html'
import detailsTemplateUrl from './view-modal--details.html'
import './view-modal.scss'

const AdditionalTasksViewModalComponent = {
  bindings: {
    additionalTaskId: '<',
    filters: '<'
  },
  templateUrl,
  require: {
    modalCtrl: '^^cdblModal'
  },
  controller: class AdditionalTasksViewModal {
    constructor (ProjectService, UserService, ModalService, AdditionalTasksService, moment) {
      'ngInject'
      this._identify = 'AdditionalTasksViewModalComponent'
      this.ProjectService = ProjectService
      this.UserService = UserService
      this.ModalService = ModalService
      this.AdditionalTasksService = AdditionalTasksService
      this.moment = moment
      this.taskId = null
      this.page = 'list'
      this.pageTemplates = {
        list: listTemplateUrl,
        details: detailsTemplateUrl
      }

      this.actionList = []
      this.descriptionMode = 'less'
    }


    $onInit () {
      console.log('[AdditionalTasksViewModalComponent] > $onInit', this.additionalTaskId)
      if (this.additionalTaskId !== null && typeof this.additionalTaskId !== 'undefined') {
        this.taskId = this.additionalTaskId
        this.page = 'details'
      }

      this.isClient = this.UserService.isClient()

      this.actionList = this.isClient ? CLIENT_ACTIONS : EXPERT_ACTIONS

      // Define filters for task list
      this.listFilters = {
        'unpaid': {
          name: 'unpaid',
          enabled: this.isClient,
          active: false
        }
      }

      // If filter bindings exist, change active state of filters on init
      if (this.filters) {
        Object.entries(this.filters).forEach(([filterName, active]) => {
          if (this.listFilters[filterName]) {
            this.listFilters[filterName].active = active
          }
        })
      }

      this.taskListFilter = (task, index, taskList) => {
        // return false
        // Filter task list if filters are defined and active
        if (this.listFilters.unpaid && this.listFilters.unpaid.active) {
          return task.state === 'published' ||
            (task.retainer && task.state === 'published' && task.paymentHistory.status === 'created') ||
            (task.retainer && task.state === 'published' && task.paymentHistory.status === 'success') ||
            (task.retainer && task.state === 'published' && task.paymentHistory.status === 'active')
        }

        // If filtering is active and if not caught by above rules then dont display task
        return !this.isListFilteringActive
      }

      const user = this.UserService.get()
      this.coupon = user.coupon && user.coupon.amount
    }

    $onDestroy () {
      if (this.taskList) {
        this.taskList.forEach(task => {
          task.isCancelActive = false
          task.isMarkCompletedActive = false
        })
      }
    }

    get project () {
      return this.ProjectService.project
    }

    get taskList () {
      if (!this.ProjectService.project) {
        return null
      }

      // Do sorting here when needed

      return this.ProjectService.project.subtasks
    }

    get task () {
      if (!this.taskId || !this.taskList) {
        return null
      }

      return this.taskList.find(t => t.id === this.taskId)
    }

    get pageTemplate () {
      return this.pageTemplates[this.page]
    }

    get showListFilters () {
      // Show filters only if at least on of them is enabled
      const enabledFilters = Object.values(this.listFilters).filter(filter => filter.enabled)
      return Boolean(enabledFilters.length)
    }

    get isListFilteringActive () {
      const activeFilters = Object.values(this.listFilters).filter(filter => filter.enabled && filter.active)
      return Boolean(activeFilters.length)
    }

    isActionAllowed (actionName, task) {
      return this.actionList.find(action => action.name === actionName && this.checkActionIsAllowed(action, task))
    }

    checkActionIsAllowed (action, task = this.task) {
      // safety condition if task is not available from scope - this could be improved with separated list/details component
      if (!task) {
        return false
      }

      action.isAllowed = action.conditions.reduce((allowed, currentCondition) => {
        // console.log('allowedActions > 0', action.name, allowed, currentCondition)
        let result = task.state === currentCondition.taskState &&
          (task.retainer ? task.paymentHistory.status === currentCondition.retainerStatus : !currentCondition.retainerStatus)
        // console.log('allowedActions > 1', action.name, result)

        result = allowed || result
        // console.log('allowedActions > 2', action.name, result)
        return result
      }, false)

      return action.isAllowed
    }

    // Actions
    actionPay (task = this.task, $event) {
      if ($event) {
        $event.stopPropagation()
        $event.preventDefault()
      }

      this.ModalService.openNext({
        closeOnBackdrop: false,
        class: 'payment-modal',
        layout: 'center',
        template: `<cdbl-payment-modal task="$ctrl.modal.project" sub-task="$ctrl.modal.task"></cdbl-payment-modal>`,
        project: this.project,
        task: task
      })

      console.log('[AdditionalTasksViewModalComponent] > actionPay', task.id)
    }

    actionCancel (task = this.task, $event) {
      if ($event) {
        $event.stopPropagation()
        $event.preventDefault()
      }

      console.log('[AdditionalTasksViewModalComponent] > actionCancel', task.id)
      task.isCancelActive = true
    }

    actionCancelConfirm (task = this.task, $event) {
      if ($event) {
        $event.stopPropagation()
        $event.preventDefault()
      }
      console.log('[AdditionalTasksViewModalComponent] > actionCancelConfirm', task.id)

      if (task.retainer) {
        this.AdditionalTasksService.cancelRetainerTask(task.id)
          .then(response => {
            console.log('[AdditionalTasksViewModalComponent] > actionCancelConfirm > response', response)
            task.state = 'canceled'
            task.paymentHistory.status = 'canceled'
          })
          .finally(() => {
            task.isCancelActive = false
          })
      } else {
        this.AdditionalTasksService.cancelTask(this.project.id, task.id)
          .then(response => {
            console.log('[AdditionalTasksViewModalComponent] > actionCancelConfirm > response', response)
            task.state = 'canceled'
          })
          .finally(() => {
            task.isCancelActive = false
          })
      }
    }

    actionCancelAbort (task = this.task, $event) {
      if ($event) {
        $event.stopPropagation()
        $event.preventDefault()
      }

      console.log('[AdditionalTasksViewModalComponent] > actionCancelAbort', task.id)
      task.isCancelActive = false
    }

    actionMarkCompleted (task = this.task, $event) {
      if ($event) {
        $event.stopPropagation()
        $event.preventDefault()
      }
      console.log('[AdditionalTasksViewModalComponent] > actionMarkCompleted', task.id)

      task.isMarkCompletedActive = true
    }

    actionMarkCompletedConfirm (task = this.task, $event) {
      if ($event) {
        $event.stopPropagation()
        $event.preventDefault()
      }
      console.log('[AdditionalTasksViewModalComponent] > actionMarkCompletedConfirm', task.id)

      this.AdditionalTasksService.markCompleted(this.project.id, task.id)
        .then(response => {
          console.log('[AdditionalTasksViewModalComponent] > markCompleted > response', response)
          task.state = 'completed'
        })
        .finally(() => {
          task.isMarkCompletedActive = false
        })
    }

    actionMarkCompletedAbort (task = this.task, $event) {
      if ($event) {
        $event.stopPropagation()
        $event.preventDefault()
      }

      console.log('[AdditionalTasksViewModalComponent] > actionCancelAbort', task.id)
      task.isMarkCompletedActive = false
    }

    actionExtend (task = this.task, $event) {
      if ($event) {
        $event.stopPropagation()
        $event.preventDefault()
      }

      task.isExtendActive = true
    }

    actionExtendAbort (task = this.task, $event) {
      if ($event) {
        $event.stopPropagation()
        $event.preventDefault()
      }

      task.isExtendActive = false
    }

    actionExtendConfirm (task = this.task, $event) {
      if ($event) {
        $event.stopPropagation()
        $event.preventDefault()
      }

      this.AdditionalTasksService.renewRetainer(task)
        .then(response => {
          task.state = 'paid'
          task.paymentHistory = response.paymentHistory
        })
        .finally(() => {
          task.isExtendActive = false
          task.renewed = true
        })
    }

    // Propertis
    taskLabel (task = this.task) {
      if (!task) {
        return null
      }
      const key = `${task.state}${task.retainer ? '|' + task.paymentHistory?.status + (task.paymentHistory?.status === 'active' && task.paymentHistory?.expiring ? '|expiring' : '') : ''}`
      const label = TASK_LABEL_LUT[key]
      if (!label) {
        return 'Unknown'
      }

      return label
    }

    taskPrice (task = this.task) {
      if (!task) {
        return null
      }

      if (this.isClient) {
        return task.prices.priceWithClientFee
      }

      return task.prices.contractorEarnings
    }

    retainerEventText (retainerEvent, task = this.task) {
      const key = `${retainerEvent.name}|${retainerEvent.status}`
      const text = RETAINER_EVENT_LOG_LUT[key]
      // workaround with hack - to be removed once provider_canceled is working properly
      if (retainerEvent.name === 'subscription_canceled' && task.paymentHistory && task.paymentHistory.failureCount >= RETAINER_FAILURE_COUNT) {
        return 'The retainer was canceled due to failed payments.'
      } else if (retainerEvent.name === 'subscription_renewed') {
        return `The retainer was extended. It will be due for another extension before ${this.moment(retainerEvent.endDate).format('MMM DD, YYYY')}`
      }
      // ---
      return text
    }

    isAllowedRetainerEvent (retainerEvent, task = this.task) {
      // TODO reduce code duplication
      const key = `${retainerEvent.name}|${retainerEvent.status}`
      const text = RETAINER_EVENT_LOG_LUT[key]

      // workaround with hack - to be removed once provider_canceled is working properly
      if (retainerEvent.name === 'subscription_canceled' && task.paymentHistory && task.paymentHistory.failureCount >= RETAINER_FAILURE_COUNT) {
        return true
      } else if (retainerEvent.name === 'subscription_renewed') {
        return `The retainer was extended. It will be due for another extension before ${this.moment(retainerEvent.endDate).format('MMM DD, YYYY')}`
      }
      // ---
      return Boolean(text)
    }

    get summaryAmountPaid () {
      let amount = 0
      const retainerEventName = 'client_payment'
      const taskPriceName = this.isClient ? 'priceWithClientFee' : 'contractorEarnings'

      if (this.taskList) {
        this.taskList.forEach(task => {
          if (task.retainer && task.paymentHistory) {
            // TODO: remove conditional bellow - this should be fixed by BE to either provide null for payment history or full object with events
            if (task.paymentHistory.events) {
              task.paymentHistory.events.forEach(event => {
                if (event.name === retainerEventName && event.status === 'success') {
                  amount += task.prices[taskPriceName]
                }
              })
            }
            //
          } else if (task.state === 'paid' || task.state === 'completed') {
            amount += task.prices[taskPriceName]
          }
        })
      }
      return amount
    }

    get summaryAmountWaiting () {
      let amount = 0
      const taskPriceName = this.isClient ? 'priceWithClientFee' : 'contractorEarnings'

      if (this.taskList) {
        this.taskList.forEach(task => {
          if (task.retainer && (task.state === 'published' || (task.paymentHistory && task.paymentHistory.status === 'failed'))) {
            amount += task.prices[taskPriceName]
          } else if (task.state === 'published') {
            amount += task.prices[taskPriceName]
          }
        })
      }
      return amount
    }

    get summaryAmountTotal () {
      return this.summaryAmountPaid + this.summaryAmountWaiting
    }

    // For task details / task list navigation inside modal
    goToPage (page, taskId) {
      // Handle special cases here before page changes - could be improved with separated list/details component
      this.taskList.forEach(task => {
        task.isCancelActive = false
        task.isMarkCompletedActive = false
      })
      this.descriptionMode = 'less'

      this.taskId = taskId
      this.page = page
    }
  }
}

const RETAINER_FAILURE_COUNT = 3
const TASK_LABEL_LUT = {
  // additional task labels
  'published': {
    state: 'published',
    status: null,
    text: 'Waiting for payment',
    className: 'cdbl__badge--red'
  },
  'paid': {
    state: 'paid',
    status: null,
    text: 'In progress',
    className: 'cdbl__badge--green'
  },
  'completed': {
    state: 'completed',
    status: null,
    text: 'Completed',
    className: 'cdbl__badge--gray'
  },
  'canceled': {
    state: 'canceled',
    status: null,
    text: 'Canceled',
    className: 'cdbl__badge--gray'
  },
  'refunded': {
    state: 'refunded',
    status: null,
    text: 'Refunded',
    className: 'cdbl__badge--gray'
  },
  'abandoned': {
    state: 'abandoned',
    status: null,
    text: 'Abandoned',
    className: 'cdbl__badge--gray'
  },

  // retainer task labels
  'published|created': {
    state: 'published',
    status: 'created',
    text: 'Waiting for payment',
    className: 'cdbl__badge--red'
  },
  // Note: Needed for edge case when subscription is created but payment is not confirmed yet
  'published|success': {
    state: 'published',
    status: 'created',
    text: 'Waiting for payment',
    className: 'cdbl__badge--red'
  },
  // --
  'paid|active': {
    state: 'paid',
    status: 'active',
    text: 'Active',
    className: 'cdbl__badge--green'
  },
  // TODO: Remove this one after BE removes it from its response, correct one should be one above
  'paid|success': {
    state: 'paid',
    status: 'active',
    text: 'Active',
    className: 'cdbl__badge--green'
  },
  // ---
  'canceled|canceled': {
    state: 'canceled',
    status: 'canceled',
    text: 'Canceled',
    className: 'cdbl__badge--gray'
  },
  // TODO: Remove this one after BE removes it from its response, correct one should be one above
  'paid|canceled': {
    state: 'canceled',
    status: 'canceled',
    text: 'Canceled',
    className: 'cdbl__badge--gray'
  },
  // ---
  'published|failing': {
    state: 'paid',
    status: 'failing',
    text: 'Failed payment',
    className: 'cdbl__badge--red'
  },
  'paid|failed': {
    state: 'paid',
    status: 'failed',
    text: 'Failed payment',
    className: 'cdbl__badge--red'
  },
  'paid|failing': {
    state: 'paid',
    status: 'failing',
    text: 'Failed payment',
    className: 'cdbl__badge--red'
  },
  'published|failed': {
    state: 'paid',
    status: 'failed',
    text: 'Failed payment',
    className: 'cdbl__badge--red'
  },
  'completed|ended': {
    state: 'canceled',
    status: 'ended',
    text: 'Expired',
    className: 'cdbl__badge--gray'
  },
  // TODO: Remove this one after BE changes it to completed from its response, correct one is the one above
  'canceled|ended': {
    state: 'canceled',
    status: 'ended',
    text: 'Expired',
    className: 'cdbl__badge--gray'
  },
  // ---
  'paid|active|expiring': {
    state: 'published',
    status: 'created',
    text: 'Expiring soon',
    className: 'cdbl__badge--orange'
  },
}

const RETAINER_EVENT_LOG_LUT = {
  // retainer event log event text
  'client_payment|success': 'The monthly payment was made by the client.',
  'expert_payment|success': 'The monthly payment was released to the expert.',
  'client_payment|failed': 'The monthly payment has failed.',
  'client_canceled|success': 'The retainer was canceled by the client.',
  'expert_canceled|success': 'The retainer was canceled by the expert.',
  'provider_canceled|success': 'The retainer was canceled due to failed payments.',
  'subscription_ended|ended': 'The retainer has expired.',
}

const CLIENT_ACTIONS = [
  {
    name: 'pay',
    conditions: [
      // additional task
      { taskState: 'published' },
      // retainer task
      { taskState: 'published', retainerStatus: 'created' },
    ]
  },
  {
    name: 'mark-completed',
    conditions: [
      // additional task
      { taskState: 'paid' },
    ]
  },
  {
    name: 'cancel',
    conditions: [
      // retainer task
      { taskState: 'published', retainerStatus: 'failing' },
      { taskState: 'paid', retainerStatus: 'active' },
      // TODO: Remove this one after BE changes it to completed from its response, correct one is the one above
      { taskState: 'paid', retainerStatus: 'success' },
      // ---
      { taskState: 'paid', retainerStatus: 'failed' },
      { taskState: 'paid', retainerStatus: 'failing' }
    ]
  },
]

const EXPERT_ACTIONS = [
  {
    name: 'cancel',
    conditions: [
      // additional task
      { taskState: 'published' },
      // retainer task
      { taskState: 'published', retainerStatus: 'created' },
      { taskState: 'published', retainerStatus: 'failing' },
      { taskState: 'paid', retainerStatus: 'active' },
      // TODO: remove this event after BE changes serializer, correct one should be the one above
      { taskState: 'paid', retainerStatus: 'success' },
      // ---
      { taskState: 'paid', retainerStatus: 'failed' },
      { taskState: 'paid', retainerStatus: 'failing' }
    ]
  },
]

export default AdditionalTasksViewModalComponent
