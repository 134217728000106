const ctrlEnterDirective = function () {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function (scope, element) {
      const closestForm = () => {
        let parent = element.parent()
        while (parent.length && (parent[0].tagName !== 'FORM')) {
          parent = parent.parent()
        }
        return parent
      }

      const keyDownHandler = $event => {
        if ($event.ctrlKey && ($event.key === 'Enter' || $event.keyCode === 13)) {
          // console.log('ctrlEnterDirective > keydown >', $event);
          closestForm().triggerHandler('submit')
          $event.preventDefault()
          $event.stopPropagation()
        }
      }

      element.on('keydown', keyDownHandler)
      scope.$on('destroy', () => {
        element.off('keydown', keyDownHandler)
      })
    }
  }
}

export default ctrlEnterDirective
