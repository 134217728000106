import ExpertProfileEditComponent from './expert-profile-edit.component'

const ExpertProfileEditModule = angular.module('cdbl.expert-profile.expert-profile-edit', [

])
  .component('cdblExpertProfileEdit', ExpertProfileEditComponent)

  .config(($stateProvider) => {
    'ngInject'

    $stateProvider
      .state('expert-profile-edit', {
        url: '/profile-edit',
        template: '<cdbl-expert-profile-edit></cdbl-expert-profile-edit>',
        resolve: {
          permissionResolver: (AuthService) => {
            'ngInject'
            console.log('debug [ExpertProfileEditModule] > permissionResolver > expert-profile-edit')
            return AuthService.permissionResolver('isExpert')
          }
        },
        layout: 'new'
      })
  })

  .name

export default ExpertProfileEditModule
