(function () {
  'use strict';

  function trustAsHtml($sce) {
    return function (text) {
      return $sce.trustAsHtml(text);
    };
  }

  app.filter('trustAsHtml', ['$sce', trustAsHtml]);
})();
