import templateUrl from './info-popover.html'
import './info-popover.scss'

const InfoPopoverComponent = {
  bindings: {
    instanceOptions: '<options',
    label: '@?',
    popoverPlacement: '@?placement',
    infoPopoverTrack: '@?',
    infoPopoverTrackPayload: '<?'
  },
  transclude: true,
  templateUrl,
  controller: class InfoPopover {
    constructor ($transclude, $compile, SegmentAnalytics) {
      'ngInject'
      this._identify = 'InfoPopoverComponent'
      this.$transclude = $transclude
      this.$compile = $compile
      this.SegmentAnalyticsService = SegmentAnalytics

      this.options = null
      this.defaultOptions = {

      }

      this.transcludedContent = null
    }

    $onInit () {
      this.updateConfig()
      const vm = this
      this.$transclude((clone, scope) => {
        vm.transcludedContent = clone
      })
    }


    $onChanges (changes) {
      if (changes.instanceOptions) {
        this.updateConfig()
      }
    }

    updateConfig () {
      this.options = Object.assign({}, this.defaultOptions, this.instanceOptions)
    }


    onPopoverOpen () {
      if (this.infoPopoverTrack) {
        const options = {
          event: this.infoPopoverTrack || 'Tooltip Viewed',
          payload: this.infoPopoverTrackPayload || {}
        }
        this.SegmentAnalyticsService.trackEvent(options.event, options.payload)
      }
    }
  }
}

export default InfoPopoverComponent
