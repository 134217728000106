// import md5 from 'crypto-js/md5'
/* Factory used to create instances of FileUploadWrappers and take care of the dependency injection
* Calling it from controller or service:
*
* const scopeDocItem = new ProjectScopeDocItemFactory (config)
*/
function ProjectScopeDocItemFactory (Configuration) {
  'ngInject'

  let idCounter = -1
  function getUniqueTemporaryId () {
    return idCounter--
  }

  const ProjectScopeDocItem = class ProjectScopeDocItem {
    constructor (config, resetIds = false) {
      // this.Configuration = Configuration

      this.id = getUniqueTemporaryId()
      this.parentId = null
      this.title = ''
      this.description = ''
      this.finishedAt = null
      this.createdAt = null
      this.updatedAt = null
      this.attachments = []
      this.scopeDocChildItems = []

      if (config) {
        this.updateSelf(config)
      }

      // If template was used to create scope doc, all ids needs to be replaced with temporary ids
      if (resetIds) {
        this.id = getUniqueTemporaryId()
      }

      // FE related data
      this._edit = {
        enableDescriptionField: this.description !== '',
        showRemoveItemConfirmation: false,
        isProcessing: false,
        // nestingDepth: 1
      }

      console.log('[ProjectScopeDocItem] > init', this.id)

      if (this.scopeDocChildItems.length) {
        const processedChildItems = []
        this.scopeDocChildItems.forEach(item => {
          processedChildItems.push(new this.constructor(item, resetIds))
        })
        this.scopeDocChildItems = processedChildItems
      }
    }

    updateSelf (newData) {
      Object.assign(this, { ...newData })
    }

    get itemsCount () {
      return this.scopeDocChildItems.length
    }

    get isFinished () {
      return Boolean(this.finishedAt)
    }

    get isEnabledItemDescriptionField () {
      return this._edit.enableDescriptionField
    }

    get canRemoveItemDescriptionField () {
      return this.description === ''
    }

    get hasDescription () {
      return this.description !== ''
    }

    get isProcessing () {
      return this._edit.isProcessing
    }

    toggleProcessing (state = false) {
      this._edit.isProcessing = state
    }

    clearDescriptionField () {
      this.description = ''
    }

    toggleItemDescriptionField () {
      this._edit.enableDescriptionField = !this._edit.enableDescriptionField
    }

    get canShowRemoveItemConfirmation () {
      return this._edit.showRemoveItemConfirmation
    }
    toggleRemoveItemConfirmation () {
      this._edit.showRemoveItemConfirmation = !this._edit.showRemoveItemConfirmation
    }


    // toggleFinishedState () {
    //   this.finishedAt = this.finishedAt ? null : true // TODO update after BE binding
    // }


    findItemIndex (item) {
      return this.scopeDocChildItems.findIndex(child => child.id === item.id)
    }
    findItemById (itemId) {
      let item = null
      this.scopeDocChildItems.forEach(child => {
        if (child.id === itemId) {
          item = child
        }

        const childOfChild = child.findItemById(itemId)
        if (childOfChild) {
          item = childOfChild
        }
      })

      return item
    }

    assignParent (id) {
      this.parentId = id
    }

    unassignParent () {
      this.parentId = null
    }

    addChild (item, position = this.itemsCount) {
      console.log('[Item] > addChild', item, position)

      item.assignParent(this.id)
      this.scopeDocChildItems.splice(position, 0, item)
    }

    removeChild (item) {
      item.unassignParent()
      const itemIndex = this.findItemIndex(item)
      this.scopeDocChildItems.splice(itemIndex, 1)
    }
  }

  return ProjectScopeDocItem
}



export default ProjectScopeDocItemFactory
