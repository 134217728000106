import templateUrl from './progressbar.html'
import './progressbar.scss'

const ProgressbarComponent = {
  bindings: {
    progress: '<',
    class: '<'
  },
  templateUrl,
  controller: class Progressbar {
    constructor () {
      'ngInject'
      this._identify = 'ProgressbarComponent'
    }
    $onInit () {
      if (!this.progress) {
        this.progress = 0
      }
    }
  }
}

export default ProgressbarComponent
