import templateUrl from './payment-modal.html'
import './payment-modal.scss'

const PaymentModalComponent = {
  bindings: {
    task: '<',
    subTask: '<',
    initialStep: '@'
    // TODO: add callbacks so that controller who opened modal knows how to respond
  },
  templateUrl,
  require: {
    modalCtrl: '^^cdblModal'
  },
  controller: class PaymentModal {
    constructor ($state, $timeout, ModalService, SegmentAnalytics, LoggingService, ExperimentService) {
      'ngInject'
      this._identify = 'PaymentModalComponent'
      this.$state = $state
      this.$timeout = $timeout
      this.ModalService = ModalService
      this.SegmentAnalytics = SegmentAnalytics
      this.LoggingService = LoggingService
      this.ExperimentService = ExperimentService

      // Wait until safecharge loads then show paypal
      this.isLoaded = false
    }

    $onInit () {
      // console.log('[PaymentModalComponent] > $onInit');
      this.isLoaded = true
      this.payWithPaypal = false
      this.step = this.initialStep ? this.initialStep : 'payment-deposit'
      this.$timeout()
    }

    $onDestroy () {
      // console.log('[PaymentModalComponent] > $onDestroy');
    }

    get isRetainer () {
      return this.subTask && this.subTask.retainer
    }


    goToStep (step) {
      // console.log('[PaymentModalComponent] > goToStep', this.step, step);
      this.step = step
    }

    completePayment (paymentProvider) {
      // TODO: display successful message from within payment modal
      if (this.subTask && !this.subTask.retainer) {
        this.goToStep('payment-successful-task') // TODO: uncomment to handle success message from here and not from subtask controller
        // TODO: close legacy flash and open new legacy flash for all additional/retainer tasks that need payment
        // TODO: remove current flash, reintroduce new flash in any waiting subtasks still not paid
      } else if (this.subTask && this.subTask.retainer) {
        this.goToStep('payment-successful-task-retainer') // TODO: uncomment to handle success message from here and not from subtask controller
        // TODO: close legacy flash and open new legacy flash for all additional/retainer tasks that need payment
        // TODO: remove current flash, reintroduce new flash in any waiting subtasks still not paid
      } else {
        this.goToStep('payment-successful') // TODO: uncomment to handle success message from here and not from task controller
        // redirect user behind modal to workroom
      }

      this.$state.go('tasks.workroom', { id: this.task.id })
      // TODO: if BE event is too slow use this instead above line
      // if (paymentProvider === 'paypal') {
      //   this.$state.reload()
      // } else {
      //   this.$state.go('tasks.workroom', { id: this.task.id })
      // }
      // this.modalCtrl.close();
    }

    trackPayment (paymentProvider) {
      if (this.subTask) {
        this.SegmentAnalytics.trackSubTaskPayment(this.task, this.subTask, paymentProvider)
      } else {
        this.SegmentAnalytics.trackTaskPayment(this.task, paymentProvider)
      }

      this.SegmentAnalytics.trackPayment(this.task, this.subTask ? this.subTask : null, paymentProvider)
    }

    canceledPayment () {
      // display cancel message
      this.goToStep('payment-canceled')

      // reset loaded flag for hiding paypal until safecharge is loaded
      this.isLoaded = false
      this.$timeout()
    }

    failedPayment () {
      this.goToStep('payment-failed')

      // reset loaded flag for hiding paypal until safecharge is loaded
      this.isLoaded = false
    }

    // Paypal
    onStartedPaypalHandler (event) {
      // console.log('[PaymentModalComponent] > onStartedPaypalHandler', event);
    }

    onCompletedPaypalHandler (event) {
      // console.log('[PaymentModalComponent] > onCompletedPaypalHandler', event);
      this.trackPayment('paypal')
      this.completePayment('paypal')
    }

    onCanceledPaypalHandler (event) {
      // console.log('[PaymentModalComponent] > onCanceledPaypalHandler', event);
      this.canceledPayment()
    }

    onErrorPaypalHandler (event) {
      // console.log('[PaymentModalComponent] > onErrorPaypalHandler', event);
      this.failedPayment()
    }

    onCompletedStripeHandler (event) {
      // console.log('[PaymentModalComponent] > onCompletedPaypalHandler', event);
      this.trackPayment('stripe')
      this.completePayment('stripe')
    }
  }
}

export default PaymentModalComponent
