const popoverDirective = function ($document, $compile, $parse, $timeout) {
  'ngInject'

  return {
    restrict: 'A',
    scope: {
      popoverComponent: '<',
      popoverComponentData: '<',
      popoverTranscludedContent: '<',
      popoverOnopen: '&?',
      popoverOnclose: '&?',
      popoverPlacement: '<?'
    },

    link: function ($scope, element, attrs) {
      let classlist = 'popover-content '
      if (attrs.popoverClass) {
        classlist += attrs.popoverClass
      }

      const opts = {
        template: `
          <div class="popover" role="tooltip">
            <div class="arrow"></div>
              <h3 class="popover-title"></h3>
            <div class="popover-content ${classlist}"></div>
          </div>
        `,
        placement: 'bottom',
        container: 'body'
      }

      if (!('outsideClickDisabled' in attrs)) {
        $('body, .modal-content').on('click', function (e) {
          if (!element.is(e.target) && element.has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
            hide()
          }
        })
      }

      if ('popoverComponent' in attrs) {
        var contentPopover = angular.element(`<div><${attrs.popoverComponent} data="popoverComponentData"></${attrs.popoverComponent}></div>`)
        $compile(contentPopover)($scope)

        opts.content = contentPopover[0]
        opts.html = true
      }

      if ($scope.popoverTranscludedContent) {
        opts.content = $scope.popoverTranscludedContent
        opts.html = true
      }

      if ($scope.popoverPlacement) {
        console.log('[popoverDirective] > $scope.popoverPlacement', $scope.popoverPlacement)
        opts.placement = $scope.popoverPlacement
      }

      // If content is not available abort initialization of popover
      if (!opts.content) {
        return
      }

      function hide () {
        element.popover('hide')

        if (element.data('bs.popover')) {
          element.data('bs.popover').inState.click = false// fix for bootstrap bug on event hide
        }
      }

      $scope.hide = function () {
        hide()
      }

      $scope.$on('modal-close', function () {
        hide()
      })

      $scope.$on('$stateChangeStart', function () {
        hide()
      })

      $scope.$on('$destroy', function () {
        element.popover('destroy')
      })

      element.on('hidden.bs.popover', function () {
        // onCloseCallback();
        if ($scope.popoverOnclose) {
          $timeout($scope.popoverOnclose)
        }
        element.addClass('popover-hidden')
        element.removeClass('popover-shown')
      })
      element.on('shown.bs.popover', function () {
        // onOpenCallback();

        if ($scope.popoverOnopen) {
          $timeout($scope.popoverOnopen)
        }

        element.addClass('popover-shown')
        element.removeClass('popover-hidden')
      })
      element.addClass('popover-hidden')


      element.popover(opts)
    }
  }
}

export default popoverDirective
