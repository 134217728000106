(function () {
  'use strict';

  function Viewport() {
    const isMobile = function () {
      if (window.outerWidth < 669) {
        return true;
      }
      return false;
    };

    return {
      isMobile
    };
  }

  app.service('Viewport', [Viewport]);
})();
