import templateUrl from './project-list.html'
import './project-list.scss'

const ProjectListComponent = {
  bindings: {},
  templateUrl,
  controller: class ProjectList {
    constructor ($window, $state, $stateParams, ProjectDashboardService) {
      'ngInject'
      this._identify = 'ProjectListComponent'
      this.$window = $window
      this.$state = $state
      this.$stateParams = $stateParams
      this.ProjectDashboardService = ProjectDashboardService

      this.isLoading = false
      this.projectList = null
    }

    $onInit () {
      this.ProjectDashboardService.init()
      this.loadProjectList()
    }

    get pagination () {
      return this.ProjectDashboardService.pagination
    }

    loadProjectList () {
      this.$window.document.querySelector('cdbl-project-dashboard .content-inner-wrapper').scrollTo({ top: 0 })
      this.isLoading = true
      this.ProjectDashboardService.getProjectList()
        .then(response => {
          console.log('[ProjectListComponent] > loadProjectList', response)
          this.projectList = response
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    onPageChangeHandler (page) {
      console.log('[ProjectListComponent] > onPageChangeHandler', page)
      this.ProjectDashboardService.updateQueryString()
    }


    removeProjectFromList (projectId) {
      if (this.projectList) {
        const index = this.projectList.findIndex(p => p.id === projectId)
        if (index > -1) {
          this.projectList.splice(index, 1)
        }

        console.log('[ProjectListComponent] > removeProjectFromList > index', index)
      }
    }
  }
}

export default ProjectListComponent
