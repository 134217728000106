import templateUrl from './header-legacy-links-popover.html'
import './header-legacy-links-popover.scss'

const HeaderLegacyLinksPopoverComponent = {
  bindings: {},
  templateUrl,
  controller: class HeaderLegacyLinksPopoverComponent {
    constructor (UserService, Configuration) {
      'ngInject'
      this.UserService = UserService
      this.Configuration = Configuration
    }
  }
}

export default HeaderLegacyLinksPopoverComponent
