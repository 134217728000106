import templateUrl from './stepper.html'
import './stepper.scss'

const StepperComponent = {
  bindings: {
    stepList: '<',
    step: '<',
    onClick: '&'
  },
  templateUrl,
  controller: class Stepper {
    constructor () {
      'ngInject'
      this._identify = 'StepperComponent'
    }

    $onInit () {
      this.actionsDisabled = false
    }

    stepClassName (step) {
      const currentStepIndex = this.stepList.findIndex(item => item === this.step)
      const stepIndex = this.stepList.findIndex(item => item === step)

      return {
        'stepper-item--current': currentStepIndex === stepIndex,
        'stepper-item--previous': currentStepIndex > stepIndex,
        'stepper-item--next': currentStepIndex < stepIndex
      }
    }

    goToStep (step) {
      if (this.onClick) {
        this.onClick({ step: step })
      }
    }
  }
}

export default StepperComponent
