(function () {
  'use strict';

  function favourite(SegmentAnalytics, $state, UserService, $http, Configuration) {
    var link = function ($scope, element, attrs) {
      element.on('click', function (event) {
        event.stopPropagation(); // keeps it from firing the click on the document
      });

      $scope.UserService = UserService;

      $scope.cssClass = attrs.cssClass ? attrs.cssClass : '';

      $scope.updateFavourite = function (task) {
        if ($scope.task.favoredByCurrentUser) {
          $scope.task.favoredByCurrentUser = false;

          $http
            .delete(`${Configuration.apiUrl}/users/me/tasks/favourites/${task.id}`)
            .then(function () {
              SegmentAnalytics.trackTaskUnfavorited(task, $state.current.name);
            })
            .catch(function (err) {
              console.log(err)
            })
        } else {
          $scope.task.favoredByCurrentUser = true;

          $http
            .put(`${Configuration.apiUrl}/users/me/tasks/favourites`, { taskId: task.id })
            .then(function () {
              SegmentAnalytics.trackTaskFavorited(task, $state.current.name)
            })
            .catch(function (err) {
              console.log(err)
            })
        }
      };

      $scope.addText = 'Add to favourites';
      $scope.removeText = 'Remove from favourites';
    };

    return {
      restrict: 'E',
      templateUrl: require('../../views/directives/favourite.html'),
      link: link,
      scope: {
        task: '=task'
      }
    };
  }

  app.directive('favourite', ['SegmentAnalytics', '$state', 'UserService', '$http', 'Configuration', favourite]);
})();
