import templateUrl from './withdrawal-modal.html'
import './withdrawal-modal.scss'

const WithdrawalModalModalComponent = {
  bindings: {
  },
  templateUrl,
  require: {
    modalCtrl: '^^cdblModal'
  },
  controller: class WithdrawalModal {
    constructor (UserService, ModalService, $http, Configuration) {
      'ngInject'
      this.UserService = UserService
      this.$http = $http
      this.ModalService = ModalService
      this.Configuration = Configuration
    }

    $onInit () {
      this.finished = false
      this.options = [
        {
          title: 'PayPal',
          value: 'paypal'
        }
      ]

      if (this.UserService.user.eligibleForWireWithdrawals) {
        this.options.unshift({
          title: 'Bank account',
          value: 'wire'
        })
      }

      this.radioConfig = {
        horizontal: true
      }

      this.formData = {
        type: this.UserService.user.eligibleForWireWithdrawals ? 'wire' : 'paypal',
        amount: this.UserService.user.balance
      }
    }

    makeWithdrawal () {
      this.processing = true
      this.resource = null
      this.error = null
      this.amount = this.formData.amount
      this.password = this.formData.current_password

      if (this.formData.type === 'paypal') {
        this.resource = this.$http.post(`${this.Configuration.apiUrl}/users/me/withdrawals`, {
          amount: this.amount, paypal_email: this.formData.paypalEmail, current_password: this.password
        })
      } else if (this.formData.type === 'wire') {
        this.resource = this.$http.post(`${this.Configuration.apiUrl}/users/me/wire_withdrawals`, {
          amount: this.amount,
          current_password: this.password
        })
      }

      this.resource.then(() => {
        this.finished = true
        this.amount = this.formData.amount
      }).catch((err) => {
        if (err.status === 422) {
          const withdrawalError = err.data.errors.find(item => item.field === 'withdrawal_request')
          const withdrawalPasswordError = err.data.errors.find(item => item.field === 'current_password')
          if (withdrawalError) {
            this.ModalService.close()
            this.ModalService.open({
              closeOnBackdrop: true,
              closeIcon: false,
              layout: 'center',
              body: withdrawalError.message,
              actions: [
                {
                  label: 'OK',
                  class: 'button-uppercase',
                  callback: () => {
                    this.ModalService.close()
                  }
                }
              ]
            })
          } else if (withdrawalPasswordError) {
            this.error = 'Your current password is incorrect. Please try again.'
          }
        }
      }).finally(() => {
        this.processing = false
      })
    }
  }
}

export default WithdrawalModalModalComponent
