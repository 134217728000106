(function () {
  'use strict';

  function DataManipulation(_) {
    var updateElement = function (searchData, replaceData, key) {
      var search = {};
      search[key] = replaceData[key];

      var element = _.find(searchData, search);
      var position = searchData.indexOf(element);

      searchData[position] = replaceData;
    };

    var removeElement = function (searchData, replaceData, key) {
      var search = {};
      search[key] = replaceData[key];

      var element = _.find(searchData, search);
      var position = searchData.indexOf(element);

      searchData[position].removed = true;
    };

    return {
      updateElement: updateElement,
      removeElement: removeElement
    };
  }

  app.service('DataManipulation', ['_', DataManipulation]);
})();
