import './character-count.directive.scss'
const characterCountDirective = function ($compile) {
  'ngInject'
  return {
    require: 'ngModel',
    restrict: 'A',
    scope: true,
    link: function (scope, element, attrs, ngModel) {
      // console.log('characterCountDirective', element, attrs, ngModel);
      scope.ngmodel = ngModel
      let template
      if ((attrs.ngMaxlength || attrs.maxlength) && attrs.characterCount !== 'up') {
        const maxlength = Number(attrs.ngMaxlength || attrs.maxlength)
        template = `
        <span class="character-counter">
          {{ ${maxlength} - ngmodel.$viewValue.length }}
        </span>
        `
      } else if (attrs.characterCount === 'up') {
        template = '<span class="character-counter">{{ngmodel.$viewValue.length}}</span>'
      }
      const counterEl = $compile(template)(scope)

      element.parent().append(counterEl)
      element.parent().attr('style', 'position: relative')
    }
  }
}

export default characterCountDirective
