const DEBUG_UI = true
const UIService = class UIService {
  constructor ($state, $window, $document, $timeout, $mdMedia, Configuration, UtilService) {
    'ngInject'
    this.$state = $state
    this.$window = $window
    this.$document = $document
    this.$timeout = $timeout
    this.$mdMedia = $mdMedia
    this.UtilService = UtilService

    this.isDevelopment = (Configuration.isBuild && Configuration.CDBL_ENV === 'develop') || (!Configuration.isProd && !Configuration.isBuild) // develop env or any local nonproduction

    this.stateName = null
    this.isStickySupported = this.checkStickySupport() || false // check if native sticky is supported
    this.viewport = {
      width: 0,
      height: 0
    }

    /**
     * Init
     */
    this.onStateChange = state => {
      this.stateName = state.name
      console.log('UIService > onStateChange > ', state)
    }

    // /**
    //  * Event bindings
    //  * TODO:
    //  * - add debounce
    //  * - create event emitter
    //  */
    // angular.element($window).on('resize', angular.bind(this, this.onWindowResize)) // bind to resize globally for app
    // angular.element($window).on('scroll', angular.bind(this, this.onScroll))       // bind to resize globally for app
    // $timeout(() => {
    //   angular.element($window).trigger('resize')
    // }, 100)


    this.components = {}

    this._layout = 'old'
  }

  registerComponent (name, componentCtrl) {
    if (DEBUG_UI && typeof name === 'undefined') {
      console.warn('[UIService] > registerComponent > component name is undefined', name)
    } else if (DEBUG_UI && typeof componentCtrl === 'undefined') {
      console.warn('[UIService] > registerComponent > component controller is undefined', name)
    } else {
      this.components[name] = componentCtrl
    }
  }

  unregisterComponent (name) {
    if (DEBUG_UI && typeof name === 'undefined') {
      console.warn('[UIService] > unregisterComponent > component name is undefined', name)
    } else if (DEBUG_UI && typeof this.components[name] === 'undefined') {
      console.warn('[UIService] > unregisterComponent > component does not exist', name)
    } else {
      delete this.components[name]
    }
  }

  getComponent (name) {
    if (DEBUG_UI && typeof name === 'undefined') {
      console.warn('[UIService] > component > component name is undefined', name)
    } else if (DEBUG_UI && typeof this.components[name] === 'undefined') {
      console.warn('[UIService] > unregisterComponent > component does not exist', name)
      return null
    } else {
      return this.components[name]
    }
  }

  closeAllComponentsPerType (type) {
    const list = Object.entries(this.components)
      .filter(([name, component]) => component.type === type)
      .reduce((arr, [name, component]) => {
        arr.push(component)
        return arr
      }, [])
    list.forEach(component => {
      if (component && component.close) {
        component.close()
      }
    })
    if (DEBUG_UI) {
      console.log('[UIService] > closeAllComponentsPerType > list', list)
    }
  }

  // Generates an configuration object for layout, so it can be generated uniformly across all pages
  getLayoutConfig (layoutName = 'new', options = {}) {
    return {
      name: layoutName,
      options: options
    }
  }

  getStateName () {
    return `${this.stateName}`
  }

  get isMobile () {
    return !this.$mdMedia('gt-xs')
  }

  // TODO: improve layout change detection, issue with timing issue was resolved however code could be better strucutred
  get layout () {
    return this._layout // this.$state.current.layout ? this.$state.current.layout : 'old'
  }

  get isNewLayout () {
    // console.log('debug [UIService] > isNewLayout', this._layout === 'new')
    return this._layout === 'new' // this.$state.current && this.$state.current.layout === 'new'
  }

  toggleLayoutClass (state) {
    if (state) {
      this.UtilService.addClass('body', 'cdbl-layout--new')
    } else {
      this.UtilService.removeClass('body', 'cdbl-layout--new')
    }
  }

  setNewLayout () {
    this._layout = 'new'
    this.toggleLayoutClass(true)
  }

  setOldLayout () {
    this._layout = 'old'
    this.toggleLayoutClass(false)
  }

  checkStickySupport () {
    let stickyProp
    const testEl = angular.element('<div>')
    this.$document[0].body.appendChild(testEl[0])

    const stickyProps = ['sticky', '-webkit-sticky']
    for (var i = 0; i < stickyProps.length; ++i) {
      testEl.css({
        position: stickyProps[i],
        top: 0,
        'z-index': 2
      })
      if (testEl.css('position') === stickyProps[i]) {
        stickyProp = stickyProps[i]
        break
      }
    }
    testEl.remove()
    return stickyProp
  }
}
export default UIService
