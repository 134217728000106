import TransactionsComponent from './transactions.component'
import TransactionsService from './transactions.service'
import TransactionItemModule from './transaction-item'

const TransactionsModule = angular.module('cdbl.transactions', [
  TransactionItemModule
])
  .component('cdblTransactions', TransactionsComponent)
  .service('TransactionsService', TransactionsService)
  .config(($stateProvider) => {
    'ngInject'

    $stateProvider
      .state('transactions', {
        url: '/transactions',
        template: '<cdbl-transactions></cdbl-transactions>',
        layout: 'new'
      })
  })
  .name

export default TransactionsModule
