import ProjectTeamComponent from './project-team.component'
import ProjectTeamService from './project-team.service'

import ProjectTeamSidebarWidgetModule from './sidebar-widget'
import ProjectTeamManageModalModule from './manage-modal'
import ProjectTeamPaymentsModalModule from './payments-modal'
import ProjectTeamMemberModule from './member'

const ProjectTeamModule = angular.module('cdbl.project-team', [
  ProjectTeamSidebarWidgetModule,
  ProjectTeamManageModalModule,
  ProjectTeamPaymentsModalModule,
  ProjectTeamMemberModule
])
  // .config($stateProvider => {
  //   'ngInject'
  //   $stateProvider
  //     .state({
  //       parent: 'tasks',
  //       name: 'project-team',
  //       url: '/:id/project-team',
  //       template: '<cdbl-project-team></cdbl-project-team>',
  //     })
  // })
  .component('cdblProjectTeam', ProjectTeamComponent)
  .service('ProjectTeamService', ProjectTeamService)
  .name

export default ProjectTeamModule
