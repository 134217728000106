import templateUrl from './submission-login-register.html'
import './submission-login-register.scss'

const SubmissionLoginRegisterComponent = {
  bindings: {
    user: '<',
    authform: '<',
    parentForm: '<' // TODO: because submission login register component template does not have own form element we have to pass angular form object
  },
  templateUrl,
  controller: class SubmissionLoginRegister {
    constructor (Auth) {
      'ngInject'
      this._identify = 'SubmissionLoginRegisterComponent'
      this.title = ''
      this.subtitle = ''
      this.toggleEventName = ''
      this.toggleName = ''
      this.auth = Auth.me()
    }

    $onInit () {
      this.setText()
    }

    /**
     * Sets general text
     */
    setText () {
      if (this.authform.type === 'register') {
        this.title = 'Create a Codeable account'
        this.subtitle = 'Already have an account?'
        this.toggleEventName = 'Click on login toggle'
        this.toggleName = 'Sign in to your account'
      }
      if (this.authform.type === 'sign in') {
        this.title = 'Log in to Codeable'
        this.subtitle = 'Don\'t have an account?'
        this.toggleEventName = 'Click on register toggle'
        this.toggleName = 'Create an account'
      }
    }

    /**
     * Toggle between Register and Sign in form
     * @return {[type]} [description]
     */
    toggleFormType () {
      if (this.authform.type === 'register') {
        this.authform.type = 'sign in'
      } else {
        this.authform.type = 'register'
      }
      this.setText()
    }
  }
}

export default SubmissionLoginRegisterComponent
