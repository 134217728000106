import templateUrl from './project-cancel-modal.html'
import './project-cancel-modal.scss'

const ProjectCancelModalComponent = {
  bindings: {

  },
  templateUrl,
  require: {
    modalCtrl: '^^cdblModal'
  },
  controller: class ProjectCancelModal {
    constructor ($state, EventEmitter, ProjectService, SegmentAnalytics) {
      'ngInject'
      this._identify = 'ProjectCancelModalComponent'
      this.$state = $state
      this.EventEmitter = EventEmitter
      this.ProjectService = ProjectService
      this.SegmentAnalytics = SegmentAnalytics

      this.isCanceled = false
      this.isProcessing = false

      this.serverError = null

      this.otherComment = {} // pass to feedback directive which populates it
    }

    $onInit () {
      // Declare listeners/callbacks here
      // ---------------------------------------------------
      const vm = this
      // TODO: replace when new feedback component is refactored
      this.onFeedbackSelect = function (answer, outerIndex) {
        vm.selectedFeedbackAnswer = answer
        if (answer.text === 'Other' || answer.inputType === 'text') {
          vm.otherComment[outerIndex] = true
        } else {
          vm.otherComment[outerIndex] = false
        }
        console.log('[ProjectCancelModalComponent] > onFeedbackSelect', vm.selectedFeedbackAnswer, vm.otherComment)
      }
      // ----
    }


    cancelProject () {
      console.log('[ProjectCancelModalComponent] > cancelProject')

      this.isProcessing = true
      this.ProjectService.cancelProject(this.modalCtrl.modal.project.id)
        .then(response => {
          console.log('[ProjectCancelModalComponent] > response', response)
          console.log('[ProjectCancelModalComponent] > project', this.modalCtrl.modal.project)
          console.log('[ProjectCancelModalComponent] > answer', this.selectedFeedbackAnswer)

          this.isCanceled = true

          if (this.modalCtrl.modal.onCanceled) {
            this.modalCtrl.modal.onCanceled(this.EventEmitter({
              projectId: this.modalCtrl.modal.project.id
            }))
          }

          this.SegmentAnalytics.trackTaskCanceled(this.modalCtrl.modal.project, this.selectedFeedbackAnswer)
        }, error => {
          console.log('[ProjectCancelModalComponent] > error', error)
          this.serverError = error
        })
        .finally(() => {
          this.isProcessing = false
        })
    }
  }
}

export default ProjectCancelModalComponent
