import templateUrl from './project-team.html'
import './project-team.scss'

const ProjectTeamComponent = {
  bindings: {},
  templateUrl,
  controller: class ProjectTeam {
    constructor () {
      'ngInject'
      this._identify = 'ProjectTeamComponent'
    }
  }
}

export default ProjectTeamComponent
