import templateUrl from './project-browse.html'
import './project-browse.scss'

const ProjectBrowseComponent = {
  bindings: {},
  templateUrl,
  controller: class ProjectBrowse {
    constructor ($mdMedia, UIService) {
      'ngInject'
      this._identify = 'ProjectBrowseComponent'
      this.$mdMedia = $mdMedia
      this.UIService = UIService
    }
  }
}

export default ProjectBrowseComponent
