import templateUrl from './autocomplete-tag.html'
import './autocomplete-tag.scss'

const AutocompleteTagComponent = {
  bindings: {
    data: '<',
    onRemove: '&?'
  },
  transclude: {
    'description': '?cdblAutocompleteTagDescription'
  },
  templateUrl,
  controller: class AutocompleteTag {
    constructor (EventEmitter) {
      'ngInject'
      this._identify = 'AutocompleteTagComponent'
      this.EventEmitter = EventEmitter
    }
    remove (option) {
      if (this.onRemove) {
        this.onRemove(this.EventEmitter({
          option: option
        }))
      }
    }
  }
}

export default AutocompleteTagComponent
