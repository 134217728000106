const PaymentsService = class PaymentsService {
  constructor ($http, Configuration) {
    'ngInject'

    this.$http = $http
    this.Configuration = Configuration
  }


  // TODO: split into two functions for subtask and task offline payment confirmation
  offlinePaymentConfirmation (task, subtask) {
    console.log('[PaymentsService] > offlinePaymentConfirmation', task, subtask)

    let endpointUrl = `tasks/${task.id}/payment_confirmation` // Task

    if (typeof subtask !== 'undefined') {
      endpointUrl = `tasks/${task.id}/sub_tasks/${subtask.id}/payment_confirmation` // Subtask
    }

    return this.$http
      .post(`${this.Configuration.apiUrl}/${endpointUrl}`)
      .then(response => {
        return response.data
      })
      .catch(err => {
        return err
      })
  }
}

export default PaymentsService
