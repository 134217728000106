import templateUrl from './accept-tos-modal.html'
import './accept-tos-modal.scss'


const AcceptTOSModalComponent = {
  bindings: {
    token: '<'
  },
  require: {
    modalCtrl: '^^cdblModal'
  },
  templateUrl,
  controller: class AcceptTOSModal {
    constructor (AuthService, SettingsExpertTeamService, $cookies, $location, $stateParams, $state, UserResource, Configuration) {
      'ngInject'
      this.AuthService = AuthService
      this.SettingsExpertTeamService = SettingsExpertTeamService
      this.$cookies = $cookies
      this.$location = $location
      this.$stateParams = $stateParams
      this.$state = $state
      this.UserResource = UserResource
      this.Configuration = Configuration
      this.toc = null
    }

    $onInit () {
    }

    acceptTOS () {
      this.AuthService.acceptTOS(this.token)
        .then((user) => {
          this.modalCtrl.close()

          if (!user.trackedSegmentByLink && user.role === 'client') {
            this.$cookies.put('segmentUser', 'yes')
          } else {
            this.$cookies.put('segmentUser', 'no')
          }


          this.AuthService.log()
          if (this.$stateParams.redirect) {
            this.$location.url(this.$stateParams.redirect)
          } else if (user.role === 'client') {
            if (user.tasksCount === 1) {
              console.log('client > tasksCount', user.tasksCount)
              var requests = ['tasksInProgress', 'tasksInquiring']
              this.UserResource.getUser(requests).then(function (tasks) {
                var taskId
                if (tasks.tasksInProgress.length === 1) {
                  taskId = tasks.tasksInProgress[0].id
                } else if (tasks.tasksInquiring.length === 1) {
                  taskId = tasks.tasksInquiring[0].id
                }

                console.log('client > taskId', taskId)
                this.$state.go('tasks.display', { id: taskId })
              }).catch(function () {
                this.$state.go(this.Configuration.defaultRouterState.name)
              })
            } else {
              this.$state.go(this.Configuration.defaultRouterState.name)
            }
          } else {
            this.$state.go(this.Configuration.defaultRouterState.name)
          }

        // TODO move to app service and call it here once login is completed
          this.UserService.getAutoDisplayModalsList()
            .then(response => {
              console.log('[SignInCtrl] > getAutoDisplayModalsList > response', response)
              response.forEach(autoDisplayModal => {
                if (autoDisplayModal.name === this.SettingsExpertTeamService.AUTO_DISPLAY_CONFIRMATION_MODAL_NAME) {
                  this.SettingsExpertTeamService.showUpdateConfirmationModal()
                }
              })
            })
            .catch(err => {
              console.log('[SignInCtrl] > getAutoDisplayModalsList > err', err)
            })
        // ----
        })
    }
  }
}

export default AcceptTOSModalComponent
