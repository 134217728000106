import templateUrl from './expert-profile-edit.html'
import './expert-profile-edit.scss'

import expertProfileConfig from './expert-profile-edit.config.js'

const ExpertProfileEditComponent = {
  bindings: {},
  templateUrl,
  controller: class ExpertProfileEditComponent {
    constructor ($anchorScroll, $location, $timeout, $window, UIService, UtilService, ModalService, UserService, EventBusService, ExpertProfileService) {
      'ngInject'
      this._identify = 'ExpertProfileEditComponent'
      this.$anchorScroll = $anchorScroll
      this.$location = $location
      this.$timeout = $timeout
      this.$window = $window
      this.UIService = UIService
      this.UtilService = UtilService
      this.ModalService = ModalService
      this.UserService = UserService
      this.EventBusService = EventBusService
      this.ExpertProfileService = ExpertProfileService
    }

    $onInit () {
      console.log('debug [ExpertProfileEditComponent] > $onInit')
      this.formData = {}
      this.fieldsConfig = expertProfileConfig.profileFieldsConfig
      this.sectionsConfig = expertProfileConfig.sectionsConfig

      this.isSaveProcessing = false
      this.isPublishProcessing = false
      this.isLoadingProfile = false
      this.isLoadingDatasets = false
      this.isDraftSaved = false // initial state of isDraftSaved after form data loaded

      this.sortableConfig = {
        animation: 150,
        chosenClass: 'draggable-item--selected',
        draggable: '.draggable-item',
        handle: '.draggable-item__handle',
        swapThreshold: 0.65,
        onUpdate: this.onUpdateSortableHandler.bind(this)
      }

      this.datasets = {
        projectTypes: [],
        countries: [],
        languages: [],
        skills: [],
        timezones: [],
        coreSpecialityTypes: [],
        roleTypes: [],
      }

      // load saved profile data
      this.isLoadingProfile = true
      this.ExpertProfileService.getProfile()
        .then(response => {
          console.log('[ExpertProfileEditComponent] > getProfile', response)
          this.updateLocalData(response)
          this.isDraftSaved = true // set it to saved when data is loaded

          // config for location autocomplete field
          this.locationOptionsListApi = {
            method: response.resources.locations.method,
            urlSlug: response.resources.locations.url,
            paramName: 'city',
            labelFormatter: function (option) {
              if (option && option.displayName) {
                return option.displayName
              }

              return ''
            }
          }

          this.isLoadingDatasets = true
          this.ExpertProfileService.getAllDatasets(response.resources)
            .then(response => {
              console.log('[ExpertProfileEditComponent] > getAllDatasets', response)
              const { portfolio, favoriteReviews, ...datasets } = response
              this.formData.portfolio = portfolio
              this.formData.favoriteReviews = favoriteReviews

              this.datasets = { ...this.datasets, ...datasets }
            })
            .finally(() => {
              this.isLoadingDatasets = false
            })
        })
        .finally(() => {
          this.isLoadingProfile = false
        })

      // show prompt when navigating to external pages if form is dirty
      this.$window.onbeforeunload = (evt) => {
        if (this.editForm && this.editForm.$dirty) {
          return true
        }

        delete evt['returnValue']
        return null
      }

      // // show prompt when navigating to internal pages if form is dirty
      // this.onStateChangeHandler = evt => {
      //   console.log('[ExpertProfileEditComponent] > on $stateChangeStart', evt)

      //   if (this.editForm && this.editForm.$dirty) {
      //     const confirm = this.$window.confirm('You have unsaved changes. Do you want to leave this page?')
      //     if (!confirm) {
      //       evt.$event.preventDefault()
      //     }
      //   }
      // }
      // this.EventBusService.channel().subscribe('$stateChangeStart', this.onStateChangeHandler)
    }

    $onDestroy () {
      console.log('debug [ExpertProfileEditComponent] > $onDestroy')
      // this.$window.onbeforeunload = null
      // if (this.onStateChangeHandler) {
      //   this.EventBusService.channel().unsubscribe('$stateChangeStart', this.onStateChangeHandler)
      // }
    }

    onUpdateSortableHandler (evt) {
      console.log('onUpdateSortableHandler', evt)
      this.editForm.$setDirty()
    }

    openDraftPreview () {
      this.ModalService.open({
        closeOnBackdrop: true,
        closeIcon: true,
        class: 'cdbl-modal--style-with-sticky-header-and-footer expert-profile-view-modal cdbl-modal--style-without-dividers',
        template: `<cdbl-expert-profile-view-modal></cdbl-expert-profile-view-modal>`,
        mode: 'draft'
      })
    }

    openPublicPreview () {
      this.ModalService.open({
        closeOnBackdrop: true,
        closeIcon: true,
        class: 'cdbl-modal--style-with-sticky-header-and-footer expert-profile-view-modal cdbl-modal--style-without-dividers',
        template: `<cdbl-expert-profile-view-modal></cdbl-expert-profile-view-modal>`,
        mode: 'public'
      })
    }

    updateLocalData (response) {
      const { resources, ...data } = response
      // this.allowedActions = actions
      this.formData = { ...this.formData, ...data }
    }

    get allowedActions () {
      return this.formData.actions
    }

    get canPreviewDraft () {
      return Boolean(this.allowedActions && this.allowedActions.previewDraft)
    }

    get canPreviewPublic () {
      return Boolean(this.allowedActions && this.allowedActions.previewPublished)
    }

    get canAddPortfolioItem () {
      return Boolean(this.formData.portfolio && this.formData.portfolio.actions && this.formData.portfolio.actions.addItem && this.formData.portfolio.items.length <= 20)
    }

    get canEditReviews () {
      return Boolean(this.formData.favoriteReviews && this.formData.favoriteReviews.actions && this.formData.favoriteReviews.actions.update)
    }

    get canPublish () {
      if (!this.editForm || !this.fieldsConfig) {
        return false
      }

      const isEmptyResult = Object.entries(this.fieldsConfig).reduce((result, [fieldName, fieldConfig]) => {
        if (fieldConfig.requiredForPublish) {
          return result || this.UtilService.isEmpty(this.formData[fieldName])
        }
        return result
      }, false)

      const allowedAction = this.allowedActions && Boolean(this.allowedActions.publishDraft)

      return this.editForm.$valid && !isEmptyResult && this.isDraftSaved && this.editForm.$pristine && allowedAction
    }

    get showSavedStatus () {
      return this.isDraftSaved && this.editForm.$pristine
    }

    get showPublishedStatus () {
      return this.formData.alreadyPublished && this.editForm.$pristine
    }

    get isLoading () {
      return this.isLoadingProfile || this.isLoadingDatasets || !this.fieldsConfig
    }

    get isProcessing () {
      return this.isSaveProcessing || this.isPublishProcessing
    }

    get currentUser () {
      return this.UserService.user
    }

    get toggleVisibilityAction () {
      return this.formData.actions.toggleVisibility
    }

    isSectionCompleted (section) {
      if (!this.isLoading && Array.isArray(section.progressFields) && this.fieldsConfig) {
        // exception for completed progress on portfolio items - items need to be created and at least one selected
        if (section.id === 'portfolio' && Array.isArray(this.formData.portfolio)) {
          const inProfileList = this.formData.portfolio.filter(p => p.inProfile)
          return inProfileList.length > 0
        }

        const isEmptyResult = section.progressFields.reduce((result, fieldName) => {
          return result || this.UtilService.isEmpty(this.formData[fieldName])
        }, false)

        return !isEmptyResult
      }

      return false
    }

    get currentProgress () {
      const sectionsWithProgressFields = this.sectionsConfig.filter(section => Boolean(section.progressFields))
      if (!sectionsWithProgressFields.length) {
        return 0
      }

      const totalCount = sectionsWithProgressFields.length
      const completedCount = sectionsWithProgressFields.reduce((result, section) => {
        if (this.isSectionCompleted(section)) {
          return result + 1
        }

        return result
      }, 0)

      return Math.round(completedCount / totalCount * 100)
    }

    onChangeShowOnWebsiteHandler () {
      this.formData.showOnWebsite = !this.formData.showOnWebsite
    }

    getShowOnWebsiteValue () {
      return this.formData.showOnWebsite
    }

    isPortfolioItemSelected (portfolio) {
      if (Array.isArray(this.formData.portfolio)) {
        return Boolean(this.formData.portfolio.find(selectedPortfolio => selectedPortfolio.id === portfolio.id))
      }
      return false
    }

    openPortfolioEditModal (portfolioItem = null) {
      this.ModalService.open({
        closeOnBackdrop: false,
        closeIcon: true,
        class: 'expert-profile-portfolio-edit-modal cdbl-modal--style-with-dividers cdbl-modal--style-with-sticky-header-and-footer',
        template: `
          <cdbl-expert-profile-portfolio-edit-modal
            portfolio="$ctrl.modal.portfolio"
            portfolio-item="$ctrl.modal.portfolioItem"
            on-save="$ctrl.modal.onPortfolioSaveHandler($event)">
          </cdbl-expert-profile-portfolio-edit-modal>`,
        portfolioItem: portfolioItem,
        portfolio: this.formData.portfolio,
        onPortfolioSaveHandler: this.onPortfolioSaveHandler.bind(this)
      })
    }

    openProfileVisibilityModal () {
      this.ModalService.open({
        closeOnBackdrop: false,
        closeIcon: true,
        layout: 'center',
        class: 'expert-profile-visibility-modal cdbl-modal--style-with-dividers cdbl-modal--style-with-sticky-header-and-footer',
        template: `
          <cdbl-expert-profile-visibility-modal
            show-on-website="$ctrl.modal.showOnWebsite"
            show-on-website-handler="$ctrl.modal.onChangeShowOnWebsiteHandler($event)"
            toggle-visibility-action="$ctrl.modal.toggleVisibilityAction">
          </cdbl-expert-profile-visibility-modal>`,
        showOnWebsite: this.getShowOnWebsiteValue.bind(this),
        showOnWebsiteHandler: this.onChangeShowOnWebsiteHandler.bind(this),
        toggleVisibilityAction: this.toggleVisibilityAction
      })
    }


    onPortfolioDeleteHandler (portfolio) {
      console.log('[ExpertProfileEditComponent] > onPortfolioDeleteHandler', portfolio)

      this.ModalService.open({
        title: `Delete portfolio item?`,
        body: `Are you sure you want to delete portfolio <strong>${portfolio.title}</strong>?`,
        closeOnBackdrop: false,
        closeIcon: false,
        backdrop: true,
        layout: 'center',
        class: 'expert-profile-portfolio-delete-confirmation-modal cdbl-modal--style-with-dividers',
        actions: [
          {
            label: 'No, Cancel',
            class: 'button-secondary',
            callback: modalCtrl => {
              modalCtrl.close()
            }
          },
          {
            label: 'Yes, Delete',
            class: 'button-reject',
            callback: modalCtrl => {
              this.ExpertProfileService.deletePortfolioItem(portfolio)
                .then(response => {
                  const removeIndex = this.formData.portfolio.items.findIndex(p => p.id === portfolio.id)
                  if (removeIndex > -1) {
                    this.formData.portfolio.items.splice(removeIndex, 1)

                    if (response.actions && response.actions.publishDraft) {
                      this.formData.actions.publishDraft = response.actions.publishDraft
                    }
                    this.formData.alreadyPublished = false // mark it as not published because of the change to portfolio item
                  }
                })
                .finally(() => {
                  modalCtrl.close()
                })
            }
          }
        ]
      })
    }

    onPortfolioSelectHandler ($event) {
      console.log('[ExpertProfileEditComponent] > onPortfolioSelectHandler', $event)
      this.editForm.$setDirty()
    }

    onPortfolioSaveHandler ($event) {
      console.log('[ExpertProfileEditComponent] > onPortfolioSaveHandler', $event)
      if ($event.portfolioItem) {
        if (!Array.isArray(this.formData.portfolio.items)) {
          this.formData.portfolio.items = []
        }

        const existingPortfolio = this.formData.portfolio.items.find(p => p.id === $event.portfolioItem.id)
        if (existingPortfolio) {
          // update existing portfolio item
          Object.assign(existingPortfolio, $event.portfolioItem)
        } else {
          // add new portfolio item
          this.formData.portfolio.items.push($event.portfolioItem)
        }

        if ($event.portfolioItem.actions && $event.portfolioItem.actions.publishDraft) {
          this.formData.actions.publishDraft = $event.portfolioItem.actions.publishDraft
        }
        this.formData.alreadyPublished = false // mark it as not published because of the change to portfolio item
      }
    }


    openReviewsEditModal () {
      this.ModalService.open({
        closeOnBackdrop: false,
        closeIcon: true,
        class: 'expert-profile-reviews-edit-modal cdbl-modal--style-with-dividers cdbl-modal--style-with-sticky-header-and-footer',
        template: `
          <cdbl-expert-profile-reviews-edit-modal
            favorite-reviews="$ctrl.modal.favoriteReviews"
            on-save="$ctrl.modal.onReviewsSaveHandler($event)">
          </cdbl-expert-profile-reviews-edit-modal>`,
        favoriteReviews: this.formData.favoriteReviews,
        onReviewsSaveHandler: this.onReviewsSaveHandler.bind(this)
      })
    }

    onReviewsSaveHandler ($event) {
      this.formData.favoriteReviews = $event.favoriteReviews
      if ($event.favoriteReviews.actions && $event.favoriteReviews.actions.publishDraft) {
        this.formData.actions.publishDraft = $event.favoriteReviews.actions.publishDraft
      }
      this.formData.alreadyPublished = false // mark it as not published because of the change to reviews selection
    }

    // helper functions for field property retreival
    fieldProp (fieldName, propName) {
      const field = this.fieldsConfig[fieldName]
      if (field && field[propName]) {
        return field[propName]
      }
      return null
    }

    fieldLabel (fieldName) {
      return this.fieldProp(fieldName, 'label')
    }
    fieldHelper (fieldName) {
      return this.fieldProp(fieldName, 'helper')
    }
    fieldDescription (fieldName) {
      return this.fieldProp(fieldName, 'description')
    }
    fieldPlaceholder (fieldName) {
      return this.fieldProp(fieldName, 'placeholder')
    }
    fieldValidation (fieldName) {
      return this.fieldProp(fieldName, 'validation')
    }
    // ---

    collapseAll () {
      this.sectionsConfig.forEach(section => {
        section.isCollapsed = true
      })
    }

    expandAll () {
      this.sectionsConfig.forEach(section => {
        section.isCollapsed = false
      })
    }

    openSidebar () {
      const sidebarCtrl = this.UIService.getComponent('sidebar-main-left')
      if (sidebarCtrl) {
        sidebarCtrl.open()
      }
    }

    closeSidebar () {
      const sidebarCtrl = this.UIService.getComponent('sidebar-main-left')
      if (sidebarCtrl) {
        sidebarCtrl.close()
      }
    }

    goToSection (section) {
      if (section && section.id) {
        const newHash = section.id
        if (this.$location.hash() !== newHash) {
          this.$location.hash(section.id)
        } else {
          this.$anchorScroll()
        }
        // expand section user wants to navigate to
        section.isCollapsed = false
      }

      // close sidebar
      this.closeSidebar()
    }

    saveProfile () {
      // save profile, portfolio items are saved with profile however to refresh data it should be refetched with separate endpoint
      this.serverError = null
      this.isSaveProcessing = true
      this.ExpertProfileService.saveProfile(this.formData)
        .then(response => {
          this.updateLocalData(response)

          // refetch portfolio items endpoint to get fresh list
          this.ExpertProfileService.refechPortfolio(this.formData.portfolio)
            .then(response => {
              this.formData.portfolio = response
            })
            .finally(() => {
              this.isSaveProcessing = false
            })

          this.isDraftSaved = true
          this.editForm.$setPristine()
        })
        .catch(err => {
          console.log('[ExpertProfileEditComponent] > saveProfile > err', err)
          this.isSaveProcessing = false
          this.serverError = err
        })
    }

    publishDraft () {
      this.serverError = null
      this.isPublishProcessing = true
      this.ExpertProfileService.publishDraft(this.formData)
        .then(response => {
          this.updateLocalData(response.draft)
          this.ExpertProfileService.refechPortfolio(this.formData.portfolio)
            .then(response => {
              this.formData.portfolio = response
            })
            .catch(err => {
              this.serverError = err
            })
            .finally(() => {
              this.isPublishProcessing = false
            })
        })
        .catch(err => {
          console.log('[ExpertProfileEditComponent] > publishDraft > err', err)
          this.isPublishProcessing = false
          this.serverError = err
        })
    }
  }
}

export default ExpertProfileEditComponent
