import templateUrl from './change-email.html'
import './change-email.scss'


const ChangeEmailModalComponent = {
  bindings: {
    email: '<',
    onSave: '&?'
  },
  require: {
    modalCtrl: '^^cdblModal'
  },
  templateUrl,
  controller: class ChangeEmailModalComponent {
    constructor ($timeout, EventEmitter, ModalService, SettingsService) {
      'ngInject'
      this._identify = 'ChangeEmailModalComponent'
      this.$timeout = $timeout
      this.EventEmitter = EventEmitter
      this.SettingsService = SettingsService
      this.ModalService = ModalService
    }

    $onInit () {
      this.isCompleted = false
      this.formData = {
        currentPassword: '',
        newEmail: '',
        confirmNewEmail: ''
      }
    }
    changePassword () {
      this.error = null
      this.isProcessing = true
      console.log('[ChangeEmailModalComponent] > changeEmail')
      this.SettingsService
        .changePassword({
          currentPassword: this.formData.currentPassword,
          newEmail: this.formData.currentEmail
        })
        .then(response => {
          console.log('[ChangeEmailModalComponent] > changeEmail > then')
          this.isCompleted = true
        })
        .catch(err => {
          console.log('[ChangeEmailModalComponent] > changeEmail > err', err)
          if (err.data.errors.find(e => e.field === 'current_password' && e.message === 'is incorrect')) {
            this.error = 'Your current password is incorrect. Please try again.'
          } else {
            this.error = err.data.errors[0].message // display first error message
          }
        })
        .finally(() => {
          console.log('[ChangeEmailModalComponent] > changeEmail > finally')
          this.isProcessing = false
        })
      // this.$timeout()
    }

    async changeEmail () {
      this.error = null
      const payload = {
        email: this.formData.newEmail,
        current_password: this.formData.currentPassword
      }
      this.isProcessing = true
      await this.SettingsService.changePassword(payload)
        .then(user => {
          this.isCompleted = true
        }).catch(err => {
          // TODO refactor this before it grows
          if (err.data.errors.find(e => e.field === 'current_password' && e.message === 'is incorrect')) {
            this.error = 'Your password is incorrect. Please try again.'
          } else if (err.data.errors.find(e => e.field === 'email' && e.message === 'An account with that email is already registered.')) {
            this.error = 'This email is already in use by another account.'
          } else if (err.data.errors.find(e => e.reason === 'account_locked')) {
            this.closeDialog()
          } else {
            this.error = 'Something went wrong.'
          }
          this.isProcessing = false
        })
        .finally(() => {
          this.isProcessing = false
        })
    }

    closeDialog () {
      this.modalCtrl.resolve()
    }
  }
}

export default ChangeEmailModalComponent
