import templateUrl from './settings-invoices.html'
import './settings-invoices.scss'

const SettingsInvoicesComponent = {
  bindings: {},
  templateUrl,
  controller: class SettingsInvoicesComponent {
    constructor ($timeout, SettingsService) {
      'ngInject'
      this._identify = 'SettingsInvoicesComponent'
      this.$timeout = $timeout
      this.SettingsService = SettingsService
    }

    async $onInit () {
      this.formData = {}


      this.isLoading = true
      await this.SettingsService.getInvoicesSettings()
        .then(response => {
          this.formData = response
          console.log(response)
        })
      this.isLoading = false
      this.$timeout()
    }



    async saveSettings () {
      this.isProcessing = true
      await this.SettingsService.updateInvoicesSettings(this.formData)
        .catch(err => {
          console.log(err)
        })

      this.isProcessing = false
      this.invoicesForm.$setPristine()
      this.$timeout()
    }
  }
}

export default SettingsInvoicesComponent
