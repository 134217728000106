import ExpertProfileReviewsComponent from './expert-profile-reviews.component'
// import ExpertProfileReviewsService from './expert-profile-reviews.service'

const ExpertProfileReviewsModule = angular.module('cdbl.expert-profile-reviews', [

])
  .component('cdblExpertProfileReviews', ExpertProfileReviewsComponent)
  // .service('ExpertProfileReviewsService', ExpertProfileReviewsService)
  .name

export default ExpertProfileReviewsModule
