(function () {
  'use strict';

  function serverError() {
    var link = function ($scope, element, attr) {
      $scope.$on('server-errors', function (event, serverErrors) {
        // shows errors
        if (angular.isObject(serverErrors)) {
          console.log('server-errors', serverErrors)
          angular.forEach(serverErrors.data.errors, function (error) {
            var field = $scope[attr.name][error.field.toCamelCase()];

            if (field) {
              field.$error.message = error.message;
              field.$setValidity('server', false);
            }
          });
        }
      });
    };

    return {
      restrict: 'A',
      link: link
    };
  }

  app.directive('serverError', serverError);
})();
