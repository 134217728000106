import templateUrl from './submission-auth.html'
import './submission-auth.scss'

const SubmissionAuthComponent = {
  bindings: {
    data: '<',
    hideToggleFormType: '<'
  },
  templateUrl,
  controller: class SubmissionAuth {
    constructor ($timeout) {
      'ngInject'
      this._identify = 'SubmissionAuthComponent'
      this.$timeout = $timeout

      this.showValidationErrors = true
    }

    $onInit () {
      console.log('[SubmissionAuthComponent] > $onInit')
      this.setText()
      this.$timeout(() => {})
    }

    setText () {
      if (this.data.formType === 'register') {
        this.title = 'Finish creating your account'
        this.subtitle = `Already have an account?`
        this.toggleEventName = `Click on login toggle`
        this.toggleName = `Login`
        this.passwordLabel = `Create a password`
      } else if (this.data.formType === 'login') {
        this.title = `Log in to Codeable`
        this.subtitle = `Don't have an account?`
        this.toggleEventName = `Click on register toggle`
        this.toggleName = `Create an account`
        this.passwordLabel = `Password`
      }
    }

    // get modelValue () {
    //   return this.ngModel.$viewValue
    // }

    // set modelValue (newValue) {
    //   this.ngModel.$setViewValue(
    //     newValue,
    //     "click"
    //   )
    // }
  }
}

export default SubmissionAuthComponent
