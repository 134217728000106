(function () {
  'use strict';

  function timeDifference(moment) {
    return function (date, format) {
      format = format || 'days';

      if (date) {
        var now = moment(new Date());
        var start = moment(date);

        return now.diff(start, format);
      }
    };
  }

  app.filter('timeDifference', ['moment', timeDifference]);
})();
