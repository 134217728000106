import IconComponent from './icon.component'
import IconService from './icon.service'

const IconModule = angular.module('cdbl.icon', [

])
  .service('IconService', IconService)
  .component('cdblIcon', IconComponent)
  .name

export default IconModule
