import templateUrl from './skeleton.html'
import './skeleton.scss'

const SkeletonComponent = {
  bindings: {
    noOfLines: '<',
    spacing: '<',
    height: '<'
  },
  transclude: true,
  templateUrl,
  controller: class SkeletonComponent {
    constructor ($attrs) {
      'ngInject'
      this._identify = 'SkeletonComponent'
      this.$attrs = $attrs
    }

    $onInit () {
      this.options = {
        noOfLines: this.noOfLines || 1,
        spacing: this.spacing || '0.25rem',
        height: this.height || 'inherit',
        width: this.width || '100%',
      }
    }
  }
}

export default SkeletonComponent
