/**

  TODO:
  - convert this to icon provider
 */

import requireAll from '@/assets/js/helpers.js'

// const svgSpriteFile = require('iconSprite') // require('assets/images/icons-sprite.svg')
const runSequence = ($http, $location, Configuration) => {
  'ngInject'

  // import all icons from icons folder with svg-sprite-loader
  requireAll(require.context('assets/icons', true, /\.(svg)$/))

  // Inline svg icons sprite to support styling - generated by svg-sprite-loader
  // $http.get(`${$location.protocol()}://${$location.host()}${$location.host() === 'localhost'? ':9000' : ''}/icons-sprite.${Configuration.REVISION}.svg`).then(response => {

  $http.get(`/icons-sprite.${Configuration.REVISION}.svg`).then(response => {
    const div = document.createElement('div')
    div.innerHTML = response.data
    div.style.display = 'none'
    div.id = 'svgSpriteInline'
    document.body.insertBefore(div, document.body.childNodes[0])
  }, error => {
    console.log('load inline icons > error', error)
  })
}
export default runSequence
