import templateUrl from './expert-profile-edit-review-item.html'
import './expert-profile-edit-review-item.scss'

const ExpertProfileEditReviewItemComponent = {
  bindings: {
    review: '<',
  },
  templateUrl,
  controller: class ExpertProfileEditReviewItemComponent {
    constructor (EventEmitter) {
      'ngInject'
      this._identify = 'ExpertProfileEditReviewItemComponent'
      this.EventEmitter = EventEmitter
    }

    $onInit () {

    }
  }
}

export default ExpertProfileEditReviewItemComponent
