// Sums array by index Price format; prepends $, formats number and limits to 2 decimals
function sumArrayByKey() {
  return function (arr, key, filterKey, filterValue) {
    if (typeof (arr) === 'undefined' || typeof (key) === 'undefined') {
      return 0;
    }

    let sum = 0;
    if (typeof filterKey !== 'undefined' && typeof filterValue !== 'undefined') {
      for (let i = arr.length - 1; i >= 0; i--) {
        if (arr[i][filterKey] === arr[i][filterKey]) {
          sum += arr[i][key];
        }
      }
    } else if (typeof filterKey !== 'undefined' && typeof filterValue === 'undefined') {
      for (let i = arr.length - 1; i >= 0; i--) {
        if (arr[i][filterKey]) {
          sum += arr[i][key];
        }
      }
    } else {
      for (let i = arr.length - 1; i >= 0; i--) {
        sum += arr[i][key];
      }
    }

    return sum;
  };
}

app.filter('sumArray', [sumArrayByKey]);
