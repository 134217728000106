import countries from '../../json/countries.json';
app.directive('countrySelect', ['$timeout', function ($timeout) {
  return {
    restrict: 'E',
    template: `
      <select class="dropdown" ng-model="data" ng-change="callbackOnChange()" ng-options="item for item in countries" ng-required="isRequired"></select>
    `,
    scope: {
      data: '=',
      onchange: '&'
    },
    link: function (scope, elem, attrs) {
      // console.log('countrySelect', scope.data);
      scope.countries = countries;
      scope.isRequired = false;

      if ('required' in attrs) {
        console.log('countrySelect > required');
        scope.isRequired = true;
      }

      scope.callbackOnChange = function () {
        // console.log('countrySelect > on change', scope.data);
        if (angular.isFunction(scope.onchange)) {
          $timeout(scope.onchange);
        }
      };
    }
  };
}]);
