import templateUrl from './presets-apply.html'
import './presets-apply.scss'

const ProjectPresetsApplyComponent = {
  bindings: {},
  templateUrl,
  controller: class ProjectPresetsApplyComponent {
    constructor ($stateParams, $state, $timeout, $location, ProjectPresetsService, AuthService) {
      'ngInject'
      this._identify = 'ProjectPresetsApplyComponent'
      this.$stateParams = $stateParams
      this.$state = $state
      this.$timeout = $timeout
      this.$location = $location
      this.ProjectPresetsService = ProjectPresetsService
      this.AuthService = AuthService
    }

    async $onInit () {
      console.log('[PresetsApply] > $onInit')
      this.isLoading = false
      this.isProcessing = false
      this.errorMessages = {
        // loading of form
        'not_found': 'Form not found.',
        'expired': 'This link has expired. Please, contact the person onboarding you to Codeable for a new link.',
        'posted_already': 'This project has already been posted. Please, contact the person onboarding you to Codeable for a new link.',
        'anonymized_already': 'This project has already been posted. Please, contact the person onboarding you to Codeable for a new link.',
        'PreferredExpertNotFoundError': 'The Codeable expert that was preselected for your project is no longer available. Please, contact the person onboarding you to Codeable for a new link.',
      }


      this.loadPreset()
    }

    loadPreset () {
      console.log('[PresetsApply] > loadPreset > params', this.$stateParams)
      this.error = null
      this.isLoading = true
      this.ProjectPresetsService.loadPreset(this.$stateParams)
        .then(data => {
          this.preset = data
          this.ProjectPresetsService.trackEventPresetLoaded(this.preset)
        })
        .catch(err => {
          this.error = err
        })
        .finally(() => {
          this.isLoading = false
          this.$timeout()
        })
    }

    get showAuthForm () {
      return !this.AuthService.isAuthenticated()
    }

    onProjectPublishHandler ($event) {
      console.log('[PresetsApply] > onProjectPublishHandler', $event)
      this.isProcessing = true
      return this.ProjectPresetsService.publishProjectWithPreset($event.presetData, $event.authData, this.$stateParams.token)
        .then(project => {
          // console.log('[PresetsApply] > onProjectPublishHandler > response', project)

          if (typeof project?.id === 'number') {
            // this.$state.go('dashboard.list')
            this.$state.go('tasks.display', { id: project.id })
          }

          return project // return response so child component can also access it
        })
        .catch(err => {
          console.log('[PresetsApply] > onProjectPublishHandler > err', err)
          throw err // return error so child component can also access it
        })
        .finally(() => {
          console.log('[PresetsApply] > onProjectPublishHandler > finally')
          this.isProcessing = false
          this.$timeout() // needed due to publishProjectWithPreset being a await/async method and ng1 does not play nice
        })
    }
  }
}
export default ProjectPresetsApplyComponent
