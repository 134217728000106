import templateUrl from './notifications-widget.html'
import './notifications-widget.scss'

const PRIORITY_NORMAL = 'normal'
const PRIORITY_IMPORTANT = 'important'
const CHANNEL_NOTIFICATIONS = 'notifications'
const EVENT_NOTIFICATIONS_PAGE_LIST_UPDATED = 'notifications-page-list-updated'
const EVENT_NOTIFICATIONS_WIDGET_LIST_UPDATED = 'notifications-widget-list-updated'

const NotificationsWidgetComponent = {
  bindings: {
    categoryName: '<'
  },
  templateUrl,
  controller: class NotificationsWidget {
    constructor (Configuration, NotificationsService, PaginationService, UIService, EventBusService, SoundService, UserService, $state, Sentry) {
      'ngInject'
      this._identify = 'NotificationsWidgetComponent'
      this.Configuration = Configuration
      this.NotificationsService = NotificationsService
      this.PaginationService = PaginationService
      this.UIService = UIService
      this.EventBusService = EventBusService
      this.SoundService = SoundService
      this.UserService = UserService
      this.$state = $state
      this.Sentry = Sentry

      this.popoverData = {}
      this._hasNew = true
      this.isMobile = false
      this.popoverCtrl = null
    }

    $onInit () {
      // TODO: validate category name to match one from configuration

      // based on category/filter listen to new events and update the dot visibility
      this.popoverData.category = this.Configuration.features.notifications.categories[this.categoryName]
      this.popoverData.notifications = {}
      this.popoverData.markAllAsRead = () => {
        this.markAllAsRead()
      }

      // Allow important list to be loaded only within new projects popover
      // This can be extended by component bindings if other lists will also need it
      if (this.popoverData.category.widget.important) {
        this.initNotificationList({
          name: PRIORITY_IMPORTANT,
          filter: this.popoverData.category.filter,
          priority: PRIORITY_IMPORTANT,
          resolved: false,
          itemsPerPage: 2,
          dotCountComparator: n => n.isResolved === false,
        })
      }
      // ---

      if (this.popoverData.category.widget.normal) {
        this.initNotificationList({
          name: PRIORITY_NORMAL,
          filter: this.popoverData.category.filter,
          priority: PRIORITY_NORMAL,
          itemsPerPage: 20,
          dotCountComparator: n => n.isNew === true,
        })
      }

      this.subscriptionCollection = this.EventBusService.createSubscriptionCollection(CHANNEL_NOTIFICATIONS)

      // If notifications are updated via page but widget is opened - widget should listen to page event and refetch data and recalculate
      this.EventBusService.channel(CHANNEL_NOTIFICATIONS).subscribe(EVENT_NOTIFICATIONS_PAGE_LIST_UPDATED, ($event) => {
        console.log('[NotificationsWidgetComponent] > on PAGE list updated > $event', $event)
        if ($event && $event.categoryName === this.popoverData.category.name) {
          if (this.popoverData.notifications.important) {
            this.loadNotifications('important')
          }
          if (this.popoverData.notifications.normal) {
            this.loadNotifications('normal')
          }
        }
      })

      this.subscriptionCollection.subscribe(this.Configuration.features.notifications.allowed, $event => {
        this.onNewNotificationHandler($event)
      })

      // Initialise all sounds that current user have set or default ones
      this.SoundService.initAllUserSounds()
    }

    $onDestroy () {
      this.subscriptionCollection.unsubscribeAll()
      this.EventBusService.channel(CHANNEL_NOTIFICATIONS).unsubscribe(EVENT_NOTIFICATIONS_PAGE_LIST_UPDATED)
    }

    $doCheck () {
      if (this.UIService.$mdMedia('xs')) {
        // if popover is opened close popover
        if (this.popoverCtrl) {
          this.popoverCtrl.close()
        }

        // on widget icon click dont open popover but instead redirect to notification page
        this.isMobile = true
      } else {
        this.isMobile = false
      }
    }

    initNotificationList (config = {}) {
      const defaultConfig = {
        name: 'normal',
        filter: null,   // if null param will be skipped in request done by $http
        priority: null, // if null param will be skipped in request done by $http
        itemsPerPage: 20,
        dotCountComparator: null,
      }
      config = Object.assign({}, defaultConfig, config)

      // const vm = this
      let list = this.NotificationsService.getWidgetList({
        categoryName: this.categoryName,
        listName: config.name
      })
      if (!list) {
        list = this.NotificationsService.setWidgetList({
          categoryName: this.categoryName,
          listName: config.name
        }, {
          config: config,
          isLoading: false,
          list: null,
          dotCount: 0,
          dateSeparators: {},
          onActionCallback: ($event) => {
            console.log('[NotificationsWidgetComponent] > onActionCallback', config, $event)
            this.onNotificationItemActionCallback(config.name, $event)
          },
          pagination: this.PaginationService.init({
            itemsPerPage: config.itemsPerPage,
            mode: 'server'
          })
        })
      }
      this.popoverData.notifications[config.name] = list
      this.loadNotifications(config.name)

      // TODO: init EventBusService subscription to new pusher notification events to update the list
    }

    loadNotifications (listName, useLoadingIndicator = true) {
      const notificationsList = this.popoverData.notifications[listName]
      const params = {
        filter: notificationsList.config.filter,
        aggregated: true,
        priority: this.UserService.isExpert() ? notificationsList.config.priority : null, // do not set priority for clients
        page: notificationsList.pagination.currentPage,
        per_page: notificationsList.pagination.itemsPerPage
      }

      // Add conditional parameter
      if (typeof notificationsList.config.resolved !== 'undefined') {
        params.resolved = notificationsList.config.resolved
      }

      // If data was already loaded before skip loading data
      if (notificationsList.list) {
        console.log('🚀 ~ file: notifications-widget.component.js:164 ~ NotificationsWidget ~ loadNotifications ~ skip:', listName)
        return
      }
      console.log('🚀 ~ file: notifications-widget.component.js:164 ~ NotificationsWidget ~ loadNotifications ~ load:', listName, notificationsList)

      if (useLoadingIndicator) {
        notificationsList.isLoading = true
      }
      this
        .NotificationsService
        .getNotificationsList(params, notificationsList.pagination)
        .then(response => {
          notificationsList.list = response

          if (response === null) {
            this.Sentry.captureMessage('Notifications > widget > loadNotifications > response is NULL')
          }

          this.calculateDotCount(notificationsList)
        })
        .finally(() => {
          if (useLoadingIndicator) {
            notificationsList.isLoading = false
          }
        })
    }

    get showDot () {
      let count = this.popoverData.notifications.normal.dotCount
      if (this.popoverData.notifications.important) {
        count += this.popoverData.notifications.important.dotCount
      }
      return count > 0
    }

    calculateDotCount (notificationsList) {
      if (notificationsList.config.dotCountComparator) {
        notificationsList.dotCount = notificationsList.list.filter(notificationsList.config.dotCountComparator).length
      } else {
        notificationsList.dotCount = null
      }
    }

    resetDot () {
      let ids = []

      // important count will be reset on resolve of the action however we should still reset the new attiribute because they were seen in popover
      const importantNotificationsList = this.popoverData.notifications.important
      if (importantNotificationsList && importantNotificationsList.list) {
        this.calculateDotCount(importantNotificationsList)

        // reset isNew for important notifications
        const importantIds = importantNotificationsList.list
          .filter(n => n.isNew)
          .map(n => {
            n.isNew = false
            return n.id
          })

        ids = [...ids, ...importantIds]
      }

      // normal count can be reset by calling BE and reporting which notifications have isNew status to be set to false
      const normalNotificationsList = this.popoverData.notifications.normal
      if (normalNotificationsList && normalNotificationsList.list) {
        // reset isNew for normal notifications
        const normalIds = normalNotificationsList.list
          .filter(normalNotificationsList.config.dotCountComparator)
          .map(n => {
            n.isNew = false
            return n.id
          })

        ids = [...ids, ...normalIds]

        // Because isNew is reset on popover open it should be dot count should be calculated after reset of isNew for all new notifications
        this.calculateDotCount(normalNotificationsList)
      }


      if (ids.length) {
        this.NotificationsService
          .resetNew(ids)
          .then(() => {
            this.EventBusService
              .channel(CHANNEL_NOTIFICATIONS)
              .publish(EVENT_NOTIFICATIONS_WIDGET_LIST_UPDATED, {
                categoryName: this.popoverData.category.name
              })
          })
      }
    }

    onPopoverOpen ($event) {
      console.log('[NotificationsWidgetComponent] > onPopoverOpen', $event)
      this.resetDot()

      // TODO: SegmentAnalytics.trackNotificationDropdownOpen(filter)"
      // track-event="click" event-name="Click on notifications icon"
    }

    onPopoverInit ($event) {
      console.log('[NotificationsWidgetComponent] > onPopoverInit', $event)
      if ($event.popover) {
        this.popoverCtrl = $event.popover
      }
    }

    markAllAsRead () {
      console.log('[NotificationsWidgetComponent] > markAllAsRead')
      let ids = []

      const importantNotificationsList = this.popoverData.notifications.important
      if (importantNotificationsList && importantNotificationsList.list) {
        const importantIds = importantNotificationsList.list
          .filter(n => n.isRead === false)
          .map(n => {
            n.isRead = true
            return n.id
          })

        ids = [...ids, ...importantIds]
      }
      const normalNotificationsList = this.popoverData.notifications.normal
      if (normalNotificationsList && normalNotificationsList.list) {
        const normalIds = normalNotificationsList.list
          .filter(n => n.isRead === false)
          .map(n => {
            n.isRead = true
            return n.id
          })

        ids = [...ids, ...normalIds]
      }


      if (ids.length) {
        this.NotificationsService
          .markAllAsRead(ids)
          .then(() => {
            // TODO: SegmentAnalytics.trackNotificationmarkAllAsRead($scope.filter)
            this.EventBusService
              .channel(CHANNEL_NOTIFICATIONS)
              .publish(EVENT_NOTIFICATIONS_WIDGET_LIST_UPDATED, {
                categoryName: this.popoverData.category.name
              })
          })
      }
    }

    onNotificationItemActionCallback (listName, $event) {
      console.log('[NotificationsWidgetComponent] > onNotificationItemActionCallback', listName, $event)
      // Refetching the list on action callback will automatically update the dot count and remove resolved notification item from the list
      if ($event && $event.action === 'resolve') {
        this.loadNotifications(listName, false)
      }
      this.EventBusService
        .channel(CHANNEL_NOTIFICATIONS)
        .publish(EVENT_NOTIFICATIONS_WIDGET_LIST_UPDATED, {
          categoryName: this.popoverData.category.name
        })
    }




    onNewNotificationHandler ($event) {
      const newNotification = $event.data

      const priority = newNotification.priority ? newNotification.priority : 'normal'
      const projectId = newNotification.project ? newNotification.project.id : null
      const notificationsList = this.popoverData.notifications[priority]
      const canAddNewNotification = notificationsList && notificationsList.list && newNotification.filter === notificationsList.config.filter


      console.log('[NotificationsWidgetComponent] > onNewNotificationHandler', canAddNewNotification, notificationsList, newNotification)
      if (canAddNewNotification) {
        // Remove existing notification for this project - aggregate only one notification per project
        const existingProjectNotificationIndex = notificationsList.list.findIndex(n => n.project && n.project.id === projectId)
        if (notificationsList.list[existingProjectNotificationIndex]) {
          notificationsList.list.splice(existingProjectNotificationIndex, 1)
        }

        // Add to top new notification
        notificationsList.list.unshift(newNotification)

        // Recalculate dot
        this.calculateDotCount(notificationsList)

        // Play sound notification
        this.playNotificationSound(this.popoverData.category.userSoundParam, newNotification)
      }
    }


    playNotificationSound (soundParamName, notification) {
      // play sound only if comment created or new project created
      const isNewProject = notification.filter === 'new-projects' && notification.name === 'update_task'
      const isNewComment = notification.name === 'create_comment'
      if (!isNewProject && !isNewComment) {
        return // skip playing sound if none one of above is true
      }

      console.log('[NotificationsWidgetComponent] > playNotificationSound', isNewProject, isNewComment)

      // If user is expert check is notifications settings per category otherwise if its a client check if notification sounds are enabled (different property)
      if (this.UserService.isExpert()) {
        const soundConfig = this.UserService.user.notificationSettings.sounds
        const soundId = soundConfig[soundParamName]

        this.SoundService.playSound(soundId)
      } else if (this.UserService.user.notificationSoundEnabled) {
        this.SoundService.playSound()
      }
    }

    get mobileIconHref () {
      const importantNotificationList = this.popoverData.notifications.important

      // If widget icon has important list check if it has any unresolved and redirect to new project or important notifications page
      if (importantNotificationList && importantNotificationList.list && (importantNotificationList.pagination.totalCount > 0 || importantNotificationList.list.length > 0)) {
        return this.$state.href('notifications.list', { listId: 'default' }) // that is displayed as important for expert
      }

      return this.$state.href('notifications.list', { listId: this.popoverData.category.name })
    }
  }
}

export default NotificationsWidgetComponent
