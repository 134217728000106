import FieldContainerComponent from './field-container.component'
import FieldInputDirective from './field-input.directive'
import FieldTextareaDirective from './field-textarea.directive'
import FieldSelectDirective from './field-select.directive'

const FieldContainerModule = angular.module('cdbl.field-container', [

])
  .component('cdblFieldContainer', FieldContainerComponent)
  .directive('input', FieldInputDirective)
  .directive('textarea', FieldTextareaDirective)
  .directive('select', FieldSelectDirective)
  .name

export default FieldContainerModule
