import templateUrl from './list-item.html'
import './list-item.scss'

const AdditionalTasksSidebarListItemComponent = {
  bindings: {
    data: '<'
  },
  require: {
    widget: '^^cdblAdditionalTasksSidebarWidget'
  },
  templateUrl,
  controller: class AdditionalTasksSidebarListItem {
    constructor () {
      'ngInject'
      this._identify = 'AdditionalTasksSidebarListItemComponent'
    }
    get isRetainer () {
      return this.data.retainer
    }
  }
}

export default AdditionalTasksSidebarListItemComponent
