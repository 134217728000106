(function () {
  'use strict';

  function clickOutside($rootScope, $document, $parse, $timeout) {
    var link = function ($scope, element, attrs) {
      var uniqueName = new Date().getTime();
      var protectedElements = $parse(attrs.protectedElements)($scope);

      // Set binding to event-loop so we wait for a possible directive that it binds to to get executed prior
      // to binding, otherwise we "clickOutside" gets executed right away
      $timeout(() => {
        $document.on('click.{name}'.supplant({name: uniqueName}), apply);
      });

      if (protectedElements) {
        protectedElements.forEach(function (element) {
          // We try 5 times as it's possible the element is inside a directive not yet triggered,
          // we could do $broadcast, but then we would need to take into account all of them here, this solution is
          // future proof
          var tryCount = 0;

          function doSomething() {
            if (angular.element(element).length === 0) {
              if (tryCount++ < 5) {
                setTimeout(doSomething, 1000);
              }
            } else {
              angular.element(element).on('click.{name}', function (event) {
                cancelClick(event);
              });
            }
          }

          doSomething();
        });
      }

      element.on('click', function (event) {
        cancelClick(event);
      });

      element.on('$destroy', function () {
        // we need to unbind event from document, otherwise we have memory leak when using this
        // directive in combination with ng-if for instance
        $document.unbind('click.{name}'.supplant({name: uniqueName}));

        if (protectedElements) {
          protectedElements.forEach(function (element) {
            angular.element(element).unbind('click.{name}'.supplant({name: uniqueName}));
          });
        }
      });

      $scope.$on('preventedClick', function (event, data) {
        if (data.origin !== uniqueName) {
          apply();
        }
      });

      function apply() {
        const callback = $parse(attrs.clickOutside);
        $timeout(() => {
          callback($scope);
        });
      }

      function cancelClick(event) {
        event.stopPropagation(); // keeps it from firing the click on the document
        $rootScope.$broadcast('preventedClick', {origin: uniqueName}); // so we can trigger other events bind to clickOutside directive
      }
    };

    return {
      restrict: 'A',
      link: link
    };
  }

  app.directive('clickOutside', ['$rootScope', '$document', '$parse', '$timeout', clickOutside]);
})();
