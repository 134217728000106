const NotificationsService = class NotificationsService {
  constructor ($http, $stateParams, $state, $location, Configuration, PaginationService, UserService) {
    'ngInject'
    this._identify = 'NotificationsService'
    this.$http = $http
    this.Configuration = Configuration
    this.PaginationService = PaginationService
    this.UserService = UserService

    this._dateSeparatorsCollection = {}
    this.notificationsWidgets = new Map()
    this.isLoaded = false

    console.error('NotificationsService > constructor')
  }
  listKey (config) {
    return `${config.categoryName}-${config.listName}`
  }

  getWidgetList (config) {
    const key = this.listKey(config)
    const list = this.notificationsWidgets.get(key) ? this.notificationsWidgets.get(key) : null
    console.log('🚀 ~ file: notifications.service.js:17 ~ NotificationsService ~ getWidgetList:', key, list)
    return list
  }
  setWidgetList (config, data) {
    const key = this.listKey(config)
    this.notificationsWidgets.set(key, data)
    console.log('🚀 ~ file: notifications.service.js:19 ~ NotificationsService ~ setWidgetList:', key, data)
    return this.notificationsWidgets.get(key)
  }

  dateSeparatorsCollection (name = 'default') {
    if (!this._dateSeparatorsCollection[name]) {
      this._dateSeparatorsCollection[name] = {}
    }
    return this._dateSeparatorsCollection[name]
  }

  getNotificationsList (params, pagination = null, listName) {
    // TODO: remove once BE ready
    // delete params.page
    // delete params.perPage
    // ---
    // if (this.notificationsLists[listName]) {
    //   // reuse data
    //   console.log('🚀 ~ file: notifications.service.js:29 ~ NotificationsService ~ getNotificationsList', listName, this.notificationsLists[listName])
    //   return this.notificationsLists[listName]
    // }

    return this.$http
      .get(`${this.Configuration.apiUrl}/users/me/notifications`, { params })
      .then(response => {
        if (pagination) {
          pagination.updateFromHeaders(response)
        }

        const allowedVerbs = this.Configuration.features.notifications.allowed
        // filter out unsupported notifications
        const data = response.data.filter(n => allowedVerbs.includes(n.name))
        return data
      })
  }

  markAsRead (notificationId) {
    const payload = {
      isRead: true,
      response: 'v2'
    }
    return this.$http
      .patch(`${this.Configuration.apiUrl}/users/me/notifications/${notificationId}`, payload)
      .then(response => {
        return response.data
      })
  }

  markAllAsRead (notificationIds) {
    const payload = {
      isRead: true,
      ids: notificationIds,
      response: 'v2'
    }
    return this.$http
      .patch(`${this.Configuration.apiUrl}/users/me/notifications`, payload)
      .then(response => {
        return response.data
      })
  }

  toggleResolve (notificationId, isResolved) {
    const payload = {
      isResolved: isResolved,
      response: 'v2'
    }
    return this.$http
      .patch(`${this.Configuration.apiUrl}/users/me/notifications/${notificationId}`, payload)
      .then(response => {
        return response.data
      })
  }

  resetNew (notificationIds) {
    const payload = {
      isNew: false,
      ids: notificationIds,
      response: 'v2'
    }
    return this.$http
      .patch(`${this.Configuration.apiUrl}/users/me/notifications`, payload)
      .then(response => {
        return response.data
      })

    // const payload = {
    //   ids: notificationIds
    // }
    // return this.$http
    //   .put(`${this.Configuration.apiUrl}/users/me/new-notifications-seen`, payload)
    //   .then(response => {
    //     return response.data
    //   })
  }
}
export default NotificationsService
