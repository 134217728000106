(function () {
  'use strict';

  function modalView($rootScope, $document, Modal, IntercomService) {
    var link = function ($scope) {
      console.log('ModalViewDir (legacy) > link init');
      $scope.close = function () {
        $rootScope.$broadcast('modal-close');
        Modal.close();
      };

      $scope.askForHelp = function () {
        IntercomService.showMessage();
      };

      $scope.$watch('modalShow()', function (val) {
        var body = $document.find('body');

        if (val) {
          $document.find('body').addClass('modal-open');

          if ($(document).height() > $(window).height()) {
            body.addClass('modal-open-noscroll');
          }
        } else {
          body.removeClass('modal-open');
          body.removeClass('modal-open-noscroll');
        }
      });
    };

    return {
      restrict: 'E',
      scope: {
        modalShow: '&',
        modalSource: '&',
        modalScope: '=',
        settings: '=modalSettings'
      },
      templateUrl: require('../../views/modals/base.html'),
      link: link
    };
  }

  app.directive('modalView', ['$rootScope', '$document', 'Modal', 'IntercomService', modalView]);
})();
