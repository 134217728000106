import templateUrl from './create-modal.html'
import './create-modal.scss'

const AdditionalTasksCreateModalComponent = {
  bindings: {},
  templateUrl,
  require: {
    modalCtrl: '^^cdblModal'
  },
  controller: class AdditionalTasksCreateModal {
    constructor ($timeout, ProjectService, AdditionalTasksService, ExperimentService) {
      'ngInject'
      this._identify = 'AdditionalTasksCreateModalComponent'
      this.$timeout = $timeout
      this.ProjectService = ProjectService
      this.AdditionalTasksService = AdditionalTasksService
      this.ExperimentService = ExperimentService

      this.formData = {
        taskType: 'task',
        title: null,
        description: null,
        price: null,
        hourlyRate: null,
        timeEstimate: null,
        billingFrequency: 'monthly'
      }

      this.billingPreview = null

      this.isProcessing = false


      this.temporaryDisableRetainers = this.ExperimentService.isOff('retainer-task-creation')
      this.taskType = {
        config: {
          type: 'radio-button-outline',
          horizontal: true
        },
        options: [
          {
            title: 'Additional Task',
            value: 'task',
            disabled: false
          },
          {
            title: 'Retainer Task',
            value: 'task-retainer',
            disabled: this.temporaryDisableRetainers
          }
        ]
      }
    }

    $onInit () {

    }

    onPriceChange () {
      this.AdditionalTasksService.getBillingPreview(this.ProjectService.project.id, this.formData.price)
        .then(response => {
          console.log('[AdditionalTasksCreateModalComponent] > onPriceChange', response)
          this.billingPreview = response.prices
        })
    }

    createTask () {
      console.log('[AdditionalTasksCreateModalComponent] > postTask', this.formData)

      if (!this.ProjectService.project) {
        console.error('[AdditionalTasksCreateModalComponent] > no project assigned in ProjectService', this.formData)
        return
      }

      this.isProcessing = true
      if (this.formData.taskType === 'task-retainer') {
        const payload = {
          retainer: {
            title: this.formData.title,
            description: this.formData.description,
            price: this.formData.price,
            task_id: this.ProjectService.project.id, // TODO: remove this after BE confirmation
            numberOfHours: this.formData.timeEstimate,
            baseHourlyRate: this.formData.hourlyRate,
            billingFrequency: this.formData.billingFrequency
          }
        }
        this.AdditionalTasksService.createRetainerTask(this.ProjectService.project.id, payload)
          .then(task => {
            // SegmentAnalytics.trackRetainerPosted(task, Auth.me(), parentTask, buttonClicked);
            // $rootScope.$broadcast('sub-task-created', task);
            this.ProjectService.addAdditionalTaskToProject(task, true)
            this.modalCtrl.close()
          })
          .finally(() => {
            this.isProcessing = false
          })
      } else {
        const payload = {
          title: this.formData.title,
          description: this.formData.description,
          price: this.formData.price,
          numberOfHours: this.formData.timeEstimate,
          baseHourlyRate: this.formData.hourlyRate
        }
        this.AdditionalTasksService.createTask(this.ProjectService.project.id, payload)
          .then(task => {
            // SegmentAnalytics.trackSubtaskPosted(task, Auth.me());
            // $rootScope.$broadcast('sub-task-created', task);
            this.ProjectService.addAdditionalTaskToProject(task, true)
            this.modalCtrl.close()
          })
          .finally(() => {
            this.isProcessing = false
          })
      }
    }
  }
}

export default AdditionalTasksCreateModalComponent
