import templateUrl from './text-decoration.html'
import './text-decoration.scss'

const TextDecorationComponent = {
  bindings: {
    // icon: '<?icon',
    // ellipsis: '<?ellipsis',
    enabled: '<?enabled'
  },
  transclude: true,
  templateUrl,
  controller: class TextDecorationComponent {
    constructor () {
      'ngInject'
      this._identify = 'TextDecorationComponent'
    }

    get isEnabled () {
      return Boolean(this.enabled)
    }
  }
}

export default TextDecorationComponent
