(function () {
  'use strict';

  function CompleteTaskCtrl($scope, $state, Modal, ModalService, Configuration, Auth, SegmentAnalytics) {
    $scope.task = $scope.modalScope.task;
    $scope.currentUser = $scope.modalScope.currentUser;

    $scope.completeTaskInProgress = false;
    $scope.completeTask = function () {
      $scope.completeTaskInProgress = true;
      $scope.task.one('complete').customPUT().then(function () {
        $scope.completeTaskInProgress = false;

        SegmentAnalytics.trackTaskCompleted($scope.task);
        $scope.modalScope.task.state = 'completed';

        if (Auth.me().completedTasksCount) {
          Auth.changeMe('completedTasksCount', Auth.me().completedTasksCount + 1);
        } else {
          Auth.changeMe('completedTasksCount', 1);
        }

        Modal.close();

        ModalService.open({
          closeOnBackdrop: false,
          closeIcon: false,
          layout: 'center',
          class: 'project-review-modal',
          template: `<cdbl-project-review-modal project="$ctrl.modal.project"></cdbl-project-review-modal>`,
          project: $scope.task
        });
      });
    };

    $scope.closeModal = function () {
      Modal.close();
    };
  }

  app.controller('CompleteTaskCtrl', ['$scope', '$state', 'Modal', 'ModalService', 'Configuration', 'Auth', 'SegmentAnalytics', CompleteTaskCtrl]);
})();
