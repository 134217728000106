import templateUrl from './project-browse-pods-management-modal.html'
import './project-browse-pods-management-modal.scss'

const ProjectBrowsePodsManagementModalComponent = {
  bindings: {
    data: '<'
  },
  templateUrl,
  require: {
    modalCtrl: '^^cdblModal'
  },
  controller: class ProjectBrowsePodsManagementModalComponent {
    constructor (UserService, ModalService) {
      'ngInject'
      this._identify = 'ProjectBrowsePodsManagementModalComponent'
      this.UserService = UserService
      this.ModalService = ModalService
    }
  }
}

export default ProjectBrowsePodsManagementModalComponent
