import templateUrl from './expert-profile-view-modal.html'
import './expert-profile-view-modal.scss'

const ExpertProfileViewModalComponent = {
  bindings: {
    mode: '<',
  },
  templateUrl,
  require: {
    modalCtrl: '^^cdblModal'
  },
  controller: class ExpertProfileViewModal {
    constructor (ExpertProfileViewModalService) {
      'ngInject'
      this._identify = 'ExpertProfileViewModalComponent'
      this.ExpertProfileViewModalService = ExpertProfileViewModalService
    }

    $onInit () {
      this.ExpertProfileViewModalService.setMode(this.modalCtrl.modal.mode)
    }
  }
}

export default ExpertProfileViewModalComponent
