const ClientProfileService = class ClientProfileService {
  constructor ($http, $q, $timeout, Configuration, ModalService) {
    'ngInject'

    this.$http = $http
    this.$q = $q
    this.$timeout = $timeout
    this.Configuration = Configuration
    this.ModalService = ModalService
  }

  // API METHODS
  // ------------------------------------------

  get profileUrl () {
    return `${this.Configuration.apiUrl}/experts/profile`
  }

  canShowProfile (project) {
    return project && project.client && project.client.resources && project.client.resources.profile
  }

  // Profile
  async getProfile (project) {
    console.log('getProfile')
    const requestConfig = { ...project.client.resources.profile }
    requestConfig.url = `${this.Configuration.apiUrl}${requestConfig.url}`
    return this.$http(requestConfig)
      .then(response => response.data)
  }

  // Profile reviews
  async getProfileReviews (projectClientProfile, pagination) {
    console.log('getProfileReviews')
    const params = pagination ? {
      page: pagination.currentPage,
      per_page: pagination.itemsPerPage
    } : {}

    const requestConfig = { ...projectClientProfile.resources.reviews, params }
    requestConfig.url = `${this.Configuration.apiUrl}${requestConfig.url}`
    return this.$http(requestConfig)
      .then(response => {
        if (pagination && typeof pagination.updateFromHeaders === 'function') {
          pagination.updateFromHeaders(response)
        }
        return response.data
      })
  }


  showProfileModal (project) {
    if (project) {
      this.ModalService.open({
        closeOnBackdrop: true,
        closeIcon: true,
        class: 'client-profile-view-modal cdbl-modal--style-with-sticky-header-and-footer cdbl-modal--style-without-dividers',
        template: `<cdbl-client-profile-view-modal project="$ctrl.modal.project"></cdbl-client-profile-view-modal>`,
        project: project
      })
    }
  }
}
export default ClientProfileService

