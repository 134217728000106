const SubmissionStepHeaderSubjectFilter = function () {
  'ngInject'

  // Print subject together with a/an or print no subject for other
  return function (subjectConfig) {
    const unallowedSubjects = ['other', 'consultation']
    const vowels = ['a', 'e', 'i', 'o', 'u']

    if (subjectConfig === null || typeof subjectConfig !== 'object' || typeof subjectConfig.value !== 'string' || unallowedSubjects.includes(subjectConfig.value)) {
      return ''
    }

    const firstLetter = subjectConfig.name.toLowerCase().substring(0, 1)
    if (vowels.includes(firstLetter)) {
      return `an ${subjectConfig.name}`
    }

    return `a ${subjectConfig.name}`
  }
}
export default SubmissionStepHeaderSubjectFilter
