import templateUrl from './submission-step-one-option.html'
import './submission-step-one-option.scss'

const SubmissionStepOneOptionComponent = {
  bindings: {
    submissionoption: '<',
    callbacks: '<'
  },
  require: {
    grandParent: '^cdblSubmission'
  },
  templateUrl,
  controller: class SubmissionStepOneOption {
    constructor (Modal, Configuration) {
      'ngInject'
      this.Modal = Modal
      this._identify = 'SubmissionStepOneOptionComponent'
      this.form = {
        project_subject: ''
      }

      this.consultationPrice = Configuration.features.pricing.consultationPrice
    }
    $onInit () {

    }
    select (valid) {
      if (valid) {
        const selectedOption = this.submissionoption
        let project_subject = (selectedOption.options.length === 1) ? selectedOption.project_subject : this.form.project_subject

        if (selectedOption.project_type === 'uncategorized') {
          this.Modal.open('views/modals/submission-uncategorized.html', {
            goToStepTwo: () => {
              this.Modal.close()
              this.grandParent.goToStepTwo(selectedOption.project_type, project_subject)
            }
          }, { closeButton: false })
        } else {
          this.grandParent.goToStepTwo(selectedOption.project_type, project_subject)
        }
      }
    }
  }
}

export default SubmissionStepOneOptionComponent
