import templateUrl from './attachment.html'
import './attachment.scss'

const AttachmentComponent = {
  bindings: {
    projectId: '<?',
    attachment: '<data', // attachement name is taken as there is a legacy directive with same name - TODO: after done with new attachments remove old one and rename this to attachment
    showIcon: '<?',
    canDownload: '<?',
    canDelete: '<?',
    deleteMode: '@?',
    deleteConfirmation: '@?',
    onDownload: '&?',
    onDelete: '&?',
    styleInline: '<?',
    styleSmall: '<?',
    showMeta: '<?',
    autoRenewUrl: '<?'
  },
  templateUrl,
  controller: class Attachment {
    constructor ($q, $http, $element, $timeout, Configuration, AttachmentsService, UserService, EventEmitter, ModalService, moment) {
      'ngInject'
      this._identify = 'AttachmentComponent'
      this.$q = $q
      this.$http = $http
      this.$element = $element
      this.$timeout = $timeout
      this.Configuration = Configuration
      this.AttachmentsService = AttachmentsService
      this.UserService = UserService
      this.EventEmitter = EventEmitter
      this.ModalService = ModalService
      this.moment = moment
    }

    $onInit () {
      const name = this.attachment.name || this.attachment.attachment // handle the delete attachment activity data structure - TODO: should be fixed on BE
      this.fileName = this.AttachmentsService.extractFilenameOnly(name, 2)
      this.fileExt = this.AttachmentsService.extractFileExtensionOnly(name, 2)

      this.showIcon = this.showIcon || false
      this.deleteConfirmation = typeof this.deleteConfirmation !== 'undefined' ? this.deleteConfirmation : 'default' // 'default' | 'dialog' | 'none'
      this.deleteMode = typeof this.deleteMode !== 'undefined' ? this.deleteMode : 'default'
      this.canDelete = typeof this.canDelete !== 'undefined' ? this.canDelete : false // by default only same user can delete
      this.canDownload = typeof this.canDownload !== 'undefined' ? this.canDownload : true // by default true
      this.showMeta = typeof this.showMeta !== 'undefined' ? this.showMeta : false

      // Special case for activities where attachment is listed however it can be already deleted.
      // Not showing link prevents users from triggering 404 error and redirects
      if (this.attachment.deleted) {
        this.canDownload = false
      }

      if (this.canDelete) {
        this.canDelete = (this.attachment.user.id === this.UserService.user.id) && this.attachment.provider !== 's3'
      }

      if (this.styleInline) {
        this.$element[0].classList.add('attachment--inline')
      }

      if (this.styleSmall) {
        this.$element[0].classList.add('attachment--small')
      }

      if (this.autoRenewUrl) {
        const vm = this
        this.expirationConfig = {
          expiresAt: null,
          timeoutPromise: null,
          callback: () => {
            vm.AttachmentsService.getAttachmentDownloadSignedUrl()
              .then(response => {
                vm.attachment.url = response.url

                vm.AttachmentsService.timeoutUpdateAttachmentsData(this.expirationConfig)
              })
          }
        }
        this.AttachmentsService.timeoutUpdateAttachmentsData(this.expirationConfig)
      }
      console.log('[AttachmentComponent]', this.deleteConfirmation)
    }

    get showAttachment () {
      // special case if delete mode is on submit - in that case if attachment is deleted (marked as deleted) then hide the attachment
      if (this.deleteMode === 'onsubmit' && this.attachment.deleted) {
        return false
      }

      return true
    }

    get showActions () {
      return this.canDelete || false
    }

    get signedUrlDownload () {
      if (this.attachment.url) {
        return this.attachment.url
      }
      return null
    }

    $onDestroy () {
      if (this.autoRenewUrl) {
        this.AttachmentsService.cancelTimeoutUpdateAttachmentsData(this.expirationConfig)
      }
    }

    refetchAttachment () {
      if (this.attachment && this.attachment.actions && this.attachment.actions['Read']) {
        const action = this.attachment.actions['Read']
        const request = {
          method: action.method,
          url: `${this.Configuration.apiUrl}${action.path}`,
        }
        return this.$http(request)
          .then(response => response.data)
      }

      // return blank resolved promise
      const deferred = this.$q.defer()
      deferred.resolve()
      return deferred.promise
    }

    /**
     * Download of attachment via call to BE to first fetch signurl
     * TODO: modify or remove it when preloading of sign urls works as expected - for now it can be used as a fallback if url is without url
     */
    download ($event) {
      console.log('[AttachmentComponent] > download')
      if (this.attachment.expiresAt) {
        // --- For testing purposes
        // const currentDateUTC = this.attachment.stopmock ? this.moment.utc() : this.moment(this.attachment.expiresAt).add(1, 'seconds')
        // ---
        const currentDateUTC = this.moment.utc()
        const expiresDateUTC = this.moment(this.attachment.expiresAt)
        console.log('[AttachmentComponent] > download')
        if (currentDateUTC.isAfter(expiresDateUTC)) {
          $event.preventDefault()
          $event.stopPropagation()

          console.log('[AttachmentComponent] > download > URL EXPIRED, refetch', this.$element)
          this.refetchAttachment()
            .then(response => {
              if (response) {
                console.log('[AttachmentComponent] > download > URL EXPIRED, refetch > response', response.url)
                Object.assign(this.attachment, response)

                // --- For testing purposes
                // this.attachment.expiresAt = this.moment.utc().add(24, 'hours')
                // this.attachment.stopmock = true
                // ---

                // Trigger click event after new data loaded and digest is called to update the link url
                this.$timeout(() => {
                  this.$element.find('a.button-link')[0].click()
                })
              }
            })
        }
      }

      if (this.attachment.url) {
        return // do nothing if attachment has url
      }

      // TODO: break away from hardcoded project attachment endpoints and use the one from new attachment.actions object

      // Fallback for missing url or expired url
      console.log('[AttachmentComponent] > downloadAttachment > START')
      this.AttachmentsService
        .downloadAttachment(this.projectId, this.attachment.id)
        .then(response => {
          console.log('[AttachmentComponent] > downloadAttachment > RESOLVE downloadAttachment', response)
          this.attachment.url = response.url
          if (this.onDownload) {
            this.onDownload(this.EventEmitter({ attachment: this.attachment }))
          }
        })
        .catch(err => { console.error(err) })
    }

    deleteAttachment () {
      if (this.deleteMode === 'default') {
        // TODO: break away from hardcoded project attachment endpoints and use the one from new attachment.actions object
        this.AttachmentsService
          .deleteAttachment(this.projectId, this.attachment.id)
          .then(response => {
            console.log('[AttachmentComponent] > downloadAttachment > RESOLVE deleteAttachment', response)
            if (this.onDelete) {
              this.onDelete(this.EventEmitter({ attachment: this.attachment }))
            }
          })
          .catch(err => { console.error(err) })
      } else if (this.deleteMode === 'onsubmit') {
        this.attachment.delete = true
      }
    }

    deleteCancel () {
      this.showDeleteConfirmation = false
    }
    deleteConfirm () {
      this.deleteAttachment()
      this.showDeleteConfirmation = false
    }

    deleteTrigger () {
      if (this.deleteConfirmation === 'dialog') {
        this.ModalService.open({
          closeOnBackdrop: true,
          closeIcon: true,
          layout: 'center',
          class: 'attachment-delete-modal',
          body: `Are you sure you want to delete "${this.fileName}${this.fileExt}?"`,
          actions: [
            {
              label: 'Delete',
              class: 'button-uppercase',
              callback: () => {
                this.deleteAttachment()
                this.ModalService.close()
              }
            },
            {
              label: 'Cancel',
              class: 'button-outline',
              callback: () => {
                this.ModalService.close()
              }
            }
          ]
        })
      } else if (this.deleteConfirmation === 'default') {
        this.showDeleteConfirmation = true
      } else if (this.deleteConfirmation === 'none') {
        this.attachment.deleted = true
        if (this.onDelete) {
          this.onDelete(this.EventEmitter({ attachment: this.attachment }))
        }
      }
    }
  }
}

export default AttachmentComponent
