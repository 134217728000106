(function () {
  'use strict';

  function Pagination($rootScope, $location, Restangular, _) {
    var _pagination = {};

    var resolvePaginationHeaders = function (resource, data) {
      var pagination = _extractPaginationValues(data.headers('link'));

      _pagination[resource] = {
        noOfRecords: data.headers('X-Total-Count') || 0,
        pageCount: data.headers('X-Page-Count') || 0,
        recordsPerPage: data.headers('X-Records-Per-Page') || 0,
        currentPage: pagination.currentPage,
        previousPage: pagination.previousPage,
        nextPage: pagination.nextPage
      };
    };

    var addPaginationSupport = function (resourceName, resource) {
      Restangular.addElementTransformer(resourceName, function (model) {
        model.pagination = {
          currentPage: function () { // needs to be encapsulated inside a function, because we replace pagination object on page change
            return parseInt(_pagination[resourceName].currentPage, 10);
          },
          previousPage: function () {
            return this.goToPage(_pagination[resourceName].previousPage);
          },
          previousPageExists: function () {
            return _pagination[resourceName].previousPage !== null;
          },
          nextPage: function () {
            return this.goToPage(_pagination[resourceName].nextPage);
          },
          nextPageExists: function () {
            return _pagination[resourceName].nextPage !== null;
          },
          goToPage: function (page) {
            this.currentPage = function () {
              return parseInt(page, 10);
            };

            var params = _.extend(model.reqParams ? model.reqParams : {}, {page: page});
            var request = model.customGET('', params);

            request.then(function (data) {
              resolvePaginationHeaders(resourceName, data);
              $rootScope.$broadcast('pagination-refreshed');
            });

            return request;
          },
          pageCount: function () {
            return parseInt(_pagination[resourceName] ? _pagination[resourceName].pageCount : 0, 10);
          },
          noOfRecords: function () {
            return parseInt(_pagination[resourceName] ? _pagination[resourceName].noOfRecords : 0, 10);
          },
          refresh: function (data) {
            resolvePaginationHeaders(resourceName, data);
          }
        };

        return model;
      });

      resource.then(function (data) {
        resolvePaginationHeaders(resourceName, data);
      });

      return resource;
    };

    function _extractPaginationValues(link) {
      var pagination = {};

      if (link === null) {
        pagination.nextPage = null;
        pagination.previousPage = null;

        return pagination;
      }

      var splitLinks = link.split('",');

      splitLinks.forEach(function (link) {
        var splitLink = link.split(';');
        var parsedLink = splitLink[0].replace('<', '').replace('>', '').replace(' ', '');

        if (splitLink[1].indexOf('start') !== -1) {
          pagination.currentPage = _getParameterByName(parsedLink, 'page');
        } else if (splitLink[1].indexOf('prev') !== -1) {
          pagination.previousPage = _getParameterByName(parsedLink, 'page');
        } else if (splitLink[1].indexOf('next') !== -1) {
          pagination.nextPage = _getParameterByName(parsedLink, 'page');
        }
      });

      if (!pagination.nextPage) {
        pagination.nextPage = null;
      }

      if (!pagination.previousPage) {
        pagination.previousPage = null;
      }

      return pagination;
    }

    function _getParameterByName(url, name) {
      name = name.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');

      var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      var results = regex.exec(url);

      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    return {
      addPaginationSupport: addPaginationSupport
    };
  }

  app.service('Pagination', ['$rootScope', '$location', 'Restangular', '_', Pagination]);
})();
