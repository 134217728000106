(function () {
  'use strict'

  function PriceCalculatorCtrl($scope, $http, Configuration, Modal, $timeout, SegmentAnalytics, FeeService) {
    $scope.estimate = $scope.modalScope.estimate
    $scope.task = $scope.modalScope.task

    $scope.expertFee = FeeService.getServiceFee('expert', $scope.task)
    $scope.clientFee = FeeService.getServiceFee('clientTask', $scope.task)

    $scope.estimationValidation = Configuration.features.estimation.totalEstimate

    $scope.init = function () {
      if ($scope.estimate && $scope.estimate.amount) {
        $scope.onAmountChange()
      }
    }

    $scope.onAmountChange = function () {
      if (!$scope.estimate.amount) {
        return
      }

      $http
        .get(`${Configuration.apiUrl}/tasks/${$scope.modalScope.task.id}/estimates/calculate?amount=${$scope.estimate.amount}`)
        .then(response => {
          console.log('[PriceCalculatorCtrl] > onPriceChange', response)
          $scope.prices = response.data.prices
          $scope.modalScope.refreshAmount($scope.estimate.amount)
        })
    }

    $scope.closeModal = function () {
      Modal.close()
    }
  }

  app.controller('PriceCalculatorCtrl', ['$scope', '$http', 'Configuration', 'Modal', '$timeout', 'SegmentAnalytics', 'FeeService', PriceCalculatorCtrl])
})()
