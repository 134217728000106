(function () {
  'use strict';

  function contenteditableListener($sce, $timeout) {
    return {
      restrict: 'A', // only activate on element attribute
      require: '?ngModel', // get a hold of NgModelController
      scope: {
        ceOnEnterListener: '&',
        ceIsActive: '=ceIsActive'
      },
      link: function (scope, element, attrs, ngModel) {
        console.log(scope, attrs);
        if (!ngModel) {
          return; // do nothing if no ng-model
        }

        // Specify how UI should be updated
        ngModel.$render = function () {
          element.html($sce.getTrustedHtml(ngModel.$viewValue || ''));
          read(); // initialize
        };

        element.on('focus', function () {
          $timeout(function () {
            scope.ceIsActive = true;
          }, 0);
        });

        element.on('blur', function () {
          $timeout(function () {
            scope.ceIsActive = false;
          }, 0);
        });

        // Listen for change events to enable binding
        element.on('blur keyup change', function (e) {
          // console.log(scope, attrs);
          var code = e.keyCode || e.which;
          if (code === 13) { // Enter keycode
            scope.ceOnEnterListener();
          }
          scope.$evalAsync(read);
        });

        var tagBody = '(?:[^"\'>]|"[^"]*"|\'[^\']*\')*';

        var tagOrComment = new RegExp(
            '<(?:' +
            // Comment body.
            '!--(?:(?:-*[^->])*--+|-?)' +
            // Special "raw text" elements whose content should be elided.
            '|script\\b' + tagBody + '>[\\s\\S]*?</script\\s*' +
            '|style\\b' + tagBody + '>[\\s\\S]*?</style\\s*' +
            // Regular name
            '|/?[a-z]' +
            tagBody +
            ')>',
            'gi');

        function removeTags(html) {
          var oldHtml;
          do {
            oldHtml = html;
            html = html.replace(tagOrComment, '');
          } while (html !== oldHtml);
          return html.replace(/</g, '&lt;');
        }

        // Write data to the model
        function read() {
          var html = element.html();
          // If strip-html attribute is provided then we strip out html tags
          if (attrs.stripHtml) {
            html = removeTags(html);
          }
          ngModel.$setViewValue(html);
        }
      }
    };
  }

  app.directive('contenteditableListener', ['$sce', '$timeout', contenteditableListener]);
})();
