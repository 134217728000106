const NoticebarService = class NoticebarService {
  constructor () {
    'ngInject'

    this._notice = null
    this.onUpdateCallback = angular.noop
  }

  get notice () {
    return this._notice
  }

  get isOpened () {
    return Boolean(this._notice)
  }

  next () {
    console.log('NoticebarService > next > TODO')
  }

  previous () {
    console.log('NoticebarService > previous > TODO')
  }

  open (noticeConfig) {
    console.log('NoticebarService > open', noticeConfig)
    this._notice = noticeConfig
  }

  close () {
    console.log('NoticebarService > close', this._notice)
    this._notice = null
  }
}
export default NoticebarService

// How to use it in controllers

// NoticebarService.open({
//   template: `
//     <a ng-click="$ctrl.flash.customCallback()">Flash content: {{$ctrl.notice.message}}</a> <a ng-click="$ctrl.noticeClose()">Close</a>
//   `,
//   message: `hello from flash`,
//   customCallback: () => {
//     console.log('flash customCallback');
//   }
// });
