const IntercomService = class IntercomService {
  constructor ($window, Configuration, UserService, StorageService) {
    'ngInject'
    this.$window = $window
    this.Configuration = Configuration
    this.UserService = UserService
    this.StorageService = StorageService

    this.$window.Intercom('onShow', function () {
      console.log('[IntercomService] > onShowIntercom > Intercom onShow event handler')
    })
  }

  init () {
    if (this.UserService.user && this.$window.Intercom) {
      const payload = {
        app_id: this.Configuration.intercom.appId,
        email: this.UserService.user.email,
        user_id: this.UserService.user.id,
        created_at: this.UserService.user.createdAt,
        name: this.UserService.user.name,
        user_hash: this.UserService.user.intercomUserHash,
      }
      this.$window.Intercom('boot', payload)
      console.log('[IntercomService] > init successful', payload)
    }
  }

  shutdown () {
    if (this.$window.Intercom) {
      this.$window.Intercom('shutdown')
      console.log('[IntercomService] > shutdown successful')
      this.$window.Intercom('boot', {
        app_id: this.Configuration.intercom.appId,
      })
    }
  }

  onShowIntercom () {
    console.log('IntercomService > onShowIntercom > Intercom onShow event handler')
  }

  showMessage (message) {
    this.$window.Intercom('showNewMessage', message)
  }
}
export default IntercomService
