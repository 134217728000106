(function () {
  'use strict';

  function popover($document, $compile, $timeout, $parse) {
    var link = function ($scope, element, attrs) {
      var cssClasses = 'popover-content ' + attrs.popoverClass;
      var template = `<div class="popover ${attrs.popoverFixed === '' ? 'popover-fixed' : ''}" role="tooltip">` +
        '<div class="arrow"></div>' +
        '<h3 class="popover-title"></h3>' +
        '<div class="popover-content ' + cssClasses + '"></div>' +
        '</div>';

      var opts = {
        template: template,
        container: attrs.popoverContainer ? attrs.popoverContainer : 'body'
      };

      if (!('outsideClickDisabled' in attrs)) {
        $('body, .modal-content').on('click', function (e) {
          if (!element.is(e.target) && element.has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
            hide();
          }
        });
      }

      if ('partial' in attrs) {
        $scope.partial = attrs.partial;

        var contentPopover = angular.element('<div><div ng-include="partial"></div></div>');
        $compile(contentPopover)($scope);

        opts.content = contentPopover[0];
        opts.html = true;
      }

      // because of how popover is implemented with scope: true.. I see no other way to implement callbacks without breaking this directive in other places where it is used - better implementation should be with isolated scope and direct binding
      let onOpenCallback = function () {};
      if ('popoverOnopen' in attrs) {
        onOpenCallback = function () {
          const callback = $parse(attrs.popoverOnopen);
          $timeout(function () {
            console.log('popover > onOpenCallback > ', attrs.popoverOnopen);
            callback($scope);
          });
        };
      }
      let onCloseCallback = function () {};
      if ('popoverOnclose' in attrs) {
        onCloseCallback = function () {
          const callback = $parse(attrs.popoverOnclose);
          $timeout(function () {
            console.log('popover > onCloseCallback > ', attrs.popoverOnclose);
            callback($scope);
          });
        };
      }

      if ('placement' in attrs) {
        opts.placement = attrs.placement;
      }

      function hide() {
        element.popover('hide');

        if (element.data('bs.popover')) {
          element.data('bs.popover').inState.click = false;// fix for bootstrap bug on event hide
        }
      }

      $scope.hide = function () {
        hide();
      };

      $scope.$on('modal-close', function () {
        hide();
      });

      $scope.$on('$stateChangeStart', function () {
        hide();
      });

      element.on('hidden.bs.popover', function () {
        onCloseCallback();
        element.addClass('popover-hidden');
        element.removeClass('popover-shown');
      });
      element.on('shown.bs.popover', function () {
        onOpenCallback();
        element.addClass('popover-shown');
        element.removeClass('popover-hidden');
      });
      element.addClass('popover-hidden');

      element.popover(opts);
    };

    return {
      restrict: 'A',
      scope: true,
      link: link
    };
  }

  app.directive('popover', ['$document', '$compile', '$timeout', '$parse', popover]);
})();
