(function () {
  'use strict';

  function OnlineUsers($rootScope, $q, Sockets, $timeout) {
    var users = [];
    var presenceChannel;

    var init = function () {
      presenceChannel = Sockets.getChannel('presence-online');

      presenceChannel.bind('pusher:subscription_succeeded', function (members) {
        members.each(function (member) {
          users.push(parseInt(member.id, 10));
        });
      });

      presenceChannel.bind('pusher:member_added', function (member) {
        users.push(parseInt(member.id, 10));
      });

      presenceChannel.bind('pusher:member_removed', function (member) {
        users.splice(users.indexOf(parseInt(member.id, 10)), 1);
      });
    };

    var checkUserId = function (userId) {
      var deferred = $q.defer();

      var checkIfOnline = function () {
        var isOnline = false;

        if (users.indexOf(userId) !== -1) {
          isOnline = true;
        }

        deferred.notify(isOnline);
      };

      $timeout(() => {
        checkIfOnline();
      });

      // Prevent binding to channels before channel is initialized
      if (presenceChannel) {
        // If a user refresh the page
        presenceChannel.bind('pusher:subscription_succeeded', function () {
          $timeout(() => {
            checkIfOnline();
          });
        });

        presenceChannel.bind('pusher:member_added', function () {
          $timeout(() => {
            checkIfOnline();
          });
        });

        presenceChannel.bind('pusher:member_removed', function () {
          $timeout(() => {
            checkIfOnline();
          });
        });
      }

      return deferred.promise;
    };

    return {
      init: init,
      checkUserId: checkUserId
    };
  }

  app.service('OnlineUsers', ['$rootScope', '$q', 'Sockets', '$timeout', OnlineUsers]);
})();
