
const fileSelectDirective = function ($compile) {
  'ngInject'
  return {
    restrict: 'A',
    link: function (scope, element, attrs) {
      var onFileSelectorHandler = scope.$eval(attrs.fileSelect)
      element.on('change', onFileSelectorHandler)
      element.on('$destroy', function () {
        element.off()
      })
    }
  }
}

export default fileSelectDirective
