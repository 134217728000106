const ModalTriggerDirective = function ($window, ModalService) {
  'ngInject'
  return {
    restrict: 'A',
    scope: {
      cdblModalTrigger: '='
    },
    link: (scope, element, attrs) => {
      console.log('ModalTriggerDirective', element, attrs, ModalService)

      // check that object passed to directive has at least template
      if (scope.cdblModal.template) {
        const triggerEventType = 'click'
        const triggerHandler = () => {
          // ModalService.open()
        }

        $window.addEventListener(triggerEventType, triggerHandler)
        scope.$on('destroy', () => {
          $window.removeEventListener(triggerEventType, triggerHandler)
        })
      }
    }
  }
}

export default ModalTriggerDirective
