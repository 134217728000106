(function () {
  'use strict';

  function ConvertTaskTypeCtrl($scope, $state, $http, Modal, SegmentAnalytics, Configuration) {
    $scope.finished = false;
    $scope.conversionFinished = false;
    $scope.consultationPrice = Configuration.features.pricing.consultationPrice

    $scope.convertTaskType = function (task) {
      const payload = {
        type: 'consultation'
      }
      $scope.processing = true;
      var previousType = task.kind;
      $http
        .put(`${Configuration.apiUrl}/tasks/${task.id}/type_conversion`, payload)
        .then(response => {
          $scope.finished = true;
          $scope.conversionFinished = true;
          const data = response.data
          console.log(response)
          SegmentAnalytics.trackTaskConverted(data, previousType);
          Object.assign(task, {
            kind: data.kind,
            projectType: data.projectType,
            projectSubject: data.projectSubject,
            convertibleToConsultation: data.convertibleToConsultation
          })
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          $scope.processing = false;
        })
    };

    $scope.closeModal = function () {
      // $state.go($state.current, {}, {reload: true});
      Modal.close();
    };
  }

  app.controller('ConvertTaskTypeCtrl', ['$scope', '$state', '$http', 'Modal', 'SegmentAnalytics', 'Configuration', ConvertTaskTypeCtrl]);
})();
