(function () {
  'use strict';

  function EstimatesCtrl ($rootScope, $scope, Storage, Auth, Modal, $timeout, ProjectRemindersService, Configuration, $http, _, SegmentAnalytics, UserService, FeeService, ModalService, ProjectService) {
    const ESTIMATION_REMINDER = 'estimationCheck'

    $scope.consultationPrice = Configuration.features.pricing.consultationPrice;

    $scope.estimators = $scope.data.estimators;
    $scope.estimate = {
      amount: null,
      baseHourlyRate: null,
      numberOfHours: null,
      form: null
    };
    $scope.me = Auth.me();

    $scope.currentUser = UserService.user;

    $scope.ratesRangeConfig = Object.assign({}, Configuration.features.pricing.ratesRange);
    $scope.estimationConfig = Object.assign({}, Configuration.features.estimation);

    $scope.expertFee = FeeService.getServiceFee('expert', $scope.task);
    $scope.clientFee = FeeService.getServiceFee('clientTask', $scope.task);


    // TODO: examine code and refactor as invitations are not used anymore although part of their mechanics is used for estimations and hires
    if (Auth.me().role === 'contractor') {
      $scope.invitation = {};
      if ($scope.task.getList) {
        $scope.task.getList('invitations').then(function (invitations) {
          if (invitations.length > 0) {
            $scope.invitation = invitations[0];
            $scope.task.invited = true;
          }
        });
      }

      $scope.$on('invitation-created', function (event, invitation) {
        if (UserService.user.id === invitation.userId) {
          $scope.invitation = invitation;
          $scope.task.invited = true;
        }
      });
    }

    $scope.$watch('estimators', function (estimators) {
      var contractorsEstimated = estimators.length !== 0;
      var userEstimated = _.filter(estimators, {id: Auth.me().id}).length !== 0;

      $scope.displayApplyWitouthEstimate = contractorsEstimated && !userEstimated;
    }, true);

    $scope.refreshAmount = function (amount) {
      $scope.estimate.amount = amount;
    };

    // TODO: refactor reminder modals to new modal component and service
    $scope.openEstimationReminderModal = function () {
      $timeout(() => {
        ModalService.open({
          closeOnBackdrop: false,
          closeIcon: true,
          class: 'estimation-task-reminder-modal cdbl-modal--style-with-dividers cdbl-modal--style-with-sticky-header-and-footer',
          template: `
            <cdbl-estimation-task-reminder-modal
              project="$ctrl.modal.project"
              current-user="$ctrl.modal.currentUser"
              estimate="$ctrl.modal.estimate"
              estimation-config="$ctrl.modal.estimationConfig"
              modal-estimate="$ctrl.modal.modalEstimate"
              on-create="$ctrl.modal.onEstimationCreateHandler($event)">
            </cdbl-estimation-task-reminder-modal>`,
          project: $scope.task,
          currentUser: $scope.currentUser,
          estimate: $scope.estimate,
          estimationConfig: $scope.estimationConfig,
          modalEstimate: $scope.modalEstimate,
          onEstimationCreateHandler: handleOnCreate.bind(this)
        })
      }, 0);
    };

    // $scope.estimateSubmitConfirm = () => {
    //   console.log('EstimatesCtrl > estimateSubmitConfirm > invalid', $scope.estimate.form.$invalid);
    //   if ($scope.estimate.form.$invalid) {
    //     return;
    //   }

    //   if (ProjectRemindersService.canShowReminder(ESTIMATION_REMINDER)) {
    //     $scope.openEstimationReminderModal();
    //   } else {
    //     $scope.create();
    //   }
    // };

    $scope.applyWithEstimate = () => {
      const config = ModalService.generateConfig('confirmation', {
        body: 'Are you sure you want to send this estimate?',
        rejectButton: {
          label: 'Cancel'
        },
        resolveButton: {
          label: 'Yes, Send'
        },
      })

      if (ProjectRemindersService.canShowReminder(ESTIMATION_REMINDER)) {
        $scope.openEstimationReminderModal();
      } else {
        ModalService.open(config)
          .then(response => {
            $scope.create();
          })
      }
    }

    $scope.applyWithoutEstimate = () =>{
      const config = ModalService.generateConfig('confirmation', {
        body: 'Are you sure you want to send this estimate?',
        rejectButton: {
          label: 'Cancel'
        },
        resolveButton: {
          label: 'Yes, Send'
        },
      })

      ModalService.open(config)
        .then(response => {
          $scope.create(true)
        })
    }

    $scope.applyForConsultation = () =>{
      const config = ModalService.generateConfig('confirmation', {
        body: 'Are you sure you want to apply for the consultation?',
        rejectButton: {
          label: 'Cancel'
        },
        resolveButton: {
          label: 'Yes, Apply'
        },
      })

      ModalService.open(config)
        .then(response => {
          $scope.create()
        })
    }



    $scope.create = (applyWithoutEstimate = false) => {
      console.log('EstimatesCtrl > estimate > estimate', $scope.estimate);

      let postData = {}

      if (!applyWithoutEstimate) {
        postData = {
          amount: $scope.estimate.amount,
          baseHourlyRate: $scope.estimate.baseHourlyRate,
          numberOfHours: $scope.estimate.numberOfHours
        }
      }

      var startSpinner = function (text) {
        $scope.spinnerText = text;
        $scope.processing = true;
      };

      var stopSpinner = function () {
        $scope.spinnerText = '';
        $scope.processing = false;
        // If modal was shown - set reminder for this project
        if (ModalService.isOpened) {
          ProjectRemindersService.setReminderState(ESTIMATION_REMINDER);
          ModalService.close()
        }
        resetForm()
      };
      startSpinner('Saving...');
      $scope.task.all('estimates').post(postData).then(function (response) {
        $scope.task.prices = response.prices;
        $scope.task.currentUserShouldGiveNoEstimateFeedback = false;

        // TODO: handle state change via ProjectService - this only changes state for current expert who did the estimate
        $scope.task.state = 'estimated'; // transition task to being estimated

        // TODO: handle detection on new estimates differently - this only changes state for current expert who did the estimate
        // Emit internal event so that other parts of app have information that current user posted an estimate
        $rootScope.$emit('current-user::estimate-submitted', {
          task: $scope.task,
          estimate: response
        });

        UserService.userProjectSubscribe($scope.task);
        SegmentAnalytics.trackTaskEstimated($scope.task, Auth.me().id, $scope.estimate.amount);
        if (_.where($scope.estimators, {id: Auth.me().id}).length === 0) { // prevent duplication
          $scope.estimators.push(Auth.me());
        }
      }).finally(stopSpinner);
    };

    // IF expert is on probation
    if ($scope.me.modalTooltipsEnabled || $scope.task.estimatesCount >= 4) {
      // Temporary estimation object (Estimation modal)
      $scope.modalEstimate = {};
      $scope.$watch('estimate.amount', _.debounce(function (amount) {
        if (amount) {
          $http.get(`${Configuration.apiUrl}/tasks/${$scope.task.id}/estimates/calculate?amount=${amount}`).then(result => {
            if (result.data) {
              $scope.modalEstimate.prices = result.data.prices;
            }
          });
        } else {
          $scope.prices = {};
        }
      }, 500));
    }

    $scope.openModalCalculator = function () {
      SegmentAnalytics.trackPriceCalculatorOpened($scope.task);
      Modal.open('views/modals/price_calculator.html', $scope);
    };

    $scope.showPreferredContractors = function () {
      return angular.isArray($scope.task.preferredContractors) && ['published', 'estimated'].indexOf($scope.task.state) > -1;
    };

    $scope.$on('estimate-updated', function (event, data) {
      $scope.task.prices = data.prices;
    });

    $scope.$on('estimators-updated', function (event, data) {
      $scope.estimators = data.estimators;
    });

    $scope.$on('partner-mentioned', function (event, data) {
      $scope.task.mentionedPartners = data.mentionedPartners.filter(partner =>
        partner.trackingName !== $scope.task.partnerName
      )
    });

    $scope.$on('project-quality-review-updated', function (event, data) {
      ProjectService.getProject($scope.task.id)
        .then(response => {
          $scope.task.qualityReview = response.qualityReview;
        })
        .catch(err => {
          console.log(err)
        })
    })

    function handleOnCreate (amount) {
      $scope.estimate.amount = amount
      this.create()
    }

    function resetForm () {
      // Reset fields and form validation
      $scope.estimate.amount = null
      $scope.estimate.baseHourlyRate = null
      $scope.estimate.numberOfHours = null
      $scope.estimate.form.$setPristine()
      $scope.estimate.form.$setUntouched()
    }
  }

  app.controller('EstimatesCtrl', ['$rootScope', '$scope', 'Storage', 'Auth', 'Modal', '$timeout', 'ProjectRemindersService', 'Configuration', '$http', '_', 'SegmentAnalytics', 'UserService', 'FeeService', 'ModalService', 'ProjectService', EstimatesCtrl]);
})();
