(function () {
  'use strict';

  function popup($compile, Tether, $timeout) {
    var link = function ($scope, elem, attrs) {
      $scope.active = false;

      var popup = '';
      var cssClass = attrs.cssClass || '';

      if ($scope.partial) {
        popup = angular.element('<div class="popup {cssClass}"><div ng-include="partial"></div></div>'.supplant({cssClass: cssClass}));
      } else {
        popup = angular.element('<div class="popup {cssClass}" ng-bind-html="text | trustAsHtml"></div>'.supplant({cssClass: cssClass}));
      }

      $compile(popup)($scope);

      var tether = new Tether({
        element: popup,
        target: elem,
        attachment: attrs.elementPosition || 'middle left',
        targetAttachment: attrs.targetPosition || 'middle right',
        targetOffset: attrs.targetOffset || '0 .5rem'
      });

      $scope.$watch('active', function (isActive) {
        if (isActive) {
          popup.css({display: 'block'});
          tether.position();
          popup.velocity({opacity: 1}, {duration: 100});
        } else {
          popup.velocity({opacity: 0}, {duration: 100});
          popup.css({display: 'none'});
        }
      });

      $scope.$on('$stateChangeStart', function () {
        tether.destroy();
        popup.remove();
      });

      var waitTimeout;

      elem.on('mouseenter', function () {
        $timeout(() => {
          $scope.active = true;
        });
      });

      elem.on('mouseleave', function () {
        if (attrs.persistent === 'true') {
          waitTimeout = $timeout(() => {
            $scope.active = false;
          }, 1000);
        } else {
          $timeout(() => {
            $scope.active = false;
          });
        }
      });

      // Next two listeners enable to go inside popup div and not to close it (useful for links inside)
      if (attrs.persistent === 'true') {
        popup.on('mouseenter', function () {
          if (angular.isDefined(waitTimeout)) {
            // clearTimeout(waitTimeout);
            $timeout.cancel(waitTimeout);
          }
        });

        popup.on('mouseleave', function () {
          $timeout(() => {
            $scope.active = false;
          });
        });
      }
    };

    return {
      restrict: 'A',
      replace: false,
      scope: {
        text: '@',
        partial: '@'
      },
      link: link
    };
  }

  app.directive('popup', ['$compile', 'Tether', '$timeout', popup]);
})();
