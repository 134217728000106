import templateUrl from './project-add-new-project.html'
import './project-add-new-project.scss'

const ProjectAddNewProjectComponent = {
  bindings: {},
  templateUrl,
  controller: class ProjectAddNewProjectComponent {
    constructor () {
      'ngInject'
      this._identify = 'ProjectAddNewProjectComponent'

      this.formData = {
        title: ''
      }
    }

    $onInit () {

    }
  }
}

export default ProjectAddNewProjectComponent
