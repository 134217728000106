import NotificationsModule from './notifications'
import SubmissionModule from './submission'
import ModalsModule from './modals'
import MenusModule from './menus'
import WorkroomModule from './workroom'
import CommentsModule from './comments'
import PaymentsModule from './payments'
import ProjectDashboardModule from './project-dashboard'
import ProjectBrowseModule from './project-browse'
import ExpertProfileModule from './expert-profile'
import SystemReloadModule from './system-reload'
import SettingsModule from './settings'
import TransactionsModule from './transactions'
import HeaderModule from './header'
import ProjectPresetsModule from './project-presets'

const ComponentsModule = angular.module('app.components', [
  NotificationsModule,
  SubmissionModule,
  ModalsModule,
  MenusModule,
  WorkroomModule,
  CommentsModule,
  PaymentsModule,
  ProjectDashboardModule,
  ProjectBrowseModule,
  ExpertProfileModule,
  SystemReloadModule,
  SettingsModule,
  TransactionsModule,
  HeaderModule,
  ProjectPresetsModule
])
  .name

export default ComponentsModule
