import ModalComponent from './modal.component'
import ModalService from './modal.service'
import ModalTriggerDirective from './modal-trigger.directive'

const ModalModule = angular.module('cdbl.modal', [

])
  .service('ModalService', ModalService)
  .directive('cdblModalTrigger', ModalTriggerDirective)
  .component('cdblModal', ModalComponent)
  .name

export default ModalModule
