(function () {
  'use strict';

  function numbersOnly() {
    var link = function ($scope, element, attr, ngModelCtrl) {
      function fromUser(text) {
        if (text) {
          var transformedInput = text.replace(/[^0-9]/g, '');

          if (transformedInput !== text) {
            ngModelCtrl.$setViewValue(transformedInput);
            ngModelCtrl.$render();
          }

          if ('min' in attr) {
            _checkMin(ngModelCtrl.$viewValue);
          }

          if ('max' in attr) {
            _checkMax(ngModelCtrl.$viewValue);
          }

          return transformedInput;
        }
        return undefined;
      }
      ngModelCtrl.$parsers.push(fromUser);

      function _checkMin(value) {
        var min = parseInt(attr.min);
        if (min > parseInt(value)) {
          ngModelCtrl.$setValidity('min', false);
        } else {
          ngModelCtrl.$setValidity('min', true);
        }
      }

      function _checkMax(value) {
        var max = parseInt(attr.max);
        if (max < parseInt(value)) {
          ngModelCtrl.$setValidity('max', false);
        } else {
          ngModelCtrl.$setValidity('max', true);
        }
      }
    };

    return {
      restrict: 'A',
      require: 'ngModel',
      link: link
    };
  }

  app.directive('numbersOnly', numbersOnly);
})();
