(function () {
  'use strict';

  function parseInteger() {
    var link = function ($scope, element, attrs, ngModelController) {
      ngModelController.$formatters.push(function (value) {
        return String(value); // DOM
      });

      ngModelController.$parsers.push(function (value) {
        if (!isNaN(value)) {
          return parseInt(value, 10);  // model
        }
      });
    };

    return {
      restrict: 'A',
      require: 'ngModel',
      link: link
    };
  }

  app.directive('parseInt', parseInteger);
})();
