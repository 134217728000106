const SitegroundrcSubmissionConfig = {
  displayName: 'SiteGround',
  content: {
    stepone: {
      title: `How can Codeable help you with your WordPress website?`,
      introduction: `<h3>Take a look at our suggestions below based on the most common requests submitted by SiteGround customers.</h3>
            Not sure what you need? There's a category for that too!`
    }
  },
  types: [
    {
      type: 'Troubleshoot and Fix',
      title: 'Troubleshoot and Fix',
      // description: 'Subscriptions',
      project_type: 'troubleshoot',
      project_subject: '',
      kind: 'uncategorized',
      position: 'main_options',
      icon: 'icon-sitegroundrc-troubleshoot',
      options: [
        {
          value: 'woo_plugin',
          name: 'WooCommerce',
          description: 'WooCommerce'
        },
        {
          value: 'plugin_errors',
          name: 'Plugin errors',
          description: 'Plugin errors'
        },
        {
          value: 'plugin_conflicts',
          name: 'Conflicts among plugins',
          description: 'Conflicts among plugins'
        },
        {
          value: 'missing_elements',
          name: 'Missing elements & functionality',
          description: 'Missing elements & functionality'
        },
        {
          value: 'php_errors',
          name: 'PHP or other errors',
          description: 'PHP or other errors'
        },
        {
          value: 'wp_errors',
          name: 'WP not working correctly',
          description: 'WP not working correctly'
        },
        {
          value: 'blank_page',
          name: 'Blank page',
          description: 'Blank page'
        }
      ]
    },
    {
      type: 'Installations',
      title: 'Installations',
      project_type: 'installations',
      project_subject: '',
      kind: 'install',
      position: 'main_options',
      icon: 'icon-sitegroundrc-installations',
      options: [
        {
          value: 'custom_install',
          name: 'Installing custom plugins & themes',
          description: 'Installing custom plugins & themes'
        }
      ]
    },
    {
      type: 'Site optimizations',
      title: 'Site optimizations',
      project_type: 'site_optimizations',
      project_subject: '',
      kind: 'uncategorized',
      icon: 'icon-sitegroundrc-site_optimizations',
      position: 'main_options',
      options: [
        {
          value: 'website_optimization',
          name: 'Website speed improvement',
          description: 'Website speed improvement'
        },
        {
          value: 'plugin_review',
          name: 'Plugin code review',
          description: 'Plugin code review'
        },
        {
          value: 'db_optimization',
          name: 'Database and slow database queries optimization',
          description: 'Database and slow database queries optimization'
        }
      ]
    },
    {
      type: 'Custom development work',
      title: 'Custom development work',
      project_type: 'custom_dev',
      project_subject: '',
      kind: 'project',
      icon: 'icon-sitegroundrc-custom_dev',
      position: 'main_options',
      options: [
        {
          value: 'customize_theme',
          name: 'Theme customization',
          description: 'Theme customization'
        },
        {
          value: 'customize_plugin',
          name: 'Plugin customization',
          description: 'Plugin customization'
        }
      ]
    },
    {
      type: 'Build & Design',
      title: 'Build & Design',
      project_type: 'build_design',
      project_subject: '',
      kind: 'project',
      icon: 'icon-sitegroundrc-build_design',
      position: 'main_options',
      options: [
        {
          value: 'build_website',
          name: 'Build website from scratch',
          description: 'Build website from scratch'
        },
        {
          value: 'design_theme',
          name: 'Theme Design',
          description: 'Theme Design'
        }
      ]
    },
    {
      type: 'Consultation',
      title: `Consult an Expert`,
      description: `For only <strong>{{ ::($ctrl.consultationPrice.value | currencyFormat) }}</strong>, speak to one of our experts about your project for 1 hour. Get advice on what should be done, or how complex different approaches are, or how to define a project before getting started.
          <a class="more" href="https://help.codeable.io/posting-your-project/what-is-a-consultation-task" target="_blank" cdbl-track="Submission Flow Link Clicked" cdbl-track-payload="{label:'Learn more'}">Learn more.</a>`,
      project_type: 'consultation',
      project_subject: 'consultation',
      kind: 'consultation',
      icon: 'icon-sitegroundrc-consultation',
      position: 'main_options',
      hideOptions: true,
      options: [
        {
          value: 'consultation',
          name: 'Consultation',
          description: false,
        }
      ]
    }
  ]
}

export default SitegroundrcSubmissionConfig
