import templateUrl from './sidebar.html'
import './sidebar.scss'

const COMPONENT_NAME_TYPE = 'sidebar'
const APP_LEFT_SIDEBAR_LOCKED_BREAKPOINT = 'gt-sm'
const SIDEBAR_CLOSED_CLASSNAME = 'cdbl-sidebar--closed'
const SIDEBAR_LOCKED_OPEN_CLASSNAME = 'cdbl-sidebar--locked-open'
const SIDEBAR_BACKDROP_VISIBLE_CLASSNAME = 'backdrop--visible'

const SidebarComponent = {
  bindings: {
    componentId: '@',
    onChange: '&',
    lockedOpen: '<',
    mobileClose: '<',
  },
  require: {
    layoutCtrl: '^cdblLayout'
  },
  transclude: true,
  templateUrl,
  controller: class SidebarComponent {
    constructor ($scope, $mdMedia, $element, $compile, $animate, $window, UIService) {
      'ngInject'
      this._identify = 'SidebarComponent'
      this.type = COMPONENT_NAME_TYPE
      this.$scope = $scope
      this.$compile = $compile
      this.$mdMedia = $mdMedia
      this.$element = $element
      this.$animate = $animate
      this.$window = $window
      this.UIService = UIService

      this.backdropClose = true

      this.state = {
        open: false,
        locked: false
      }

      this.previousState = {
        open: false,
        locked: false
      }

      this.initialized = false // used to prevent animations on first run

      const self = this
      this.closeEventHandler = function (event) {
        if (event.key === 'Escape') {
          self.close(event)
          self.$scope.$apply()
        }
      }
    }

    $onInit () {
      console.log('[SidebarComponent] > $onInit', this)
      // Initial setup
      this.ngWindow = angular.element(this.$window)
      this.state.locked = this.isLockedOpen
      this.previousState.locked = this.state.locked // sync previous state to current one to avoid animation of first render
      this.$element.toggleClass(SIDEBAR_LOCKED_OPEN_CLASSNAME, this.state.locked)
      this.$element.toggleClass(SIDEBAR_CLOSED_CLASSNAME, !this.state.open)

      // Expose sidebar api to internal app events
      if (this.componentId) {
        this.UIService.registerComponent(`${COMPONENT_NAME_TYPE}-${this.componentId}`, this)
        this.$scope.$on(`sidebar-toggle--${this.componentId}`, ($event, data) => {
          this.toggle()
        })

        this.$scope.$on(`sidebar-open--${this.componentId}`, ($event, data) => {
          this.open()
        })

        this.$scope.$on(`sidebar-close--${this.componentId}`, ($event, data) => {
          this.close()
        })
      }
    }

    $onDestroy () {
      this.removeBackdrop()
      if (this.componentId) {
        this.UIService.unregisterComponent(`${COMPONENT_NAME_TYPE}-${this.componentId}`)
      }
    }

    $doCheck () {
      // Be carefull to use light checks here as they happen on each digest
      this.updateIsLocked()
      this.updateIsOpen()
    }

    // TODO: expose to be configurable from instance to instance
    get isLockedOpen () {
      if (this.componentId === 'app-sidebar-left' || this.lockedOpen === 'app-left-sidebar-breakpoint') {
        return this.$mdMedia(APP_LEFT_SIDEBAR_LOCKED_BREAKPOINT)
      } else if (typeof this.lockedOpen !== 'undefined') {
        return Boolean(this.lockedOpen) // accept locked open flag from outside, TODO: check its reactive if changed after init
      }

      return false
      // return this.$mdMedia(MAIN_LEFT_SIDEBAR_SHOW_BREAKPOINT)
    }

    get isOpen () {
      return this.state.open
    }

    updateIsLocked () {
      // if old value is same to new value
      this.state.locked = this.isLockedOpen

      if (this.state.locked !== this.previousState.locked) {
        console.log('[SidebarComponent] > updateIsLocked', this.state.locked, this.previousState.locked)
        this.$animate[this.state.locked ? 'addClass' : 'removeClass'](this.$element, SIDEBAR_LOCKED_OPEN_CLASSNAME)
        this.previousState.locked = this.state.locked

        // Remove backdrop if sidbar open on mobile but then was resized and locked open
        if (this.state.locked && this.state.open) {
          this.removeBackdrop()
        }
        // Close sidebar when resized and locked closed
        if (!this.state.locked) {
          this.close()
        }
      } else {
        this.$element.toggleClass(SIDEBAR_LOCKED_OPEN_CLASSNAME, this.state.locked)
      }
    }

    updateIsOpen () {
      if (this.state.open !== this.previousState.open) {
        console.log('[SidebarComponent] > updateIsOpen', this.state.open, this.previousState.open)

        if (this.state.open && !this.state.locked) {
          this.insertBackdrop()
        }

        if (this.backdropElement) {
          this
            .$animate[this.state.open ? 'addClass' : 'removeClass'](this.backdropElement, SIDEBAR_BACKDROP_VISIBLE_CLASSNAME)
            .then(() => {
              if (!this.state.open) {
                this.removeBackdrop()
              }
            })
        }

        this
          .$animate[this.state.open ? 'removeClass' : 'addClass'](this.$element, SIDEBAR_CLOSED_CLASSNAME)
          .then(() => {
            if (this.state.open) {
              this.ngWindow.triggerHandler('resize')
            }
          })
        this.previousState.open = this.state.open
      }
    }

    insertBackdrop () {
      const html = `<div class="cdbl-sidebar-backdrop sidebar--{{ $ctrl.componentId }}" ng-class="{'backdrop--closable': $ctrl.backdropClose}" ng-click="$ctrl.close($event)"></div>`
      this.backdropElement = this.$compile(angular.element(html))(this.$scope)
      this.$element.parent().append(this.backdropElement)
      this.ngWindow.on('keyup', this.closeEventHandler)
    }

    removeBackdrop () {
      if (this.backdropElement) {
        this.backdropElement.remove()
        this.ngWindow.off('keyup', this.closeEventHandler)
      }
    }

    toggle ($event) {
      this.preventEventDefault($event)
      this.state.open = !this.state.open
    }

    open ($event) {
      this.preventEventDefault($event)
      this.state.open = true
    }

    close ($event) {
      this.preventEventDefault($event)
      this.state.open = false
    }

    preventEventDefault ($event) {
      if ($event) {
        $event.preventDefault()
        $event.stopPropagation()
      }
    }
  }
}

export default SidebarComponent
