import templateUrl from './project-convert-from-consultation-button.html'
import './project-convert-from-consultation-button.scss'

const ProjectConvertFromConsultationButtonComponent = {
  bindings: {
    project: '<'
  },
  templateUrl,
  controller: class ProjectConvertFromConsultationButton {
    constructor (ModalService) {
      'ngInject'
      this._identify = 'ProjectConvertFromConsultationButtonComponent'
      this.ModalService = ModalService
    }

    $onInit () {

    }

    isAllowedTypeChange () {
      const allowed = (this.project.estimatesCount === 0 && this.project.kind === 'consultation')

      return allowed
    }

    openConvertFromConsultationModal ($event) {
      $event.preventDefault()
      $event.stopPropagation()

      this.ModalService.open({
        closeOnBackdrop: false,
        closeIcon: false,
        layout: 'center',
        class: 'project-cancel-modal',
        template: `<cdbl-project-convert-type-modal project="$ctrl.modal.project"></cdbl-project-convert-type-modal>`,
        project: this.project
      })
    }
  }
}

export default ProjectConvertFromConsultationButtonComponent
