(function () {
  'use strict';
  // Get ride of this when state resolver gets refactored.

  function UserResource($q, Restangular, Pagination, ResourceManipulation, _) {
    var local = {}; // Local namespace

    var getUser = function (options) {
      var user = Restangular.one('users').one('me');
      var returnObject = {};

      options.forEach(function (option) {
        var resource = local['get' + option.capitaliseFirstLetter()].call(this, user); // call method with same name as option parameter
        returnObject[option] = ResourceManipulation.resolveError(resource);
      });

      return $q.all(returnObject);
    };

    var getTasksAll = function (filter) {
      var params = {};

      if (filter) {
        params = {page: 1, filter: filter};
      } else {
        params = {page: 1};
      }

      var resource = Restangular.one('users').one('me').one('tasks').one('in-progress-or-pending').get(params);

      resource = Pagination.addPaginationSupport('all', resource);
      resource = ResourceManipulation.resolveError(resource);

      return resource;
    };

    var getTasksInquiring = function (filter) {
      var params = {};

      if (filter) {
        params = {page: 1, filter: filter};
      } else {
        params = {page: 1};
      }

      var resource = Restangular.one('users').one('me').one('tasks').one('inquiring').get(params);

      resource = Pagination.addPaginationSupport('inquiring', resource);
      resource = ResourceManipulation.resolveError(resource);

      return resource;
    };


    local.getSettings = function (user) {
      return user.get();
    };

    local.getNotificationSettings = function (user) {
      return user.one('notification_settings').get();
    };

    local.getPreferencesSettings = function (user) {
      return user.one('preferences').get();
    };

    local.getSuggestions = function (user) {
      return user.one('tasks').one('suggestions').get();
    };

    var items = [
      {item: 'transactions', filter: null, name: 'getTransactions'},
      {item: 'tasks', filter: 'inquiring', name: 'getTasksInquiring'}, // used in legacy SignInCtrl for somekind of redirection TODO: check
      {item: 'tasks', filter: 'in_progress', name: 'getTasksInProgress'}, // used in legacy login for somekind of redirection TODO: check
    ];

    var getItem = function (item, filter, user) {
      var resource = user.one(item);

      if (filter !== null) {
        resource = resource.one(filter);
      }

      var resourcePageOne = resource.get({page: 1});
      var data = Pagination.addPaginationSupport(filter === null ? item : filter, resourcePageOne);

      Restangular.addElementTransformer(filter, function (resource) {
        resource.refresh = function () {
          return resource.customGET();
        };

        return resource;
      });

      return data;
    };

    items.forEach(function (obj) {
      local[obj.name] = function (user) {
        return getItem(obj.item, obj.filter, user);
      };
    });

    return {
      getUser: getUser,
      getTasksAll: getTasksAll,
      getTasksInquiring: getTasksInquiring
    };
  }

  app.service('UserResource', ['$q', 'Restangular', 'Pagination', 'ResourceManipulation', '_', UserResource]);
})();
