import templateUrl from './settings-availability.html'
import './settings-availability.scss'

const SettingsAvailabilityComponent = {
  bindings: {},
  templateUrl,
  controller: class SettingsAvailabilityComponent {
    constructor ($http, Configuration, Flash) {
      'ngInject'
      this._identify = 'SettingsAvailabilityComponent'
      this.$http = $http
      this.Configuration = Configuration
      this.Flash = Flash
      this.availability = {
        generalAvailability: false,
        preferredProjects: false,
        preferredFromPreviousClients: false
      }
      this.payload = null
    }

    $onInit () {
      this.isLoading = true
      this.$http
        .get(`${this.Configuration.apiUrl}/users/me/preferences/project_availability`)
        .then(response => {
          const preferred = response.data.preferred
          this.handleResponse(preferred)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    handleResponse (preferred) {
      this.availability = {
        preferredProjects: (preferred === 'old' || preferred === 'all'),
        preferredFromPreviousClients: (preferred === 'all'),
        generalAvailability: (preferred !== 'none')
      }
      this.payload = {
        preferred: preferred
      }
    }

    onToggleAll () {
      if (this.availability.generalAvailability) {
        this.availability.preferredFromPreviousClients = true
        this.availability.preferredProjects = true
        this.payload = { preferred: 'all' }
      } else {
        this.availability.preferredFromPreviousClients = false
        this.availability.preferredProjects = false
        this.payload = { preferred: 'none' }
      }
    }

    onTogglePreferredProjects () {
      this.payload.preferred = this.availability.preferredProjects ? 'all' : 'none'
      this.availability.preferredFromPreviousClients = this.availability.preferredProjects
      this.availability.generalAvailability = this.availability.preferredProjects
    }

    onToggle () {
      if (!this.availability.preferredFromPreviousClients && this.availability.preferredProjects) {
        this.payload.preferred = 'old'
      } else if (this.availability.preferredFromPreviousClients && this.availability.preferredProjects) {
        this.payload.preferred = 'all'
      } else if (!this.availability.preferredProjects && this.availability.preferredFromPreviousClients) {
        this.payload.preferred = 'all'
        this.availability.preferredProjects = true
      }
      this.availability.generalAvailability = this.availability.preferredProjects
    }

    saveChanges () {
      this.isProcessing = true
      this.$http
        .put(
          `${this.Configuration.apiUrl}/users/me/preferences/project_availability`,
          this.payload
        )
        .then(response => {
          this.availabilityForm.$setPristine()
        })
        .catch(err => {
          console.log(err)
        }).finally(() => {
          this.isProcessing = false
          this.Flash.hide()
          if (this.availability.generalAvailability) {
            this.Flash.show('views/flashes/avail_persistant.html', true)
          } else {
            this.Flash.show('views/flashes/unavail_persistant.html', true)
          }
        })
    }
  }
}

export default SettingsAvailabilityComponent
