(function () {
  'use strict';

  function DataInterceptor ($q, _, $injector) {
    const analyseSensitiveInfo = function (xhrObject, xhrType) {
      try {
        const LoggingService = $injector.get('LoggingService')
        const AuthService = $injector.get('AuthService')
        const UserService = $injector.get('UserService')

        const whitelistUrls = [
          /\/tasks\/\d+\/safecharge-payload(\?sub_task_id=\d+)?/,
          /\/users(\/me)?(\/me\/avatar)?(\/token_login)?(\/login)?$/,
          /\/(clients|experts)\/account_details$/,
        ]

        if (AuthService.isAuthenticated() && xhrObject && typeof xhrObject.data === 'object') {
          const url = xhrObject.url ? xhrObject.url : xhrObject.config.url
          const isWhitelisted = whitelistUrls.find(regX => regX.test(url))
          if (isWhitelisted) {
            console.log('[DataInterceptor] > analyseSensitiveInfo > whitelisted', url)
            return
          }



          const sensitiveAttributes = ['email', 'password', 'authToken']
          const jsonString = JSON.stringify(xhrObject.data)
          const attributesList = [...jsonString.matchAll(/"(?<attributeName>\w*)":/gm)]
            .map(match => {
              return match.groups.attributeName
            })

          const sensitiveAttrsFound = sensitiveAttributes.filter(attrName => attributesList.includes(attrName))
          if (sensitiveAttrsFound.length) {
            const payload = {
              url: url,
              method: xhrObject.method ? xhrObject.method : xhrObject.config.method,
              sensitiveAttrs: sensitiveAttrsFound,
              type: xhrType,
              userId: UserService.user ? UserService.user.id : null
            }
            console.log('[DataInterceptor] > analyseSensitiveInfo > report', payload)
            LoggingService.logInfo('API endpoint with sensitive attrs', sensitiveAttrsFound.join(', '), payload)
          }
        }
      } catch (err) {
        console.log('[DataInterceptor] > analyseSensitiveInfo > err')
      }
    }

    const convertCase = function (obj, type) {
      // If the response is an array, it needs
      // to be iterated through each item and process it
      const convertArray = function (arr) {
        var processed = [];

        _(arr).each(function (item) {
          if (!_.isArray(item) && !_.isObject(item)) {
            processed.push(item);
          } else {
            processed.push(convertCase(item, type));
          }
        });

        return processed;
      };

      // Meat of the function, goes through every key:value pair
      // in an object to create a new, 'type' one
      const convertObject = function (obj) {
        var output = {};

        _(obj).forEach(function (val, key) {
          var newKey = type === 'toCamelCase' ? _.camelCase(key) : _.snakeCase(key); // String.prototype[type].call(key);

          if (_.isObject(val)) {
            output[newKey] = convertCase(val, type);
          } else {
            output[newKey] = val;
          }
        });

        return output;
      };

      // After defined all possible scenarios (having obj | array)
      // run one of the two outcomes, process the object or the array
      if (_.isArray(obj)) {
        return convertArray(obj);
      }

      return convertObject(obj);
    };

    return {
      request: function (config) {
        if (!(config.disableDataInterceptor || config.disableRequestDataInterceptor)) {
          if (config.method === 'POST' || config.method === 'PUT' || config.method === 'PATCH' || config.method === 'DELETE') {
            if (config.data) {
              config.data = convertCase(config.data, 'toSnakeCase');
            }
          }
        }

        analyseSensitiveInfo(config, 'request')
        return config || $q.when(config);
      },
      response: function (response) {
        if (!(response.config.disableDataInterceptor || response.config.disableResponseDataInterceptor)) {
          // Some objects don't have headers (like HTML templates)
          if (!response.headers) {
            return response || $q.when(response);
          }

          const headers = response.headers();
          if (headers['content-type'] === 'application/json; charset=utf-8') {
            response.data = convertCase(response.data, 'toCamelCase');
          }
        }

        analyseSensitiveInfo(response, 'response')
        return response;
      },
      convertCase: convertCase
    };
  }

  app.factory('DataInterceptor', ['$q', '_', '$injector', DataInterceptor]);

  app.config(['$httpProvider', function ($httpProvider) {
    $httpProvider.interceptors.push('DataInterceptor');
  }]);
})();
