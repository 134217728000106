import templateUrl from './notifications-popover.html'
import './notifications-popover.scss'

const NotificationsWidgetPopoverComponent = {
  bindings: {
    data: '<'
  },
  templateUrl,
  controller: class NotificationsWidgetPopover {
    constructor () {
      'ngInject'
      this._identify = 'NotificationsWidgetPopoverComponent'
    }

    $onInit () {
      console.log('[NotificationsWidgetPopoverComponent] > data > ', this.data)
      this.uisrefNotificationPageImportant = `notifications.list({listId: 'default' })`
      this.uisrefNotificationPage = `notifications.list({listId: '${this.data.category.name}' })`
    }

    get showImportantSection () {
      return this.data.notifications.important && this.data.notifications.important.list && this.data.notifications.important.list.length > 0
    }

    get numAdditionalImportantNotifications () {
      if (this.data.notifications.important) {
        const totalCount = this.data.notifications.important.pagination.totalCount
        const itemsPerPage = this.importantItemsPerPage
        const listLength = this.data.notifications.important.list.length

        const diff = Math.max(listLength - itemsPerPage, 0)
        const num = Math.max(0, totalCount - Math.min(itemsPerPage, listLength) + diff)

        return num
      }

      return 0
    }

    get importantItemsPerPage () {
      if (this.data.notifications.important && this.data.notifications.important.pagination) {
        return this.data.notifications.important.pagination.itemsPerPage
      }

      return 2
    }
  }
}

export default NotificationsWidgetPopoverComponent
