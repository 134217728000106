import ProjectPresetsComponent from './project-presets.component'
import ProjectPresetsListComponent from './presets-list'
import ProjectPresetsCreateComponent from './presets-create'
import ProjectPresetsApplyComponent from './presets-apply'
import ProjectPresetsParentListComponent from './presets-parent-list'
import ProjectPresetsParentEditComponent from './presets-parent-edit'
import ProjectPresetsDefinerFormComponent from './definer-form'
import ProjectPresetsClientFormComponent from './client-form'
import ProjectPresetsService from './project-presets.service'

import ProjectPresetsParentFieldControlComponent from './parent-field-control'

const ProjectPresetsModule = angular.module('cdbl.project-presets', [

])
  .service('ProjectPresetsService', ProjectPresetsService)
  .component('cdblProjectPresets', ProjectPresetsComponent)
  .component('cdblProjectPresetsList', ProjectPresetsListComponent)
  .component('cdblProjectPresetsCreate', ProjectPresetsCreateComponent)
  .component('cdblProjectPresetsApply', ProjectPresetsApplyComponent)
  .component('cdblProjectPresetsParentList', ProjectPresetsParentListComponent)
  .component('cdblProjectPresetsParentEdit', ProjectPresetsParentEditComponent)
  .component('cdblProjectPresetsParentFieldControl', ProjectPresetsParentFieldControlComponent)
  .component('cdblProjectPresetsDefinerForm', ProjectPresetsDefinerFormComponent)
  .component('cdblProjectPresetsClientForm', ProjectPresetsClientFormComponent)
  .config(($stateProvider, $urlRouterProvider) => {
    'ngInject'

    $stateProvider
      .state('presets', {
        url: '/presets',
        abstract: true,
        template: '<cdbl-project-presets></cdbl-project-presets>',
        layout: 'new'
      })
      .state('presets.parents', {
        url: '/parents',
        abstract: true,
        template: '<div ui-view></div>',
        layout: 'new'
      })
      .state('presets.parents.list', {
        url: '',
        template: '<cdbl-project-presets-parent-list></cdbl-project-presets-parent-list>',
        layout: 'new'
      })
      .state('presets.parents.edit', {
        url: '/:id',
        template: '<cdbl-project-presets-parent-edit></cdbl-project-presets-parent-edit>',
        layout: 'new'
      })
      .state('presets.list', {
        url: '?partner&expert&staff',
        params: {
          partner: { squash: true, type: 'string' },
          expert: { squash: true, type: 'string' },
          staff: { squash: true, type: 'string' }
        },
        template: '<cdbl-project-presets-list></cdbl-project-presets-list>',
        layout: 'new'
      })
      .state('presets.create', {
        url: '/create?partner&expert&staff&form&shortcode',
        params: {
          partner: { squash: true, type: 'string' },
          expert: { squash: true, type: 'string' },
          staff: { squash: true, type: 'string' },
          form: { squash: true, type: 'string' },
          shortcode: { squash: true, type: 'string' }
        },
        template: '<cdbl-project-presets-create></cdbl-project-presets-create>',
        layout: 'new'
      })
      .state('presets.apply', {
        url: '/apply?token',
        params: {
          token: { squash: true, type: 'string' }
        },
        template: '<cdbl-project-presets-apply></cdbl-project-presets-apply>',
        layout: 'new'
      })
    // $urlRouterProvider.when('/presets', '/presets/create') // redirect to default view
  })
  .name

export default ProjectPresetsModule
