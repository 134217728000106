const WoocommerceSubmissionConfig = {
  displayName: 'WooCommerce',
  types: [
    {
      type: 'Product Page',
      title: 'Product Page',
      // description: 'Subscriptions',
      project_type: 'product_page',
      project_subject: '',
      kind: 'plugin',
      position: 'main_options',
      icon: 'icon-woocommerce-product_page',
      options: [
        {
          value: 'layout_update',
          name: 'Updates',
          description: 'Updates'
        },
        {
          value: 'new_functionality',
          name: 'Add functionality',
          description: 'Add functionality'
        }
      ]
    },
    {
      type: 'Shop Page',
      title: 'Shop Page',
      project_type: 'shop_page',
      project_subject: '',
      kind: 'plugin',
      position: 'main_options',
      icon: 'icon-woocommerce-shop_page',
      options: [
        {
          value: 'layout_update',
          name: 'Updates',
          description: 'Updates'
        },
        {
          value: 'new_functionality',
          name: 'Add functionality',
          description: 'Add functionality'
        }
      ]
    },
    {
      type: 'Cart',
      title: 'Cart',
      project_type: 'cart',
      project_subject: '',
      kind: 'plugin',
      icon: 'icon-woocommerce-shopping_cart',
      position: 'main_options',
      options: [
        {
          value: 'layout_update',
          name: 'Updates',
          description: 'Updates'
        },
        {
          value: 'new_functionality',
          name: 'Add functionality',
          description: 'Add functionality'
        }
      ]
    },
    {
      type: 'Checkout',
      title: 'Checkout',
      project_type: 'checkout',
      project_subject: '',
      kind: 'plugin',
      icon: 'icon-woocommerce-checkout',
      position: 'main_options',
      options: [
        {
          value: 'layout_update',
          name: 'Updates',
          description: 'Updates'
        },
        {
          value: 'new_functionality',
          name: 'Add functionality',
          description: 'Add functionality'
        }
      ]
    },
    {
      type: 'Store Management',
      title: 'Store Management',
      project_type: 'store_management',
      project_subject: '',
      kind: 'plugin',
      icon: 'icon-woocommerce-storemanagement',
      position: 'main_options',
      options: [
        {
          value: 'update',
          name: 'Updates',
          description: 'Updates'
        },
        {
          value: 'migration',
          name: 'Migrations',
          description: 'Migrations'
        },
        {
          value: 'maintenance',
          name: 'Maintenance',
          description: 'Maintenance'
        }
      ]
    },
    {
      type: 'Consultation',
      title: `Consult an Expert`,
      description: `For only <strong>{{ ::($ctrl.consultationPrice.value | currencyFormat) }}</strong>, speak to one of our experts about your project for 1 hour. Get advice on what should be done, or how complex different approaches are, or how to define a project before getting started.
          <a class="more" href="https://help.codeable.io/posting-your-project/what-is-a-consultation-task" target="_blank" cdbl-track="Submission Flow Link Clicked" cdbl-track-payload="{label:'Learn more'}">Learn more.</a>`,
      project_type: 'consultation',
      project_subject: 'consultation',
      kind: 'consultation',
      icon: 'icon-woocommerce-consultation',
      position: 'main_options',
      hideOptions: true,
      options: [
        {
          value: 'consultation',
          name: 'Consultation',
          description: false,
        }
      ]
    }
  ]
}

export default WoocommerceSubmissionConfig
