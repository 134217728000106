import templateUrl from './scope-doc-view.html'
import './scope-doc-view.scss'

const ProjectScopeDocViewComponent = {
  bindings: {
    scopeDoc: '<',
    onItemFinishToggle: '&'
  },
  templateUrl,
  controller: class ProjectScopeDocView {
    constructor (UserService, EventEmitter) {
      'ngInject'
      this._identify = 'ProjectScopeDocViewComponent'
      this.UserService = UserService
      this.EventEmitter = EventEmitter
    }

    toggleFinishedState (item) {
      if (this.UserService.isClient()) {
        return
      }

      if (typeof this.onItemFinishToggle === 'function') {
        item.toggleProcessing(true)
        this.onItemFinishToggle(this.EventEmitter({ item }))
          .then(response => {
            console.log('[ProjectScopeDocViewComponent] > onItemFinishToggle > response', response)
            // item.updateSelf(response) // if we would like that whole item is updated from BE

            item.finishedAt = response.finishedAt
          })
          .finally(() => {
            item.toggleProcessing(false)
          })
      }
    }
  }
}

export default ProjectScopeDocViewComponent
