import FileSelectorComponent from './file-selector.component'

const FileSelectorModule = angular.module('cdbl.file-selector', [

])
  .component('cdblFileSelector', FileSelectorComponent)
  .directive('cdblFileSelectorOnChange', function () {
    return {
      restrict: 'A',
      link: function ($scope, $element, $attrs) {
        var onChangeHandler = $scope.$eval($attrs.cdblFileSelectorOnChange)
        $element.on('change', onChangeHandler)
        $element.on('$destroy', function () {
          $element.off()
        })
      }
    }
  })
  .name

export default FileSelectorModule
