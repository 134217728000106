import SubmissionProgressbarComponent from './submission-progressbar.component'
import ProgressBar from './../../../common/ui/progressbar/progressbar.module'
import ProgressStep from './submission-progressbar-step/submission-progressbar-step.module'

const SubmissionProgressbarModule = angular.module('cdbl.submission-progressbar', [
  ProgressBar,
  ProgressStep
])
  .component('cdblSubmissionProgressbar', SubmissionProgressbarComponent)
  .name

export default SubmissionProgressbarModule
