const StorageService = class StorageService {
  constructor ($rootScope, $localStorage, $window) {
    'ngInject'
    this.$rootScope = $rootScope
    this.$localStorage = $localStorage
    this.$window = $window
  }

  init () {
    this.$rootScope.$storage = this.$localStorage
  }

  set (key, value) {
    this.$rootScope.$storage[key] = value
  }

  get (key) {
    return this.$rootScope.$storage[key]
  }

  destroy (key) {
    this.remove(key)
  }

  remove (key) {
    delete this.$rootScope.$storage[key]
  }

  removeAll () {
    this.$rootScope.$storage.$reset()
  }

  nativeGet (key) { // to mitigate issue of fetching non prefixed keys
    return angular.fromJson(this.$window.localStorage.getItem(key))
  }
  nativeSet (key, value) { // to mitigate issue of fetching non prefixed keys
    return this.$window.localStorage.setItem(key, angular.toJson(value))
  }
  nativeRemove (key) {
    this.$window.localStorage.removeItem(key)
  }
  nativeRemoveAll () {
    this.$window.localStorage.clear()
  }
}
export default StorageService
