import HeaderHelpMenuPopoverModule from './header-help-menu-popover'
import HeaderLegacyLinksPopoverModule from './header-legacy-links-popover'
import HeaderPopoverModule from './header-popover'
import HeaderComponent from './header.component'

const HeaderModule = angular.module('cdbl.header', [
  HeaderPopoverModule,
  HeaderHelpMenuPopoverModule,
  HeaderLegacyLinksPopoverModule
])
  .component('cdblHeader', HeaderComponent)
  .name

export default HeaderModule
