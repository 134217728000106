const mdMetaProvider = function () {
  var head = angular.element(document.head)
  var metaElements = {}

  /**
   * Checks if the requested element was written manually and maps it
   *
   * @param {string} name meta tag 'name' attribute value
   * @returns {boolean} returns true if there is an element with the requested name
   */
  function mapExistingElement (name) {
    if (metaElements[name]) {
      return true
    }

    var element = document.getElementsByName(name)[0]

    if (!element) {
      return false
    }

    metaElements[name] = angular.element(element)

    return true
  }

  /**
   * @ngdoc method
   * @name $$mdMeta#setMeta
   *
   * @description
   * Creates meta element with the 'name' and 'content' attributes,
   * if the meta tag is already created than we replace the 'content' value
   *
   * @param {string} name meta tag 'name' attribute value
   * @param {string} content meta tag 'content' attribute value
   * @returns {function} remove function
   *
   */
  function setMeta (name, content) {
    mapExistingElement(name)

    if (!metaElements[name]) {
      var newMeta = angular.element('<meta name="' + name + '" content="' + content + '"/>')
      head.append(newMeta)
      metaElements[name] = newMeta
    } else {
      metaElements[name].attr('content', content)
    }

    return function () {
      metaElements[name].attr('content', '')
      metaElements[name].remove()
      delete metaElements[name]
    }
  }

  /**
   * @ngdoc method
   * @name $$mdMeta#getMeta
   *
   * @description
   * Gets the 'content' attribute value of the wanted meta element
   *
   * @param {string} name meta tag 'name' attribute value
   * @returns {string} content attribute value
   */
  function getMeta (name) {
    if (!mapExistingElement(name)) {
      throw new Error('$$mdMeta: could not find a meta tag with the name \'' + name + '\'')
    }

    return metaElements[name].attr('content')
  }

  var module = {
    setMeta: setMeta,
    getMeta: getMeta
  }

  return angular.extend({}, module, {
    $get: function () {
      return module
    }
  })
}

export default mdMetaProvider
