import Barn2UKSubmissionPresets from './submission.presets.barn2uk.js'
const Barn2UKSubmissionConfig = {
  displayName: 'Barn2 Media',
  presets: Barn2UKSubmissionPresets,
  types: [
    {
      type: 'WooCommerce Product Table',
      title: 'WooCommerce Product Table',
      project_type: 'barn2uk_woo_product_table_plugin',
      project_subject: '',
      kind: 'plugin',
      position: 'main_options',
      icon: 'icon-barn2uk-product_page',
      options: [
        {
          value: 'installation',
          name: 'Installation',
          description: 'Plugin Installation & Set-up'
        },
        {
          value: 'integration',
          name: 'Integration',
          description: 'Add the Product Table Layout to your Shop and Category Pages'
        }
      ]
    },
    {
      type: 'Posts Table Pro',
      title: 'Posts Table Pro',
      project_type: 'barn2uk_posts_table_pro_plugin',
      project_subject: '',
      kind: 'plugin',
      position: 'main_options',
      icon: 'icon-barn2uk-shop_page',
      options: [
        {
          value: 'basic_installation',
          name: 'Basic Installation',
          description: 'Plugin Installation & Set-up - Basic'
        },
        {
          value: 'advanced_installation',
          name: 'Advanced Installation',
          description: 'Plugin Installation & Set-up - Advanced'
        }
      ]
    },
    {
      type: 'WooCommerce Private Store',
      title: 'WooCommerce Private Store',
      project_type: 'barn2uk_woo_private_store_plugin',
      project_subject: '',
      kind: 'plugin',
      icon: 'icon-barn2uk-shopping_cart',
      position: 'main_options',
      options: [
        {
          value: 'installation',
          name: 'Installation',
          description: 'Plugin Installation & Set-up'
        }
      ]
    },
    {
      type: 'WooCommerce Protected Categories',
      title: 'WooCommerce Protected Categories',
      project_type: 'barn2uk_woo_protected_categories_plugin',
      project_subject: '',
      kind: 'plugin',
      icon: 'icon-barn2uk-checkout',
      position: 'main_options',
      options: [
        {
          value: 'installation',
          name: 'Installation',
          description: 'Plugin Installation & Set-up'
        }
      ]
    },
    {
      type: 'Password Protected Categories',
      title: 'Password Protected Categories',
      project_type: 'barn2uk_password_protected_categories_plugin',
      project_subject: '',
      kind: 'plugin',
      icon: 'icon-barn2uk-storemanagement',
      position: 'main_options',
      options: [
        {
          value: 'installation',
          name: 'Installation',
          description: 'Plugin Installation & Set-up'
        }
      ]
    }
  ]
}

export default Barn2UKSubmissionConfig
