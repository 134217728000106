(function () {
  'use strict';

  function escapeHTML(html) {
    if (html.indexOf('```') === -1) {
      return html.escape();
    }

    return html.split(/(```)/g).reduce(function (previous, current, idx) {
      if (idx === 1) {
        previous = previous.escape();
      }

      return idx % 4 === 0 ? previous + current.escape() : previous + current;
    });
  }

  /*
    If a href doesn't start with http:// or https://, insert http:// before
    XSS vulnerability: for example, markdown converts [notmalicious](javascript:window.onerror=alert;throw%20document.cookie) to
    <a href="javascript:window.onerror=alert;throw%20document.cookie">notmalicious</a>
    Anyway, all links after markdown is applied, should start with either http:// or https://
  */
  function preventXSS(html) {
    var delimiter = '<a href="';
    return html.split(delimiter).reduce(function (prev, curr, idx, arr) {
      if (idx > 0 && (curr.substring(0, 7) !== 'http://' || curr.substring(0, 8) !== 'https://')) {
        prev.push('http://');
      }
      prev.push(curr);
      if (idx < arr.length - 1) {
        prev.push(delimiter);
      }
      return prev;
    }, []).join('');
  }

  function unmarkup() {
    return function (html) {
      if (html) {
        // html = html.replace(/<[^>]+>/gm, '');//remove html tags
        html = html.replace(/\*|#|_|~|`|-|(\[ ])|(\[x])/g, '');// remove marked tags
        return preventXSS(escapeHTML(html));
      }
      // console.log('unmarkup > not valid', html);
      return html;
    };
  }

  app.filter('unmarkup', unmarkup);
})();
