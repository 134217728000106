'use strict';

// returns date if input date is not passed and returns false is it is past current date - granularity is set to day
function datePassed() {
  return function (inputDate) {
    if (typeof inputDate !== 'undefined' && typeof inputDate !== 'boolean' && moment(inputDate).isSameOrBefore(moment(), 'day')) {
      return inputDate;
    }

    return false;
  };
}

app.filter('datePassed', datePassed);

