(function () {
  'use strict';

  function scrollTo() {
    var link = function ($scope, element) {
      $scope.scrollToTop = 1;
      $scope.scrollToBottom = 1;

      $scope.$watch('scrollToTop', function () {
        element.scrollTop(0);
      });

      $scope.$watch('scrollToBottom', function () {
        element.scrollTop(element[0].scrollHeight);
      });
    };

    return {
      restrict: 'A',
      scope: {
        scrollToTop: '=',
        scrollToBottom: '='
      },
      link: link
    };
  }

  app.directive('scrollTo', scrollTo);
})();
