(function () {
  'use strict';

  function estimator(User, UserService, ProjectService, ModalService) {
    var link = function ($scope) {
      $scope.inviteConfirm = function (estimator) {
        // TODO: check if this needs refactoring or removal
        // User.inviteContractor($scope.task, estimator); // TODO refactor into separate service or move to UserService
        // $scope.task.contractor = estimator;

        ProjectService.hireExpert($scope.task, estimator)
          .then(response => {
            console.log('[estimator] > ProjectService.hireExpert > response', response, estimator)
            estimator.invitation = response // TODO: whole mechanism needs refactoring, this is needed because its still 2 step process
          })
      };


      $scope.invite = (estimator) => {
        const config = ModalService.generateConfig('confirmation', {
          body: `Are you sure you want to hire ${estimator.fullName}?`,
          rejectButton: {
            label: 'Cancel'
          },
          resolveButton: {
            label: 'Yes, Hire'
          },
        })

        ModalService.open(config)
          .then(response => {
            $scope.inviteConfirm(estimator)
          })
      }

      // $scope.$on('invitation-updated', function (event, invitation) {
      //   if (UserService.user.id === invitation.userId) {
      //     if (invitation.state === 'accepted') {
      //       $scope.task.state = invitation.taskState;
      //       $scope.task.contractor = invitation.eventOwner;
      //       $scope.estimator.invitation.state = 'declined';
      //     } else if (invitation.state === 'declined') {
      //       $scope.estimator.invitation.state = 'declined';
      //     }
      //   } else if (invitation.userId === $scope.estimator.id) {
      //     if (invitation.state === 'accepted') {
      //       $scope.task.state = invitation.taskState;
      //       $scope.estimator.invitation = invitation;
      //     } else if (invitation.state === 'declined') {
      //       $scope.estimator.invitation.state = 'declined';
      //     } else if (invitation.state === 'created') {
      //       $scope.estimator.invitation = invitation;
      //     }
      //   }
      // });
    };

    return {
      restrict: 'EA',
      replace: true,
      templateUrl: require('../../views/directives/estimator.html'),
      link: link
    };
  }

  app.directive('estimator', ['User', 'UserService', 'ProjectService', 'ModalService', estimator]);
})();
