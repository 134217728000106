// const checkRequiredDirective = function () {
//   return function () {
//   'ngInject';

//     return {
//       require: 'ngModel',
//       restrict: 'A',
//       link: function (scope, element, attrs, ngModel) {
//         'ngInject';
//         console.log('checkRequired', ngModel);
//         ngModel.$validators.checkRequired = function (modelValue, viewValue) {

//           var value = modelValue || viewValue;
//           var match = scope.$eval(attrs.ngTrueValue) || true;

//           console.log('checkRequired', value && match === value);
//           return value && match === value;
//         };
//       }
//     }
//   }
// }


const checkRequiredDirective = function () {
  return {
    require: 'ngModel',
    restrict: 'A',
    link: function (scope, element, attrs, ngModel) {
      ngModel.$validators.checkRequired = function (modelValue, viewValue) {
        var value = modelValue || viewValue
        var match = scope.$eval(attrs.ngTrueValue) || true
        return value && match === value
      }
    }
  }
}


export default checkRequiredDirective
