const SettingsExpertTeamService = class SettingsExpertTeamService {
  constructor ($http, $q, $state, Configuration, UserService, AuthService, ModalService) {
    'ngInject'
    this._identify = 'SettingsExpertTeamService'
    this.$http = $http
    this.$q = $q
    this.$state = $state
    this.Configuration = Configuration
    this.UserService = UserService
    this.AuthService = AuthService
    this.ModalService = ModalService

    this.AUTO_DISPLAY_CONFIRMATION_MODAL_NAME = 'experts_team_needs_confirming'
  }

  showUpdateConfirmationModal () {
    // Do not show reminder if user is already on settings expert team page
    if (this.$state.current.name === 'settings.expert-team') {
      return
    }
    this.ModalService.open({
      title: `PLEASE CONFIRM OR UPDATE TEAM MEMBER DETAILS`,
      body: `Please take a moment to confirm your team details.`,
      closeOnBackdrop: false,
      closeIcon: true,
      backdrop: true,
      layout: 'center',
      class: 'settigns-expert-team-reminder-modal cdbl-modal--style-with-dividers',
      actions: [
        {
          label: 'GO TO SETTINGS',
          class: 'button-primary',
          callback: modalCtrl => {
            this.$state.go('settings.expert-team')
            modalCtrl.close()
          }
        }
      ]
    })
  }

  // -------------------------------------------------------
  // Expert teams API
  // -------------------------------------------------------

  async getExpertTeamSettings () {
    return this.$http
      .get(`${this.Configuration.apiUrl}/experts/team`)
      .then(response => response.data)
  }

  async updateExpertTeamDetails (team, formData) {
    const requestConfig = { ...team.actions.updateDetails }
    const fd = new FormData()
    const config = {
      url: `${this.Configuration.apiUrl}${requestConfig.url}`,
      method: requestConfig.method,
      transformRequest: angular.identity,
      disableRequestDataInterceptor: true,
      headers: {
        'Content-Type': undefined
      }
    }

    fd.append('legal_entity_name', formData.legalEntityName)
    fd.append('team_display_name', formData.teamDisplayName)
    fd.append('location', formData.location)
    fd.append('team_size', formData.teamSize)
    fd.append('year_founded', formData.yearFounded)

    if (formData.logo && formData.logo[0]) {
      fd.append('logo', formData.logo[0])
      console.log(formData.logo[0])
    } else if (formData.logo === null) {
      fd.append('logo', formData.logo)
    }
    return this.$http({ ...config, data: fd })
      .then(response => response.data)
  }


  getAllResources (resources) {
    // locations will be handled by autocomplete, do not load self again, remove both from list of preloaded resources
    const promises = []
    const resourcesMap = Object.entries(resources)
    resourcesMap.forEach(([resourceName, resourceConfig]) => {
      resourceConfig.url = `${this.Configuration.apiUrl}${resourceConfig.url}`
      const p = this.$http(resourceConfig)
        .then(response => [resourceName, response.data])
      promises.push(p)
    })

    return this.$q.all(promises).then(response => Object.fromEntries(response))
  }


  async saveMember (team = null, member = null, formData = null) {
    let requestConfig
    if (member) {
      requestConfig = { ...member.actions.updateMember }
    } else {
      requestConfig = { ...team.actions.createMember }
    }

    const fd = new FormData()
    const config = {
      url: `${this.Configuration.apiUrl}${requestConfig.url}`,
      method: requestConfig.method,
      transformRequest: angular.identity,
      disableRequestDataInterceptor: true,
      headers: {
        'Content-Type': undefined
      }
    }

    fd.append('name', formData.name)
    fd.append('email', formData.email)
    fd.append('location', formData.location)
    fd.append('employee', formData.employee)

    const roles = formData.roles.map(r => r.id)
    roles.forEach(role => {
      fd.append('roles[]', role)
    })

    if (formData.avatar && formData.avatar[0]) {
      fd.append('avatar', formData.avatar[0])
    }
    return this.$http({ ...config, data: fd })
      .then(response => response.data)
  }

  toggleActiveMember (member) {
    const requestConfig = member.actions.createMemberActivation ? { ...member.actions.createMemberActivation } : { ...member.actions.deleteMemberActivation }
    const config = {
      url: `${this.Configuration.apiUrl}${requestConfig.url}`,
      method: requestConfig.method
    }

    return this.$http({ ...config })
      .then(response => response.data)
  }

  async deleteMember (member = null, formData) {
    const requestConfig = { ...member.actions.deleteMember }
    const config = {
      url: `${this.Configuration.apiUrl}${requestConfig.url}`,
      method: requestConfig.method
    }
    return this.$http({ ...config })
      .then(response => response.data)
  }


  confirmTeamInformation (team) {
    const requestConfig = team.actions.updateConfirmation
    const config = {
      url: `${this.Configuration.apiUrl}${requestConfig.url}`,
      method: requestConfig.method
    }

    return this.$http({ ...config })
      .then(response => response.data)
  }
}
export default SettingsExpertTeamService
