const LoggingService = class LoggingService {
  constructor ($http, Configuration, Auth, Storage, Sockets) {
    'ngInject'
    this.$http = $http
    this.Configuration = Configuration
  }

  logError (context, errorName, errorData) {
    const payload = {
      message: `ERROR: ${context} - ${errorName}`,
      data: {
        context: context,
        name: errorName,
        error: errorData
      }
    }
    this.$http
      .post(`${this.Configuration.apiUrl}/log/errors`, payload)
      .then(response => {
        console.log('[LoggingService] > logError > successful')
      }, error => {
        console.log('[LoggingService] > logError > error', error)
      })
  }


  logInfo (context, infoName, infoData) {
    const payload = {
      message: `INFO: ${context} - ${infoName}`,
      data: {
        context: context,
        name: infoName,
        data: infoData
      }
    }
    this.$http
      .post(`${this.Configuration.apiUrl}/log/infos`, { entry: payload })
      .then(response => {
        console.log('[LoggingService] > logInfo > successful', payload)
      }, error => {
        console.log('[LoggingService] > logInfo > error', error)
      })
  }
}
export default LoggingService
