// TODO: something to think about - both activities and notifcations could have configuration dynamically extendable by other features configurations if they are included in the project. Example: scope doc could register which event names are valid and provide templates for them for activities and notifications.

const config = {
  publicOffsetVerb: 'payment_successful',
  commentVerb: 'create_comment',

  // this list of verbs is used to filter endpoint response
  validVerbs: [
    'cancel_sub_task',
    'complete_sub_task',
    'complete_task',
    'create_attachment',
    'destroy_attachment',
    'create_comment',
    'update_comment', // uses same template as create_comment
    'create_estimate', // same verb is used for updated estimate
    'create_sub_task',
    // 'destroy_vault',
    'payment_subscription_canceled',
    'payment_successful',
    'project-refund-successful',
    // 'retainer_subscription_created',
    // 'retainer_subscription_deleted',
    'retainer_subscription_failed',
    'retainer_subscription_succeeded',
    'sub-task-refund-successful',
    'sub_task_payment_successful',
    'retainer_subscription_renewed',

    // scope doc
    'create_scope_doc_submission',
    'create_scope_doc_rejection',
    'create_scope_doc_approval',
    'create_scope_doc_finish',
    'create_scope_doc_item_finish',
  ]
}
export default config
