import templateUrl from './certificate-item.html'
import './certificate-item.scss'

const CertificateItemComponent = {
  bindings: {
    certificate: '<'
  },
  templateUrl,
  controller: class CertificateItemComponent {
    constructor ($window, $state, UserService, ModalService, TransactionsService, SegmentAnalytics) {
      'ngInject'
      this._identify = 'CertificateItemComponent'
      this.$window = $window
      this.$state = $state
      this.UserService = UserService
      this.ModalService = ModalService
      this.TransactionsService = TransactionsService
      this.SegmentAnalytics = SegmentAnalytics
    }

    $onInit () {
      this.title = null
      this.content = null
      this.showFee = false
    }

    downloadCertificate () {
      this.$window.open(this.certificate?.certificatePdfUrl?.url, '_blank')
    }
  }
}

export default CertificateItemComponent
