// Static config that should be served from BE in the future and used across whole app

const config = {
  auth: {
    tokenKey: 'Authorization',      // localstorage key for auth token
    userKey: 'User',                // localstorage key for user
    tokenHeaderKey: 'auth-token',   // header key for auth token
    loginRoute: 'auth.sign_in',     // TODO change to state object
    tokenRenewalInterval: 21600,
    // tokenEndpointUrls: [                    // urls that can contain new auth token
    //   '/users/login',
    //   '/users/token_login',
    //   '/users/auth_tokens',
    //   '/users',
    // ],
    logoutWithoutRedirectUrls: [    // urls which on automatic logout user should not be redirected to login page, however all data about user and auth token is destroyed and all services are disconnected
      '/tasks/new',
      '/presets/create',
      '/presets/apply',
      '/presets',
      '/sign_in',
      '/reset_password',
      '/register',
      '/debug'
    ],
    loginWithoutRedirectUrls: [    // urls which on successful login user is NOT redirected to default route, these pages need to handle redirect after user is logged in on them
      '/tasks/new',
      '/presets/create',
      '/presets/apply',
      '/presets',
      '/debug'
    ],
    preventLogoutWhenUnauthorizedUrls: [
      '/tasks',
      '/team'
    ],
  },
  defaultRouterState: {
    name: 'dashboard.list',
    options: { listId: 'default' }
  },
  router: {
    preventRedirectOn404Urls: [
      '/draft_submissions',
      '/project_parent_presets',
      '/presets',
    ],
    fallbackRedirects: [
      { from: `/users/transactions`, to: `/transactions` },
      { from: `/users/settings?tab=invoices`, to: `/settings/invoices` }
    ],
    fallbackPageTitles: [
      {
        path: '(/users/dashboard|/dashboard)',
        title: 'Project Dashboard | Codeable'
      },
      {
        path: '(/users/dashboard/inquiring|/dashboard/pending)',
        title: 'Pending Project Dashboard | Codeable'
      },
      {
        path: '(/users/dashboard/in-progress|/dashboard/in-progress)',
        title: 'In progress Project Dashboard | Codeable'
      },
      {
        path: '(/users/dashboard/archive|/dashboard/archived)',
        title: 'Archived Project Dashboard | Codeable'
      },
      {
        path: '(/users/dashboard/favourites|/dashboard/favourites)',
        title: 'Favourited Project Dashboard | Codeable'
      },
      {
        path: '/transactions',
        title: 'Transactions | Codeable'
      },
      {
        path: '/tasks/([0-9]*)',
        title: 'Project | Codeable'
      },
      {
        path: '/tasks/([0-9]*)/workroom',
        title: 'Project Workroom | Codeable'
      },
      {
        path: '/new-projects',
        title: 'New Projects | Codeable'
      },
      {
        path: '/notifications',
        title: 'Notifications | Codeable'
      },
      {
        path: '/settings',
        title: 'Settings | Codeable'
      },
      {
        path: '/profile-edit',
        title: 'Profile | Codeable'
      },
      {
        path: '/tasks/new',
        title: 'Submit Your Project | Codeable'
      }
    ]
  },
  helpUrl: 'https://help.codeable.io/en/', // used for clients and experts
  experiments: {
    // leave empty here, add experiments in the environment configs
  },
  privacy: {
    noticeUrl: 'https://storage.googleapis.com/cdbl-general/privacy-notice.html',
    policyUrl: 'https://storage.googleapis.com/cdbl-general/privacy-policy-content.html'
  },
  features: {
    pricing: {
      ratesRange: {
        min: 70,
        max: 120,
        rangeLabel: '$80 - $120 USD'
      },
      consultationPrice: {
        value: 69,
        discountValue: 29,
        label: '$69 USD'
      }
    },
    user: {
      // default notification settings for expert and client
      notificationSettings: {
        sounds: {
          newProject: 'click',
          pendingProject: 'heart-attack',
          inprogressProject: 'heart-attack'
        }
      }
    },
    subtask: {
      budget: {
        min: 30,
        minLabel: '$30 USD',
        max: 999999,
        maxLabel: '$999.999 USD'
      }
    },
    settings: {
      account: {
        maxTags: 20,
        maxLanguages: 9
      },
      notificationSounds: [
        {
          id: 'default',
          label: 'Default'
        },
        {
          id: 'none',
          label: 'None'
        },
        {
          id: 'heart-attack',
          label: 'Heart attack'
        },
        {
          id: 'click',
          label: 'Click'
        },
        {
          id: 'cha-ching',
          label: 'Cha-ching'
        },
        {
          id: 'friends',
          label: 'What friends are for'
        },
        {
          id: 'filling-inbox',
          label: 'Filling your inbox'
        },
        {
          id: 'coins',
          label: 'Coins'
        },
        {
          id: 'arpeggio',
          label: 'Arpeggio'
        },
        {
          id: 'jingle-bells',
          label: 'Jingle Bells'
        },
        {
          id: 'another-message',
          label: 'What, another message?'
        },
        {
          id: 'bubble',
          label: 'Bubble'
        }
      ]
    },
    estimation: {
      hourlyRate: {
        minValue: 1,
        minLabel: '$1 USD',
      },
      timeEstimate: {
        minValue: 0.5,
        minLabel: '0.5h',
      },
      totalEstimate: {
        minValue: 50,
        minLabel: '$50 USD',
        maxValue: 999999,
        maxLabel: '$999.999 USD'
      }
    },
    submission: {
      budgetCustom: {
        min: 30,
        minLabel: '$30 USD',
        max: 100000,
        maxLabel: '$100.000 USD'
      },
      budgetRanges: [
        {
          id: -1,
          label: 'up to $200 USD',
          enabled: false
        },
        {
          id: -2,
          label: '$200 - $600 USD',
          enabled: false
        },
        {
          id: -3,
          label: '$600 - $1,000 USD',
          enabled: false
        },
        {
          id: -4,
          label: 'more than $1,000 USD',
          enabled: false
        },
        {
          id: -5,
          label: 'not sure',
          enabled: true
        },
        {
          id: -6,
          label: 'flexible',
          enabled: false
        },
        {
          id: -10,
          label: '$7,000 - $9,000 USD',
          enabled: false
        },
        {
          id: -13,
          label: '$1,000 - $3,000 USD',
          enabled: false
        },
        {
          id: -14,
          label: '$3,000 - $10,000 USD',
          enabled: false
        },
        {
          id: -15,
          label: 'more than $10,000 USD',
          enabled: false
        },

        {
          id: -3000,
          label: '$3,000 - $5,000 USD',
          enabled: false
        },
        {
          id: -5000,
          label: '$5,000 - $7,000 USD',
          enabled: false
        },

        // enabled
        {
          id: -11,
          label: 'up to $500 USD',
          enabled: true
        },
        {
          id: -12,
          label: '$500 - $1,000 USD',
          enabled: true
        },
        {
          id: -7,
          label: '$1,000 - $3,000 USD',
          enabled: true
        },
        {
          id: -8,
          label: '$3,000 - $5,000 USD',
          enabled: true
        },
        {
          id: -9,
          label: '$5,000 - $7,000 USD',
          enabled: true
        },
        {
          id: -7000,
          label: '$7,000 - $10,000 USD',
          enabled: true
        },
        {
          id: -10000,
          label: '$10,000 - $50,000 USD',
          enabled: true
        },
        {
          id: -50000,
          label: 'more than $50,000 USD',
          enabled: true
        }
      ]
    }
  }
}
export default config
