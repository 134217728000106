import templateUrl from './project-convert-type-modal.html'
import './project-convert-type-modal.scss'

const ProjectConvertTypeModalComponent = {
  bindings: {
    project: '<'
  },
  templateUrl,
  require: {
    modalCtrl: '^^cdblModal'
  },
  controller: class ProjectConvertTypeModal {
    constructor ($state, Configuration, ProjectService, SegmentAnalytics) {
      'ngInject'
      this._identify = 'ProjectConvertTypeModalComponent'
      this.$state = $state
      this.Configuration = Configuration
      this.ProjectService = ProjectService
      this.SegmentAnalytics = SegmentAnalytics

      this.consultationPrice = Configuration.features.pricing.consultationPrice

      this.isConverted = false
      this.isProcessing = false

      this.serverError = null
    }

    $onInit () {

    }

    closeAndReload () {
      this.$state.go(this.$state.current, {}, { reload: true })
      this.modalCtrl.close()
    }

    convertToConsultation () {
      console.log('[ProjectConvertTypeModalComponent] > convertToConsultation')

      this.isProcessing = true
      const previousType = this.project.kind
      this.ProjectService.convertProjectToConsultation(this.project.id)
        .then(response => {
          console.log('[ProjectConvertTypeModalComponent] > response', response)
          const project = response // because this.project is project structure from listing it does not have all data that SegmentAnalytics requires... we need to use project from response

          this.SegmentAnalytics.trackTaskConverted(project, previousType)

          this.isConverted = true
        }, error => {
          console.log('[ProjectConvertTypeModalComponent] > error', error)
          this.serverError = error
        })
        .finally(() => {
          this.isProcessing = false
        })
    }
  }
}

export default ProjectConvertTypeModalComponent
