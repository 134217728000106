import NoticebarComponent from './noticebar.component'
import NoticebarService from './noticebar.service'

const NoticebarModule = angular.module('cdbl.noticebar', [

])
  .service('NoticebarService', NoticebarService)
  .component('cdblNoticebar', NoticebarComponent)
  .name

export default NoticebarModule
