import templateUrl from './scope-doc-sidebar-widget.html'
import './scope-doc-sidebar-widget.scss'

const ProjectScopeDocSidebarWidgetComponent = {
  bindings: {
    project: '<'
  },
  templateUrl,
  controller: class ProjectScopeDocSidebarWidget {
    constructor (UserService, ModalService, Modal, Flash, EventBusService, ProjectScopeDocService, ActivityStreamService, NoticebarService, ProjectService) {
      'ngInject'
      this._identify = 'ProjectScopeDocSidebarWidgetComponent'
      this.UserService = UserService
      this.ModalService = ModalService
      this.ModalLegacy = Modal
      this.FlashLegacy = Flash
      this.EventBusService = EventBusService
      this.ProjectScopeDocService = ProjectScopeDocService
      this.ActivityStreamService = ActivityStreamService
      this.NoticebarService = NoticebarService
      this.ProjectService = ProjectService
    }

    $onInit () {
      this.scopeDoc = null
      // this.template = this.UserService.isClient() ? templateUrlClient : templateUrlExpert

      if (this.isEligable && !this.ProjectService.isInState(this.project, 'refunded')) {
        this.getScopeDoc()
          .then(() => {
            console.log('[ProjectScopeDocSidebarWidgetComponent] > init')

            // Show modal/banner to expert
            if (this.UserService.isExpert() && this.isEligable && !this.scopeDoc) {
              // show modal
              this.ModalLegacy.close() // close legacy modal
              this.openModal()

              // show banner
              this.FlashLegacy.hide() // close legacy flash
              this.NoticebarService.open({
                template: `
                  <span>The client has paid. Please <a class="button-link" ng-click="$ctrl.notice.openScopeDocModal()">ADD YOUR SCOPE</a> now.</span>
                `,
                openScopeDocModal: () => {
                  this.openModal()
                }
              })
            }

            // Show modal to client
            if (this.UserService.isClient() && this.isState('submitted')) {
              this.ModalLegacy.close() // close legacy modal
              this.openModal()
            } else if (this.UserService.isClient() && this.isState('finished')) {
              this.ModalLegacy.close() // close legacy modal
              this.openModal()
            }
          })
      }


      // subscribe on pusher data events
      this.subscriptionCollection = this.EventBusService.createSubscriptionCollection('legacy')
      this.subscriptionCollection.subscribe([
        'scope-doc-submitted',
        'scope-doc-rejected',
        'scope-doc-approved',
        'scope-doc-finished',
        'scope-doc-completed',
        'scope-doc-item-finished',
        'scope-doc-item-unfinished'
      ], $event => {
        this.onRealTimeEventHandler($event)
      })
    }

    $onDestroy () {
      if (this.subscriptionCollection) {
        this.subscriptionCollection.unsubscribeAll()
      }

      this.NoticebarService.close()
      this.ModalService.close()
    }

    getScopeDoc () {
      return this.ProjectScopeDocService.getScopeDoc(this.project.id)
        .then(response => {
          console.log('[ProjectScopeDocSidebarWidgetComponent]', response)
          this.scopeDoc = response // non exisiting scope doc will be returned as null
          return this.scopeDoc
        })
        .catch(err => {
          console.log(err)
        })
    }

    get isWaitingOnUser () {
      return this.ProjectScopeDocService.getStateMappedProp('isWaiting', this.scopeDoc)
    }

    get isEligable () {
      return this.project && this.project.eligibleForScopeDoc
    }

    get canOpenScopeDocModal () {
      return this.ProjectScopeDocService.canOpenScopeDocModal(this.scopeDoc)
    }

    get buttonWidgetText () {
      return this.ProjectScopeDocService.getStateMappedProp('buttonWidget', this.scopeDoc)
    }

    get statusWidgetText () {
      // Exception for refunded project
      if (this.project.state === 'refunded' && this.project.contractor) {
        return 'The project is refunded. You can view the scope for reference.'
      }

      return this.ProjectScopeDocService.getStateMappedProp('statusWidget', this.scopeDoc, this.project)
    }

    isState (state) {
      return this.scopeDoc && this.scopeDoc.state === state
    }

    onRealTimeEventHandler ($event) {
      console.log('[ProjectScopeDocSidebarWidgetComponent] > onRealTimeEventHandler', $event)

      // Execute update only for user who is not an owner of the real time update - those are handled by resolve of endpoint call. Skip updating whole scope doc for item finish/unfinish
      const skipUpdateEvents = ['scope-doc-item-finished', 'scope-doc-item-unfinished']
      if (!skipUpdateEvents.includes($event.pusherEvent) && $event.data.eventOwner && $event.data.eventOwner.id !== this.UserService.user.id) {
        console.log('[ProjectScopeDocSidebarWidgetComponent] > onRealTimeEventHandler > update')
        if (this.isEligable) {
          this.getScopeDoc()
        }
      }

      this.ActivityStreamService.addActivity($event.data)
    }

    onUpdateHandler ($event) {
      console.log('[ProjectScopeDocSidebarWidgetComponent] > onUpdateHandler', $event.scopeDoc)
      this.scopeDoc = $event.scopeDoc

      if (this.NoticebarService.isOpened) {
        this.NoticebarService.close()
      }
    }

    openModal () {
      // Check if scope doc exists if not create new blank one and pass it to modal if user is expert as this means expert will create new scope doc that is kept in memory until saved as drafted on BE side
      // if (!this.scopeDoc && this.UserService.isExpert()) {
      //   // scope doc does not exist on BE side yet so generate it
      //   this.scopeDoc = this.ProjectScopeDocService.generateScopeDoc('blank')
      // }

      // const vm = this
      this.ModalService.open({
        closeOnBackdrop: false,
        closeIcon: true,
        class: 'project-scope-doc-modal cdbl-modal--style-with-dividers cdbl-modal--style-with-sticky-header-and-footer',
        template: `
          <cdbl-project-scope-doc-modal
            project="$ctrl.modal.project"
            scope-doc="$ctrl.modal.scopeDoc"
            on-update="$ctrl.modal.onUpdateHandler($event)">
          </cdbl-project-scope-doc-modal>`,
        project: this.project,
        scopeDoc: this.scopeDoc,
        onUpdateHandler: this.onUpdateHandler.bind(this)
      })
    }
  }
}

export default ProjectScopeDocSidebarWidgetComponent
