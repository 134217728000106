const UrlProtocolPrefixerFilter = function ($filter) {
  'ngInject'
  return function (url) {
    // Handle only string values
    if (typeof url === 'string') {
      const pattern = /^(http[s]?:\/\/)/i

      if (!pattern.test(url)) {
        url = `http://${url}`
      }
    }

    return url
  }
}
export default UrlProtocolPrefixerFilter
