import templateUrl from './project-cancel-button.html'
import './project-cancel-button.scss'

const ProjectCancelButtonComponent = {
  bindings: {
    project: '<',
    onCanceled: '&'
  },
  templateUrl,
  controller: class ProjectCancelButtonComponent {
    constructor (ModalService) {
      'ngInject'
      this._identify = 'ProjectCancelButtonComponent'
      this.ModalService = ModalService
    }

    $onInit () {

    }

    openCancelProjectModal ($event) {
      $event.preventDefault()
      $event.stopPropagation()

      this.ModalService.open({
        closeOnBackdrop: false,
        closeIcon: false,
        layout: 'center',
        class: 'project-cancel-modal',
        template: `<cdbl-project-cancel-modal></cdbl-project-cancel-modal>`,
        project: this.project,
        onCanceled: this.onCanceled
      })
    }
  }
}

export default ProjectCancelButtonComponent
