import templateUrl from './payments-paypal-button.html'
import './payments-paypal-button.scss'



import { loadScript } from '@paypal/paypal-js'
const PaymentsPaypalButtonComponent = {
  bindings: {
    task: '<',
    onStarted: '&',
    onCompleted: '&',
    onCanceled: '&',
    onError: '&'
  },
  templateUrl,
  controller: class PaymentsPaypalButton {
    constructor ($element, $httpParamSerializer, $http, Configuration, AuthService, LoggingService) {
      'ngInject'
      this._identify = 'PaymentsPaypalButtonComponent'
      this.$element = $element
      this.$httpParamSerializer = $httpParamSerializer
      this.Configuration = Configuration
      this.AuthService = AuthService
      this.LoggingService = LoggingService
      this.paypal = null
      this.$http = $http
    }

    async loadSdk () {
      try {
        this.paypal = await loadScript({ clientId: this.Configuration.paypal.clientId, currency: 'USD', 'disable-funding': 'card' })
        console.log('Paypal > loaded ', this.paypal)
      } catch (error) {
        console.error('failed to load the PayPal JS SDK script', error)
      }
    }

    async $onInit () {
      const vm = this
      await this.loadSdk()

      // this.paypal.Buttons({
      //   // Order is created on the server and the order id is returned
      //   createOrder () {
      //     return this.createOrder()
      //   },
      //   // Finalize the transaction on the server after payer approval
      //   onApprove (data) {
      //     return this.onApprove(data)
      //   }
      // }).render('#cdbl-paypal-button-container')

      // const paypalEnv = this.Configuration.paypal.env
      const style = {
        size: 'medium',
        color: 'white',
        shape: 'rect',
      }

      // TODO: clean up below block - probably left due to merge, no need to have const declarations
      const IS_SUBTASK = !!this.task.parentTask
      const SUB_TASK_ID = this.task.id
      const TASK_ID = IS_SUBTASK ? this.task.parentTask.id : this.task.id

      // this is
      this.isSubtask = IS_SUBTASK
      this.subtaskId = SUB_TASK_ID
      this.taskId = TASK_ID

      // const cdblPaymentRootUrl = IS_SUBTASK
      //   ? `${this.Configuration.apiUrl}/tasks/${TASK_ID}/sub_tasks/${SUB_TASK_ID}`
      //   : `${this.Configuration.apiUrl}/tasks/${TASK_ID}`

      const gid = this.task.gid

      // console.log('[PaymentsPaypalButtonComponent] > cdblPaymentRootUrl', cdblPaymentRootUrl)

      // Payment
      const createOrder = () => {
        console.log('[PaymentsPaypalButtonComponent] > createOrder > gid', gid)
        // const queryString = vm.$httpParamSerializer({
        //   auth_token: vm.AuthService.getAuthToken(),
        //   type: 'paypal'
        // })
        // const cdblPaymentUrl = `${cdblPaymentRootUrl}/payments?${queryString}`
        vm.LoggingService.logInfo('payments.paypal', 'createOrder', {
          gid,
          taskId: vm.taskId,
          subtaskId: vm.subtaskId,
          state: vm.task?.state,
          instanceUUID: vm?.Configuration?.appInstanceUUID
        })
        return this.$http
          .post(`${this.Configuration.apiUrl}/payments/paypal/orders`, { gid })
          .then(response => {
            const order = response.data
            console.log('[PaymentsPaypalButtonComponent] > createOrder > order', order)
            vm.LoggingService.logInfo('payments.paypal', 'createOrderAfterBE', {
              gid,
              taskId: vm.taskId,
              subtaskId: vm.subtaskId,
              state: vm.task?.state,
              orderId: order?.id,
              instanceUUID: vm?.Configuration?.appInstanceUUID
            })

            // Trigger callback if defined
            if (vm.onStarted) {
              vm.onStarted({ event: { paymentId: order?.id } })
            }

            // Return res.id from the response
            // return res.paymentId
            return order?.id
          })
          .catch(err => {
            console.log('[PaymentsPaypalButtonComponent] > createOrder > err', err)

            const error = {
              data: {
                taskId: vm.taskId,
                subtaskId: vm.isSubtask ? vm.subtaskId : null,
                gatewayError: err
              },
              type: 'payment setup error'
            }

            vm.LoggingService.logError('payments.paypal', error.type, error.data)

            if (vm.onError) {
              vm.onError({
                event: {
                  eventName: 'failed',
                  error: error
                }
              })
            }
            throw err
          })
      }

      // Authorize
      const onApprove = (data) => {
        // console.log('[PaymentsPaypalButtonComponent] > onApprove')
        // const queryString = vm.$httpParamSerializer({
        //   auth_token: vm.AuthService.getAuthToken()
        // })
        // const cdblAuthorizeUrl = `${cdblPaymentRootUrl}/payments/paypal_authorized?${queryString}`
        // const authorizePayload = {
        //   payer_id: data.payerID,
        //   token: data.paymentToken
        // }

        console.log('[PaymentsPaypalButtonComponent] > onApprove', { gid, orderId: data.orderID })
        vm.LoggingService.logInfo('payments.paypal', 'onApprove', {
          gid,
          taskId: vm.taskId,
          subtaskId: vm.subtaskId,
          state: vm.task?.state,
          paypalOrderId: data.orderID,
          paypalData: data,
          instanceUUID: vm?.Configuration?.appInstanceUUID
        })

        return this.$http
          .put(`${this.Configuration.apiUrl}/payments/paypal/orders`, { gid, orderId: data.orderID })
          .then(response => {
            const transaction = response.data.purchaseUnits[0].payments.captures[0]
            // Show the buyer a confirmation message.
            console.log('[PaymentsPaypalButtonComponent] > onApprove > transaction', transaction)
            vm.LoggingService.logInfo('payments.paypal', 'onApproveAfterBE', {
              gid,
              taskId: vm.taskId,
              subtaskId: vm.subtaskId,
              state: vm.task?.state,
              paypalOrderId: data.orderID,
              paypalData: data,
              transactionId: transaction.id,
              instanceUUID: vm?.Configuration?.appInstanceUUID
            })

            // Trigger callback if defined
            if (vm.onCompleted) {
              vm.onCompleted({ event: { transactionId: transaction.id } })
            }
          })
          .catch(err => {
            console.log('[PaymentsPaypalButtonComponent] > onApprove > err', err)

            const error = {
              data: {
                taskId: vm.taskId,
                subtaskId: vm.isSubtask ? vm.subtaskId : null,
                gatewayError: err
              },
              type: 'payment authorization error'
            }

            vm.LoggingService.logError('payments.paypal', error.type, error.data)

            if (vm.onError) {
              vm.onError({
                event: {
                  eventName: 'failed',
                  error: error
                }
              })
            }
            throw err
          })
      }

      // On Error - handle errors
      const onError = err => {
        console.log('[PaymentsPaypalButtonComponent] > onError > err', err)
        const error = {
          data: {
            taskId: vm.taskId,
            subtaskId: vm.isSubtask ? vm.subtaskId : null,
            gatewayError: err
          },
          type: 'error'
        }

        vm.LoggingService.logInfo('payments.paypal', error.type, error.data)

        // Trigger callback if defined
        if (vm.onError) {
          vm.onError({
            event: {
              eventName: 'failed',
              error: error
            }
          })
        }
      }

      // On Cancel - // Manage cancelation
      const onCancel = data => {
        console.log('[PaymentsPaypalButtonComponent] > onCanceled', data)
        vm.LoggingService.logInfo('payments.paypal', 'onCancel', {
          gid,
          taskId: vm.taskId,
          subtaskId: vm.subtaskId,
          state: vm.task?.state,
          paypalData: data,
          instanceUUID: vm?.Configuration?.appInstanceUUID
        })

        // Trigger callback if defined
        if (vm.onCanceled) {
          vm.onCanceled({ event: { data: data } })
        }
      }


      // this.button = {
      //   locale: 'en_US',

      //   env: paypalEnv,

      //   style: style,

      //   // Set up the payment
      //   payment: payment,

      //   // Execute the payment
      //   onAuthorize: onAuthorize,

      //   // Pass a function to be called when the customer cancels the payment
      //   onCancel: onCancel,

      //   // Handle errors
      //   onError: onError
      // }

      console.log('Paypal > ', this.paypal)
      if (this.paypal) {
        this.paypal.Buttons({
          style,
          onCancel,
          onError,
          createOrder,
          onApprove
        }).render('#cdbl-paypal-button-container')
      }
    }
  }
}

export default PaymentsPaypalButtonComponent
