import EarningsCertificateModalComponent from './earnings-certificate-modal.component'
import EarningCertificateService from './earning-certificate.service'
import CertificateItemModule from './certificate-item'

const EarningsCertificateModalModule = angular.module('cdbl.earnings-certificate-modal', [
  CertificateItemModule
])
  .service('EarningCertificateService', EarningCertificateService)
  .component('cdblEarningsCertificateModal', EarningsCertificateModalComponent)
  .name

export default EarningsCertificateModalModule
