import templateUrl from './section.html'
import './section.scss'

const SectionComponent = {
  bindings: {
    isCollapsed: '<?'
  },
  templateUrl,
  transclude: {
    title: '?sectionTitle'
  },
  controller: class SectionComponent {
    constructor ($attrs) {
      'ngInject'
      this._identify = 'SectionComponent'
      this.$attrs = $attrs
    }

    $onInit () {
      this.showCollapseToggle = typeof this.$attrs.isCollapsed !== 'undefined'
    }
  }
}

export default SectionComponent
