/**
 *
 * requireAll(ctx)
 *
 * Helper for importing all assests from folder
 * Use: requireAll(require.context('src/assets/icons', true, /\.(svg)$/))
 *
 */

const requireAll = ctx => {
  return ctx.keys().map(ctx)
}

export default requireAll
