(function () {
  'use strict';
  //  DEPRECATED
  //  SINCE 8.5.2017
  // function escapeHTML(html) {
  //   if (html.indexOf('```') === -1) {
  //     return html.escape();
  //   }

  //   return html.split(/(```)/g)
  //     .reduce(function (previous, current, idx) {
  //       if (idx === 1) {
  //         previous = previous.escape();
  //       }

  //       return idx % 4 === 0 ? previous + current.escape() : previous + current;
  //     });
  // }

  //  DEPRECATED
  // /*
  //   If a href doesn't start with http:// or https://, insert http:// before
  //   XSS vulnerability: for example, markdown converts [notmalicious](javascript:window.onerror=alert;throw%20document.cookie) to
  //   <a href="javascript:window.onerror=alert;throw%20document.cookie">notmalicious</a>
  //   Anyway, all links after markdown is applied, should start with either http:// or https://
  // */
  // function preventXSS(html) {
  //   var delimiter = '<a href="';

  //   return html.split(delimiter).reduce(function (prev, curr, idx, arr) {
  //     if (idx > 0 && (curr.substring(0, 7) !== 'http://' || curr.substring(0, 8) !== 'https://')) {
  //       prev.push('http://');
  //     }
  //     prev.push(curr);
  //     if (idx < arr.length - 1) {
  //       prev.push(delimiter);
  //     }
  //     return prev;
  //   }, []).join('');
  // }

  function processLinks(html) {
    var reg = /^((ftp|http|https):\/\/)/i;
    var delimiter = '<a href="';
    var protocol = 'http://';

    return html.split(delimiter).reduce(function (prev, curr, idx, arr) {
      // console.log(prev, curr, idx, arr);

      if (idx > 0) {
        if (!reg.test(curr)) {
          // add missing protocol
          prev.push(protocol);
        }
      }
      prev.push(curr);
      if (idx < arr.length - 1) {
        prev.push(delimiter);
      }
      return prev;
    }, []).join('');
  }

  function markup() {
    marked.setOptions({breaks: true});
    return function (html) {
      if (html === null) {
        return ''
      }
      /* preventXSS - we have to replace all malicious code after markedown converts to html! */
      // return preventXSS(marked(escapeHTML(html)));

      // XSS vulnerability was fixed with marked 0.3.6 and above function preventXSS breaks links
      // return processLinks(marked(escapeHTML(html)));
      // Marked has a param senitize that does html escape
      return processLinks(marked(html, {sanitize: true}));
    };
  }

  app.filter('markup', markup);
})();
