import templateUrlClient from './project-last-activity-client.html'
import templateUrlExpert from './project-last-activity-expert.html'
import './project-last-activity.scss'

const ProjectLastActivityComponent = {
  bindings: {
    project: '<'
  },
  template: `<div ng-include="$ctrl.templateUrl"></div>`,
  controller: class ProjectLastActivity {
    constructor ($stateParams, UserService) {
      'ngInject'
      this._identify = 'ProjectLastActivity'
      this.UserService = UserService
    }

    $onInit () {
      this.templateUrl = this.UserService.isClient() ? templateUrlClient : templateUrlExpert
    }
  }
}

export default ProjectLastActivityComponent
