import templateUrl from './checkbox.html'
import './checkbox.scss'

const CheckboxComponent = {
  bindings: {},
  require: {
    fieldCtrl: '?^cdblFieldContainer',
    ngModel: '?ngModel'
  },
  transclude: true,
  templateUrl,
  controller: class CheckboxComponent {
    constructor ($element, $attrs, $mdConstant) {
      'ngInject'
      this._identify = 'CheckboxComponent'
      this.$element = $element
      this.$attrs = $attrs
      this.$mdConstant = $mdConstant
    }

    $onInit () {
      this._fieldElementCtrl = false
      console.log('[CheckboxComponent]', this.$attrs)

      this.$attrs.$set('tabindex', this.$attrs.tabindex || '0')
      this.$attrs.$set('type', 'checkbox')
      this.$attrs.$set('role', this.$attrs.type)

      if (this.fieldCtrl) {
        this.fieldCtrl.setFieldElementCtrl({
          ngModel: this.ngModel,
          $element: this.$element,
          $attrs: this.$attrs
        })
      }

      this.$element.children().on('focus', function () {
        this.$element.focus()
      })

      this.$element.on('click', this.onClickHandler.bind(this))
        .on('keypress', this.onKeypressHandler.bind(this))

      this.ngModel.$render = () => {
        this.value = this.ngModel.$viewValue
      }
    }

    $onDestroy () {
      if (this.fieldCtrl) {
        this.fieldCtrl.setFieldElementCtrl(null)
      }
    }

    setFieldElementCtrl (fieldElementCtrl) {
      console.log('[CheckboxComponent] > setFieldElementCtrl', fieldElementCtrl)
      this._fieldElementCtrl = fieldElementCtrl
    }

    get isChecked () {
      return this.value
      // return this._fieldElementCtrl && this._fieldElementCtrl.ngModel.$viewValue
    }

    onClickHandler (evt) {
      if (this.$attrs.disabled || evt.target.tagName === 'A') {
        evt.stopImmediatePropagation()
        return
      }
      this.value = !this.value
      this.ngModel.$setViewValue(this.value)
      this.ngModel.$setTouched()
    }

    onKeypressHandler (evt) {
      const keyCode = evt.which || evt.keyCode

      evt.preventDefault()

      switch (keyCode) {
      case this.$mdConstant.KEY_CODE.SPACE:
        this.onClickHandler(evt)
        break
      case this.$mdConstant.KEY_CODE.ENTER:
        this.onClickHandler(evt)
        break
      }
    }
  }
}

export default CheckboxComponent
