import templateUrl from './header-popover.html'
import './header-popover.scss'

const HeaderPopoverComponent = {
  bindings: {},
  templateUrl,
  controller: class HeaderPopoverComponent {
    constructor (AuthService, UserService) {
      'ngInject'
      this._identify = 'HeaderPopoverComponent'
      this.AuthService = AuthService
      this.UserService = UserService
    }
  }
}

export default HeaderPopoverComponent
