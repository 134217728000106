(function () {
  'use strict'

  function VaultApi ($http, Configuration) {
    function createVault (taskId, encData, sharedAccesses) {
      const payload = {
        taskId: taskId,
        encData: encData,
        sharedAccesses: sharedAccesses
      }

      return $http
        .post(`${Configuration.apiUrl}/tasks/${taskId}/vault`, payload)
    }

    function updateVault (taskId, encData, sharedAccesses) {
      const payload = {
        taskId: taskId,
        encData: encData,
        sharedAccesses: sharedAccesses
      }

      return $http
        .put(`${Configuration.apiUrl}/tasks/${taskId}/vault`, payload)
    }

    function deleteVault (taskId) {
      return $http
        .delete(`${Configuration.apiUrl}/tasks/${taskId}/vault`)
    }

    return {
      createVault: createVault,
      updateVault: updateVault,
      deleteVault: deleteVault
    }
  }

  app.service('VaultApi', ['$http', 'Configuration', VaultApi])
})()
