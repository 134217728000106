import templateUrl from './client-form.html'
import './client-form.scss'
import { urlPattern } from '../project-presets.config'

const ProjectPresetsClientFormComponent = {
  bindings: {
    preset: '<',
    isPreview: '<',
    showAuthForm: '<',
    title: '@',
    onProjectPublish: '&'
  },
  templateUrl,
  controller: class ProjectPresetsClientForm {
    constructor (EventEmitter, $timeout, $http, Configuration) {
      'ngInject'
      this._identify = 'ProjectPresetsClientFormComponent'
      this.EventEmitter = EventEmitter
      this.$timeout = $timeout
      this.$http = $http
      this.Configuration = Configuration
    }

    $onInit () {
      console.log('clientForm', this.clientForm)
      this.urlPattern = urlPattern
      this.authData = {
        authMode: 'register',
        firstName: undefined,
        lastName: undefined,
        email: undefined,
        password: undefined,
        // passwordRepeat: undefined, // TODO: TBD
        toc: false
      }
      this.isProcessing = false
      this.error = null
      this.wasProjectPosted = false
      this.typingTimer = null
      this.errorMessages = {
        // 'project_preset_not_found': 'Form not found.',
        // 'project_preset_expired': 'This link has expired. Please, contact the person onboarding you to Codeable for a new link.',
        // 'project_preset_posted_already': 'This project has already been posted. Please, contact the person onboarding you to Codeable for a new link.',
        // 'project_preset_anonymized_already': 'This project has already been posted. Please, contact the person onboarding you to Codeable for a new link.',
      }
      // TODO: remove demo data
      // this.fillDemoData()

      // this.datasets = {
      //   projectType: [{ id: 'fix', name: 'Fixing' }, { id: 'install', name: 'Installing' }, { id: 'other', name: 'Other' }],
      //   projectSubject: [{ id: 'theme', name: 'Theme' }, { id: 'plugin', name: 'Plugin' }, { id: 'other', name: 'Other' }],
      //   urgency: [{ id: 1, name: 'low' }, { id: 2, name: 'medium' }, { id: 3, name: 'high' }],
      //   complexity: [{ id: 'low', name: 'low' }, { id: 'medium', name: 'medium' }, { id: 'high', name: 'high' }],
      //   expireDurationDays: [1, 3, 7, 14, 30], // min: 1, max: 30, integer
      //   preferredExperts: [{ id: 1, fullName: 'Dave' }, { id: 2, fullName: 'Nick' }, { id: 3, fullName: 'John' }, { id: 4, fullName: 'Donny' }, { id: 5, fullName: 'Jesus' }, { id: 6, fullName: 'Lucifer' }]
      // }
    }

    get canPublish () {
      // temporary fix for redirect delay due to tech debt in workroom state
      // TODO: remove this after route state for workroom is changed so that it does not wait for laoding of data and delaying redirect
      if (this.wasProjectPosted) {
        return false
      }

      // For not logged-in users
      if (this.showAuthForm) {
        return this.authForm.$valid && this.clientForm.$valid && !this.isProcessing
      }

      // For logged-in users
      return this.clientForm.$valid && !this.isProcessing
    }


    publishProject () {
      this.error = null
      this.isProcessing = true
      if (typeof this.onProjectPublish === 'function') {
        this.onProjectPublish(this.EventEmitter({ presetData: this.preset, authData: this.authData }))
          .then(response => {
            this.isProcessing = false
            this.wasProjectPosted = true
            console.log('[PresetsClientForm] > onProjectPublish > response', response)
          })
          .catch(err => {
            console.log('[PresetsClientForm] > onProjectPublish > err', err)
            this.error = err
            this.isProcessing = false
          })
          // .finally(() => {
          //   console.log('[PresetsClientForm] > onProjectPublish > finally')
          //   this.isProcessing = false
          // })
      }
    }

    fillDemoData () {
      this.authData.authMode = 'register'
      this.authData.firstName = 'Client'
      this.authData.lastName = 'Client'
      this.authData.email = 'jan+client@codeable.io'
      this.authData.password = 'demo0101'
      this.authData.toc = true

      // this.error = {
      //   'data': {
      //     'error': 'not_postable',
      //     'message': 'asdfasdf'
      //   },
      //   'status': 422
      // }
    }

    toggleAuthMode () {
      this.authData.authMode = this.authData.authMode === 'register' ? 'login' : 'register'
    }

    canEdit (field) {
      return !field.locked
    }

    isVisible (field) {
      const isEmpty = typeof field.value === 'undefined' || field.value === null || field.value === '' || (Array.isArray(field.value) && field.value.length === 0)
      return !field.hidden && (!isEmpty || !field.locked)
      // !field.hidden && ((typeof field.value !== 'undefined' && field.value !== '') || field.locked === false)
    }

    renderField (fieldName) {
      const value = this.preset?.fields[fieldName]?.value

      switch (fieldName) {
      case 'projectType':
        return this.preset.resources.taskProjectTypes.data.find(item => item.id === value)?.name
      case 'projectSubject':
        return this.preset.resources.taskProjectSubjects.data[this.preset.fields.projectType.value].find(item => item.id === value)?.name
      case 'complexity':
        return this.preset.resources.taskProjectComplexity.data.find(item => item.id === value)?.name
      case 'urgency':
        return this.preset.resources.taskProjectUrgency.data.find(item => item.id === value)?.name
      case 'preferredExperts':
        // this.datasets.preferredExperts.map(item => item.fullName).join(', ')
        return value
          .map(expertId => this.preset.resources.taskExpertsPreferrables.data.find(de => de.id === expertId))
          .map(expert => expert?.fullName).join(', ')
      case 'preferredExpertsPublishForAll':
        return value ? 'Yes' : 'No'
      }

      return value
    }

    getValidURL (url) {
      this.$http.post(`${this.Configuration.apiUrl}/url_protocol_check`, { url })
        .then(response => {
          const url = response.data.url
          if (url) {
            this.formData.websiteUrl = url
          }
        })
        .catch(err => {
          console.log('Error trying to get url', err)
        })
    }

    onInputChange () {
      // Clear the previous typing timer
      this.$timeout.cancel(this.typingTimer)

      // Set a new typing timer
      this.typingTimer = this.$timeout(() => {
        if (!this.definerForm.websiteUrl.$error || Object.keys(this.definerForm.websiteUrl.$error).length === 0) {
          this.getValidURL(this.formData.websiteUrl)
        }
      }, 500) // Delay in milliseconds
    }
  }
}

export default ProjectPresetsClientFormComponent
