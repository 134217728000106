import templateUrl from './persona-survey-modal.html'
import './persona-survey-modal.scss'

const PersonaSurveyModalComponent = {
  bindings: {
  },
  templateUrl,
  require: {
    modalCtrl: '^^cdblModal'
  },
  controller: class PersonaSurveyModal {
    constructor ($q, $location, UserService, FeedbackService, ModalService) {
      'ngInject'
      this.UserService = UserService
      this.$q = $q
      this.ModalService = ModalService
      this.$location = $location
      this.FeedbackService = FeedbackService


      // Segment question - hardcoded in FE
      // more details: https://trello.com/c/su98QOGq/934-add-in-how-did-you-find-us-to-modal
      this.segment = {
        selected: null,
        other: ''
      }
      this.segmentOptions = [
        {
          id: 'wp_agency',
          label: `I'm outsourcing work for my client projects.`
        },
        {
          id: 'business_owner',
          label: `I'm outsourcing work for my own/company site(s).`
        },
        {
          id: 'wp_freelancer',
          label: `I'm a freelance developer/designer needing assistance.`
        }
      ]
      this.segmentOptions = this.segmentOptions.sort(() => (Math.random() - 0.5))
    }

    $onInit () {
      // Get other questions from feedback
      this.FeedbackService
        .getQuestions('persona_survey')
        .then(response => {
          console.log('PersonaSurveyCtrl > getQuestions', response)
          this.feedbackQuestions = response
        }, error => {
          console.log('PersonaSurveyCtrl > getQuestions > error', error)
        })
    }

    // Check if form can be submitted otherwise disable submit button
    disableSubmit ($form) {
      if (!this.feedbackQuestions) {
        return true // if questions are unavailable form is invalid
      }

      let unansweredSegment = !this.segment.selected
      let unansweredQuestions = this.feedbackQuestions.filter(question => typeof question.value === 'undefined')
      return $form.$invalid || unansweredQuestions.length > 0 || unansweredSegment
    };

    // Submit form for both segment and feedback endpoints
    submitFeedback () {
      const segmentPayload = this.segment.selected === 'other' ? this.segment.other : this.segment.selected
      let promises = [
        this.FeedbackService.submitAllFeedback(this.feedbackQuestions),
        this.UserService.updatePersonaSurvey(segmentPayload)
      ]

      // resolve all promisses then expecute callback
      this.$q.all(promises)
        .then(response => {
          console.log('PersonaSurveyCtrl > submitFeedback > response', response)
          this.UserService.updateUser({
            trackedSegmentByLink: segmentPayload
          })

          // remove "intro" query param from url
          this.$location.search('intro', null).replace()
          this.ModalService.close()
        }, error => {
          console.log('PersonaSurveyCtrl > submitFeedback > error', error)
        })
    };
  }
}

export default PersonaSurveyModalComponent
