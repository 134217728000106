import templateUrl from './settings-comms-preferences.html'
import './settings-comms-preferences.scss'

const SettingsCommsPreferencesComponent = {
  bindings: {},
  templateUrl,
  controller: class SettingsCommsPreferencesComponent {
    constructor ($timeout, SettingsService, UIService) {
      'ngInject'
      this._identify = 'SettingsCommsPreferencesComponent'
      this.$timeout = $timeout
      this.SettingsService = SettingsService
      this.UIService = UIService
    }

    async $onInit () {
      this.formData = {}

      this.isLoading = true
      await this.SettingsService.getCommsPreferences()
        .then(commsPreferences => {
          this.formData = { ...this.formData, ...commsPreferences }
        })

      this.isLoading = false
      console.log('isLoading', this.isLoading)
      this.$timeout()
    }


    async saveSettings () {
      this.isProcessing = true
      await this.SettingsService.updateCommsPreferences(this.formData)
        .catch(err => {
          console.log(err)
        })

      this.isProcessing = false
      this.commsForm.$setPristine()
      this.$timeout()
    }
  }
}

export default SettingsCommsPreferencesComponent
