import templateUrl from './expert-profile-reviews-edit-modal.html'
import './expert-profile-reviews-edit-modal.scss'

const ExpertProfileReviewsEditModalComponent = {
  bindings: {
    favoriteReviews: '<',
    onSave: '&?'
  },
  require: {
    modalCtrl: '^^cdblModal'
  },
  templateUrl,
  controller: class ExpertProfileReviewsEditModalComponent {
    constructor ($timeout, $filter, EventEmitter, ExpertProfileService, PaginationService) {
      'ngInject'
      this._identify = 'ExpertProfileReviewsEditModalComponent'
      this.$timeout = $timeout
      this.$filter = $filter
      this.EventEmitter = EventEmitter
      this.ExpertProfileService = ExpertProfileService
      this.PaginationService = PaginationService
    }

    $onInit () {
      this.MAX_SELECTED_REVIEWS = 3
      this.availableReviews = []
      this.selectedReviews = []
      if (this.favoriteReviews.items.length) {
        this.favoriteReviews.items.forEach(review => {
          this.selectedReviews.push(this.preprocessSelectedReview(review))
        })
      }

      this.reviewsPagination = this.PaginationService.init({
        itemsPerPage: 10,
        mode: 'server'
      })

      this.loadReviews()
    }

    get canUpdateReviews () {
      return this.favoriteReviews && this.favoriteReviews.actions && this.favoriteReviews.actions.update
    }

    canToggleSelection (review) {
      if (review.selected) {
        return true
      } else if (this.selectedReviews.length < this.MAX_SELECTED_REVIEWS) {
        return true
      }

      return false
    }

    saveEdit () {
      this.isProcessing = true
      this.ExpertProfileService.updateFavoriteReviews(this.selectedReviews, this.favoriteReviews)
        .then(response => {
          if (typeof this.onSave === 'function') {
            this.onSave(this.EventEmitter({ favoriteReviews: response })) // update parent component
          }
          this.isProcessing = false
          this.modalCtrl.close()
        })
        .catch(err => {
          console.log(err)
        })
    }

    cancelEdit () {
      this.modalCtrl.close()
    }

    selectReview (review = null) {
      console.log('selectReview', this.selectedReviews.length)
      // check if review can be added
      if (review && this.selectedReviews.length < this.MAX_SELECTED_REVIEWS && !this.selectedReviews.find(r => r.id === review.id)) {
        // modify review content for selected review card and add it to the list
        this.selectedReviews.push(this.preprocessSelectedReview(review))
      }
    }

    preprocessSelectedReview (review) {
      const processedSelectedReview = { ...review }
      processedSelectedReview.projectTitle = this.$filter('truncateFormat')(processedSelectedReview.projectTitle, 25, { dots: true, splitByWords: true })
      processedSelectedReview.comment = this.$filter('truncateFormat')(processedSelectedReview.comment, 45, { dots: true, splitByWords: true })
      processedSelectedReview.selected = true
      return processedSelectedReview
    }

    removeSelectedReview (review) {
      const index = this.selectedReviews.findIndex(r => r.id === review.id)
      if (index > -1) {
        this.selectedReviews.splice(index, 1)
      }
    }

    toggleReviewSelection (review) {
      if (this.isSelected(review)) {
        this.removeSelectedReview(review)
      } else {
        this.selectReview(review)
      }
      this.updateListedReviewsState()
    }

    loadReviews () {
      this.isLoadingReviews = true
      this.ExpertProfileService.getAvailableReviews(this.favoriteReviews, this.reviewsPagination)
        .then(response => {
          this.availableReviews = response
          this.updateListedReviewsState()
        })
        .finally(() => {
          this.isLoadingReviews = false
        })
    }

    isSelected (review) {
      return Boolean(this.selectedReviews.find(sr => sr.id === review.id))
    }

    updateListedReviewsState () {
      this.availableReviews.forEach(r => {
        r.selected = this.isSelected(r)
      })
    }

    onReviewsPageChangeHandler (page) {
      this.loadReviews() // pagination is handeled in service
    }
  }
}

export default ExpertProfileReviewsEditModalComponent
