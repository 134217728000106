import Player from '@vimeo/player'
import templateUrl from './submission-instruction-modal.html'
import './submission-instruction-modal.scss'

const VIDEO_START_SECONDS = 64

const SubmissionInstructionModalComponent = {
  require: {
    modalCtrl: '^^cdblModal'
  },
  templateUrl,
  controller: class SubmissionInstructionModal {
    constructor (SegmentAnalytics) {
      'ngInject'
      this._identify = 'SubmissionInstructionModalComponent'
      this.SegmentAnalytics = SegmentAnalytics
      this.activeVideo = false
      this.isVideoVisible = false
      this.player = null
    }

    $onInit () {
      const options = {
        id: 236830246,
        width: 640,
        loop: false
      }
      const element = 'vimeo-video'
      this.player = new Player(element, options)
      this.player.setCurrentTime(VIDEO_START_SECONDS).then(seconds => {
        console.log('[SubmissionInstructionModalComponent] > player.setCurrentTime > success', seconds)
        this.addEventListeners('posting')
      }).catch(error => {
        console.log('[SubmissionInstructionModalComponent] > player.setCurrentTime > error', error)
        this.addEventListeners('posting')
      })
    }

    addEventListeners (videoId) {
      this.player.on('play', evt => {
        this.SegmentAnalytics.trackVimeoVideo(videoId, 'play', evt)
      })
      this.player.on('pause', evt => {
        this.SegmentAnalytics.trackVimeoVideo(videoId, 'pause', evt)
      })
      this.player.on('ended', evt => {
        this.SegmentAnalytics.trackVimeoVideo(videoId, 'ended', evt)
      })
      this.player.on('seeked', evt => {
        this.SegmentAnalytics.trackVimeoVideo(videoId, 'seeked', evt)
      })
    }
    removeEventListeners () {
      this.player.off('play')
      this.player.off('pause')
      this.player.off('ended')
      this.player.off('seeked')
    }
  }
}

export default SubmissionInstructionModalComponent
