import AttachmentsUploadComponent from './attachments-upload.component'

import AttachmentsUploadQueueItemModule from './queue-item'

const AttachmentsUploadModule = angular.module('cdbl.attachments.attachments-upload', [
  AttachmentsUploadQueueItemModule
])
  .component('cdblAttachmentsUpload', AttachmentsUploadComponent)
  .name

export default AttachmentsUploadModule
