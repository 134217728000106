import SubmissionStepOneVariantComponent from './submission-step-one-variant.component'
import SubmissionDraftService from './submission-draft.service'
import SubmissionStepHeaderSubjectFilter from './submission-step-header-subject.filter'

const SubmissionStepOneVariantModule = angular.module('cdbl.submission-step-one-variant', [

])
  .component('cdblSubmissionStepOneVariant', SubmissionStepOneVariantComponent)
  .service('SubmissionDraftService', SubmissionDraftService)
  .filter('submissionStepHeaderSubjectFilter', SubmissionStepHeaderSubjectFilter)
  .name

export default SubmissionStepOneVariantModule
