import templateUrl from './expert-profile-portfolio-edit-modal.html'
import './expert-profile-portfolio-edit-modal.scss'

import portfolioConfig from './expert-profile-portfolio-edit-modal.config.js'

const ExpertProfilePortfolioEditModalComponent = {
  bindings: {
    portfolioItem: '<?',
    portfolio: '<?',
    onSave: '&?'
  },
  require: {
    modalCtrl: '^^cdblModal'
  },
  templateUrl,
  controller: class ExpertProfilePortfolioEditModalComponent {
    constructor ($timeout, EventEmitter, ExpertProfileService, $http, Configuration) {
      'ngInject'
      this._identify = 'ExpertProfilePortfolioEditModalComponent'
      this.$timeout = $timeout
      this.EventEmitter = EventEmitter
      this.ExpertProfileService = ExpertProfileService
      this.$http = $http
      this.Configuration = Configuration
    }

    $onInit () {
      this.formData = {
        title: '',
        description: '',
        siteUrl: '',
        imageList: []
      }

      this.fileSelectorConfig = {
        preview: true, // only for images
        previewWidth: 200,
        previewHeight: 150,
        showRemoveIcon: true,
        accept: 'image/png, image/jpeg',
        maxSize: 2 * 1024 * 1e+3, // max 2mb,
      }

      this.fieldsConfig = portfolioConfig.portfolioFieldsConfig
      this.urlPattern = new RegExp('^(http[s]?:\\/\\/){0,1}(www\\.){0,1}[a-zA-Z0-9\\.\\-]+\\.[a-zA-Z]{2,15}[\\.]{0,1}(\\/.*)?(\\?.*)?$')

      if (typeof this.portfolioItem === 'object') {
        this.formData = { ...this.formData, ...this.portfolioItem } // TODO: manage precise mappings when BE structure will be known
      }
    }

    get isFeaturedImageRequired () {
      return !(this.portfolioItem && this.portfolioItem.featuredImage)
    }
    get canShowPreviouslyUploadedImage () {
      if (this.formData.imageList && this.formData.imageList.length > 0) {
        return false
      } else if (this.portfolioItem && this.portfolioItem.featuredImage) {
        return true
      }

      return false
    }

    // helper functions for field property retreival TODO move to service
    fieldProp (fieldName, propName) {
      const field = this.fieldsConfig[fieldName]
      if (field && field[propName]) {
        return field[propName]
      }
      return null
    }

    fieldLabel (fieldName) {
      return this.fieldProp(fieldName, 'label')
    }
    fieldHelper (fieldName) {
      return this.fieldProp(fieldName, 'helper')
    }
    fieldDescription (fieldName) {
      return this.fieldProp(fieldName, 'description')
    }
    fieldPlaceholder (fieldName) {
      return this.fieldProp(fieldName, 'placeholder')
    }
    fieldValidation (fieldName) {
      return this.fieldProp(fieldName, 'validation')
    }
    // ---

    // onSelectedFileRemovedHandler ($event) {
    //   this.formData.imageList = []
    // }

    removeExistingImage () {
      delete this.portfolioItem.featuredImage
    }

    savePortfolioItem () {
      this.serverError = null
      this.isProcessing = true

      this.$http.post(`${this.Configuration.apiUrl}/url_protocol_check`, { url: this.formData.siteUrl })
        .then(response => {
          const url = response.data.url
          if (url) {
            this.formData.siteUrl = url
          }

          this.ExpertProfileService.savePortfolioItem(this.formData, this.portfolioItem, this.portfolio)
            .then(response => {
              if (typeof this.onSave === 'function') {
                this.onSave(this.EventEmitter({ portfolioItem: response }))
              }

              // close modal
              this.modalCtrl.close()
            })
            .catch(err => {
              this.serverError = err
            })
            .finally(() => {
              this.isProcessing = false
            })
        })
        .catch(err => {
          console.log('Error trying to get url', err)
        })
    }

    cancelEdit () {
      this.modalCtrl.close()
    }
  }
}

export default ExpertProfilePortfolioEditModalComponent
