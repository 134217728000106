const SubmissionService = class SubmissionService {
  constructor ($http, Configuration, ExperimentService, SegmentAnalytics) {
    'ngInject'
    this.$http = $http
    this.config = Configuration
    this.Configuration = Configuration
    this.experimentService = ExperimentService
    this.segmentAnalytics = SegmentAnalytics
    this.experimentPredictedBudget = false
  }

  processQueryParams (formData = {}, params, formConfig = {}) {
    // Support old submission flow values for urgency and remap them to new ones for the form compatibility, currently it gets mapped back when publishing a project


    // TITLE
    if (params.title) {
      formData.title = params.title
    }
    // DESCRIPTION
    if (params.description || params.desc) {
      formData.description = params.description || params.desc
    }
    // TYPE & PROJECT_TYPE
    if (params.type || params.project_type) {
      let type = params.type || params.project_type

      if (type === 'consultation') {
        formData.type = formConfig.consultationType || null
      } else {
        formData.type = formConfig.typeField.find(t => t.project_type === type) || null
      }
    }
    // SUBJECT & PROJECT_SUBJECT
    if (params.subject || params.project_subject) {
      let subjectValue = params.subject || params.project_subject
      formData.subject = formData.type.options.find(t => t.value === subjectValue) || null
    }
    // COMPLEXITY
    if (params.complexity) {
      formData.complexity = formConfig.complexityField.options.find(t => t.value === params.complexity) ? params.complexity : null
    }
    // URGENCY
    if (params.urgency) {
      switch (params.urgency) {
      case 0:
        formData.urgency = 'urgent'
        break
      case 2:
        formData.urgency = 'moderate'
        break
      case '3':
        formData.urgency = 'flexible'
        break
      default:
        formData.urgency = formConfig.urgencyField.options.find(t => t.value === params.urgency) ? params.urgency : null
      }
    }
  }

  /**
   * Get experts
   * @return {object} Sorted expert
   */
  getExperts () {
    return this.$http
      .get(`${this.config.apiUrl}/experts/preferrables`)
      .then(function (response) {
        let experts = {
          all: [],
          normal: [],
          pro: []
        }
        if (response.data) {
          experts.all = response.data.map(expert => {
            return {
              id: expert.id,
              name: expert.fullName,
              available: expert.available
            }
          })
          experts.normal = experts.all.filter(contractor => {
            return !contractor.pro
          })
          experts.pro = experts.all.filter(contractor => {
            return contractor.pro
          })
        }
        return experts
      })
      .catch(function (err) {
        // @TODO: Do something with error
        console.log(err)
      })
  }
/**
   * Get expert
   * @return response
   */
  getExpert (id) {
    return this.$http
      .get(`${this.config.apiUrl}/contractors/` + id)
      .then(function (response) {
        return response.data
      })
      .catch(function (err) {
        // @TODO: Do something with error
        console.log(err)
      })
  }

  getReferralPartner (ref) {
    console.log('SubmissionService > getReferralPartner > ref', ref)
    const config = {
      params: {}
    }

    if (angular.isDefined(ref)) {
      config.params.ref = ref
    }

    return this.$http
      .get(`${this.config.apiUrl}/tasks/new`, config)
      .then(function (response) {
        return response.data
      })
      .catch(function (err) {
        // @TODO: Do something with error
        console.log(err)
      })
  }
  /**
   * Create new Task
   * @param  {object} task
   * @return {object}   Promise
   */
  createTask (task) {
    const payload = this.modifyTaskDataBeforePublish(task)
    payload.analytics = {
      cdblAnonymousId: this.experimentService.anonymousId,
      anonymousId: this.segmentAnalytics.getAnonymousId()
    }

    return this.$http
      .post(`${this.config.apiUrl}/tasks`, payload)
  }

 /**
 * Update Task
 * @param  {object} task
 * @return {object}   Promise
 */
  updateTask (task) {
    return this.$http
      .put(`${this.config.apiUrl}/tasks/${task.id}`, task)
  }

   /**
   * Segment
   * @param  {object} payload
   * @return {object}   Promise
   */
  segment (payload) {
    return this.$http
      .put(`${this.config.apiUrl}/users/me/segment`, payload)
  }


  subjectHasPreset (subjectId) {
    return true
  }

  /**
   * Modify data before submit
   * @param  {[object} task [description]
   * @return {object}      modified object
   */
  modifyTaskDataBeforePublish (task) {
    let payload = Object.assign({}, {
      title: task.title,
      description: task.description,
      websiteUrl: task.websiteUrl,
      urgency: task.urgency,
      budget: task.budget,
      preferredContractors: [],
      staysPreferred: task.invitePreferredExpert && !task.publishForAll,
      pro: task.pro,
      project_type: task.project_type,
      project_subject: task.project_subject
    })

    // Add submission form version if exists
    if (task.submissionFormVersion) {
      payload.submissionFormVersion = task.submissionFormVersion
    }

    if (task.draftId) {
      payload.draftId = task.draftId
    }

    if (task.budgetRange) {
      payload.budgetRange = task.budgetRange
    }

    if (task.complexity) {
      payload.complexity = task.complexity
    }

    if (task.invitePreferredExpert && task.preferredContractors.selected.length > 0) {
      payload.preferredContractors = task.preferredContractors.selected.map(contractor => {
        return contractor.id
      })
    }

    if (task.fixedBudget === 'no' && this.experimentPredictedBudget) {
      payload.budget = -5 // set budget range to "not sure"
    }

    if (!this.experimentPredictedBudget) {
      payload.ignoreBudget = true
    }

    if (task.exactBudget) {
      payload.budget = parseInt(task.exactBudget.toString().replace(/[^0-9.]/g, ''), 10)
    }

    // Conditionaly add partner_name if exists
    if (task.partner_name) {
      payload.partner_name = task.partner_name
    }

    // Conditionaly add kind if exists
    if (task.kind) {
      payload.kind = task.kind
    }

    // TODO: remove this when BE fixes legacy kind parameter
    if (task.project_subject === 'consultation') {
      payload.kind = 'consultation'
    }

    console.log('modifyTaskDataBeforePublish', task, payload)
    return payload
  }



  getPredictedBudget (params = {}) {
    const config = {
      params: params
    }
    return this.$http
      .get(`${this.Configuration.apiUrl}/tasks/budget_range`, config)
      .then(response => response.data)
  }
}
export default SubmissionService
