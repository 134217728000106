import templateUrl from './submission-step-two.html'
import templateConsultationUrl from './submission-step-two--consultation.html'

import './submission-step-two.scss'

const SubmissionStepTwoComponent = {
  bindings: {
    config: '<',
    callbacks: '<',
    coupon_code: '<',
    data: '<',
    user: '<',
    step: '<'
  },
  require: {
    parent: '^cdblSubmission'
  },
  // templateUrl,
  template: `<div ng-include="$ctrl.templateUrl"></div>`,
  controller: class SubmissionStepTwo {
    constructor ($location, Flash, Modal, SubmissionStepTwoService, $timeout, $element, SegmentAnalytics, Configuration, $window) {
      'ngInject'
      this._identify = 'SubmissionStepTwoComponent'
      this.$location = $location
      this.$timeout = $timeout
      this.$element = $element
      this.segmentAnalytics = SegmentAnalytics
      this.isSubmitClicked = false

      this.submissionConfig = Configuration.features.submission

      this.reviewed = false
      this.stickyOffset = 0
      this.title = 'Create a new project'
      this.modal = Modal
      this.autocomplete = {
        focus: false
      }
      this.flash = Flash
      this.service = SubmissionStepTwoService
      this.attachments = []
      this.helpers = {
        title: false,
        experts: false,
        description: false,
        files: true,
        fixedBudget: false,
        budget: true,
        invitePreferredExpert: true
      }
      this.disabledFields = {
        title: false,
        preferredContractors: false,
        urgency: false,
        description: false,
        attachments: false,
        fixedBudget: false,
        budget: false,
        invitePreferredExpert: false
      }
    }

    $onInit () {
      // Set template
      this.templateUrl = (this.data && this.data.project_type === 'consultation') ? templateConsultationUrl : templateUrl

      const usePreset = this.$location.search().usePreset

      // Check if selected type and subject have presets and apply them to form
      if (usePreset && this.config.presets && this.config.presets[this.data.project_type] && this.config.presets[this.data.project_type][this.data.project_subject]) {
        const preset = this.config.presets[this.data.project_type][this.data.project_subject]
        console.log('[Submission][StepTwo] > preset found and applied', preset)
        if (preset.description) {
          this.data.description = preset.description
        }
      }

      const vm = this
      this.onAttachmentsSelect = $event => {
        console.log('[onAttachmentsSelect]', $event)
        vm.data.attachmentsQueueCtrl = $event
      }
    }



    /**
     * Go to step
     * @param  {string} step
     */
    goToStep (step) {
      this.parent.goToStep(step)
    }

    /**
     * Is helper active
     * @param  {string} helper
     * @return {boolean}
     */
    helperIsActive (helper) {
      return this.helpers[helper]
    }

    /**
     * Toogle helpers
     * @param  {string} helper
     */
    toogleHelpers (helper) {
      let timeout = 0
      if (this.helpers[helper]) {
        timeout = 1000
      }
      this.$timeout(() => {
        this.helpers[helper] = !this.helpers[helper]
      }, timeout)
    }

    /**
     * Show project Budget
     * @return {[type]} [description]
     */
    showProjectBudget () {
      let show = this.data.fixedBudget === 'yes'
      return show
    }
    /**
     * Open modal
     * @param  {string} Modal name
     */
    openModal (modalName) {
      if (modalName === 'TutorialModal') {
        this.modal.open('views/modals/tutorial_video.html', {
          cssClass: 'modal--video p-a-0'
        })
      }

      if (modalName === 'ExamplesModal') {
        this.modal.open('views/tasks/modal_examples.html')
      }

      if (modalName === 'DescriptionModal') {
        this.modal.open('views/tasks/modal_description.html')
      }
    }

    turnProjectIntoConslutation () {
      this.parent.turnIntoAConslutation()
      $('html,body').scrollTop(0)
    }

    reviewProject (form) {
      this.reviewed = true

      if (form.$valid) {
        Object.keys(this.disabledFields).forEach(fieldName => {
          this.disabledFields[fieldName] = true
        })

        this.segmentAnalytics.trackTaskInfoSubmitted(this.data)
        this.goToStep('three')
        $('html,body').scrollTop(0)
      }
    }

    publishProject (form) {
      this.isSubmitClicked = true
      console.log('publishProject', form.$valid, form)
      if (form.$valid) {
        this.parent.publishProject()
      } else {
        angular.forEach(form.$error, function (errorType) {
          angular.forEach(errorType, function (errorField) {
            // if (errorField.$name === 'formcallbox') {
            //   angular.forEach(errorField.$error, function (errorType) {
            //     angular.forEach(errorType, function (errorField) {
            //       errorField.$setTouched();
            //     });
            //   });
            // } else {
            //   errorField.$setTouched();
            // }
            errorField.$setTouched()
          })
        })
        this.parent.authForm.showValidationMessages = true
        this.focusFirstWithError()
      }
    }

    /**
     * focusFirstWithError
     * focuses first element
     * @return {[type]} [description]
     */

    focusFirstWithError () {
      this.reviewed = true
      this.$timeout(() => {
        console.log('first error field', this.$element.find('.has-error').first())
        console.log('children', this.$element.find('.has-error').first().find('input, textarea, select'))

        this.$element.find('.has-error').first().find('input, textarea, select').focus()
      }, 250)
    }

    focusExactBudget () {
      this.$element.find('#exactBudget').focus()
    }

    onChangeProjectBudget () {
      console.log('onChangeProjectBudget', this.data.budget)
      // reset exactBudget
      if (this.data.budget !== 'custom') {
        this.$timeout(() => {
          this.data.exactBudget = null
        })
      }
    }

    toggleChange (fieldName) {
      this.disabledFields[fieldName] = !this.disabledFields[fieldName]
    }
    isDisabled (fieldName) {
      return this.disabledFields[fieldName]
    }

    onStickyChange ($event) {
      // this.$timeout(() => {
      //   if ($event.isFixed) {
      //     this.stickyOffset = 25;
      //   } else {
      //     this.stickyOffset = 0;
      //   }
      //   console.log('onStickyChange', this.stickyOffset, $event);
      // });
    }
  }
}

export default SubmissionStepTwoComponent
