const DeleteAccountService = class DeleteAccountService {
  constructor ($http, $q, $timeout, Configuration, UserService) {
    'ngInject'

    this.$http = $http
    this.$q = $q
    this.$timeout = $timeout
    this.Configuration = Configuration
    this.UserService = UserService
  }

  deleteAccount (formData) {
    return this.$http
      .get(`${this.Configuration.apiUrl}/users/me/auth-password`, {
        params: {
          password: formData.current_password,
        }
      })
      .then(res => {
        if (formData.email === this.UserService.user.email) {
          if (res.data.passwordValid) {
            return this.$http.delete(`${this.Configuration.apiUrl}/users/me`, {
              params: {
                current_password: formData.current_password
              }
            }).then(res => res.data)
          } else {
            return this.$q.reject({
              error: 'correctPassword',
              message: 'Password you\'ve entered is not correct'
            })
          }
        }

        return this.$q.reject({
          error: 'correctEmail',
          message: 'Email you\'ve entered is not your email'
        })
      })
  }
}
export default DeleteAccountService
