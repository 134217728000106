// TODO: this service file will once legacy Sockets service is refactored become main point of pusher integration
// - move subscripton to dynamic channels and event binding
const PusherService = class PusherService {
  constructor ($timeout, AuthTokenService, Sockets) {
    'ngInject'
    this.$timeout = $timeout
    this.AuthTokenService = AuthTokenService
    this.Sockets = Sockets // TODO: refactor Sockets service into this Pusher service

    this.presenceChannel = null
  }

  init (userId, userRole, authToken) {
    // Sockets expects user object (it only uses id and role) and auth token for intitialization
    this.Sockets.init({
      id: userId,
      role: userRole
    }, authToken)

    this.initPresenceChannel()
  }


  get isInitialized () {
    return this.Sockets.isInitialized()
  }

  updateAuthToken (newToken) {
    if (!newToken) {
      newToken = this.AuthTokenService.get()
    }

    if (newToken && this.isInitialized) {
      this.Sockets.updateToken(newToken)
    }
  }

  unsubscribeAll () {
    this.Sockets.unsubscribeAll()
  }

  initPresenceChannel () {
    this.presenceChannel = this.Sockets.getChannel('presence-online') // subscribed in legacy Sockets init method

    if (this.presenceChannel) {
      const vm = this
      this.presenceChannel.bind('pusher:subscription_succeeded', members => {
        vm.$timeout(() => {})
      })

      this.presenceChannel.bind('pusher:member_added', member => {
        vm.$timeout(() => {})
      })

      this.presenceChannel.bind('pusher:member_removed', member => {
        vm.$timeout(() => {})
      })
    }
  }

  isUserOnline (userId) {
    if (this.presenceChannel && this.presenceChannel.members) {
      const presenceUser = this.presenceChannel.members.get(userId)
      if (presenceUser && presenceUser.id === userId) {
        return true
      }
    }

    return false
  }
}
export default PusherService
