import templateUrl from './project-completed-reminder-modal.html'
import './project-completed-reminder-modal.scss'

const COMPLETE_PROJECT_REMINDER = 'completionCheck'

const ProjectCompletedReminderModalComponent = {
  bindings: {
    project: '<?',
    currentUser: '<?',
  },
  require: {
    modalCtrl: '^^cdblModal'
  },
  templateUrl,
  controller: class ProjectCompletedReminderModalComponent {
    constructor (ProjectService, ModalService, ProjectRemindersService) {
      'ngInject'
      this._identify = 'ProjectCompletedReminderModalComponent'
      this.ProjectService = ProjectService
      this.ModalService = ModalService
      this.ProjectRemindersService = ProjectRemindersService
      this.processing = false
    }

    $onInit () {
    }

    closeProjectCompleteModal () {
      this.processing = true
      this.ProjectRemindersService.setReminderState(COMPLETE_PROJECT_REMINDER)
      this.ModalService.close()
      this.processing = false
    };
  }

}

export default ProjectCompletedReminderModalComponent
