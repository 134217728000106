(function () {
  'use strict';

  function twoDecimals($sce, numberFilter) {
    return function (number, cssClass) {
      cssClass = cssClass || '';

      if (number) {
        var decimals = number.toString().split('.');
        if (!decimals[1]) {
          decimals[1] = '00';
        }

        return $sce.trustAsHtml('$' + numberFilter(decimals[0], 0) + '<span class="' + cssClass + '">' + decimals[1] + '</span>');
      }
    };
  }

  app.filter('twoDecimals', ['$sce', 'numberFilter', twoDecimals]);
})();
