const excludeFromArrayFilter = function () {
  return function (array, selected, comparatorField = 'id') {
    return array.filter(item => {
      let unselected = true

      if (typeof selected === 'undefined' || selected === null) {
        return unselected
      }

      const comparator = selectedItem => {
        if (typeof selectedItem === 'object' && typeof item === 'object' && selectedItem[comparatorField] === item[comparatorField]) {
          unselected = false
        } else if (typeof selectedItem !== 'object' && typeof item === 'object' && selectedItem === item[comparatorField]) {
          unselected = false
        } else if (selectedItem === item) {
          unselected = false
        }
      }

      if (Array.isArray(selected)) {
        selected.forEach(comparator)
      } else {
        comparator(selected)
      }

      return unselected
    })
  }
}
export default excludeFromArrayFilter
