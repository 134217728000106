class PreferredExpertNotFoundError extends Error {
  constructor (message) {
    const messageText = message ? `Preferred experts not found: ${message}` : 'Preferred experts not found'
    super(messageText)

    this.name = 'PreferredExpertNotFoundError'
    this.message = 'Preferred expert(s) not found.'
    this.description = ''
  }
}

export default PreferredExpertNotFoundError
