import ActivityStreamComponent from './activity-stream.component'
import ActivityStreamService from './activity-stream.service'

import ActivityModule from './activity'
import CommentModule from './comment'

const ActivityStreamModule = angular.module('cdbl.activity-stream', [
  ActivityModule,
  CommentModule
])
  .component('cdblActivityStream', ActivityStreamComponent)
  .service('ActivityStreamService', ActivityStreamService)
  .name

export default ActivityStreamModule
