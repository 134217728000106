import templateUrl from './project-dashboard.html'
import './project-dashboard.scss'

const ProjectDashboardComponent = {
  bindings: {},
  templateUrl,
  controller: class ProjectDashboard {
    constructor (UserService, ProjectDashboardService, Flash, UIService) {
      'ngInject'
      this._identify = 'ProjectDashboardComponent'
      this.UserService = UserService
      this.ProjectDashboardService = ProjectDashboardService
      this.Flash = Flash
      this.UIService = UIService

      this.showAddNewProjectForm = UserService.isClient()
    }

    $onInit () {
      console.log('[ProjectDashboardComponent] > init')

      // TODO: refactor
      // if (!UserService.hasCompletedPersonaSurvey() && UserService.isClient()) {
      //   Modal.open('views/modals/persona-survey.html', {}, {closeButton: false});
      // }
      // ----
    }

    closeSidebar () {
      this.sidebar = this.UIService.getComponent('sidebar-main-left')
      if (this.sidebar) {
        this.sidebar.close()
      }
    }

    openRightSidebar () {
      const sidebar = this.UIService.getComponent('sidebar-dashboard-right')
      sidebar.open()
    }

    get showSidebarToggle () {
      return !this.UIService.$mdMedia('gt-md')
    }
  }
}

export default ProjectDashboardComponent
