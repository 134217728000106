/* Factory used to create instances of FileUploadWrappers and take care of the dependency injection
* Calling it from controller or service:
*
* const fileUploadWrapper = new FileUploadWrapperFactory ({
*   filename: ...        // filename
*   signedUrlUpload: ... , // signed url
*   fileObject: ...  // raw file object
* })
*/
function FileUploadWrapperFactory ($q, Configuration) {
  'ngInject'

  const FileUploadWrapper = class FileUploadWrapper {
    constructor (config) {
      this.$q = $q
      this.Configuration = Configuration

      this.started = false
      this.uploaded = false
      this.progress = false
      this.synced = false
      this.canceled = false
      this.signedUrlUpload = null
      this.signedUrlDownload = null
      this.fileObject = null
      this.attachment = null

      const vm = this
      this.onUploadFileProgressHandler = function (fileObject, percentage) {
        vm.progress = percentage
      }

      this.httpRequestCanceller = this.$q.defer()

      Object.assign(this, config)

      if (!this.filename && this.fileObject) {
        this.filename = this.fileObject.name
      }
    }


    get name () {
      return this.fileObject ? this.fileObject.name : null
    }

    get filename () {
      return this.name
    }

    cancelUpload () {
      this.httpRequestCanceller.resolve()
      this.canceled = true
    }

    /**
     * Set signed url
     * @param {Object, String} signedUrl
     */
    setSignedUrl (signedUrl = {}) {
      if (typeof signedUrl === 'string') {
        this.signedUrlUpload = signedUrl
      } else if (signedUrl.url) {
        this.signedUrlUpload = signedUrl.url
      }
    }
  }

  return FileUploadWrapper
}
export default FileUploadWrapperFactory
