import templateUrl from './menu-main.html'
import './menu-main.scss'

const MenuMainComponent = {
  bindings: {},
  templateUrl,
  controller: class MenuMain {
    constructor (UIService, SegmentAnalytics, Configuration, UserService) {
      'ngInject'
      this._identify = 'MenuMainComponent'
      this.UIService = UIService
      this.SegmentAnalytics = SegmentAnalytics
      this.Configuration = Configuration
      this.UserService = UserService
    }

    closeAllSidebars () {
      this.UIService.closeAllComponentsPerType('sidebar')
    }

    helpCenterClicked () {
      this.SegmentAnalytics.helpCenterClicked('navbar')
      this.closeAllSidebars()
    }
  }
}

export default MenuMainComponent
