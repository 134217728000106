const AttachmentNameFormatFilter = function ($filter) {
  'ngInject'
  return function (attachmentName) {
    let formattedName = attachmentName
    if (attachmentName.length > 25) {
      formattedName = attachmentName.substr(0, attachmentName.length - 6) + '...' + attachmentName.substr(attachmentName.length - 6)
    }
    return formattedName
  }
}
export default AttachmentNameFormatFilter
