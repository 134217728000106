(function () {
  'use strict';

  function RestangularizeElements(Restangular, Auth) {
    var restangularizeInvitation = function (taskId, invitationId) {
      var invitation = {
        state: 'created',
        id: invitationId,
        userId: Auth.me().id
      };

      return Restangular.restangularizeElement(null, invitation, 'tasks/' + taskId + '/invitations');
    };

    var restangularizeComment = function (task, comment) {
      return Restangular.restangularizeElement(null, comment, 'tasks/' + task.id + '/comments');
    };

    var restangularizeSubTask = function (task, subTask) {
      return Restangular.restangularizeElement(null, subTask, 'tasks/' + task.id + '/sub_tasks');
    };

    return {
      restangularizeInvitation: restangularizeInvitation,
      restangularizeComment: restangularizeComment,
      restangularizeSubTask: restangularizeSubTask
    };
  }

  app.service('RestangularizeElements', ['Restangular', 'Auth', RestangularizeElements]);
})();
