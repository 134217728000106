const CURRENCY_PREFIX = '$'
const CURRENCY_POSTFIX = ' USD'
const currencyFormatFilter = function ($filter) {
  'ngInject'
  return function (amount, _options = {}) {
    const options = {
      precision: 2,
      postfix: true,
      prefix: true,
      ..._options
    }
    // Handle undefined and null values
    if (typeof amount === 'undefined' || amount === null) {
      amount = 0
    }

    const prefix = options.prefix ? CURRENCY_PREFIX : ''
    const postfix = options.postfix ? CURRENCY_POSTFIX : ''
    // Handle number values
    if (typeof amount === 'number') {
      const isNegative = amount < 0
      const amountFormattedString = $filter('number')(Math.abs(amount), options.precision) // round it to 2 decimals
        .toString()            // convert to string
        .replace(/[.]00$/, '') // remove .00 decimals

      return `${isNegative ? '-' : ''}${prefix}${amountFormattedString}${postfix}`
    }

    // Handle string values
    if (typeof amount === 'string') {
      const stringExceptions = ['not sure', 'flexible']
      if (stringExceptions.includes(amount)) {
        return amount
      }
      const isNegative = amount.startsWith('-')
      const amountFormattedString = amount.replace(/[.]00$/, '') // remove .00 decimals
      if (amountFormattedString.startsWith(prefix)) {
        return `${amountFormattedString}${postfix}`
      }

      return `${isNegative ? '-' : ''}${prefix}${amountFormattedString}${postfix}` // if its a string it already has $
    }

    // TODO: Handle range values

    // In all other cases return original value
    return amount
  }
}
export default currencyFormatFilter
