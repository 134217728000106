import templateUrl from './expert-profile-edit-portfolio-item.html'
import './expert-profile-edit-portfolio-item.scss'

const ExpertProfileEditPortfolioItemComponent = {
  bindings: {
    portfolio: '<',
    onEdit: '&?',
    onSelect: '&?',
    onDelete: '&?',
  },
  templateUrl,
  controller: class ExpertProfileEditPortfolioItemComponent {
    constructor (EventEmitter) {
      'ngInject'
      this._identify = 'ExpertProfileEditPortfolioItemComponent'
      this.EventEmitter = EventEmitter
    }

    $onInit () {

    }

    get imageStyle () {
      if (this.portfolio && this.portfolio.featuredImage) {
        return {
          'background-image': `url(${this.portfolio.featuredImage.medium.url})`
        }
      }
      return {}
    }

    get canEdit () {
      return this.portfolio.actions && this.portfolio.actions.update
    }

    get canDelete () {
      return true
      // return this.portfolio.actions && this.portfolio.actions.delete
    }

    edit () {
      if (typeof this.onEdit === 'function') {
        this.onEdit(this.EventEmitter({ portfolio: this.portfolio }))
      }
    }

    delete () {
      if (typeof this.onDelete === 'function') {
        this.onDelete(this.EventEmitter({ portfolio: this.portfolio }))
      }
    }

    select () {
      if (typeof this.onSelect === 'function') {
        this.onSelect(this.EventEmitter({ portfolio: this.portfolio }))
      }
    }
  }
}

export default ExpertProfileEditPortfolioItemComponent
