import templateUrl from './project-presets.html'
import './project-presets.scss'

const ProjectPresetsComponent = {
  bindings: {},
  templateUrl,
  controller: class ProjectPresetsComponent {
    constructor (AuthService) {
      'ngInject'
      this._identify = 'ProjectPresetsComponent'
      this.AuthService = AuthService
    }
  }
}

export default ProjectPresetsComponent
