const ProjectScopeDocService = class ProjectScopeDocService {
  constructor ($http, Configuration, UserService, ProjectScopeDocFactory, ProjectScopeDocItemFactory) {
    'ngInject'

    this.$http = $http
    this.Configuration = Configuration
    this.UserService = UserService
    this.ProjectScopeDocFactory = ProjectScopeDocFactory
    this.ProjectScopeDocItemFactory = ProjectScopeDocItemFactory

    this.draftModeStates = [null, 'drafted']
    this.viewModeStates = ['submitted', 'approved', 'finished', 'completed']
    this.stateMapping = {
      expert: {
        'drafted': {
          isWaiting: false,
          statusModal: 'You can still edit the scope before submitting.',
          statusWidget: 'Please define your scope of work now and get it approved by the client.',
          buttonWidget: 'Add Scope'
        },
        'submitted': {
          isWaiting: true,
          statusModal: 'Waiting for the client to accept or reject the scope.',
          statusWidget: 'Waiting for the client to accept or reject your scope of work. Please check back later.',
          buttonWidget: 'View Scope'
        },
        'approved': {
          isWaiting: false,
          statusModal: 'Please keep the client up to date by marking items complete below.',
          statusWidget: 'Work is in progress. Please keep the client up to date.',
          buttonWidget: 'Update Scope'
        },
        'finished': {
          isWaiting: true,
          statusModal: 'You\'ve marked the project as done. Waiting for the client to mark it complete.',
          statusWidget: 'Waiting for the client to confirm the project is complete.',
          buttonWidget: 'View Scope'
        },
        'completed': {
          isWaiting: false,
          statusModal: 'Project is complete.',
          statusWidget: 'The project is complete. You can view the scope for reference.',
          buttonWidget: 'View Scope'
        },
        'fallback': {
          isWaiting: false,
          statusModal: 'Select one of your predefined templates or start a new scope.',
          statusWidget: 'Please define your scope of work now and get it approved by the client.',
          buttonWidget: 'Add Scope'
        },
      },
      client: {
        'submitted': {
          isWaiting: false,
          statusModal: 'The scope of the project your expert provided. Please, accept or request changes.',
          statusWidget: 'Please review the scope your expert has submitted, so that work can start.',
          buttonWidget: 'Review Scope'
        },
        'approved': {
          isWaiting: true,
          statusModal: 'Work in progress.',
          statusWidget: 'Your expert will keep the scope up to date as items are done. View the scope to check on progress.',
          buttonWidget: 'View Scope'
        },
        'finished': {
          isWaiting: false,
          statusModal: 'The expert marked the entire project as done. Please confirm.',
          statusWidget: 'Your expert has finished work on this project. Please review and confirm so that your expert can get paid.',
          buttonWidget: 'Review & Complete'
        },
        'completed': {
          isWaiting: false,
          statusModal: 'The project is complete.',
          statusWidget: 'The project is complete. You can view the scope for reference.',
          buttonWidget: 'View Scope'
        },
        'fallback': {
          isWaiting: true,
          statusModal: null,
          statusWidget: 'Your expert will upload the scope shortly. Please check back later.',
          buttonWidget: null
        }
      }
    }
  }

  getStateMappedProp (propName = 'statusModal', scopeDoc = null) {
    const userRole = this.UserService.userRole
    if (scopeDoc) {
      const stateConfig = this.stateMapping[userRole][scopeDoc.state]
      if (stateConfig) {
        return stateConfig[propName]
      } else {
        return this.stateMapping[userRole]['fallback'][propName]
      }
    }

    return this.stateMapping[userRole]['fallback'][propName]
  }

  // Utility methods
  canOpenScopeDocModal (scopeDoc) {
    return (this.UserService.isClient() && scopeDoc && this.viewModeStates.includes(scopeDoc.state)) || this.UserService.isExpert()
  }

  isViewMode (scopeDoc) {
    return (
      this.UserService.isClient() &&
      this.viewModeStates.includes(scopeDoc.state)
    ) ||
    (
      this.UserService.isExpert() &&
      scopeDoc &&
      this.viewModeStates.includes(scopeDoc.state)
    )
  }

  isSaved (scopeDoc) {
    return scopeDoc.state !== null
  }

  isDraftMode (scopeDoc) {
    return this.UserService.isExpert() && scopeDoc && !this.viewModeStates.includes(scopeDoc.state)
  }

  isState (state, scopeDoc) {
    return scopeDoc && scopeDoc.state === state
  }

  generateScopeDoc (templateName) {
    if (templateName === 'blank') {
      const scopeDoc = new this.ProjectScopeDocFactory()

      // Default one parent item and one subitem
      const scopeDocItem = new this.ProjectScopeDocItemFactory()
      scopeDocItem.addChild(new this.ProjectScopeDocItemFactory())

      scopeDoc.scopeDocItems.push(scopeDocItem)
      return scopeDoc
    }
    // TODO special case generation can be added here

    return null
  }

  generateBlankTemplate () {
    const template = this.generateScopeDoc('blank')
    template.name = 'Blank Proposal Template'
    template.id = null

    return template
  }

  processScopeDocResponse (scopeDoc) {
    if (scopeDoc) {
      // reconstruct class instance of scope doc
      return new this.ProjectScopeDocFactory(scopeDoc)
    }

    return null
  }

  processScopeDocItemResponse (scopeDocItem) {
    if (scopeDocItem) {
      // reconstruct class instance of scope doc
      return new this.ProjectScopeDocItemFactory(scopeDocItem)
    }
  }

  processScopeDocResetIds (scopeDocConfig) {
    if (scopeDocConfig) {
      return new this.ProjectScopeDocFactory(scopeDocConfig, true) // true switch will enforce usage of temporary (FE sided) ids
    }

    return null // TODO: if this will cause any problem perhaps it would be better to return blank template?
  }


  // API METHODS
  // ------------------------------------------

  scopeDocUrl (projectId) {
    const apiRoleSlug = this.UserService.apiRoleSlug
    return `${this.Configuration.apiUrl}/${apiRoleSlug}/projects/${projectId}/scope_doc`
  }

  scopeDocTemplateUrl () {
    return `${this.Configuration.apiUrl}/experts/me/scope_doc_templates`
  }


  // Scope doc
  getScopeDoc (projectId) {
    return this.$http
      .get(`${this.scopeDocUrl(projectId)}`)
      .then(response => {
        if (response.data && Object.keys(response.data).length === 0) {
          return null // handle empty object case (TODO: look into data intercepter)
        }
        return this.processScopeDocResponse(response.data)
      })
  }

  saveScopeDoc (scopeDoc, projectId) {
    const payload = JSON.parse(JSON.stringify(scopeDoc))
    delete payload._edit
    payload.scopeDocItems.forEach(item => {
      delete item._edit
      item.scopeDocChildItems.forEach(childItem => {
        delete childItem._edit
      })
    })
    return this.$http
      .put(`${this.scopeDocUrl(projectId)}`, payload)
      .then(response => this.processScopeDocResponse(response.data))
  }

  submitScopeDoc (projectId) {
    const payload = {}
    return this.$http
      .post(`${this.scopeDocUrl(projectId)}/submission`, payload)
      .then(response => this.processScopeDocResponse(response.data))
  }

  approveScopeDoc (projectId) {
    const payload = {}
    return this.$http
      .post(`${this.scopeDocUrl(projectId)}/approval`, payload)
      .then(response => this.processScopeDocResponse(response.data))
  }

  rejectScopeDoc (rejectionReason, projectId) {
    const payload = {
      rejectionReason
    }
    return this.$http
      .post(`${this.scopeDocUrl(projectId)}/rejection`, payload)
      .then(response => this.processScopeDocResponse(response.data))
  }

  finishScopeDoc (projectId) {
    const payload = {}
    return this.$http
      .post(`${this.scopeDocUrl(projectId)}/finish`, payload)
      .then(response => this.processScopeDocResponse(response.data))
  }

  completeScopeDoc (projectId) {
    const payload = {}
    return this.$http
      .post(`${this.scopeDocUrl(projectId)}/completion`, payload)
      .then(response => this.processScopeDocResponse(response.data))
  }

  // Scope doc items
  finishScopeDocItem (scopeDocItemId, projectId) {
    const payload = {}
    return this.$http
      .post(`${this.scopeDocUrl(projectId)}/scope_doc_items/${scopeDocItemId}/finish`, payload)
      .then(response => this.processScopeDocItemResponse(response.data))
  }

  unfinishScopeDocItem (scopeDocItemId, projectId) {
    const payload = {}
    return this.$http
      .delete(`${this.scopeDocUrl(projectId)}/scope_doc_items/${scopeDocItemId}/finish`, payload)
      .then(response => this.processScopeDocItemResponse(response.data))
  }

  // Templates
  getTemplatesList () {
    return this.$http
      .get(`${this.scopeDocTemplateUrl()}`)
      .then(response => {
        return response.data
      })
  }
  getTemplate (templateId) {
    return this.$http
      .get(`${this.scopeDocTemplateUrl()}/${templateId}`)
      .then(response => {
        return response.data
      })
  }
  updateTemplate (template) {
    const payload = {
      ...template
    }
    return this.$http
      .put(`${this.scopeDocTemplateUrl()}/${template.id}`, payload)
      .then(response => {
        return response.data
      })
  }
  createTemplate (name, scopeDoc) {
    const payload = {
      ...scopeDoc,
      name
    }
    return this.$http
      .post(`${this.scopeDocTemplateUrl()}`, payload)
      .then(response => {
        return response.data
      })
  }
}
export default ProjectScopeDocService
