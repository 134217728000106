import DeleteDialogComponent from './delete.component'
import DeleteAccountService from './delete.service'

const DeleteDialogModule = angular.module('cdbl.account-delete', [

])
  .component('cdblAccountDelete', DeleteDialogComponent)
  .service('DeleteAccountService', DeleteAccountService)
  .name

export default DeleteDialogModule
