(function () {
  'use strict';

  function hrefify() {
    return function (html) {
      let htmlEl = document.createElement('span');
      htmlEl.innerHTML = html;
      $(htmlEl).find('a').attr('target', '_blank');
      return htmlEl.innerHTML;
    };
  }

  app.filter('hrefify', hrefify);
})();
