(function () {
  'use strict';

  function activitySearchResult (cdblMarkedFilter, UserService) {
    return {
      restrict: 'E',
      replace: true,
      scope: {
        comment: '=',
        activity: '<',
        task: '=',
        searchInput: '=',
        currentUser: '=',
        goToActivity: '&',
        refactoredMode: '='
      },
      templateUrl: require('../../views/comments/search_result_comment.html'),
      link: function ($scope) {
        $scope.UserService = UserService;

        const parseSearchInput = input => {
          return {
            words: input.split(' ')
          };
        };

        function highlight() {
          const parsedSearchInput = parseSearchInput($scope.searchInput);

          // console.log(parsedSearchInput.words.join('|'));
          var commentEl = document.createElement('span');
          commentEl.innerHTML = $scope.comment.text;

          commentEl.innerHTML = cdblMarkedFilter(commentEl.innerHTML);
          $(commentEl).find('a').attr('target', '_blank');

          $(commentEl).markRegExp(new RegExp(parsedSearchInput.words.join('|'), 'gi'), {
            element: 'span',
            className: 'highlight'
          });

          $scope.comment.textMarked = commentEl.innerHTML;
        }

        $scope.$watch('searchInput', highlight);
      }
    };
  }

  app.directive('activitySearchResult', ['cdblMarkedFilter', 'UserService', activitySearchResult]);
})();
