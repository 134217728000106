(function () {
  'use strict';

  function taskUrgencyToString(dateFilter, UserService) {
    return function (taskUrgency) {
      var taskUrgencyString = '';

      switch (taskUrgency) {
        case 0:
          taskUrgencyString = 'Urgent';
          if (UserService.isClient()) {
            taskUrgencyString = 'Urgently';
          }
          break;
        case 1:
          taskUrgencyString = 'Today or tomorrow';

          break;
        case 2:
          taskUrgencyString = 'In a week';

          break;
        case 3:
          taskUrgencyString = 'Anytime';

          break;
        default:
          taskUrgencyString = 'by ' + dateFilter(taskUrgency * 1000, 'MMMM dd yyyy');

          break;
      }

      return taskUrgencyString;
    };
  }

  app.filter('taskUrgencyToString', ['dateFilter', 'UserService', taskUrgencyToString]);
})();
