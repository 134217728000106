const mdMediaFactory = function ($mdConstant, $rootScope, $window) {
  'ngInject'

  var queries = {}
  var mqls = {}
  var results = {}
  var normalizeCache = {}

  $mdMedia.getResponsiveAttribute = getResponsiveAttribute
  $mdMedia.getQuery = getQuery
  $mdMedia.watchResponsiveAttributes = watchResponsiveAttributes

  return $mdMedia

  function $mdMedia (query) {
    var validated = queries[query]
    if (angular.isUndefined(validated)) {
      validated = queries[query] = validate(query)
    }

    var result = results[validated]
    if (angular.isUndefined(result)) {
      result = add(validated)
    }

    return result
  }

  function validate (query) {
    return $mdConstant.MEDIA[query] ||
           ((query.charAt(0) !== '(') ? ('(' + query + ')') : query)
  }

  function add (query) {
    var result = mqls[query]
    if (!result) {
      result = mqls[query] = $window.matchMedia(query)
    }

    result.addListener(onQueryChange)
    return (results[result.media] = !!result.matches)
  }

  function onQueryChange (query) {
    $rootScope.$evalAsync(function () {
      results[query.media] = !!query.matches
    })
  }

  function getQuery (name) {
    return mqls[name]
  }

  function getResponsiveAttribute (attrs, attrName) {
    for (var i = 0; i < $mdConstant.MEDIA_PRIORITY.length; i++) {
      var mediaName = $mdConstant.MEDIA_PRIORITY[i]
      if (!mqls[queries[mediaName]].matches) {
        continue
      }

      var normalizedName = getNormalizedName(attrs, attrName + '-' + mediaName)
      if (attrs[normalizedName]) {
        return attrs[normalizedName]
      }
    }

    // fallback on unprefixed
    return attrs[getNormalizedName(attrs, attrName)]
  }

  function watchResponsiveAttributes (attrNames, attrs, watchFn) {
    var unwatchFns = []
    attrNames.forEach(function (attrName) {
      var normalizedName = getNormalizedName(attrs, attrName)
      if (angular.isDefined(attrs[normalizedName])) {
        unwatchFns.push(
          attrs.$observe(normalizedName, angular.bind(void 0, watchFn, null)))
      }

      for (var mediaName in $mdConstant.MEDIA) {
        normalizedName = getNormalizedName(attrs, attrName + '-' + mediaName)
        if (angular.isDefined(attrs[normalizedName])) {
          unwatchFns.push(
            attrs.$observe(normalizedName, angular.bind(void 0, watchFn, mediaName)))
        }
      }
    })

    return function unwatch () {
      unwatchFns.forEach(function (fn) { fn() })
    }
  }

  // Improves performance dramatically
  function getNormalizedName (attrs, attrName) {
    return normalizeCache[attrName] ||
        (normalizeCache[attrName] = attrs.$normalize(attrName))
  }
}

export default mdMediaFactory
