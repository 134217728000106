import templateUrl from './workroom-project-info.html'
import './workroom-project-info.scss'

// Project details template (for workroom and estimation room)
import clientProjectInfoDetailsTemplate from './project-info-details-client.html'
import expertProjectInfoDetailsTemplate from './project-info-details-expert.html'
import projectInfoDescriptionTemplate from './project-info-description.html'

const WorkroomProjectInfoComponent = {
  bindings: {
    project: '<'
  },
  require: {
    ActivityStreamCtrl: '^cdblActivityStream'
  },
  templateUrl,
  controller: class WorkroomProjectInfo {
    constructor (ProjectService, UserService, ModalService, $filter) {
      'ngInject'
      this._identify = 'WorkroomProjectInfoComponent'
      this.ProjectService = ProjectService
      this.UserService = UserService
      this.ModalService = ModalService
      this.$filter = $filter
    }

    $onInit () {
      this.projectInfoDetailsTemplate = this.UserService.isClient() ? clientProjectInfoDetailsTemplate : expertProjectInfoDetailsTemplate
      this.projectInfoDescriptionTemplate = projectInfoDescriptionTemplate

      this.projectDate = this.ProjectService.formatProjectDate(this.project)

      // Toggle project details
      this.showProjectDetails = true
      this.toggleProjectDetails = () => {
        this.showProjectDetails = !this.showProjectDetails
      }

      // Toggle description length
      this.showFullDescription = false
      this.toggleFullDescription = () => {
        this.showFullDescription = !this.showFullDescription
      }

      // Edit description
      this.isEditDescriptionActive = false
      this.newDescription = null
      this.startEditDescription = () => {
        this.newDescription = this.project.description
        this.isEditDescriptionActive = true
      }
      this.cancelEditDescription = () => {
        this.newDescription = null
        this.isEditDescriptionActive = false
      }
      this.updateEditDescription = () => {
        this.isUpdateEditDescriptionProcessing = true
        this.ProjectService.updateProjectDescription(this.project, this.newDescription)
          .then(response => {
            this.newDescription = null
            this.isEditDescriptionActive = false
            this.project.description = response.description
          })
          .catch(error => {
            // TODO: process error
            console.log(error)
          })
          .finally(() => {
            this.isUpdateEditDescriptionProcessing = false
          })
      }
    }


    openProjectSubmissionMetaModal () {
      const vm = this
      this.ModalService.open({
        closeOnBackdrop: true,
        closeIcon: true,
        layout: 'default',
        class: 'project-submission-meta-modal',
        template: `<cdbl-project-submission-meta-modal project="$ctrl.modal.project"></cdbl-project-submission-meta-modal>`,
        project: vm.project
      })
    }

    $onDestroy () {

    }
  }
}

export default WorkroomProjectInfoComponent
