import md5 from 'crypto-js/md5'
import CONFIG from './activity-stream.config.js'

const ActivityStreamService = class ActivityStream {
  constructor ($http, $timeout, Configuration, CommentService, PaginationService, StorageService) {
    'ngInject'
    this.$http = $http
    this.$timeout = $timeout
    this.Configuration = Configuration
    this.CommentService = CommentService
    this.PaginationService = PaginationService
    this.StorageService = StorageService

    this.activityList = []

    this.pagination = this.PaginationService.init({
      itemsPerPage: this.StorageService.get('activites--items-per-page') || 50,
      mode: 'server',
      itemsPerPageOptions: [
        25,
        50,
        100,
        300
      ]
    })
  }

  // Activities
  getActivities (config) {
    let { projectId, filterData, params = {} } = config

    if (this.pagination) {
      params = {
        page: this.pagination.currentPage,
        per_page: this.pagination.itemsPerPage,
        ...params
      }
    }

    if (filterData) {
      const q = {
        include_public: typeof filterData.includePublic !== 'undefined' ? filterData.includePublic : null,
        comments_only: typeof filterData.commentsOnly !== 'undefined' ? filterData.commentsOnly : null,
        search: filterData.search ? filterData.search : null
      }

      params = {
        ...params,
        q
      }
    }

    console.log('[ActivityStreamService] > getActivities > params', { ...params })
    return this.$http
      .get(`${this.Configuration.apiUrl}/tasks/${projectId}/activities`, { params })
      .then(response => {
        if (this.pagination) {
          this.pagination.updateFromHeaders(response)
        }
        this.activityList = this.filterValidAndProcess(response.data)
        return this.activityList
      })
      .catch(function (err) {
        console.log(err)
      })
  }

  filterValidAndProcess (activityList) {
    const reducer = (filteredList, activity, index) => {
      const proccessedActivity = this.processActivity(activity)
      // const previousActivity = filteredList[filteredList.length - 1]
      // const isValid = CONFIG.validVerbs.includes(proccessedActivity.verb) &&
      //                 !this.removeSequentialAttachmentActivites(proccessedActivity, previousActivity) &&
      //                 !this.removeSequentialEstimateActivites(proccessedActivity, previousActivity)

      const isValid = CONFIG.validVerbs.includes(proccessedActivity.verb)

      if (isValid) {
        filteredList.push(proccessedActivity)
      }

      return filteredList
    }

    const result = activityList.reduce(reducer, [])
    console.log('[ActivityStreamService] > filterValidAndProcess', result)
    return result
  }

  removeSequentialAttachmentActivites (activity, previousActivity) {
    // If Check for previous actvity and combine or remove duplicates
    if (activity.verb === 'create_attachment' &&
        typeof previousActivity !== 'undefined' &&
        previousActivity !== null) {
      // Check previous activity and combine sequential create_attachment activites (file uploads) into one activity with multiple files as attachments
      if (previousActivity.verb === activity.verb &&
          previousActivity.trackable.user.id === activity.trackable.user.id) {
        if (!previousActivity.trackable.attachments) {
          previousActivity.trackable.attachments = []
          previousActivity.trackable.attachments.push({
            ...previousActivity.trackable
          })
        }
        if (!previousActivity.trackable.attachments.find(a => a.id === activity.trackable.id)) {
          previousActivity.trackable.attachments.push({
            ...activity.trackable
          })
        }
        // remove current activity from list as we insert attachment into previous activity
        return true
      }
    }
    return false
  }

  removeSequentialEstimateActivites (activity, previousActivity) {
    // If Check for previous actvity and combine or remove duplicates
    if (activity.verb === 'create_estimate' &&
        typeof previousActivity !== 'undefined' &&
        previousActivity !== null) {
      // Check previous activity and remove create_estimate duplicates
      if (previousActivity.verb === activity.verb &&
          previousActivity.trackable.estimator.id === activity.trackable.estimator.id) {
        // remove current activity from list as its a duplicate of previous activity
        return true
      }
    }
    return false
  }

  processActivity (rawActivity) {
    let activity

    // Impose new activity structure
    if (!rawActivity.trackableType && !rawActivity.activityId) {
      // Handle in app created activities - wrap it as trackable and assign them temporary unique id

      const verb = rawActivity.verb || null
      delete rawActivity.verb // remove verb from actual trackable object (TODO remove adding it in the first place in app)
      activity = {
        id: md5(`${rawActivity.id}${verb}${rawActivity.createdAt}${new Date().getTime()}`).toString(), // simulate id creation
        verb: verb,
        trackableType: verb.includes('attachment') ? 'Attachment' : verb.includes('comment') ? 'Comment' : verb.includes('estimate') ? 'Estimate' : 'undefined',
        trackable: {
          ...rawActivity
        }
      }
    } else if (rawActivity.activityId) {
      // Handle pusher event created activities - wrap it as trackable

      delete rawActivity.verb // remove verb from actual trackable object (TODO remove adding it in the first place in app)
      activity = {
        id: rawActivity.activityId,
        verb: rawActivity.activityVerb,
        trackableType: rawActivity.activityTrackableType,
        trackable: {
          ...rawActivity
        }
      }
    } else {
      // Handle activities that already support trackable returned by BE
      activity = rawActivity
    }

    // Generate markedHTML for comments
    if (this.isComment(activity)) {
      this.CommentService.processComment(activity.trackable)
    }

    return activity
  }

  addActivity (activity) {
    console.log('[ActivityStreamService] > addActivity', this.pagination.currentPage, this.pagination.pageCount)
    const newProjectException = this.pagination.currentPage === 1 && this.pagination.pageCount === 0
    if (!newProjectException && this.pagination.currentPage !== this.pagination.pageCount) {
      // Do not add new activities to the list if user is not on the last page
      return
    }

    const proccessedActivity = this.processActivity(activity)
    const previousActivity = this.activityList[this.activityList.length - 1]
    const TRACKABLE_TYPES_AUTO_UPDATE = ['Comment']

    console.log('[ActivityStreamService] > addActivity', proccessedActivity, previousActivity)

    // const isValid = CONFIG.validVerbs.includes(proccessedActivity.verb) &&
    //                 !this.removeSequentialAttachmentActivites(proccessedActivity, previousActivity) &&
    //                 !this.removeSequentialEstimateActivites(proccessedActivity, previousActivity)

    const isValid = CONFIG.validVerbs.includes(proccessedActivity.verb)

    if (isValid) {
      // Check for existing activities or their tractable (id and type needs to match) and replace them
      // Used for "comment updated" and other "updated" activities
      const isExistingActivity = (existingActivity) => {
        return existingActivity.id === proccessedActivity.id ||
          (
            TRACKABLE_TYPES_AUTO_UPDATE.includes(proccessedActivity.trackableType) &&
            typeof existingActivity.trackable.id !== 'undefined' &&
            typeof proccessedActivity.trackable.id !== 'undefined' &&
            existingActivity.trackable.id === proccessedActivity.trackable.id &&
            existingActivity.trackableType === proccessedActivity.trackableType
          )
      }

      const existingActivityIndex = this.activityList.findIndex(isExistingActivity)

      if (existingActivityIndex !== -1) {
        this.activityList[existingActivityIndex] = proccessedActivity
      } else {
        this.activityList.push(proccessedActivity)
      }
    }

    // Trigger digest to mitigate legacy code issues
    this.$timeout()
  }

  isComment (activity) {
    return activity.trackableType === 'Comment' // TODO: add trackable types to CONFIG
  }
}
export default ActivityStreamService
