const FIELD_CTRL_TYPES = [
  '',
  'text',
  'number',
  'date',
  'datetime',
  'email',
  'password'
]
const FieldInputDirective = function () {
  return {
    restrict: 'E',
    require: {
      fieldCtrl: '?^cdblFieldContainer',
      checkboxCtrl: '?^cdblCheckbox',
      ngModel: '?ngModel'
    },
    bindToController: true,
    link: ($scope, $element, $attrs, $controllers) => {
      if (typeof $attrs.ignoreFieldCtrl === 'undefined' && FIELD_CTRL_TYPES.includes($attrs.type) && $controllers.fieldCtrl) {
        $controllers.fieldCtrl.setFieldElementCtrl({
          ngModel: $controllers.ngModel,
          checkboxCtrl: $controllers.checkboxCtrl,
          $element: $element,
          $attrs: $attrs
        })

        $scope.$on('$destroy', function () {
          $controllers.fieldCtrl.setFieldElementCtrl(null)
        })
      }
    }
  }
}

export default FieldInputDirective
