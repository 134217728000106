const FeedbackService = class FeedbackService {
  constructor ($q, $http, Configuration, AuthService) {
    'ngInject'
    this.$q = $q
    this.$http = $http
    this.Configuration = Configuration
    this.AuthService = AuthService
  }

  getQuestions (type) {
    let config = {
      params: {
        type: type
      }
    }
    return this.$http
      .get(`${this.Configuration.apiUrl}/feedbacks`, config)
      .then(function (response) {
        return response.data
      })
      .catch(function (err) {
        console.log('error', err)
      })
  }

  submitSingleFeedback (question) {
    const payload = {}
    let answer = question.answers.find(item => item.id === question.value)
    payload.answerId = answer.id

    // TODO: remove text condition as it is not reliable, update all feedback questions in DB to have input type text for other answer instead
    if (answer.text.toLowerCase() === 'other' || answer.inputType === 'text') {
      payload.details = answer.value
    } else if (answer.details) {
      payload.details = answer.details
    }
    return this.$http
      .post(`${this.Configuration.apiUrl}/feedbacks`, payload)
  }

  submitAllFeedback (questions) {
    let promises = []
    questions.forEach(question => {
      promises.push(this.submitSingleFeedback(question))
    })

    return this.$q.all(promises)
  }

  randomizeQuestionsAnswers (questions, otherOnBottom = true) {
    questions.forEach(question => {
      question.answers = this.randomizeAnswers(question.answers, otherOnBottom)
    })
    return questions
  }

  randomizeAnswers (answers, otherOnBottom = true) {
    const fnRandomize = () => (Math.random() - 0.5)

    if (otherOnBottom) {
      let otherArr = answers.filter(item => item.text.toLowerCase() === 'other')
      let regularArr = answers.filter(item => item.text.toLowerCase() !== 'other')
      return regularArr.sort(fnRandomize).concat(otherArr)
    }
    return answers.sort(() => (Math.random() - 0.5))
  }
}
export default FeedbackService
