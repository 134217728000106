(function () {
  'use strict';

  function htmlSpaces() {
    return function (text) {
      if (typeof text === 'string') {
        return text.replace(/ /g, '\u00a0');
      }
      return text;
    };
  }

  app.filter('htmlSpaces', htmlSpaces);
})();
