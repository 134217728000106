import templateUrl from './radio-group.html'
import './radio-group.scss'

const RadioGroupComponent = {
  bindings: {},
  templateUrl,
  require: {
    fieldCtrl: '?^cdblFieldContainer',
    ngModel: '?ngModel'
  },
  transclude: true,
  controller: class RadioGroupComponent {
    constructor ($element, $attrs) {
      'ngInject'
      this._identify = 'RadioGroupComponent'
      this.$element = $element
      this.$attrs = $attrs
    }

    $onInit () {
      if (this.fieldCtrl) {
        this.fieldCtrl.setFieldElementCtrl({
          ngModel: this.ngModel,
          $element: this.$element,
          $attrs: this.$attrs
        })
      }

      // const self = this
      // this.mouseActive = false
      this.$element
        .attr({
          'role': 'radiogroup',
          // 'tabIndex': this.$element.attr('tabindex') || '0'
        })
        // .on('keydown', function (evt) {
        //   const keyCode = evt.which || evt.keyCode
        //   if (keyCode !== this.$mdConstant.KEY_CODE.ENTER &&
        //     evt.currentTarget !== evt.target) {
        //     return
        //   }
        // })
        // .on('mousedown', function () {
        //   self.mouseActive = true
        //   self.$timeout(function () {
        //     self.mouseActive = false
        //   }, 100)
        // })
    }

    $onDestroy () {
      if (this.fieldCtrl) {
        this.fieldCtrl.setFieldElementCtrl(null)
      }
    }

    get modelValue () {
      return this.ngModel.$viewValue
    }

    set modelValue (newValue) {
      this.ngModel.$setViewValue(
        newValue
      )
    }
  }
}

export default RadioGroupComponent
