import templateUrl from './project-review-modal.html'
import templateUrlFormReview from './form-review.html'
import templateUrlFormNps from './form-nps.html'
import templateUrlSocialButtons from './social-buttons.html'

import './project-review-modal.scss'

const ProjectReviewModalComponent = {
  bindings: {
    project: '<'
  },
  templateUrl,
  require: {
    modalCtrl: '^^cdblModal'
  },
  controller: class ProjectReviewModal {
    constructor (Configuration, $state, EventEmitter, ProjectService, UserService, ProjectReviewService, SegmentAnalytics) {
      'ngInject'
      this._identify = 'ProjectReviewModalComponent'
      this.Configuration = Configuration
      this.$state = $state
      this.EventEmitter = EventEmitter
      this.ProjectService = ProjectService
      this.UserService = UserService
      this.ProjectReviewService = ProjectReviewService
      this.SegmentAnalytics = SegmentAnalytics

      this.templateFormReview = templateUrlFormReview
      this.templateFormNps = templateUrlFormNps
      this.templateSocialButtons = templateUrlSocialButtons

      this.steps = ['review', 'promote']
      this.step = 'review'

      this.reviewData = {
        score: null,
        communication: null,
        responsiveness: null,
        comment: '',
        socialMediaConsent: false
      }


      this.npsData = {
        score: null,
        comment: ''
      }

      this.isProcessing = false
      this.isReviewSubmitted = false
      this.isNpsSubmitted = false
    }

    $onInit () {


    }

    goToStep (step) {
      if (this.steps.includes(step)) {
        this.step = step
      } else {
        console.error('[ProjectReviewModalComponent] > goToStep > step does not exist', step)
      }
    }


    sendReview () {
      console.log('[ProjectReviewModalComponent] > sendReview', this.reviewData)
      this.reviewForm.$setSubmitted()

      if (this.reviewForm.$valid) {
        // TODO send review
        // TODO track

        this.isProcessing = true
        this.ProjectReviewService
          .postReview(this.project.id, this.reviewData)
          .then(response => {
            console.log('[ProjectReviewModalComponent] > postReview > response', response)
            this.isReviewSubmitted = true
            this.project.reviewNeeded = false // set property so that button in workoom is hidden

            if (this.UserService.isClient()) {
              this.SegmentAnalytics.trackExpertReviewed(this.project, this.reviewData)
              this.goToStep('promote')
            } else {
              this.SegmentAnalytics.trackClientReviewed(this.project, this.reviewData)
              this.modalCtrl.close()
            }
          })
          .catch(err => {
            console.error('[ProjectReviewModalComponent] > postReview', err)
            // TODO show server error message?
          })
          .finally(() => {
            this.isProcessing = false
          })
      }
    }

    sendNps () {
      console.log('[ProjectReviewModalComponent] > sendNps', this.npsData)
      this.npsForm.$setSubmitted()

      if (this.npsForm.$valid) {
        // TODO send review
        // TODO track
        const payload = {
          taskId: this.project.id,
          userId: this.UserService.user.id,
          score: this.npsData.score
        }
        if (this.npsData.comment !== '') {
          payload.whatCouldBeBetter = this.npsData.comment
        }

        this.isProcessing = true
        this.ProjectReviewService
          .postNetPromotorScore(payload)
          .then(response => {
            console.log('[ProjectReviewModalComponent] > postNetPromotorScore > response', response)
            this.isNpsSubmitted = true
            this.SegmentAnalytics.trackNpsSubmitted({
              npsScore: this.npsData.score,
              userId: this.UserService.user.id
            })

            if (this.npsData.score < 8) {
              this.modalCtrl.close()
            }
          })
          .catch(err => {
            console.error('[ProjectReviewModalComponent] > postNetPromotorScore', err)
            // TODO show server error message?
          })
          .finally(() => {
            this.isProcessing = false
          })
      }
    }

    redirectToDashboard () {
      this.modalCtrl.close()
      // this.$state.go(this.Configuration.defaultRouterState.name, this.Configuration.defaultRouterState.options)
    }
  }
}

export default ProjectReviewModalComponent
