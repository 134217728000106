import templateUrl from './project-convert-type-button.html'
import './project-convert-type-button.scss'

const ProjectConvertTypeButtonComponent = {
  bindings: {
    project: '<'
  },
  templateUrl,
  controller: class ProjectConvertTypeButton {
    constructor (ModalService) {
      'ngInject'
      this._identify = 'ProjectConvertTypeButtonComponent'
      this.ModalService = ModalService
    }

    $onInit () {

    }

    openConvertToConsultationModal ($event) {
      $event.preventDefault()
      $event.stopPropagation()

      this.ModalService.open({
        closeOnBackdrop: false,
        closeIcon: false,
        layout: 'center',
        class: 'project-cancel-modal',
        template: `<cdbl-project-convert-type-modal project="$ctrl.modal.project"></cdbl-project-convert-type-modal>`,
        project: this.project
      })
    }
  }
}

export default ProjectConvertTypeButtonComponent
