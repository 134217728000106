import templateUrl from './header.html'
import './header.scss'

const HeaderComponent = {
  bindings: {},
  templateUrl,
  controller: class HeaderComponent {
    constructor (SegmentAnalytics, UserService, UIService, AuthService, Configuration, $state) {
      'ngInject'
      this.SegmentAnalytics = SegmentAnalytics
      this.UserService = UserService
      this.UIService = UIService
      this.AuthService = AuthService
      this.collapseMenu = true
      this.Configuration = Configuration
    }

    get showTriggerSidebarMainLeft () {
      const sidebarCtrl = this.UIService.getComponent('sidebar-main-left')
      return Boolean(sidebarCtrl)
    }

    get isOpenSidebarMainLeft () {
      const sidebarCtrl = this.UIService.getComponent('sidebar-main-left')
      if (sidebarCtrl) {
        return sidebarCtrl.isOpen
      }

      return false
    }

    toggleSidebarMainLeft () {
      const sidebarCtrl = this.UIService.getComponent('sidebar-main-left')
      if (sidebarCtrl) {
        sidebarCtrl.toggle()
      }
    }
  }
}

export default HeaderComponent
