// TODO: current state of activity stream is not completed yet - it currently supports legacy templates however the templates system should be upgraded to components. This is useful as each component per verb can then have smart logic embeded. Example see scope doc - which could be further improverd if activity item would accept components and not only templates

import activityTemplateDefault from './activity-default.template.html'

// Because this are still old templates use same name to avoid confusion - disable camelcase only variables here
/* eslint-disable camelcase */
import create_estimate from './templates/create_estimate.html'
import create_sub_task from './templates/create_sub_task.html'
import create_attachment from './templates/create_attachment.html'
import create_comment from './templates/create_comment.html'
import cancel_sub_task from './templates/cancel_sub_task.html'
import complete_sub_task from './templates/complete_sub_task.html'
import complete_task from './templates/complete_task.html'
import project_refund_successful from './templates/project-refund-successful.html'
import sub_task_refund_successful from './templates/sub-task-refund-successful.html'
import destroy_attachment from './templates/destroy_attachment.html'
import destroy_vault from './templates/destroy_vault.html'
import payment_subscription_canceled from './templates/payment_subscription_canceled.html'
import payment_successful from './templates/payment_successful.html'
import sub_task_payment_successful from './templates/sub_task_payment_successful.html'
import retainer_subscription_succeeded from './templates/retainer_subscription_succeeded.html'
import retainer_subscription_failed from './templates/retainer_subscription_failed.html'
import retainer_subscription_created from './templates/retainer_subscription_created.html'
import retainer_subscription_deleted from './templates/retainer_subscription_deleted.html'
import retainer_subscription_renewed from './templates/retainer_subscription_renewed.html'

import project_scope_doc_activity from './templates/project_scope_doc_activity.html'
/* eslint-enable camelcase */

import './activity.scss'

const ActivityComponent = {
  bindings: {
    activity: '<',
    task: '<'
  },
  require: {
    parent: '^cdblActivityStream'
  },
  template: `
    <div id="{{ ::$ctrl.activity.id }}" class="{{ ::$ctrl.className }}" ng-include="$ctrl.getTemplate()" ng-cloak></div>
  `,
  // <pre class="debug">{{ ::$ctrl.activity.id }} | {{$ctrl.activity.verb}}</pre>
  controller: class Activity {
    constructor (UserService, $scope) {
      'ngInject'
      this._identify = 'ActivityComponent'
      this.UserService = UserService

      // TODO: refactor templates by removing scope variables and using isolated component variables
      // Hack for existing templates
      this.$scope = $scope
      // console.log('Activity > $scope:', $scope);

      this.templates = {
        'default': activityTemplateDefault,

        create_attachment,
        create_comment,
        create_estimate,
        create_sub_task,
        cancel_sub_task,
        complete_sub_task,
        complete_task,
        project_refund_successful,
        sub_task_refund_successful,
        destroy_attachment,
        destroy_vault,
        payment_subscription_canceled,
        payment_successful,
        sub_task_payment_successful,
        retainer_subscription_succeeded,
        retainer_subscription_failed,
        retainer_subscription_created,
        retainer_subscription_deleted,
        retainer_subscription_renewed,

        project_scope_doc_activity
      }
    }

    $onInit () {
      this.templateName = this.activity.verb

      // Temporary workaround to group activities and handle them in one template/component - we could potentially use newly added trackable_type for grouping
      // TODO: move to config
      const scopeDocVerbs = [
        'create_scope_doc_submission',
        'create_scope_doc_rejection',
        'create_scope_doc_approval',
        'create_scope_doc_finish',
        'create_scope_doc_item_finish'
      ]

      // Exceptions
      if (this.templateName === 'update_comment') {
        this.templateName = 'create_comment' // use same template for update as it is for create comment activity
      } else if (this.templateName === 'update_estimate') {
        this.templateName = 'create_estimate' // use same template for update as it is for create comment activity
      } else if (scopeDocVerbs.includes(this.activity.verb)) {
        this.templateName = 'project_scope_doc_activity'
      }

      this.className = this.activity ? `activity--${this.templateName}` : ``
    }

    getTemplate () {
      return this.activity && this.templateName && this.templates[this.templateName] ? this.templates[this.templateName] : this.templates.default
    }

    get activityDate () {
      // DO NOT use trackable date as it changes with trackable entity updates apparently
      // if (this.activity && this.activity.trackable && this.activity.trackable.createdAt) {
      //   return this.activity.trackable.createdAt
      // }

      return this.activity.createdAt
    }
  }
}

export default ActivityComponent
