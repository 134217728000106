import ExpertProfilePortfolioComponent from './expert-profile-portfolio.component'
// import ExpertProfilePortfolioService from './expert-profile-portfolio.service'

const ExpertProfilePortfolioModule = angular.module('cdbl.expert-profile-portfolio', [

])
  .component('cdblExpertProfilePortfolio', ExpertProfilePortfolioComponent)
  // .service('ExpertProfilePortfolioService', ExpertProfilePortfolioService)
  .name

export default ExpertProfilePortfolioModule
