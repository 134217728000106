import templateUrl from './toolbar.html'
import './toolbar.scss'

const ToolbarComponent = {
  bindings: {
    _template: '@?template'
  },
  transclude: {
    title: '?toolbarTitle',
    subtitle: '?toolbarSubtitle',
    actionsLeft: '?toolbarActionsLeft',
    actionsRight: '?toolbarActionsRight',
  },
  templateUrl,
  controller: class ToolbarComponent {
    constructor ($attrs, $element, UtilService) {
      'ngInject'
      this._identify = 'ToolbarComponent'
      this.$element = $element
      this.UtilService = UtilService
    }

    $onInit () {
      this.template = this._template ? this._template : 'default'
      this.UtilService.addClass(this.$element[0], `toolbar-template--${this.template}`)
    }
  }
}

export default ToolbarComponent
