(function () {
  'use strict';

  function createOverflowComments($window) {
    return {
      restrict: 'A',
      link: function ($scope, elem) {
        var mainPaddingBottom = parseInt(angular.element('#mainContent').css('padding-bottom'), 10) || 0;
        var modulePaddingBottom = parseInt(elem.parent().css('padding-bottom'), 10) || 0;
        var formHeight = 0;
        var formMarginTop = 0;
        let currentHeight;

        $scope.$on('commentsFormDimensions', function (event, dimensions) {
          formHeight = dimensions.height;
          formMarginTop = dimensions.marginTop;

          setTimeout(resizeComments);
        });

        $scope.$watch('task.isEditable()', function (value) {
          if (value === false) {
            formHeight = 0;
            resizeComments();
          }
        });

        $scope.$on('flashDirChange', function () {
          setTimeout(resizeComments);
        });

        $($window).bind('resize', function () {
          resizeComments();
        });

        function resizeComments() {
          var windowHeight = $($window).height();
          var commentsOffsetTop = elem.offset().top;

          // Check if virtual keyboard has been shown
          if (currentHeight === undefined || Math.abs(currentHeight - windowHeight) < 30) { // only resize if keyboard hasn't been shown
            // console.log('keyboard not shown/hidden');
            var commentsHeight = windowHeight - commentsOffsetTop - formHeight - formMarginTop - mainPaddingBottom - modulePaddingBottom;
            elem.css({height: commentsHeight + 'px'});
          } else {
            // console.log('keyboard shown/hidden');
            // do nothing
          }

          currentHeight = windowHeight;
        }
      }
    };
  }

  app.directive('createOverflowComments', ['$window', createOverflowComments]);
})();
