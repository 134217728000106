import templateUrl from './notifications-list.html'
import './notifications-list.scss'

// TODO: move to configuration
const CHANNEL_NOTIFICATIONS = 'notifications'
const EVENT_NOTIFICATIONS_PAGE_LIST_UPDATED = 'notifications-page-list-updated'
const EVENT_NOTIFICATIONS_WIDGET_LIST_UPDATED = 'notifications-widget-list-updated'
// ---

const NotificationsListComponent = {
  bindings: {},
  templateUrl,
  controller: class NotificationsList {
    constructor ($stateParams, $state, NotificationsService, UserService, PaginationService, EventBusService, Configuration) {
      'ngInject'
      this._identify = 'NotificationsListComponent'
      this.$stateParams = $stateParams
      this.$state = $state
      this.NotificationsService = NotificationsService
      this.UserService = UserService
      this.PaginationService = PaginationService
      this.EventBusService = EventBusService
      this.Configuration = Configuration

      this.notificationsList = null
      this.isLoading = false
      this.pagination = null
      this.isPageImportant = false // additional flag to support separate page because important notifications are still classified as new-projects with filter
    }

    $onInit () {
      this.config = this.Configuration.features.notifications.categories[this.$stateParams.listId]

      if (typeof this.$stateParams.listId === 'undefined' || this.$stateParams.listId === 'default' || !this.config) {
        const categoryName = this.UserService.isClient() ? 'all' : 'new-projects'
        this.config = this.Configuration.features.notifications.categories[categoryName]
      }

      // Exception for notification page because important notifications are displayed as separate page and as default for expert - they still are under `new-projects` category as they are used within `new-projects` for widget. In future when we know more how this will work it can become part of the notification category configuration.
      this.isPageImportant = this.$stateParams.listId === 'default' && this.UserService.isExpert()

      this.pagination = this.PaginationService.init({
        itemsPerPage: 20,
        mode: 'server'
      })

      // Process pagination from URL
      if (this.$stateParams.page) {
        this.pagination.setCurrentPage(this.$stateParams.page)
      }

      this.fetchNotificationsList()

      // If notifications are updated via widget but page is opened - page should listen to widget event and refetch data
      this.EventBusService.channel(CHANNEL_NOTIFICATIONS)

      // TODO: init EventBusService subscription to new pusher notification events to update the list
      this.subscriptionCollection = this.EventBusService.createSubscriptionCollection(CHANNEL_NOTIFICATIONS)

      this.subscriptionCollection.subscribe(EVENT_NOTIFICATIONS_WIDGET_LIST_UPDATED, ($event) => {
        if ($event && $event.categoryName === this.config.name) {
          this.fetchNotificationsList(false)
        }
      })

      this.subscriptionCollection.subscribe(this.Configuration.features.notifications.allowed, $event => {
        this.onNewNotificationHandler($event)
      })

      this.dateSeparators = {}
    }

    $onDestroy () {
      this.subscriptionCollection.unsubscribeAll()
    }

    onNewNotificationHandler ($event) {
      const newNotification = $event.data

      // Verify category of current page and of new notification to see if applicable to be added to the top of the list
      const canBeAddedAsImportant = this.isPageImportant && newNotification.priority === 'important'
      const canBeAddedAsNormal = !this.isPageImportant && newNotification.priority !== 'important'

      console.log('[NotificationsListComponent] > onNewNotificationHandler', canBeAddedAsImportant, canBeAddedAsNormal, this.config, newNotification)
      if ((canBeAddedAsImportant || canBeAddedAsNormal) && this.config.filter === newNotification.filter) {
        this.notificationsList.unshift(newNotification)
      }
    }

    fetchNotificationsList (useLoadingIndicator = true) {
      console.log('[NotificationsListComponent] > fetchNotificationsList')
      const params = {
        filter: this.config.filter,
        priority: this.isPageImportant ? 'important' : 'normal',
        page: this.pagination.currentPage,
        per_page: this.pagination.itemsPerPage
      }

      if (useLoadingIndicator) {
        this.isLoading = true
      }

      this
        .NotificationsService
        .getNotificationsList(params, this.pagination)
        .then(response => {
          console.log('[NotificationsListComponent] > getNotificationsList', response)
          this.notificationsList = response

          // Autocorrect page param in url to match header page count without reloading view
          if (this.$stateParams.page > 1 && this.$stateParams.page > this.pagination.pageCount) {
            this.$location.search('page', Math.max(1, this.pagination.pageCount))
            this.$location.replace()
          }

          // TODO: mark notifications as seen (isNew = false)
          this.markAllOnPageAsSeen()
        })
        .finally(() => {
          if (useLoadingIndicator) {
            this.isLoading = false
          }
        })
    }

    get listTitle () {
      let title = 'Notifications'

      if (this.config.title) {
        title = this.config.title
      }

      // Exception for Important page as its not defined as separate category
      if (this.isPageImportant) {
        title = 'Important Notifications'
      }

      return title
    }

    get noResultsMessage () {
      let message = 'Sorry no recent notifications found.'

      if (this.config.noResultsMessage) {
        message = this.config.noResultsMessage
      }
      return message
    }


    markAllOnPageAsRead () {
      let ids = []
      if (this.notificationsList) {
        ids = this.notificationsList
          .filter(n => n.isRead === false)
          .map(n => {
            n.isRead = true
            return n.id
          })
      }


      if (ids.length) {
        this.NotificationsService
          .markAllAsRead(ids)
          .then(() => {
            this.EventBusService
              .channel(CHANNEL_NOTIFICATIONS)
              .publish(EVENT_NOTIFICATIONS_PAGE_LIST_UPDATED, {
                categoryName: this.config.name
              })
          })
      }
    }

    markAllOnPageAsSeen () {
      // normal count can be reset by calling BE and reporting which notifications have isNew status to be set to false
      let ids = []
      if (this.notificationsList) {
        ids = this.notificationsList
          .filter(n => n.isNew)
          .map(n => {
            n.isNew = false
            return n.id
          })
      }


      if (ids.length) {
        this.NotificationsService
          .resetNew(ids)
          .then(() => {
            // TODO: SegmentAnalytics.trackNotificationmarkAllAsRead($scope.filter)

            this.EventBusService
              .channel(CHANNEL_NOTIFICATIONS)
              .publish(EVENT_NOTIFICATIONS_PAGE_LIST_UPDATED, {
                categoryName: this.popoverData.category.name
              })
          })
      }
    }

    onNotificationItemActionCallback ($event) {
      console.log('[NotificationsListComponent] > onNotificationItemActionCallback', $event)
      // Refetching the list on action callback will automatically update the list
      // TODO: improve fetching by updating the list and not changing other notification items - just the one action was performed on OR use debounce on the fetching - so last action on FE will make list update from BE
      this.fetchNotificationsList(false)

      this.EventBusService
        .channel(CHANNEL_NOTIFICATIONS)
        .publish(EVENT_NOTIFICATIONS_PAGE_LIST_UPDATED, {
          categoryName: this.config.name
        })
    }

    onPageChangeHandler (page) {
      this.$state.go('notifications.list', {
        page: this.pagination.currentPage
      })
    }
  }
}

export default NotificationsListComponent
