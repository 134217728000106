(function () {
  'use strict';

  var repeatLastElement = function () {
    return {
      restrict: 'A',
      link: function ($scope, element, attrs) {
        $scope.$on('lastElementLoaded', function () {
          $scope.$eval(attrs.repeatLastElement);
        });

        if ($scope.$last) {
          setTimeout(function () { // run after all elements are drawn
            $scope.$eval(attrs.repeatLastElement);
          });
        }
      }
    };
  };

  app.directive('repeatLastElement', repeatLastElement);
})();
