import ProjectReviewModalComponent from './project-review-modal.component'
import ProjectReviewService from './project-review.service'

const ProjectReviewModalModule = angular.module('cdbl.project.project-review-modal', [

])
  .service('ProjectReviewService', ProjectReviewService)
  .component('cdblProjectReviewModal', ProjectReviewModalComponent)
  .name

export default ProjectReviewModalModule
