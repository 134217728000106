import templateUrl from './scope-doc-modal.html'
import './scope-doc-modal.scss'

const ProjectScopeDocModalComponent = {
  bindings: {
    project: '<',
    scopeDocOriginal: '<scopeDoc',
    onUpdate: '&'
  },
  templateUrl,
  require: {
    modalCtrl: '^^cdblModal'
  },
  controller: class ProjectScopeDocModal {
    constructor ($q, $timeout, UserService, ProjectScopeDocService, ProjectScopeDocFactory, EventEmitter, EventBusService, ModalService) {
      'ngInject'
      this._identify = 'ProjectScopeDocModalComponent'
      this.$q = $q
      this.$timeout = $timeout
      this.UserService = UserService
      this.ProjectScopeDocService = ProjectScopeDocService
      this.ProjectScopeDocFactory = ProjectScopeDocFactory
      this.EventEmitter = EventEmitter
      this.EventBusService = EventBusService
      this.ModalService = ModalService

      this.rejectionMessage = ''
      this.showRejectionConfirmation = false
    }

    $onInit () {
      // Fetch scope doc from BE on modal open - currently it is being fetched and passed by sidebar widget
      // this.scopeDoc = null
      // this.ProjectScopeDocService.getScopeDoc(this.project.id)
      //   .then(response => {
      //     this.scopeDoc = response
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })

      this.init()

      // subscribe on pusher data events that update UI and add activity
      this.subscriptionCollection = this.EventBusService.createSubscriptionCollection('legacy')
      this.subscriptionCollection.subscribe([
        'scope-doc-submitted',
        'scope-doc-rejected',
        'scope-doc-approved',
        'scope-doc-finished',
        'scope-doc-completed',
      ], $event => {
        this.onRealTimeEventScopeDocHandler($event)
      })

      this.subscriptionCollectionScopeDocItem = this.EventBusService.createSubscriptionCollection('legacy')
      this.subscriptionCollectionScopeDocItem.subscribe([
        'scope-doc-item-finished',
        'scope-doc-item-unfinished'
      ], $event => {
        this.onRealTimeEventScopeDocItemHandler($event)
      })
    }

    $onDestroy () {
      if (this.subscriptionCollection) {
        this.subscriptionCollection.unsubscribeAll()
      }

      if (this.subscriptionCollectionScopeDocItem) {
        this.subscriptionCollectionScopeDocItem.unsubscribeAll()
      }
    }

    onRealTimeEventScopeDocHandler ($event) {
      console.log('[ProjectScopeDocModalComponent] > onRealTimeEventScopeDocHandler', $event)

      // Execute update only for user who is not an owner of the real time update - those are handled by resolve of endpoint call
      if ($event.data.eventOwner && $event.data.eventOwner.id !== this.UserService.user.id) {
        console.log('[ProjectScopeDocModalComponent] > onRealTimeEventHandler > update')
        this.getUpdatedScopeDoc()
      }
    }

    onRealTimeEventScopeDocItemHandler ($event) {
      console.log('[ProjectScopeDocModalComponent] > onRealTimeEventScopeDocItemHandler', $event, $event.data.eventOwner.id !== this.UserService.user.id)

      // Execute update only for user who is not an owner of the real time update - those are handled by resolve of endpoint call
      if ($event.data.eventOwner && $event.data.eventOwner.id !== this.UserService.user.id) {
        const item = this.scopeDoc.findItemById($event.data.id)
        if (item) {
          item.finishedAt = $event.data.finishedAt
        }
        console.log('[ProjectScopeDocModalComponent] > onRealTimeEventScopeDocItemHandler > update', item)
      }
    }

    init () {
      if (!this.scopeDocOriginal && this.UserService.isExpert()) {
        // Generate new blank scope doc on modal open if scope doc does not exist yet on BE and current user is expert
        this.scopeDoc = this.ProjectScopeDocService.generateScopeDoc('blank')
      } else if (this.scopeDocOriginal) {
        // Create a copy of the original scope doc object so changes are not reflected back to component that invoked/passed it (sidebar widget)until confirmed by user (use of onUpdate callback)
        this.scopeDoc = new this.ProjectScopeDocFactory(JSON.parse(JSON.stringify(this.scopeDocOriginal)))
        console.log('[ProjectScopeDocModalComponent] > init', this.scopeDocOriginal, this.scopeDoc)
      } else {
        // TODO: handle edge case / prevention if client opens modal and scope doc does not exist - close it directly?
      }
    }

    getUpdatedScopeDoc () {
      this.ProjectScopeDocService.getScopeDoc(this.project.id)
        .then(response => {
          console.log('ProjectScopeDocModalComponent > getUpdatedScopeDoc', response)
          this.scopeDoc = new this.ProjectScopeDocFactory(response)
        })
        .catch(err => {
          console.log(err)
        })
    }

    get isViewMode () {
      return this.ProjectScopeDocService.isViewMode(this.scopeDoc)
    }

    get isDraftMode () {
      return this.ProjectScopeDocService.isDraftMode(this.scopeDoc)
    }

    get showActions () {
      // This area is meant for expansion - if other confirmations are neeed make checks here
      return !this.showRejectionConfirmation
    }


    // called by edit form to register form controller and pass it back to parent component (modal)
    onEditFormInitHandler ($event) {
      console.log('[ProjectScopeDocModalComponent] > onEditFormInitHandler', $event)
      this._getEditFormCtrl = $event.getFormCtrl
      this._setEditFormPristine = $event.setFormPristine
      this._isFormReady = $event.isFormReady
    }

    get isEditFormReady () {
      if (typeof this._isFormReady === 'function') {
        return this._isFormReady()
      }
      return false
    }

    get editForm () {
      if (typeof this._getEditFormCtrl === 'function') {
        return this._getEditFormCtrl()
      }
      return null
    }

    setFormPristine () {
      console.log('[ProjectScopeDocModalComponent] > setFormPristine')
      if (typeof this._setEditFormPristine === 'function') {
        console.log('[ProjectScopeDocModalComponent] > setFormPristine > _setEditFormPristine', this._setEditFormPristine)
        return this._setEditFormPristine()
      }
    }

    // called by view form to register form controller and pass it back to parent component (modal)
    onViewFormInitHandler ($event) {
      this._getViewFormCtrl = $event.getFormCtrl
    }

    get viewForm () {
      if (typeof this._getViewFormCtrl === 'function') {
        return this._getViewFormCtrl()
      }
      return null
    }

    get statusModalText () {
      return this.ProjectScopeDocService.getStateMappedProp('statusModal', this.scopeDoc)
    }

    onEditUpdateHandler ($event) {
      this.scopeDoc = $event.scopeDoc // update modal view without affecting the parent components (widget), those should be updated only on BE save/change
    }

    updateScopeDoc (scopeDoc) {
      // update scope doc object in modal and view/edit subcomponents (change detection done via one way bindings)
      this.scopeDoc = scopeDoc

      // Update external component that triggered modal to also update its view by calling their handler
      if (this.onUpdate) {
        this.onUpdate(this.EventEmitter({ scopeDoc: this.scopeDoc }))
      }
    }

    mapUploadersToUpdatedScopeDoc (newScopeDoc, oldScopeDoc) {
      newScopeDoc._edit.attachmentsQueueCtrl = oldScopeDoc._edit.attachmentsQueueCtrl

      for (const [index, item] of oldScopeDoc.scopeDocItems.entries()) {
        if (newScopeDoc.scopeDocItems[index]) {
          newScopeDoc.scopeDocItems[index]._edit.attachmentsQueueCtrl = item._edit.attachmentsQueueCtrl
          for (const [childIndex, childItem] of item.scopeDocChildItems.entries()) {
            if (newScopeDoc.scopeDocItems[index].scopeDocChildItems[childIndex]) {
              newScopeDoc.scopeDocItems[index].scopeDocChildItems[childIndex]._edit.attachmentsQueueCtrl = childItem._edit.attachmentsQueueCtrl
            }
          }
        }
      }
    }

    uploadAttachments (scopeDoc) {
      const promises = []

      // Add global attachments
      const globalQueueCtrl = scopeDoc._edit.attachmentsQueueCtrl
      if (globalQueueCtrl && globalQueueCtrl.queueList && globalQueueCtrl.queueList.length) {
        const globalPromise = globalQueueCtrl.entityUploader({
          entitySignedUrlsUrlSlug: `/experts/projects/${this.project.id}/scope_doc/attachments/signed_urls`,
          entityAttachUrlSlug: `/experts/projects/${this.project.id}/scope_doc/attachments`
        })
          .then(response => {
            console.log('[ProjectScopeDocModalComponent] > entityUploader > global response', JSON.parse(JSON.stringify(response)))

            if (response && Array.isArray(response)) {
              response.forEach(attachmentWrapper => {
                if (attachmentWrapper.attachment) {
                  scopeDoc.attachments.push(attachmentWrapper.attachment)
                }
              })
            }

            return globalQueueCtrl
          })

        promises.push(globalPromise)
      }

      scopeDoc.scopeDocItems.forEach(item => {
        // Add parent item attachments
        const itemQueueCtrl = item._edit.attachmentsQueueCtrl
        if (itemQueueCtrl && itemQueueCtrl.queueList && itemQueueCtrl.queueList.length) {
          const itemPromise = itemQueueCtrl.entityUploader({
            entitySignedUrlsUrlSlug: `/experts/projects/${this.project.id}/scope_doc/scope_doc_items/${item.id}/attachments/signed_urls`,
            entityAttachUrlSlug: `/experts/projects/${this.project.id}/scope_doc/scope_doc_items/${item.id}/attachments`
          })
            .then(response => {
              console.log('[ProjectScopeDocModalComponent] > entityUploader > item response', JSON.parse(JSON.stringify(response)))
              // itemQueueCtrl.resetQueueList()
              if (response && Array.isArray(response)) {
                response.forEach(attachmentWrapper => {
                  if (attachmentWrapper.attachment) {
                    item.attachments.push(attachmentWrapper.attachment)
                  }
                })
              }

              return itemQueueCtrl
            })

          promises.push(itemPromise)
        }

        // Add child item attachments
        item.scopeDocChildItems.forEach(childItem => {
          const childItemQueueCtrl = childItem._edit.attachmentsQueueCtrl
          if (childItemQueueCtrl && childItemQueueCtrl.queueList && childItemQueueCtrl.queueList.length) {
            const childItemPromise = childItemQueueCtrl.entityUploader({
              entitySignedUrlsUrlSlug: `/experts/projects/${this.project.id}/scope_doc/scope_doc_items/${childItem.id}/attachments/signed_urls`,
              entityAttachUrlSlug: `/experts/projects/${this.project.id}/scope_doc/scope_doc_items/${childItem.id}/attachments`
            })
              .then(response => {
                console.log('[ProjectScopeDocModalComponent] > entityUploader > child item response', JSON.parse(JSON.stringify(response)))

                if (response && Array.isArray(response)) {
                  response.forEach(attachmentWrapper => {
                    if (attachmentWrapper.attachment) {
                      childItem.attachments.push(attachmentWrapper.attachment)
                    }
                  })
                }

                return childItemQueueCtrl
              })

            promises.push(childItemPromise)
          }
        })
      })

      console.log('[ProjectScopeDocModalComponent] > uploadAttachments > promises', promises)

      return this.$q
        .all(promises)
        .then(response => {
          console.log('[ProjectScopeDocModalComponent] > uploadAttachments > RESOLVED!!!!', response)
          return response
        })
        // .catch(err => {
        //   console.log('[ProjectScopeDocModalComponent] > uploadAttachments > ERROR!!!!', err)
        // })
    }

    saveScopeDoc () {
      console.log('[ProjectScopeDocModalComponent] > saveScopeDoc > START')
      this.isSaving = true
      this.ProjectScopeDocService.saveScopeDoc(this.scopeDoc, this.project.id)
        .then(response => {
          this.mapUploadersToUpdatedScopeDoc(response, this.scopeDoc)
          console.log('[ProjectScopeDocModalComponent] > saveScopeDoc > mapUploaders', response)
          this.updateScopeDoc(response)
          this.uploadAttachments(this.scopeDoc)
            .then(response => {
              console.log('[ProjectScopeDocModalComponent] > saveScopeDoc > uploadAttachments > completed', response)

              response.forEach(attachmentCtrl => {
                console.log('[ProjectScopeDocModalComponent] > saveScopeDoc > uploadAttachments > completed > attachmentCtrl', attachmentCtrl)
                const queueList = attachmentCtrl.resetQueueList()
                console.log('[ProjectScopeDocModalComponent] > saveScopeDoc > uploadAttachments > completed > queueList', queueList)
                // attachmentCtrl.queueList.length = 0
              })

              this.setFormPristine()
              this.isSaving = false
            })
            .catch(err => {
              console.log('[ProjectScopeDocModalComponent] > saveScopeDoc > uploadAttachments > ERROR!!!!', err)
            })
            .finally(() => {
              console.log('[ProjectScopeDocModalComponent] > saveScopeDoc > uploadAttachments > finally', this.scopeDoc)
              this.isSaving = false
            })
        })
        .catch(err => {
          console.log('[ProjectScopeDocModalComponent] > saveScopeDoc > ERROR', err)
        })
        .finally(() => {
          console.log('[ProjectScopeDocModalComponent] > saveScopeDoc > FINALLY', this.scopeDoc)
        })

      console.log('[ProjectScopeDocModalComponent] > saveScopeDoc > END')
    }

    submitScopeDoc () {
      this.ProjectScopeDocService.submitScopeDoc(this.project.id)
        .then(response => {
          this.updateScopeDoc(response)
        })
    }

    openRejectConfirmation () {
      this.showRejectionConfirmation = true
    }

    closeRejectConfirmation () {
      this.showRejectionConfirmation = false
    }

    rejectScopeDoc () {
      this.ProjectScopeDocService.rejectScopeDoc(this.rejectionMessage, this.project.id)
        .then(response => {
          this.updateScopeDoc(response)

          this.modalCtrl.close()
        })
    }

    approveScopeDoc () {
      this.ProjectScopeDocService.approveScopeDoc(this.project.id)
        .then(response => {
          this.updateScopeDoc(response)
        })
    }

    onScopeDocItemFinishToggleHandler ($event) {
      if (!$event || !$event.item) {
        return
      }

      const item = $event.item
      if (item.isFinished) {
        return this.ProjectScopeDocService.unfinishScopeDocItem(item.id, this.project.id)
          .then(response => {
            // this.updateScopeDoc(response)
            return response
          })
      } else {
        return this.ProjectScopeDocService.finishScopeDocItem(item.id, this.project.id)
          .then(response => {
            // this.updateScopeDoc(response)
            return response
          })
      }
    }

    finishScopeDoc () {
      this.ProjectScopeDocService.finishScopeDoc(this.project.id)
        .then(response => {
          this.updateScopeDoc(response)
        })
    }

    completeScopeDoc () {
      this.ProjectScopeDocService.completeScopeDoc(this.project.id)
        .then(response => {
          this.updateScopeDoc(response)

          // open review modal
          this.ModalService.open({
            closeOnBackdrop: false,
            closeIcon: false,
            layout: 'center',
            class: 'project-review-modal',
            template: `<cdbl-project-review-modal project="$ctrl.modal.project"></cdbl-project-review-modal>`,
            project: this.project
          })
        })
    }
  }
}

export default ProjectScopeDocModalComponent
