export default {
  CDBL_ENV: 'production', // will be overriden by webpack
  isProd: true, // will be overriden by webpack
  isBuild: true, // will be overriden by webpack
  apiUrl: 'https://api.codeable.io',
  auth: {
    tokenRenewalInterval: 21600,
  },
  settings: {
    tokenRenewalInterval: 21600, // TODO: deprecate this
    maxAllowedExpertTags: 20
  },
  pusher: {
    appKey: '04d90e7f0d56d5d89b48',
    authEndpoint: 'https://api.codeable.io/pusher/auth'
  },
  intercom: {
    appId: 'jfwhinpl'
  },
  paypal: {
    env: 'production',
    clientId: 'AbX5RxC2610E0MQgsQnColbt2078RerkwRsmn8_mlfgl7MpiQn3avciM5-zI'
  },
  growthbook: {
    apiKey: 'sdk-vhs8amJGPXcfgwC'
  },
  segmentAnalytics: 'eX0xXiR8M93UsHjdhkfC66gZ0JejvMhl',
  sentry: {
    dns: 'https://cce4182e0bc140b8ab8dab956a46c9bc@o26974.ingest.sentry.io/107328',
  },
  googleAnalytics: 'G-BLQNYK2YHW',
  googleClientId: '216260975019-almd9l73ulf2mk9k7jpjkrd75g9267cr.apps.googleusercontent.com'
}
