import templateUrl from './attachments-upload.html'
import './attachments-upload.scss'
import { MissingArgumentsError } from '@/app/common/errors'

const MODE_AUTO_UPLOAD = 'auto'
const UI_BUTTON = 'button'
const UI_DRAGDROP = 'dragdrop'
const AttachmentsUploadComponent = {
  bindings: {
    ui: '@?',
    entityApiConfig: '<?',
    mode: '<?',
    showQueue: '<?',
    showQueueItemUploadProgress: '<?',
    showQueueUploadProgress: '<?',
    showUploadQueueButton: '<?',
    showUploadCancelButton: '<?',
    hideQueueItemOnUploaded: '<?',
    projectId: '<',
    initQueueCtrl: '<',
    onSelect: '&',
    onFileUploaded: '&',
    onUploadFinish: '&',
    showError: '<?'
  },
  templateUrl,
  controller: class AttachmentsUpload {
    constructor ($q, $timeout, $window, $element, AttachmentsService, ErrorsService, EventEmitter, FileUploadWrapperFactory) {
      'ngInject'
      this._identify = 'AttachmentUploadComponent'
      this.$q = $q
      this.$timeout = $timeout
      this.$window = $window
      this.$element = $element
      this.AttachmentsService = AttachmentsService
      this.ErrorsService = ErrorsService
      this.EventEmitter = EventEmitter
      this.FileUploadWrapperFactory = FileUploadWrapperFactory
    }

    $onInit () {
      // if (!this.projectId) {
      //   throw new MissingArgumentsError()
      // }

      console.log('[AttachmentUploadComponent] > $onInit', this.mode)
      this.isUploading = false

      const allowedUI = [UI_DRAGDROP, UI_BUTTON]

      this.ui = allowedUI.includes(this.ui) ? this.ui : UI_BUTTON
      this.mode = this.mode || MODE_AUTO_UPLOAD
      this.showQueue = this.showQueue || false
      this.showError = typeof this.showError !== 'undefined' ? this.showError : true
      this.showQueueItemUploadProgress = this.showQueueItemUploadProgress || false
      this.showQueueUploadProgress = typeof this.showQueueUploadProgress !== 'undefined' ? this.showQueueTotalUploadProgress : true
      this.showUploadQueueButton = this.showUploadQueueButton || false
      this.showUploadCancelButton = typeof this.showUploadCancelButton !== 'undefined' ? this.showUploadCancelButton : true
      this.hideQueueItemOnUploaded = typeof this.hideQueueItemOnUploaded !== 'undefined' ? this.hideQueueItemOnUpload : true
      this.errorHandler = this.ErrorsService.createErrorHandler()
      this.elFileUploadField = this.$element[0].querySelector('#cdblAttachmentFileField')

      this.queueList = (this.initQueueCtrl && this.initQueueCtrl.queueList) ? this.initQueueCtrl.queueList : []
    }

    $onDestroy () {
      this.removeSelectDialogListeners()
    }

    onDropHandler ($event) {
      console.log('[AttachmentUploadComponent] > onDropHandler > $event', $event, $event.originalEvent.dataTransfer.files)
      $event.preventDefault()
      // evt.stopPropagation()
      const dataTransfer = $event.dataTransfer || $event.originalEvent.dataTransfer
      if (dataTransfer.files) {
        // Use DataTransfer interface to access the file(s)
        for (let i = 0; i < dataTransfer.files.length; i++) {
          console.log('[AttachmentUploadComponent] > onDropHandler > file[' + i + '].name = ' + dataTransfer.files[i].name)
        }

        this.onFileSelectChangeHandler(Array.from(dataTransfer.files))
      }
    }
    onDragOverHandler (evt) {
      // console.log('[AttachmentUploadComponent] > onDragOverHandler > evt', evt)
      evt.preventDefault()
    }

    cancelAllUpload () {
      // cancel uploading of the attachments that are still in progress
      this.queueList.forEach(wf => wf.cancelUpload())
      this.isUploading = false

      // reset queue
      this.resetQueueList()
      this.errorHandler.reset()
    }

    get uploadProgress () {
      if (this.uploadNumFiles === 0) {
        return 0
      }

      let overallProgress = 0
      this.queueList.forEach(wf => { overallProgress += wf.progress })
      return Math.round(overallProgress / this.queueList.length)
    }

    get uploadInProgress () {
      let inProgress = false
      if (this.queueList) {
        this.queueList.forEach(wf => { inProgress = inProgress || (wf.started && !wf.synced) })
      }

      return inProgress
    }

    get uploadNumFiles () {
      return this.queueList.filter(wf => wf.started && !wf.synced).length
    }

    addSelectDialogListeners () {
      const vm = this
      this.listenerFileUpload = function () {
        const fileList = Array.from(this.files) // convert iterable object to array
        vm.$timeout(vm.onFileSelectChangeHandler(fileList))
      }
      this.elFileUploadField.addEventListener('change', this.listenerFileUpload, false)
    }

    removeSelectDialogListeners () {
      if (this.elFileUploadField) {
        this.elFileUploadField.removeEventListener('change', this.listenerFileUpload)
      }
    }

    openFileSelectDialog () {
      this.errorHandler.reset()
      this.removeSelectDialogListeners() // remove existing listeners
      this.addSelectDialogListeners()    // attach new listeners
      this.elFileUploadField.click() // invoke upload dialog
    }
    /**
     * Remove item from queue
     * @param  {[type]} file wrapper [description]
     */
    removeFromQueue (queuedFile) {
      console.log('[AttachmentsUploadComponent] > removeFromQueue', queuedFile)
      if (!queuedFile) {
        return
      }

      const index = this.queueList.findIndex(qf => qf.name === queuedFile.name)
      if (index > -1) {
        this.queueList.splice(index, 1)
      }
      // update file input - fileList is readonly so it needs to be reset like this
      this.elFileUploadField.value = null

      // after removal make sure to reset isUploading if no files left and upload is currently active (ie last uploading file was canceled)
      if (this.isUploading && this.queueList.length === 0) {
        this.isUploading = false
      }

      this.triggerOnSelect()
    }

    resetQueueList () {
      this.queueList.length = 0 // make sure to keep same array because othewise it will not work because old array reference would still be present in parent controllers

      // update file input - fileList is readonly so it needs to be reset like this
      this.elFileUploadField.value = null

      console.log('[AttachmentsUploadComponent] > resetQueueList', this)
      return this
    }

    uploadQueue () {
      // Safeguarde for the double clicking
      if (this.isUploading) {
        return
      }

      this.isUploading = true

      if (this.entityApiConfig) {
        this.entityUploadFiles()
          .then(response => {
            console.log('[AttachmentsUploadComponent] > uploadQueue > RESOLVE entityUploadFiles', response)
            const newAttachments = response.map(fileWrapper => fileWrapper.attachment)

            this.triggerOnUploadFinish(newAttachments)

            this.resetQueueList()
          })
          .catch(err => { this.errorHandler.abort(err) })
          .finally(() => {
            this.isUploading = false
          })
      } else {
        this.uploadFiles()
          .then(response => {
            console.log('[AttachmentsUploadComponent] > uploadQueue > RESOLVE uploadFiles', response)
            const newAttachments = response.map(fileWrapper => fileWrapper.attachment)

            this.triggerOnUploadFinish(newAttachments)

            this.resetQueueList()
          })
          .catch(err => { this.errorHandler.abort(err) })
          .finally(() => {
            this.isUploading = false
          })
      }
    }

    triggerOnSelect () {
      const vm = this
      if (this.onSelect) {
        this.onSelect(this.EventEmitter({
          get queueList () {
            return vm.queueList
          },
          errorHandler: this.errorHandler,
          uploader: async (projectId) => {
            return this.uploadFiles(projectId)
          },
          entityUploader: async (entityConfig) => {
            return this.entityUploadFiles(entityConfig)
          },
          uploaderCancel: () => {
            this.cancelAllUpload()
          },
          uploadInProgress: () => {
            return this.uploadInProgress
          },
          uploadProgress: () => {
            return this.uploadProgress
          },
          resetQueueList: () => {
            console.log('[AttachmentsUploadComponent] > triggerOnSelect > resetQueueList > this', this)
            this.resetQueueList()
          },
          removeFromQueue: (queueItem) => {
            this.removeFromQueue(queueItem)
          },
          setEntityApiConfig: (config) => { // used for advanced usage where ids of entity is does not exist before adding attachments on the form
            this.entityApiConfig = config
          }
        }))
      }
    }

    triggerOnFileUploaded (newAttachment) {
      if (this.onFileUploaded) {
        this.onFileUploaded(this.EventEmitter({
          attachment: newAttachment
        }))
      }
    }

    triggerOnUploadFinish (newAttachments) {
      if (this.onUploadFinish) {
        this.onUploadFinish(this.EventEmitter({
          uploadedFiles: newAttachments
        }))
      }
    }

    onFileSelectChangeHandler (fileList = []) {
      const uniqueFileList = fileList
        .filter(fileObject => !this.queueList.find(qFile => qFile.name === fileObject.name))
        .map(fileObject => new this.FileUploadWrapperFactory({ fileObject }))

      this.queueList.push(...uniqueFileList)

      console.log('[AttachmentsUploadComponent] > onFileSelectChangeHandler', fileList)
      if (this.mode === MODE_AUTO_UPLOAD) {
        console.log('[AttachmentsUploadComponent] > onFileSelectChangeHandler > START uploadFiles')
        this.uploadQueue()
      } else {
        this.triggerOnSelect()
      }
    }



    async uploadFiles (projectId = this.projectId) {
      const deferred = this.$q.defer()

      if (!this.queueList) {
        deferred.reject(new MissingArgumentsError())
      }

      console.log('[AttachmentsUploadComponent] > uploadFiles', projectId)
      if (this.queueList.length > 0) {
        console.log('[AttachmentsUploadComponent] > uploadFiles > START getAttachmentUploadSignedUrls')
        await this.AttachmentsService
          .getAttachmentUploadSignedUrls(projectId, this.queueList)
          .then(response => {
            const signedUrls = response.filter(f => f.url)
            signedUrls.forEach(sUrl => {
              const wFile = this.queueList.find(f => f.name === sUrl.filename)
              if (wFile) {
                wFile.setSignedUrl(sUrl)
              }
            })

            // update queueList to include only files that can be uploaded
            this.queueList = this.queueList.filter(wf => wf.signedUrlUpload)

            // process errors of files that can not be uploaded
            const errors = response
              .filter(f => f.error)
              .map(f => this.errorHandler.stringToErrorObject(f.error))

            this.errorHandler.add(errors) // TODO show modal
            console.log('[AttachmentsUploadComponent] > uploadFiles > RESOLVE getAttachmentUploadSignedUrls', response)
          })
          .catch(err => { this.errorHandler.abort(err) })
        console.log('[AttachmentsUploadComponent] > uploadFiles > END getAttachmentUploadSignedUrls')
        this.$timeout()

        if (this.errorHandler.aborted) {
          deferred.reject(this.errorHandler)
        }

        console.log('[AttachmentsUploadComponent] > uploadFiles > START uploadToProvider', this.queueList)
        await this.uploadToProvider(projectId, this.queueList)
          .then(response => {
            console.log('[AttachmentsUploadComponent] > uploadFiles > RESOLVE uploadToProvider', response)
          })
          .catch(err => { this.errorHandler.abort(err) })
        console.log('[AttachmentsUploadComponent] > uploadFiles > END uploadToProvider')
        this.$timeout()

        deferred.resolve(this.queueList)
      }

      return deferred.promise
    }

    async uploadToProvider (projectId, queueList) {
      console.log('[AttachmentsUploadComponent] > uploadToProvider > START uploadFileToGCS')
      if (!queueList) {
        throw new MissingArgumentsError()
      }

      const promises = []
      if (queueList.length > 0) {
        queueList.forEach(wrappedFile => {
          wrappedFile.started = true
          wrappedFile.progress = 0
          const p = this.AttachmentsService
            .uploadFileToGCS(wrappedFile)
            .then(async response => {
              // check if response from GCS is undefined - file upload was either canceled or failed
              if (typeof response === 'undefined') {
                wrappedFile.failed = true
                return wrappedFile
              }

              wrappedFile.uploaded = true
              console.log('[AttachmentsUploadComponent] > uploadToProvider > RESOLVE uploadFileToGCS', response, wrappedFile)

              console.log('[AttachmentsUploadComponent] > uploadToProvider > START attachFileToProject', response, wrappedFile)

              await this.AttachmentsService
                .postAttachmentToProject(projectId, wrappedFile.fileObject)
                .then(response => {
                  wrappedFile.synced = true
                  wrappedFile.attachment = response
                  console.log('[AttachmentsUploadComponent] > uploadToProvider > RESOLVE attachFileToProject', wrappedFile)

                  // file added - update list or parent component
                  this.triggerOnFileUploaded(wrappedFile.attachment)
                })
                .catch(err => { this.errorHandler.add(err) })
              console.log('[AttachmentsUploadComponent] > uploadToProvider > END attachFileToProject', wrappedFile.fileObject.name)
              this.$timeout()

              return wrappedFile
            })
            .catch(err => {
              if (err.xhrStatus === 'abort') {
                // handle upload cancelation - nothing to do - item will be gracefully  removed from the queue
              } else {
                // handle the reset of the errors
                this.errorHandler.add('GCS service unavailable. Please try again later.')
              }
            })
          promises.push(p)
        })
      }

      return this.$q
        .all(promises)
        .catch(err => {
          console.log('[AttachmentsUploadComponent] > all catch', err, promises)
          this.errorHandler.add(err)
        })
    }


    async entityUploadFiles (entityConfig) {
      const deferred = this.$q.defer()
      console.log('[AttachmentsUploadComponent] > entityUploadFiles', this.queueList, entityConfig)

      if (!this.queueList || !entityConfig) {
        deferred.reject(new MissingArgumentsError())
      }


      if (this.queueList.length > 0) {
        console.log('[AttachmentsUploadComponent] > entityUploadFiles > START getAttachmentUploadSignedUrls')
        await this.AttachmentsService
          .entityGetSignedUrls(entityConfig.entitySignedUrlsUrlSlug, this.queueList)
          .then(response => {
            const signedUrls = response.filter(f => f.url)
            signedUrls.forEach(sUrl => {
              const wFile = this.queueList.find(f => f.name === sUrl.filename)
              if (wFile) {
                wFile.setSignedUrl(sUrl)
              }
            })

            // update queueList to include only files that can be uploaded
            this.queueList = this.queueList.filter(wf => wf.signedUrlUpload)

            // process errors of files that can not be uploaded
            const errors = response
              .filter(f => f.error)
              .map(f => this.errorHandler.stringToErrorObject(f.error))

            this.errorHandler.add(errors) // TODO show modal
            console.log('[AttachmentsUploadComponent] > entityUploadFiles > RESOLVE getAttachmentUploadSignedUrls', response)
          })
          .catch(err => { this.errorHandler.abort(err) })
        console.log('[AttachmentsUploadComponent] > entityUploadFiles > END getAttachmentUploadSignedUrls')
        this.$timeout()

        if (this.errorHandler.aborted) {
          deferred.reject(this.errorHandler)
        }

        console.log('[AttachmentsUploadComponent] > entityUploadFiles > START uploadToProvider', this.queueList)
        await this.entityUploadToProvider(entityConfig.entityAttachUrlSlug, this.queueList)
          .then(response => {
            console.log('[AttachmentsUploadComponent] > entityUploadFiles > RESOLVE uploadToProvider', response)
          })
          .catch(err => { this.errorHandler.abort(err) })
        console.log('[AttachmentsUploadComponent] > entityUploadFiles > END uploadToProvider')
        this.$timeout()

        deferred.resolve(this.queueList)
      }

      return deferred.promise
    }

    async entityUploadToProvider (entityAttachUrlSlug, queueList) {
      console.log('[AttachmentsUploadComponent] > entityUploadToProvider > START uploadFileToGCS')
      if (!entityAttachUrlSlug || !queueList) {
        throw new MissingArgumentsError()
      }

      const promises = []
      if (queueList.length > 0) {
        queueList.forEach(wrappedFile => {
          wrappedFile.started = true
          wrappedFile.progress = 0
          const p = this.AttachmentsService
            .uploadFileToGCS(wrappedFile)
            .then(async response => {
              // check if response from GCS is undefined - file upload was either canceled or failed
              if (typeof response === 'undefined') {
                wrappedFile.failed = true
                return wrappedFile
              }

              wrappedFile.uploaded = true
              console.log('[AttachmentsUploadComponent] > entityUploadToProvider > RESOLVE uploadFileToGCS', response, wrappedFile)

              console.log('[AttachmentsUploadComponent] > entityUploadToProvider > START attachFileToProject', response, wrappedFile)

              await this.AttachmentsService
                .entityAttachFile(entityAttachUrlSlug, wrappedFile.fileObject)
                .then(response => {
                  wrappedFile.synced = true
                  wrappedFile.attachment = response
                  console.log('[AttachmentsUploadComponent] > entityUploadToProvider > RESOLVE attachFileToProject', wrappedFile)

                  // file added - update list or parent component
                  this.triggerOnFileUploaded(wrappedFile.attachment)
                })
                .catch(err => { this.errorHandler.add(err) })
              console.log('[AttachmentsUploadComponent] > entityUploadToProvider > END attachFileToProject', wrappedFile.fileObject.name)
              this.$timeout()

              return wrappedFile
            })
            .catch(err => {
              if (err.xhrStatus === 'abort') {
                // handle upload cancelation - nothing to do - item will be gracefully  removed from the queue
              } else {
                // handle the reset of the errors
                this.errorHandler.add('GCS service unavailable. Please try again later.')
              }
            })
          promises.push(p)
        })
      }

      return this.$q
        .all(promises)
        .catch(err => {
          console.log('[AttachmentsUploadComponent] > all catch', err, promises)
          this.errorHandler.add(err)
        })
    }
  }
}

export default AttachmentsUploadComponent
