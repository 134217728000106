import jsonInflector from 'json-inflector'

const SIDEBAR_COMPONENT_NAME = 'main-left'
const DEFAULT_LIST_BE_SLUG = ''


const ProjectBrowseService = class ProjectBrowseService {
  constructor ($http, $location, $stateParams, $httpParamSerializer, Configuration, UserService, PaginationService, UIService) {
    'ngInject'
    this.$http = $http
    this.$location = $location
    this.$stateParams = $stateParams
    this.$httpParamSerializer = $httpParamSerializer
    this.Configuration = Configuration
    this.UserService = UserService
    this.PaginationService = PaginationService
    this.UIService = UIService

    this._filters = null
    this._pagination = null

    this._beParams = null
  }

  init () {
    console.log('[ProjectBrowseService] > init')
    this._filters = {
      search: {
        text: ''
      },
      basic: {
        previousClients: false,
        noEstimates: false,
        noComments: false,
        skills: false
      },
      category: {

      },
      pods: ''
    }

    this._pagination = this.PaginationService.init({
      itemsPerPage: 10,
      mode: 'server'
    })

    // Process basic filters from URL
    Object.entries(this._filters.basic).forEach(([key, value]) => {
      if (this.$stateParams[key]) {
        this._filters.basic[key] = this.$stateParams[key]
      }
    })
    // Process search filter from URL
    if (this.$stateParams['q']) {
      this._filters.search.text = this.$stateParams['q']
    }

    // Process pagination from URL
    if (this.$stateParams['page']) {
      this._pagination.setCurrentPage(this.$stateParams['page'])
    }

     // Process pods from URL
    if (this.$stateParams['pods']) {
      this._filters.pods = +this.$stateParams['pods']
    }
  }

  get filters () {
    return this._filters
  }

  get pagination () {
    return this._pagination
  }

  closeSidebar () {
    const sidebarCtrl = this.UIService.getComponent(SIDEBAR_COMPONENT_NAME)

    if (sidebarCtrl) {
      sidebarCtrl.close()
    }
  }

  updateQueryString ($event) {
    console.log('[ProjectBrowseService] > $event', $event)

    // update the query string with search text
    if (this._filters.search && this._filters.search.text !== '') {
      this.$location.search('q', this._filters.search.text)
    } else {
      this.$location.search('q', null)
    }

    // update the query string with the new filters
    Object.entries(this._filters.basic).forEach(([key, value]) => {
      if (this._filters.basic[key]) {
        this.$location.search(key, value)
      } else {
        this.$location.search(key, null) // remove empty filter
      }
    })

    if (this._filters.pods) {
      this.$location.search('pods', this._filters.pods)
    } else {
      this.$location.search('pods', null)
    }

    // update the query string with pagination
    if (this._pagination.currentPage !== this._pagination.firstPage) {
      this.$location.search('page', this._pagination.currentPage)
    } else {
      this.$location.search('page', null) // remove default page
    }
  }

  getProjectList () {
    let listSlug = this.$stateParams.listId
    // set default listId for client and expert
    if (typeof listSlug === 'undefined' || listSlug === 'default') {
      listSlug = DEFAULT_LIST_BE_SLUG
    } else if (listSlug === 'expert-pods') {
      listSlug = 'with_expert_pods'
    }



    const params = this._pagination ? {
      page: this._pagination.currentPage,
      per_page: this._pagination.itemsPerPage
    } : {}

    // Handle search filter
    if (this._filters.search && this._filters.search.text !== '') {
      params.q = this._filters.search.text
    }

    // Handle pods filter
    if (this._filters.pods) {
      params.pods = this._filters.pods
    }

    // Handle basic filters
    if (this._filters && this._filters.basic) {
      let activeFilters = Object.entries(this._filters.basic)
        .filter(([key, value]) => value === true)
        .reduce((obj, [key, value]) => {
          obj[key] = value
          return obj
        }, {})

      activeFilters = jsonInflector.transform(activeFilters, 'underscore')
      activeFilters = jsonInflector.transform(activeFilters, 'dasherize')

      activeFilters = Object.keys(activeFilters)

      if (activeFilters.length) {
        params.filter = activeFilters.join(',')
      }
    }

    console.log('[ProjectBrowseService] > params', params)

    return this.$http.get(`${this.Configuration.apiUrl}/experts/projects/new-projects/${listSlug}`, { params })
      .then(response => {
        if (this._pagination && typeof this._pagination.updateFromHeaders === 'function') {
          this._pagination.updateFromHeaders(response)

          // Autocorrect page param in url to match header page count without reloading view
          if (this.$stateParams.page > 1 && this.$stateParams.page > this._pagination.pageCount) {
            this.$location.search('page', Math.max(1, this._pagination.pageCount))
            this.$location.replace()
          }
        }
        return response.data
      })
  }
}
export default ProjectBrowseService
