import { AuthorizationPermissionError } from '@/app/common/errors'

const NavigationService = class NavigationService {
  constructor (Configuration, $state, $location, $window, $rootScope, EventBusService, UIService, UserService, SegmentAnalytics, Flash) {
    'ngInject'
    this.$state = $state
    this.$location = $location
    this.$window = $window
    this.$rootScope = $rootScope
    this.EventBusService = EventBusService
    this.Configuration = Configuration
    this.UIService = UIService
    this.UserService = UserService
    this.SegmentAnalytics = SegmentAnalytics

    this.Flash = Flash

    this.allowStateChange = true
    this.previousState = null
    this.currentState = null
  }

  init () {
    this.handleAuthorizationPermissionError()
    this.handleForwardingEventsToEventBusService()
    this.handleLayoutSwitching()
    this.handleStateHistory()
    this.handleFallbackRedirect()
    this.handleFallbackPageTitles()
    this.handleGlobalTracking()
    this.handleExpertAvailabilityFlash()

    console.log('[NavigationService] >> UserService', this.UserService)
  }

  get appUrl () {
    const isLocal = this.Configuration.NODE_ENV === 'development'
    if (isLocal) {
      return `${this.$location.protocol()}://${this.$location.host()}:${this.$location.port()}`
    }
    return `${this.$location.protocol()}://${this.$location.host()}`
  }

  handleAuthorizationPermissionError () {
    this.$rootScope.$on('$stateChangeError', ($event, toState, toParams, fromState, fromParams, error) => {
      // On permissions error redirect to login page
      if (error instanceof AuthorizationPermissionError) {
        console.log('[NavigationService] > handleAuthorizationPermissionError > $stateChangeError > AuthorizationPermissionError')
        console.log('[NavigationService] > handleAuthorizationPermissionError > UserService', this.UserService)
        if (this.UserService.isAuthenticated()) {
          this.$state.go('dashboard.list')
        } else {
          this.$state.go('auth.sign_in', { redirect: this.$state.href(toState, toParams) })
        }
      }
    })
  }


  handleLayoutSwitching () {
    // create new channel for for navigation events
    this.$rootScope.$on('$stateChangeSuccess', ($event, toState, toParams, fromState, fromParams) => {
      // handle layout change
      if (toState.layout && toState.layout === 'new') {
        this.UIService.setNewLayout()
      } else {
        this.UIService.setOldLayout()
      }
      console.log('[NavigationService] > $stateChangeSuccess > layout', toState.layout)
    })
  }

  handleStateHistory () {
    this.$rootScope.$on('$stateChangeSuccess', ($event, toState, toParams, fromState) => {
      console.log('[NavigationService] > handleStateHistory', fromState, toState)

      // Remember previus state name
      this.setPreviousState(fromState)
      this.setCurrentState(toState)
    })
  }

  handleForwardingEventsToEventBusService () {
    // create new channel for for navigation events
    this.$rootScope.$on('$stateChangeStart', ($event, toState, toParams, fromState, fromParams) => {
      this.EventBusService.channel().publish('$stateChangeStart', {
        $event,
        toState,
        toParams,
        fromState,
        fromParams
      })
    })
  }

  handleFallbackRedirect () {
    const currentUrl = this.$location.url()
    const urlRedirects = this.Configuration.router.fallbackRedirects
    const redirectConfig = urlRedirects.find(r => currentUrl.includes(r.from))
    if (redirectConfig) {
      console.log(`[NavigationService] > handleFallbackRedirect > from:`, currentUrl, ' to:', redirectConfig.to)
      this.$location.url(redirectConfig.to)
      this.$location.replace()
    }
  }

  handleExpertAvailabilityFlash () {
    this.$rootScope.$on('$stateChangeSuccess', ($event, toState, toParams, fromState) => {
      console.log('[NavigationService] > handleExpertAvailabilityFlash')

      // Show unavailable flash message for expert
      // TODO: refactor flash component
      if (this.UserService.isAuthenticated() && this.UserService.isExpert() && !this.UserService.user.available) {
        this.Flash.show('views/flashes/unavail_persistant.html', true)
      }
    })
  }

  handleFallbackPageTitles () {
    this.$rootScope.$on('$stateChangeSuccess', ($event, toState, toParams, fromState) => {
      // Change document title - could be done on individual routes registration/definition
      let title = 'Codeable'
      const fallbackTitlesConfig = this.Configuration.router.fallbackPageTitles

      // go trough paths find mathes if match set title
      for (let item of fallbackTitlesConfig) {
        const re = new RegExp(item.path, 'i')
        const match = this.$location.path().match(re)
        if (match) {
          console.log('match', match)
          title = item.title
        }
      }
      console.log('[NavigationService] > handleFallbackPageTitles > title', title)

      // TODO: move logic to change titles into route definition and read it from there
      this.$window.document.title = title
    })
  }

  handleGlobalTracking () {
    this.$rootScope.$on('$stateChangeSuccess', ($event, toState, toParams, fromState) => {
      // Global page and identity tracking
      this.SegmentAnalytics.identify(this.UserService.user, 'appRouteChangeSuccess')
      this.SegmentAnalytics.pageview(fromState)
    })
  }

  setPreviousState (previous) {
    this.previousState = previous
  }

  get previousStateName () {
    return this.previousState ? this.previousState.name : ''
  }

  setCurrentState (current) {
    this.currentState = current
  }

  get currentStateName () {
    return this.currentState ? this.currentState.name : ''
  }
}
export default NavigationService
