import templateUrl from './payments-stripe-button.html'
import './payments-stripe-button.scss'

const PaymentsStripeButtonComponent = {
  bindings: {
    task: '<',
    onStarted: '&',
    onCompleted: '&',
    onCanceled: '&',
    onError: '&'
  },
  templateUrl,
  controller: class PaymentsStripeButtonComponent {
    constructor (PaymentsStripeService) {
      'ngInject'
      this._identify = 'PaymentsStripeButtonComponent'
      this.PaymentsStripeService = PaymentsStripeService
    }

    $onInit () {
      const IS_SUBTASK = !!this.task.parentTask
      const SUB_TASK_ID = IS_SUBTASK ? this.task.id : null
      const TASK_ID = IS_SUBTASK ? this.task.parentTask.id : this.task.id

      this.isSubtask = IS_SUBTASK
      this.subtaskId = SUB_TASK_ID
      this.taskId = TASK_ID
    }

    pay () {
      const payload = {
        taskType: this.isSubtask ? 'SubTask' : 'Task',
        taskId: this.isSubtask ? this.subtaskId : this.taskId,
      }

      this.serverError = null
      this.PaymentsStripeService.getStripeUrl(payload)
        .then(response => {
          console.log('[PaymentsStripeComponent] > getStripeUrl > response', response)
          window.location.replace(response.paymentUrl)
        }, error => {
          console.log('[PaymentsStripeComponent] > getStripeUrl > error', error)
          if (error && error.data && error.data.errors && error.data.errors[0].message) {
            this.serverError = error.data.errors[0].message
            if (this.serverError === 'nothing_to_pay') {
              this.onFailed({
                event: {
                  eventName: this.serverError,
                }
              })
            }
          }
        })
    }
  }
}

export default PaymentsStripeButtonComponent
