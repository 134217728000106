/* Factory used to create instances of FileUploadWrappers and take care of the dependency injection
* Calling it from controller or service:
*
* const scopeDoc = new ProjectScopeDocFactory (config)
*/
function ProjectScopeDocFactory (Configuration, ProjectScopeDocItemFactory) {
  'ngInject'

  let idCounter = -1
  function getUniqueTemporaryId () {
    return idCounter--
  }

  const ProjectScopeDoc = class ProjectScopeDoc {
    constructor (config, resetIds = false) {
      this.id = getUniqueTemporaryId()
      this.state = null
      this.createdAt = null
      this.updatedAt = null
      this.submittedAt = null
      this.rejectedAt = null
      this.approvedAt = null
      this.finishedAt = null
      this.completedAt = null
      this.attachments = []
      this.scopeDocItems = []
      this.templateId = null

      // FE related data
      this._edit = {
        enableDescriptionField: false,
        isDraftSaved: Boolean(this.createdAt)
      }

      if (config) {
        Object.assign(this, config)

        // If template was used to create scope doc, all ids needs to be replaced with temporary ids
        if (resetIds) {
          this.id = getUniqueTemporaryId()
        }

        if (this.scopeDocItems.length) {
          // reconstruct class instances of items
          const processedItems = []
          this.scopeDocItems.forEach(item => {
            const processedItem = new ProjectScopeDocItemFactory(item, resetIds)

            // if (processedItem.scopeDocChildItems.length) {
            //   // reconstruct class instances of nested items
            //   const processedChildItems = []
            //   processedItem.scopeDocChildItems.forEach(item => {
            //     processedChildItems.push(new ProjectScopeDocItemFactory(item))
            //   })
            //   processedItem.scopeDocChildItems = processedChildItems
            // }

            processedItems.push(processedItem)
          })
          this.scopeDocItems = processedItems
        }
      }

      console.log('[ProjectScopeDoc] > init', this.id)
    }

    get itemsCount () {
      return this.scopeDocItems.length
    }

    get wasSaved () {
      return Boolean(this.state) && Boolean(this.createdAt)
    }

    get canSubmit () {
      return this.wasSaved
    }

    get canFinish () {
      let result = true
      this.scopeDocItems.forEach(item => {
        if (!item.finishedAt) {
          result = false
          return result
        }

        if (item.itemsCount) {
          item.scopeDocChildItems.forEach(childItem => {
            if (!childItem.finishedAt) {
              result = false
              return result
            }
          })
        }
      })

      return result
    }

    get canFinishItems () {
      return this.state === 'approved'
    }

    get canComplete () {
      return this.state === 'finished'
    }

    isState (stateName) {
      return this.state === stateName
    }

    findItemById (itemId) {
      let item = null
      this.scopeDocItems.forEach(rootItem => {
        if (rootItem.id === itemId) {
          item = rootItem
        } else {
          const child = rootItem.findItemById(itemId)
          if (child) {
            item = child
          }
        }
      })

      return item
    }

    findItemIndex (item) {
      return this.scopeDocItems.findIndex(rootItem => rootItem.id === item.id)
    }

    findParentOfItem (item) {
      return this.scopeDocItems.find(rootItem => {
        return rootItem.scopeDocChildItems.find(childItem => childItem.id === item.id)
      })
    }

    addNewItem (targetItem, parent = null) {
      const targetIndex = parent ? parent.findItemIndex(targetItem) : this.findItemIndex(targetItem)
      this.addItem(new ProjectScopeDocItemFactory(), parent, targetIndex + 1)
    }

    addItem (item, parent = null, position = null) {
      if (parent && position === null) {
        parent.addChild(item)
      } else if (parent && position !== null) {
        parent.addChild(item, position)
      } else {
        position = position === null ? this.itemsCount : position
        this.scopeDocItems.splice(position, 0, item)
      }
    }

    canRemoveItem (item) {
      return this.itemsCount > 1
    }

    removeItem (item) {
      if (item.parentId === null) {
        const rootIndex = this.findItemIndex(item)
        if (rootIndex > -1) {
          this.scopeDocItems.splice(rootIndex, 1)
        }
      } else {
        const parent = this.findParentOfItem(item)
        if (parent) {
          parent.removeChild(item)
        }
      }
    }
  }

  return ProjectScopeDoc
}


export default ProjectScopeDocFactory
