(function () {
  'use strict';

  function Auth($injector, $interval, $timeout, $cookies, Restangular, Sockets, Configuration, OnlineUsers, Storage, $state, $window, SegmentAnalytics, $rootScope) {

    // AUTH service
    var updating = false;

    // TODO: fetch user form storage only on app init, otherwise store it in memory
    var me = function () {
      return Storage.get('User');
    };

    var isClient = function () {
      let user = me();
      return user ? user.role === 'client' : false;
    };
    var isExpert = function () {
      let user = me();
      return user ? (user.role === 'contractor' || user.role === 'expert') : false;
    };

    var authenticated = false;
    function setAuthenticated(state) {
      authenticated = state;
    }
    function isAuthenticated() {
      return authenticated;
    }

    var changeMe = function (attribute, value) {
      var user = Storage.get('User');
      user[attribute] = value;
      Storage.set('User', user);

      me = function () {
        return Storage.get('User');
      };
    };

    var getToken = function () {
      return Storage.get('Authorization');
    };

    var register = function (user) {
      if (typeof user === 'object') {
        user.timezoneOffset = new Date().getTimezoneOffset();
      }

      var resource = _postUserData(Restangular.all('users'), user);

      // Additional processing for register endpoint
      resource.then(function (user) {
        SegmentAnalytics.identify(user, 'oldAuthService register');
      });

      return resource;
    };

    var signIn = function (user) {
      if (typeof user === 'object') {
        user.timezoneOffset = new Date().getTimezoneOffset();
      }
      var resource = _postUserData(Restangular.all('users').all('login'), user); // remove restangular we get sent user data in the catch block

      // Additional processing for signin endpoint
      resource.then(function (user) {
        SegmentAnalytics.identify(user.user, 'oldAuthService signIn');
      })

      return resource;
    };

    var signInToken = function (token) {
      console.log('Auth > signInToken > token', token);
      var resource = Restangular.all('users').all('token_login').post({
        authToken: token
      });

      resource.then(function (response) {
        const user = response.user ? response.user : response;
        SegmentAnalytics.identify(user, 'oldAuthService signInToken');
        Storage.set('User', user);
        setAuthenticated(true);
        startUpdatingToken();

        $rootScope.$broadcast('authSrv::logged-in'); // need to use events to execute other services and avoid circular dependency issue
      });

      return resource;
    };

    var signOut = function (state, params, options) {
      /*
        set default values to reduce errors
        if these aren't set here you get a "TypeError: Cannot read property 'indexOf' of undefined" in `$state.go();`
      */
      if (typeof state !== 'string') {
        state = 'auth.sign_in'; // default state after logout
      }
      if (typeof params !== 'object') {
        params = {};
      }
      if (typeof options !== 'object') {
        options = {};
      }
      if (updating) {
        $interval.cancel(updating);
        updating = false;
      }

      Storage.remove('Authorization');
      Storage.remove('User');
      Sockets.unsubscribeAll();
      setAuthenticated(false);
      $rootScope.$broadcast('authSrv::logged-out'); // need to use events to execute other services and avoid circular dependency issue


      console.log('Auth > signOut >', state, params, options);
      $state.go(state, params || {}, options || {});
    };

    var signOutWithoutRedirect = function () {
      console.log('Auth > signOutWithoutRedirect');
      if (updating) {
        $interval.cancel(updating);
        updating = false;
      }

      Storage.remove('Authorization');
      Storage.remove('User');
      Sockets.unsubscribeAll();
      setAuthenticated(false);
      $rootScope.$broadcast('authSrv::logged-out'); // need to use events to execute other services and avoid circular dependency issue
    };

    function _postUserData(resource, user) {
      var result = resource.post(user);

      result.then(function (response) {
        console.log('[AuthSrv] > _postUserData (signin/register) > successful');
        const user = response.user ? response.user : response;
        // if endpoint is resolved successfully the authinterceptor already handles extraction and updating of auth token in LS and Sockets
        Storage.set('User', user);
        setAuthenticated(true);
        startUpdatingToken(); // start interval for updating token after TTL expires (currently 7 days)

        /* ************************************************************************
        /* point in time when user logged in (via register page or via signin page)
        /* ************************************************************************/

        $rootScope.$broadcast('authSrv::logged-in'); // need to use events to execute other services and avoid circular dependency issue
      }).catch(err => {
        console.log(err)
        // TODO: error on register/login should be handled in UI component by either not catching it in service or bubbling it out of service to omponent.
      });

      return result;
    }

    function startUpdatingToken () {
      // console.log('============================================== START UPDATING TOKEN ====================================');
      // Do not start with token update if user is already updating
      // or if user is not logged in
      if (updating || !me()) {
        return;
      }

      // TODO put out of this function
      Sockets.init(me(), getToken());
      // Notifications.init(me());
      OnlineUsers.init();


      function updateInterval() { // start with angular's $interval
        Restangular.all('users').all('auth_tokens').post({
          authToken: getToken()
        }).then(function (data) {
          // auth intercpetor handles token update from endpoint response headers
        }).catch(signOut);
      }

      $timeout(updateInterval); // Otherwise restangular doesn't have base path

      // The meat of the function: Every "n" seconds, reach out to server,
      // get a new token back, then update websockets service
      // and instert the new token into local storage
      updating = $interval(updateInterval, Configuration.settings.tokenRenewalInterval * 1000);
      // updating = $interval(updateInterval, 60 * 1000);
    }

    // startUpdatingToken();

    return {
      me: me,
      isClient: isClient,
      isExpert: isExpert,
      changeMe: changeMe,
      signIn: signIn,
      signInToken: signInToken,
      signOut: signOut,
      signOutWithoutRedirect: signOutWithoutRedirect,
      register: register,
      getToken: getToken,
      isAuthenticated: isAuthenticated,
      setAuthenticated: setAuthenticated
    };
  }

  app.service('Auth', ['$injector', '$interval', '$timeout', '$cookies', 'Restangular', 'Sockets', 'Configuration', 'OnlineUsers', 'Storage', '$state', '$window', 'SegmentAnalytics', '$rootScope', Auth]);
})();
