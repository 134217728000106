(function () {
  'use strict';

  function checkboxNew() {
    return {
      restrict: 'E',
      scope: {
        ngModel: '=',

        checkboxId: '@',
        disabled: '=',
        label: '@'
      },
      // replace: true,
      templateUrl: require('../../views/directives/checkboxNew.html'),
      link: function ($scope, element, attrs) {
        if ($scope || element || attrs) {
          return;
        }
      }
    };
  }

  app.directive('checkboxNew', [checkboxNew]);
})();
