import templateUrl from './definer-form.html'
import './definer-form.scss'
// import { urlPattern, presetFields } from '../project-presets.config'
import { urlPattern } from '../project-presets.config'

const ProjectPresetsDefinerFormComponent = {
  bindings: {
    preset: '<',
    onGenerateEnduserLink: '&',
    isParentEditMode: '<',
    isPreview: '<',
    onDefinerFormInit: '&'
  },
  templateUrl,
  require: {
    // 'presetsCreateCtrl': '^cdblProjectPresetsCreate'
  },
  controller: class ProjectPresetsDefinerForm {
    constructor (ModalService, EventEmitter) {
      'ngInject'
      this._identify = 'ProjectPresetsDefinerFormComponent'
      this.ModalService = ModalService
      this.EventEmitter = EventEmitter
    }

    $onInit () {
      this.urlPattern = urlPattern

      if (this.onDefinerFormInit) {
        // pass controller to parent components
        this.onDefinerFormInit(this.EventEmitter({ definerFormCtrl: this }))
      }

      // TODO: remove demo data
      // Object.entries(presetFields).forEach(([fieldName, field]) => {
      //   this.preset.fields[fieldName].hidden = false
      //   if (!['descriptionNotes'].includes(fieldName)) {
      //     this.preset.fields[fieldName].locked = true
      //   }
      // })
      // this.preset.fields.title.value = 'Lorem ipsum dolor sit amet'
      // this.preset.fields.description.value = 'Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet Lorem ipsum dolor sit amet '
      // this.preset.fields.projectType.value = 'install'
      // this.preset.fields.projectSubject.value = 'theme'
      // this.preset.fields.shortcode.value = ''
      // this.preset.fields.urgency.value = 2
      // this.preset.fields.complexity.value = 'medium'
      // this.preset.fields.expireDurationDays.hidden = false
    }

    generateEnduserLink () {
      this.isProcessing = true
      if (this.onGenerateEnduserLink) {
        this.onGenerateEnduserLink(this.preset)
          .finally(() => {
            this.isProcessing = false
          })
      }
    }

    // isVisible (field) {
    //   return typeof field !== 'undefined' && !field?.hidden
    // }

    isVisible (fieldName) {
      // Always visible field for the parent form editing
      if (this.isParentEditMode) {
        return true
      }

      const field = this.preset?.fields[fieldName]
      const visible = typeof field !== 'undefined' && !field?.hidden

      switch (fieldName) {
      case 'preferredExpertsPublishForAll':
        return visible && this.preset.fields.preferredExperts.value.length > 0
      case 'expireDurationDays':
        return visible && !this.preset.fields.multiUseLink.value
      }

      return visible
    }


    openPreviewModal () {
      this.ModalService.open(this.ModalService.generateConfig('success', {
        title: 'CUSTOMER PREVIEW',
        body: `<cdbl-project-presets-client-form preset="$ctrl.modal.preset" is-preview="true" class="align-left"></cdbl-project-presets-client-form>`,
        resolveButton: {
          label: 'Close'
        },
        preset: this.preset
      }))
    }
  }
}

export default ProjectPresetsDefinerFormComponent
