import SettingsAvailabilityComponent from './settings-availability.component'

const SettingsAvailabilityModule = angular.module('cdbl.settings-availability', [

])
  .component('cdblSettingsAvailability', SettingsAvailabilityComponent)
  .config(($stateProvider) => {
    'ngInject'

    $stateProvider
      .state('settings.availability', {
        url: '/availability',
        views: {
          'settingsView': {
            template: '<cdbl-settings-availability></cdbl-settings-availability>'
          }
        },
        resolve: {
          permissionResolver: (AuthService) => {
            'ngInject'
            return AuthService.permissionResolver('isExpert')
          }
        },
        layout: 'new'
      })
  })
  .name

export default SettingsAvailabilityModule
