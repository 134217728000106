import SettingsNotificationsComponent from './settings-notifications.component'

const SettingsNotificationsModule = angular.module('cdbl.settings-notifications', [

])
  .component('cdblSettingsNotifications', SettingsNotificationsComponent)
  .config(($stateProvider) => {
    'ngInject'

    $stateProvider
      .state('settings.notifications', {
        url: '/notifications',
        views: {
          'settingsView': {
            template: '<cdbl-settings-notifications></cdbl-settings-notifications>'
          }
        },
        resolve: {
          permissionResolver: (AuthService) => {
            'ngInject'
            return AuthService.permissionResolver('isAuthenticated')
          }
        },
        layout: 'new'
      })
  })
  .name

export default SettingsNotificationsModule
