import templateUrl from './team-member.html'
import './team-member.scss'

const SettingsExpertTeamMemberComponent = {
  bindings: {
    member: '<',
    onDelete: '&',
    onToggleActive: '&'
  },
  templateUrl,
  controller: class SettingsExpertTeamMemberComponent {
    constructor (EventEmitter) {
      'ngInject'
      this._identify = 'SettingsExpertTeamMemberComponent'
      this.EventEmitter = EventEmitter
    }

    $onInit () {
      this.formData = {
        active: this.member.state === 'active'
      }
    }

    $onChanges (changes) {
      console.log('changes', changes)
      this.formData = {
        active: changes.member.currentValue.state === 'active'
      }
    }

    get avatarStyle () {
      return {
        backgroundImage: `url(${this.member.avatar.mediumUrl})`
      }
    }

    get memberRolesAsString () {
      return Array.isArray(this.member.roles) ? this.member.roles.map(r => r.name).join(', ') : ''
    }

    get canDelete () {
      return this.member && this.member.actions && this.member.actions.deleteMember
    }

    get canToggleActiveInactive () {
      return ['active', 'inactive'].includes(this.member.state)
    }

    toggleActive ($event) {
      if (typeof this.onToggleActive === 'function') {
        this.onToggleActive(this.EventEmitter({ member: this.member, active: this.formData.active }))
      }
    }

    delete () {
      if (typeof this.onDelete === 'function') {
        this.onDelete(this.EventEmitter({ member: this.member }))
      }
    }
  }
}

export default SettingsExpertTeamMemberComponent
