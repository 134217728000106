import SubmissionFirstprojectComponent from './submission-firstproject.component'

const SubmissionFirstprojectModule = angular.module('cdbl.submission-firstproject', [

])
  .component('cdblSubmissionFirstproject', SubmissionFirstprojectComponent)
  .config($stateProvider => {
    'ngInject'

    $stateProvider
      // .state('tasks.firstproject-generic', {
      //   url: '/first-project',
      //   template: '<cdbl-submission-firstproject></cdbl-submission-firstproject>',
      //   onEnter: ['$stateParams', function ($stateParams) {
      //     console.log('tasks.firstproject onEnter', $stateParams)
      //   }]
      // })
      .state('tasks.firstproject', {
        url: '/first-project/:id?intro&feedback&showContractor&edit&pay', // arguments must match arguments in tasks states.js
        template: '<cdbl-submission-firstproject></cdbl-submission-firstproject>',
        // onEnter: ['$stateParams', function ($stateParams) {
        //   console.log('tasks.firstproject onEnter', $stateParams)
        // }],
        resolve: {
          permissionResolver: (AuthService) => {
            'ngInject'
            return AuthService.permissionResolver('isClient')
          }
        },
      })
  })
  .name

export default SubmissionFirstprojectModule
