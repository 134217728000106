import templateUrl from './change-password.html'
import './change-password.scss'


const ChangePasswordModalComponent = {
  bindings: {
    portfolio: '<?',
    onSave: '&?'
  },
  require: {
    modalCtrl: '^^cdblModal'
  },
  templateUrl,
  controller: class ChangePasswordModalComponent {
    constructor ($timeout, EventEmitter, ModalService, SettingsService) {
      'ngInject'
      this._identify = 'ChangePasswordModalComponent'
      this.$timeout = $timeout
      this.EventEmitter = EventEmitter
      this.SettingsService = SettingsService
      this.ModalService = ModalService
    }

    $onInit () {
      this.isCompleted = false
      this.formData = {
        currentPassword: '',
        newPassword: '',
        repeatPassword: '',
      }
    }
    changePassword () {
      this.error = null
      this.isProcessing = true
      console.log('[ChangePasswordModalComponent] > changePassword')
      this.SettingsService
        .changePassword({
          currentPassword: this.formData.currentPassword,
          newPassword: this.formData.newPassword
        })
        .then(response => {
          console.log('[ChangePasswordModalComponent] > changePassword > then')
          this.isCompleted = true
        })
        .catch(err => {
          console.log('[ChangePasswordModalComponent] > changePassword > err', err)
          if (err.data.errors.find(e => e.field === 'current_password' && e.message === 'is incorrect')) {
            this.error = 'Your current password is incorrect. Please try again.'
          } else {
            this.error = err.data.errors[0].message // display first error message
          }
        })
        .finally(() => {
          console.log('[ChangePasswordModalComponent] > changePassword > finally')
          this.isProcessing = false
        })
      // this.$timeout()
    }

    closeDialog () {
      this.modalCtrl.close()
    }
  }
}

export default ChangePasswordModalComponent
