const PercentageFormatFilter = function ($filter) {
  'ngInject'
  return function (value) {
    if (typeof value !== 'number') {
      return value
    }

    let percentage = $filter('number')(value * 100, 1)
    percentage = percentage.replace(/[.]0$/, '')
    return `${percentage} %`
  }
}
export default PercentageFormatFilter
