(function () {
  'use strict';

  function RegisterCtrl($scope, $state, $cookies, AuthService, SegmentAnalytics, Configuration, UserService, $stateParams, $location, Sentry, UserResource, SettingsExpertTeamService, ModalService) {
    $scope.user = {
      fullName: '',
      firstName: '',
      lastName: '',
      email: '',
      password: ''
    };

    $('input[type="text"]').first().focus();

    $scope.register = function (user) {
      user.fullName = user.firstName + ' ' + user.lastName;
      $scope.processing = true;

      if ($cookies.get('coupon_code')) {
        user.couponCode = $cookies.coupon_code;
      }

      AuthService.register(user).then(function (user) {
        $cookies.remove('coupon_code');

        SegmentAnalytics.trackAccountCreated(user);

        $state.go(Configuration.defaultRouterState.name);
      }).finally(function () {
        $scope.processing = false;
      });
    };

    $scope.signInWithGoogle = function () {
      $scope.errorAccountAlreadyExists = false
      var auth2 = gapi.auth2.getAuthInstance()
      auth2.grantOfflineAccess().then(signInCallback)
    }

    function signInCallback (resp) {
      AuthService.loginGoogle(resp.code)
        .then(function (user) {
          if (user.ttl) {
            ModalService.open({
              closeOnBackdrop: false,
              closeIcon: true,
              class: 'accept-tos-modal cdbl-modal--style-with-dividers cdbl-modal--style-with-sticky-header-and-footer',
              template: `
                <cdbl-accept-tos-modal
                token="$ctrl.modal.token">
                </cdbl-accept-tos-modal>`,
              token: user.token,
            })
          } else {
            if (!user.trackedSegmentByLink && user.role === 'client') {
              $cookies.put('segmentUser', 'yes')
            } else {
              $cookies.put('segmentUser', 'no')
            }

            $scope.inProgress = true

            AuthService.log()
            if ($stateParams.redirect) {
              $location.url($stateParams.redirect)
            } else if (user.role === 'client') {
              if (user.tasksCount === 1) {
                console.log('client > tasksCount', user.tasksCount)
                var requests = ['tasksInProgress', 'tasksInquiring']
                UserResource.getUser(requests).then(function (tasks) {
                  var taskId
                  if (tasks.tasksInProgress.length === 1) {
                    taskId = tasks.tasksInProgress[0].id
                  } else if (tasks.tasksInquiring.length === 1) {
                    taskId = tasks.tasksInquiring[0].id
                  }

                  console.log('client > taskId', taskId)
                  $state.go('tasks.display', { id: taskId })
                }).catch(function () {
                  $state.go(Configuration.defaultRouterState.name)
                })
              } else {
                $state.go(Configuration.defaultRouterState.name)
              }
            } else {
              $state.go(Configuration.defaultRouterState.name)
            }

          // TODO move to app service and call it here once login is completed
            UserService.getAutoDisplayModalsList()
              .then(response => {
                console.log('[SignInCtrl] > getAutoDisplayModalsList > response', response)
                response.forEach(autoDisplayModal => {
                  if (autoDisplayModal.name === SettingsExpertTeamService.AUTO_DISPLAY_CONFIRMATION_MODAL_NAME) {
                    SettingsExpertTeamService.showUpdateConfirmationModal()
                  }
                })
              })
              .catch(err => {
                console.log('[SignInCtrl] > getAutoDisplayModalsList > err', err)
              })
          // ----
          }
        // ----
        })
        .catch(err => {
          console.log('[SignInCtrl] > err', err)
          delete err.config // do not report users login data

        // TODO: because how the server errors are currently handeled this should be revisited when we change/refactor auth module
          if (err.data.reason === 'account_locked') {
            $scope.errorAccountLocked = true
          }

          if (err.data.reason === 'user_already_exists') {
            $scope.errorAccountAlreadyExists = true
          }

          if (Sentry) {
            Sentry.sendMessage('signInCtrl.js > AuthService.login (status: ' + err.status + '): ' + JSON.stringify(err), 'info')
          }
        })
        .finally(function () {
          $scope.processing = false
        })
    }
  }

  app.controller('RegisterCtrl', ['$scope', '$state', '$cookies', 'AuthService', 'SegmentAnalytics', 'Configuration',  'UserService', '$stateParams', '$location', 'Sentry', 'UserResource', 'SettingsExpertTeamService', 'ModalService', RegisterCtrl]);
})();
