import templateUrl from './member.html'
import './member.scss'

const ProjectTeamMemberComponent = {
  bindings: {
    member: '<',
    project: '<',
    onRemoveMember: '&'
  },
  templateUrl,
  controller: class ProjectTeamMember {
    constructor (ProjectTeamService, EventEmitter, ModalService) {
      'ngInject'
      this._identify = 'ProjectTeamMemberComponent'
      this.ProjectTeamService = ProjectTeamService
      this.EventEmitter = EventEmitter
      this.ModalService = ModalService
    }

    get canBeRemoved () {
      return this.onRemoveMember && this.member && this.member.paymentsCount === 0
    }

    removeMember () {
      const expertIds = [
        this.member.expert.id
      ]

      const config = this.ModalService.generateConfig('confirmation', {
        body: `Are you sure you want to remove ${this.member.expert.fullName} from the team?`,
        rejectButton: {
          label: 'Cancel'
        },
        resolveButton: {
          label: 'Yes, remove'
        },
      })

      this.ModalService.open(config)
        .then(response => {
          this.ProjectTeamService.removeTeamMember(this.project.id, expertIds)
            .then(response => {
              if (this.onRemoveMember) {
                this.onRemoveMember(this.EventEmitter({ member: this.member }))
              }
            })
        })
    }
  }
}

export default ProjectTeamMemberComponent
