import templateUrl from './manage-modal.html'
import './manage-modal.scss'

const ProjectTeamManageModalComponent = {
  bindings: {
    project: '<',
    team: '<',
    onMembersAdded: '&'
  },
  require: {
    modalCtrl: '^^cdblModal'
  },
  templateUrl,
  controller: class ProjectTeamManageModal {
    constructor (ProjectTeamService, EventEmitter, ErrorsService, UserService) {
      'ngInject'
      this._identify = 'ProjectTeamManageModalComponent'
      this.ProjectTeamService = ProjectTeamService
      this.EventEmitter = EventEmitter
      this.ErrorsService = ErrorsService
      this.UserService = UserService
    }

    $onInit () {
      this.maxMembers = 5
      this.numExistingMembers = this.team && this.team.teamMembers ? this.team.teamMembers.length : 0
      this.numRemainingMembers = 5 - this.numExistingMembers

      this.canAddNewMemmbers = this.numExistingMembers < 5

      this.members = null
      this.errorHandler = this.ErrorsService.createErrorHandler()

      this.isLoading = true
      this.ProjectTeamService.fetchExperts()
        .then(response => {
          let experts = response

          // filter experts that are not in the team or lead expert
          if (this.team && this.team.teamMembers && this.team.teamLead) {
            experts = experts.filter(expert => {
              return !this.team.teamMembers.find(member => member.expert.id === expert.id) && this.team.teamLead.id !== expert.id
            })
          }

          this.members = {
            options: experts,
            selected: []
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    }

    addMembers () {
      if (this.members && this.members.selected.length) {
        const ids = this.members.selected.map(expert => expert.id)
        this.isLoading = true
        this.ProjectTeamService.addTeamMembers(this.project.id, ids)
          .then(members => {
            console.log('[ProjectTeamManageModal] > addMembers > members', members, this.onMembersAdded)
            if (this.onMembersAdded) {
              this.onMembersAdded(this.EventEmitter({ members: members }))
            }

            this.modalCtrl.close()
          })
          .catch(err => {
            this.errorHandler.add(err)
          })
          .finally(() => {
            this.isLoading = false
          })
      }
    }
  }
}

export default ProjectTeamManageModalComponent
