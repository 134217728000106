import ProjectDashboardComponent from './project-dashboard.component'
import ProjectDashboardService from './project-dashboard.service'

import ProjectNavigationComponent from './project-navigation'
import ProjectStatsComponent from './project-stats'
import ProjectAddNewProjectComponent from './project-add-new-project'
import ProjectListComponent from './project-list'
import ProjectComponent from './project'
import ProjectLastActivityComponent from './project/project-last-activity'



// Project universal component - TODO: move to new folder when project estimation/workroom redo
import ProjectCancelButtonComponent from './project/project-cancel-button'
import ProjectConvertTypeButtonComponent from './project/project-convert-type-button'
import ProjectFavouriteButtonComponent from './project/project-favourite-button'
import ProjectConvertFromConsultationButtonComponent from './project/project-convert-from-consultation-button'

const ProjectDashboardModule = angular.module('cdbl.project-dashboard', [

])

  .service('ProjectDashboardService', ProjectDashboardService)
  .component('cdblProjectDashboard', ProjectDashboardComponent)

  .component('cdblProjectDashboardNavigation', ProjectNavigationComponent)
  .component('cdblProjectDashboardList', ProjectListComponent)
  .component('cdblProjectDashboardProject', ProjectComponent)
  .component('cdblProjectDashboardProjectLastActivity', ProjectLastActivityComponent)
  .component('cdblProjectDashboardStats', ProjectStatsComponent)
  .component('cdblProjectDashboardAddNewProject', ProjectAddNewProjectComponent)

  .component('cdblProjectCancelButton', ProjectCancelButtonComponent)
  .component('cdblProjectConvertTypeButton', ProjectConvertTypeButtonComponent)
  .component('cdblProjectConvertFromConsultationButton', ProjectConvertFromConsultationButtonComponent)
  .component('cdblProjectFavouriteButton', ProjectFavouriteButtonComponent)

  .config($stateProvider => {
    'ngInject'
    $stateProvider
      .state({
        name: 'dashboard',
        url: '/dashboard',
        template: '<cdbl-project-dashboard></cdbl-project-dashboard>',
        abstract: true,
        resolve: {
          permissionResolver: (AuthService) => {
            'ngInject'
            return AuthService.permissionResolver('isAuthenticated')
          }
        },
        // layout: 'new'
      })
      .state({
        name: 'dashboard.list',
        parent: 'dashboard',
        url: '/:listId?page&commented&estimated', // '&page' add to have it in url
        params: {
          listId: { squash: true, value: 'default' },
          commented: { squash: true, type: 'bool', value: false },
          estimated: { squash: true, type: 'bool', value: false },
          page: { squash: true, type: 'int', value: 1 },
        },
        views: {
          'projectListView': {
            template: '<cdbl-project-dashboard-list></cdbl-project-dashboard-list>'
          }
        },
        layout: 'new'
      })
  })

  // This makes sure app folows right routing after init
  .run(($rootScope, $state, UserService) => {
    'ngInject'
    const LEGACY_DASHBOARD_ROUTER_STATE = 'users.dashboard'

    // Redirect to new dashboard
    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
      if (toState.name.startsWith(LEGACY_DASHBOARD_ROUTER_STATE)) {
        event.preventDefault() // cancel original route transition

        console.log('[ProjectDashboardModule] > redirect to new dashboard', toState.name, toParams)

        // Redirect to new dashboard based on current state name
        switch (toState.name) {
        case 'users.dashboard.inProgress':
          $state.go('dashboard.list', { listId: 'in-progress' })
          break
        case 'users.dashboard.inquiring':
          $state.go('dashboard.list', { listId: 'pending' })
          break
        case 'users.dashboard.archive':
          $state.go('dashboard.list', { listId: 'archived' })
          break
        case 'users.dashboard.favourites':
          $state.go('dashboard.list', { listId: 'favourites' })
          break
        case 'users.dashboard.preferred':
          $state.go('dashboard.list', { listId: 'preferred' })
          break
        default:
          $state.go('dashboard.list', { listId: 'default' })
          break
        }
      }
    })
    // -----
  })
  .name

export default ProjectDashboardModule
