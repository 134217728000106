import templateUrl from './transaction-item.html'
import './transaction-item.scss'

const TransactionItemComponent = {
  bindings: {
    transaction: '<'
  },
  templateUrl,
  controller: class TransactionItemComponent {
    constructor ($window, $state, UserService, ModalService, TransactionsService, SegmentAnalytics) {
      'ngInject'
      this._identify = 'TransactionItemComponent'
      this.$window = $window
      this.$state = $state
      this.UserService = UserService
      this.ModalService = ModalService
      this.TransactionsService = TransactionsService
      this.SegmentAnalytics = SegmentAnalytics
    }

    $onInit () {
      this.title = null
      this.content = null
      this.showFee = false

      this.processTransaction()
    }

    get showDownloadInvoice () {
      return this.transaction.actions && this.transaction.actions.readInvoice
    }

    downloadInvoice () {
      this.TransactionsService.downloadInvoice(this.transaction)
        .then(response => {
          const project = this.transaction.resources.project
          const subtask = this.transaction.resources.subTask
          const segmentPayload = {}
          if (project) {
            segmentPayload.task_id = project.id
          } else if (subtask) {
            segmentPayload.task_id = subtask.parentTaskId
            segmentPayload.subtask_id = subtask.id
            segmentPayload.retainer = subtask.retainer
          }

          this.SegmentAnalytics.trackEvent('Invoice Downloaded', segmentPayload)
          this.$window.open(response.url, '_blank')
        })
        .catch(err => {
          console.log(err.status === 422)
          if (err.status === 422) {
            this.ModalService.open(this.ModalService.generateConfig('confirmation', {
              title: 'INVOICE DATA MISSING',
              body: 'You must enter your business or personal information before you can download invoices',
              rejectButton: {
                label: 'Cancel'
              },
              resolveButton: {
                label: 'GO TO SETTINGS'
              },
            }))
              .then(resolve => {
                this.$state.go('settings.invoices')
              })
          }
        })
    }

    processTransaction () {
      console.log('[TransactionItemComponent] > transaction', this.transaction)
      const project = this.transaction.resources ? this.transaction.resources.project : null
      const subtask = this.transaction.resources ? this.transaction.resources.subTask : null
      const client = this.transaction.resources ? this.transaction.resources.client : null

      switch (this.transaction.description) {
      case 'offline_client_deposit':
      case 'client_deposit':
        if (project) {
          this.title = `Project paid`
          this.content = project.title
        } else if (subtask) {
          const isRetainer = subtask.retainer
          this.title = `${isRetainer ? 'Retainer' : 'Additional task'} paid`
          this.content = subtask.title
        }
        this.showFee = true
        break
      case 'task_completion':
        if (project) {
          this.title = `Project ${client ? 'for ' + client.fullName : ''} completed`
          this.content = project.title
        } else if (subtask) {
          const isRetainer = Boolean(subtask.retainer)
          this.title = `${isRetainer ? 'Retainer' : 'Additional task'} ${client ? ' for ' + client.fullName : ''} completed`
          this.content = subtask.title
        }
        this.showFee = true
        break
      case 'full_refund_keep_fee':
        this.title = 'Project refund'
        this.content = project ? project.title : subtask ? subtask.title : null
        break
      case 'full_refund':
        this.title = 'Project refund'
        this.content = project ? project.title : subtask ? subtask.title : null
        break
      case 'partial_refund':
        this.title = 'Partial project refund'
        this.content = project ? project.title : subtask ? subtask.title : null
        break
      case 'ad_hoc_expert_credit':
        this.title = 'Expert compensation (by Codeable)'
        this.content = project ? project.title : subtask ? subtask.title : null
        break
      case 'ad_hoc_expert_debit':
        this.title = 'Expert deduction (by Codeable)'
        this.content = project ? project.title : subtask ? subtask.title : null
        break
      case 'ad_hoc_team_work':
        const sender = this.transaction.resources.sender
        const recepient = this.transaction.resources.recepient
        const isSender = this.transaction.amount < 0

        if (isSender) {
          this.title = `Expert transfer sent ${recepient ? 'to ' + recepient.fullName : ''}`
        } else {
          this.title = `Expert transfer received ${sender ? 'from ' + sender.fullName : ''}`
        }

        this.content = project ? project.title : subtask ? subtask.title : null
        break
      case 'expert_withdrawal_request':
        this.title = 'Withdrawal request submitted'
        break
      case 'cancel_expert_withdrawal_request':
        this.title = 'Withdrawal request canceled'
        break
      case 'contractor_withdrawal':
        this.title = 'Withdrawal request processed'
        this.showFee = true
        break
      case 'account_closure':
        this.title = 'Account closed'
        break
      default:
        this.title = 'Mapping not found'
        break
      }
    }
  }
}

export default TransactionItemComponent
