(function () {
  'use strict';

  function spinner(Spinner) {
    var link = function ($scope, elem, attrs) {
      var opts = {
        lines: 12,
        length: 4,
        radius: 4,
        width: 1,
        corners: 0,
        speed: 2,
        color: attrs.spinnerColor ? attrs.spinnerColor : '#bcbabc',
        top: '-5px',
        left: '-20px'
      };
      var spinner = new Spinner(opts);

      $scope.$watch('processing', function (val) {
        return val ? spinner.spin(elem[0]) : spinner.stop();
      });
    };

    return {
      restrict: 'A',
      replace: false,
      link: link
    };
  }

  app.directive('spinner', ['Spinner', spinner]);
})();
